<template>
  <Alert
    v-if="showWarning"
    in-page
    data-testid="ie-warning"
    type="warning"
    text="You may experience issues running this application in this browser."
    subtext="Please use Google Chrome, Safari or Microsoft Edge"
  >
    <template slot="close">
      <v-btn @click="acknowledge" icon color="#DC5800" aria-label="dismiss">
        <v-icon>cancel</v-icon>
      </v-btn>
    </template>
  </Alert>
</template>

<script>
import { IeBlocker, Alert } from '@nswdoe/doe-ui-core'

const ACK_SESSION_KEY = 'si.ieAck'

export default {
  name: 'IeWarning',
  components: {
    Alert
  },
  data() {
    return {
      isIEBrowser: IeBlocker.isIe(),
      dataWarningAcknowledged: sessionStorage.getItem(ACK_SESSION_KEY)
    }
  },
  computed: {
    warningAcknowledged: {
      get() {
        return this.dataWarningAcknowledged
      },
      set(value) {
        sessionStorage.setItem(ACK_SESSION_KEY, value)
        this.dataWarningAcknowledged = value
      }
    },
    showWarning() {
      return !this.warningAcknowledged && this.isIEBrowser
    }
  },
  methods: {
    acknowledge() {
      this.warningAcknowledged = true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-alert__content button {
  border: none;
  &:focus {
    border: 3px solid $ads-navy;
  }
}
</style>
