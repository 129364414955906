<template>
  <!-- eslint-disable max-len -->

  <div class="pt-0" ref="highSchoolEoIDetails">
    <p v-if="!isMultipleSchools" class="pb-3 page-title">
      This child is entitled to a place at their local high school.<br />This
      child’s local high school for
      <strong>{{ this.residentialAddress }}</strong> is:
    </p>
    <v-form
      ref="highSchoolDetailsForm"
      data-testid="high-school-form"
      lazy-validation
    >
      <v-card class="pa-6 mb-8">
        <h2
          v-if="isMultipleSchools"
          class="heading mb-4"
          data-testid="select-preferred-local-schools"
        >
          Select preferred local school
        </h2>
        <component
          :is="isMultipleSchools ? 'RadioGroup' : 'div'"
          v-model="selectedLocalHighSchoolCode"
          :class="isMultipleSchools ? 'multipleSchools' : ''"
          :rules="[
            validators.customError('Please select preferred local school')
          ]"
          required
          :error-messages="blurLocalSchoolError"
          @radioblur="handleBlur(selectedLocalHighSchoolCode, 'school')"
          @input="blurLocalSchoolError = []"
          label="Please select preferred local school"
          data-testid="preferred-local-schools"
        >
          <SchoolCard
            v-for="(school, index) in localSecondarySchoolsForDisplay"
            :key="index"
            :image-src="require('@/assets/icon-school.svg')"
            :radio-attrs="{ value: localHighSchools[index].school_code }"
            :school-name="school.schoolName"
            :address-text="school.addressText"
            :address-url="school.addressUrl"
            :phone-number="school.phoneNumber"
            :website-url="school.websiteUrl"
            :website-text="school.websiteText"
            :selectable="isMultipleSchools"
            data-testid="preferred-local-school-card"
          />
        </component>
      </v-card>

      <div class="form-card pa-6 mb-8">
        <h2 class="heading">Other school options</h2>
        <RadioGroup
          v-model="consideringSelectiveSchool"
          class="form-card__question form-card__question--selective"
          label="Has the parent submitted an application for placement in an academically selective school?"
          :items="YES_NO_OPTIONS"
          :rules="[
            validators.customError(
              'Please choose if the parent has submitted an application for placement in an academically selective school'
            )
          ]"
          required
          :error-messages="blurSelectiveError"
          @radioblur="handleBlur(consideringSelectiveSchool, 'selective')"
          @input="blurSelectiveError = []"
          data-testid="selective-school"
        />
        <v-divider v-if="showOOAQuestion" />
        <RadioGroup
          v-if="showOOAQuestion"
          v-model="consideringOoAGovtSchools"
          class="form-card__question form-card__question-ooa"
          label="Will the parent be considering placement(s) at any other government schools besides their local high school?"
          :items="YES_NO_OPTIONS"
          :rules="[
            validators.customError(
              'Please choose if the parent will be considering placement(s) at any other government schools besides their local high school'
            )
          ]"
          :error-messages="blurOoaError"
          @change="changeOoaSchoolOptions"
          @radioblur="handleBlur(consideringOoAGovtSchools, 'ooa')"
          @input="blurOoaError = []"
          required
          data-testid="ooa-schools"
        />
        <v-divider v-if="consideringOoAGovtSchools" />
        <TopSlideTransition
          class="school-finder__transition"
          element-max-height="40rem"
        >
          <div
            v-if="consideringOoAGovtSchools || nominatedSchools.length > 0"
            class="school-finder__wrapper"
          >
            <AdditionalSchoolFinder
              :card="false"
              title="Nominate out-of-area schools"
              :nominated-schools="nominatedSchools"
              :local-schools="localHighSchools"
              @update:nominatedSchools="setSelectedSchools"
              data-testid="ooa-additional-school-finder"
            />
          </div>
        </TopSlideTransition>
      </div>
      <div
        v-if="consideringOoAGovtSchools && nominatedSchools.length > 0"
        class="form-card"
      >
        <h2 class="heading mb-6">Considerations document</h2>
        <p class="description">
          Upload a scanned document of any details supplied by parent/carer to
          support this student’s application
        </p>
        <ConsiderationsDocumentUpload
          class="mt-8"
          v-model="files"
          ref="considerationsDocumentUpload"
          :is-busy.sync="isFileOperationInProgress"
          label="Uploaded file"
          data-testid="ooa-considerations-upload"
          @notification="passNotification"
        />
      </div>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { YES_NO_OPTIONS } from '@/constants'
import utils from '@/mixins/utils'
import validators from '@/helpers/validators'
import _pick from 'lodash/pick'
import RadioGroup from '@/components/application/y67t/paperEoI/Form/RadioGroup'
import SchoolCard from '@/components/application/y67t/paperEoI/Form/SchoolCard'
import SchoolFinder from '@/components/form/OesSchoolFinder'
import AdditionalSchoolFinder from '@/components/application/y67t/paperEoI/Form/AdditionalSchoolFinder'
import TopSlideTransition from '@/components/application/y67t/paperEoI/Form/TopSlideTransition'
import { AdsButton } from '@nswdoe/doe-ui-core'
import ConsiderationsDocumentUpload from '@/components/application/y67t/paperEoI/Form/ConsiderationsDocumentUpload'

export default {
  name: 'HighSchoolEOIDetails',
  components: {
    RadioGroup,
    SchoolCard,
    AdsButton,
    SchoolFinder,
    AdditionalSchoolFinder,
    TopSlideTransition,
    ConsiderationsDocumentUpload
  },
  mixins: [utils],
  data() {
    const { customError } = validators
    return {
      YES_NO_OPTIONS,
      validators: { customError },
      blurLocalSchoolError: [],
      blurSelectiveError: [],
      blurOoaError: [],
      files: [],
      isFileOperationInProgress: false
    }
  },
  computed: {
    ...mapGetters([
      'eoiApplication',
      'localHighSchools',
      'ooaHighSchools',
      'supportingDocuments'
    ]),
    consideringSelectiveSchool: {
      get() {
        return this.$store.getters.consideringSelectiveSchool
      },
      set(value) {
        this.$store.dispatch('setConsideringSelectiveSchool', value)
      }
    },
    consideringOoAGovtSchools: {
      get() {
        return this.$store.getters.consideringOoAGovtSchools
      },
      set(value) {
        this.$store.dispatch('setConsideringOoAGovtSchools', value)
      }
    },
    nominatedSchools: {
      get() {
        return this.$store.getters.ooaHighSchools || []
      },
      set(schools) {
        // If new, set valid to null, else use the existing value of valid.
        // Updated preference number based on new order.
        const modifiedSchools = schools.map((school, index) => ({
          preferenceNo: index + 1,
          criteria: null,
          otherConsiderations: null,
          hasAnySiblings: null,
          siblings: null,
          ..._pick(school, [
            'schoolCode',
            'schoolName',
            'catchmentLevel',
            'capacity',
            'y67t',
            'criteria',
            'hasAnySiblings',
            'siblings',
            'website'
          ])
        }))
        this.$store.dispatch('setOoaHighSchools', modifiedSchools)
        this.$store.dispatch('updateCapacityForOOASchools')
      }
    },
    selectedLocalHighSchoolCode: {
      get() {
        return this.eoiApplication.localHighSchool
          ? this.eoiApplication.localHighSchool.schoolCode
          : null
      },
      set(value) {
        const selectedSchool = this.localHighSchools.find(
          (school) => school.school_code === value
        )
        this.$store.dispatch(
          'setSelectedLocalSecondarySchool',
          selectedSchool || {}
        )
      }
    },
    isMultipleSchools() {
      return this.localHighSchools ? this.localHighSchools.length > 1 : false
    },
    localSecondarySchoolsForDisplay() {
      return this.localHighSchools
        ? this.localHighSchools.map(this.transformSchoolForCardProps)
        : []
    },
    showOOAQuestion() {
      return this.eoiApplication && this.eoiApplication.isPaperEOI === true
    },
    residentialAddress() {
      return this.eoiApplication.residentialAddress
        ? this.addressObjectToString(this.eoiApplication.residentialAddress)
        : null
    }
  },
  created() {
    if (this.localHighSchools && this.localHighSchools.length === 1) {
      this.selectedLocalHighSchoolCode = this.localHighSchools[0].school_code
    } else {
      this.selectedLocalHighSchoolCode = null
    }
  },
  watch: {
    showOOAQuestion(newValue) {
      if (!newValue) {
        this.$store.dispatch('setConsideringOoAGovtSchools', null)
        this.removeOoaSchools()
      }
    }
  },
  methods: {
    handleBlur(input, type) {
      const selectiveSchoolErrorMsg =
        'Please choose if the parent has submitted an application for placement in an academically selective school'
      const OoaGovSchoolErrorMsg =
        'Please choose if the parent will be considering placement(s) at any other government schools besides their local high school'
      const localSchoolErrorMsg = 'Please select preferred local school'
      if (type === 'selective') {
        this.blurSelectiveError = input !== null ? [] : selectiveSchoolErrorMsg
      }
      if (type === 'ooa') {
        this.blurOoaError = input !== null ? [] : OoaGovSchoolErrorMsg
      }
      if (type === 'school') {
        this.blurLocalSchoolError = input !== null ? [] : localSchoolErrorMsg
      }
    },
    transformSchoolForCardProps(school) {
      const long = school.longitude
      const lat = school.latitude
      const addressString = this.addressComponentsToString(
        school.street,
        school['town_suburb'],
        'NSW',
        school.postcode,
        ', '
      )
      return {
        websiteText: school.website,
        websiteUrl: school.website && `https://${school.website}`,
        phoneNumber: school.phone,
        addressText: school && addressString,
        addressUrl:
          long && lat && `https://www.google.com/maps?q=${lat},${long}`,
        // eslint-disable-next-line camelcase
        schoolName: school.school_name
      }
    },
    setSelectedSchools(schools) {
      this.nominatedSchools = schools
    },
    validate() {
      if (
        this.consideringOoAGovtSchools &&
        this.nominatedSchools.length === 0
      ) {
        this.consideringOoAGovtSchools = null
        this.$store.dispatch('setValidHighSchool', false)
      } else {
        this.$store.dispatch(
          'setValidHighSchool',
          this.$refs.highSchoolDetailsForm.validate()
        )
      }
      if (this.nominatedSchools.length > 0) {
        this.supportingDocuments?.digitizeEoiConsiderations.length > 0
          ? this.$store.dispatch('setValidConsiderations', true)
          : this.$store.dispatch('setValidConsiderations', false)
      } else {
        this.$store.dispatch('setValidConsiderations', null)
      }
    },
    changeOoaSchoolOptions() {
      if (!this.consideringOoAGovtSchools && this.nominatedSchools.length > 0) {
        this.removeOoaSchools()
      }
    },
    removeOoaSchools() {
      this.$store.dispatch('setOoaHighSchools', [])
    },
    passNotification(notification) {
      this.$emit('notification', notification)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
}
.white-card.school-card {
  font-family: $digitize-eoi-font-family;
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 1.5rem 2.75rem;
  margin: 0 -1rem;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
}
.form-card {
  font-family: $digitize-eoi-font-family;
  background: $ads-white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 2rem 0;

  & > :not(hr) {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .v-divider {
    margin-top: 1rem;
    margin-bottom: 1.75rem;
  }
}
.heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  color: $ads-navy;
}
.multipleSchools {
  & ::v-deep div div div legend {
    display: none;
  }
}

.school-finder {
  & > ::v-deep *:not(.nominated-schools) {
    margin-right: 2rem;
  }

  & ::v-deep .nominated-schools li {
    padding-left: 0;
    padding-right: 2rem;
  }

  &__transition {
    margin-right: 0;
  }
}
.description {
  font-size: 1rem;
  font-weight: 500;
  color: $ads-dark;
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
</style>
