<template>
  <div>
    <v-card data-testid="noeoi">
      <v-card-title>
        <h1>{{ title }}</h1>
      </v-card-title>
      <hr />
      <div class="card-contents">
        <div class="icon iconCircleBackground mb-5">
          <v-icon size="30" class="card-icon">
            {{ icon }}
          </v-icon>
        </div>
        <v-card-subtitle v-if="subtitle" class="card-heading text-center">
          <div>
            {{ subtitle }}
          </div></v-card-subtitle
        >
        <v-card-text class="card-body">
          <div>
            {{ msg }}
          </div>
        </v-card-text>
        <v-card-actions v-if="showTargetBtn" class="justify-center mt-5">
          <AdsButton
            icon="location_searching"
            button-text="Set/Edit enrolment forecast"
            @click="$emit('open')"
          />
        </v-card-actions>
      </div>
    </v-card>
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
export default {
  name: 'NoEOI',
  components: { AdsButton },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    msg: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    showTargetBtn: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.card-heading {
  font-size: 16px;
  color: rgba(0, 0, 0, 1) !important;
  font-weight: bold;
  text-align: center;
}

.card-body {
  color: rgba(0, 0, 0, 1) !important;
  text-align: center;
}

.card-icon {
  color: #041e42;
}

.card-contents {
  font-size: 16px;
  padding: 7rem;
  width: 950px;
  margin: 0 auto;
}

.iconCircleBackground {
  border-radius: 3rem;
  width: 74px;
  height: 74px;
  background-color: $ads-light-blue;
  line-height: 3;
}

.icon {
  margin: 0 auto; // Yes, the use of "em" and "rem" is intended
  text-align: center !important;
  display: block !important;
}
</style>
