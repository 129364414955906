<template>
  <div class="px-8 pt-4 pb-2">
    <v-row>
      <v-col>
        <read-only-field
          label="Current Address (ERN)"
          v-model="residentialAddress"
          readonly
        />
      </v-col>
      <v-col>
        <read-only-field
          label="Local high school"
          v-model="schoolName"
          readonly
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <read-only-field
          label="Current address start date (ERN)"
          v-model="startDate"
          :formatter="displayDate"
          readonly
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'

import ReadOnlyField from '@/components/form/ReadOnlyField'
import { DATASET } from '@/constants'

export default {
  name: 'PnrLocationDetails',
  components: {
    ReadOnlyField
  },
  data() {
    return {
      validators: {
        required: validators.required
      },
      addressFormatter: formatters.getAddressFormatter(
        this.$store.getters.referenceData(DATASET.COUNTRIES),
        this.$store.getters.referenceData(DATASET.STATES)
      ),
      displayDate: formatters.displayDate
    }
  },
  computed: {
    ...mapGetters(['application', 'selectedSchool']),
    residentialAddress() {
      return this.addressFormatter(this.application.residentialAddress)
    },
    schoolName() {
      if (this.application.localHighSchool) {
        return this.application.localHighSchool.schoolName
      }
      return ''
    },
    startDate() {
      if (this.application.residentialAddress) {
        return this.application.residentialAddress.startDate
      }
      return null
    },
    dateReceived() {
      if (this.application.dateReceived) {
        return this.application.dateReceived.slice(0, 10)
      }
      return null
    }
  }
}
</script>
