// Taken from https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
export function numToOrdinal(number) {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const v = number % 100
  const suffix = suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]
  return `${number}${suffix}`
}

export function isIe() {
  const ua = window.navigator.userAgent
  return (
    ua.indexOf('MSIE') !== -1 || !!navigator.userAgent.match(/Trident.*rv:11\./)
  )
}

//https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
export function generateGuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function isWindowsOS() {
  return window.navigator.platform.toLocaleLowerCase() === 'win32'
}
