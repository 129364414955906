<!--
  Provides the template for a single selectable SRN match within the SRN search dialog
-->
<template>
  <button
    :class="'option unstyled ' + (isSelected ? 'isTicked' : '')"
    type="button"
    role="checkbox"
    :aria-checked="isSelected"
    @click="$emit('click')"
  >
    <div class="optionContent">
      <font-awesome-icon focusable="false" icon="check" class="checkbox" />
      <div class="group1">
        <div class="label isLinked" v-if="isLinked">
          <font-awesome-icon focusable="false" icon="link" /><span>
            Currently linked</span
          >
        </div>
        <div class="label" v-if="!isLinked">{{ label }}</div>
        <div :class="getValueCssClass('surname,firstName')">
          {{ record.surname }}, {{ record.firstName }}
        </div>
        <div class="label">Date of birth</div>
        <div :class="getValueCssClass('dateOfBirth')">{{ getDob }}</div>
      </div>
      <div class="group2">
        <div class="label">Gender</div>
        <div :class="getValueCssClass('gender')">{{ record.gender }}</div>
        <div class="label">Postcode</div>
        <div :class="getValueCssClass('postcode')">{{ record.postcode }}</div>
      </div>
      <div class="group3">
        <div class="label">Parents/carers</div>
        <div
          v-for="parent in record.parents"
          :key="parent"
          :class="getParentsCssClass(parent)"
        >
          {{ parent }}
        </div>
        <div v-if="!!record.srn" class="label">SRN</div>
        <div v-if="!!record.srn">{{ record.srn }}</div>
      </div>
      <div class="group4">
        <div v-if="!!record.schoolNYear" class="label">Current school</div>
        <div
          v-if="!!record.schoolNYear"
          :class="getValueCssClass('schoolNYear')"
        >
          {{ record.schoolNYear }}
        </div>
      </div>
    </div>
    <div v-if="compare" class="stars">
      <font-awesome-icon
        focusable="false"
        icon="star"
        :class="getStarCssClass(1)"
      />
      <font-awesome-icon
        focusable="false"
        icon="star"
        :class="getStarCssClass(2)"
      />
      <font-awesome-icon
        focusable="false"
        icon="star"
        :class="getStarCssClass(3)"
      />
    </div>
  </button>
</template>

<script>
export default {
  name: 'ApplicationSrnSearchRecord',
  props: {
    record: {
      type: Object
    },
    isLinked: {
      type: Boolean
    },
    isSelected: {
      type: Boolean
    },
    compare: {
      type: Object
    },
    label: {
      type: String
    }
  },
  computed: {
    getDob() {
      return this.$moment(this.record.dateOfBirth).format('DD/MM/YYYY')
    }
  },
  methods: {
    getParentsCssClass(parent) {
      if (
        this.compare &&
        this.compare.parents.find(
          (p) => p.toLowerCase().trim() === parent.toLowerCase().trim()
        )
      ) {
        return 'value isMatch'
      }
    },
    getValueCssClass(fields) {
      // Returns css classes to indicate if the ERN value matches the OES value
      if (this.compare) {
        let cssClass = 'value isMatch'
        // convert the date format in this.record to match the one in this.compare;
        let record = {
          ...this.record,
          ...{
            dateOfBirth: this.$moment(this.record.dateOfBirth).format(
              'YYYY-MM-DD'
            )
          }
        }
        fields.split(',').forEach((field) => {
          if (record && this.compare) {
            let fieldValue = record[field]
            if (
              (fieldValue && typeof fieldValue === 'string') ||
              fieldValue instanceof String
            ) {
              fieldValue = fieldValue.toLowerCase()
            }
            let compareValue = this.compare[field]
            if (
              (compareValue && typeof compareValue === 'string') ||
              compareValue instanceof String
            ) {
              compareValue = compareValue.toLowerCase()
            }
            if (fieldValue && fieldValue !== compareValue) {
              cssClass = 'value noMatch'
            }
          }
        })
        return cssClass
      }
      return 'value'
    },
    getStarCssClass(starIndex) {
      // Returns css classes for turning stars on or off, according to
      // criteria used in ERN.
      const match = Number(this.record.matchPercentage)
      if (starIndex === 3 && match >= 90) {
        return `star3 on`
      } else if (starIndex === 2 && match >= 83) {
        return `star2 on`
      } else if (starIndex === 1 && match >= 81) {
        return `star1 on`
      }
      return `star${starIndex} off`
    }
  }
}
</script>

<style scoped lang="scss">
.option {
  width: 100%;
  border-radius: 0.25em !important;
  margin: 0.25em 0 !important;
  background-color: $color-student-record-bg !important;
  position: relative;

  &[disabled] {
    opacity: 1 !important;
    background: transparent !important;
    cursor: default;
    svg {
      color: white !important;
    }
  }

  &.isTicked {
    background-color: $color-selected-item !important;
    color: black !important;
  }

  .optionContent {
    padding: 0.5em;
    display: flex;
    align-items: top;
  }

  .group1 {
    flex: 0.75;
  }
  .group2 {
    flex: 0.5;
  }
  .group3 {
    flex: 1;
  }
  .group4 {
    flex: 1;
  }
  .group1,
  .group2,
  .group3 {
    padding-right: 1em;
  }
  .label {
    font-size: 0.8em;
    color: $color-dark70;
    &:after {
      content: ':';
    }
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    &.isLinked {
      opacity: 1;
    }
  }
  .value.isMatch {
    font-weight: bold;
  }

  .stars {
    position: absolute;
    top: 0.25em;
    right: 0.5em;
    font-size: 0.8rem;

    .on {
      color: #ffb500;
    }
    .off {
      opacity: 0.2;
    }
  }

  .checkbox {
    font-size: 1.5rem;
    width: 1em;
    height: 1em;
    color: white;
    background-color: white;
    border-radius: 1em;
    padding: 0.2em;
    vertical-align: middle;
    margin: 1em 0.5rem 0 0;
  }
  &.isTicked .checkbox {
    color: $color-primary;
  }
}
</style>
