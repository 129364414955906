<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :max-width="dialogWidth"
    :persistent="isPersistent"
    ref="legendModal"
  >
    <v-card :role="role" class="legendModal" @keyup.esc="$emit('close')">
      <v-btn
        class="modal__close-button float-right"
        :ripple="false"
        icon
        aria-label="close"
        @click="$emit('close')"
        ref="closeButton"
      >
        <v-icon> close </v-icon>
      </v-btn>
      <div class="header heading d-flex align-left">
        <span class="legend-heading">Legend</span>
      </div>
      <div class="body">
        <div class="legend-item">
          <v-icon class="legend-active">mdi-play-circle</v-icon>
          <p class="legend-list-text">Active</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-offered">outbound</v-icon>
          <p class="legend-list-text">Offered</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-submitted">mdi-check-circle</v-icon>
          <p class="legend-list-text">Submitted</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-grey">mdi-minus-circle-outline</v-icon>
          <p class="legend-list-text">Placement not required</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-grey">hourglass_empty</v-icon>
          <p class="legend-list-text">Pending</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-grey">mdi-close-circle-outline</v-icon>
          <p class="legend-list-text">Declined or Not accepted</p>
        </div>
        <div class="legend-item">
          <v-icon class="legend-grey">not_interested</v-icon>
          <p class="legend-list-text">Inactive or Withdrawn or Offer expired</p>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Y67tModal',
  props: {
    isPersistent: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 744
    },
    footer: {
      type: Boolean,
      default: true
    },
    role: {
      type: String,
      default: 'alert'
    }
  }
}
</script>

<style scoped lang="scss">
.legend-list-text {
  display: inline;
  margin-left: 10px;
  color: $ads-dark;
  font-size: 16px;
}

.legend-heading {
  color: $ads-navy;
}

.legend-item {
  margin-bottom: 10px;
}

.v-icon.legend-active {
  color: $ads-warning-orange;
}

.v-icon.legend-submitted {
  color: $ads-success-green;
}

.v-icon.legend-offered {
  color: $ads-primary-blue;
}

.v-icon.legend-grey {
  color: $ads-dark-60;
}

.legendModal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .heading {
    display: flex;
    font-weight: 700;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3333rem;
  }

  p {
    line-height: 1.5;
  }

  .body {
    margin: 1.5rem 0;
  }
}
</style>
