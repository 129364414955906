<template>
  <v-col
    class="review-field"
    :class="{ 'review-field--long': long }"
    v-bind="attrs"
    v-on="$listeners"
  >
    <div class="field-label">{{ label }}</div>
    <div class="field-value" :class="{ 'field-value--missing': !value }">
      {{ value || missingText }}
    </div>
    <div v-if="additionalField" data-testid="additionalField-section">
      <div class="field-label" data-testid="additionalFieldLabel">
        {{ additionalFieldLabel }}
      </div>
      <div
        class="field-value"
        :class="{ 'field-value--missing': !value }"
        data-testid="additionalFieldValue"
      >
        {{ additionalFieldValue || missingText }}
      </div>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'ReviewField',
  props: {
    label: {
      type: String
    },
    value: {
      type: String
    },
    missingText: {
      type: String,
      default: '(empty)'
    },
    long: {
      type: Boolean,
      default: false
    },
    additionalField: {
      type: Boolean,
      default: false
    },
    additionalFieldLabel: {
      type: String
    },
    additionalFieldValue: {
      type: String
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        cols: 12,
        sm: this.long ? 10 : 5
      }
      return { ...defaultProps, ...this.$attrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.field-value {
  font-family: $digitize-eoi-font-family;
  color: $ads-navy;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  overflow-wrap: break-word;
}

.review-field--long {
  .field-value {
    white-space: pre-wrap;
    &:not(.field-value--missing) {
      font-family: $digitize-eoi-font-family;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: $ads-navy;
    }
  }
}
.field-label {
  font-family: $digitize-eoi-font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: $ads-dark-70;
  overflow-wrap: break-word;
  margin-bottom: 0.25rem;
}
</style>
