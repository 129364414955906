<template>
  <div>
    <v-menu
      ref="dateMenu"
      v-model="dateMenu"
      transition="scale-transition"
      :close-on-content-click="false"
      v-bind="dateMenuAttrs"
    >
      <template #activator="{ on: { click }, attrs }">
        <slot name="date" v-bind="{ click, attrs }">
          <!-- autocomplete prop disables annoying autocomplete pop ups in chrome -->
          <v-text-field
            v-model="dateText"
            :ref="inputRef"
            autocomplete="new-password"
            :label="label"
            prepend-inner-icon="mdi-calendar-range-outline"
            outlined
            :placeholder="placeholder || dateFormat.toLowerCase()"
            :rules="getRules(rules)"
            v-bind="$attrs"
            @click="click"
            :aria-describedby="messageBoxId"
            :aria-invalid="!isValid"
            @blur="handleDateTextBlur"
            @input="handleDateEntered"
            @keydown="handleSubmit"
          >
            <template #message="data">
              <div :id="messageBoxId" class="v-messages__message">
                {{ data.message }}
              </div>
            </template>
          </v-text-field>
        </slot>
      </template>
      <v-date-picker
        ref="datePicker"
        v-model="date"
        v-bind="datePickerAttrs"
        @change="handleDatePickerUpdate"
        @keydown:year="(year, e) => keyDownYear('datePicker', year, e)"
      />
    </v-menu>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import formFieldAccessibility from '@nswdoe/doe-ui-core/src/mixins/formFieldAccessibility'

const defaultDateFormat = 'DD/MM/YYYY'
const defaultMonthFormat = 'MM/YYYY'
const isoDateFormat = 'YYYY-MM-DD'
const isoMonthFormat = 'YYYY-MM'

export default {
  name: 'OesDatePicker',
  mixins: [formFieldAccessibility('datePicker')],
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: null
    },
    datePickerAttrs: {
      type: Object,
      default() {
        return {
          max: `${new Date().getFullYear() + 10}` + '-12-31'
        }
      }
    },
    dateFormat: {
      type: String,
      default() {
        return this.datePickerAttrs.type === 'month'
          ? defaultMonthFormat
          : defaultDateFormat
      }
    },
    outputDateFormat: {
      type: String,
      default() {
        return this.datePickerAttrs.type === 'month'
          ? isoMonthFormat
          : isoDateFormat
      }
    },
    yearPicker: {
      type: Boolean,
      default: false
    },

    // custom rules
    rules: {
      type: Array,
      default: () => []
    },
    // built in rules
    allowFuture: {
      type: Boolean,
      default: true
    },
    allowPast: {
      type: Boolean,
      default: true
    },
    allowToday: {
      type: Boolean,
      default: true
    },
    dateMenuAttrs: {
      type: Object,
      default() {
        return {
          'offset-y': true
        }
      }
    }
  },
  beforeCreate() {
    // required for parser
    dayjs.extend(customParseFormat)
  },
  data() {
    let notTodayMessage = 'Date cannot be today.'
    if (!this.allowToday) {
      if (!this.allowFuture) {
        notTodayMessage = 'Date must be in the past.'
      }
      if (!this.allowPast) {
        notTodayMessage = 'Date must be in the future.'
      }
    }

    return {
      date: null, // iso format 2020-08-15
      dateMenuAnchorId: `dateMenuAnchor-${this._uid}`,
      dateText: null,
      closeMenuOnBlur: false,
      originalDate: null,
      dateMenu: false,
      isoFormat:
        this.datePickerAttrs.type === 'month' ? isoMonthFormat : isoDateFormat,
      validationRules: {
        validDate: (val) => {
          // let required flag handle empty values
          const valid = !val || val === '' ? true : this.parseDate(val) != null
          // reversing the auto correction
          if (this.originalDate !== val) {
            this.$emit('input', this.originalDate)
            this.dateText = this.originalDate
          }
          return (
            valid ||
            `Date is not a valid date. Please use '${this.dateFormat.toLowerCase()}'.`
          )
        },
        required: (val) =>
          val != null || (val && val.trim() === '') || 'Required.',
        noFutureDates: (val) =>
          !this.isFuture(val) || 'Future dates are not allowed.',
        noPastDates: (val) =>
          !this.isPast(val) || 'Past dates are not allowed.',
        notToday: (val) => !this.isToday(val) || notTodayMessage
      }
    }
  },
  watch: {
    dateMenu(val) {
      if (val) {
        this.handlePickerOpened('datePicker', this.dateMenuAnchorId)
      }
      this.$emit('date-picker-activated', val)
    },
    value: {
      immediate: true,
      handler(val) {
        // value changed externally
        const dt = this.parseDate(val)
        if (dt) {
          // only accept the value if it's a valid date
          this.date = dt.format(this.isoFormat)
          this.originalDate = dt.format(this.dateFormat)
        } else if (!val) {
          // clear the date when cleared externally
          this.date = null
          this.dateText = null
        }
      }
    },
    date: {
      immediate: true,
      handler(newDate, oldDate) {
        if (newDate !== oldDate) {
          const dt = this.parseDate(newDate)
          if (dt) {
            // reformat the text
            this.dateText = dt.format(this.dateFormat)
          }
          this.$emit('input', this.outputDateFormatted)
        }
      }
    }
  },
  computed: {
    outputDateFormatted() {
      const dt = this.parseDate(this.date)
      if (dt) {
        return dt.format(this.outputDateFormat)
      }

      return this.date
    }
  },
  methods: {
    handleSubmit(event) {
      if (event.key === 'Enter') {
        this.$emit('submit')
      }
    },
    handleDateEntered(val) {
      const dt = this.parseDate(val)
      this.originalDate = val
      if (dt) {
        // sync back into pick list
        this.date = dt.format(this.isoFormat)
        // close the menu if they've typed a valid date
        this.closeMenuOnBlur = true
      }
      if (!val) {
        this.date = null
      }
    },
    handlePickerOpened(ref, menuAnchorId) {
      // Add accessibility fixes to date picker
      // wait for the menu to appear
      setTimeout(() => {
        this.$watch(`$refs.${ref}.activePicker`, (val) => {
          if (val === 'YEAR') {
            // make all the years tabbable
            const listItems = document.querySelectorAll(
              `#${menuAnchorId} ul li`
            )
            for (let i = 0; i < listItems.length; i++) {
              listItems[i].tabIndex = '0'
            }
          }
        })

        // switch to the year view
        if (this.yearPicker) {
          this.$refs[ref].internalActivePicker = 'YEAR'
        }
      })
    },
    keyDownYear(ref, year, e) {
      // add keyboard selection support in the year picker
      if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
        this.$refs[ref].yearClick(year)
      }
    },
    getRules(baseRules) {
      const rules = []
      if (this.required) {
        rules.push(this.validationRules.required)
      }

      const additionalRules = baseRules || []
      rules.push(this.validationRules.validDate, ...additionalRules)

      if (!this.allowFuture) {
        rules.push(this.validationRules.noFutureDates)
      }

      if (!this.allowPast) {
        rules.push(this.validationRules.noPastDates)
      }

      if (!this.allowToday) {
        rules.push(this.validationRules.notToday)
      }
      return rules
    },

    isFuture(dateStr) {
      const date = this.parseDate(dateStr)
      if (!date) {
        return false
      }
      return date.isAfter(dayjs(), 'day')
    },
    isPast(dateStr) {
      const date = this.parseDate(dateStr)
      if (!date) {
        return false
      }
      return date.isBefore(dayjs(), 'day')
    },
    isToday(dateStr) {
      const date = this.parseDate(dateStr)
      if (!date) {
        return false
      }

      return date.isSame(this.startToday, 'day')
    },

    parseDate(dateStr) {
      const dateFormats =
        this.datePickerAttrs.type === 'month'
          ? [this.dateFormat, 'MM/YYYY', 'MM-YYYY', 'MM YYYY', 'YYYY-MM']
          : [
              this.dateFormat,
              'DD/MM/YYYY',
              'DD-MM-YYYY',
              'DD MM YYYY',
              'YYYY-MM-DD',
              'YYYYMMDD'
            ]

      // enable dayjs strict parsing
      const date = dayjs(dateStr, dateFormats, true)
      return date.isValid() ? date : null
    },
    outputDate() {},
    handleDatePickerUpdate() {
      this.dateMenu = false
      this.$emit('blur', this.outputDateFormatted)
    },

    handleDateTextBlur() {
      if (this.closeMenuOnBlur) {
        this.closeMenuOnBlur = false
        this.dateMenu = false
      }
      this.$emit('blur', this.outputDateFormatted)

      if (this.date) {
        const dt = this.parseDate(this.date)
        if (dt) {
          // reformat the text
          this.dateText = dt.format(this.dateFormat)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-menu__content.menuable__content__active {
  min-width: 290px !important;
}
</style>
