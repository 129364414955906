<!--
  This template is displayed for every ERN record that was NOT selected by the user (i.e. discarded), during
  resolution of a LIST conflict.
  The template displays a single ERN record with a droplist of actions (e.g. remove record, replace with
  another OES record etc)
-->
<template>
  <div class="record">
    <div class="recordFields">
      <!--
      FUS-1813: Changes to eslint rules now disallow the use of
      v-if with v-for on the same element. The error for the next
      block has been disabled as the application and components
      are currently working.
      -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <span
        v-for="(field, index) in fields"
        :key="index"
        v-if="field.ernValue"
        class="recordField"
        >{{ field.ernValue }}</span
      >
      <!-- eslint-enable vue/no-use-v-if-with-v-for -->
    </div>

    <select ref="droplist" @change="onDroplistSelect($event.target.value)">
      <option value="">Select an action</option>
      <option
        v-for="(option, index) in droplistOptions"
        :key="index"
        :value="option.value"
      >
        Replace with {{ option.text }}
      </option>
      <option value="REMOVE">Remove record</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'ApplicationResolveConflictErnAction',
  props: {
    droplistOptions: {
      type: Object
    },
    fields: {
      type: Object
    },
    setFocus: {
      type: Boolean
    }
  },
  methods: {
    onDroplistSelect(value) {
      if (value === '') {
        this.$emit('change', null, null)
      } else if (value === 'REMOVE') {
        this.$emit('change', 'REMOVE', null)
      } else {
        this.$emit('change', 'REPLACE', this.droplistOptions[value].record)
      }
    }
  },
  mounted() {
    if (this.setFocus) {
      this.$refs.droplist.focus()
    }
  }
}
</script>

<style scoped lang="scss">
.record {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em;
  align-items: center;
  border-radius: 0.25em;
  margin: 0.5em 0;
  background-color: $color-student-record-bg;

  .recordField {
    font-weight: bold;
    &:after {
      content: ', ';
    }
    &:last-of-type:after {
      content: '';
    }
  }
  select {
    margin: 0.5em 0;
    background-color: white;
    border: none;
    max-width: 100%;
    width: 100%;
  }
}
</style>
