<template>
  <div :class="isAlertMessage ? 'warningMessage' : 'panel'">
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="exclamation-triangle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      class="icon svg-inline--fa fa-exclamation-triangle fa-w-18"
      :class="isAlertMessage ? 'warningSvg' : 'infoSvg'"
    >
      <path
        data-v-4c20966d=""
        fill="currentColor"
        d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
        class=""
      ></path>
    </svg>
    <div class="panel_content" v-html="content" />
  </div>
</template>

<script>
export default {
  name: 'InfoPanel',
  props: {
    content: {
      type: String,
      default: null
    },
    isAlertMessage: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.warningMessage {
  display: flex;
}
.panel {
  padding: $content-padding;
  display: flex;
  background: $color-field-background;
  border: 1px solid $color-text-body;
  border-radius: $panel-border-radius;
  color: $color-text-body;
}
.panel_content::v-deep {
  padding-left: 1rem;
  h3 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  p {
    margin: 0.3rem 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.infoSvg {
  color: $color-red;
}
.warningSvg {
  color: $color-warning-dark;
  height: 22px;
  width: 19px;
}
</style>
