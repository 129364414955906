<template>
  <div class="oes-school-finder">
    <div :id="menuAnchorID" class="menu-anchor" />
    <div id="suggestionsText" class="sr-only">
      Suggestions will appear below as you type more than three characters into
      the field.
    </div>
    <!-- Screen reader message to announce no of options available after the address search. -->
    <div class="sr-only" aria-live="assertive" role="alert">
      {{ noOfSuggestionsMsg }}
    </div>
    <v-combobox
      ref="combobox"
      :items="optionsList"
      :item-text="itemText"
      :search-input.sync="internalSearchInput"
      aria-describedby="suggestionsText"
      :rules="computedRules"
      v-bind="attrs"
      validate-on-blur
      v-on="$listeners"
      @input="handleSuggestionSelection"
      persistent-placeholder
    >
      <!-- Passes all slots/scopedSlots passed in to this component down into v-combobox -->
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </v-combobox>
  </div>
</template>

<script>
const EVENTS = {
  LOADING_SUGGESTIONS: 'loadingSuggestions',
  UPDATE_SCHOOL: 'update:school',
  UPDATE_OPTIONS: 'update:options',
  UPDATE_SEARCH_INPUT: 'update:searchInput'
}

export default {
  name: 'OesSchoolFinder',
  inheritAttrs: false,
  props: {
    suggestionsCallback: {
      type: Function,
      required: true
    },
    suggestionsCallbackDebounce: {
      type: Number,
      default: 500
    },
    maxSuggestions: {
      type: Number,
      default: 10
    },
    minLengthForSearch: {
      type: Number,
      default: 4
    },
    noMatchingText: {
      type: String
    },
    itemText: {
      type: String,
      default: 'schoolName'
    },
    searchInput: {
      type: String
    },
    required: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedSchool: null,
      optionsList: [],
      dataSearchInput: '',
      invalidSchool: false
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        attach: `#${this.menuAnchorID}`,
        'append-icon': null,
        clearable: true,
        'hide-no-data': true,
        outlined: true,
        'return-object': true,
        'prepend-inner-icon': 'search',
        'no-filter': true
      }
      return { ...defaultProps, ...this.$attrs }
    },
    menuAnchorID() {
      // eslint-disable-next-line no-underscore-dangle
      return `school-menu-anchor_${this._uid}`
    },
    internalSearchInput: {
      get() {
        return this.dataSearchInput
      },
      set(value) {
        this.dataSearchInput = value
        this.$emit(EVENTS.UPDATE_SEARCH_INPUT, value)
      }
    },
    noOfSuggestionsMsg() {
      const numOptions = this.optionsList.length
      return Number.isInteger(numOptions)
        ? `There are ${numOptions} results to choose from`
        : ''
    },
    computedRules() {
      const myRules = []

      if (this.required && this.invalidSchool) {
        myRules.push(
          'We cannot verify the school. Please clear the field and search for the school again'
        )
      }
      return myRules
    }
  },
  watch: {
    searchInput(val) {
      this.dataSearchInput = val
    },
    internalSearchInput(val) {
      if (!val) {
        this.optionsList = []
        this.invalidSchool = false
        return
      }
      //const selectedSchool = this.selectedSchool?.[this.itemText]
      const selectedSchool = this.selectedSchool
        ? this.selectedSchool[this.itemText]
        : null

      const sanitisedVal = this.sanitise(val)
      if (
        sanitisedVal.trim().length >= this.minLengthForSearch &&
        val !== selectedSchool
      ) {
        this.debounce(
          () =>
            this.handleSuggestions(
              this.suggestionsCallback,
              sanitisedVal,
              this.maxSuggestions,
              this.noMatchingText
            ),
          this.suggestionsCallbackDebounce
        )
      }
    }
  },
  methods: {
    sanitise(val) {
      return val.replace(/^\s+/, '').replace(/\s+/g, ' ')
    },
    debounce(callback, duration) {
      clearTimeout(this.debounceTimerID)
      this.debounceTimerID = setTimeout(() => {
        callback()
      }, duration)
    },
    async getSuggestions(suggestionsCallback, searchInput, maxSuggestions) {
      this.$emit(EVENTS.LOADING_SUGGESTIONS, true)
      try {
        return suggestionsCallback(searchInput).slice(0, maxSuggestions)
      } finally {
        this.$emit(EVENTS.LOADING_SUGGESTIONS, false)
      }
    },
    populateSuggestionsList(suggestions, noMatchingText) {
      if (Array.isArray(suggestions) && suggestions.length !== 0) {
        this.optionsList = suggestions
      } else if (noMatchingText) {
        this.optionsList = [{ [this.itemText]: noMatchingText, disabled: true }]
      }
      this.$emit(EVENTS.UPDATE_OPTIONS, this.optionsList)
    },
    async handleSuggestions(
      suggestionsCallback,
      searchInput,
      maxSuggestions,
      noMatchingText
    ) {
      // eslint-disable-next-line max-len
      const suggestions = await this.getSuggestions(
        suggestionsCallback,
        searchInput,
        maxSuggestions
      )
      this.populateSuggestionsList(suggestions, noMatchingText)
    },
    handleSuggestionSelection(suggestion) {
      if (suggestion && typeof suggestion === 'object') {
        this.selectedSchool = suggestion

        if (suggestion) {
          // eslint-disable-next-line max-len
          const isValid = this.optionsList.filter(
            (item) => item.orgUnitCode === suggestion.orgUnitCode
          )
          if (isValid.length) {
            this.invalidSchool = false
          } else {
            this.invalidSchool = true
          }
        }

        this.$emit(EVENTS.UPDATE_SCHOOL, this.selectedSchool)
      } else {
        // eslint-disable-next-line max-len
        // free text cannot be entered by user for SchoolType :- Aus states or territories, has to be selected from dropdown
        this.invalidSchool = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.menu-anchor {
  position: relative;
}
</style>
