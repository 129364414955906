<!--
  This template displays content for a "group" field. Group fields define a number of fields
  as a single group for the purposes of group conflict resolution.
-->
<template>
  <div
    :data-id="modelRow.id"
    :class="
      'FieldGroup' +
      (modelRow.isReadOnly ? ' isReadOnly' : '') +
      (modelRow.isConflict && !isPrintPreview ? ' isConflict' : '')
    "
  >
    <FieldGroupHeading :model-row="modelRow" />
    <div
      v-for="fieldModelRow in getChildren(modelRow)"
      :class="'columnLayout' + getFieldWidthClass(fieldModelRow)"
      :key="fieldModelRow.id"
    >
      <br v-if="fieldModelRow.type == 'LINEBREAK'" />
      <Field
        v-else-if="
          isEditing &&
          !fieldModelRow.isHidden &&
          !fieldModelRow.isReadOnly &&
          !fieldModelRow.isResolved
        "
        :model-row="fieldModelRow"
      />
      <FieldReadOnly v-else :model-row="fieldModelRow" />
    </div>
    <FieldAlert v-if="!isWithdrawn" class="FieldAlert" :model-row="modelRow" />
  </div>
</template>

<script>
import FieldGroupHeading from '@/components/form/FieldGroupHeading.vue'
import Field from '@/components/form/Field.vue'
import FieldReadOnly from '@/components/form/FieldReadOnly.vue'
import FieldAlert from '@/components/form/FieldAlert.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'FieldGroup',
  components: {
    FieldGroupHeading,
    Field,
    FieldReadOnly,
    FieldAlert
  },
  mixins: [fieldHelperMixin],
  props: {
    modelRow: {
      type: Object
    }
  }
}
</script>

<style scoped lang="scss">
.FieldGroup {
  &.isReadOnly {
    background-color: $color-disabled-section;
    margin: 0.5rem 0;
  }

  &.isConflict {
    background-color: $color-red-background;
    margin: 0.5rem 0;
  }

  .columnLayout {
    display: inline-block;
    vertical-align: top;
    width: 33.33%; // Currently giving a three column layout - TODO: Make responsive
    &.width66 {
      width: 66.66%;
    }
    &.width100 {
      width: 100%;
    }
    & > * {
      width: 100%;
    }
  }

  .FieldAlert {
    padding: 0 1em 1em;
  }
}
.Y67T .FieldGroup {
  box-shadow: 0 1px 3px #c5c5c5;
  margin: 1rem;
}
</style>
