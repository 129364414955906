<template>
  <div>
    <div>
      <v-icon class="bankIcon">mdi-bank</v-icon>
      <span class="seeText">Special Education in Ethics (SEE) options</span>
    </div>
    <div v-if="seeOptions.length === 0" class="noSeeOptionWrapper">
      <P class="noSeeOption"
        >No SEE options are currently set up. Click Add New to add a new SEE
        option.</P
      >
    </div>
    <v-form ref="seeForm" v-else class="see-option-wrapper">
      <AppSettingsSreSeeOptions
        :disabled="disabled"
        :default-sre-see-options="defaultSeeOptions"
        :options="seeOptions"
        @updatedSreSeeOptions="setSeeOptions"
        :is-see-options="true"
        ref="seeOptions"
        :is-duplicate-option="isDuplicateOption"
      />
    </v-form>
    <div class="addNewBtn">
      <AdsButton
        v-if="seeOptions.length < 5"
        secondary
        :disabled="disabled"
        button-text="Add new"
        aria-label="Add new"
        icon="mdi-plus-circle-outline"
        @click="addNewSeeOption"
      />
    </div>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import AppSettingsSreSeeOptions from './AppSettingsSreSeeOptions.vue'
import settingsUtils from '@/helpers/settingsUtils'
import API from '@/store/apiUtils'

export default {
  name: 'AppSettingsSeeOptions',
  components: { AdsButton, AppSettingsSreSeeOptions },
  data() {
    return {
      seeOptions: [],
      isDuplicateOption: false
    }
  },
  props: {
    defaultSeeOptions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    savedSeeOptions: {
      type: Array,
      default: () => []
    },
    availableScholasticYears: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.$eventHub.$on('validate', this.validate)
    if (this.savedSeeOptions?.length > 0) {
      // FUS-1813: Changes to eslint rules now disallow the mutation of
      // component props. The error for the next line has been disabled
      // as the component is currently working.
      // eslint-disable-next-line vue/no-mutating-props
      this.savedSeeOptions
        .sort((o1, o2) => (o1.name < o2.name ? -1 : 1))
        .map((saved) => {
          const option = {
            id: saved.id,
            guid: saved.guid,
            name: saved.name,
            providers: saved.providers,
            availableProviders: this.getProviders(saved.name),
            scholasticYears: saved.scholasticYears,
            availableScholasticYears: this.availableScholasticYears,
            description: saved.description,
            curriculumLink: saved.curriculumLink,
            religions: this.defaultSeeOptions.map(({ name }) => name)
          }
          this.seeOptions.push(option)
        })
    }
  },
  methods: {
    validate() {
      if (this.$refs.seeForm) {
        this.isDuplicateOption = settingsUtils.checkDuplicateOption(
          this.seeOptions
        )
        this.$emit('duplicateSeeOption', this.isDuplicateOption)
        this.$refs.seeForm.validate()
      }
    },
    getProviders(religion) {
      const options = this.defaultSeeOptions.find(
        (option) => option.name === religion
      )
      return options?.providers || []
    },
    addNewSeeOption() {
      const option = {
        id: '',
        guid: API.getGuid(),
        name: '',
        providers: [],
        availableProviders: [],
        scholasticYears: [],
        availableScholasticYears: this.availableScholasticYears,
        description: '',
        curriculumLink: '',
        availableReligions: [],
        religions: this.defaultSeeOptions.map(({ name }) => name)
      }

      this.isDuplicateOption = settingsUtils.checkDuplicateOption(
        this.seeOptions
      )

      this.$emit('duplicateSeeOption', this.isDuplicateOption)
      this.$nextTick(() => {
        this.seeOptions.length === 0 ||
        (this.seeOptions.length > 0 &&
          this.$refs.seeForm.validate() &&
          this.seeOptions.every(
            (option) => option.scholasticYears.length > 0
          ) &&
          !this.isDuplicateOption)
          ? this.seeOptions.push(option)
          : this.seeOptions

        if (this.seeOptions.length > 0) {
          const lastIndex = this.seeOptions.length - 1
          this.focusOnSeeOptionsChanged(lastIndex)
        }
      })
    },
    setSeeOptions(options, isSeeOptions = true) {
      this.isDuplicateOption = settingsUtils.checkDuplicateOption(
        this.seeOptions
      )
      this.$emit('duplicateSeeOption', this.isDuplicateOption)
      this.$emit('updatedSeeOptions', options, isSeeOptions)
    },
    focusOnSeeOptionsChanged(lastIndex) {
      this.$nextTick(() => {
        const seeOptionsRefs = this.$refs.seeOptions.$refs
        if (seeOptionsRefs) {
          const sreSeeOptionRemoveBtnRefName =
            'sreSeeOptionRemoveBtn-' + lastIndex
          const nestedElementRefs = seeOptionsRefs[sreSeeOptionRemoveBtnRefName]
          if (nestedElementRefs && nestedElementRefs.length > 0) {
            const elementRef = nestedElementRefs[0]
            if (elementRef) {
              elementRef.$el.focus()
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bankIcon {
  border-radius: 50%;
  background: $ads-light-blue;
  color: $ads-navy;
  min-width: 46px;
  height: 46px;
}
.seeText {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}
.addNewBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.noSeeOption {
  box-shadow: 0px 1px 4px $ads-light-40;
  border-radius: 4px;
  padding: 32px 0 38px 54px;
  margin-top: 2.5rem;
}

.addNewBtn,
.see-option-wrapper,
.noSeeOptionWrapper {
  max-width: 808px;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
