<!-- 
  Provides a dialog showing a "Sending to ERN" spinner, which changes
  to a confirmation message once sent. 
-->

<template>
  <v-dialog value="true" persistent width="500">
    <v-card>
      <v-card-title class="primary--text">
        <v-avatar color="secondary" size="48" class="mr-2">
          <v-icon color="white" size="28">$vuetify.icons.send</v-icon>
        </v-avatar>

        <span v-if="isSentToErn">Sending complete</span>
        <span v-else>Sending to ERN</span>
        <v-btn
          v-if="isSentToErn"
          @click="close"
          class="ma-0 primary--text"
          right
          absolute
          fab
          small
          color="white"
          depressed
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="text-center">
        <v-icon v-if="isSentToErn" color="primary" size="100"
          >check_circle_outline</v-icon
        >
        <v-progress-circular
          v-else
          indeterminate
          color="primary"
          size="100"
          width="6"
          center
        ></v-progress-circular>
      </v-card-text>

      <v-card-text v-if="isSentToErn" class="text-center primary--text">
        <strong>The application has been successfully sent to ERN!</strong>
        <v-btn
          @click="backToApplications"
          height="auto"
          color="secondary"
          class="mt-5"
          depressed
        >
          <v-icon class="mr-2">$vuetify.icons.layers</v-icon>Back to
          applications
        </v-btn>
      </v-card-text>

      <v-card-text v-else class="text-center primary--text">
        This can take up to a minute. Please stand by.
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SendToErnProgress',
  computed: {
    isSentToErn() {
      return this.$store.getters.isSentToErn
    }
  },
  methods: {
    close() {
      this.$store.dispatch('set', ['isSendingToErn', false])
    },
    backToApplications() {
      this.close()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss"></style>
