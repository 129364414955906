import { STREAM } from '../constants'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['currentStream']),
    currentIdKey() {
      if (this.currentStream === STREAM.SELECTIVE_OFFERS) {
        return 'shsOfferId'
      }
      return 'applicationID'
    }
  },
  methods: {
    setFilteredIds(items) {
      const ids = items.map((item) => item[this.currentIdKey])

      this.$store.dispatch('set', [
        'filteredApplicationIds',
        ids,
        this.currentIdKey
      ])
    }
  }
}
