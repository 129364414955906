export const disableVuetifyClickFn = function (vComponent) {
  return vComponent.extend({
    methods: {
      generateRouteLink() {
        return {
          tag: this.tag,
          data: {
            attrs: {
              tabindex: undefined
            },
            class: this.classes,
            directives: []
          }
        }
      }
    }
  })
}
