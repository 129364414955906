import Vue from 'vue'
import API from '@/store/apiUtils'

import { GENERIC_SERVER_ERROR } from '@/constants'

import FIELD from '@/helpers/fieldHelper'

export const shsApiApplicationUrl = '/v1/shs/applications/'

const shsOffersModule = {
  state: {
    shsOffers: {},
    shsOffer: {},
    filteredShsOffers: [],
    shsApiApplicationUrl
  },
  getters: {
    shsOffers: (state) => (schoolId) => {
      return state.shsOffers[schoolId] || []
    },

    shsOffer: (state) => state.shsOffer,

    getFilteredShsOffers: (state) => {
      return state.filteredShsOffers
    }
  },
  mutations: {
    setShsOffers(state, { schoolId, shsOffers }) {
      if (!state.shsOffers[schoolId]) {
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.shsOffers, schoolId, [])
      }

      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.shsOffers, schoolId, shsOffers)
    },
    setFilteredShsOffers(state, filteredShsOffers) {
      state.filteredShsOffers = filteredShsOffers
    },
    setShsOffer(state, shsOffer) {
      state.shsOffer = shsOffer
    }
  },
  actions: {
    loadShsOffers({ commit, getters, state, dispatch }, payload = {}) {
      const school = getters.selectedSchool
      const schoolCode = payload.payloadSchoolCode || school?.schoolCode
      if (!schoolCode) {
        return Promise.resolve()
      }

      return API.get(
        `${process.env.VUE_APP_API_PI}${state.shsApiApplicationUrl}${schoolCode}`,
        false,
        { schoolCode }
      )
        .then((res) => {
          return res?.data
        })
        .then((res) => {
          const shsOffers = res?.body
          commit('setShsOffers', {
            schoolId: school.schoolCode,
            shsOffers
          })
        })
        .catch((data) => {
          if (data?.response?.status === 400) {
            dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2><p>${GENERIC_SERVER_ERROR}</p>`
            })
            return
          }
          return data
        })
    },
    getShsOffer(_, [id, schoolCode]) {
      const apiHeaders = { schoolCode }
      let apiUrl = `${process.env.VUE_APP_API_PI}/v1/shs/applications/${schoolCode}/${id}`

      return API.get(apiUrl, true, apiHeaders)
    },

    getShsOfferForSummary(store, [id, schoolCode]) {
      FIELD.setStore(store)

      return new Promise((resolve) => {
        store.commit('set', ['application', null])

        store.dispatch('showSpinner')

        return Promise.all([
          store.dispatch('getReferenceData'),
          store.dispatch('getShsOffer', [id, schoolCode])
        ])
          .then((data) => {
            const referenceData = data[0]
            const application = data[1].data.body

            store.commit('set', ['referenceData', referenceData])
            store.commit('set', ['application', application])
            resolve()
          })
          .catch(() => {
            store.dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2><p>${GENERIC_SERVER_ERROR}</p>`
            })
            resolve()
          })
          .finally(() => {
            store.dispatch('hideSpinner')
          })
      })
    },

    updateShsOfferEnrolmentStatus(
      { dispatch, rootState },
      {
        shsOffer: { shsOfferId: shsOfferId, schoolCode: schoolCode },
        payload: { status: enrolmentStatus, isFromCore = false }
      }
    ) {
      const apiHeaders = { schoolCode }
      const apiUrl = `${process.env.VUE_APP_API_PI}/v1/shs/status/${schoolCode}/${shsOfferId}`

      dispatch('showSpinner')

      return API.post(apiUrl, { enrolmentStatus }, true, apiHeaders)
        .then(({ status }) => {
          if (
            status === 200 &&
            rootState.application?.shsOfferId === shsOfferId &&
            !isFromCore
          ) {
            dispatch(
              'set',
              ['application.applicationStatus', enrolmentStatus?.toUpperCase()],
              { root: true }
            )
          }
        })
        .finally(() => {
          dispatch('hideSpinner')
        })
    }
  }
}

export default shsOffersModule
