// traverse all child elements looking for vuetify form errors
// or errors created via the validatableMixin
export const getFormErrors = (form) => {
  const errorInputs = []
  const search = (children, parentSections, depth = 0) => {
    const sections = [...parentSections]
    if (children) {
      for (let index = 0; index < children.length; index++) {
        const child = children[index]

        // add another nested section if applicable
        if (child.$attrs && child.$attrs['validation-section-name']) {
          sections.push(child.$attrs['validation-section-name'])
        }

        // push if error
        if (child.errorBucket !== undefined && child.hasError) {
          errorInputs.push({
            sections: [...sections],
            label: child.label || child.sectionLabel,
            error: child.errorBucket && child.errorBucket[0],
            element: child
          })
        }
        search(child.$children, sections, depth + 1)
      }
    }
    if (depth === 0) return errorInputs
  }
  return search(form.$children, [])
}

// traverse child elements to find a validatable element
export const findValidatableElement = (baseElement) => {
  const search = (el) => {
    if (!el || (el && el.validate)) {
      return el
    }
    for (let c of el.$children) {
      const foundEl = search(c)
      if (foundEl) {
        return foundEl
      }
    }
    return null
  }
  return search(baseElement)
}

// traverse child elements to find a validatable element
export const focusOnFirstError = (form) => {
  const search = (el) => {
    if (el.errorBucket !== undefined && el.hasError && el.focus) {
      // return error element if it's focusable
      return el
    }

    if (el.$children) {
      for (let c of el.$children) {
        const el = search(c)
        // return element if found, otherwise go to next child
        if (el) {
          return el
        }
      }
    }
    return null
  }

  const focusable = search(form)
  if (focusable) {
    setTimeout(() => {
      focusable.focus()
    }, 200)
  }
}
