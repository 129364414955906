<template>
  <FocusTrap is-active>
    <v-dialog value="true" persistent width="500">
      <v-card class="pa-4">
        <v-btn
          @click="close"
          class="ma-0 primary--text unlink-close-btn"
          right
          absolute
          fab
          small
          color="white"
          depressed
          aria-label="Close Unlink SRN dialog"
          id="btn-close-unlinksrn"
        >
          <v-icon>close</v-icon>
        </v-btn>

        <v-container class="pl-5 pr-5 pb-0 pt-0">
          <v-row justify="start" align="center">
            <v-col cols="1" class="align-center justify-center d-flex pa-0">
              <v-avatar color="secondary" size="40">
                <v-icon color="white" size="24">mdi-link-off</v-icon>
              </v-avatar>
            </v-col>
            <v-col cols="11">
              <h1>Are you sure you want to unlink this SRN?</h1>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="11">
              <p>
                Unlinking this SRN will withdraw this application's registration
                in ERN. Any resolved ERN conflicts will be reverted to their
                previous state.
              </p>
              <p>
                You will need to repeat the SRN check process to proceed with
                this application.
              </p>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="red" class="unlink-btn" @click="unlinkSrn">
              Unlink SRN
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import UTILS from '@/store/utils'

export default {
  name: 'UnlinkSrn',
  components: {
    FocusTrap
  },
  computed: {
    ...mapGetters(['application', 'oesProperties'])
  },
  methods: {
    close() {
      this.$store.dispatch('set', ['openUnlinkSrnModal', false])
    },
    unlinkSrn() {
      const params = {
        SRN: this.application.student.srn,
        unlinkSRN: true
      }
      if (
        UTILS.isNextCalendarYear7LocalApp(this.application, this.oesProperties)
      ) {
        // Call unstampedLocalApplication API before unLinking SRN...
        // unlinkSrn is called within
        this.$store.dispatch('y67tCoreLinkUnlinkSrn', params)
        this.$store.dispatch('set', ['openUnlinkSrnModal', false])
      } else if (
        UTILS.isNextCalendarYear7OoaApp(this.application, this.oesProperties)
      ) {
        this.$store.dispatch('y67tOoaLinkUnlinkSrn', params)
        this.$store.dispatch('set', ['openUnlinkSrnModal', false])
      } else {
        this.$store.dispatch('unlinkSrn')
        this.$store.dispatch('set', ['openUnlinkSrnModal', false])
      }
    }
  }
}
</script>

<style scoped lang="scss">
button.v-btn.unlink-close-btn {
  border: none;
  &:focus {
    box-shadow: 0 0 0 2px $color-focus !important;
  }
}
button.v-btn.unlink-btn {
  border: none;
  &:focus {
    box-shadow: 0 0 0 4px $color-red-border;
  }
}
</style>
