import { SHS_APPLICATION_STATUS_CHIPS, SHS_STATUS } from '@/constants'

export const mixStatuses = {
  data() {
    return {
      shsStatusChips: SHS_APPLICATION_STATUS_CHIPS
    }
  },
  methods: {
    getStatusChipDetails(status, chipsArray = this.shsStatusChips) {
      const key = Object.keys(SHS_STATUS).find(
        (key) => SHS_STATUS[key] === status
      )

      return chipsArray[key]
    }
  }
}
