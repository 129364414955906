<template>
  <div class="px-8 pt-4 pb-2">
    <v-row>
      <v-col v-for="school in ooaHighSchools" :key="school.schoolCode">
        <read-only-field
          :label="`School ${school.preferenceNo}:`"
          v-model="school.schoolName"
          readonly
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'

import ReadOnlyField from '@/components/form/ReadOnlyField'

export default {
  name: 'OoaSchoolChoices',
  components: {
    ReadOnlyField
  },
  data() {
    return {
      validators: {
        required: validators.required
      }
    }
  },
  computed: {
    ...mapGetters(['application']),
    ooaHighSchools() {
      return this.application.ooaHighSchools
    }
  }
}
</script>
