<template>
  <div v-show="this.initialised">
    <!-- v-on:submit.prevent handles submit on pressing the Enter key -->
    <v-form ref="ooaForm" @submit.prevent="save()">
      <ads-expansion-panel :items="panels" v-model="openPanels">
        <template slot="contentStudentDetails">
          <v-form
            ref="studentDetailsForm"
            v-model="studentValid"
            @submit.prevent="save()"
            validation-section-name="Student details"
          >
            <student-details
              :readonly="isOoaApplicationReadOnly || isArchived"
            />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="sumbit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentParentCarers">
          <v-form
            ref="parentCarerForm"
            v-model="parentCarerValid"
            @submit.prevent="save()"
            validation-section-name="Parent / Carers"
          >
            <parent-carers :readonly="isOoaApplicationReadOnly || isArchived" />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="sumbit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentSiblings">
          <siblings />
        </template>
        <template slot="contentConsiderations">
          <considerations />
        </template>
      </ads-expansion-panel>
      <!-- v-on:reset.prevent in the v-form wasn't working, so I have to handle it here -->
      <save-cancel v-if="isEditing" @cancel="cancel" />
    </v-form>
    <errors-dialog
      v-model="showErrors"
      :errors="errors"
      @close="closeErrorsDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'
import { getFormErrors, focusOnFirstError } from '@/helpers/form'

import StudentDetails from './StudentDetails.vue'
import ParentCarers from './ParentCarers.vue'
import Siblings from './Siblings.vue'
import Considerations from './Considerations.vue'

import ErrorsDialog from './ErrorsDialog.vue'
import SaveCancel from '@/components/form/SaveCancel'
import { STREAM, OOA_STATUSES_FOR_OPT_OUT_EMAIL } from '@/constants'

export default {
  name: 'OoaForm',
  components: {
    AdsExpansionPanel,
    SaveCancel,
    StudentDetails,
    ParentCarers,
    Siblings,
    Considerations,
    ErrorsDialog
  },
  data() {
    return {
      studentValid: true,
      parentCarerValid: true,
      openPanels: [],
      initialised: false,
      errors: [],
      showErrors: false,
      test: ''
    }
  },
  computed: {
    ...mapGetters([
      'application',
      'isEditing',
      'referenceData',
      'isOoaApplicationReadOnly',
      'currentStream'
    ]),
    panels() {
      const errorProps = { headerClass: 'sectionError', icon: 'error_outline' }
      const sections = [
        {
          id: 'StudentDetails',
          title: 'Student details',
          ...(this.studentValid ? {} : errorProps)
        },
        {
          id: 'ParentCarers',
          title: 'Parent / Carers',
          ...(this.parentCarerValid ? {} : errorProps)
        },
        {
          id: 'Siblings',
          title: 'Siblings'
        },
        {
          id: 'Considerations',
          title: 'Considerations'
        }
      ]
      return sections
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    }
  },
  mounted() {
    // open and close all the panels to ensure they are rendered so we can run pre-validation
    this.openPanels = [...Array(this.panels.length).keys()]
    this.$nextTick(() => {
      this.openPanels = []

      // give the animation a chance to complete
      setTimeout(() => {
        this.initialised = true
      }, 100)
    })
  },
  methods: {
    save() {
      // A work around to update the sendPCEmail field
      const canUpdateSendPCEmail = OOA_STATUSES_FOR_OPT_OUT_EMAIL.includes(
        this.application.applicationStatus
      )
      if (this.isOoaApplicationReadOnly && !canUpdateSendPCEmail) {
        return
      }
      this.validate()
      if (this.errors.length) {
        this.showErrors = true
      } else {
        this.$store.dispatch('saveOoaApplication')
      }
    },
    cancel() {
      this.$store.dispatch('cancelApplication')
    },
    stopEdit() {
      this.$store.commit('set', ['isEditing', false])
    },
    validate() {
      if (this.$refs.ooaForm) {
        this.$refs.ooaForm.validate()
      }
      if (this.$refs.studentDetailsForm) {
        this.$refs.studentDetailsForm.validate()
      }
      if (this.$refs.parentCarerForm) {
        this.$refs.parentCarerForm.validate()
      }
      if (this.$refs.ooaForm) {
        this.errors = getFormErrors(this.$refs.ooaForm)
      }
    },
    closeErrorsDialog() {
      this.showErrors = false
      focusOnFirstError(this.$refs.ooaForm)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header.sectionError {
  span i {
    color: $ads-error-red;
  }
  span {
    color: $ads-error-red;
    font-weight: bold;
  }
}
.col {
  padding: 0px;
}
</style>
