<template>
  <div>
    <v-divider />
    <div class="mx-6 mx-md-12 pt-6">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30" class="material-icons-outlined"
            >directions_bus</v-icon
          >
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">
            Parent communications - out of area enrolments
          </h3>
        </div>
      </div>
      <p class="app-settings__subheading pt-4 pb-2">
        Enrolments for this year will be responded to:
      </p>
      <div
        class="d-flex align-start mb-2"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <AdsSelect
          @change="$emit('updatedCurrentYearTimeFrame', $event)"
          :value="currentYearTimeFrame ? currentYearTimeFrame.value : ''"
          :items="currentYearTimeFrames"
          item-text="value"
          return-object
          label="Current time frame"
          :outlined="true"
          :readonly="isSuperAdmin"
          placeholder="Current time frame"
          class="app-settings__time-frames-input mr-12"
          aria-labelledby="currentYearTimeFramePreview"
          persistent-placeholder
        />
        <div v-if="currentYearTimeFrame" class="app-settings__preview">
          <p><strong>Preview:</strong></p>
          <p
            class="app-settings__preview-text app-settings__paragraph-text"
            id="currentYearTimeFramePreview"
          >
            {{ currentYearTimeFrame.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="mx-6 mx-md-12">
      <p class="app-settings__subheading pb-2">
        Enrolments for next year will be responded to:
      </p>
      <div
        class="d-flex align-start pb-12"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <AdsSelect
          @change="$emit('updatedNextYearTimeFrame', $event)"
          :value="nextYearTimeFrame ? nextYearTimeFrame.value : ''"
          :items="nextYearTimeFrames"
          item-text="value"
          return-object
          label="Current time frame"
          :outlined="true"
          :readonly="isSuperAdmin"
          class="app-settings__time-frames-input mr-12"
          placeholder="Current time frame"
          aria-labelledby="nextYearTimeFramePreview"
          persistent-placeholder
        />
        <div v-if="nextYearTimeFrame" class="app-settings__preview">
          <p><strong>Preview:</strong></p>
          <p
            class="app-settings__preview-text app-settings__paragraph-text"
            id="nextYearTimeFramePreview"
          >
            {{ nextYearTimeFrame.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AdsSelect } from '@nswdoe/doe-ui-core'
import settingsUtils from '@/helpers/settingsUtils'

export default {
  name: 'AppSettingsParentCommsOOA',
  components: { AdsSelect },
  props: {
    currentYearTimeFrame: {
      type: Object,
      default: null
    },
    currentYearTimeFrames: {
      type: Array,
      default: () => []
    },
    nextYearTimeFrame: {
      type: Object,
      default: null
    },
    nextYearTimeFrames: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    }
  }
}
</script>

<style lang="scss" scope>
.app-settings__time-frames-input {
  width: 280px;
  max-width: 415px;
  .v-label {
    font-weight: 700;
    color: $ads-navy;
  }
}

.app-settings__preview {
  background: $ads-light-10;
  padding: 16px;
  border-radius: 4px;
  .app-settings__preview-text {
    font-style: italic;
  }
}
</style>
