<!--
  Provides the template for a single selectable SRN match within the SRN search dialog
-->
<template>
  <v-radio-group
    v-model="selectedSrn"
    class="option unstyled"
    aria-labelledby="Match this student"
  >
    <div
      v-for="(record, index) in records"
      class="studentMatchPanel"
      :class="isSelected(record)"
      :key="index"
      @click="setSelectedRecord(record)"
    >
      <v-row>
        <v-col cols="1">
          <v-radio
            :aria-label="'select' + record.surname + ',' + record.firstName"
            class="ml-3"
            :value="record.srn"
            data-testid="selected-srn"
          />
        </v-col>
        <v-col cols="9">
          <div class="ml-n7">
            <div class="label isLinked" v-if="isLinked(record)">
              <font-awesome-icon focusable="false" icon="link" />
              <span> Currently linked</span>
            </div>
            <div class="studentName" data-testid="student-name">
              <strong>{{ record.surname }}, {{ record.firstName }}</strong>
            </div>
          </div>
        </v-col>
        <v-col cols="2">
          <div v-if="compare" class="stars pt-2 mr-2">
            <span class="ml-n7 helpText">Relevance: </span>
            <font-awesome-icon
              focusable="false"
              icon="star"
              :class="getStarCssClass(record, 1)"
              data-testid="star-1"
            />
            <font-awesome-icon
              focusable="false"
              icon="star"
              :class="getStarCssClass(record, 2)"
              data-testid="star-2"
            />
            <font-awesome-icon
              focusable="false"
              icon="star"
              :class="getStarCssClass(record, 3)"
              data-testid="star-3"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="1"></v-col>
        <v-col class="ml-n7">
          <div class="label">Gender</div>
          <div
            :class="[
              record.gender === compare.gender ? 'matched' : '',
              'value'
            ]"
          >
            {{ record.gender }}
          </div>
        </v-col>
        <v-col>
          <div class="label">Date of Birth</div>
          <div :class="getDobClass(record)" data-testid="date-of-birth">
            {{ getDob(record) }}
          </div>
        </v-col>
        <v-col>
          <div class="label">Scholastic Year</div>
          <div :class="getSyClass(record)">{{ getScholasticYear(record) }}</div>
        </v-col>
        <v-col>
          <div class="label">Parents/Carer</div>
          <div
            v-for="(parent, pcindex) in record.parents"
            :key="pcindex"
            :class="getPcClass(parent, pcindex)"
            data-testid="parent-names"
          >
            {{ parent }}
          </div>
        </v-col>
        <v-col>
          <div class="label">Postcode</div>
          <div
            :class="[
              record.postcode === compare.postcode ? 'matched' : '',
              'value'
            ]"
          >
            {{ record.postcode }}
          </div>
        </v-col>
      </v-row>
    </div>
  </v-radio-group>
</template>

<script>
export default {
  name: 'SrnMatchSearchRecord',
  props: {
    records: {
      type: Array
    },
    compare: {
      type: Object
    },
    label: {
      type: String
    },
    currentSrn: {
      type: [Number, String]
    }
  },
  data() {
    return {
      selectedSrn: this.currentSrn
    }
  },
  methods: {
    isSelected(record) {
      if (record.srn === this.selectedSrn) {
        return 'isTicked'
      }
      return ''
    },
    isLinked(record) {
      if (record.srn === this.currentSrn) {
        return true
      }
      return false
    },
    getDob(record) {
      return this.$moment(record.dateOfBirth).format('DD/MM/YYYY')
    },
    getScholasticYear(record) {
      return record.schoolNYear.split(',')[1]
    },
    getStarCssClass(record, starIndex) {
      // Returns css classes for turning stars on or off, according to
      // criteria used in ERN.
      const match = Number(record.matchPercentage)
      if (starIndex === 3 && match >= 90) {
        return `star3 on`
      } else if (starIndex === 2 && match >= 83) {
        return `star2 on`
      } else if (starIndex === 1 && match >= 81) {
        return `star1 on`
      }
      return `star${starIndex} off`
    },
    setSelectedRecord(record) {
      this.selectedSrn = record.srn
      this.$emit('click', record)
    },
    getSyClass(record) {
      const year = this.getScholasticYear(record)
        ? this.getScholasticYear(record).trim()
        : ''
      const studentYear = this.compare ? this.compare.schYear.trim() : ''
      if (year === studentYear) {
        return 'matched value'
      }
      return 'value'
    },
    getDobClass(record) {
      const dob = this.getDob(record)
      const studentDob = this.compare
        ? this.$moment(this.compare.dateOfBirth).format('DD/MM/YYYY')
        : null
      if (dob === studentDob) {
        return 'matched value'
      }
      return 'value'
    },
    getPcClass(parent) {
      const parentName = parent.trim()
      const comapreParentName = this.compare
        ? this.compare.parents[0].trim()
        : ''
      if (parentName === comapreParentName) {
        return 'matched value'
      }
      return 'value'
    }
  }
}
</script>

<style scoped lang="scss">
.option {
  width: 100%;
  border-radius: 0.25em;
  margin: 0.25em 0;
  position: relative;

  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }

  .studentMatchPanel {
    background-color: $grey-light-10;
    border-radius: 4px;
    margin-bottom: 20px;
  }

  .isTicked {
    background-color: $color-selected-item;
    color: black;
    border: 2px solid $color-primary;
  }

  .studentName {
    font-size: 1.375em;
    color: $color-primary;
    font-weight: 700;
  }

  .helpText {
    font-size: 0.875rem;
    color: $grey-1;
  }

  .label {
    font-size: 0.875rem;
    padding-bottom: 5px;
    color: $color-primary;
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    margin-left: 0;
  }

  .value {
    font-size: 1rem;
    color: $color-primary;
  }
  .value.matched {
    color: $color-primary;
    font-weight: bold;
  }

  .stars {
    font-size: 0.8rem;
    .on {
      color: $color-primary-lighten-1;
    }
    .off {
      opacity: 0.2;
    }
  }

  .checkbox {
    font-size: 1.5rem;
    width: 1em;
    height: 1em;
    color: white;
    background-color: white;
    border-radius: 1em;
    padding: 0.2em;
  }

  &.isTicked .checkbox {
    color: $color-primary;
  }

  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }

  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
</style>
