<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <v-row>
      <ReviewField
        data-testid="localSchool"
        sm="10"
        label="Preferred local school"
        :value="schoolName"
      />
    </v-row>
    <hr class="ml-n7 mr-n3" />
    <v-row>
      <ReviewField
        data-testid="localSchool-selective"
        sm="10"
        label="Has the parent submitted an application for placement in an academically selective school?"
        :value="consideringSelectiveSchoolVal"
      />
    </v-row>
    <v-row v-if="showOOAQuestion">
      <ReviewField
        data-testid="localSchool-ooa"
        sm="10"
        label="Will the parent be considering placement(s) at any other government schools besides their local high school?"
        :value="consideringOoAGovtSchoolsVal"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewField from '@/components/application/y67t/paperEoI/eoi/Review/ReviewField'

export default {
  name: 'SchoolView',
  components: {
    ReviewField
  },
  computed: {
    ...mapGetters([
      'eoiApplication',
      'consideringOoAGovtSchools',
      'consideringSelectiveSchool'
    ]),
    schoolName() {
      return (
        this.eoiApplication.localHighSchool &&
        this.eoiApplication.localHighSchool.schoolName
      )
    },
    consideringOoAGovtSchoolsVal() {
      if (this.consideringOoAGovtSchools !== null) {
        return this.consideringOoAGovtSchools ? 'Yes' : 'No'
      }
      return null
    },
    consideringSelectiveSchoolVal() {
      if (this.consideringSelectiveSchool !== null) {
        return this.consideringSelectiveSchool ? 'Yes' : 'No'
      }
      return null
    },
    showOOAQuestion() {
      return this.eoiApplication && this.eoiApplication.isPaperEOI === true
    }
  }
}
</script>

<style lang="scss" scoped></style>
