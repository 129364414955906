<template>
  <div
    class="displayField"
    :class="{ readonly }"
    :tabindex="readonly ? -1 : 0"
    v-on="$listeners"
    @keydown="handleKeyDown"
  >
    <label>{{ label }}</label>
    <p class="value mt-2">{{ formattedValue }}</p>
    <div class="editIcon" v-if="!readonly">
      <v-icon size="20px">mdi-pencil</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadOnlyField',
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      default: null,
      type: [String, Boolean]
    },
    readonly: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: null
    }
  },
  computed: {
    formattedValue() {
      return this.formatter ? this.formatter(this.value) : this.value
    }
  },
  methods: {
    handleKeyDown(e) {
      if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
        e.preventDefault()
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  color: $color-text-light;
  font-size: $small-text;
}
.value {
  min-height: 1rem;
}

.editIcon {
  position: absolute;
  top: 7px;
  right: 7px;
  display: none;
  &:focus {
    display: block;
    border: 2px solid $ads-navy;
  }
}

.displayField:not(.readonly) {
  position: relative;
  cursor: pointer;
  label {
    cursor: pointer;
  }
  &:hover,
  &:focus {
    background-color: $ads-light-10;
    .editIcon {
      display: block;
    }
  }
}

.displayField.readonly {
  cursor: not-allowed;
  label {
    cursor: not-allowed;
  }
}

.isEditing .displayField {
  padding-top: 0px !important;
}
</style>
