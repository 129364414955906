<!--
  Provides an input field and droplist, where the caller populates the droplist options
  as per their requirements. Ideally suited to displaying a list of matches from an api.

  Works in a similar way to HTML5 Datalist (which was unusable because the droplist would
  not always show current state when being set dynamically).

  PROPS:
    value <string> - Initial text value for the input
    options <array> - Array of strings to appear in the droplist
    placeholder <string> - Placeholder text for the input
    uppercase <boolean> - If true, input displays and returns uppercase
    showSpinner <boolean> - If true, show spinner while typing (until options are populated)
    maxlength <integer> - If provided, this is the max length of the type ahead input field
    minlength <integer> - If provided, this is the number of characters required to trigger a search
    maxDroplistHeight <string> - If provided, this is the maximum height of the droplist with units, e.g. "10em"

  EVENTS:
    input(value) - Fires when the input value changes
    search(value) - Fires when ready to search (i.e. when there is a slight pause in the user's typing)
    select(index) - Fires when a droplist item is selected, sending the index of the selected item

-->
<template>
  <div>
    <input
      v-model="currentText"
      @input="onInput"
      @focus="isLookupFocused = true"
      @blur="isLookupFocused = false"
      @keydown.down.prevent="moveSelectionDown()"
      @keydown.up.prevent="moveSelectionUp()"
      @keydown.enter.prevent="selectItem(selectedIndex)"
      :class="getInputClasses"
      :placeholder="placeholder"
      :maxlength="maxlength"
      ref="input"
      type="text"
    />

    <div v-if="showDroplist" class="AppTypeAhead_options" ref="droplist">
      <div :class="'spinner ' + (isLoading ? 'isLoading' : '')">
        <font-awesome-icon focusable="false" class="icon" icon="circle-notch" />
        Searching...
      </div>
      <div
        v-for="(option, index) in options"
        :key="index"
        @mousedown="selectItem(index)"
        :class="index === selectedIndex ? 'selected' : ''"
      >
        {{ option }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TypeAhead',
  props: {
    value: {
      type: String
    },
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    uppercase: {
      type: String
    },
    maxlength: {
      type: String
    },
    minlength: {
      type: String
    },
    showSpinner: {
      type: Boolean
    },
    maxDroplistHeight: {
      type: String
    }
  },
  data() {
    return {
      isLoading: false,
      currentText: '',
      isLookupFocused: false,
      selectedIndex: null,
      keystrokeTimer: null,
      loadingTimer: null,
      keyboardDelay: 300 // This is the time between the user finishing typing and the search() event being fired. 300 (0.3s) seems good. Any lower means too many api requests. Any higher means a sluggish UX.
    }
  },
  computed: {
    showDroplist() {
      return this.isLookupFocused && (this.options.length || this.isLoading)
    },
    getCurrentText() {
      return this.uppercase ? this.currentText.toUpperCase() : this.currentText
    },
    getInputClasses() {
      var classes = ['TypeAhead']
      if (this.uppercase) {
        classes.push('uppercase')
      }
      if (this.isLoading) {
        classes.push('isLoading')
      }
      return classes.join(' ')
    }
  },
  methods: {
    onInput() {
      // keystrokeTimer is used to detect a pause in the user's typing so that a
      // search() event can be fired. This is designed to prevent an api from being
      // overloaded with search requests.

      // loadingTimer hides the loading spinner after 3 seconds. This prevents it
      // staying on screen if no droplist results are returned.

      this.$emit('input', this.getCurrentText)
      clearTimeout(this.keystrokeTimer)
      clearTimeout(this.loadingTimer)

      if (this.getCurrentText.trim().length < Number(this.minlength)) {
        this.isLoading = false
      } else {
        if (this.showSpinner) {
          this.isLoading = true
          this.loadingTimer = setTimeout(() => {
            this.isLoading = false
          }, 3000)
        }

        this.keystrokeTimer = setTimeout(() => {
          this.$emit('search', this.getCurrentText)
        }, this.keyboardDelay)
      }
    },
    moveSelectionUp() {
      if (this.options.length) {
        this.selectedIndex--
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.options.length - 1
        }
        this.scrollSelectionIntoView()
      }
    },
    moveSelectionDown() {
      if (this.options.length) {
        this.selectedIndex++
        if (this.selectedIndex >= this.options.length) {
          this.selectedIndex = 0
        }
        this.scrollSelectionIntoView()
      }
    },
    selectItem(index) {
      if (typeof index === 'number') {
        this.$emit('select', index)
      }
    },
    scrollSelectionIntoView() {
      // Ensures the currently selected item in the droplist is visble,
      // i.e. is not scrolled out of view.
      var droplistEl = this.$refs.droplist
      var hasScrollBar =
        droplistEl && droplistEl.scrollHeight !== droplistEl.clientHeight
      if (hasScrollBar) {
        // Timeout ensures UI has rendered the new selectedIndex (set when
        // moving the selection up or down)
        setTimeout(() => {
          var selectedEl = droplistEl.getElementsByClassName('selected')[0]
          if (selectedEl.offsetTop < droplistEl.scrollTop) {
            droplistEl.scrollTop = selectedEl.offsetTop
          } else {
            var lastVisibleRowPos =
              droplistEl.scrollTop +
              droplistEl.clientHeight -
              selectedEl.clientHeight
            if (selectedEl.offsetTop > lastVisibleRowPos) {
              droplistEl.scrollTop =
                selectedEl.offsetTop -
                droplistEl.clientHeight +
                selectedEl.clientHeight
            }
          }
        }, 0)
      }
    },
    setDroplistHeight() {
      // Sets droplist max-height whenever droplist content changes.
      var el = this.$refs.droplist
      if (el) {
        if (this.maxDroplistHeight) {
          el.style.maxHeight = this.maxDroplistHeight
        } else {
          // If no maxHeight prop set, ensure droplist does not extend below viewport
          var pos = el.getBoundingClientRect()
          var maxHeight = window.innerHeight - pos.top
          el.style.maxHeight = `${maxHeight}px`
        }
      }
    }
  },
  mounted() {
    this.currentText = this.value
  },
  watch: {
    value() {
      // Watches to see if parent component sets new value
      // for the type ahead input...
      this.currentText = this.value
    },
    options() {
      // Clear "loading" spinner when results are returned
      if (this.options.length) {
        this.selectedIndex = 0
        this.isLoading = false
      }
    },
    showDroplist() {
      // Timeout ensures droplist has rendered before setting height
      setTimeout(() => this.setDroplistHeight(), 0)
    }
  }
}
</script>

<style scoped lang="scss">
input.uppercase {
  text-transform: uppercase;
}

input::placeholder {
  text-transform: none;
}

.AppTypeAhead_options {
  background-color: white;
  color: black;
  border-radius: 0.2rem;
  border: 1px solid #ddd;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  position: absolute;
  z-index: 10;
  padding: 0.25em 0;
  overflow-y: auto;

  div {
    padding: 0.5em 2em 0.5em 1em;
    cursor: pointer;

    &:hover {
      background-color: $color-row-hover;
    }

    &.selected {
      background-color: $color-selected-item;
    }
  }
}

.spinner {
  font-weight: bold;
  padding: 1em;
  display: none;

  &.isLoading {
    display: inline-block;
  }

  .icon {
    animation: spin 3s infinite linear;

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
</style>
