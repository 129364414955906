<template>
  <div ref="textField">
    <div v-if="!isEditing || $attrs.readonly">
      <read-only-field
        :label="$attrs.label"
        :value="$attrs.value"
        v-bind="$attrs"
        @click="setEditMode($refs.textField)"
        data-testid="textFieldReadOnly"
      />
    </div>
    <div v-else>
      <AdsFormatContactField
        v-if="$attrs.inputType === 'phone'"
        :value="this.removeNonAsciiCharacters($attrs.value)"
        v-bind="$attrs"
        v-on="$listeners"
        persistent-placeholder
      />
      <AdsTextField
        v-else
        v-bind="$attrs"
        v-on="$listeners"
        data-testid="textField"
        persistent-placeholder
      />
    </div>
  </div>
</template>

<script>
import { AdsTextField, AdsFormatContactField } from '@nswdoe/doe-ui-core'
import toggleFieldMixin from './toggleFieldMixin'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'TextField',
  mixins: [toggleFieldMixin, fieldHelperMixin],
  components: {
    AdsTextField,
    AdsFormatContactField
  }
}
</script>
