// Helper functions for getting values from state

import { STATUS, DATASET, REF_DATA, OOA_STATUS } from '@/constants'
import allScholasticYears from '@/store/allScholasticYears'
import _get from 'lodash/get'

export default {
  // Our application model (window.MODEL) is not a good match for Vue
  // reactivity because of its mix of data, metadata and business logic.
  // However it is useful for it to be universally accessible via a getter.
  // To make this work we simply reference state.application (which is reactive)
  // before returning our model.
  model: (state) => (state.renderModel ? window.MODEL : []),
  emailTemplateLink: (state) => {
    return _get(
      state.oesProperties,
      'si.emailsTemplate.link',
      'https://my.education.nsw.gov.au/help/platoapps/home/oes/out-of-area-ooa'
    )
  },
  helpLink: (state) => {
    return _get(state.oesProperties, 'si.help.link')
  },
  referenceData: (state) => (dataset) => {
    // Returns reference data (droplist data) for a specified category

    try {
      if (dataset === REF_DATA.SCHOLASTIC_YEAR) {
        // Looks up available scholastic years for the current intended start year.
        // If no years are available, display all years (K to 12).
        let startYear = Number(
          state.application?.intendedStartDate?.substring(0, 4)
        )
        if (startYear && state.referenceData[REF_DATA.SCHOLASTIC_YEAR]) {
          let years = state.referenceData[REF_DATA.SCHOLASTIC_YEAR].find(
            (data) => data.calenderYear == startYear
          )
          if (years) {
            return years.scholasticYears
          }
        }
        return allScholasticYears
      } else if (typeof dataset === 'function') {
        // If 'dataset' attribute is a function, run function (passing in application data) to get dataset name...
        dataset = dataset(state.application)
      }
      return state.referenceData[dataset] || []
    } catch (e) {
      return []
    }
  },

  lookupCode: (state, getters) => (dataset, code) => {
    // Looks up reference data text based on supplied dataset name and item code
    if (code === null || code === undefined) {
      return ''
    }
    let item = getters
      .referenceData(dataset)
      .find((data) => data.value === code)
    if (item) {
      return item.text + (item.hidden ? ' (SUSPENDED)' : '')
    }
    return code ? `${code} (description unavailable)` : ''
  },

  additionalSchoolMessages: (state) => {
    return _get(state.oesProperties, 'y67tAdditionalSchoolMessages')
  },

  y67tRoundDates: (state) => {
    return _get(state.oesProperties, 'y67tRoundDates')
  },

  y67tEmailLink: (state) => {
    return _get(state.oesProperties, 'si.y67tParentEmailTemplates.link')
  },

  application: (state) => state.application,

  oesProperties: (state) => state.oesProperties,

  isEditing: (state) => state.isEditing,

  isPrintPreview: (state) => state.isPrintPreview,

  isSpinner: (state) => state.showSpinner,

  isSentToErn: (state) =>
    state.application && state.application.applicationStatus === STATUS.SENT,

  isWithdrawn: (state) =>
    state.application &&
    state.application.applicationStatus === STATUS.WITHDRAWN,

  isInvalid: (state) => state.application?.applicationStatus === STATUS.INVALID,

  isInternalTransfer: (state) =>
    state.application?.applicationStatus === STATUS.INTERNAL_TRANSFER,

  isDeclined: (state) =>
    state.application &&
    state.application.applicationStatus === OOA_STATUS.DECLINED,

  isAccepted: (state) =>
    state.application &&
    state.application.applicationStatus === OOA_STATUS.ACCEPTED,

  isEhubLinkedStudent: (state) => {
    // this flag is set in PI when an application is created via Ehub and the student info (SRN) has been retrived from ERN in Ehub.
    return state.application && state.application.isEhubLinkedStudent
  },

  parentId: (state) => {
    // parentId will be present if an enrolment owner parent has gone through SNSW POI process.
    // PI is setting and pass it on to SI when an application is created via Ehub and parents have been verified by SNSW POI.
    return state.application.parentCarers
      .map((p) => p.parentId)
      .filter(Boolean)[0]
  },

  studentSrn: (state) => {
    return state.application && state.application.student.srn
  },

  focusFieldId: (state) => state.focusFieldId,

  totalAlerts: (state, getters) =>
    getters.missingFields.length +
    getters.invalidFields.length +
    getters.conflictFields.length +
    getters.unresolvedMatching.length +
    (getters.isCheckForSrn ? 1 : 0),

  isCheckForSrn: (state) => !state.ernRecord,

  missingFields: (state, getters) =>
    getters.model.filter(
      (row) => row.isMissing || (row.isWarning && !row.isResolved)
    ),

  invalidFields: (state, getters) =>
    getters.model.filter((row) => row.isInvalid),

  conflictFields: (state, getters) =>
    getters.model.filter((row) => row.isConflict),

  unresolvedMatching: (state, getters) =>
    getters.model.filter((row) => row.isUnresolvedMatching),

  // When a user creates an SRN from OES, its id is stored here. Should there be any further
  // attempts to create an SRN from the same application, this SRN will be reused.
  srnCreatedByOes: (state) =>
    state.application ? state.application.student.srnCreatedByOes : null,

  totalSupportingDocs: (state) => {
    if (state.application && state.application.supportingDocuments) {
      let totalDocs = Object.values(state.application.supportingDocuments)
      let totalDocsNum = totalDocs.reduce((acc, cur) => {
        return acc + cur.length
      }, 0)
      return totalDocsNum
    } else {
      return 0
    }
  },

  userID: (state) => state.userID,
  staffUserEmailId: (state) => `${state.userID}@det.nsw.gov.au`,
  selectedSchool: (state) => state.selectedSchool,
  selectedSchoolCode: (state) =>
    state.selectedSchool ? state.selectedSchool.schoolCode : null,
  ooaContactOptions: (state) => {
    if (!state.referenceData) {
      return []
    }
    const contactTypes = state.referenceData[DATASET.CONTACT_TYPE]
    if (contactTypes) {
      const ooaContactTypes = ['PHMOB', 'PHHOM', 'PHWRK']
      return contactTypes.filter(
        (ct) => ooaContactTypes.indexOf(ct.value) !== -1 && !ct.hidden
      )
    }
    return []
  },
  auSchools: (state) => state.auSchools,
  filteredApplicationIds: (state) => state.filteredApplicationIds,
  filteredTrackingSheetIds: (state) => state.filteredTrackingSheetIds,
  currentStream: (state) => state.currentStream,
  actionFromStream: (state) => state.actionFromStream,
  redirectFromY67TToLocalPending: (state) =>
    state.redirectFromY67TToLocalPending,
  redirectFromY67TToOoaPending: (state) => state.redirectFromY67TToOoaPending,
  applicationCounts: (state) => state.applicationCounts,
  updateCount: (state) => state.updateCount,
  countApiError: (state) => state.countApiError,
  showSettings: (state) => state.showSettings,
  settingsTab: (state) => state.settingsTab
}
