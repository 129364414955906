<!--
Provides a date picker using day, month and year droplists. It returns
a YYYY-MM-DD date format. Note that date validation (of invalid or incomplete
dates) is not handled by the picker, but by the component hosting the picker.

If monthOnly is set as <true>, the picker will act as an MM/YYYY picker.
-->
<template>
  <div class="AppDatePicker">
    <select
      :id="id"
      :title="label + ' day'"
      :value="day"
      :disabled="disabled"
      @change="updateDate('day', $event.target.value)"
      @focus="onFocus()"
      v-if="!monthOnly"
      :ref="monthOnly ? '' : 'defaultInput'"
      data-testid="appDatePickerDay"
      :aria-label="`${label} - day`"
      :aria-describedby="ariaDescribedBy"
    >
      <option v-for="dayx in getDays()" :key="dayx" :value="dayx">
        {{ dayx }}
      </option>
    </select>
    <select
      :id="monthOnly ? id : null"
      :title="label + ' month'"
      :value="month"
      :disabled="disabled"
      @change="updateDate('month', $event.target.value)"
      @focus="onFocus()"
      :ref="monthOnly ? 'defaultInput' : ''"
      data-testid="appDatePickerMonth"
      :aria-label="`${label} - month`"
      :aria-describedby="ariaDescribedBy"
    >
      <option v-for="monthx in getMonths()" :key="monthx" :value="monthx">
        {{ monthx }}
      </option>
    </select>
    <select
      :title="label + ' year'"
      :value="year"
      :disabled="disabled"
      @change="updateDate('year', $event.target.value)"
      @focus="onFocus()"
      data-testid="appDatePickerYear"
      :aria-label="`${label} - year`"
      :aria-describedby="ariaDescribedBy"
    >
      <option
        v-for="(yearx, index) in getYears()"
        :key="yearx + index"
        :value="yearx"
      >
        {{ yearx }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'AppDatePicker',
  props: {
    id: String,
    value: String,
    label: String,
    disabled: Boolean,
    futureYears: { type: Number, default: 10 },
    pastYears: { type: Number, default: 100 },
    //specialYears property is to add extra years on top of the year dropdown list to cater for special cases e.g end date for addresses can be 2999
    specialYears: { type: Array, default: () => [''] },
    monthOnly: { type: Boolean, default: false }, // Allows the picker to act as an MM/YYYY picker
    ariaDescribedBy: { type: String }
  },
  computed: {
    isIsoFormat() {
      // is value YYYY-MM-DD
      return (
        this.value &&
        /^([0-9]{4})-(0[1-9]|1[0-2])-(0[0-9]|1[0-9]|2[0-9]|3[0-1])$/.test(
          this.value
        )
      )
    },
    day() {
      return this.value ? this.value.split('-')[2] : ''
    },
    month() {
      // monthOnly must be in MM/YYYY, or MM/ when year hasn't been entered yet
      if (this.monthOnly && !this.isIsoFormat) {
        return this.value ? this.value.split('/')[0] : ''
      }
      return this.value ? this.value.split('-')[1] : ''
    },
    year() {
      // monthOnly must be in MM/YYYY, or /YYYY when month hasn't been entered yet
      if (this.monthOnly && !this.isIsoFormat) {
        return this.value ? this.value.split('/')[1] : ''
      }
      return this.value ? this.value.split('-')[0] : ''
    },
    startYear() {
      return new Date().getFullYear() + this.futureYears
    },
    endYear() {
      return new Date().getFullYear() - this.pastYears
    },
    specialYearsOptions() {
      return this.specialYears
    }
  },
  methods: {
    getDays() {
      var days = ['']
      for (var d = 1; d <= 31; d++) {
        days.push(d.toString().padStart(2, '0'))
      }
      return days
    },
    getMonths() {
      return [
        '',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12'
      ]
    },
    getYears() {
      var years = this.specialYears.slice(0) // clone specialYears array from prop as Array is a reference type
      for (var y = this.startYear; y >= this.endYear; y = y - 1) {
        years.push(y.toString())
      }
      return years
    },
    updateDate(part, value) {
      if (!value) {
        // If a single droplist is set to empty we clear the whole date
        this.$emit('change', '')
      } else if (this.monthOnly) {
        var my
        if (part == 'month') {
          my = `${value}/${this.year}`
        } else if (part == 'year') {
          my = `${this.month}/${value}`
        }
        this.$emit('change', my)
      } else {
        var ymd
        if (part == 'day') {
          ymd = `${this.year}-${this.month}-${value}`
        } else if (part == 'month') {
          ymd = `${this.year}-${value}-${this.day}`
        } else if (part == 'year') {
          ymd = `${value}-${this.month}-${this.day}`
        }
        this.$emit('change', ymd)
      }
    },
    onFocus() {
      this.$emit('focus', this)
    }
  }
}
</script>

<style scoped lang="scss">
.AppDatePicker {
  border: $field-border;
  background-color: $color-field-background;
  margin-top: 0.25em;
  border-radius: $field-border-radius;
  select {
    border: 0;
  }
}
</style>
