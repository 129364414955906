<template>
  <div>
    <v-col class="pt-0 pl-0 pr-0">
      <TabNavigation v-model="tab" :items="items" text-transform="none">
        <template slot="content1">
          <v-row class="mx-10">
            <Y67TSecondaryEoiList />
          </v-row>
        </template>
        <template slot="content2">
          <Y67TSecondarySummaryReport />
        </template>
      </TabNavigation>
    </v-col>
  </div>
</template>

<script>
import Y67TSecondaryEoiList from '@/components/applicationList/Y67TSecondaryEoiList.vue'
import Y67TSecondarySummaryReport from '@/components/applicationList/Y67TSecondarySummaryReport.vue'
import { TabNavigation } from '@nswdoe/doe-ui-core'

export default {
  name: 'Y67TSecondaryDataTable',
  components: {
    Y67TSecondaryEoiList,
    Y67TSecondarySummaryReport,
    TabNavigation
  },
  data() {
    return {
      tab: null
    }
  },
  computed: {
    eoiTabName() {
      return 'TRACKING SHEET'
    },
    summaryTabName() {
      return 'SUMMARY REPORT'
    },
    items() {
      return [{ tab: `${this.eoiTabName}` }, { tab: `${this.summaryTabName}` }]
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
</style>
