<!-- 
  Provides a dialog for choosing which ERN record relates to the current record.
  - Any unlinked ERN records are displayed
  - An option for "New record" is provided (if current record is not in ERN)
-->
<template>
  <AppModal v-if="modelRow" @close="cancel()" ref="AppModal">
    <span slot="header">Choose a matching ERN record</span>
    <div slot="body">
      <div class="label">
        Find a match for
        <strong>{{ modelRow.summaryText || modelRow.label }}:</strong>
      </div>
      <div v-if="!ernRecords.length" class="noRecordsMessage">
        No ERN records available
      </div>
      <div v-for="(ernRecord, index) in ernRecords" :key="index">
        <ResolveLinkingOption
          :text="getErnRecordText(ernRecord)"
          :is-selected="selectedRecordId === ernRecord[ernRecordNumberField]"
          :linked-oes-id="ernRecord.LINKED_OES_ID"
          @click="selectedRecordId = ernRecord[ernRecordNumberField]"
          :disabled="isSuspended(ernRecord)"
        />
      </div>
      <ResolveLinkingOption
        text="New record (no match in ERN)"
        :is-selected="selectedRecordId === -1"
        @click="selectedRecordId = -1"
      />
    </div>
    <div slot="footer">
      <button
        @click="proceed"
        :disabled="selectedRecordId === this.record[this.ernRecordNumberField]"
        class="primary"
        type="button"
      >
        OK
      </button>
      <button @click="cancel" type="button">Cancel</button>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import ResolveLinkingOption from '@/components/application/ResolveLinkingOption.vue'

export default {
  name: 'ApplicationResolveLinking',
  components: {
    AppModal,
    ResolveLinkingOption
  },
  data() {
    return {
      selectedRecordId: null,
      ernRecords: []
    }
  },
  computed: {
    modelRow() {
      // modelRow for the record being resolved
      return this.$store.state.resolveLinking
    },
    state() {
      return this.$store.state
    },
    dispatch() {
      return this.$store.dispatch
    },
    field() {
      return this.modelRow.field
    },
    ernRecordNumberField() {
      return this.field.ernRecordNumberField
    },
    oesRecords() {
      return this.modelRow.parent.oesValue || []
    },
    record() {
      return this.modelRow.oesValue
    },
    recordIndex() {
      return this.modelRow.recordIndex
    },
    getErnRecords() {
      var ernRecords = this.modelRow.parent.ernValue || []
      ernRecords.map((ernRecord) => {
        // Add a temporary OES record link into each ERN record...
        var linkedOesRecord = this.oesRecords.find(
          (oesRecord) =>
            oesRecord[this.ernRecordNumberField] ===
              ernRecord[this.ernRecordNumberField] &&
            oesRecord[this.field.oesRecordNumberField] !==
              this.record[this.field.oesRecordNumberField]
        )
        ernRecord.LINKED_OES_ID = linkedOesRecord
          ? linkedOesRecord[this.field.oesRecordNumberField]
          : null
      })
      return ernRecords
    }
  },
  methods: {
    getErnRecordText(record) {
      let summaryText
      if (this.field.summaryText) {
        // If record summary text is available, use it...
        summaryText = this.field.summaryText(record)
      }
      // Otherwise use standard record heading...
      return summaryText || this.field.heading(record)
    },
    isSuspended(record) {
      // ERN records using suspended values should have '(SUSPENDED)' in
      // their text, indicating that the item should not be selectable.
      return this.getErnRecordText(record).indexOf('(SUSPENDED)') >= 0
    },
    getResolvedConflictIds(recordIndex) {
      // Gets model row ids of all resolved fields within the specified oes record
      var alertIds = []
      var recordId = `${this.modelRow.parent.id}[${recordIndex}]`
      this.$store.getters.model.forEach((row) => {
        if (
          row.resolution &&
          (row.id === recordId || (row.parent && row.parent.id === recordId))
        ) {
          alertIds.push(row.id)
        }
      })
      return alertIds
    },
    getLinkedOesRecordIndexForSelectedErnRecord() {
      if (this.selectedRecordId > 0) {
        var linkedOesRecordId = this.ernRecords.find(
          (ernRecord) =>
            ernRecord[this.ernRecordNumberField] === this.selectedRecordId
        ).LINKED_OES_ID
        if (linkedOesRecordId) {
          return this.oesRecords.findIndex(
            (oesRecord) =>
              oesRecord[this.field.oesRecordNumberField] === linkedOesRecordId
          )
        }
      }
      return -1
    },
    getAlertIdsToBeUndone() {
      // Builds array of resolution ids that need to be undone when
      // linking to a different ERN record.
      var alertIdsToBeUndone = this.getResolvedConflictIds(this.recordIndex)
      if (this.selectedRecordId > 0) {
        // If chosen ERN record is already linked to another OES record, any
        // resolutions from that linked OES record must also be undone (by adding
        // their ids into alertIdsToBeUndone).
        var oesRecordIndex = this.getLinkedOesRecordIndexForSelectedErnRecord()
        if (oesRecordIndex !== -1) {
          alertIdsToBeUndone = alertIdsToBeUndone.concat(
            this.getResolvedConflictIds(oesRecordIndex)
          )
        }
      }
      return alertIdsToBeUndone
    },
    proceed() {
      // Firstly warns if another OES record is already linked. Then warns of
      // any resolved conflicts that need to be undone.
      var me = this
      var existingLinkedOesRecordIndex =
        this.getLinkedOesRecordIndexForSelectedErnRecord()
      var alertIdsToBeUndone = this.getAlertIdsToBeUndone()

      if (existingLinkedOesRecordIndex !== -1) {
        this.dispatch('showMessageBox', {
          html: `
            <h2>Replace existing match</h2>
            <div>Another record is already matched to this ERN record. It will be unmatched if you continue.</div>
          `,
          onConfirm() {
            warnIfResolutionsToUndo()
          }
        })
      } else {
        warnIfResolutionsToUndo()
      }

      function warnIfResolutionsToUndo() {
        if (alertIdsToBeUndone.length) {
          me.dispatch('showMessageBox', {
            icon: 'mdi-exclamation-thick',
            html: `
              <h2>Undo conflict resolutions to proceed</h2>
              <div>In order to change the matched ERN record, all related conflict resolutions must be undone.</div>
            `,
            textConfirm: `Undo ${alertIdsToBeUndone.length} resolution${
              alertIdsToBeUndone.length === 1 ? '' : 's'
            }`,
            onConfirm() {
              me.save(existingLinkedOesRecordIndex, alertIdsToBeUndone)
            }
          })
        } else {
          me.save(existingLinkedOesRecordIndex)
        }
      }
    },
    save(existingLinkedOesRecordIndex, alertIdsToBeUndone) {
      this.dispatch('setCleanApplication')
      var setLinkField = `application.${this.field.apiKey}[${this.recordIndex}].${this.ernRecordNumberField}`

      // Undo any related conflict resolutions
      if (alertIdsToBeUndone) {
        this.dispatch('undoConflictResolutions', alertIdsToBeUndone)
      }

      // Remove linking from any existing linked record...
      if (existingLinkedOesRecordIndex !== -1) {
        var removeLinkField = `application.${this.field.apiKey}[${existingLinkedOesRecordIndex}].${this.ernRecordNumberField}`
        this.dispatch('set', [removeLinkField, null])
      }

      // Set linking and save application...
      this.dispatch('set', [setLinkField, this.selectedRecordId])
      this.dispatch('saveApplication', { skipValidation: true })
      this.dispatch('set', ['resolveLinking', null])
    },
    cancel() {
      this.dispatch('set', ['resolveLinking', null])
    }
  },
  mounted() {
    // Set selected option to current linked record
    if (this.modelRow) {
      this.selectedRecordId = this.record[this.ernRecordNumberField]
      this.ernRecords = this.getErnRecords
    }
  }
}
</script>

<style scoped lang="scss">
.label {
  padding: 0.5em 0;
}
.noRecordsMessage {
  border-bottom: 2px solid black;
  padding-bottom: 0.5em;
}
</style>
