<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <v-row>
      <ReviewField
        data-testid="relationship"
        label="Relationship"
        :value="relationship"
      />
      <ReviewField
        data-testid="title"
        label="Title"
        :value="parentCarerTitle"
      />
    </v-row>
    <v-row>
      <ReviewField
        data-testid="firstName"
        label="Given name"
        :value="parentCarerGivenName"
      />
      <ReviewField
        data-testid="familyName"
        label="Family name"
        :value="parentCarerFamilyName"
      />
    </v-row>
    <v-row>
      <ReviewField
        data-testid="email"
        label="Email address"
        :value="parentCarerEmail"
      />
      <ReviewField
        v-for="(contact, index) in contactDetails"
        :key="index"
        :label="contactType(contact.contactType)"
        :value="contact.contactValue"
        additional-field
        additional-field-label="Comment"
        :additional-field-value="contact.comments"
        data-testid="contactDetails"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewField from '@/components/application/y67t/paperEoI/eoi/Review/ReviewField'
import { CONTACT_PHONE_TYPE } from '@/constants'

// NOTE: only works for current iteration with 1 parent will need
// to expand when multiple parent are introduced
const mapParentCarerGetters = (variables) =>
  variables.reduce(
    (result, variable) => ({
      ...result,
      [variable]: {
        get() {
          return this.parentCarers && this.parentCarers[variable]
        }
      }
    }),
    {}
  )
export default {
  name: 'ParentCarerView',
  components: {
    ReviewField
  },
  computed: {
    ...mapGetters(['eoiApplication', 'relationshipTypes', 'titles']),
    ...mapParentCarerGetters([
      'parentCarerRelation',
      'parentCarerTitle',
      'parentCarerGivenName',
      'parentCarerFamilyName',
      'parentCarerEmail',
      'contactDetails'
    ]),
    parentCarers() {
      return this.eoiApplication.parentCarers[0]
    },
    relationship() {
      const relationship = this.relationshipTypes.find(
        ({ value }) => value === this.parentCarerRelation
      )
      return relationship ? relationship.text : null
    }
  },
  methods: {
    contactType(contactTypeValue) {
      const contactTypeName = CONTACT_PHONE_TYPE[contactTypeValue] || 'empty'
      return `Contact number (${contactTypeName})`
    }
  }
}
</script>

<style lang="scss" scoped></style>
