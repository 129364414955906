<!--
  Provides the template for a single selectable SRN match within the SRN search dialog
-->
<template>
  <div class="currentStudentContent" data-testid="current-student-display">
    <v-row>
      <v-col cols="1">
        <v-icon class="ml-3" color="white">mdi-account-outline</v-icon>
      </v-col>
      <v-col cols="11">
        <h2 class="ml-n7 mb-0 studentName" data-testid="current-student-name">
          <strong>{{ student.surname }}, {{ student.firstName }}</strong>
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1"></v-col>
      <v-col class="ml-n7">
        <div class="label">Gender</div>
        <div class="value">{{ student.gender }}</div>
      </v-col>
      <v-col>
        <div class="label">Date of Birth</div>
        <div class="value" data-testid="date-of-birth">
          {{ getDob(student) }}
        </div>
      </v-col>
      <v-col>
        <div class="label">Scholastic Year</div>
        <div class="value">{{ student.schYear }}</div>
      </v-col>
      <v-col>
        <div class="label">Parents/Carer</div>
        <div
          v-for="(parent, index) in student.parents"
          :key="index"
          class="value"
          data-testId="parent-name"
        >
          {{ parent }}
        </div>
      </v-col>
      <v-col>
        <div class="label">Postcode</div>
        <div class="value">{{ student.postcode }}</div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SrnMatchCurrentStudent',
  props: {
    student: {
      type: Object
    }
  },
  computed: {},
  methods: {
    getDob(student) {
      return this.$moment(student.dateOfBirth).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped lang="scss">
.currentStudentContent {
  background-color: $color-primary;
  color: white;
  border-radius: 4px;

  .studentName {
    font-size: 1.375em;
  }

  .label {
    font-size: 0.875rem;
    padding-bottom: 5px;
    &:nth-of-type(3) {
      // When group contains two fields (one above the other) add a gap between them
      margin-top: 0.5em;
    }
    &.isLinked {
      opacity: 1;
    }
    margin-left: 0;
    color: white;
  }

  .value {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>
