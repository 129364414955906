import moment from 'moment'
import { DATE_FORMAT, STATEMENT_OF_ACCOUNT_TYPE } from '@/constants'

export default {
  birthdate(value, application) {
    if (value && moment(value, DATE_FORMAT) > moment()) {
      return 'Cannot be a future date'
    }
    const calendarYear = application.intendedStartDate.split('-')[0]
    // using scholastic year to check if primary/high school
    const isHighSchooler = parseInt(application.scholasticYear, 10) > 6
    const thisYear = new Date().getFullYear()

    const cutOffDate =
      +calendarYear === thisYear
        ? moment().startOf('day').format('YYYY-MM-DD')
        : `${calendarYear}-01-01`

    const baseDateForAge = isHighSchooler ? cutOffDate : `${calendarYear}-07-31`
    const diff = moment(baseDateForAge).diff(value, 'year', true)

    const minAge = isHighSchooler ? 9 : 5
    const maxAge = 18

    if (minAge > diff) {
      return isHighSchooler
        ? `This student is too young to begin high school in ${calendarYear}.`
        : `This student is too young to begin school in ${calendarYear}.`
    }
    if (diff >= maxAge && isHighSchooler) {
      return `This student must be no younger than 9 and no older than ${maxAge} years of age to enrol in high school in ${calendarYear}.`
    }
  },
  prevSchoolLastDate(_, application) {
    if (application) {
      var startDate = application.previousSchool.prevSchoolStartDate
      var lastDate = application.previousSchool.prevSchoolLastDate

      // monthOnly dates can come back in MM/YYYY or ISO YYYY-MM-DD (DATE_FORMAT) format
      if (
        startDate &&
        lastDate &&
        moment(lastDate, ['MM/YYYY', DATE_FORMAT]) <
          moment(startDate, ['MM/YYYY', DATE_FORMAT])
      ) {
        return "Cannot be earlier than 'Attended from' date"
      }
    }
  },
  noFutureDate(date) {
    if (
      date &&
      moment(date, ['MM/YYYY', DATE_FORMAT, 'DD-MM-YYYY']) > moment()
    ) {
      return 'Cannot be a future date'
    }
  },
  noPastDate(date) {
    if (date) {
      const mDate = moment(date, [
        'MM/YYYY',
        DATE_FORMAT,
        'DD-MM-YYYY',
        'DD MMM YYYY'
      ])
      if (
        mDate.isBefore(
          moment(),
          mDate.creationData().format === 'MM/YYYY' ? 'month' : 'day'
        )
      ) {
        return 'Cannot be a past date'
      }
    }
  },
  getMaxFutureYearValidator(yearsInTheFuture) {
    // returns a validator function
    return (date) => {
      if (date) {
        const maxYear = new Date().getFullYear() + yearsInTheFuture
        const mDate = moment(date, ['MM/YYYY', DATE_FORMAT, 'DD-MM-YYYY'])
        return mDate.year() <= maxYear || `Cannot be a later than ${maxYear}`
      }
    }
  },
  statementAccountValidator(value, application) {
    if (value) {
      var parentFinancialResponsible = application['parentCarers'].filter(
        (p) => p.statementOfAccount === STATEMENT_OF_ACCOUNT_TYPE.FIR
      )
      if (parentFinancialResponsible.length <= 0) {
        return 'There must be one parent/carer listed as “Financially Responsible (receives the original Statement of Account)'
      }

      if (parentFinancialResponsible.length >= 2) {
        return 'There can only be one parent/carer listed that as “Financially Responsible (receives the original Statement of Account)'
      }
    }
  },
  occupationNameValidator(value) {
    if (value && !/^[a-zA-Z ,'&()/-]+$/.test(value)) {
      return "Can only contain letters, spaces, and the following ' , ( ) / - &"
    }
  },
  nameValidator(v) {
    return (
      !v ||
      /^[a-zA-Z ()'-]+$/.test(v) ||
      "Can only contain letters, spaces, and - ( ) '"
    )
  },
  required(v) {
    return !!v || 'Field is required'
  },
  email(v) {
    return !v || /\S+@\S+\.\S+/.test(v) || 'Invalid email'
  },
  studentMinAge(minAge, startYear) {
    return (value) => {
      const newIntakeYearStartDate = moment(`${startYear}-07-31`, [
        'YYYY-MM-DD'
      ])
      const mDate = moment(value, ['YYYY-MM-DD', 'DD-MM-YYYY', 'DD MMM YYYY'])
      return (
        newIntakeYearStartDate.diff(mDate, 'year', true) >= minAge ||
        `This student is too young to begin school in ${startYear}.`
      )
    }
  }
}
