<template>
  <div>
    <v-card flat data-testid="compskeleton">
      <v-card-title>
        <h1>{{ title }}</h1>
      </v-card-title>
      <hr />
      <v-card-text>
        <v-row>
          <v-col cols="3" md="3">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-col>
          <v-col>
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
          <v-col align-self="end">
            <v-skeleton-loader class="chart-1" type="image"></v-skeleton-loader>
          </v-col>
          <v-col align-self="end">
            <v-skeleton-loader class="chart-2" type="image"></v-skeleton-loader>
          </v-col>
          <v-col align-self="end">
            <v-skeleton-loader class="chart-3" type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ComparisonSkeleton',
  props: {
    title: {
      type: String,
      default: 'Skeleton'
    }
  }
}
</script>

<style lang="scss">
.v-skeleton-loader__heading {
  margin: 8px !important;
  width: 80% !important;
}

.v-skeleton-loader__image {
  height: 280px;
  border-radius: 20px;
  opacity: 0.5;
}
.theme--light.v-skeleton-loader {
  background: #e4e4e6 !important;
  opacity: 0.5;
  border-radius: 20px;
}

.chart-1 {
  height: 180px;
}

.chart-2 {
  height: 200px;
}

.chart-3 {
  height: 140px;
}
</style>
