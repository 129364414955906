<template>
  <div class="black--text alert-message" :class="messageTheme.messageClass">
    <v-icon
      size="30"
      class="alert-message__icon"
      :color="messageTheme.iconColour"
      >{{ messageTheme.icon }}</v-icon
    >

    <div class="alert-message__body">
      <p v-if="message && message.vHtmlBody" v-html="message.vHtmlBody"></p>
      <p v-else-if="message && message.body">
        {{ message && message.body }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage',
  props: {
    message: {
      type: Object
    }
  },
  data() {
    return {
      themeConfig: {
        success: {
          messageClass: 'alert-message--success',
          icon: 'mdi-check-circle',
          iconColour: 'success'
        },
        warning: {
          messageClass: 'alert-message--warning',
          icon: 'mdi-alert',
          iconColour: 'orange darken-1'
        },
        error: {
          messageClass: 'alert-message--error',
          icon: 'mdi-alert-circle',
          iconColour: 'red darken-1'
        },
        info: {
          messageClass: 'alert-message--info',
          icon: 'mdi-information',
          iconColour: 'info'
        },
        default: {
          messageClass: '',
          icon: 'mdi-information',
          iconColour: 'info'
        }
      }
    }
  },
  computed: {
    messageTheme() {
      return (
        (this.message && this.themeConfig[this.message.type]) ||
        this.themeConfig.default
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-message {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 1.2rem 0 1.2rem 1.2rem;
  border-left: 8px solid;
  border-radius: 4px;
  border-color: transparent;
  margin: 1rem;

  &--error {
    border-color: $color-training;
    background-color: $color-red-background;
  }

  &--success {
    border-color: $color-success;
    background-color: $color-success-background;
  }

  &--info {
    border-color: $color-info;
    background-color: $color-info-background;
  }

  &--warning {
    border-color: $color-warning-dark;
    background-color: $color-warning;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__body {
    display: flex;
    font-size: 1.07rem;
  }

  &__body {
    margin-right: 2rem;

    > p {
      margin: 0;
    }
  }

  &__close-btn {
    border: none;
    margin-left: auto;
  }

  &__icon {
    margin-right: 1.2rem;
  }
}
</style>
