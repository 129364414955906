<template>
  <AppModal data-testid="confirm-internal-transfer-modal">
    <template slot="header">
      <div class="d-flex justify-space-between pt-3 pl-3 w-full">
        <span class="font-weight-bold" role="alert"> Confirm update </span>
        <v-icon
          class="closeBtn"
          aria-label="close"
          @click="closeModal"
          data-testid="btn-close-modal"
        >
          mdi-close
        </v-icon>
      </div>
    </template>
    <template slot="body">
      <div class="content mt-3 mx-3">
        <p>
          {{ firstName }}
          {{ familyName }}'s enrolment application status will be marked as
          <strong>'internal transfer'</strong> because their mainstream
          enrolment was internally transferred to a selective high school
          enrolment in ERN.
        </p>
        <Alert
          in-page
          icon="mdi-alert"
          class="modal-alerts in-page-alert--small"
          alert-class="alert--no-border"
          elevation="0"
          background-colour
          type="warning"
        >
          <template #optional>
            <p>
              Please ensure that {{ firstName }} {{ familyName }}'s student
              record has been updated in ERN before proceeding.
            </p>
            <p>This action cannot be undone.</p>
          </template>
        </Alert>
      </div>
    </template>
    <template #footer>
      <div class="mb-0 mr-3 pb-2">
        <AdsButton
          icon="mdi-check"
          button-text="Confirm"
          aria-label="Confirm"
          @click="handleInternalTransferConfirm"
        />
        <AdsButton
          tertiary
          button-text="Cancel"
          aria-label="Cancel"
          @click="closeModal"
        />
      </div>
    </template>
  </AppModal>
</template>
<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import AppModal from '@/components/app/AppModal.vue'
import { AdsButton, Alert } from '@nswdoe/doe-ui-core'
import { STREAM, STATUS } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'InternalTransferModal',
  mixins: [fieldHelperMixin],
  components: {
    AppModal,
    AdsButton,
    Alert
  },
  computed: {
    ...mapGetters(['actionFromStream']),
    firstName() {
      return this.application?.student?.firstName
    },
    familyName() {
      return this.application?.student?.familyName
    }
  },
  methods: {
    closeModal() {
      this.$store.dispatch('set', ['openInternalTransfer', false])
    },
    handleInternalTransferConfirm() {
      if (this.actionFromStream === STREAM.LOCAL_AREA) {
        const applicationStatusId = 6
        this.$store.dispatch('saveCoreApplicationStatus', {
          applicationStatusId
        })
      } else {
        this.$store
          .dispatch('updateShsOfferEnrolmentStatus', {
            shsOffer: this.application,
            payload: {
              status: STATUS.INTERNAL_TRANSFER
            }
          })
          .finally(() => {
            this.closeModal()
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.w-full {
  width: 100%;
}
::v-deep .alert--element:not(.in-page-alert--small) .v-alert__wrapper {
  margin: 5px 18px;
}
::v-deep .alert--element .v-alert.alert--no-border .v-alert__border {
  display: none;
}
::v-deep .alert--element.in-page-alert--small {
  .v-alert {
    margin-bottom: 0;
  }
  .v-alert__icon.v-icon {
    font-size: 22px;
    height: 22px;
    min-width: 22px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.theme--light.v-icon.v-icon--link:focus {
  border: none !important;
  outline: 3px solid currentColor;
}
::v-deep .modal-alerts {
  .alertElement:focus {
    border: none !important;
    outline: 3px solid currentColor;
  }
}
</style>
