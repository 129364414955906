<template>
  <div>
    <template>
      <v-tabs
        v-bind="$attrs"
        v-on="$listeners"
        class="v-tab-navigation-vertical"
      >
        <v-tab
          v-for="(item, tabIndex) in items"
          :key="item.tab"
          :style="{ 'text-transform': textTransform }"
          class="justify-start"
          :data-testid="
            $attrs['data-testid']
              ? $attrs['data-testid'] + tabIndex
              : `tab${tabIndex}`
          "
          @change="
            () => {
              tabChange(tabIndex)
            }
          "
        >
          <v-icon v-if="item.icon || iconsOnly" left>
            {{ item.icon }}
          </v-icon>
          <span v-if="!iconsOnly" v-html="item.tab" />
        </v-tab>
        <v-tab-item v-for="(item, i) in items" :key="item.tab">
          <slot :name="'content' + (item.id ? item.id : i + 1)">
            <v-card flat>
              <!--
              FUS-1813: Changes to eslint rules now disallow the use of
              v-text and v-html on components. The error for the next
              line has been disabled as it conforms to the standards set
              by Vuetify 2.
              @see https://v2.vuetifyjs.com/en/components/lists/#dense
              -->
              <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
              <v-card-text v-html="item.content" />
            </v-card>
          </slot>
        </v-tab-item>
      </v-tabs>
    </template>
  </div>
</template>

<script>
/**
 * TODO: Attention VUE 3 developers
 *
 * This has temporarily been abstracted from ADS v1
 *
 * This component is seemingly broken in ADS when in vertical mode.
 * ADS v1 will soon be overriden by v3 (Vue 3, Vuetify 3, etc) and this component will be fixed in the process.
 */
export default {
  name: 'TabNavigation',
  data() {
    return {
      tab: null
    }
  },
  methods: {
    tabChange(index) {
      this.tab = index
      this.$emit('tabChange', index)
    }
  },
  props: {
    iconsOnly: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    textTransform: {
      type: String,
      default: 'uppercase'
    },
    nextIcon: {
      type: String,
      default: 'keyboard_arrow_right' // override the default value '$next' in v-tabs
    },
    prevIcon: {
      type: String,
      default: 'keyboard_arrow_left' // override the default value '$prev' in v-tabs
    },
    showArrows: {
      type: [Boolean, String],
      default: true // override the default value 'undefined' in v-tabs
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab-navigation .v-slide-group__wrapper,
.v-tab-navigation .v-window__container {
  padding-left: 0 !important;
}

.v-tabs .v-tab {
  font-size: 14px;
  text-align: left;
  letter-spacing: 0.03em;
  &.v-tab--active {
    font-weight: bold;
  }
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-dark-60 !important;
}

.theme--light.v-tabs .v-tab.v-tab--active:focus::before {
  opacity: inherit;
  border: 4px solid $ads-navy !important;
}

.theme--dark.v-tabs .v-tab:focus::before {
  opacity: inherit;
  border: 4px solid $ads-white !important;
}

.v-tab:before {
  background-color: inherit !important;
}

/* Custom 'outline' for OES applications, can refactor for other projects later */
.v-tab-navigation-vertical .v-tab.v-tab--active {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0px 50px 50px 0px;
}
</style>
