<template>
  <div
    :class="
      'd-flex justify-space-between align-center pl-2 pr-2 mt-3 ' + statusClass
    "
    :id="id"
  >
    <span v-if="safe">
      <v-icon>mdi-paperclip</v-icon>
      {{ fileName }}
    </span>

    <span v-else-if="infected" class="orange--text text--darken-1 pt-2">
      <v-icon color="orange darken-1" class="mr-1">mdi-alert-circle</v-icon>
      <strong>{{ fileName }}</strong> - this file was infected and cannot be
      viewed
    </span>

    <span v-else-if="toBeScanned" class="orange--text text--darken-1 pt-2">
      <v-icon color="orange darken-1" class="pr-1">mdi-autorenew</v-icon>
      <strong>{{ fileName }}</strong> - this file has not been virus scanned yet
      and cannot be viewed
    </span>

    <span v-else-if="missing" class="orange--text text--darken-1 pt-2">
      <v-icon color="orange darken-1" class="pr-1">mdi-alert-circle</v-icon>
      <strong>{{ fileName }}</strong> - this file is unavailable and cannot be
      viewed
    </span>

    <span v-else-if="noStatus" class="orange--text text--darken-1 pt-2">
      <v-icon color="orange darken-1" class="pr-1">mdi-alert-circle</v-icon>
      <strong>{{ fileName }}</strong> - this file cannot currently be retrieved
    </span>
    <div>
      <AdsButton
        v-if="canView && safe"
        secondary
        button-text="Download"
        icon="mdi-download-outline"
        :aria-label="ariaLabelDownload"
        class="mr-3"
        @click="onDownloadClick($event, value)"
        data-testid="download-button"
      />
      <AdsButton
        v-if="canView && safe"
        secondary
        button-text="View"
        icon="mdi-eye-outline"
        :aria-label="ariaLabelView"
        @click="onViewClick($event, value)"
        data-testid="view-button"
      />
      <DownloadDocumentsModal
        v-if="displayDownloadDialog"
        :applications="applicationInfo"
        :school-code="schoolCode"
        :application-type="applicationType"
        :document="document"
        :skip-download-app="true"
        @close="displayDownloadDialog = false"
      />
    </div>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import { FILE_STATUSES } from '@/constants'
import DownloadDocumentsModal from '@/components/applicationList/DownloadDocumentsModal.vue'

export default {
  name: 'AppFileEditor',
  components: { AdsButton, DownloadDocumentsModal },
  props: {
    id: String,
    value: String, // Path to the file
    handleView: Function, // Passed in function to view the file,
    canView: Boolean, // Control viewability of the file
    status: String // 'SAFE', 'INFECTED-REPLACED' or 'UNSCANNED' to control the message for an infected file.
  },
  data() {
    return {
      document: null,
      displayDownloadDialog: false
    }
  },
  computed: {
    ariaLabelDownload() {
      return 'Download ' + this.fileName
    },
    ariaLabelView() {
      return 'View ' + this.fileName
    },
    fileName() {
      return this.value.split('/').slice(-1)[0]
    },
    infected() {
      return this.status === FILE_STATUSES.INFECTED
    },
    safe() {
      return this.status === FILE_STATUSES.SAFE
    },
    toBeScanned() {
      return this.status === FILE_STATUSES.TO_BE_SCANNED
    },
    missing() {
      return this.status === FILE_STATUSES.MISSING
    },
    noStatus() {
      return this.status === undefined
    },
    statusClass() {
      return this.status === undefined ? '' : this.status.toLowerCase()
    },
    application() {
      return this.$store.getters.application
    },
    schoolCode() {
      return this.application.schoolCode
    },
    applicationType() {
      return this.$store.state.applicationType
    },
    applicationInfo() {
      return [
        {
          applicationID: this.application.applicationID,
          studentFirstName: this.application.student.firstName,
          studentFamilyName: this.application.student.familyName,
          supportingDocuments: this.application.supportingDocuments,
          isDigitizeEOIConsiderationDocUploaded:
            this.application.isDigitizeEOIConsiderationDocUploaded
        }
      ]
    }
  },
  methods: {
    onViewClick(e, value) {
      this.handleView({ filePath: value, previewMode: 'INLINE' })
    },
    onDownloadClick(e, value) {
      this.displayDownloadDialog = true
      this.document = { filePath: value, previewMode: 'ATTACHMENT' }
    }
  }
}
</script>
