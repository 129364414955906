var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-8"},[_c('v-row',[_c('v-col',{class:(_vm.isAddressConflict || _vm.hadAddressConflict) &&
        !_vm.isSecondarySchool &&
        !_vm.isArchived
          ? 'address-conflict-bg'
          : '',attrs:{"cols":"12","md":"4"}},[_c('read-only-field',{attrs:{"label":"Submitted EOI Address","readonly":""},model:{value:(_vm.residentialAddress),callback:function ($$v) {_vm.residentialAddress=$$v},expression:"residentialAddress"}}),(_vm.isAddressConflict && !_vm.isSecondarySchool && !_vm.isArchived)?_c('div',{staticClass:"address-conflict"},[_c('div',{staticClass:"conflict-text float-left"},[_c('v-icon',{staticClass:"material-icons pr-3"},[_vm._v("error")]),_c('span',[_vm._v("Conflict with ERN")])],1),_c('div',{staticClass:"float-right"},[_c('AdsButton',{staticClass:"button red darken-1",attrs:{"data-testid":"resolve-conflict","button-text":"Resolve"},on:{"click":_vm.resolveConflict}})],1)]):_vm._e(),(
          _vm.isReadyToSendErn &&
          _vm.hadAddressConflict &&
          !_vm.isSecondarySchool &&
          !_vm.isArchived
        )?_c('div',{staticClass:"ready-to-sent-ern"},[_c('div',{staticClass:"sent-ern-text float-left"},[_c('v-icon',{staticClass:"material-icons pr-3"},[_vm._v("send")]),_c('span',[_vm._v("Ready to send to HS")])],1)]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[(_vm.isSecondarySchool)?_c('div',[_c('read-only-field',{attrs:{"label":"Current Address (ERN)","readonly":""},model:{value:(_vm.ernAddress),callback:function ($$v) {_vm.ernAddress=$$v},expression:"ernAddress"}})],1):_c('div',[_c('read-only-field',{attrs:{"label":"Local high school","readonly":""},model:{value:(_vm.schoolName),callback:function ($$v) {_vm.schoolName=$$v},expression:"schoolName"}})],1)])],1),(_vm.isSecondarySchool)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('read-only-field',{attrs:{"label":"Submitted EOI Date","formatter":_vm.displayDate,"readonly":""},model:{value:(_vm.dateReceived),callback:function ($$v) {_vm.dateReceived=$$v},expression:"dateReceived"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('read-only-field',{attrs:{"label":"Current address start date (ERN)","formatter":_vm.displayDate,"readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('read-only-field',{attrs:{"label":"Local high school","readonly":""},model:{value:(_vm.schoolName),callback:function ($$v) {_vm.schoolName=$$v},expression:"schoolName"}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }