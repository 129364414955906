<!--
  Displays a single field (or group or collection), in the correct view/edit mode, with
  an necessary headings.
  Note that there is some template duplication with FieldCollection, because we
  were unable to use this template recursively due to a Vue error (possibly a bug within Vue itself).
-->
<template>
  <br v-if="modelRow.type === FIELD_TYPE.LINEBREAK" />

  <AlertMessage
    v-else-if="modelRow.type === FIELD_TYPE.MESSAGE && !modelRow.isHidden"
    :message="modelRow.field.message"
  />
  <FieldCollection
    v-else-if="modelRow.type === FIELD_TYPE.COLLECTION && !modelRow.isHidden"
    :model-row="modelRow"
  />
  <FieldGroup
    v-else-if="modelRow.type === FIELD_TYPE.GROUP"
    :model-row="modelRow"
  />
  <FieldHeading
    v-else-if="modelRow.type === FIELD_TYPE.HEADING && !modelRow.isHidden"
    :model-row="modelRow"
  />
  <Field
    v-else-if="
      !modelRow.isHidden &&
      isEditing &&
      !modelRow.isReadOnly &&
      !modelRow.isResolved
    "
    :model-row="modelRow"
  />
  <FieldCustom
    v-else-if="modelRow.type === FIELD_TYPE.CUSTOM && !modelRow.isHidden"
    :model-row="modelRow"
  />
  <FieldReadOnly v-else-if="!modelRow.isHidden" :model-row="modelRow" />
</template>

<script>
import FieldHeading from '@/components/form/FieldHeading.vue'
import Field from '@/components/form/Field.vue'
import FieldReadOnly from '@/components/form/FieldReadOnly.vue'
import FieldCollection from '@/components/form/FieldCollection.vue'
import FieldGroup from '@/components/form/FieldGroup.vue'
import FieldCustom from '@/components/form/FieldCustom.vue'
import AlertMessage from '@/components/app/AlertMessage.vue'

import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { FIELD_TYPE } from '@/constants'

export default {
  name: 'FieldRow',
  props: {
    modelRow: {
      type: Object
    }
  },
  data() {
    return { FIELD_TYPE }
  },
  components: {
    FieldHeading,
    Field,
    FieldReadOnly,
    FieldCollection,
    FieldGroup,
    FieldCustom,
    AlertMessage
  },
  mixins: [fieldHelperMixin]
}
</script>
