// See README.md for more info about field configuration

import moment from 'moment'
import {
  FIELD_TYPE,
  CONFLICT_ALERTING,
  MATCH,
  DATASET,
  DATE_FORMAT,
  YES_NO
} from '@/constants'
import UTILS from '@/store/utils'
import store from '@/store/store'

const MEDICATION_CATEGORY = [
  { value: 'AOINJ', text: 'Adrenaline Auto-injector' },
  { value: 'ERM', text: 'ERN Converted - Medication', hidden: true },
  { value: 'OTHER', text: 'Other' }
]

function getDoctors(data) {
  var doctors = []
  if (data) {
    data.forEach((doctor) => {
      if (doctor.doctorName) {
        var suburb = doctor.doctorAddress.suburbName || ''
        if (suburb) {
          suburb = ' (' + suburb + ')'
        }
        doctors.push({
          value: doctor.doctorRecordNo,
          text: `${doctor.doctorName}${suburb}`
        })
      }
    })
  }
  return doctors
}

function getAdministeredByOptions(medicationItem) {
  let administeredBy = UTILS.clone(
    store.getters.referenceData(DATASET.ADMINISTERED_BY)
  )

  if (isAutoinjector(medicationItem)) {
    administeredBy = administeredBy.filter((option) => option.value === 'SEM')
  }

  return administeredBy
}

function getCategoryOptions(isAllergy) {
  let category = UTILS.clone(MEDICATION_CATEGORY)

  if (!isAllergy) {
    category = category.filter((option) => option.value === 'OTHER')
  }

  return category
}

function isAutoinjector(medicationItem) {
  return medicationItem.medicationCategory === 'AOINJ'
}

export default [
  {
    label: 'Medicare',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'student.medicareNumber',
    label: 'Medicare number',
    required: false,
    validation(value) {
      if (!value) {
        return
      }
      if (!/^[0-9 ]+$/.test(value)) {
        return 'Must not contain characters other than digits and spaces'
      }
      let digits = value.replace(/ /g, '').length
      if (digits < 10 || digits > 11) {
        // 'Should' is used in validation message (instead of 'must') because
        // although this should be a 10 digit number, ERN allows 11 digits.
        return 'Should be a 10 digit number'
      }
    },
    format: 'XXXX XXXXX XX'
  },
  {
    apiKey: 'student.medicareCardRef',
    label: 'Medicare card reference number',
    required: false,
    validation(value) {
      if (value && !/^\d+$/.test(value)) {
        return 'Must not contain characters other than digits'
      }

      if (value && value.length > 2) {
        return 'Must be a number containing up to 2 digits'
      }
    },
    maxlength: 2
  },
  {
    apiKey: 'student.medicareExpiryDate',
    label: 'Medicare card valid to date',
    required: false,
    type: FIELD_TYPE.MONTH_YEAR,
    validation(value) {
      if (value) {
        let yearInput = parseInt(value.split('/')[1])
        let yearCurrent = new Date().getFullYear()
        if (yearInput - yearCurrent > 10) {
          return 'Cannot be more than 10 years'
        }
      }
    }
  },
  {
    type: FIELD_TYPE.CUSTOM,
    component: 'InfoPanel',
    componentProps: {
      content: `<span style="line-height: 24px"><b>Note: </b>Doctor details should be present<span>`,
      isAlertMessage: true
    },
    visible(application) {
      return !application.doctorDetails.length
    },
    readOnly: true
  },

  // DOCTORS -------------------------------------------------------
  {
    apiKey: 'doctorDetails',
    type: FIELD_TYPE.COLLECTION,
    required: false,
    heading(doctor) {
      return `Doctor${doctor.doctorName ? ': ' + doctor.doctorName : ''}`
    },
    summaryText(record) {
      return `${record.doctorName}${
        record.doctorAddress.suburbName
          ? ', ' + record.doctorAddress.suburbName
          : ''
      }`
    },
    conflictAlerting: CONFLICT_ALERTING.AB_GROUP,
    ernMatch(oes, ern) {
      return UTILS.isMatch(oes, ern, { doctorName: MATCH.FUZZY })
    },
    ernRecordNumberField: 'ernDoctorRecordNo',
    oesRecordNumberField: 'doctorRecordNo',
    useDummyRecordNumbers: true,
    addLabel: 'Add doctor',
    newRecordTemplate() {
      return {
        doctorName: '',
        doctorPhoneNumber: '',
        doctorAddress: {}
      }
    },
    onRemove(doctor, application) {
      // Find allergies & medical conditions linked to the removed doctor, then remove doctorRecordNo link
      application.allergies.forEach((allergy) => {
        if (allergy.doctorRecordNo === doctor.doctorRecordNo) {
          allergy.doctor = ''
        }
      })
      application.nonAllergies.forEach((nonAllergy) => {
        if (nonAllergy.doctorRecordNo === doctor.doctorRecordNo) {
          nonAllergy.doctor = ''
        }
      })
    },
    fields(apiKeyPrefix) {
      return [
        {
          apiKey: apiKeyPrefix + 'doctorName',
          label: `Doctor's name/medical centre`,
          required: true,
          type: FIELD_TYPE.NAME,
          maxlength: 132
        },
        {
          apiKey: apiKeyPrefix + 'doctorAddress',
          label: 'Address',
          required: true,
          type: FIELD_TYPE.ADDRESS,
          showCountry: true,
          hasPoBox: true
        },
        {
          apiKey: apiKeyPrefix + 'doctorPhoneNumber',
          label: 'Phone number',
          required: true,
          type: FIELD_TYPE.PHONE
        }
      ]
    }
  },

  // ALLERGIES/ANAPHYLAXIS -----------------------------------------------------
  {
    apiKey: 'allergies',
    type: FIELD_TYPE.COLLECTION,
    heading(allergy) {
      var type = store.getters.lookupCode(
        DATASET.ALLERGY,
        allergy.medicalConditionType
      )
      return `${
        allergy.severeAllergy === true
          ? 'Anaphylaxis'
          : allergy.severeAllergy === false
          ? 'Allergy'
          : 'Allergy/Anaphylaxis'
      }${type ? ' - ' + type : ''}`
    },
    summaryText(allergy) {
      var type = store.getters.lookupCode(
        DATASET.ALLERGY,
        allergy.medicalConditionType
      )
      let item = store.getters
        .referenceData(DATASET.ALLERGY)
        .find((data) => data.value === allergy.medicalConditionType)
      if (item.hidden) {
        return type
      }
      return `${type} ${allergy.severeAllergy ? 'anaphylaxis' : 'allergy'}`
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      if (
        oes.medicalConditionType === ern.medicalConditionType &&
        oes.severeAllergy === ern.severeAllergy &&
        oes.doctorDiagnosed === ern.doctorDiagnosed &&
        oes.hasHospitalised === ern.hasHospitalised &&
        oes.hasAutoinjector === ern.hasAutoinjector
      ) {
        if (oes.medicalConditionType === 'ALO') {
          // If 'Other', perform additional check on notes
          return oes.notes === ern.notes
        }
        return true
      }
    },
    ernRecordNumberField: 'ernMedicalConditionRecordNo',
    oesRecordNumberField: 'medicalConditionRecordNo',
    addLabel: 'Add allergy/anaphylaxis',
    newRecordTemplate() {
      return {
        medicalConditionType: '',
        notes: '',
        doctorDiagnosed: '',
        doctorRecordNo: '',
        severeAllergy: '',
        hasHospitalised: '',
        hospitalName: '',
        actionPlan: '',
        individualHealthCarePlan: '',
        medications: [],
        hasAutoinjector: ''
      }
    },
    fields(apiKeyPrefix, allergyItem, application) {
      return [
        {
          apiKey: apiKeyPrefix + 'medicalConditionType',
          label: 'Allergic to',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.ALLERGY
        },
        {
          apiKey: apiKeyPrefix + 'notes',
          type: FIELD_TYPE.TEXTAREA,
          label: 'Notes',
          required: true,
          colspan: 2,
          conflictSettings: {
            dictionary: {
              conflictHelperText:
                'Please review the medical notes and edit if required'
            },
            forceErnResolution: true,
            alerts: {
              affectErn: true,
              parentCarerClarification: true
            }
          }
        },
        {
          apiKey: apiKeyPrefix + 'severeAllergy',
          label: 'Severe allergy (anaphylaxis)?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'doctorDiagnosed',
          label: 'Diagnosed by a doctor?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'doctorRecordNo',
          label: 'Doctor',
          required: false,
          type: FIELD_TYPE.DROPLIST,
          options: getDoctors(application.doctorDetails),
          visible() {
            return allergyItem.doctorDiagnosed
          }
        },
        {
          apiKey: apiKeyPrefix + 'hasHospitalised',
          label: 'Has been hospitalised with this condition?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'hospitalName',
          label: 'Hospital name',
          maxlength: 100,
          required: true,
          visible() {
            return allergyItem.hasHospitalised
          }
        },
        {
          apiKey: apiKeyPrefix + 'actionPlan',
          label: `Has an ASCIA Action Plan ${
            allergyItem.severeAllergy ? 'for Anaphylaxis' : ' for this allergy'
          }?`,
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'individualHealthCarePlan',
          label: `Has individual health care plan?`,
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'hasAutoinjector',
          label: `Adrenaline autoinjector?`,
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          type: FIELD_TYPE.CUSTOM,
          component: 'InfoPanel',
          componentProps: {
            content: `
            <h3>Adrenaline Autoinjector</h3>
            <p>
              Use the <strong>EBS:central autoinjector module</strong> to enter the
              autoinjector details.
            </p>
            <p>
              You will need the <strong>autoinjector Expiry Date</strong> and
              <strong>Batch Number</strong>.
            </p>
          `
          },
          colspan: 3,
          visible() {
            return allergyItem.hasAutoinjector
          }
        },
        // ALLERGY - MEDICATIONS -----------------------------------------------------
        {
          apiKey: apiKeyPrefix + 'medications',
          type: FIELD_TYPE.COLLECTION,
          heading(medication) {
            return medication.medicationName
              ? 'Treated with ' + medication.medicationName
              : 'Medication'
          },
          placeholderTitle: 'Medications',
          conflictAlerting: CONFLICT_ALERTING.LIST,
          ernRecordNumberField: 'ernMedicationRecordNo',
          oesRecordNumberField: 'medicationRecordNo',
          addLabel: 'Add medication',
          newRecordTemplate() {
            return {
              dosage: '',
              hasPrescribedMedication: '',
              medicationAdministeredBy: '',
              medicationCategory: 'OTHER',
              medicationName: '',
              autoinjectorExpiryDate: '',
              notes: ''
            }
          },
          fields(apiKeyPrefix, medicationItem) {
            return [
              {
                apiKey: apiKeyPrefix + 'medicationName',
                label: 'Medication name',
                required: true
              },
              {
                apiKey: apiKeyPrefix + 'medicationAdministeredBy',
                label: 'Administered by',
                type: FIELD_TYPE.DROPLIST,
                required: true,
                options: getAdministeredByOptions(medicationItem)
              },
              {
                apiKey: apiKeyPrefix + 'medicationCategory',
                label: 'Medication type',
                type: FIELD_TYPE.DROPLIST,
                required: true,
                options: getCategoryOptions(true)
              },

              {
                apiKey: apiKeyPrefix + 'dosage',
                label: 'Dosage',
                required: false
              },
              {
                apiKey: apiKeyPrefix + 'autoinjectorExpiryDate',
                type: FIELD_TYPE.DATE,
                futureYears: 10,
                pastYears: 8,
                label: 'Expiry date',
                validation(value) {
                  if (
                    value &&
                    moment(value, DATE_FORMAT) < moment().startOf('day')
                  ) {
                    return 'Cannot be a past date'
                  }
                }
              },
              {
                apiKey: apiKeyPrefix + 'notes',
                type: FIELD_TYPE.TEXTAREA,
                colspan: 2,
                label: 'Notes',
                required: false,
                conflictSettings: {
                  dictionary: {
                    conflictHelperText:
                      'Please review the medical notes and edit if required'
                  },
                  forceErnResolution: true,
                  alerts: {
                    affectErn: true,
                    parentCarerClarification: true
                  }
                }
              }
            ]
          }
        }
      ]
    }
  },

  // MEDICAL CONDITIONS ------------------------------------------------------
  {
    apiKey: 'nonAllergies',
    type: FIELD_TYPE.COLLECTION,
    heading(nonAllergy) {
      var type = store.getters.lookupCode(
        DATASET.MEDICAL_CONDITION,
        nonAllergy.medicalConditionType
      )
      return `Medical condition${type ? ' - ' + type : ''}`
    },
    summaryText(nonAllergy) {
      return store.getters.lookupCode(
        DATASET.MEDICAL_CONDITION,
        nonAllergy.medicalConditionType
      )
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      if (
        oes.medicalConditionType === ern.medicalConditionType &&
        oes.doctorDiagnosed === ern.doctorDiagnosed &&
        oes.hasHospitalised === ern.hasHospitalised
      ) {
        if (
          oes.medicalConditionType === 'OTH' ||
          oes.medicalConditionType === 'OHR'
        ) {
          // If 'Other', perform additional check on notes
          return oes.notes === ern.notes
        }
        return true
      }
    },
    ernRecordNumberField: 'ernMedicalConditionRecordNo',
    oesRecordNumberField: 'medicalConditionRecordNo',
    addLabel: 'Add medical condition',
    newRecordTemplate() {
      return {
        medicalConditionType: '',
        notes: '',
        doctorDiagnosed: '',
        doctorRecordNo: '',
        hasHospitalised: '',
        hospitalName: '',
        actionPlan: '',
        individualHealthCarePlan: '',
        medications: []
      }
    },
    fields(apiKeyPrefix, nonAllergy, application) {
      return [
        {
          apiKey: apiKeyPrefix + 'medicalConditionType',
          label: 'Medical condition',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.MEDICAL_CONDITION
        },
        {
          apiKey: apiKeyPrefix + 'notes',
          type: FIELD_TYPE.TEXTAREA,
          label: 'Notes',
          required: true,
          colspan: 2,
          conflictSettings: {
            dictionary: {
              conflictHelperText:
                'Please review the medical notes and edit if required'
            },
            forceErnResolution: true,
            alerts: {
              affectErn: true,
              parentCarerClarification: true
            }
          }
        },
        {
          apiKey: apiKeyPrefix + 'doctorDiagnosed',
          label: 'Diagnosed by a doctor?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'doctorRecordNo',
          label: 'Doctor',
          required: false,
          type: FIELD_TYPE.DROPLIST,
          options: getDoctors(application.doctorDetails),
          visible() {
            return nonAllergy.doctorDiagnosed
          }
        },
        {
          apiKey: apiKeyPrefix + 'hasHospitalised',
          label: 'Has been hospitalised with this condition?',
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'hospitalName',
          label: 'Hospital name',
          maxlength: 100,
          required: true,
          visible() {
            return nonAllergy.hasHospitalised
          }
        },
        {
          apiKey: apiKeyPrefix + 'actionPlan',
          label: `Has an Action Plan for this medical condition?`,
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },
        {
          apiKey: apiKeyPrefix + 'individualHealthCarePlan',
          label: `Has individual health care plan?`,
          required: true,
          type: FIELD_TYPE.RADIO,
          options: YES_NO
        },

        // MEDICAL CONDITIONS - MEDICATIONS -----------------------------------------------------
        {
          apiKey: apiKeyPrefix + 'medications',
          type: FIELD_TYPE.COLLECTION,
          heading(medication) {
            return medication.medicationName
              ? 'Treated with ' + medication.medicationName
              : 'Medication'
          },
          placeholderTitle: 'Medications',
          conflictAlerting: CONFLICT_ALERTING.LIST,
          ernRecordNumberField: 'ernMedicationRecordNo',
          oesRecordNumberField: 'medicationRecordNo',
          addLabel: 'Add medication',
          newRecordTemplate() {
            return {
              dosage: '',
              hasPrescribedMedication: '',
              medicationAdministeredBy: '',
              medicationCategory: 'OTHER',
              medicationName: '',
              notes: ''
            }
          },
          fields(apiKeyPrefix, medicationItem) {
            return [
              {
                apiKey: apiKeyPrefix + 'medicationName',
                label: 'Medication name',
                required: true
              },
              {
                apiKey: apiKeyPrefix + 'medicationAdministeredBy',
                label: 'Administered by',
                type: FIELD_TYPE.DROPLIST,
                required: true,
                options: getAdministeredByOptions(medicationItem)
              },
              {
                apiKey: apiKeyPrefix + 'medicationCategory',
                label: 'Medication type',
                type: FIELD_TYPE.DROPLIST,
                required: true,
                options: getCategoryOptions()
              },
              {
                apiKey: apiKeyPrefix + 'dosage',
                label: 'Dosage',
                required: false
              },
              {
                apiKey: apiKeyPrefix + 'notes',
                type: FIELD_TYPE.TEXTAREA,
                colspan: 2,
                label: 'Notes',
                required: false,
                conflictSettings: {
                  dictionary: {
                    conflictHelperText:
                      'Please review the medical notes and edit if required'
                  },
                  forceErnResolution: true,
                  alerts: {
                    affectErn: true,
                    parentCarerClarification: true
                  }
                }
              }
            ]
          }
        }
      ]
    }
  }
]
