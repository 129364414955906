export default {
  adviceType: 'EOI',
  consideringSelectiveSchool: null,
  consideringOoAGovtSchools: null,
  student: null,
  parentCarers: [
    {
      parentCarerCompletingApp: true,
      contactDetails: [{}]
    }
  ],
  residentialAddress: null, // pre-app
  currentPrimarySchool: null, // pre-app
  localHighSchool: null,
  nonGovtHighSchool: '',
  ooaHighSchools: [],
  isPaperEOI: null,
  isVerbalAdvice: null,
  meta: {
    stream: 'y67t', // hardcoded - will not change
    currentStep: 1, // current (active) step
    validStudent: null,
    validParentCarers: null,
    validHighSchool: null,
    validConsiderations: null,
    localSecondarySchools: null // pre-app
  },
  supportingDocuments: {
    digitizeEoiConsiderations: []
  },
  isDigitizeEOIConsiderationDocUploaded: null
}
