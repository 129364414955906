import ReadOnlyField from './ReadOnlyField.vue'
import { mapGetters } from 'vuex'

// mixins for toggling edit mode on click of readonly fields
export default {
  name: 'ToggleFieldMixin',
  components: {
    ReadOnlyField
  },
  computed: {
    ...mapGetters(['isEditing'])
  },
  methods: {
    setEditMode(containerElement) {
      if (!this.$attrs.readonly) {
        this.$store.dispatch('editApplication').then(() => {
          // Focus on the input in the container after toggling edit mode
          if (containerElement && containerElement.querySelector) {
            const focusEl = containerElement.querySelector('input')
            if (focusEl) {
              focusEl.focus()
            }
          }
        })
      }
    }
  }
}
