<template>
  <div class="countApiErrorWrapper">
    <img class="alertIcon" src="@/assets/alert.svg" alt="NSW Government" />
    <div class="errorTittle">System error</div>
    <div class="errorSubtitle">
      We were unable to connect to the system. Please try again.
    </div>
    <AdsButton
      button-text="Refresh"
      icon="mdi-refresh"
      aria-label="Refresh"
      class="refreshButton"
      @click="onRefreshClick($event)"
      data-testid="download-button"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'CountApiError',
  components: { AdsButton },
  computed: {
    ...mapGetters(['selectedSchool', 'applicationCounts'])
  },
  methods: {
    onRefreshClick() {
      this.$store.dispatch('getApplicationCounts')
    }
  }
}
</script>

<style scoped lang="scss">
.countApiErrorWrapper {
  margin-top: 7rem;
}

.alertIcon {
  display: block;
  margin: 2rem auto;
}

.errorTittle {
  font-weight: bold;
  font-size: 20px;
  color: $ads-primary-blue;
  text-align: center;
}

.errorSubtitle {
  color: $ads-primary-blue;
  text-align: center;
  margin-top: 1rem;
  font-size: 14px;
}

.refreshButton {
  display: block;
  margin: 2rem auto 10rem;
}
</style>
