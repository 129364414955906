// See README.md for more info about field configuration

import { FIELD_TYPE, CONFLICT_ALERTING, MATCH, DATE_FORMAT } from '@/constants'
import UTILS from '@/store/utils'
import {
  getContactText,
  getContactNumberFields,
  isParentCarerMatch,
  getParentCarerSummaryText
} from './helpers'
import moment from 'moment'

export default [
  {
    label: `Parents/carers with whom this student normally lives`,
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'correspondenceName',
    label: 'Name to be used for all correspondence',
    required: true,
    maxlength: 64
  },
  {
    apiKey: 'residentialAddress',
    label: 'Residential address',
    required: true,
    type: FIELD_TYPE.ADDRESS,
    colspan: 2
  },
  {
    apiKey: 'isInArea',
    type: FIELD_TYPE.RADIO,
    label: 'In area?',
    required: true,
    options: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    readOnly: true
  },
  {
    apiKey: 'residentialAddress.startDate',
    label: 'Start date',
    required: true,
    type: FIELD_TYPE.DATE,
    noConflictAlerting: true, // Prevents start & end dates triggering conflicts
    readOnly(application) {
      return !!application.residentialAddress.addressLine1
    }
  },
  {
    apiKey: 'residentialAddress.endDate',
    label: 'End date',
    required: true,
    type: FIELD_TYPE.DATE,
    noConflictAlerting: true, // Prevents start & end dates triggering conflicts
    specialYears: ['', '2999'],
    validation(endDate, application) {
      if (application) {
        var startDate = eval(`application.residentialAddress.startDate`)
        if (
          startDate &&
          endDate &&
          moment(endDate, DATE_FORMAT) < moment(startDate, DATE_FORMAT)
        ) {
          return 'Cannot fall before start date'
        }
      }
    },
    readOnly(application) {
      return !!application.residentialAddress.addressLine1
    }
  },
  {
    type: FIELD_TYPE.CUSTOM,
    component: 'InfoPanel',
    componentProps: {
      content: `<span style="line-height: 24px"><b>Note: </b>Start and end dates can only be edited in ERN<span>`,
      isAlertMessage: true
    },
    readOnly: true
  },
  {
    type: FIELD_TYPE.HEADING,
    label: 'Correspondence details'
  },
  {
    apiKey: 'correspondenceAddress',
    label: 'Correspondence address',
    type: FIELD_TYPE.ADDRESS,
    hasPoBox: true,
    showCountry: true
  },
  {
    apiKey: 'correspondenceAddress.startDate',
    label: 'Start date',
    required: false,
    type: FIELD_TYPE.DATE,
    noConflictAlerting: true, // Prevents start & end dates triggering conflicts
    readOnly(application) {
      return !!application.correspondenceAddress.addressLine1
    }
  },
  {
    apiKey: 'correspondenceAddress.endDate',
    label: 'End date',
    required: false,
    type: FIELD_TYPE.DATE,
    specialYears: ['', '2999'],
    noConflictAlerting: true, // Prevents start & end dates triggering conflicts
    validation(endDate, application) {
      if (application) {
        var startDate = eval(`application.correspondenceAddress.startDate`)
        if (
          startDate &&
          endDate &&
          moment(endDate, DATE_FORMAT) < moment(startDate, DATE_FORMAT)
        ) {
          return 'Cannot fall before start date'
        }
      }
    },
    readOnly(application) {
      return !!application.correspondenceAddress.addressLine1
    }
  },

  // PARENT CARERS -----------------------------------------------------
  {
    apiKey: 'parentCarers',
    type: FIELD_TYPE.COLLECTION,
    filter: {
      apiKey: 'isEnrolmentOwner',
      value: true
    },
    heading(parentCarer) {
      return `Parent/carer: ${parentCarer.parentCarerTitle} ${parentCarer.parentCarerGivenName} ${parentCarer.parentCarerFamilyName}`
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      return isParentCarerMatch(oes, ern)
    },
    ernRecordNumberField: 'ernParentRecordNo',
    oesRecordNumberField: 'parentCarerRecordNo',
    summaryText(record) {
      return getParentCarerSummaryText(record)
    },
    fields(apiKeyPrefix, parent) {
      return [
        // PARENT CARER CONTACT NUMBERS -------------------------------------------
        {
          apiKey: apiKeyPrefix + 'contactDetails',
          type: FIELD_TYPE.COLLECTION,
          conflictAlerting: CONFLICT_ALERTING.LIST,
          ernRecordNumberField: 'ernContactDetailRecordNo',
          oesRecordNumberField: 'contactDetailRecordNo',
          heading(contactItem) {
            return getContactText(contactItem)
          },
          placeholderTitle: 'Contact details',
          orderBy: 'priority',
          addLabel: 'Add contact number',
          newRecordTemplate() {
            return {
              contactType: '',
              contactValue: '',
              comments: ''
            }
          },
          ernMatch(oes, ern) {
            return UTILS.isMatch(oes, ern, {
              contactValue: MATCH.FUZZY,
              contactType: MATCH.EXACT
            })
          },
          fields(apiKeyPrefix, contactItem) {
            return getContactNumberFields(
              this.apiKey,
              contactItem,
              apiKeyPrefix,
              null,
              null,
              parent
            )
          }
        }
      ]
    }
  }
]
