<template>
  <div>
    <Banner
      data-testid="eoi-main-banner"
      v-if="displayMainbanner"
      content-class="adsBanner"
      default-background1
    >
      <template #title>
        <h1 class="appTitle" v-html="bannerHeading"></h1>
      </template>
      <template #subtitle>
        <h1 class="appSubtitle" v-html="bannerSubtitle"></h1>
      </template>
      <template #icon>
        <img
          class="banner__icon pr-2"
          src="@/assets/school-logo.svg"
          alt="School logo"
        />
      </template>
    </Banner>
    <Banner data-testid="eoi-compact-banner" v-else default-background1>
      <template #title>
        <h1 class="appTitle ml-10">{{ title }}</h1>
      </template>
    </Banner>
    <div
      v-if="applicationSubmitted"
      class="py-12"
      data-testid="eoi-submited-message"
    >
      <div class="sr-only" aria-live="polite">
        <span v-html="bannerHeading"></span>
        <span
          >. The reference number is {{ applicationID }}. We have sent the
          parent a copy of expression of interest and reference number to
          {{ parentCarerEmail }}. {{ studentName }}’s expression of interest
          will now appear in the school interface under Received EOIs. You can
          now close this window to return to the school interface.</span
        >
      </div>
      <v-container>
        <ContentBox class="pt-6 contentbox" img-file-name="green_tick.svg">
          <div slot="title">
            <h2 class="pb-4 normal-text-medium-size">
              The reference number is <strong>{{ applicationID }}</strong>
            </h2>
          </div>
          <div slot="copyContent" class="normal-text">
            <p>
              We have sent the parent a copy of expression of interest and
              reference number to <strong>{{ parentCarerEmail }}</strong>
            </p>
            <p>
              {{ studentName }}’s expression of interest will now appear in the
              school interface under Received EOIs.
            </p>
            <p>
              You can now close this window to return to the school interface.
            </p>
            <AdsButton
              class="mt-4"
              x-large
              aria-label="Close this window"
              button-text="Close this window"
              icon="close"
              @click="$emit('close', false)"
            />
          </div>
        </ContentBox>
      </v-container>
    </div>
    <div v-else>
      <StudentAddressLookup
        data-testid="student-eoi-address"
        ref="studentAddress"
        v-if="showStudentAddress"
        @proceed="closeAddressLookup"
      />
      <!-- EOI stepper flow components -->
      <v-container
        fluid
        class="contentPadding form-container py-12"
        data-testid="form-container"
      >
        <v-row v-if="showSteps">
          <v-col cols="12 hidden-sm-and-down" md="3" class="sideContent ml-6">
            <div class="stepper">
              <Stepper
                :steps="steps"
                :current-step="currentStep"
                data-testid="eoi-stepper"
                ref="stepper"
                @select="changeStepper"
              />
            </div>
          </v-col>
          <v-col cols="12 hidden-sm-and-down" md="1" />
          <v-col cols="12" md="6" data-testid="form-content">
            <StudentEOIDetails
              data-testid="student-eoi-details"
              ref="studentEoIDetails"
              v-show="showStudentDetails"
            />
            <ParentCarersEOIDetails
              data-testid="parentcarer-eoi-details"
              ref="parentCarerEOIDetails"
              v-show="showParentCarerDetails"
            />
            <HighSchoolEOIDetails
              data-testid="highschool-eoi-details"
              ref="highSchoolEoIDetails"
              v-show="showHighSchoolDetails"
              @notification="pushNotification"
            />
            <ReviewEOI
              data-testid="review-eoi-details"
              ref="reviewEoIDetails"
              v-show="showReview"
              @edit="changeStepper"
            />
            <ForwardBackNav
              data-testid="eoi-fwd-button"
              ref="forwardBack"
              :include-back="showBackButton"
              :forward-loading="forwardLoading"
              :forward-text="forwardButtonText"
              :forward-attrs="{ disabled: forwardButtonDisabled }"
              @click:forward="handleForwardClick(currentStepNum)"
              :aria-label="forwardButtonText"
            />
          </v-col>
          <v-col cols="12 hidden-sm-and-down" md="2" />
        </v-row>
        <div
          class="alertsWrapper"
          :class="{ mobile: $vuetify.breakpoint.smAndDown }"
          role="alert"
          aria-live="assertive"
        >
          <Alert
            v-for="(alert, i) in alerts"
            :key="i"
            in-page
            :show-alert="true"
            :type="alert.type"
            absolute
            :html-text="alert.html"
          >
            <template slot="close">
              <v-btn
                @click="closeError(i)"
                icon
                class="rounds-dismiss-button"
                aria-label="dismiss"
                ref="alertButton"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </template>
          </Alert>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import { Banner, Stepper, AdsButton, Alert } from '@nswdoe/doe-ui-core'
import ContentBox from '@/components/app/ContentBox'
import StudentEOIDetails from './StudentEOIDetails.vue'
import ParentCarersEOIDetails from './ParentCarersEOIDetails.vue'
import HighSchoolEOIDetails from './HighSchoolEOIDetails.vue'
import StudentAddressLookup from './StudentAddressLookup.vue'
import ReviewEOI from './ReviewEOI.vue'
import ForwardBackNav from '@/components/application/y67t/paperEoI/Form/ForwardBackNav'
import { mapGetters } from 'vuex'
import { FILE_UPLOAD_ERROR, FILE_REMOVE_ERROR } from '@/constants'

export default {
  name: 'SIForm',
  components: {
    Banner,
    AdsButton,
    Alert,
    ContentBox,
    StudentAddressLookup,
    StudentEOIDetails,
    ParentCarersEOIDetails,
    HighSchoolEOIDetails,
    ReviewEOI,
    Stepper,
    ForwardBackNav
  },
  data() {
    return {
      showSteps: false,
      showStudentAddress: true,
      showStudentDetails: false,
      showParentCarerDetails: false,
      showHighSchoolDetails: false,
      showReview: false,
      forwardLoading: false,
      blockNav: false,
      showBackButton: false,
      currentStepNum: 0,
      alerts: []
    }
  },
  computed: {
    ...mapGetters([
      'validForm',
      'steps',
      'currentStep',
      'eoiApplication',
      'applicationSubmitted',
      'applicationID',
      'isSubmitting'
    ]),
    title() {
      return this.currentStepNum > 0 && this.currentStepNum <= 4
        ? this.steps[this.currentStepNum - 1].name
        : ''
    },
    forwardButtonText() {
      return this.isReviewSection ? 'Submit' : 'Proceed'
    },
    canSubmit() {
      return this.validForm
    },
    forwardButtonDisabled() {
      return (
        (this.isReviewSection && !this.canSubmit) ||
        this.blockNav ||
        this.isSubmitting
      )
    },
    selectedSchool() {
      return this.$store.state.selectedSchool
    },
    displayMainbanner() {
      return this.currentStepNum === 0 || this.currentStepNum === 5
    },
    bannerHeading() {
      return this.currentStepNum === 0
        ? 'New expression of interest'
        : this.applicationStatusTitle
    },
    bannerSubtitle() {
      return this.currentStepNum === 0
        ? `For Year 6 students attending <strong>${this.selectedSchool.schoolName}</strong>`
        : ''
    },
    applicationStatusTitle() {
      return this.applicationSubmitted
        ? `${this.studentName}'s expression of interest has been submitted`
        : `${this.studentName}'s expression of interest has not been submitted`
    },
    isReviewSection() {
      return this.currentStepNum === 4
    },
    parentCarers() {
      return this.eoiApplication && this.eoiApplication.parentCarers[0]
    },
    parentCarerEmail() {
      return this.parentCarers && this.parentCarers.parentCarerEmail
    },
    studentName() {
      return (
        this.eoiApplication &&
        `${this.eoiApplication.student.firstName} ${this.eoiApplication.student.familyName}`
      )
    }
  },
  watch: {
    currentStepNum(newValue) {
      this.$store.dispatch('setCurrentStep', newValue)
    },
    applicationSubmitted(newValue) {
      if (newValue) {
        this.currentStepNum++
      }
    },
    alerts() {
      this.$nextTick(() => {
        if (this.$refs.alertButton) {
          this.$refs.alertButton.forEach((btn) => {
            if (btn) {
              btn.$el.focus()
            }
          })
        }
      })
    }
  },
  methods: {
    closeAddressLookup() {
      this.currentStepNum++
      this.showStudentAddress = false
      this.showStep(this.currentStepNum)
    },
    handleForwardClick(step) {
      if (this.$store.getters.isFileUploadInProgress) {
        this.pushNotification(FILE_UPLOAD_ERROR, 500)
      } else if (this.$store.getters.isFileDeleteInProgress) {
        this.pushNotification(FILE_REMOVE_ERROR, 500)
      } else {
        this.validate(step)
        this.currentStepNum++
        this.showStep(this.currentStepNum)
      }
    },
    changeStepper(step) {
      if (this.$store.getters.isFileUploadInProgress) {
        this.pushNotification(FILE_UPLOAD_ERROR, 500)
      } else if (this.$store.getters.isFileDeleteInProgress) {
        this.pushNotification(FILE_REMOVE_ERROR, 500)
      } else {
        this.validate(this.currentStepNum)
        //Re-checking OOA question on Application origin change
        if (step === 4 && this.validForm) {
          this.validate(step - 1)
        }
        this.currentStepNum = step
        this.showStep(step)
      }
    },
    showStep(step) {
      this.showSteps = true
      this.blockNav = false
      this.showStudentDetails = false
      this.showParentCarerDetails = false
      this.showHighSchoolDetails = false
      this.showReview = false

      switch (step) {
        case 1:
          this.showStudentDetails = true
          break
        case 2:
          this.showParentCarerDetails = true
          break
        case 3:
          this.showHighSchoolDetails = true
          break
        case 4:
          this.showReview = true
          break
        case 5:
          this.currentStepNum--
          this.showReview = true
          this.$store.dispatch('submitAdvice', this.eoiApplication)
          break
        default:
          this.blockNav = true
      }
    },
    validate(step) {
      const formName = this.steps[step - 1].form
      if (this.$refs[formName]) {
        this.$refs[formName].validate()
      }
    },
    pushNotification(notification, delay) {
      if (delay) {
        setTimeout(() => {
          this.alerts.push(notification)
        }, delay)
      } else {
        this.alerts.push(notification)
      }
    },
    closeError(i) {
      this.alerts.splice(i, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.container.adsBanner {
  font-family: $digitize-eoi-font-family;
  margin: auto;
  width: 60%;
  .appTitle {
    font-size: 51px;
  }
  .appSubtitle {
    font-size: 22px;
    font-weight: normal;
  }
  .d-flex.align-start {
    align-items: center !important;
  }
}
.banner {
  font-family: $digitize-eoi-font-family;
}
.contentPadding {
  padding: 0 50px;
}
.container {
  padding: 50px;
  height: 100%;
}
.contentbox {
  margin: 0 auto;
  width: 70%;
}
.sideContent {
  position: relative;
  min-height: 100%;
  top: 0;
}
.stepper {
  font-family: $digitize-eoi-font-family;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  position: sticky;
  height: auto;
  top: 95px;
}
.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.normal-text {
  font-family: $digitize-eoi-font-family;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}
.normal-text-medium-size {
  font-family: $digitize-eoi-font-family;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
}
.content-img.rightSpacing {
  margin-top: -20px;
}
.alertsWrapper {
  font-family: $digitize-eoi-font-family;
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}
::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
}
::v-deep .alertsWrapper {
  .alertElement {
    .rounds-dismiss-button {
      color: $color-error-dark;
    }
    &:focus {
      border: none;
      outline: 3px solid $color-error-dark;
    }
    .font-weight-normal {
      font-weight: 500;
      color: $grey-dark;
      line-height: 24px;
    }
    .theme--light.v-btn.v-btn--flat.v-btn--icon.v-btn--round.v-size--default {
      border: none !important;
      &:focus {
        border: 3px solid $color-error-dark !important;
      }
    }
  }
}
</style>
