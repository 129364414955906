<template>
  <TabNavigation v-model="tab" :items="items">
    <template slot="content1">
      <div class="mx-10">
        <StudentDetailUpdatesPending />
      </div>
    </template>
    <template slot="content2">
      <div class="mx-10">
        <StudentDetailUpdatesProcessed />
      </div>
    </template>
  </TabNavigation>
</template>

<script>
import { TabNavigation } from '@nswdoe/doe-ui-core'
import StudentDetailUpdatesPending from './StudentDetailUpdatesPending.vue'
import StudentDetailUpdatesProcessed from './StudentDetailUpdatesProcessed.vue'
import { mapGetters } from 'vuex'

const TAB_NAMES = {
  PENDING: 'Pending',
  PROCESSED: 'Processed'
}

export default {
  name: 'StudentDetailUpdates',
  components: {
    TabNavigation,
    StudentDetailUpdatesPending,
    StudentDetailUpdatesProcessed
  },
  data() {
    return {
      tab: null,
      activeTab: ''
    }
  },
  watch: {
    schoolCode() {
      this.$store.dispatch('loadStudentDetailUpdates')
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode'
    }),
    items() {
      return [
        { tab: `${TAB_NAMES.PENDING}` },
        { tab: `${TAB_NAMES.PROCESSED}` }
      ]
    }
  },
  created() {
    // load the list on first load
    this.$store.dispatch('loadStudentDetailUpdates')
  },
  activated() {
    // refresh the list in case the status changed in the details window
    this.$store.dispatch('loadStudentDetailUpdates')
  },
  methods: {}
}
</script>

<style scoped lang="scss"></style>
