<template>
  <v-menu
    ref="dateRangeMenu"
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <v-text-field
        outlined
        readonly
        v-bind="$attrs"
        v-on="on"
        persistent-placeholder
        prepend-inner-icon="mdi-calendar-range-outline"
        v-model="displayValue"
      >
        <template #append>
          <v-icon
            :disabled="disabled"
            @click="on.click"
            tabindex="-1"
            class="carat"
            :data-open="isActive"
            >mdi-menu-down</v-icon
          >
        </template>
      </v-text-field>
    </template>
    <FocusTrap :is-active="isActive" v-if="!disabled">
      <v-card class="date-range-input__menu p-4" :height="cardHeight">
        <v-card-title>
          {{ $attrs.label }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="menu = false"
            aria-label="Close filter options menu"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-0">
          <v-radio-group
            @change="handleTypeChange"
            mandatory
            v-model="selectedDateType"
            class="mt-0"
          >
            <v-radio label="By specific date" value="specific" />
            <OesDatePicker
              class="specificDatePicker"
              :disabled="selectedDateType !== 'specific'"
              :label="$attrs.label"
              persistent-placeholder
              v-model="selectedDateSpecific"
              @input="emitSelected('specific')"
            />
            <v-radio class="mt-4" label="By date range" value="range" />
            <div class="by-date-range" v-if="!resetPickers">
              <div class="by-date-range__pickers">
                <OesDatePicker
                  class=""
                  data-testid="selectedDateFrom"
                  :disabled="selectedDateType !== 'range'"
                  label="From"
                  persistent-placeholder
                  v-model="selectedDateFrom"
                  @input="emitSelected('range')"
                />
                <OesDatePicker
                  class=""
                  data-testid="selectedDateTo"
                  :disabled="selectedDateType !== 'range'"
                  label="To"
                  persistent-placeholder
                  v-model="selectedDateTo"
                  @input="emitSelected('range')"
                />
              </div>
              <div class="date-range-clear" v-if="cardHeight === 'auto'">
                <v-btn
                  text
                  v-if="selectedDateType === 'range'"
                  @click="clearFilter"
                  >Clear date filter</v-btn
                >
              </div>
            </div>
          </v-radio-group>
        </v-card-text>
      </v-card>
    </FocusTrap>
  </v-menu>
</template>

<script>
import OesDatePicker from '@/components/app/OesDatePicker.vue'
import { FocusTrap } from '@nswdoe/doe-ui-core'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

const defaultDateFormat = 'DD/MM/YYYY'
const isoDateFormat = 'YYYY-MM-DD'

export default {
  name: 'DateRangeInput',
  components: {
    FocusTrap,
    OesDatePicker
  },
  props: {
    filterOptions: {
      type: Object,
      default: () => {}
    },
    dateFormat: {
      type: String,
      default() {
        return defaultDateFormat
      }
    },
    outputDateFormat: {
      type: String,
      default() {
        return isoDateFormat
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  beforeCreate() {
    // required for parser
    dayjs.extend(customParseFormat)
  },
  data() {
    return {
      displayValue: null,
      selectedDateType: 'specific',
      selectedDateSpecific: null,
      selectedDateFrom: null,
      selectedDateTo: null,
      menu: false,
      selectedOptions: [],
      rangeDateOptions: [],
      cardHeight: 'auto',
      dataPickerActivated: {
        start: false,
        end: false
      },
      resetPickers: false,
      isActive: false
    }
  },
  watch: {
    value(newValue) {
      if (!newValue) {
        this.displayValue = null
        return
      }

      const [from, to] = newValue
      if (!from || !to) {
        this.displayValue = null
        return
      }

      let newDisplayValue = `${this.formatDate(from)}`
      if (from !== to) {
        newDisplayValue += `—${this.formatDate(to)}`
      }
      this.displayValue = newDisplayValue
    },
    selectedDateFrom(newValue) {
      const [from, to] = this.correctDateOrder(newValue, this.selectedDateTo)
      this.selectedDateFrom = from
      this.selectedDateTo = to
      this.rangeDateOptions[0] = from
      this.rangeDateOptions[1] = to
    },
    selectedDateTo(newValue) {
      const [from, to] = this.correctDateOrder(this.selectedDateFrom, newValue)
      this.selectedDateFrom = from
      this.selectedDateTo = to
      this.rangeDateOptions[0] = from
      this.rangeDateOptions[1] = to
    },
    // clear selected filters on change of school switcher
    filterOptions(newVal, oldVal) {
      if (newVal && oldVal && newVal.toString() === oldVal.toString()) {
        // don't clear the filters ( back to list view button)
        return
      }
      this.rangeDateOptions = []
    },
    menu(opened) {
      this.isActive = opened
      if (opened) {
        this.$emit('menuOpened')
      } else {
        this.$emit('menuClosed')
      }
    }
  },
  computed: {
    selectedFilterOptions() {
      let isSelected = false
      if (this.rangeDateOptions.length === 2) {
        isSelected = !!this.rangeDateOptions[0] && !!this.rangeDateOptions[1]
      }
      return isSelected ? true : false
    },
    selectedDate() {
      if (this.selectedDateType === 'specific') {
        return [this.selectedDateSpecific, this.selectedDateSpecific]
      } else {
        return [this.selectedDateFrom, this.selectedDateTo]
      }
    }
  },
  methods: {
    reset() {
      this.displayValue = null
      this.selectedDateType = 'specific'
      this.selectedDateSpecific = null
      this.selectedDateFrom = null
      this.selectedDateTo = null
      this.menu = false
      this.selectedOptions = []
      this.rangeDateOptions = []
    },
    correctDateOrder(from = this.selectedDateFrom, to = this.selectedDateTo) {
      const format = [
        this.dateFormat,
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'DD MM YYYY',
        'YYYY-MM-DD',
        'YYYYMMDD'
      ]

      const fromIsAfterTo =
        dayjs(from, format, true).diff(dayjs(to, format, true), 'day') > 0

      if (from !== null && to !== null && fromIsAfterTo) {
        ;[from, to] = [to, from]
      }

      return [from, to]
    },
    emitSelected(selectionType) {
      if (this.selectedDateType !== selectionType) {
        return
      }
      switch (selectionType) {
        case 'specific':
          this.$emit('input', [
            this.selectedDateSpecific,
            this.selectedDateSpecific
          ])
          break
        case 'range':
          this.$emit('input', [this.selectedDateFrom, this.selectedDateTo])
          break
      }
    },
    handleTypeChange() {
      this.emitSelected(this.selectedDateType)
    },
    formatDate(theDate) {
      const dateFormats = [
        this.dateFormat,
        'DD/MM/YYYY',
        'DD-MM-YYYY',
        'DD MM YYYY',
        'YYYY-MM-DD',
        'YYYYMMDD'
      ]
      return dayjs(theDate, dateFormats, true).format(this.dateFormat)
    },
    clearFilter() {
      this.rangeDateOptions = []
      this.selectedDateFrom = null
      this.selectedDateTo = null
      this.$emit('updatefilter', this.rangeDateOptions)

      // force pickers to re-render so that the internal dates get reset
      this.resetPickers = true
      this.$nextTick(() => {
        this.resetPickers = false
      })
    },
    datePickerActivated(activated, datePicker) {
      this.datePickerActivated[datePicker] = activated
    },
    dateRangeUpdate([start, end]) {
      if (!!start && !!end) {
        this.$emit('updatefilter', [start, end])
      } else {
        this.$emit('updatefilter', [])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 2px;
}
.dialogCloseBtn {
  border: none;
}
.date-range-input__menu {
  width: 610px;

  & ::v-deep .theme--light.v-messages {
    min-height: 2.25rem;
    font-size: 16px !important;
  }
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $color-primary;
  font-weight: 700;
}

::v-deep .v-list-item__title {
  font-size: 1.125rem;
}

.specificDatePicker,
.by-date-range {
  margin-top: 0.5rem;
  margin-left: 2rem;
}

.by-date-range {
  &__pickers {
    $pickers-gap: 1.5rem;

    display: flex;
    gap: $pickers-gap;

    & > div {
      flex: 0 0 calc(50% - #{$pickers-gap / 2});
    }
  }
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
  text-align: right;
  margin-right: 100px;
}

::v-deep div.v-picker--date {
  button:not(.v-btn--outlined):not(:focus) {
    border: none;
  }
}

.v-card__text {
  padding: 0rem 1.5rem 0.5rem 1rem !important;
}

.v-card__title {
  font-size: 1rem !important;
  padding: 0.5rem 0.5rem 0rem 1rem !important;
  color: $ads-grey-01;

  & .v-btn {
    border: none;
  }
}
.date-range-clear {
  display: flex;
  justify-content: flex-end;

  button {
    font-weight: normal;
    text-decoration: underline;
    font-size: 1rem;
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    height: initial !important;
  }
}

.v-icon.carat {
  transition: all 0.2s ease-in-out;
  opacity: 1;
  border: none;
}
.v-icon.carat[data-open] {
  rotate: 180deg;
}
</style>
