<template>
  <!--
    'persistent' and 'no-click-animation' props are a work-around for
    modal mysteriously closing when pressing down on a select box
  -->
  <v-dialog
    class="app-settings"
    v-model="valueInternal"
    fullscreen
    persistent
    no-click-animation
    hide-overlay
    transition="dialog-bottom-transition"
    origin="center bottom"
  >
    <v-card class="formCard" rounded="0">
      <div tabindex="0" ref="appSettingsModalPreFocus" />
      <div class="d-flex align-center app-online-enrolment-header">
        <span
          id="closeOnlineEnrolment"
          class="d-sr-only"
          :aria-label="`Close online enrolment system manual ${applicationType}`"
        />
        <v-btn
          icon
          text
          class="white--text mr-3"
          data-testid="digitise-flow-modal-close"
          ref="closeWithoutSave"
          @click="closeAppSettingsCancelModal"
          aria-labelledby="closeOnlineEnrolment"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <h1 data-testid="application-modal-heading">
          Online enrolment system - manual {{ applicationType }}
        </h1>
      </div>
      <div class="formContent">
        <SiForm v-if="type === 'EOI'" @close="closeAppSettingsModal" />
        <PnrForm
          v-else
          @close="closeAppSettingsModal"
          :display-digitise-flow-cancel-confirm-modal="
            displayDigitiseFlowCancelConfirmModal
          "
        />
        <DigitiseFlowCancelConfirmModal
          v-if="displayDigitiseFlowCancelConfirmModal"
          :type="type"
          :student-name="studentName"
          @close="closeDigitiseFlowCancelConfirmModal"
          @confirm="closeAppSettingsModal"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PnrForm from '@/components/application/y67t/paperEoI/pnr/PnrForm.vue'
import Form from '@/components/application/y67t/paperEoI/eoi/Form.vue'
import DigitiseFlowCancelConfirmModal from '@/components/application/y67t/paperEoI/DigitiseFlowCancelConfirmModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationModal',
  components: {
    PnrForm,
    SiForm: Form,
    DigitiseFlowCancelConfirmModal
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    type: {
      type: String
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      valueInternal: true,
      backgroundImage: '../banner/background2.jpg',
      displayDigitiseFlowCancelConfirmModal: false,
      studentName: ''
    }
  },
  computed: {
    ...mapGetters({
      applicationSubmitted: 'applicationSubmitted'
    }),
    applicationType() {
      return this.type === 'EOI' ? 'EOI submission' : 'PNR advisory'
    }
  },
  created() {
    this.$store.dispatch('getReferenceDataForPaperEoI')
    this.$store.dispatch('getPredictiveSchools')
    this.$store.dispatch('setApplicationSubmitted', false)
    this.$store.dispatch('setApplicationType', this.type)
    this.$store.dispatch('createAdvice', this.item)
  },
  methods: {
    closeDigitiseFlowCancelConfirmModal() {
      this.displayDigitiseFlowCancelConfirmModal = false
    },
    closeAppSettingsCancelModal() {
      if (this.applicationSubmitted) {
        this.closeAppSettingsModal()
      } else {
        this.studentName = `${this.item.selectedItem.student.firstName} ${this.item.selectedItem.student.familyName}`
        this.displayDigitiseFlowCancelConfirmModal = true
      }
    },
    closeAppSettingsModal() {
      this.displayDigitiseFlowCancelConfirmModal = false
      this.valueInternal = false
      this.$store.commit('setIsSubmitting', false)
      this.$emit('close', false)
    }
  }
}
</script>

<style lang="scss">
.container.adsBanner {
  font-family: $digitize-eoi-font-family;
  margin: auto;
  width: 70%;
  .appTitle {
    font-size: 51px;
    line-height: 62px;
  }
  .appSubtitle {
    font-size: 22px;
    line-height: 27px;
    font-weight: normal;
  }
  .d-flex.align-start {
    align-items: center !important;
  }
}
.formContent {
  background-color: $grey-light-10;
  height: auto;
  min-height: 960px;
}
.app-online-enrolment-header {
  background: $ads-navy;
  color: white !important;
  position: sticky; // sticky allows keyboard scroll when focused in the header
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 64px;
  z-index: 6;
  border-bottom: 1px solid;
  h1 {
    font-size: 1.25rem;
  }
  .v-btn {
    &:focus {
      border: 3px solid $color-secondary-lighten-1 !important;
    }
  }
  .v-btn--icon.v-size--default {
    height: 48px;
    width: 48px;
  }
}

.app-online-enrolment-header-ie {
  position: fixed; // ie-11 doesn't support sticky
}

.app-settings__content {
  color: $ads-navy;
  background: $ads-light-10;
  width: 100%;
  min-height: calc(100vh - 64px);
  position: absolute;
  top: 64px;
  h2 {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 1.875rem;
    margin-bottom: 0px;
  }
  p.subtitle {
    font-size: 1rem;
    color: $ads-navy;
  }
  .app-settings__circle-icon {
    border-radius: 50%;
    background: $ads-light-blue;
    color: $ads-navy;
    min-width: 54px;
    height: 54px;
    &.large {
      min-width: 116px;
      height: 116px;
    }
  }
  .app-settings__last-updated {
    font-style: italic;
    color: $ads-dark-70;
    font-size: 0.875rem;
    .v-icon {
      color: $ads-dark-70;
    }
  }
  .app-settings-heading {
    font-size: 1.25rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__subheading {
    font-size: 1rem;
    font-weight: 700;
    color: $ads-navy;
  }
  .app-settings__paragraph-text {
    max-width: 30rem;
  }
  .full-width {
    width: 100%;
    height: 39px;
    button {
      float: right;
    }
  }
  .sr-only {
    background-color: transparent;
  }
  .v-tabs {
    .v-tab {
      font-size: 18px;
      margin-bottom: 20px;
      letter-spacing: normal;
      padding-left: 60px;
      width: 292px;
      height: 67px;

      span {
        z-index: 1;
      }
    }
    .v-tabs-bar,
    .v-tabs-items {
      background: $ads-light-10;
    }
    .v-tab--active {
      background: $ads-white;
    }
  }
}

.v-application
  > .v-dialog__content
  > .v-dialog.v-dialog--active
  .v-card
  > .v-card__title
  > .title {
  font-size: 1.25rem !important;
}
.theme--light.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before,
.theme--light.v-btn:focus::before {
  opacity: 0;
}
.theme--light.v-btn.settingsPrimary.primary:not(.v-btn--text):not(
    .v-btn--outlined
  ):focus {
  box-shadow: 0 0 0 4px $color-focus;
}
.theme--light.v-btn.settingsOutline.v-btn--outlined:focus {
  box-shadow: 0 0 0 4px $color-primary !important;
}
.theme--light.v-messages.error--text {
  font-size: 16px !important;
}
.theme--light.v-messages {
  color: $color-dark70;
  font-size: 14px;
  font-weight: 500;
}
.v-messages__message {
  line-height: 18px;
}
.theme--light input::placeholder {
  color: $color-placeholder !important;
}
.theme--light.v-input textarea::placeholder {
  color: $color-placeholder !important;
}
.theme--light.radio-group label.v-label {
  font-weight: 500;
}
.v-list-item__title .v-input--checkbox.criteria .v-messages {
  display: none;
}

.v-alert__content .settings-link {
  margin: 0;
  text-decoration: underline;
  padding: 0;
  height: 20px;
}
.settingsIcon.theme--dark.v-btn.v-btn--disabled {
  color: $color-btn-disabled-text-default !important;
  opacity: 1;
}
</style>
