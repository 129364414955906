<template>
  <div class="pb-6 px-12">
    <AdsExpansionPanel :items="historyPanels">
      <template slot="contentHistory">
        <v-expand-transition>
          <div class="pa-6">
            <v-data-table
              class="dataTable"
              :headers="headers"
              :items="applicationComment"
              hide-default-footer
              disable-pagination
              disable-filtering
              sort-by="dateTime"
              sort-desc
              data-testid="history-data-table"
            >
              <template #[`item.dateTime`]="{ item }">
                <strong>{{ formatDate(item.dateTime) }}</strong>
              </template>
              <template #[`item.applicationStatus`]="{ item }">
                <Chip
                  v-bind="getStatusChipDetails(item)"
                  class="ApplicationStatusChips mb-4"
                />
              </template>
              <template #[`item.userId`]="{ item }">
                {{ item.actionedBy }}
              </template>
              <template v-if="isPrimarySchool" #[`item.comment`]="{ item }">
                {{ oesProp(item.eventName) }}
              </template>
              <template v-else #[`item.comment`]="{ item }">
                <span
                  v-if="item.eventName === 'AutoWithdrawnEoi'"
                  v-html="''"
                ></span>
                <span
                  v-else
                  v-html="
                    oesProp(item.eventName) === undefined
                      ? lineBreak(item.value)
                      : lineBreak(oesProp(item.eventName))
                  "
                ></span>
              </template>
              <template #[`item.supportingDocuments`]="{ item }">
                <div v-if="item.supportingDocuments">
                  <File
                    v-for="(doc, index) in item.supportingDocuments"
                    :key="index"
                    :name="getFileName(doc)"
                    :disabled="doc.status !== 'SAFE' || isSuperAdmin"
                    :message="messages[doc.status]"
                    @click="downloadFile(doc.objectKey)"
                  />
                </div>
              </template>
            </v-data-table>
          </div>
        </v-expand-transition>
      </template>
    </AdsExpansionPanel>
  </div>
</template>

<script>
import moment from 'moment'
import { AdsExpansionPanel, Chip } from '@nswdoe/doe-ui-core'
import File from '@/components/app/File.vue'
import {
  STREAM,
  Y67T_STATUS_CHIPS,
  MERGE_LOCAL_APP_EVENTNAME
} from '@/constants'
import settingsUtils from '@/helpers/settingsUtils'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationHistory',
  components: {
    AdsExpansionPanel,
    Chip,
    File
  },
  data() {
    return {
      headers: [
        { text: 'Date', value: 'dateTime', width: '120px', sortable: false },
        {
          text: 'Status',
          value: 'applicationStatus',
          width: '175px',
          sortable: false
        },
        {
          text: 'Actioned by',
          value: 'userId',
          width: '150px',
          sortable: false
        },
        { text: 'Comments', value: 'comment', width: '350px', sortable: false },
        {
          text: 'Supporting documents',
          value: 'supportingDocuments',
          sortable: false
        }
      ],
      messages: {
        SAFE: '',
        'INFECTED-REPLACED': 'This file is infected and cannot be downloaded.',
        UNSCANNED:
          'This file has not been virus scanned and cannot be downloaded.',
        MISSING: 'This file is missing and cannot be downloaded.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'oesProperties',
      'application',
      'currentStream'
    ]),
    historyPanels() {
      return [
        {
          id: 'History',
          title: 'Application History',
          icon: 'history'
        }
      ]
    },
    applicationComment() {
      return this.application?.comment
    },
    isPrimarySchool() {
      return this.currentStream === STREAM.Y67T_PRIMARY
    },
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    },
    staffUserEmailId() {
      return `${this.$store.state.userID}@det.nsw.edu.au`
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('DD MMM YYYY')
    },
    getStatusChipDetails(item) {
      return Y67T_STATUS_CHIPS[item.appStatus]
    },
    oesProp(item) {
      if (item === MERGE_LOCAL_APP_EVENTNAME) {
        const mergeLocalAppOnSendToHSComment = this.applicationComment?.filter(
          (comment) => comment.eventName === MERGE_LOCAL_APP_EVENTNAME
        )
        const directLocalAppReceivedDate =
          mergeLocalAppOnSendToHSComment?.length > 0
            ? mergeLocalAppOnSendToHSComment[0]?.directLocalAppReceivedDate
            : ''
        const oesMergeLocalAppOnSendToHSComment =
          this.oesProperties.y67tEventComments?.MergeLocalAppOnSendToHS?.replace(
            '{{directLocalAppDateReceived}}',
            moment(directLocalAppReceivedDate).format('Do MMM YYYY')
          )
        return oesMergeLocalAppOnSendToHSComment || ''
      }

      return this.oesProperties.y67tEventComments?.[item]
    },
    getFileName(data) {
      return data.objectKey.replace(/^.*[\\/]/, '')
    },
    async downloadFile(objectKey) {
      // Super admins are not allowed to view/download the files
      if (this.isSuperAdmin) {
        return
      }
      this.$store.dispatch('showSpinner')
      const objectKeyArray = objectKey.split('/')
      const eoiID = objectKeyArray[2]
      const dateStamp = objectKeyArray[3]
      const file = {}
      file.name = `${objectKeyArray[4]}`
      file.type = 'application/pdf'
      await this.$store
        .dispatch('viewY67TSupportingDocument', {
          file,
          schoolCode: this.application.schoolCode,
          applicationId: eoiID,
          uploadedBy: this.staffUserEmailId,
          stream: 'y67t',
          hsTrackingAction: true,
          dateStamp
        })
        .catch(() => {
          this.$store.dispatch('hideSpinner')
          this.$store.dispatch('showMessageBox', {
            icon: 'mdi-exclamation-thick',
            html: `<h2>This file could not be opened</h2><p>Please try again later</p>`
          })
        })
        .finally(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    lineBreak(value) {
      return value ? value.replace(/\n/g, '<br />') : ''
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table.dataTable {
  background: transparent;
  tr {
    vertical-align: top;
  }
  tr:hover {
    background: transparent !important;
  }
  th {
    color: $ads-dark-70 !important;
    font-size: 16px !important;
  }
  td {
    color: $grey-1 !important;
    font-size: 0.875rem !important;
    padding-top: 15px;
  }
}
::v-deep .ApplicationStatusChips {
  width: 9.0625rem;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
::v-deep .displayField label {
  font-weight: bold;
  font-size: 14px !important;
  color: $ads-primary-blue !important;
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  color: $ads-primary-blue !important;
}
::v-deep .theme--light.v-icon {
  color: $ads-primary-blue !important;
}

::v-deep .v-card__title {
  font-size: 16px !important;
}
</style>
