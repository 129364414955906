<template>
  <div class="px-8 pt-4 pb-2" :class="{ isEditing }">
    <v-row>
      <v-col cols="12" md="4">
        <text-field
          label="Student's first name"
          v-model="student.firstName"
          :rules="[validators.required, validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <text-field
          label="Second given name"
          v-model="student.otherName"
          :rules="[validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <text-field
          label="Student's family name"
          v-model="student.familyName"
          :rules="[validators.required, validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <text-field
          label="Student's preferred name"
          v-model="student.prefFirstName"
          :rules="[validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <radio-options
          label="Student's gender"
          v-model="student.genderCode"
          :options="genderOptions"
          :rules="[validators.required]"
          row
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <date-field
          label="Student's birthday"
          v-model="student.dateOfBirth"
          :date-picker-attrs="{
            min: minBirthDate,
            max: sevenDaysAgo
          }"
          :date-menu-attrs="{
            'offset-y': true,
            top: true
          }"
          year-picker
          :rules="[
            validators.required,
            validators.noFutureDate,
            validators.primaryStudentMaxAge,
            ...birthdateValidators
          ]"
          :readonly="readonly"
          data-testid="studentsBirthdate"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <date-field
          label="Intended start date"
          v-model="application.intendedStartDate"
          :date-picker-attrs="{
            min: maxAttendedStartDate,
            max: endOfNextYear,
            showCurrent: true
          }"
          :date-menu-attrs="{
            'offset-y': true,
            top: true
          }"
          :rules="[
            validators.required,
            validators.noPastDate,
            validators.maxStartDate
          ]"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <select-field
          label="Intended scholastic year"
          v-model="application.scholasticYear"
          :items="scholasticYears"
          :rules="[validators.required]"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <select-field
          label="Student's residency status"
          v-model="application.residencyStatus"
          :items="[
            { text: 'Australian Citizen', value: 'AUC' },
            { text: 'NZ Citizen', value: 'NZC' },
            { text: 'Permanent Resident', value: 'PER' },
            { text: 'Temporary Visa Holder', value: 'TMP' }
          ]"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <read-only-field
          label="Has student previously attended another school"
          v-model="application.prevAttendSchool"
          readonly
          :formatter="boolYesNo"
        />
      </v-col>
      <v-col cols="12" md="4">
        <select-field
          v-if="application.prevAttendSchool"
          label="Type of school"
          v-model="previousSchool.prevSchoolType"
          :items="[
            { text: 'Australian school', value: 'A' },
            { text: 'Overseas school', value: 'O' }
          ]"
          :rules="[validators.required]"
          @change="resetPrevSchool"
          :readonly="readonly"
          data-testid="prevSchoolType"
        />
      </v-col>
      <v-col cols="12" md="4">
        <SchoolLookup
          v-if="application.prevAttendSchool && isAustralianSchool"
          v-model="previousSchool.prevSchoolId"
          label="Previously attended school"
          :state-code.sync="previousSchool.prevSchoolTypeValue"
          :rules="[validators.required]"
          :readonly="readonly"
          data-testid="schoolLookup"
        />
        <text-field
          v-if="application.prevAttendSchool && !isAustralianSchool"
          v-model="previousSchool.prevSchoolName"
          label="Previously attended school"
          :rules="[validators.required]"
          max-length="128"
          :readonly="readonly"
        />
      </v-col>
      <v-col
        v-if="application.prevAttendSchool && !isAustralianSchool"
        cols="12"
        md="4"
      >
        <text-field
          v-model="previousSchool.otherSchoolAttendedLocation"
          label="Previously attended school location"
          :rules="[validators.required]"
          max-length="128"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <date-field
          v-if="application.prevAttendSchool"
          ref="prevSchoolStartDate"
          v-model="prevSchoolStartDate"
          label="Attended from"
          year-picker
          :date-menu-attrs="{
            'offset-y': true,
            top: true
          }"
          :date-format="MONTH_YEAR_DATE_FORMAT"
          :output-date-format="MONTH_YEAR_DATE_FORMAT"
          :rules="[
            validators.required,
            validators.noMonthAndYearBefore16years,
            validators.noFutureMonthAndYear
          ]"
          @input="crossValidate('prevSchoolLastDate')"
          :readonly="readonly"
          :date-picker-attrs="{
            type: 'month',
            min: minAttendedStartDate,
            max: maxAttendedStartDate,
            showCurrent: true
          }"
        />
      </v-col>
      <v-col cols="12" md="4">
        <date-field
          v-if="application.prevAttendSchool"
          ref="prevSchoolLastDate"
          v-model="prevSchoolLastDate"
          label="Attended until"
          year-picker
          :date-menu-attrs="{
            'offset-y': true,
            top: true
          }"
          :date-format="MONTH_YEAR_DATE_FORMAT"
          :output-date-format="MONTH_YEAR_DATE_FORMAT"
          :rules="[
            validators.required,
            validators.noMonthAndYearBefore16years,
            validators.prevSchoolEndDate
          ]"
          :readonly="readonly"
          :date-picker-attrs="{
            type: 'month',
            min: minAttendedLastDate,
            max: maxAttendedLastDate,
            showCurrent: true
          }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatters from '@/helpers/formatters'
import validators from '@/helpers/validators'
import { findValidatableElement } from '@/helpers/form'

import TextField from '@/components/form/TextField'
import SelectField from '@/components/form/SelectField'
import DateField from '@/components/form/DateField'
import ReadOnlyField from '@/components/form/ReadOnlyField'
import RadioOptions from '@/components/form/RadioOptions'
import SchoolLookup from '@/components/form/SchoolLookupAds'
import {
  GENDER_OPTIONS,
  REF_DATA,
  DATE_OF_BIRTH_MINIMUM_YEAR
} from '@/constants'
import moment from 'moment'

function dateToIsoDate(date) {
  return date.toISOString ? date.toISOString().slice(0, 10) : null
}

export default {
  name: 'StudentDetails',
  components: {
    TextField,
    SelectField,
    DateField,
    ReadOnlyField,
    RadioOptions,
    SchoolLookup
  },
  props: {
    readonly: {
      type: Boolean,
      defaiult: false
    }
  },
  data() {
    const sevenDaysAgo = new Date()
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7)

    const startOfThisYear = new Date()
    startOfThisYear.setDate(1)
    startOfThisYear.setMonth(0)

    const endOfNextYear = new Date()
    endOfNextYear.setMonth(11, 31) // zero based months!
    endOfNextYear.setFullYear(startOfThisYear.getFullYear() + 1)

    const thisYear = new Date().getFullYear()
    const minAttendedYear = thisYear - 16
    const maxAttendedLastDate = `${thisYear.toString()}-12-31`

    const {
      required,
      noPastDate,
      noFutureDate,
      noFutureMonthAndYear,
      noMonthAndYearBefore16years,
      name,
      primaryStudentMaxAge
    } = validators

    return {
      MONTH_YEAR_DATE_FORMAT: 'MM-YYYY',
      minBirthDate: `${thisYear - DATE_OF_BIRTH_MINIMUM_YEAR}-01-01`,
      sevenDaysAgo: dateToIsoDate(sevenDaysAgo),
      endOfNextYear: dateToIsoDate(endOfNextYear),
      boolYesNo: formatters.boolYesNo,
      validators: {
        required,
        noPastDate,
        noFutureDate,
        noFutureMonthAndYear,
        noMonthAndYearBefore16years,
        name,
        primaryStudentMaxAge,
        maxStartDate: validators.getMaxFutureYearValidator(1),
        prevSchoolEndDate: (toDate) =>
          validators.dateOrder(
            this.previousSchool.prevSchoolStartDate,
            toDate
          ) || "Cannot be earlier than 'Attended from' date"
      },
      genderOptions: GENDER_OPTIONS,
      minAttendedStartDate: new Date(`${minAttendedYear}-01-01`)
        .toISOString()
        .slice(0, 10),
      maxAttendedStartDate: new Date().toISOString().slice(0, 10),
      maxAttendedLastDate
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing', 'referenceData']),
    student() {
      return this.application.student
    },
    previousSchool() {
      return this.application.previousSchool
    },
    scholasticYears() {
      return this.referenceData(REF_DATA.SCHOLASTIC_YEAR)
    },
    isAustralianSchool() {
      return this.previousSchool.prevSchoolType === 'A'
    },
    minAttendedLastDate() {
      return this.previousSchool.prevSchoolStartDate
        ? this.previousSchool.prevSchoolStartDate
            .split('/')
            .reverse()
            .join('-01')
        : this.minAttendedStartDate
    },
    birthdateValidators() {
      const isHighSchool = parseInt(this.application.scholasticYear, 10) > 6
      const intendedStartYear = new Date(
        this.application.intendedStartDate
      ).getFullYear()

      return isHighSchool
        ? [
            validators.getSecondaryStudentMinAgeValidator(intendedStartYear),
            validators.getSecondaryStudentMaxAgeValidator(intendedStartYear)
          ]
        : [validators.getPrimaryStudentMinAgeValidator(intendedStartYear)]
    },
    prevSchoolStartDate: {
      get: function () {
        return this.previousSchool.prevSchoolStartDate
          ? moment(new Date(this.previousSchool.prevSchoolStartDate)).format(
              this.MONTH_YEAR_DATE_FORMAT
            )
          : null
      },
      set: function (value) {
        this.previousSchool.prevSchoolStartDate =
          value.split('-').reverse().join('-') + '-01'
      }
    },
    prevSchoolLastDate: {
      get: function () {
        return this.previousSchool.prevSchoolLastDate
          ? moment(new Date(this.previousSchool.prevSchoolLastDate)).format(
              this.MONTH_YEAR_DATE_FORMAT
            )
          : null
      },
      set: function (value) {
        this.previousSchool.prevSchoolLastDate =
          value.split('-').reverse().join('-') + '-01'
      }
    }
  },
  methods: {
    crossValidate(ref) {
      const refEl = this.$refs[ref]
      // our component doesn't implement vuetify's validatable interface
      // so we have to find the validatable element in its children
      const validatableElement = findValidatableElement(refEl)
      if (validatableElement) {
        validatableElement.validate(true)
      }
    },
    resetPrevSchool() {
      // clear out all irrelevant data
      this.previousSchool.prevSchoolName = ''
      this.previousSchool.otherSchoolAttendedLocation = ''
      this.previousSchool.prevSchoolId = ''
      this.previousSchool.prevSchoolTypeValue = ''
    }
  }
}
</script>
