<template>
  <v-expand-transition>
    <div class="pt-6 px-6">
      <v-data-table
        class="dataTable"
        :headers="headers"
        :items="history"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        :loading="loading"
        loading-text="Loading... Please wait"
        data-testid="history-data-table"
      >
        <template #[`item.datetimeModified`]="{ item }">
          <strong>{{ formatDate(item.datetimeModified) }}</strong>
        </template>
        <template #[`item.userId`]="{ item }">
          {{ actionByText(item) }}
        </template>
        <template #[`item.comment`]="{ item }">
          {{ item.comment || 'n/a' }}
        </template>
        <template #[`item.staffSupportingDocuments`]="{ item }">
          <div v-if="item.staffSupportingDocuments">
            <File
              v-for="(doc, index) in item.staffSupportingDocuments.statusChange"
              :key="index"
              :name="getFileName(doc)"
              :disabled="doc.status !== 'SAFE' || isSuperAdmin"
              :message="messages[doc.status]"
              @click="downloadFile(doc.objectKey)"
            />
          </div>
        </template>
      </v-data-table>
    </div>
  </v-expand-transition>
</template>

<script>
import moment from 'moment'
import File from '@/components/app/File.vue'
import settingsUtils from '@/helpers/settingsUtils'
import { USER_GROUP } from '@/constants'

export default {
  name: 'OoaHistory',
  props: {
    schoolCode: {
      type: String,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    }
  },
  components: {
    File
  },
  data() {
    return {
      headers: [
        { text: 'Date', value: 'datetimeModified', width: '120px' },
        { text: 'Status', value: 'applicationStatus' },
        { text: 'Action by', value: 'userId' },
        { text: 'Comments', value: 'comment' },
        { text: 'Supporting documents', value: 'staffSupportingDocuments' }
      ],
      history: [],
      loading: false,
      messages: {
        SAFE: '',
        'INFECTED-REPLACED': 'This file is infected and cannot be downloaded.',
        UNSCANNED:
          'This file has not been virus scanned and cannot be downloaded.',
        MISSING: 'This file is missing and cannot be downloaded.'
      }
    }
  },
  computed: {
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    }
  },
  methods: {
    actionByText: (item) => {
      return item.userGroup === USER_GROUP.PARENT ? 'Parent/carer' : item.userId
    },
    formatDate(date) {
      return moment(date).format('DD MMM YYYY')
    },
    getFileName: (data) => {
      return data.objectKey.replace(/^.*[\\/]/, '')
    },
    showMessageBox() {
      this.$store.dispatch('showMessageBox', {
        icon: 'mdi-exclamation-thick',
        html: `<h2>This file could not be opened</h2><p>Please try again later</p>`
      })
    },
    async downloadFile(objectKey) {
      // Super admins are not allowed to view/download the files
      if (this.isSuperAdmin) {
        return
      }
      this.loading = true
      const { applicationID, schoolCode } = this.$store.getters.application

      const relativePath = objectKey.replace(
        `${schoolCode}/${applicationID}/`,
        ''
      )
      const file = {
        name: relativePath
      }

      await this.$store
        .dispatch('viewSupportingDocument', {
          schoolCode,
          applicationId: applicationID,
          file
        })
        .catch(() => {
          this.showMessageBox()
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted() {
    if (!this.history.length) {
      this.loading = true
      this.$store
        .dispatch('fetchOoaApplicationHistory', {
          schoolCode: this.schoolCode,
          applicationId: this.applicationId
        })
        .then((historyData) => {
          this.history = historyData
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-data-table.dataTable {
  background: transparent;
  tr {
    vertical-align: top;
  }
  th {
    color: $ads-dark-80 !important;
    font-size: 0.875rem !important;
  }
  td {
    color: $ads-dark-80 !important;
    font-size: 0.875rem !important;
    padding-top: 15px;
  }
}
</style>
