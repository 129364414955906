<template>
  <div ref="radioOptions">
    <div v-if="!isEditing || $attrs.readonly">
      <read-only-field
        :label="label"
        v-bind="$attrs"
        :formatter="formatter || defaultFormatter"
        @click="setEditMode($refs.radioOptions)"
        data-testid="radioOptionsReadOnly"
      />
    </div>
    <div v-else class="radioGroupEdit">
      <!-- @change emitting input below shouldn't be necessary but it's not triggering reactivity without it -->
      <v-radio-group
        :label="label"
        v-bind="$attrs"
        v-on="$listeners"
        @change="(v) => $emit('input', v)"
        class="mt-0"
      >
        <v-radio v-for="(option, i) in options" :key="i" v-bind="option" />
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import toggleFieldMixin from './toggleFieldMixin'

export default {
  name: 'RadioOptions',
  mixins: [toggleFieldMixin],
  props: {
    label: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    formatter: {
      type: Function,
      default: null
    }
  },
  methods: {
    defaultFormatter(val) {
      const option = this.options.find((o) => o.value === val)
      return option ? option.label : null
    }
  }
}
</script>

<style lang="scss" scoped>
.radioGroupEdit {
  margin-top: -11px;
  ::v-deep .v-input {
    legend.v-label {
      font-size: 0.937rem !important;
      flex-basis: 100%;
      margin-bottom: 4px;
      height: auto;
    }
    label.v-label {
      color: $ads-navy;
    }
  }
}
</style>
