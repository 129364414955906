<template>
  <div class="mx-12">
    <!-- Core Application SHS offer alerts -->
    <template v-if="isShsAlerts">
      <Alert
        v-if="isApplicationSentToErnAndOfferDeclined"
        in-page
        type="error"
        icon="mdi-alert-circle"
        background-colour
        :text="`This enrolment application is invalid and should be withdrawn.`"
      >
        <template #optional>
          <div class="pt-3 alert--textsimple">
            <p>
              {{ firstName }}'s parent/carer has declined your selective high
              school offer after submitting an enrolment application.
            </p>
            <p class="mb-0">
              <a href="#" @click.prevent="$emit('withdrawApplication')">
                <strong>
                  Withdraw {{ firstName }} {{ familyName }}'s (SRN {{ srn }})
                  enrolment in ERN
                </strong>
              </a>
            </p>
          </div>
        </template>
      </Alert>
      <Alert
        v-if="isShsInProgressDeclined"
        in-page
        type="error"
        icon="mdi-alert-circle"
        background-colour
        :text="`This enrolment application is invalid and should be withdrawn.`"
      >
        <template #optional>
          <div class="pt-3 alert--textsimple">
            <p class="mb-0">
              {{ firstName }}'s parent/carer has declined your selective high
              school offer after submitting an enrolment application.
            </p>
          </div>
        </template>
      </Alert>
      <Alert
        v-if="isEnrolmentInvalid"
        in-page
        icon="mdi-lock-outline"
        class="greyBorderAndIcon"
        :text="`This enrolment application cannot be withdrawn because ${firstName} has already attended the school.`"
      >
        <template #optional>
          <div class="pt-3 alert--textsimple">
            <p>
              {{ firstName }}'s parent/carer declined your school's selective
              stream offer after submitting an enrolment application. Enrolment
              application marked as invalid.
            </p>
            <p class="mb-0">
              <a href="#" @click="$emit('viewErnRecord')">
                <strong
                  >Go to {{ firstName }}'s ERN record
                  <template v-if="application.student.srn"
                    >(SRN {{ getValue('student.srn') }})</template
                  ></strong
                >
              </a>
            </p>
          </div>
        </template>
      </Alert>
      <Alert
        v-if="isEnrolmentInternalTransfer"
        in-page
        icon="mdi-lock-outline"
        class="greyBorderAndIcon"
        text="Selective enrolment application marked as internal transfer"
        :subtext="`${firstName} ${familyName}'s parent/carer(s) have submitted a separate mainstream enrolment application, which has been internally transferred to a selective high school enrolment in ERN.`"
      />
    </template>
  </div>
</template>

<script>
import { SHS_OFFER_STATUS, STATUS } from '@/constants'
import { Alert } from '@nswdoe/doe-ui-core'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'ApplicationContentTopAlerts',
  components: {
    Alert
  },

  mixins: [fieldHelperMixin],
  inject: {
    applicationReactiveObject: {
      from: 'application'
    },
    shsOfferReactiveObject: {
      from: 'shsOffer'
    }
  },
  computed: {
    application() {
      return this.applicationReactiveObject?.value
    },
    shsOffer() {
      return this.shsOfferReactiveObject?.value
    },
    firstName() {
      return this.application?.student?.firstName || 'Student'
    },
    familyName() {
      return this.application?.student?.familyName || ''
    },
    srn() {
      return this.application?.student?.srn || ''
    },
    isEnrolmentInvalid() {
      return this.application?.applicationStatus === STATUS.INVALID
    },
    isEnrolmentInternalTransfer() {
      return this.application?.applicationStatus === STATUS.INTERNAL_TRANSFER
    },
    isShsAlerts() {
      const { shsOfferId: applicationShsOfferId } = this.application || {}
      const { shsOfferId: offerShsOfferId } = this.shsOffer || {}

      return applicationShsOfferId && applicationShsOfferId === offerShsOfferId
    },
    isShsInProgressDeclined() {
      const { offerStatus = '' } = this.shsOffer || {}
      const { applicationStatus = '' } = this.application || {}
      return (
        this.isShsAlerts &&
        offerStatus.toUpperCase() === SHS_OFFER_STATUS.DECLINED.toUpperCase() &&
        [
          STATUS.IN_PROGRESS.toUpperCase(),
          STATUS.RECEIVED.toUpperCase()
        ].includes(applicationStatus.toUpperCase())
      )
    },
    isApplicationSentToErnAndOfferDeclined() {
      const { offerStatus = '' } = this.shsOffer || {}
      const { applicationStatus = '' } = this.application || {}

      return (
        this.isShsAlerts &&
        offerStatus.toUpperCase() === SHS_OFFER_STATUS.DECLINED.toUpperCase() &&
        applicationStatus.toUpperCase() === STATUS.SENT.toUpperCase()
      )
    }
  }
}
</script>

<style scoped lang="scss">
.greyBorderAndIcon {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }
  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
}
// Remove this chunk during ADS3 upgrade
::v-deep .v-alert__wrapper {
  margin: 5px 18px 0;
}
</style>
