<template>
  <div aria-live="polite" class="d-flex align-center alertMessage">
    <v-icon class="mr-2">mdi-alert</v-icon>
    <p class="alertMessage my-0">
      <slot name="default" />
    </p>
  </div>
</template>

<script>
export default {
  name: 'AlertMessage'
}
</script>

<style lang="scss" scoped>
.alertMessage {
  color: $color-error-dark;
  .v-icon {
    color: $color-error-dark;
  }
}
</style>
