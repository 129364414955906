<!--
  Provides the template for the expandable application section bar
-->
<template>
  <div class="ApplicationSectionBar">
    <h2 v-if="!isPrintPreview" class="unstyled">
      <button
        type="button"
        @click="toggleSection"
        @focus="onFocus()"
        :aria-expanded="isSectionExpanded.toString()"
        :aria-controls="ariaControls"
        title="Click to expand or collapse this section"
        ref="sectionHeader"
        class="sectionHeader unstyled"
      >
        <v-icon v-if="showErrorIcon" class="error--text pr-5"> error </v-icon>
        <div :class="showErrorIcon ? 'sectionTitle' : 'sectionTitle pl-11'">
          {{ section.label }}
        </div>
        <font-awesome-icon
          focusable="false"
          class="icon"
          v-if="isSectionExpanded"
          icon="angle-up"
        />
        <font-awesome-icon
          focusable="false"
          class="icon"
          v-if="!isSectionExpanded"
          icon="angle-down"
        />
      </button>
    </h2>

    <div v-if="isPrintPreview" class="printPreviewSectionTitle">
      <h2>{{ sectionIndex }}. {{ section.label }}</h2>
    </div>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
export default {
  name: 'ApplicationSectionBar',
  props: {
    section: {
      type: Object
    },
    sectionIndex: {
      type: [Number, String]
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    ariaControls() {
      return this.isSectionExpanded ? this.section.id + '-fields' : false
    },
    isSectionExpanded() {
      return this.$store.state.sectionExpandedId === this.section.id
    },
    showErrorIcon() {
      return (
        this.showMissingInfoIcon ||
        this.showConflictFieldsIcon ||
        this.showUnresolvedMatchingIcon ||
        this.showInvalidFieldsIcon
      )
    },
    showMissingInfoIcon() {
      return this.missingFields.find(
        (missingFieldSection) =>
          this.section.id === missingFieldSection.section.id
      )
    },
    showConflictFieldsIcon() {
      return this.conflictFields.find(
        (conflictFieldSection) =>
          this.section.id === conflictFieldSection.section.id
      )
    },
    showUnresolvedMatchingIcon() {
      return this.unresolvedMatching.find(
        (unresolvedMatchingSection) =>
          this.section.id === unresolvedMatchingSection.section.id
      )
    },
    showInvalidFieldsIcon() {
      return this.invalidFields.find(
        (invalidField) => this.section.id === invalidField.section.id
      )
    }
  },
  methods: {
    toggleSection() {
      if (
        this.$store.state.sectionExpandedId &&
        this.$store.state.sectionExpandedId !== this.section.id
      ) {
        // If switching between different open sections, scroll the section
        // header to the top of the viewport to avoid ending up with a "random"
        // scroll position (caused by hiding the original section)
        window.OES.scrollSectionToTop = true
      }
      this.$store.dispatch('toggleSection', this.section)
    },
    onFocus() {
      // If focused section bar is concealed below Save/Cancel bar, scroll it into view.
      // Timeout is required to prevent scroll interfering with the click event
      var distanceFromBottom =
        window.innerHeight - this.$el.getBoundingClientRect().bottom
      var saveBarHeight = 65
      if (this.$store.state.isEditing && distanceFromBottom < saveBarHeight) {
        window.scrollTo(
          window.pageXOffset,
          window.pageYOffset + (saveBarHeight - distanceFromBottom)
        )
      }
    }
  },
  updated() {
    // When opening a section, scroll the section bar to the top of the screen
    if (window?.OES?.scrollSectionToTop && this.isSectionExpanded) {
      var spacingForFocusOutline = 4
      window.OES.scrollSectionToTop = false
      window.scrollTo(
        window.pageXOffset,
        window.pageYOffset +
          this.$el.getBoundingClientRect().top -
          spacingForFocusOutline
      )
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationSectionBar {
  display: flex;

  h2 {
    width: 100%;
    margin-bottom: 0;

    .sectionHeader {
      height: 3.5rem;
      width: 100%;
      padding: 1rem 1rem 1rem 2rem;
      display: flex;
      align-items: center;
      border-top: 1px solid rgba(0, 0, 0, 0.12);
      &:focus {
        outline: none;
        background: $color-row-hover;
      }

      .icon {
        vertical-align: middle;
        height: 1.5em;
        width: 1.5em;
        padding: 0.25em;
        font-size: 0.8em;
      }

      .sectionTitle {
        flex: 1;
        h2 {
          margin: 0;
        }
      }
    }
  }

  .printPreviewSectionTitle {
    padding: 1rem 1rem 0;
    width: 100%;
    h2 {
      margin: 0;
      font-size: 1.4em;
      border-bottom: 1px solid;
      padding-bottom: 0.5rem;
    }
  }

  button:hover {
    background-color: $color-field-hover;
  }
}
</style>
