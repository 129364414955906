<template>
  <div>
    <template v-if="isPnrApplication">
      <pnr-parent-carer
        v-for="(parent, pIndex) in parentCarers"
        :key="`${pIndex}`"
        :parent-carer="parent"
        :rules="pIndex === 0 ? [validators.contactRequired] : []"
        :readonly="readonly"
        :data-testid="`parentCarer${pIndex}`"
      />
    </template>
    <template v-else>
      <parent-carer
        v-for="(parent, pIndex) in parentCarers"
        :key="`${pIndex}`"
        :parent-carer="parent"
        :rules="[validators.contactRequired]"
        :readonly="readonly"
        :data-testid="`parentCarer${pIndex}`"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ParentCarer from './ParentCarer.vue'
import PnrParentCarer from './PnrParentCarer.vue'

export default {
  name: 'ParentCarers',
  components: {
    ParentCarer,
    PnrParentCarer
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      contactCount: 0
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing']),
    parentCarers() {
      return this.application.parentCarers
    },
    isPnrApplication() {
      return (
        this.application.adviceType && this.application.adviceType === 'PNR'
      )
    },
    validators() {
      return {
        contactRequired: (parentCarer) => {
          return parentCarer.contactDetails?.length > 0
            ? true
            : 'Contact details required'
        }
      }
    }
  }
}
</script>
