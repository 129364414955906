<template>
  <div class="px-8 pt-4 pb-2">
    <no-details v-if="!walkups || walkups.length === 0" detail-name="walkups" />
    <v-row v-else>
      <v-col
        v-for="(walkup, index) in orderedWalkups"
        :key="index"
        cols="12"
        md="4"
      >
        <div class="mb-3">{{ 'Walkup ' + parseInt(index + 1) }}</div>
        <div>
          <div class="float-left">
            <v-icon
              :class="
                statusBasedIconClass(
                  walkup.schoolName + '|' + walkup.registrationStatus
                )
              "
            >
              {{ getIcon(walkup.registrationStatus) }}</v-icon
            >
          </div>
          <div
            class="walkup-text float-left"
            :class="
              sameSchoolClass(
                walkup.schoolName + '|' + walkup.registrationStatus
              )
            "
          >
            {{ walkup.schoolName }}
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Y67T_STATUS_ICONS } from '@/constants'
import NoDetails from '@/components/application/NoDetails'
import _ from 'lodash'

export default {
  name: 'Y67tFormWalkups',
  components: {
    NoDetails
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['application', 'selectedSchool']),
    walkups() {
      return this.application.walkups
    },
    orderedWalkups() {
      return _.orderBy(this.walkups, 'schoolName')
    }
  },
  methods: {
    getIcon(walkup) {
      return Y67T_STATUS_ICONS[walkup]
    },
    statusBasedIconClass(round) {
      const statusClasses = []
      if (round.split('|')[0]) {
        round.split('|')[0] === this.selectedSchool?.schoolName
          ? statusClasses.push('same-school')
          : statusClasses.push('different-school')
      }
      if (round) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-icon-' +
                round.split('|')[1].replace(' ', '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    },
    sameSchoolClass(round) {
      const statusClasses = []
      if (round.split('|')[0]) {
        round.split('|')[0] === this.selectedSchool
          ? statusClasses.push('same-school')
          : statusClasses.push('different-school')
      }
      if (round.split('|')[1]) {
        round.split('|')[1]
          ? statusClasses.push(
              'status-' + round.split('|')[1].replace(' ', '-').toLowerCase()
            )
          : ''
      }
      return statusClasses.join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.walkup-text {
  padding-left: 5px;
  padding-top: 2px;
}
.status-active,
.status-offered,
.status-submitted {
  font-weight: bold;
}
.status-offer-expired,
.status-declined,
.status-withdrawn,
.status-not-required,
.status-inactive,
.status-not-accepted {
  text-decoration-line: line-through;
  color: $ads-dark-60;
}
.status-icon-withdrawn,
.status-icon-declined,
.status-icon-offer-expired,
.status-icon-not-required,
.status-icon-inactive,
.status-icon-not-accepted,
.status-icon-pending {
  color: $ads-dark-60;
}
.status-pending {
  color: $ads-dark-60;
}
.status-icon-submitted.same-school {
  color: $ads-success-green;
}
.status-icon-offered.same-school {
  color: $ads-info-blue;
}
.status-icon-active.same-school {
  color: $ads-warning-orange;
}
.different-school {
  color: $ads-dark-60;
}
</style>
