import API from '@/store/apiUtils'
import { Y67T_STATUS } from '@/constants'

const y67tSummaryModule = {
  state: {
    comparisonsPerRound: {},
    numbersTracker: {},
    eoiApplications: [],
    isLoadingChart: true,
    isLoadingBar: true,
    numbersTarget: null,
    targetText: 0,
    remainingSpots: null,
    barData: [],
    seriesTotal: null,
    summaryCustomisations: {}
  },
  getters: {
    comparisonsPerRound: (state) => state.comparisonsPerRound,
    numbersTracker: (state) => state.numbersTracker,
    isLoadingChart: (state) => state.isLoadingChart,
    isLoadingBar: (state) => state.isLoadingBar,
    numbersTarget: (state) => state.numbersTarget,
    eoiApplications: (state) => state.eoiApplications,
    targetText: (state) => state.targetText,
    remainingSpots: (state) => state.remainingSpots,
    barData: (state) => state.barData,
    seriesTotal: (state) => state.seriesTotal,
    summaryCustomisations: (state) => state.summaryCustomisations
  },
  mutations: {
    setComparisonPerRounds(state, { schoolId, applications }) {
      const currentYear = new Date().getFullYear().toString()
      const eoiApplications = applications.filter(
        (app) =>
          app.applicationStatus !== 'AutoDraft' &&
          currentYear === new Date(app.psDateSentToERN).getFullYear().toString()
      )
      const ooaRound1SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 1 && o.schoolCode === schoolId
        )
      ).length
      const ooaRound2SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 2 && o.schoolCode === schoolId
        )
      ).length
      const ooaRound3SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 3 && o.schoolCode === schoolId
        )
      ).length
      const localHighSchoolRound1Count = eoiApplications?.filter(
        (a) =>
          a.preferenceNo === 1 &&
          a.localHighSchool?.schoolCode === schoolId &&
          a.applicationStatus !== Y67T_STATUS.NOT_REQUIRED
      ).length

      const localHighSchoolRound2Count = eoiApplications?.filter(
        (a) =>
          a.preferenceNo === 2 && a.localHighSchool?.schoolCode === schoolId
      ).length

      const localHighSchoolRound3Count = eoiApplications?.filter(
        (a) =>
          a.preferenceNo === 3 && a.localHighSchool?.schoolCode === schoolId
      ).length

      const localHighSchoolRound4Count = eoiApplications?.filter(
        (a) =>
          a.preferenceNo === 4 && a.localHighSchool?.schoolCode === schoolId
      ).length

      const comparisonsPerRound = {
        round1ooa: ooaRound1SchoolsCount,
        round2ooa: ooaRound2SchoolsCount,
        round3ooa: ooaRound3SchoolsCount,
        round1local: localHighSchoolRound1Count,
        round2local: localHighSchoolRound2Count,
        round3local: localHighSchoolRound3Count,
        round4local: localHighSchoolRound4Count
      }
      state.comparisonsPerRound = comparisonsPerRound
      state.isLoadingChart = false
    },
    setNumbersTracker(state, applications) {
      const currentYear = new Date().getFullYear().toString()
      const eoiApplications = applications.filter(
        (app) =>
          app.applicationStatus !== 'AutoDraft' &&
          currentYear === new Date(app.psDateSentToERN).getFullYear().toString()
      )

      state.eoiApplications = eoiApplications
      const submitted = eoiApplications?.filter(
        (a) => a.applicationStatus === 'Submitted'
      ).length
      const offered = eoiApplications?.filter(
        (a) => a.applicationStatus === 'Offered'
      ).length

      state.numbersTracker = {
        submitted: submitted,
        offered: offered
      }
    },
    setIsLoadingComparison(state, value) {
      state.isLoadingChart = value
    },
    setIsLoadingNumberTracker(state, value) {
      state.isLoadingBar = value
    },
    setNumbersTarget(state, value) {
      if (value != null) {
        let newVal =
          state.numbersTracker.submitted + state.numbersTracker.offered - value
        if (newVal < 0) {
          state.targetText = value
          state.remainingSpots = Math.abs(newVal)
          state.seriesTotal =
            state.numbersTracker.submitted +
            state.numbersTracker.offered +
            state.remainingSpots
          state.numbersTarget = state.numbersTarget == 0 ? 0.01 : 0
        } else {
          state.targetText = value
          state.remainingSpots = 0
          state.seriesTotal =
            state.numbersTracker.submitted +
            state.numbersTracker.offered +
            state.remainingSpots
          state.numbersTarget = state.seriesTotal - value
        }
      } else {
        state.targetText = 0
        state.remainingSpots = 0
        state.seriesTotal = 0
        state.numbersTarget = null
      }
      state.barData = [
        {
          name: 'Remaining spots',
          data: [state.remainingSpots]
        },
        {
          name: 'Offered',
          data: [state.numbersTracker.offered]
        },
        {
          name: 'Submitted',
          data: [state.numbersTracker.submitted]
        }
      ]
      state.isLoadingBar = false
    },
    setSummaryCustomisation(state, { schoolCode, customisation }) {
      state.summaryCustomisations[schoolCode] = customisation
    }
  },
  actions: {
    getComparisonPerRounds({ commit, getters }) {
      const school = getters.selectedSchool
      if (!school) {
        return { studentApplications: [] }
      }
      const promises = []
      promises.push(
        API.get(
          `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${school.schoolCode}`,
          false,
          { schoolCode: school.schoolCode }
        ).then((res) => {
          return res.data.studentApplications
        })
      )
      Promise.all(promises).then((responses) => {
        const applications = responses[0]
        const summaryApplications = applications.map((a) => {
          // flatten application data
          const app = { ...a, ...a.application }
          app.name = app.student.familyName + ', ' + app.student.firstName
          return app
        })
        commit('setComparisonPerRounds', {
          schoolId: school.schoolCode,
          applications: summaryApplications
        })
      })
    },
    getNumbersTracker({ commit, dispatch, getters }) {
      const school = getters.selectedSchool
      if (!school) {
        return { studentApplications: [] }
      }
      const promises = []
      promises.push(
        API.get(
          `${process.env.VUE_APP_API_PI}/v1/y67t/eoi/submitted/${school.schoolCode}`,
          false,
          { schoolCode: school.schoolCode }
        ).then((res) => {
          return res.data.studentApplications
        })
      )
      Promise.all(promises).then((responses) => {
        const applications = responses[0]
        const summaryApplications = applications.map((a) => {
          // flatten application data
          const app = { ...a, ...a.application }
          app.name = app.student.familyName + ', ' + app.student.firstName
          return app
        })
        commit('setNumbersTracker', summaryApplications)
        dispatch('getSummaryCustomisation', school.schoolCode)
      })
    },
    setIsLoadingComparison({ commit }, value) {
      commit('setIsLoadingComparison', value)
    },
    setIsLoadingNumberTracker({ commit }, value) {
      commit('setIsLoadingNumberTracker', value)
    },
    async getSummaryCustomisation({ commit, dispatch }, schoolCode) {
      const currentYear = new Date().getFullYear()
      await dispatch('getSavedSettings', schoolCode).then((savedSettings) => {
        if (
          savedSettings === null ||
          !savedSettings ||
          !savedSettings?.customisation ||
          !savedSettings?.customisation?.y67t
        ) {
          commit('setNumbersTarget', null)
        } else {
          let savedCustomisationSettings = savedSettings?.customisation
          if (savedCustomisationSettings?.y67t) {
            const y67tCapacityCurrentYearSettings =
              savedCustomisationSettings.y67t.filter(
                (a) => a.calendarYear === currentYear
              )
            if (y67tCapacityCurrentYearSettings?.length > 0) {
              commit(
                'setNumbersTarget',
                y67tCapacityCurrentYearSettings[0].y7forecast
              )
            } else {
              commit('setNumbersTarget', null)
            }
          } else {
            commit('setNumbersTarget', null)
          }
        }
      })
    },
    async saveSummaryTarget({ commit, dispatch, getters }, value) {
      const school = getters.selectedSchool
      const currentYear = new Date().getFullYear()
      await dispatch('getSavedSettings', school.schoolCode).then(
        (savedSettings) => {
          if (savedSettings === null) {
            API.get(
              `${process.env.VUE_APP_REFDATA_API}/global-web-content-ref.json`
            ).then((response) => {
              const globalRefSettings = response.data
              const criteria = globalRefSettings.ooa.criteria.map((s, i) => {
                return {
                  code: s.code,
                  order: i + 1,
                  enabled: i === 0,
                  description: ''
                }
              })
              const currentYearTimeFrame =
                globalRefSettings.ooa.currentYearTimeFrames.find(
                  (t) => t.default
                )
              const nextYearTimeFrame =
                globalRefSettings.ooa.nextYearTimeFrames.find((t) => t.default)
              const whoWillContact = globalRefSettings.local.whoWillContact
              const currentYearTimeFrameLocal =
                globalRefSettings.local.currentYearTimeFrames.find(
                  (t) => t.default
                )
              const nextYearTimeFrameLocal =
                globalRefSettings.local.nextYearTimeFrames.find(
                  (t) => t.default
                )
              //Re create default object for customisation
              const settings = {
                schoolCode: school.schoolCode,
                ooa: {
                  criteria,
                  enableOtherCriteria: true,
                  captureSiblingInfo: true,
                  currentYearTimeFrames: { code: currentYearTimeFrame.code },
                  nextYearTimeFrames: { code: nextYearTimeFrame.code },
                  capacity: { code: 'Approach' }
                },
                local: {
                  whoWillContact: whoWillContact,
                  currentYearTimeFrames: {
                    code: currentYearTimeFrameLocal.code
                  },
                  nextYearTimeFrames: {
                    code: nextYearTimeFrameLocal.code
                  }
                },
                y67t: [{ calendarYear: currentYear, y7forecast: value }]
              }
              dispatch('saveSettings', settings).then(() => {
                commit('setNumbersTarget', value)
                commit('setIsLoadingNumberTracker', false)
              })
            })
          } else {
            let savedCustomisationSettings = savedSettings?.customisation
            if (savedCustomisationSettings?.y67t) {
              let y67tCapacityCurrentYearSettings =
                savedCustomisationSettings.y67t.filter(
                  (a) => a.calendarYear === currentYear
                )
              if (y67tCapacityCurrentYearSettings?.length > 0) {
                y67tCapacityCurrentYearSettings =
                  y67tCapacityCurrentYearSettings.filter(function (el) {
                    return el.calendarYear != currentYear
                  })
                delete savedCustomisationSettings.y67t
              }
              y67tCapacityCurrentYearSettings.push({
                calendarYear: currentYear,
                y7forecast: value
              })
              savedCustomisationSettings.y67t = y67tCapacityCurrentYearSettings
            } else {
              savedCustomisationSettings.y67t = [
                { calendarYear: currentYear, y7forecast: value }
              ]
            }
            dispatch('saveSettings', savedCustomisationSettings).then(() => {
              commit('setNumbersTarget', value)
              commit('setIsLoadingNumberTracker', false)
            })
          }
        }
      )
    }
  }
}

export default y67tSummaryModule
