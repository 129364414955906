// Contains all logic for detecting conflicts, and provides helper functions for
// setting necessary flags within the data model.

import { FIELD_TYPE, CONFLICT_ALERTING, ADDRESS_MAPPINGS } from '@/constants'
import UTILS from '@/store/utils'
import { replace } from 'lodash'

export default {
  setConflictFlag(modelRow) {
    let { field, ernValue, parent } = modelRow
    let isConflictForParent =
      parent &&
      (parent.field.type === FIELD_TYPE.GROUP ||
        parent.field.conflictAlerting === CONFLICT_ALERTING.AB_GROUP)

    /*
      Skip conflict checking if any of these:
      - No ERN value
      - Already resolved
      - Parent is already resolved
      - Field is configured with noConflictAlerting=true
      - Field is has ern coalesce property AND OES is empty AND ERN is not empty
      - Parent already has conflict and parent is GROUP type or AB_GROUP alerting
      - Field is GROUP type (child fields must be checked)
      - Field is COLLECTION:AB_GROUP (child fields must be checked)
      - Field is COLLECTION:AB (child fields must be checked)
      - Field is in a record which is part of a LIST-type collection (LIST-type collections do not need to check conflicts at a field level)
    */
    // If ERN has value and has conflict with OES, we don't want to resolve conflict manually and only display alert message
    // Currently, there are four fields(Occupation, Occupation group, Highest level of schooling completed and Highest qualification completed) display alert message.
    // For details, see ticket FUS-683
    // We would like to display empty ERN value when it is empty string
    if (ernValue || ernValue === '') {
      modelRow.fieldConflictAlerting = this.isFieldConflict(modelRow)
        ? true
        : undefined
      modelRow.displayfieldAlerting = field?.displayfieldAlerting
    }

    if (
      UTILS.isEmpty(ernValue) ||
      modelRow.isResolved ||
      field.noConflictAlerting ||
      (isConflictForParent && parent.isConflict) ||
      field.type === FIELD_TYPE.GROUP ||
      field.conflictAlerting === CONFLICT_ALERTING.AB_GROUP ||
      field.conflictAlerting === CONFLICT_ALERTING.AB ||
      (parent && parent.field.conflictAlerting === CONFLICT_ALERTING.LIST)
    ) {
      return
    }

    let isConflict
    if (field.conflictAlerting === CONFLICT_ALERTING.LIST) {
      isConflict = true
    } else if (field.type === FIELD_TYPE.ADDRESS) {
      isConflict = this.isAddressConflict(modelRow) ? true : undefined
    } else {
      isConflict = this.isFieldConflict(modelRow) ? true : undefined
    }

    // Field groups and records with 'AB_GROUP' conflictAlerting do not
    // highlight individual field conflicts. Instead the whole record or
    // group becomes resolvable as a block. To achieve this, any individual
    // field conflicts will set a flag on the parent record/group, not the field
    // itself.
    if (isConflictForParent) {
      parent.isConflict = isConflict
    } else {
      modelRow.isConflict = isConflict
    }
  },

  isFieldConflict({ field, oesValue, ernValue }) {
    if (field.type === FIELD_TYPE.NAME) {
      return this.isNameConflict(oesValue, ernValue)
    } else if (this.isFieldFuzzy(field)) {
      return !UTILS.isFuzzyMatch(oesValue, ernValue)
    }
    return oesValue !== ernValue
  },

  isFieldFuzzy(field) {
    // True if specified field should use fuzzy matching.
    // NOTE: TEXT is the default field type and does not need to be
    // specified on the field, hence "undefined" catches these:
    var fieldTypesToFuzzyMatch = [
      undefined,
      FIELD_TYPE.TEXTAREA,
      FIELD_TYPE.PHONE
    ]
    return fieldTypesToFuzzyMatch.findIndex((type) => type === field.type) >= 0
  },

  isAddressConflict({ oesValue, ernValue }) {
    if (!ernValue || ernValue.ernAddressRecordNo === undefined) {
      return false
    }

    var oesAddress
    var ernAddress

    if (oesValue.countryCode === 'AUS') {
      // Australian address confilict resolution
      oesAddress = [
        oesValue.addressLine1,
        oesValue.addressLine2,
        oesValue.suburbName,
        oesValue.stateCode,
        oesValue.postCode,
        oesValue.countryCode
      ]
        .join(' ')
        .toUpperCase()
      oesAddress = this.normaliseAddress(oesAddress)
      ernAddress = [
        ernValue.addressLine1,
        ernValue.addressLine2,
        ernValue.suburbName,
        ernValue.stateCode,
        ernValue.postCode,
        ernValue.countryCode
      ]
        .join(' ')
        .toUpperCase()
      ernAddress = this.normaliseAddress(ernAddress)
    } else {
      // Foreign address confilict resolution
      oesAddress = [
        oesValue.addressLine1,
        oesValue.addressLine2,
        oesValue.countryCode
      ]
        .join(' ')
        .toUpperCase()
      oesAddress = this.normaliseAddress(oesAddress)

      ernAddress = [
        ernValue.addressLine1,
        ernValue.addressLine2,
        ernValue.countryCode
      ]
        .join(' ')
        .toUpperCase()
      ernAddress = this.normaliseAddress(ernAddress)
    }

    return oesAddress !== ernAddress
  },

  isNameConflict(a, b) {
    return a?.toLowerCase() !== b?.toLowerCase()
  },

  normaliseAddress(address) {
    if (!address) {
      return ''
    }
    const addressString = address.toUpperCase().replace(/\s+/g, ' ').trim()
    const addressArray = addressString.split(' ')
    const addressMapArr = ADDRESS_MAPPINGS.map((arr) => arr[0])
    const abbAddressArray = addressArray.map((addStr) => {
      // Exactly compare the address and convert it to abbreviation
      if (addressMapArr.includes(addStr)) {
        return ADDRESS_MAPPINGS.reduce(
          (normalisedAddress, [from, to]) =>
            replace(
              normalisedAddress,
              new RegExp(from.toUpperCase(), 'g'),
              to.toUpperCase()
            ),
          addStr
        )
      }
      return addStr
    })
    return abbAddressArray.join(' ')
  },

  formatWithoutAbbreviation(address) {
    if (!address) {
      return ''
    }
    const addressString = address.toUpperCase().replace(/\s+/g, ' ').trim()
    return addressString
  }
}
