<template>
  <!-- eslint-disable max-len -->
  <div class="pt-0" ref="parentCarerEOIDetails">
    <p class="pb-3 page-title">
      Enter the details of the parent/carer completing this expression of
      interest on behalf of the student. We use this information to contact you
      through the process.
    </p>
    <v-card class="mb-6">
      <v-form
        ref="parentCarerEoIForm"
        class="v-form"
        data-testid="parent-carer-form"
        lazy-validation
      >
        <div class="pa-6">
          <h2 class="section-heading mb-8" data-testid="parent-carer-name">
            Parent/carer details
          </h2>
          <AdsSelect
            v-model="parentCarers.parentCarerRelation"
            class="mb-6"
            :items="relationshipTypes"
            label="Relationship"
            placeholder="Select..."
            hint="e.g. mother, father"
            persistent-placeholder
            persistent-hint
            :aria-label="
              ariaLabel(parentCarers.parentCarerRelation, 'relationship')
            "
            :rules="[
              validators.required(
                parentCarers.parentCarerRelation,
                'Relationship'
              )
            ]"
            required
            data-testid="parent-carer-relation"
          />

          <AdsSelect
            v-model="parentCarers.parentCarerTitle"
            class="mb-6"
            :items="titles"
            label="Title"
            placeholder="Select..."
            :aria-label="ariaLabel(parentCarers.parentCarerTitle, 'title')"
            :rules="[
              validators.required(parentCarers.parentCarerTitle, 'Title')
            ]"
            required
            data-testid="parent-carer-title"
            persistent-placeholder
          />

          <AdsTextField
            v-model.trim="parentCarers.parentCarerGivenName"
            class="mb-6"
            label="Given name"
            placeholder="Enter given name"
            :rules="[
              validators.required(
                parentCarers.parentCarerGivenName,
                'Given name'
              ),
              validators.name(parentCarers.parentCarerGivenName)
            ]"
            required
            maxlength="200"
            data-testid="parent-carer-given-name"
            persistent-placeholder
          />

          <AdsTextField
            v-model.trim="parentCarers.parentCarerFamilyName"
            class="mb-6"
            label="Family name"
            placeholder="Enter family name"
            required
            :rules="[
              validators.required(
                parentCarers.parentCarerFamilyName,
                'Family name'
              ),
              validators.name(parentCarers.parentCarerFamilyName)
            ]"
            maxlength="200"
            data-testid="parent-carer-family-name"
            persistent-placeholder
          />

          <AdsTextField
            validate-on-blur
            v-model.trim="parentCarers.parentCarerEmail"
            class="mb-6"
            label="Contact email"
            placeholder="Enter email"
            :rules="[
              validators.required(
                parentCarers.parentCarerEmail,
                'Contact email'
              ),
              validators.email(parentCarers.parentCarerEmail, 'email address')
            ]"
            required
            maxlength="200"
            data-testid="parent-carer-email"
            persistent-placeholder
          />

          <div
            v-for="(contact, idx) in parentCarers.contactDetails"
            :key="idx"
            class="mx-n6 contact-border"
          >
            <div class="ma-6">
              <v-row class="d-flex align-start justify-space-between my-6">
                <v-col cols="10" md="11">
                  <h2
                    data-testid="contact-details"
                    class="section-heading mb-1"
                  >
                    <span class="de-emphasise">
                      <span
                        v-if="parentCarers && parentCarers.parentCarerGivenName"
                        >{{ pluralisedFirstName }}</span
                      >
                      contact number {{ idx + 1 }}:
                    </span>
                    {{ contactTypeName(contact.contactType) }}
                  </h2>
                  <span v-if="idx === 0" class="hint"
                    >Add at least one contact number
                    <span
                      v-if="parentCarers && parentCarers.parentCarerGivenName"
                      >for {{ parentCarers.parentCarerGivenName }}
                    </span>
                  </span>
                </v-col>
                <v-col cols="2" md="1">
                  <v-btn
                    v-if="idx !== 0"
                    text
                    icon
                    class="remove-btn no-outline blue-icon mt-n4"
                    aria-label="remove parent / carer"
                    data-testid="remove-contact-btn"
                    @click="removeContact(idx)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <AdsSelect
                v-model="contact.contactType"
                class="mb-6"
                :items="contactTypes"
                label="Phone number type"
                placeholder="Enter type"
                :aria-label="ariaLabel(contact.contactType, 'contacttype')"
                :rules="[
                  validators.required(contact.contactType, 'Phone number type')
                ]"
                required
                data-testid="contact-contact-type"
                persistent-placeholder
              />
              <AdsFormatContactField
                validate-on-blur
                v-model.trim="contact.contactValue"
                class="mb-6"
                label="Enter number"
                placeholder="Enter number"
                required
                :rules="[
                  validators.required(contact.contactValue, 'Phone number'),
                  validators.phone(contact.contactValue),
                  validateSamePhoneNumber(
                    contact.contactType,
                    contact.contactValue,
                    idx
                  )
                ]"
                maxlength="132"
                data-testid="contact-contact-value"
                persistent-placeholder
              />

              <AdsTextArea
                class="comment-field"
                v-model.trim="contact.comments"
                label="Comments (optional)"
                placeholder="e.g. best time to call"
                data-testid="contact-comments"
                :counter="false"
                persistent-placeholder
              />
            </div>
          </div>
          <AdsButton
            v-if="showAddContactButton"
            class="no-outline addcontact-btn mt-n4"
            :button-text="addContactLabel"
            icon="add_circle_outline"
            x-large
            tertiary
            data-testid="add-another-contact-btn"
            @click="addContact"
          />
        </div>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  AdsButton,
  AdsTextField,
  AdsSelect,
  AdsTextArea,
  AdsFormatContactField
} from '@nswdoe/doe-ui-core'
import { CONTACT_PHONE_TYPE } from '@/constants'
import validators from '@/helpers/validators'

const emptyContact = {
  contactType: null,
  contactValue: null,
  comments: null
}

export default {
  name: 'ParentCarersEOIDetails',
  components: {
    AdsSelect,
    AdsTextField,
    AdsTextArea,
    AdsButton,
    AdsFormatContactField
  },
  data() {
    const { required, name, email, phone } = validators

    return {
      validators: {
        required,
        name,
        email,
        phone
      }
    }
  },
  computed: {
    ...mapGetters([
      'eoiApplication',
      'contactTypes',
      'relationshipTypes',
      'titles'
    ]),
    parentCarers() {
      return this.eoiApplication.parentCarers[0]
    },
    parentCarerName() {
      return this.fullName(this.parentCarers)
    },
    pluralisedFirstName() {
      const firstName = this.parentCarers
        ? this.parentCarers.parentCarerGivenName
        : null
      if (firstName) {
        return firstName.trim().slice(-1) === 's'
          ? `${firstName}'`
          : `${firstName}'s`
      }
      return ''
    },
    addContactLabel() {
      return this.parentCarers.contactDetails &&
        this.parentCarers.contactDetails.length > 0
        ? 'Add another contact number'
        : 'Add a contact number'
    },
    showAddContactButton() {
      return this.parentCarers.contactDetails
        ? this.parentCarers.contactDetails.length < this.contactTypes.length
        : true
    }
  },
  methods: {
    validate() {
      this.$store.dispatch(
        'setValidParentCarers',
        this.$refs.parentCarerEoIForm.validate()
      )
      this.$store.dispatch('setEoIApplication', this.eoiApplication)
    },
    fullName(person) {
      const firstName = person.parentCarerGivenName
      const lastName = person.parentCarerFamilyName

      if (firstName && lastName) {
        return `${firstName} ${lastName}`
      }
      return ''
    },
    addContact() {
      this.parentCarers.contactDetails.push(
        JSON.parse(JSON.stringify(emptyContact))
      )
    },
    removeContact(index) {
      this.parentCarers.contactDetails.splice(index, 1)
    },
    contactTypeName(type) {
      return CONTACT_PHONE_TYPE[type] || ''
    },
    ariaLabel(value, objectKey) {
      if (value) {
        const objects = {
          relationship: this.relationshipTypes,
          title: this.titles,
          contacttype: this.contactTypes
        }
        const itemType = objects[objectKey].find((item) => item.value === value)
        return itemType ? itemType.text : ''
      }
      return ''
    },
    validateSamePhoneNumber(type, phone, index) {
      let duplicateNumberExists = false
      if (index > 0 && phone?.length > 0) {
        const filteredContactDetails = this.parentCarers.contactDetails.filter(
          (x) =>
            x.contactType === type &&
            x.contactValue.replace(/\s/g, '') === phone.replace(/\s/g, '')
        )
        if (filteredContactDetails?.length > 1) {
          duplicateNumberExists = true
        }
      }
      return (
        !duplicateNumberExists || 'Same contact number must not be used again'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .comment-field.v-text-field.v-textarea {
    margin-left: 0 !important;
  }
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.section-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  font-style: normal;
  color: $ads-navy;
}
h2 {
  color: $ads-navy;
  font-size: 1.375rem;
  line-height: 1.2;
}
::v-deep .v-text-field.v-textarea {
  max-width: 800px !important;
  margin-left: 0px !important;
  margin-top: 5px;
}
.de-emphasise {
  font-weight: 500;
}
.blue-icon {
  color: $ads-blue-1 !important;
}
.no-outline {
  border: none !important;
}
.note {
  color: $color-text-body;
}
.hint {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $color-dark70;
}
.contact-border {
  border-top: 1px solid $ads-light-20 !important;
}
.addcontact-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.remove-btn {
  &:focus {
    border: 2px solid $ads-navy !important;
  }
}
</style>
