<!-- Component for filter options (Checkbox and radio seclection controls) -->
<template>
  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="false"
    class="filter-by-year-or-daterange"
  >
    <template #activator="{ on }">
      <a
        role="button"
        href="#"
        @click="menu = true"
        :aria-label="`Filter by ${label.toLowerCase()}`"
      >
        <v-chip
          class="chip"
          :disabled="filterOptions.disabled"
          :class="
            selectedFilterOptions || selectedFilterYearOptions ? 'selected' : ''
          "
          outlined
          v-on="on"
        >
          {{ label }}
          <v-icon
            v-if="selectedFilterOptions || selectedFilterYearOptions"
            class="pl-1"
            >cancel</v-icon
          >
          <v-icon v-else>arrow_drop_down</v-icon>
        </v-chip>
      </a>
    </template>
    <FocusTrap :is-active="isActive" v-if="!filterOptions.disabled">
      <v-card class="filterOptions" width="690px" :height="cardHeight">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <strong>{{ heading }}</strong>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <a
                href="#"
                @click="menu = false"
                class="dialogCloseBtn"
                aria-label="Close filter options menu"
                role="button"
              >
                <v-icon class="text-right closeBtn">mdi-close</v-icon>
              </a>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <div>
          <v-radio-group class="filter-by-options" v-model="selectedFilterBy">
            <v-list>
              <v-list-item>
                <v-radio
                  :label="'By year'"
                  :value="'year'"
                  class="filter-by"
                  @click="setSelectedRecord('year')"
                />
              </v-list-item>
              <v-list-item
                v-for="filter in filterOptionsInternal"
                :key="filter.value"
                class="year-list"
              >
                <OesCheckbox
                  v-model="selectedYearOptions"
                  :label="filter.label"
                  :value="filter.value"
                  class="checkFilter"
                  @click="$emit('updateYearFilter', selectedYearOptions)"
                  :disabled="yearDisabled"
                />
              </v-list-item>
            </v-list>
            <v-list>
              <v-list-item>
                <v-radio
                  :label="'By date range'"
                  :value="'daterange'"
                  class="filter-by"
                  @click="setSelectedRecord('daterange')"
                />
              </v-list-item>
              <div class="datePickerRange" v-if="!resetPickers">
                <DatePickerRange
                  :maxlength="10"
                  v-model="rangeDateOptions"
                  range-separator-text=""
                  :start-date-picker-attrs="{ label: 'From' }"
                  :end-date-picker-attrs="{ label: 'To' }"
                  @date-picker-activated="datePickerActivated"
                  @input="dateRangeUpdate"
                  :disabled="dateRangeDisabled"
                  persistent-placeholder
                />
                <div class="pb-4 clearfilter" v-if="cardHeight === 'auto'">
                  <a
                    href="#"
                    @click="clearFilter"
                    :class="dateRangeDisabled ? 'disabled' : ''"
                    >Clear dates</a
                  >
                </div>
              </div>
            </v-list>
            <v-list v-if="archiveType">
              <v-list-item class="px-7">
                <SearchArchive :archive-type="archiveType" />
              </v-list-item>
            </v-list>
          </v-radio-group>
        </div>
      </v-card>
    </FocusTrap>
  </v-menu>
</template>

<script>
import { FocusTrap, DatePickerRange } from '@nswdoe/doe-ui-core'
import OesCheckbox from './OesCheckbox.vue'
import SearchArchive from '@/components/app/SearchArchive.vue'
import _isEqual from 'lodash/isEqual'
import { STREAM } from '@/constants'

export default {
  name: 'YearDateRangeFilter',
  props: {
    yearOptions: {
      type: Array,
      default: () => []
    },
    filterOptions: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: 'Show Records'
    },
    showDefaultFilter: {
      type: Array,
      default: () => []
    },
    selectedApplication: {
      type: String,
      default: null
    },
    archiveType: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchArchive,
    FocusTrap,
    DatePickerRange,
    OesCheckbox
  },
  data() {
    return {
      menu: false,
      selectedYearOptions: [],
      rangeDateOptions: [],
      cardHeight: 'auto',
      dataPickerActivated: {
        start: false,
        end: false
      },
      resetPickers: false,
      isActive: false,
      dateRangeDisabled: true,
      yearDisabled: false,
      selectedFilterBy: 'year'
    }
  },
  watch: {
    yearOptions(newVal, oldVal) {
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        // don't clear the filters ( back to list view button)
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.setSelectedRecord('year')
        this.selectedYearOptions = this.showDefaultFilter
        this.$emit('updateYearFilter', this.selectedYearOptions)
        return
      }
      this.selectedYearOptions = []
    },
    schoolCode(newVal, oldVal) {
      // clear selected filters on change of school switcher
      if (newVal && oldVal && _isEqual(newVal, oldVal)) {
        this.selectedYearOptions = this.showDefaultFilter
        return
      }
      if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
        // don't clear the current year filter
        this.setSelectedRecord('year')
        this.selectedYearOptions = this.showDefaultFilter
        this.$emit('updateYearFilter', this.selectedYearOptions)
        return
      }
      this.selectedYearOptions = []
    },
    // clear selected filters on change of school switcher
    filterOptions(newVal, oldVal) {
      if (newVal && oldVal && newVal.toString() === oldVal.toString()) {
        // don't clear the filters ( back to list view button)
        return
      }
      this.rangeDateOptions = []
    },
    menu(opened) {
      this.isActive = opened
      this.$emit('menuOpened', opened)
    }
  },
  created() {
    // y67t set default filter - display applications for the current year
    if (this.showDefaultFilter && this.showDefaultFilter.length > 0) {
      this.selectedYearOptions = this.showDefaultFilter
    }
  },
  computed: {
    selectedFilterYearOptions() {
      return this.selectedYearOptions.length ? true : false
    },
    selectedFilterOptions() {
      let isSelected = false
      if (this.rangeDateOptions.length === 2) {
        isSelected = !!this.rangeDateOptions[0] && !!this.rangeDateOptions[1]
      }
      return isSelected ? true : false
    },
    filterOptionsInternal() {
      return this.yearOptions.map((option) =>
        typeof option === 'object' ? option : { value: option, label: option }
      )
    },
    schoolCode() {
      return this.$store.getters.selectedSchoolCode
    },
    heading() {
      return [STREAM.Y67T_PRIMARY, STREAM.Y67T_SECONDARY].includes(
        this.selectedApplication
      )
        ? 'Show EOIs'
        : 'Show start date'
    }
  },
  methods: {
    clearFilter() {
      this.selectedYearOptions = []
      this.$emit('updateYearFilter', this.selectedYearOptions)
      this.rangeDateOptions = []
      this.$emit('updatefilter', this.rangeDateOptions)

      // force pickers to re-render so that the internal dates get reset
      this.resetPickers = true
      this.$nextTick(() => {
        this.resetPickers = false
      })
    },
    datePickerActivated(activated, datePicker) {
      this.datePickerActivated[datePicker] = activated
      this.cardHeight =
        this.datePickerActivated.start || this.datePickerActivated.end
          ? '890px'
          : 'auto'
    },
    dateRangeUpdate([start, end]) {
      if (!!start && !!end) {
        this.$emit('updatefilter', [start, end])
      } else {
        this.$emit('updatefilter', [])
      }
    },
    setSelectedRecord(record) {
      this.selectedFilterBy = record
      if (this.selectedFilterBy === 'year') {
        this.yearDisabled = false
        this.dateRangeDisabled = true
        this.rangeDateOptions = []
        this.clearFilter()
      } else {
        this.dateRangeDisabled = false
        this.yearDisabled = true
        this.selectedYearOptions = []
        this.$emit('updateYearFilter', this.selectedYearOptions)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 0px;
}
.dialogCloseBtn {
  margin-right: 3px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $color-primary;
  font-weight: 700;
}

::v-deep .v-list-item__title {
  font-size: 1.125rem;
}

.datePickerRange {
  margin-left: 45px;
  max-width: 500px !important;
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  a {
    text-decoration: underline;
  }
}
::v-deep .filterOptions {
  .filter-by-options {
    margin-top: -18px;
    .year-list {
      padding-left: 45px !important;
      .v-label {
        font-weight: 400 !important;
        color: $grey-dark !important;
      }
    }
    .filter-by {
      .v-label {
        font-weight: 400 !important;
        color: $grey-dark !important;
      }
    }
    .disabled {
      display: none;
    }
  }
}
::v-deep div.v-picker--date {
  button:not(.v-btn--outlined):not(:focus) {
    border: none;
  }
}
::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
  border: 2px solid $ads-navy;
  background: none !important;
}
::v-deep .v-input--selection-controls__input {
  .v-input--selection-controls__ripple:before {
    opacity: 0;
  }
}
.year-list.v-list-item {
  margin-bottom: -10px !important;
}
</style>
