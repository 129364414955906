<template>
  <div ref="addressLookup">
    <div v-if="!isEditing || $attrs.readonly">
      <read-only-field
        v-bind="$attrs"
        :formatter="formatter || defaultFormatter"
        @click="setEditMode($refs.addressLookup)"
      />
    </div>
    <div v-else>
      <div>
        <select-field
          label="Country"
          v-model="$attrs.value.countryCode"
          :items="getCountries"
          @change="handleCountryChange"
        />
      </div>
      <div v-if="displayAddressLookup">
        <AddressLookupComponent
          v-bind="$attrs"
          hide-details
          @update:address="handleAddressUpdated"
        />
      </div>
      <div v-else-if="displayManualAddress">
        <AdsTextField
          label="Address line 1"
          v-model="$attrs.value.addressLine1"
          maxlength="100"
          :rules="[validators.required]"
          persistent-placeholder
        />
        <AdsTextField
          label="Address line 2"
          v-model="$attrs.value.addressLine2"
          maxlength="100"
          hide-details
          :rules="[validators.required]"
          persistent-placeholder
        />
      </div>
      <v-checkbox
        v-if="$attrs.value.countryCode === 'AUS'"
        label="Enter manually"
        v-model="enterManually"
        hint="(When address cannot be found)"
        persistent-hint
        @change="handleEnterManually"
      />
    </div>
  </div>
</template>

<script>
import AddressLookupComponent from './AddressLookupComponent.vue'
import SelectField from '@/components/form/SelectField'
import { AdsTextField } from '@nswdoe/doe-ui-core'
import toggleFieldMixin from './toggleFieldMixin'
import formatters from '@/helpers/formatters'
import { DATASET } from '@/constants'
import validators from '@/helpers/validators'

const EVENTS = {
  UPDATE_ADDRESS: 'update:address'
}

export default {
  name: 'AddressLookup',
  mixins: [toggleFieldMixin],
  components: {
    AddressLookupComponent,
    AdsTextField,
    SelectField
  },
  props: {
    formatter: {
      type: Function,
      default: null
    }
  },
  computed: {
    getCountries() {
      return this.$store.getters
        .referenceData(DATASET.COUNTRIES)
        .filter((country) => !country.hidden)
    },
    displayManualAddress() {
      let isManual = false

      const address = this.$attrs.value
      const countryCode = address ? address.countryCode : undefined

      if (
        (countryCode === 'AUS' && this.enterManually) ||
        (countryCode !== 'AUS' && countryCode !== undefined)
      ) {
        isManual = true
      }

      if (address && address.addressLine1 && address.addressLine1) {
        isManual = true
      }

      return isManual
    },
    displayAddressLookup() {
      let isDisplay = false
      const address = this.$attrs.value
      if (
        address === undefined ||
        address.countryCode === undefined ||
        address.countryCode !== 'AUS'
      ) {
        isDisplay = false
      } else if (
        address.address ||
        (address.countryCode === 'AUS' && !this.enterManually)
      ) {
        isDisplay = true
      }

      return isDisplay
    }
  },
  watch: {
    displayManualAddress(newVal) {
      this.enterManually = newVal
    }
  },
  data() {
    let enterManually = false
    let address = this.$attrs.value || {}
    if (address && address.addressLine1 && !address.postCode) {
      enterManually = true
    }

    return {
      defaultFormatter: formatters.getAddressFormatter(
        this.$store.getters.referenceData(DATASET.COUNTRIES),
        this.$store.getters.referenceData(DATASET.STATES)
      ),
      enterManually,
      validators: {
        required: validators.required
      }
    }
  },
  methods: {
    handleEnterManually() {
      this.$emit(EVENTS.UPDATE_ADDRESS, {
        countryCode: this.$attrs.value.countryCode
      })
    },
    handleCountryChange() {
      if (this.$attrs.value.countryCode === 'AUS') {
        this.enterManually = false
      }
      this.$emit(EVENTS.UPDATE_ADDRESS, {
        countryCode: this.$attrs.value.countryCode
      })
    },
    handleAddressUpdated(addressObject) {
      this.$emit(EVENTS.UPDATE_ADDRESS, { ...addressObject })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input label.v-label {
  color: $ads-navy;
}
</style>
