<template>
  <div class="file">
    <v-icon
      dark
      :color="disabled ? '#6d7079' : '#333333'"
      outlined
      class="material-icons-outlined"
      >{{ icon }}</v-icon
    >
    <AdsButton
      class="unstyled btn-link pl-1"
      type="button"
      v-on="$listeners"
      color="#333333"
      tertiary
      text
      small
      :disabled="disabled"
      :button-text="name"
    >
    </AdsButton>
    <div class="status" :class="disabled ? 'disabled' : ''" v-if="message">
      <font-awesome-icon
        focusable="false"
        icon="exclamation-circle"
        class="exclamationCircle mt-1 fa-lg ml-1 mr-1"
        color="#b81237"
      />
      <span class="message pl-1 pb-2">{{ message }}</span>
    </div>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'AppFile',
  props: {
    name: String, // file name
    message: String, // Message to display with error
    icon: {
      type: String,
      default: 'insert_drive_file'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AdsButton
  }
}
</script>

<style lang="scss" scoped>
.file {
  margin-bottom: 20px;
  .name {
    font-weight: bold;
    text-decoration: underline;
    display: inline-block;
  }
  .status {
    max-width: 230px;
    display: flex;
    &.disabled {
      color: $grey-darken1;
    }
    .message {
      display: inline-block;
    }
  }
  ::v-deep .v-icon.disabled {
    color: $grey-darken1;
  }
  ::v-deep .btn-link {
    padding-left: 0;
    font-size: 0.9rem;
    font-weight: bold;
    > .v-btn__content {
      text-decoration: underline;
    }

    &.v-btn--disabled .v-icon,
    &.v-btn--disabled {
      color: $grey-darken1 !important;
      opacity: 1;

      > .v-btn__content {
        text-decoration: none;
      }
    }
  }
}
</style>
