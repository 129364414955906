<template>
  <div>
    <Banner content-class="adsBanner" default-background1>
      <template #title>
        <h1 class="appTitle" v-html="bannerHeading"></h1>
      </template>
      <template #subtitle>
        <h1 class="appSubtitle" v-html="bannerSubtitle"></h1>
      </template>
      <template #icon>
        <img
          class="banner__icon pr-2"
          src="@/assets/school-logo.svg"
          alt="School logo"
        />
      </template>
    </Banner>
    <div class="pnrForm py-12">
      <div class="sr-only" aria-live="polite">
        <span
          v-if="pnrApplicationSubmittedStatus"
          v-html="bannerHeading"
          data-testid="banner-heading"
        ></span>
        <span v-if="pnrApplicationSubmittedStatus"
          >. We have sent a confirmation email to
          {{ parentCarers.parentCarerEmail }}. {{ studentName }}'s PNR advice
          will now appear in the school interface under Received EOIs. You can
          now close this window to return to the school interface.</span
        >
      </div>
      <v-container v-if="pnrApplicationSubmittedStatus">
        <ContentBox class="pt-6" img-file-name="green_tick.svg">
          <div slot="title">
            <h2 class="pb-4 normal-text-medium-size">
              We have sent a confirmation email to
              <strong>{{ parentCarers.parentCarerEmail }}</strong>
            </h2>
          </div>
          <div slot="copyContent" class="normal-text">
            <p>
              {{ studentName }}'s PNR advice will now appear in the school
              interface under Received EOIs.
            </p>
            <p>
              You can now close this window to return to the school interface.
            </p>
            <AdsButton
              class="mt-4"
              x-large
              aria-label="Close this window"
              button-text="Close this window"
              icon="close"
              @click="$emit('close', false)"
              data-testid="close-this-window"
            />
          </div>
        </ContentBox>
      </v-container>
      <v-container v-else>
        <p class="pb-4 normal-text">
          If this student intends to leave the public education system, please
          complete the form below to confirm the child and carer details.
        </p>
        <v-form ref="pnrform" class="v-form" v-model="valid" lazy-validation>
          <!-- Application origin -->
          <v-card class="pa-6 my-8">
            <h2 class="section-heading mb-8">Application origin</h2>
            <RadioGroup
              v-model="origin"
              class="mb-6"
              :items="applicationOrigin"
              label="How was this application delivered by the parent/carer?"
              :rules="[validators.required(origin, 'Application origin')]"
              required
              data-testid="application-originCode"
            />
          </v-card>
          <!-- Student details -->
          <v-card class="pa-6 my-8">
            <h2 class="section-heading mb-8">Student details</h2>
            <AdsTextField
              class="mb-6"
              v-model.trim="student.firstName"
              label="Student's first name"
              placeholder="Enter first name"
              hint="As shown on their birth certificate"
              persistent-hint
              maxlength="100"
              :rules="[
                validators.required(student.firstName, `Student's first name`),
                validators.name(student.firstName)
              ]"
              required
              data-testid="student-firstName"
              persistent-placeholder
            />
            <AdsTextField
              v-model.trim="student.familyName"
              class="mb-6"
              label="Student's family name"
              placeholder="Enter family name"
              hint="As shown on their birth certificate"
              persistent-hint
              maxlength="100"
              :rules="[
                validators.required(
                  student.familyName,
                  `Student's family name`
                ),
                validators.name(student.familyName)
              ]"
              required
              data-testid="student-familyName"
              persistent-placeholder
            />
            <AdsTextField
              v-model.trim="student.preferredName"
              class="mb-6"
              label="Student's preferred name"
              placeholder="Enter preferred name"
              hint="(Optional) This is the name usually used to address your child. It can be different from their birth certificate."
              persistent-hint
              maxlength="100"
              :rules="[validators.name(student.preferredName)]"
              data-testid="student-preferredName"
              persistent-placeholder
            />
            <DatePicker
              v-model="student.dateOfBirth"
              :label="`${pluralisedFirstName} date of birth`"
              :date-picker-attrs="{
                min: minBirthDate,
                max: nineYearsAgo
              }"
              year-picker
              date-format="DD-MM-YYYY"
              output-date-format="DD-MM-YYYY"
              required
              :rules="[
                validators.required(
                  student.dateOfBirth,
                  `${pluralisedFirstName} date of birth`
                ),
                validators.noFutureDate,
                this.validateMinimumBirthDate(student.dateOfBirth),
                this.validateMaximumBirthDate(student.dateOfBirth)
              ]"
              data-testid="student-dateOfBirth"
              persistent-placeholder
            />
            <RadioGroup
              v-model="student.genderCode"
              class="mb-6 mt-0"
              :items="genderOptions"
              :label="pluralisedFirstName + ' gender'"
              :rules="[
                validators.required(
                  student.genderCode,
                  `${pluralisedFirstName} gender`
                )
              ]"
              required
              data-testid="student-genderCode"
            />
          </v-card>
          <!-- Intended high school details -->
          <v-card class="pa-6 my-8">
            <h2 class="section-heading mb-8">Intended high school</h2>
            <p>Which high school are you intending on applying to?</p>
            <AdsSelect
              v-model="schoolType"
              class="mb-6"
              :items="allStates"
              label="Australia/Overseas"
              placeholder="Select..."
              hint="(optional)"
              persistent-hint
              :aria-label="ariaLabel(schoolType, 'allstates')"
              @input="changeSchoolType"
              persistent-placeholder
            />
            <AdsTextField
              v-if="schoolType && isSchoolTypeOverseasOrOther"
              v-model.trim="overseasOtherSchool"
              class="mb-6"
              label="Intended high school"
              placeholder="Enter high school name"
              maxlength="132"
              hint="(optional)"
              persistent-hint
              @blur="handleOvsOtherSchool"
              :rules="[validators.name(overseasOtherSchool)]"
              persistent-placeholder
            />
            <SchoolFinder
              v-if="schoolType && !isSchoolTypeOverseasOrOther"
              id="school-finder"
              ref="schoolFinder"
              v-model="intendedHighSchool"
              class="school-finder__search"
              label="Intended high school"
              placeholder="Enter high school name"
              hint="(optional)"
              persistent-hint
              :search-input.sync="schoolSearchInput"
              :clearable="true"
              :menu-props="{ maxHeight: 250 }"
              :min-length-for-search="minLengthForSearch"
              :suggestions-callback="getSchoolSuggestions"
              item-text="displayName"
              :required="
                schoolSearchInput != null && schoolSearchInput.length > 0
                  ? true
                  : false
              "
              @update:school="handleSchoolSelected"
              @input="checkIntendedHS"
            />
          </v-card>
          <v-card class="pa-6 my-8">
            <h2 class="section-heading mb-8">Other school options</h2>
            <RadioGroup
              v-model="consideringSelectiveSchool"
              :items="selectiveOptions"
              label="Have you submitted an application for placement in an academically selective school?"
              data-testid="student-selective"
              :rules="[
                validators.customError(
                  'Please choose if the parent has submitted an application for placement in an academically selective school'
                )
              ]"
            />
          </v-card>
          <!-- Parent/carer details -->
          <v-card class="pa-6 my-8">
            <h2 class="section-heading mb-8">Parent/carer details</h2>
            <AdsTextField
              class="mb-6"
              v-model.trim="parentCarers.parentCarerGivenName"
              label="Given name"
              placeholder="Enter given name"
              maxlength="100"
              :rules="[
                validators.required(
                  parentCarers.parentCarerGivenName,
                  'Given name'
                ),
                validators.name(parentCarers.parentCarerGivenName)
              ]"
              required
              data-testid="parent-carer-given-name"
              persistent-placeholder
            />
            <AdsTextField
              class="mb-6"
              v-model.trim="parentCarers.parentCarerFamilyName"
              label="Family name"
              placeholder="Enter family name"
              maxlength="100"
              :rules="[
                validators.required(
                  parentCarers.parentCarerFamilyName,
                  'Family name'
                ),
                validators.name(parentCarers.parentCarerFamilyName)
              ]"
              required
              data-testid="parent-carer-family-name"
              persistent-placeholder
            />
            <AdsTextField
              validate-on-blur
              class="mb-6"
              v-model.trim="parentCarers.parentCarerEmail"
              label="Contact email"
              placeholder="Enter email address"
              maxlength="100"
              :rules="[
                validators.required(
                  parentCarers.parentCarerEmail,
                  'Contact email'
                ),
                validators.email(parentCarers.parentCarerEmail, 'email address')
              ]"
              required
              data-testid="parent-carer-email"
              persistent-placeholder
            />
            <div
              v-for="(contact, idx) in parentCarers.contactDetails"
              :key="idx"
            >
              <AdsSelect
                v-model="contact.contactType"
                class="mb-6"
                label="Phone number type"
                placeholder="Enter type"
                :items="contactTypes"
                :rules="[
                  validators.required(contact.contactType, 'Phone number type')
                ]"
                required
                data-testid="phone-number-type"
                persistent-placeholder
              />
              <AdsFormatContactField
                validate-on-blur
                v-model.trim="contact.contactValue"
                class="mb-6"
                label="Enter number"
                placeholder="Enter number"
                maxlength="100"
                :rules="[
                  validators.required(contact.contactValue, 'Enter number'),
                  validators.phone(contact.contactValue)
                ]"
                required
                data-testid="contact-number"
                persistent-placeholder
              />
            </div>
          </v-card>
        </v-form>
        <div class="text-right">
          <AdsButton
            class="submit-btn"
            ref="submit"
            x-large
            aria-label="Submit"
            button-text="Submit"
            icon="arrow_forward"
            @click="submitPnrAdvice"
            :disabled="submitButtonDisabled"
          />
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import RadioGroup from '@/components/application/y67t/paperEoI/Form/RadioGroup'
import ContentBox from '@/components/app/ContentBox'
import SchoolFinder from '@/components/form/OesSchoolFinder'
import { GENDER_OPTIONS, APPLICATION_ORIGIN, YES_NO_OPTIONS } from '@/constants'
import {
  DatePicker,
  AdsTextField,
  AdsSelect,
  AdsButton,
  Banner,
  AdsFormatContactField
} from '@nswdoe/doe-ui-core'
import validators from '@/helpers/validators'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PNRForm',
  components: {
    RadioGroup,
    DatePicker,
    AdsTextField,
    AdsSelect,
    AdsButton,
    ContentBox,
    SchoolFinder,
    Banner,
    AdsFormatContactField
  },
  props: {
    displayDigitiseFlowCancelConfirmModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const dateNineYearsAgo = new Date()
    dateNineYearsAgo.setFullYear(dateNineYearsAgo.getFullYear() - 9)
    const nineYearsAgo = dateNineYearsAgo.toISOString().slice(0, 10)
    const dateSeventeenYearsAgo = new Date()
    dateSeventeenYearsAgo.setFullYear(dateSeventeenYearsAgo.getFullYear() - 17)
    const seventeenYearsAgo = dateSeventeenYearsAgo.toISOString().slice(0, 10)

    const {
      required,
      noPastDate,
      noFutureDate,
      name,
      email,
      phone,
      customError
    } = validators

    return {
      selectiveOptions: YES_NO_OPTIONS,
      applicationOrigin: APPLICATION_ORIGIN,
      genderOptions: GENDER_OPTIONS,
      minBirthDate: seventeenYearsAgo,
      nineYearsAgo,
      origin: null,
      schoolType: null,
      validators: {
        required,
        noPastDate,
        noFutureDate,
        name,
        email,
        phone,
        customError
      },
      minLengthForSearch: 3,
      schoolSearchInput: null,
      overseasOtherSchool: null,
      intendedHighSchool: null,
      submitted: false,
      valid: false
    }
  },
  computed: {
    ...mapGetters([
      'pnrApplication',
      'referenceData',
      'contactTypes',
      'predictiveSchools',
      'school',
      'allStates',
      'applicationSubmitted',
      'isSubmitting'
    ]),
    consideringSelectiveSchool: {
      get() {
        return this.$store.getters.consideringSelectiveSchool
      },
      set(value) {
        this.$store.dispatch('setConsideringSelectiveSchool', value)
      }
    },
    student() {
      return this.pnrApplication.student
    },
    parentCarers() {
      return this.pnrApplication.parentCarers[0]
    },
    submitButtonDisabled() {
      return !this.valid || this.isSubmitting
    },
    pluralisedFirstName() {
      const name = this.student.preferredName || this.student.firstName
      if (name) {
        return name.trim().slice(-1) === 's' ? `${name}'` : `${name}'s`
      }
      return "Student's"
    },
    studentName() {
      return `${this.student.firstName} ${this.student.familyName}`
    },
    selectedSchool() {
      return this.$store.state.selectedSchool
    },
    bannerHeading() {
      if (this.applicationSubmitted) {
        return `${this.student.firstName} ${this.student.familyName}'s PNR advice <br />has been submitted`
      }
      return 'Placement not required <br/> in a government high school'
    },
    bannerSubtitle() {
      if (this.applicationSubmitted) {
        return ''
      }
      return `For Year 6 students attending <strong>${this.selectedSchool.schoolName}</strong>`
    },
    isSchoolTypeOverseasOrOther() {
      return this.schoolType === 'OVS' || this.schoolType === 'YYY'
    },
    pnrApplicationSubmittedStatus() {
      return this.applicationSubmitted
    }
  },
  created() {},
  mounted() {
    this.valid = false
  },
  methods: {
    dateStringToDateObject(date) {
      return moment(this.formatDateString(date), 'YYYY-MM-DD')
    },
    formatDateString(date) {
      return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    },
    validateMinimumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const minBirthDate = moment(this.minBirthDate)
      return (
        dateObject.diff(minBirthDate) >= 0 ||
        'Children enrolling in high school must be no younger than 9 and no older than 17 years of age. Please choose a date of birth within this range.'
      )
    },
    validateMaximumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const nineYearsAgo = moment(this.nineYearsAgo)
      return (
        dateObject.diff(nineYearsAgo) <= 0 ||
        'Children enrolling in high school must be at least 9 years of age.'
      )
    },
    submitPnrAdvice() {
      if (this.$refs.pnrform.validate()) {
        const application = this.pnrApplication
        application.isPaperEOI = this.origin === 'paper'
        application.isVerbalAdvice = this.origin === 'verbal'
        application.consideringSelectiveSchool = this.consideringSelectiveSchool
        this.$store.dispatch('submitAdvice', application).then(() => {
          this.submitted = true
        })
      }
    },
    checkIntendedHS() {
      if (
        !(
          this.intendedHighSchool && typeof this.intendedHighSchool === 'object'
        )
      ) {
        const schoolDetails =
          this.$store.getters.pnrApplication.intendedHighSchool
        schoolDetails.schoolCode = ''
        schoolDetails.schoolName = ''
        this.$store.dispatch('setIntendedHighSchool', schoolDetails)
      }
    },
    handleOvsOtherSchool() {
      const schoolDetails = {
        schoolCode: '',
        schoolName: this.overseasOtherSchool,
        schoolType: this.schoolType
      }
      this.$store.dispatch('setIntendedHighSchool', schoolDetails)
    },
    getSchoolSuggestions(searchInput) {
      if (searchInput == null || searchInput === '') {
        return []
      }
      let suggestedHighSchools

      const labels = {
        centralCommunitySchools: 'Central/Community Schools',
        secSchools: 'Secondary Schools'
      }
      if (this.schoolType === 'NSW') {
        suggestedHighSchools = this.predictiveSchools.data.filter(
          (school) =>
            school.stateCode === 'NSW' &&
            school.nswGovSchool === 'N' &&
            // eslint-disable-next-line max-len
            (school.catchmentLevel === labels.centralCommunitySchools ||
              school.catchmentLevel === labels.secSchools) &&
            school.orgUnitName.toLowerCase().includes(searchInput.toLowerCase())
        )
        // eslint-disable-next-line brace-style
      }
      // other states for Overseas and Other it will be free text box
      else {
        suggestedHighSchools = this.predictiveSchools.data.filter(
          (school) =>
            school.stateCode === this.schoolType &&
            // eslint-disable-next-line max-len
            (school.catchmentLevel === labels.centralCommunitySchools ||
              school.catchmentLevel === labels.secSchools) &&
            school.orgUnitName.toLowerCase().includes(searchInput.toLowerCase())
        )
      }

      suggestedHighSchools.forEach((school) => {
        const schoolData = school
        if (school.suburb !== 'null' && school.suburb !== '') {
          const suburb = school.suburb.trim()
          schoolData.displayName = `${school.orgUnitName} (${suburb})`
        } else {
          schoolData.displayName = school.orgUnitName
        }
      })
      return suggestedHighSchools
    },
    changeSchoolType() {
      //  clear out all inteded high school value
      this.schoolSearchInput = null
      this.overseasOtherSchool = null
      this.intendedHighSchool = null
      // resetting school selection when schooltype is changed
      this.handleSchoolSelected(null)
    },
    handleSchoolSelected(school) {
      let schoolDetails
      if (
        school != null &&
        (this.schoolType !== 'OVS' || this.schoolType !== 'YYY')
      ) {
        schoolDetails = {
          schoolCode: school.orgUnitCode,
          schoolName: school.orgUnitName,
          schoolType: this.schoolType
        }
      } else if (school == null) {
        schoolDetails = {
          schoolCode: '',
          schoolName: '',
          schoolType: this.schoolType
        }
      }
      this.$store.dispatch('setIntendedHighSchool', schoolDetails)
    },
    ariaLabel(value, objectKey) {
      if (value) {
        const objects = {
          allstates: this.allStates,
          contacttype: this.contactTypes
        }
        const { text } = objects[objectKey].find((item) => item.value === value)
        return text
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
.theme--light.v-icon.v-icon--link:focus {
  border: 3px solid $color-primary;
  border-radius: 50%;
}
.theme--light.v-icon.v-icon--link.error--text:focus {
  border: 3px solid $color-error-dark;
  border-radius: 50%;
}
.theme--light.v-icon:focus::after {
  opacity: 0;
}
.container.adsBanner {
  font-family: $digitize-eoi-font-family;
  margin: auto;
  .appTitle {
    font-size: 51px;
  }
  .appSubtitle {
    font-size: 22px;
    font-weight: normal;
  }
  .d-flex.align-start {
    align-items: center !important;
  }
}
.pnrForm {
  font-family: $digitize-eoi-font-family;
  .v-form {
    .v-text-field.v-input {
      max-width: 500px !important;
    }
  }
  .container {
    margin: 0 auto;
    width: 70%;
  }
  .section-heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
    font-style: normal;
    color: $ads-navy;
  }
  .v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .normal-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }
  .normal-text-medium-size {
    font-size: 22px;
  }
  .content-img.rightSpacing {
    margin-top: -20px;
  }
}
</style>
