var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"studentEoIDetails",staticClass:"pt-0"},[_c('p',{staticClass:"pb-3 page-title"},[_vm._v(" Let’s start with some basic details of the student. This information is used by your nominated high school(s) to identify the student when processing the expression of interest. ")]),_c('v-form',{ref:"studentDetailsForm",staticClass:"v-form",attrs:{"data-testid":"student-details-form","lazy-validation":""}},[_c('v-card',{staticClass:"pa-6"},[_c('h2',{staticClass:"section-heading mb-4"},[_vm._v("Application origin")]),_c('RadioGroup',{attrs:{"items":_vm.applicationOrigin,"label":"How was this application delivered by the parent/carer?","data-testid":"application-originCode","rules":[_vm.validators.required(_vm.originValue, 'Application origin')],"required":"","error-messages":_vm.blurError},on:{"radioblur":function($event){return _vm.handleBlur(_vm.originValue)},"input":function($event){_vm.blurError = []}},model:{value:(_vm.originValue),callback:function ($$v) {_vm.originValue=$$v},expression:"originValue"}}),(_vm.originValue === 'verbal')?_c('div',{staticClass:"warning-message",attrs:{"data-testid":"eoi-origin"}},[_c('v-icon',{staticClass:"warning-icon"},[_vm._v("mdi-alert-outline")]),_c('strong',[_vm._v(" Note: ")]),_vm._v(_vm._s(_vm.verbalAlertText)+" ")],1):_vm._e(),_c('div',{staticClass:"sr-only",attrs:{"aria-live":"polite"}},[_c('p',[_vm._v(_vm._s(_vm.verbalAlertText))])])],1),_c('v-card',{staticClass:"pa-6 my-8"},[_c('h2',{staticClass:"section-heading mb-8"},[_vm._v("Student details")]),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":_vm.studentsFirstNameLabel,"placeholder":"Enter first name","hint":"As it is shown on the birth certificate","persistent-hint":"","rules":[
          _vm.validators.required(_vm.student.firstName, _vm.studentsFirstNameLabel),
          _vm.validators.name(_vm.student.firstName)
        ],"maxlength":"100","data-testid":"student-firstName","required":"","persistent-placeholder":""},model:{value:(_vm.student.firstName),callback:function ($$v) {_vm.$set(_vm.student, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"student.firstName"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":"Student's middle name","placeholder":"Enter middle name","hint":"(optional)","persistent-hint":"","maxlength":"200","rules":[
          _vm.validators.name(_vm.student.middleName, 'Student\'s middle name')
        ],"data-testid":"student-middleName","persistent-placeholder":""},model:{value:(_vm.student.middleName),callback:function ($$v) {_vm.$set(_vm.student, "middleName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"student.middleName"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":_vm.studentsFamilyNameLabel,"hint":"As it is shown on the birth certificate","persistent-hint":"","placeholder":"Enter family name","rules":[
          _vm.validators.required(_vm.student.familyName, _vm.studentsFamilyNameLabel),
          _vm.validators.name(_vm.student.familyName)
        ],"maxlength":"100","required":"","data-testid":"student-familyName","persistent-placeholder":""},model:{value:(_vm.student.familyName),callback:function ($$v) {_vm.$set(_vm.student, "familyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"student.familyName"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":"Student's preferred name","hint":"(Optional) This is the name usually used to address the child. It can be different from their birth certificate.","persistent-hint":"","placeholder":"Enter preferred name","maxlength":"100","rules":[_vm.validators.name(_vm.student.preferredName)],"data-testid":"student-preferredName","persistent-placeholder":""},model:{value:(_vm.student.preferredName),callback:function ($$v) {_vm.$set(_vm.student, "preferredName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"student.preferredName"}}),_c('DatePicker',{staticClass:"mb-2 date-picker",attrs:{"label":_vm.studentsDateOfBirthLabel,"date-picker-attrs":{
          min: _vm.minBirthDate,
          max: _vm.nineYearsAgo
        },"year-picker":"","date-format":"DD-MM-YYYY","output-date-format":"DD-MM-YYYY","required":"","rules":[
          _vm.validators.required(_vm.student.dateOfBirth, _vm.studentsDateOfBirthLabel),
          _vm.validators.noFutureDate(
            _vm.student.dateOfBirth,
            _vm.studentsDateOfBirthLabel
          ),
          this.validateMinimumBirthDate(_vm.student.dateOfBirth),
          this.validateMaximumBirthDate(_vm.student.dateOfBirth)
        ],"data-testid":"student-dateOfBirth","persistent-placeholder":""},model:{value:(_vm.student.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.student, "dateOfBirth", $$v)},expression:"student.dateOfBirth"}}),_c('RadioGroup',{staticClass:"mt-0",attrs:{"items":_vm.genderOptions,"label":_vm.studentsGenderLabel,"data-testid":"student-genderCode","rules":[
          _vm.validators.required(_vm.student.genderCode, _vm.studentsGenderLabel)
        ],"required":""},model:{value:(_vm.student.genderCode),callback:function ($$v) {_vm.$set(_vm.student, "genderCode", $$v)},expression:"student.genderCode"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }