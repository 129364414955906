<template>
  <div class="ApplicationDownloadDocument">
    <v-btn
      class="ma-0 download-btn"
      color="#041E42"
      download
      text
      x-large
      @click.prevent="showModal"
    >
      <v-icon class="mr-2">mdi-download-outline</v-icon>
      Download
    </v-btn>
    <Y67TDownloadDocuments
      v-if="showDownloadModal && applicationType === APPLICATION_TYPE.Y67T"
      :applications="applicationInfo"
      :school-code="schoolCode"
      :application-type="applicationType"
      @close="showDownloadModal = false"
    />
    <DownloadDocumentsModal
      v-if="
        showDownloadModal && applicationType === APPLICATION_TYPE.OUT_OF_AREA
      "
      :applications="applicationInfo"
      :school-code="schoolCode"
      :application-type="applicationType"
      @close="showDownloadModal = false"
    />
  </div>
</template>

<script>
import Y67TDownloadDocuments from '@/components/applicationList/Y67TDownloadDocuments.vue'
import DownloadDocumentsModal from '@/components/applicationList/DownloadDocumentsModal.vue'
import { APPLICATION_TYPE } from '@/constants'

export default {
  name: 'ApplicationDownloadDocument',
  components: {
    Y67TDownloadDocuments,
    DownloadDocumentsModal
  },
  data() {
    return {
      showDownloadModal: false,
      APPLICATION_TYPE
    }
  },
  props: {
    schoolCode: {
      type: String,
      required: true
    },
    applicationType: {
      type: String
    }
  },
  computed: {
    applicationInfo() {
      const app = this.$store.state.application
      return [
        {
          applicationID: app.applicationID,
          studentFirstName: app.student.firstName,
          studentFamilyName: app.student.familyName,
          supportingDocuments: app.supportingDocuments,
          isDigitizeEOIConsiderationDocUploaded:
            app.isDigitizeEOIConsiderationDocUploaded
        }
      ]
    }
  },
  methods: {
    showModal() {
      this.showDownloadModal = true
      this.$gtm.trackEvent({
        category: 'pdfApplication',
        action: 'Download',
        label:
          this.applicationType === APPLICATION_TYPE.Y67T
            ? 'Application view'
            : `${this.applicationType}ApplicationView`,
        value: 1,
        stream: this.applicationType
      })
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationDownloadDocument {
  .v-btn {
    letter-spacing: inherit;
    text-transform: none;
    background-color: transparent;
  }

  .v-btn::before {
    background-color: transparent;
  }

  .v-btn:hover {
    border: 2px solid $color-primary;
  }
}
#app.v-application button.v-btn:not(.v-btn--round).download-btn {
  background-color: transparent;
  height: auto;
  border: 3px solid transparent;
  font-weight: 400;
}
#app.v-application button.v-btn:not(.v-btn--round).download-btn:hover {
  background-color: $color-disabled-section;
  border: 3px solid $color-primary;
}

.download-btn:focus {
  outline: 3px solid $color-primary;
}
</style>
