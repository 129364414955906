<template>
  <div class="forward-back-nav">
    <slot name="back-button">
      <AdsButton
        v-if="includeBack"
        class="button button--back"
        v-bind="internalBackAttrs"
        :button-text="backText"
        :icon="backIcon"
        :loading="backwardLoading"
        :secondary="!includeBackPrimary"
        @click="$emit('click:backward', $event)"
      >
        <template #loader>
          <v-progress-circular
            class="icon spin button__spinner"
            :size="20"
            :width="4"
            color="white"
            indeterminate
            aria-label="loading"
          />
          {{ backText }}
        </template>
      </AdsButton>
    </slot>
    <slot name="forward-button">
      <AdsButton
        v-if="includeForward"
        class="button button--forward"
        v-bind="internalForwardAttrs"
        :button-text="forwardText"
        :icon="forwardIcon"
        :loading="forwardLoading"
        @click="$emit('click:forward', $event)"
      >
        <template #loader>
          <v-progress-circular
            class="icon spin button__spinner"
            :size="20"
            :width="4"
            color="white"
            indeterminate
            aria-label="loading"
          />
          {{ forwardText }}
        </template>
      </AdsButton>
    </slot>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'ForwardBackNav',
  components: {
    AdsButton
  },
  props: {
    includeBack: {
      type: Boolean,
      default: true
    },
    includeBackPrimary: {
      type: Boolean,
      default: false
    },
    includeForward: {
      type: Boolean,
      default: true
    },
    backText: {
      type: String,
      default: 'Back'
    },
    backIcon: {
      type: String,
      default: 'mdi-arrow-left'
    },
    backwardLoading: {
      type: Boolean,
      default: false
    },
    backAttrs: {
      type: Object,
      default: () => {}
    },
    forwardIcon: {
      type: String,
      default: 'mdi-arrow-right'
    },
    forwardLoading: {
      type: Boolean,
      default: false
    },
    forwardText: {
      type: String,
      default: 'Proceed'
    },
    forwardAttrs: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    internalBackAttrs() {
      const defaultAttrs = {
        large: true,
        outlined: true
      }
      return { ...defaultAttrs, ...this.backAttrs }
    },
    internalForwardAttrs() {
      const defaultAttrs = {
        large: true
      }
      return { ...defaultAttrs, ...this.forwardAttrs }
    }
  }
}
</script>

<style lang="scss" scoped>
.forward-back-nav {
  display: flex;
  justify-content: space-between;
  margin: 2.25rem 0;
  font-family: $digitize-eoi-font-family;
}

.button--forward:first-child {
  margin-left: auto;
}

.button {
  font-size: 1rem;
  font-weight: 500;

  &__icon {
    margin-right: 0.75rem;
    margin-left: -0.25rem;
  }

  &__spinner {
    margin-right: 0.75rem;
  }
}
.button--forward.v-btn--disabled {
  border: 3px solid transparent;
}
.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
