import { render, staticRenderFns } from "./SupportingDocumentUploadY67T.vue?vue&type=template&id=07e9ea38&scoped=true&"
import script from "./SupportingDocumentUploadY67T.vue?vue&type=script&lang=js&"
export * from "./SupportingDocumentUploadY67T.vue?vue&type=script&lang=js&"
import style0 from "./SupportingDocumentUploadY67T.vue?vue&type=style&index=0&id=07e9ea38&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07e9ea38",
  null
  
)

export default component.exports