<template>
  <div data-testid="app-settings-scholastic-year">
    <h4
      :class="showError || isDuplicate ? 'scholastic-year-error mb-3' : 'mb-3'"
    >
      Scholastic year *
    </h4>
    <div class="scholasticYear">
      <OesCheckbox
        :disabled="disabled"
        v-model="selectedAll"
        label="All years"
        data-testid="scholastic-year-all-years"
      />
      <OesCheckbox
        v-for="scholasticYear in availableScholasticYears"
        :key="scholasticYear.value"
        :disabled="disabled"
        v-model="selectedYears"
        :value="scholasticYear.value"
        :label="scholasticYear.label"
        :data-testid="`scholastic-year-${scholasticYear.value}`"
      />
    </div>
    <div
      v-if="showError"
      class="scholastic-year-error"
      data-testid="app-settings-scholastic-year-error"
      aria-label="At least one scholastic year is required"
      tabindex="0"
    >
      <span>At least one scholastic year is required</span>
    </div>
  </div>
</template>

<script>
import OesCheckbox from '@/components/app/OesCheckbox'

export default {
  name: 'AppSettingsScholasticYear',
  components: { OesCheckbox },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    scholasticYears: {
      type: Array,
      default: () => []
    },
    availableScholasticYears: {
      type: Array,
      default: () => []
    },
    isDuplicate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedAll: false,
      selectedYears: [],
      showError: false
    }
  },
  mounted() {
    this.selectedYears = this.scholasticYears
  },
  watch: {
    selectedAll(newVal) {
      if (newVal) {
        this.selectedYears = this.availableScholasticYears.map(
          ({ value }) => value
        )
        this.showError = false
      } else if (
        this.selectedYears.length === this.availableScholasticYears.length
      ) {
        this.selectedYears = []
        this.showError = true
      }
    },
    selectedYears(years) {
      this.selectedAll =
        years.length > 0 &&
        years.length === this.availableScholasticYears.length
      this.showError = years.length === 0 ? true : false
      this.$emit('updatedScholasticYears', years)
    },
    scholasticYears(years) {
      this.selectedYears = years
    }
  }
}
</script>

<style lang="scss" scoped>
h4 {
  color: $color-primary;
}

.scholasticYear {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1em;
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

::v-deep .v-label {
  font-size: 14px;
  color: $color-dark70;
}

::v-deep .v-input__slot {
  margin-bottom: 0;
}

.scholastic-year-error {
  color: $color-error-dark;
}
</style>
