// See README.md for more info about field configuration

import { FIELD_TYPE, YES_NO } from '@/constants'

export default [
  {
    label: `Risk assessment`,
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'riskOfAnyTypeToStudentsORStaff',
    label: 'Has a history of risk to students or staff?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'riskOfAnyTypeDetails',
    label: 'History of risk',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.riskOfAnyTypeToStudentsORStaff
    }
  },
  {
    type: FIELD_TYPE.LINEBREAK
  },
  {
    apiKey: 'healthProfHaveKnowledge',
    label: 'Are there health professionals with knowledge of risk history?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'healthProfContact',
    label: 'Contact details of health professionals',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.healthProfHaveKnowledge
    }
  },
  {
    type: FIELD_TYPE.LINEBREAK
  },
  {
    apiKey: 'anyHistoryViolentBehaviour',
    label: 'Has a history of violent behaviour?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'violentBehaviourDetails',
    label: 'History of violent behaviour',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.anyHistoryViolentBehaviour
    }
  },
  {
    label: `Suspension/expulsion`,
    type: FIELD_TYPE.HEADING
  },

  {
    apiKey: 'hasSuspendedorExpelled',
    label: 'Suspended/expelled from any previous school?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },

  {
    apiKey: 'reasonForSuspension.actualViolence',
    label: 'For actual violence?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'reasonForSuspension.prossessionOfWeapon',
    label: 'For possession of weapon?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'reasonForSuspension.threatOfViolence',
    label: 'For threat of violence or intimidation?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'reasonForSuspension.illegalDrugs',
    label: 'For illegal drugs?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    type: FIELD_TYPE.LINEBREAK
  },
  {
    apiKey: 'hasOtherIncident',
    label: 'Any other incidents of this sort?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'otherIncidentDetails',
    label: 'Other incidents',
    required: true,
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    visible(application) {
      return application.hasOtherIncident
    }
  }
]
