export default {
  getHashParams() {
    return location.hash
      .slice(2)
      .split('&')
      .map((p) => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
  },

  getQueryParams() {
    return location.search
      .slice(1)
      .split('&')
      .map((p) => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
  },

  queryStringify(params) {
    return Object.entries(params).reduce(
      (acc, [key, value], i) =>
        `${acc}${i === 0 ? '' : '&'}${encodeURIComponent(
          key
        )}=${encodeURIComponent(value)}`,
      ''
    )
  }
}
