<!-- Component for filter options (Checkbox and radio seclection controls) -->
<template>
  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-content-click="false"
  >
    <template #activator="{ on }">
      <a
        href="#"
        @click.prevent="menu = true"
        :aria-label="`Filter by ${label.toLowerCase()} range`"
      >
        <v-chip
          class="chip"
          :disabled="filterOptions.disabled"
          :class="selectedFilterOptions ? 'selected' : ''"
          outlined
          v-on="on"
        >
          {{ label }}
          <v-icon v-if="selectedFilterOptions" class="pl-1">cancel</v-icon>
          <v-icon v-else>arrow_drop_down</v-icon>
        </v-chip>
      </a>
    </template>
    <FocusTrap :is-active="isActive" v-if="!filterOptions.disabled">
      <v-card class="filterOptions" width="630px" :height="cardHeight">
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <strong>{{ heading }} </strong>
            </v-list-item-title>
            <v-list-item-action>
              <a
                href="#"
                @click.prevent="menu = false"
                class="dialogCloseBtn"
                aria-label="Close filter options menu"
              >
                <v-icon class="text-right closeBtn">mdi-close</v-icon>
              </a>
            </v-list-item-action>
          </v-list-item>
          <div class="datePickerRange" v-if="!resetPickers">
            <DatePickerRange
              v-model="rangeDateOptions"
              range-separator-text=""
              :start-date-picker-attrs="{ label: 'From' }"
              :end-date-picker-attrs="{ label: 'To' }"
              @date-picker-activated="datePickerActivated"
              @input="dateRangeUpdate"
              persistent-placeholder
            />
            <div class="pb-4 clearfilter" v-if="cardHeight === 'auto'">
              <a href="#" @click.prevent="clearFilter">Clear dates</a>
            </div>
          </div>
          <v-list-item class="mb-2" v-if="archiveType">
            <SearchArchive :archive-type="archiveType" />
          </v-list-item>
        </v-list>
      </v-card>
    </FocusTrap>
  </v-menu>
</template>

<script>
import { FocusTrap, DatePickerRange } from '@nswdoe/doe-ui-core'
import SearchArchive from '@/components/app/SearchArchive.vue'

export default {
  name: 'ListViewFilter',
  props: {
    filterOptions: {
      type: Object,
      default: () => {}
    },
    label: {
      type: String,
      default: 'Received date'
    },
    heading: {
      type: String,
      default: 'Received date'
    },
    archiveType: {
      type: String,
      default: ''
    }
  },
  components: {
    SearchArchive,
    FocusTrap,
    DatePickerRange
  },
  data() {
    return {
      menu: false,
      selectedOptions: [],
      rangeDateOptions: [],
      cardHeight: 'auto',
      dataPickerActivated: {
        start: false,
        end: false
      },
      resetPickers: false,
      isActive: false
    }
  },
  watch: {
    // clear selected filters on change of school switcher
    filterOptions(newVal, oldVal) {
      if (newVal && oldVal && newVal.toString() === oldVal.toString()) {
        // don't clear the filters ( back to list view button)
        return
      }
      this.rangeDateOptions = []
    },
    menu(opened) {
      this.isActive = opened
      this.$emit('menuOpened', opened)
    }
  },
  computed: {
    selectedFilterOptions() {
      let isSelected = false
      if (this.rangeDateOptions.length === 2) {
        isSelected = !!this.rangeDateOptions[0] && !!this.rangeDateOptions[1]
      }
      return isSelected ? true : false
    }
  },
  methods: {
    clearFilter() {
      this.rangeDateOptions = []
      this.$emit('updatefilter', this.rangeDateOptions)

      // force pickers to re-render so that the internal dates get reset
      this.resetPickers = true
      this.$nextTick(() => {
        this.resetPickers = false
      })
    },
    datePickerActivated(activated, datePicker) {
      this.datePickerActivated[datePicker] = activated
      this.cardHeight =
        this.datePickerActivated.start || this.datePickerActivated.end
          ? '600px'
          : 'auto'
    },
    dateRangeUpdate([start, end]) {
      if (!!start && !!end) {
        this.$emit('updatefilter', [start, end])
      } else {
        this.$emit('updatefilter', [])
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-chip.chip.v-chip--outlined {
  border-color: $grey-darken1;
  background-color: white !important;
  color: $grey-darken1;
}
.v-chip.chip.v-chip--outlined.selected {
  background-color: $color-selected-item !important;
  color: $color-primary;
}
.checkFilter {
  margin-top: 2px;
}
.dialogCloseBtn {
  margin-right: 3px;
}
a {
  text-decoration: none;
}

::v-deep .filterOptions .theme--light.v-label {
  color: $color-primary;
  font-weight: 700;
}

::v-deep .v-list-item__title {
  font-size: 1.125rem;
}

.datePickerRange {
  width: 100%;
  margin-left: 20px;
  padding-top: 20px;
}

.closeBtn {
  color: $color-primary;
}
.clearfilter {
  font-size: 14px;
  font-weight: bold;
  a {
    text-decoration: underline;
  }
  text-align: right;
  margin-right: 100px;
}

::v-deep div.v-picker--date {
  button:not(.v-btn--outlined):not(:focus) {
    border: none;
  }
}
</style>
