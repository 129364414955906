<template>
  <div class="search-archive">
    <v-icon>mdi-magnify</v-icon>
    <span class="search-archive__content">
      Looking for a historical record?
      <button class="search-archive__cta" @click="handleClick">
        Search archive
      </button>
    </span>
  </div>
</template>

<script>
import { STREAM, ARCHIVE_TYPES } from '@/constants'

export default {
  props: {
    archiveType: {
      type: String,
      default: ARCHIVE_TYPES.ALL.type
    }
  },
  methods: {
    async handleClick() {
      await this.$store.dispatch('archiving/setType', this.archiveType)
      this.$store.commit('set', ['currentStream', STREAM.ARCHIVE])
    }
  }
}
</script>

<style lang="scss" scoped>
$search-archive-color: $ads-primary-blue;

body {
  div.search-archive {
    background: #{$ads-tertiary-blue-dark}66;
    color: $search-archive-color;
    padding: 0.5rem;
    border-radius: 0.25rem;

    width: 100%;
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: flex-start;

    & span.search-archive__content {
      flex-grow: 1;
      line-height: 1.5;
      margin-bottom: 0;
    }

    & i.v-icon {
      color: $search-archive-color;
      flex-shrink: 0;
      font-size: 2rem;
    }

    & button.search-archive__cta {
      color: $search-archive-color;
      text-decoration: underline;
      padding: 0;
      margin: 0;
      border: none;

      &:focus-visible {
        outline: 3px solid $ads-primary-blue-dark;
      }
    }
  }
}
</style>
