<!-- 
  Provides a dialog for resoliving all kinds of conflict:
  - A-B (where an OES or ERN value is selected)
  - A-B GROUP (where an OES or ERN field group, such as an address, is selected)
  - LIST (where the user chooses which OES and ERN records to keep and, if necessary, which OES records will replace which ERN records)

  NOTE: A-B resolutions provide extra functionality for TEXTAREA fields. An extra step is possible allowing the
  user to manually merge the OES & ERN text.
-->
<template>
  <AppModal
    v-if="modelRow"
    @close="cancel()"
    ref="AppModal"
    :max-width="isTextArea ? '900px' : ''"
  >
    <span slot="header"
      ><strong>{{ modelRow.label }}</strong></span
    >
    <div slot="body">
      <!-- LIST-type resolution UI STEP 2 (what should happen to deselected ERN records?) -->
      <div v-if="isErnLinkingVisible">
        <div>
          What should happen to the ERN record{{
            getDeselectedErnRecords.length > 1 ? 's' : ''
          }}?
        </div>
        <div v-for="(record, index) in getDeselectedErnRecords" :key="index">
          <ResolveConflictErnAction
            :droplist-options="getErnLinkingDroplistOptions"
            :fields="getOptionFields({}, record)"
            :set-focus="index == 0"
            @change="selectErnRecordAction(record, ...arguments)"
          />
        </div>
      </div>

      <!-- TEXTAREA resolution UI STEP 2 (manual merge) -->
      <div v-else-if="isResolveTextAreaManually">
        {{
          (conflictSettings &&
            conflictSettings.dictionary &&
            conflictSettings.dictionary.conflictHelperText) ||
          'Please review and finalise your merged text:'
        }}

        <v-textarea
          :label="modelRow.label"
          ref="manualMergeText"
          placeholder=" "
          v-model="manualMergeText"
          class="mt-6"
          rows="10"
          counter="2048"
          autofocus
          outlined
          persistent-placeholder
          :rules="[(v) => (v.length <= 2048 && v.length > 0) || '']"
          @update:error="handleErrorupdate"
        />
      </div>

      <!-- LIST-type resolution UI STEP 1 (select records to keep) -->
      <div v-else-if="isListType">
        <div>
          Please select the records to keep<span v-if="isDisabledOption">
            (records with missing information cannot be selected)</span
          >:
        </div>
        <div v-for="(record, index) in modelRow.oesValue" :key="'OES' + index">
          <ResolveConflictOption
            :is-ern="false"
            :is-list-type="true"
            :value="record"
            :fields="getOptionFields(record, {})"
            :is-selected="keptRecords.indexOf('OES' + index) >= 0"
            :disabled="isResolutionOptionDisabled(`${modelRow.id}[${index}]`)"
            @click="selectOption('OES' + index)"
          />
        </div>
        <div v-for="(record, index) in modelRow.ernValue" :key="'ERN' + index">
          <ResolveConflictOption
            :is-ern="true"
            :is-list-type="true"
            :value="record"
            :fields="getOptionFields({}, record)"
            :is-selected="keptRecords.indexOf('ERN' + index) >= 0"
            @click="selectOption('ERN' + index)"
          />
        </div>
      </div>

      <!-- AB-type resolution -->
      <div v-else>
        <div v-if="isDisabledOption">
          Please select the record to keep (records with missing information
          cannot be selected):
        </div>
        <ResolveConflictOption
          :is-ern="false"
          :is-text-area="isTextArea"
          :value="modelRow.oesValue"
          :value-text="modelRow.oesDisplayValue"
          :fields="getOptionFields()"
          :is-selected="keptValue === 'OES'"
          :disabled="isResolutionOptionDisabled(modelRow.id)"
          @click="selectOption('OES')"
        />
        <ResolveConflictOption
          :is-ern="true"
          :is-text-area="isTextArea"
          :value="modelRow.ernValue"
          :value-text="modelRow.ernDisplayValue"
          :fields="getOptionFields()"
          :is-selected="keptValue === 'ERN'"
          @click="selectOption('ERN')"
        />
        <ResolveConflictOption
          v-if="isTextArea && modelRow.oesValue && modelRow.ernValue"
          :value="resolutionType.MERGE"
          value-text="Resolve manually"
          :is-selected="keptValue === resolutionType.MERGE"
          @click="selectOption(resolutionType.MERGE)"
        />
      </div>

      <Alert
        v-if="
          conflictSettings &&
          conflictSettings.alerts &&
          conflictSettings.alerts.affectErn
        "
        in-page
        type="info"
        elevation="0"
        background-colour
        subtext="Any changes to text will affect ERN"
        class="v-alert__warning-hack mt-3"
      />
      <Alert
        v-if="
          conflictSettings &&
          conflictSettings.alerts &&
          conflictSettings.alerts.parentCarerClarification
        "
        in-page
        type="warning"
        elevation="0"
        background-colour
        subtext="If clarification is needed, please contact the parent/carer"
        class="v-alert__warning-hack mt-3"
      />
    </div>
    <div slot="footer">
      <AdsButton
        @click="resolve"
        :disabled="isResolveButtonDisabled"
        class="primary"
        :icon="this.isShowNextButton ? undefined : 'check'"
        :color="this.isShowNextButton ? undefined : 'success'"
        :button-text="getConfirmButtonText"
      />
      <AdsButton icon="mdi-close" secondary @click="cancel">
        {{ getCancelButtonText }}
      </AdsButton>
    </div>
  </AppModal>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import { AdsButton, Alert } from '@nswdoe/doe-ui-core'
import ResolveConflictOption from '@/components/application/ResolveConflictOption'
import ResolveConflictErnAction from '@/components/application/ResolveConflictErnAction'
import { FIELD_TYPE, CONFLICT_ALERTING, RESOLUTION_TYPE } from '@/constants'
import UTILS from '@/store/utils'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

const REPLACE = 'REPLACE'

export default {
  name: 'ApplicationResolveConflict',
  components: {
    AppModal,
    ResolveConflictOption,
    ResolveConflictErnAction,
    Alert,
    AdsButton
  },
  mixins: [fieldHelperMixin],
  data() {
    return {
      keptValue: 'OES', // For A-B conflicts, stores the chosen value (either 'OES' or 'ERN'). Defaults to OES
      keptRecords: [], // For LIST conflicts, stores the current selection of records to keep
      isErnLinkingVisible: false, // Switches UI to show available actions for un-kept (unselected) ERN records
      isResolveTextAreaManually: false, // Switches UI to 2nd step of textarea resolution, where text can be merged manually
      ernActions: [], // Stores user selections on what should happen to un-kept ERN records
      manualMergeText: '', // Used to store user's manually merged TEXTAREA text
      isDisabledOption: false, // True if one or more resolution options are disabled because of missing fields within that option
      textareaError: false
    }
  },
  computed: {
    modelRow() {
      return this.$store.state.resolveConflict
    },

    conflictSettings() {
      return (
        (this.modelRow &&
          this.modelRow.field &&
          this.modelRow.field.conflictSettings) ||
        {}
      )
    },

    isListType() {
      return this.modelRow.field.conflictAlerting === CONFLICT_ALERTING.LIST
    },

    isResolveButtonDisabled() {
      if (this.isResolveTextAreaManually && this.textareaError) return true
      return (
        this.isErnLinkingVisible &&
        this.ernActions.length !== this.getDeselectedErnRecords.length
      )
    },

    resolutionType() {
      return RESOLUTION_TYPE
    },

    isTextArea() {
      return this.modelRow.type === FIELD_TYPE.TEXTAREA
    },

    oesRecordNumberField() {
      return this.modelRow.field.oesRecordNumberField
    },

    getConfirmButtonText() {
      return this.isShowNextButton ? 'Next >' : 'Resolve'
    },

    getCancelButtonText() {
      return this.isErnLinkingVisible ||
        (this.isResolveTextAreaManually && !this.isAutoResolveTextAreaManually)
        ? '< Back'
        : 'Cancel'
    },

    getManualMergeText() {
      if (this.manualMergeText) {
        return this.manualMergeText
      }
      return `*** ${
        this.$store.state.selectedSchool?.schoolName
      } notes (${new Date().toLocaleDateString('en-AU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })}) ***\n${this.modelRow.oesValue}\n\n*** ERN Notes ***\n${
        this.modelRow.ernValue
      }`
    },

    getDeselectedErnRecords() {
      return this.getRecords(false, true)
    },

    getSelectedOesRecords() {
      return this.getRecords(true, false)
    },

    getSelectedErnRecords() {
      return this.getRecords(true, true)
    },

    getErnLinkingDroplistOptions() {
      // Builds droplist options for ERN linking droplists (showing selected OES items)
      var options = []
      this.getSelectedOesRecords.forEach((record, index) => {
        options.push({
          record: record,
          value: index,
          text: this.getErnLinkingOptionText(record)
        })
      })
      return options
    },

    isShowNextButton() {
      // Both LIST-type resolutions and TEXTAREA AB resolutions may display a 2nd UI step, thus
      // requiring a "Next" button.
      // If OES record(s) have been selected but an ERN record remains unselected (to be discarded),
      // the "Resolve" button should change to a "Next" button. This is because an extra UI step is
      // required to choose what should happen with the deselected ERN record(s), i.e. whether the
      // selected OES record should replace it.
      return (
        (this.keptValue === RESOLUTION_TYPE.MERGE &&
          !this.isResolveTextAreaManually) ||
        (this.isListType &&
          this.modelRow.field.ernRecordNumberField &&
          this.keptRecords.length &&
          this.getDeselectedErnRecords.length &&
          this.getSelectedOesRecords.length &&
          !this.isErnLinkingVisible)
      )
    },

    areErnActionsInvalid() {
      // This check ensures that the same OES record has not been selected to replace more than one
      // ERN record (as it would be nonsensical to allow this).

      // When one or more OES records have been ticked, but one or more ERN records are unticked,
      // we ask the user what should happen to the ERN records. Should they be replaced by an OES
      // record, or should they be removed? The answers to those questions are stored in ernActions.

      // Filter actions to record replacements only (there is nothing to check for record removals)...
      var replacements = this.ernActions.filter(
        (action) => action.action === REPLACE
      )

      // Find actions using the same replacement OES record number...
      var oesId = this.oesRecordNumberField
      var duplicates = replacements.filter((action, index) =>
        replacements.find(
          (otherAction, otherIndex) =>
            action.oesRecord[oesId] === otherAction.oesRecord[oesId] &&
            index !== otherIndex
        )
      )
      return duplicates.length > 0
    },

    getResolvedRecords() {
      // Returns the resolved set of records for a LIST type resolution. This involves:
      // - Returning user-selected OES records and setting any necessary ERN links (if the OES record should replace the ERN record)
      // - Returning user-selected ERN records
      var resolvedRecords = []
      this.getResolvedOesRecords(
        resolvedRecords,
        this.oesRecordNumberField,
        this.modelRow.field.ernRecordNumberField
      )
      this.getSelectedErnRecords.forEach((ernRecord) => {
        resolvedRecords.push(UTILS.clone(ernRecord))
      })
      return resolvedRecords
    },

    isAutoResolveTextAreaManually() {
      // If of type listed below the manual resolution step is shown automatically

      return Boolean(this.conflictSettings?.forceErnResolution)
    }
  },

  methods: {
    handleErrorupdate(state) {
      this.textareaError = state
    },
    selectOption(optionId) {
      if (this.isListType) {
        // List type allows multiple selection (checkbox)
        var idx = this.keptRecords.indexOf(optionId)
        if (idx >= 0) {
          this.keptRecords.splice(idx, 1)
        } else {
          this.keptRecords.push(optionId)
        }
      } else {
        // A-B type allows single selection (radio)
        this.keptValue = optionId
      }
    },

    selectErnRecordAction(ernRecord, action, oesRecord) {
      // Updates ernActions whenever an action is selected from the ERN action droplist
      var ernRecordNumberField = this.modelRow.field.ernRecordNumberField
      var existingActionIndex = this.ernActions.findIndex(
        (action) =>
          action.ernRecord[ernRecordNumberField] ===
          ernRecord[ernRecordNumberField]
      )
      if (existingActionIndex >= 0) {
        // Remove any existing action first...
        this.ernActions.splice(existingActionIndex, 1)
      }
      if (action) {
        this.ernActions.push({
          action: action,
          ernRecord: ernRecord,
          oesRecord: oesRecord
        })
      }
    },

    getRecords(isSelected, isErn) {
      // For LIST type resolutions, gets OES or ERN records for the current collection
      // - isSelected: If true, gets the records ticked by the user. Otherwise gets unticked records
      // - isErn: If true, gets ERN records. Otherwise gets OES records.
      var records = []
      var source = isErn ? this.modelRow.ernValue : this.modelRow.oesValue
      source.forEach((record, index) => {
        let recordIsSelected =
          this.keptRecords.indexOf((isErn ? 'ERN' : 'OES') + index) > -1
        if (isSelected === recordIsSelected) {
          var clone = UTILS.clone(record)
          records.push(clone)
        }
      })
      return records
    },

    getOptionFields(oesRecord, ernRecord) {
      // Build JSON array of each field label, OES field value and ERN field
      // value. This is passed to each resolution option so that they can show
      // the appropriate values within the option.
      var parentField = this.modelRow.field
      var fields
      var fieldData = []
      if (parentField.type === FIELD_TYPE.GROUP) {
        fields = this.getGroupFields(parentField, true)
      } else if (parentField.type === FIELD_TYPE.COLLECTION) {
        fields = this.getRecordFields(parentField, this.modelRow.oesValue)
      } else {
        return null
      }
      //This is to remove the field which is invisible
      const visibleField = fields.filter((field) => {
        if (!field.visible) {
          return true
        } else if (field.visible(this.application) === true) {
          return true
        } else {
          return false
        }
      })
      visibleField.forEach((field) => {
        if (!field.noConflictAlerting) {
          fieldData.push({
            label: this.getFieldLabel(field),
            oesValue: this.getFieldDisplayValue(
              field,
              false,
              oesRecord || this.modelRow.oesValue
            ),
            ernValue: this.getFieldDisplayValue(
              field,
              false,
              ernRecord || this.modelRow.ernValue
            )
          })
        }
      })
      return fieldData
    },

    getResolvedOesRecords(
      resolvedRecords,
      oesRecordNumberField,
      ernRecordNumberField
    ) {
      // Loop through each selected OES record...
      this.getSelectedOesRecords.forEach((record) => {
        // Lookup if any ERN record is being replaced by the OES record...
        var oesRecord = UTILS.clone(record)
        var replacementAction = this.ernActions.find(
          (action) =>
            action.action === REPLACE &&
            action.oesRecord[this.oesRecordNumberField] ===
              oesRecord[this.oesRecordNumberField]
        )
        if (replacementAction) {
          // If OES record is to replace an ERN record, set link in OES record...
          oesRecord[ernRecordNumberField] =
            replacementAction.ernRecord[ernRecordNumberField]
        }
        resolvedRecords.push(oesRecord)
      })
    },

    getErnLinkingOptionText(record) {
      // Concatenates record field values into a single string for use in ERN linking droplist
      var fieldLabels = []
      this.getOptionFields(record, {}).forEach((field) => {
        if (field.oesValue) {
          fieldLabels.push(field.oesValue)
        }
      })
      return fieldLabels.join(', ')
    },

    resolve() {
      // Proceed to next UI step if necessary...
      if (this.isShowNextButton) {
        if (this.keptValue === RESOLUTION_TYPE.MERGE) {
          // Proceed to manually merging textarea content
          this.manualMergeText = this.getManualMergeText
          this.isResolveTextAreaManually = true
        } else if (this.isListType) {
          // Proceed to selecting ERN record actions (replace or remove)
          this.ernActions = []
          this.isErnLinkingVisible = true
        }
        return
      }

      // When resolving TEXTAREA, warn if textarea exceeds limit
      if (this.isResolveTextAreaManually && this.chrsRemaining < 0) {
        this.$store.dispatch('showMessageBox', {
          icon: 'mdi-exclamation-thick',
          html: `
            <h2>Please shorten your text</h2>
            <div>Your text has exceeded ths 2048 character limit.</div>
          `
        })
        return
      }

      // Construct the resolution object...
      var resolution = {
        id: this.modelRow.id,
        staffName: this.$store.state.userName,
        date: this.$moment().format('YYYY-MM-DD HH:mm'),
        originalValue: this.modelRow.oesValue,
        originalErnValue: this.modelRow.ernValue
      }
      if (this.isListType) {
        if (this.areErnActionsInvalid) {
          this.$store.dispatch('showMessageBox', {
            icon: 'mdi-exclamation-thick',
            html: `
              <h2>Please choose different records</h2>
              <div>The same record can not replace multiple ERN records.</div>
            `
          })
          return
        } else {
          return this.resolveListType(resolution)
        }
      } else if (this.keptValue === 'ERN') {
        resolution.type = RESOLUTION_TYPE.AB_ERN
        resolution.resolvedValue = this.getErnValue()
      } else if (this.keptValue === RESOLUTION_TYPE.MERGE) {
        // When manually merging textarea content...
        resolution.type = RESOLUTION_TYPE.MERGE
        resolution.resolvedValue = this.manualMergeText
      } else {
        resolution.type = RESOLUTION_TYPE.AB_OES
        resolution.resolvedValue = this.modelRow.oesValue
      }
      this.save(resolution)
    },

    isResolutionOptionDisabled(modelRowId) {
      // OES resolution items containing invalid or missing values are disabled
      var isDisabled = this.$store.getters.model.find(
        (row) =>
          (row.isMissing || row.isInvalid) &&
          (row.id === modelRowId ||
            (row.parent && row.parent.id === modelRowId))
      )
        ? true
        : false
      if (isDisabled) {
        this.isDisabledOption = true
        if (!this.isListType) {
          this.keptValue = 'ERN' // Select the ERN option if OES option is disabled
        }
        return true
      }
    },

    resolveListType(resolution) {
      var me = this
      resolution.type = RESOLUTION_TYPE.LIST
      resolution.resolvedValue = this.getResolvedRecords
      if (!resolution.resolvedValue.length) {
        this.$store.dispatch('showMessageBox', {
          html: `
            <h2>Resolve with no records selected?</h2>
            <div>Only continue if you do not want to keep any of the records displayed.</div>
          `,
          textConfirm: 'Resolve',
          onConfirm() {
            me.save(resolution)
          }
        })
      } else {
        this.save(resolution)
      }
    },

    save(resolution) {
      this.$store.dispatch('updateAlertResolution', [this.modelRow, resolution])
      this.$store.dispatch('set', ['resolveConflict', null])
    },

    cancel() {
      if (
        this.isErnLinkingVisible ||
        (this.isResolveTextAreaManually && !this.isAutoResolveTextAreaManually)
      ) {
        this.isErnLinkingVisible = false
        this.isResolveTextAreaManually = false
        this.$refs.AppModal.focusDialog() // Calls method on AppModal to reset focus to the dialog itself
      } else {
        this.$store.dispatch('set', ['resolveConflict', null])
      }
    },

    getErnValue() {
      // Gets the ERN value to be copied across to OES. If resolving a record, the ERN
      // record must be copied over while retaining the OES record number. Also if the
      // record contains any ADDRESS fields, these must also retain their OES record number.
      var modelRow = this.modelRow
      var field = modelRow.field
      var ernValue = modelRow.ernValue
      if (
        field.type === FIELD_TYPE.GROUP ||
        field.type === FIELD_TYPE.ADDRESS ||
        field.conflictAlerting === CONFLICT_ALERTING.AB_GROUP
      ) {
        // Copies the ERN record into the OES record while maintaing the OES record number
        var ernRecord = UTILS.clone(ernValue)
        var oesRecordNumberField =
          field.type === FIELD_TYPE.ADDRESS
            ? 'addressRecordNo'
            : field.oesRecordNumberField
        if (oesRecordNumberField) {
          ernRecord[oesRecordNumberField] =
            modelRow.oesValue[oesRecordNumberField]
        }
        if (field.conflictAlerting === CONFLICT_ALERTING.AB_GROUP) {
          this.setRecordNumberForAddressesInCollection(
            modelRow.oesValue,
            ernRecord
          )
        }
        return ernRecord
      } else {
        return ernValue
      }
    },

    setRecordNumberForAddressesInCollection(oesRecord, ernRecord) {
      // When resolving an AB_GROUP record using the ERN value, we need
      // to check if that record contains any address records. If so, we
      // need to ensure that the OES address record number is retained when
      // bringing the ERN data across.
      Object.keys(oesRecord).forEach((key) => {
        if (
          UTILS.isObject(oesRecord[key]) &&
          oesRecord[key].addressRecordNo !== undefined
        ) {
          ernRecord[key].addressRecordNo = oesRecord[key].addressRecordNo
        }
      })
    }
  },

  mounted() {
    if (this.isAutoResolveTextAreaManually) {
      this.selectOption(this.resolutionType.MERGE)
      this.resolve()
    }
  }
}
</script>

<style scoped lang="scss">
.tickIcon,
.alertIcon {
  display: inline-block;
  color: white;
  background-color: green;
  font-size: 0.7em;
  margin-right: 0.25rem;
  border-radius: 1em;
  vertical-align: middle;
  line-height: 1;
  svg {
    width: 1em;
    height: 1em;
    margin: 0.5em;
    vertical-align: middle;
  }
}
.tickIcon {
  background-color: green;
}
.alertIcon {
  background-color: $color-red;
}
::v-deep .v-alert__warning-hack > .v-alert {
  .v-alert__border {
    border-width: 0;
  }
}
</style>
