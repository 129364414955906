var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-8 pt-4 pb-2"},[(
      (!_vm.considerations || !_vm.considerations.length) &&
      !_vm.application.otherConsiderations
    )?_c('no-details',{attrs:{"detail-name":"Consideration"}}):_vm._e(),_c('div',{staticClass:"downloadBtn"},[(_vm.isDisplayDownloadAll() && _vm.isOesAdmin)?_c('AdsButton',{attrs:{"secondary":"","button-text":"Download all","icon":"mdi-folder-multiple-outline"},on:{"click":function($event){_vm.showDownloadModal = true}}}):_vm._e(),(_vm.showDownloadModal)?_c('DownloadDocumentsModal',{attrs:{"applications":_vm.applicationInfo,"school-code":_vm.application.schoolCode,"skip-download-app":true,"application-type":_vm.APPLICATION_TYPE.OUT_OF_AREA},on:{"close":function($event){_vm.showDownloadModal = false}}}):_vm._e()],1),_vm._l((_vm.considerations),function(consideration,i){return _c('v-card',{key:i,staticClass:"mb-6 px-4 py-3"},[_c('div',{staticClass:"consideration-heading px-0 py-2"},[_vm._v(" "+_vm._s(_vm.schoolCriteria(consideration.code))+" ")]),(consideration.description)?_c('read-only-field',{attrs:{"label":"Details","value":consideration.description,"readonly":""}}):_vm._e(),(
        _vm.supportingDocuments != null &&
        _vm.supportingDocuments[consideration.code] != null
      )?_c('div',{staticClass:"px-0 mb-4"},[_c('label',{staticClass:"supporting-docs-label"},[_vm._v("Supporting documents")]),_vm._l((_vm.supportingDocuments[consideration.code]),function(doc,j){return _c('AppFileEditor',{key:j,attrs:{"label":doc.objectKey,"value":doc.objectKey,"handle-view":_vm.handleView,"can-view":_vm.isOesAdmin,"status":doc.status}})})],2):_vm._e()],1)}),(_vm.application.otherConsiderations)?_c('v-card',{staticClass:"mb-3 px-4"},[_c('div',{staticClass:"consideration-heading px-0 py-2"},[_vm._v("Other considerations")]),(_vm.application.otherConsiderations)?_c('read-only-field',{attrs:{"label":"Details","value":_vm.application.otherConsiderations,"readonly":""}}):_vm._e()],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }