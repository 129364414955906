export const STATES = [
  { value: 'NSW', text: `NSW` },
  { value: 'VIC', text: `VIC` },
  { value: 'QLD', text: `QLD` },
  { value: 'SA', text: `SA` },
  { value: 'WA', text: `WA` },
  { value: 'TAS', text: `TAS` },
  { value: 'NT', text: `NT` },
  { value: 'ACT', text: `ACT` },
  { value: 'OTH', text: `Other Territories` },
  { value: 'OVS', text: `Overseas` },
  { value: 'YYY', text: `Other/Not Applicable` },
  { value: 'XXX', text: `Unknown/Not Provided` },
  { value: 'ZZZ', text: `Not Stated (intentionally)` }
]
