// Contains all common field functions.

// IMPORTANT *********************************************************
// Please use setStore() when initialising this helper. It prevents us
// having to pass the store into every function.

// NOTE **************************************************************
// Try/catches are used to handle and log potential mistakes made in
// the field configuration. Otherwise these can break the whole form
// and can be hard to track down.

import { FIELD_TYPE, DATASET, DATE_FORMAT } from '@/constants'
import UTILS from '@/store/utils'
import moment from 'moment'
import { STATES } from '@/applicationDefinition/droplistData/states'
import VALIDATION_RULES from '@/store/validation'

var $store

// LOCAL HELPER FUNCTIONS *************************************************

// We're prefixing local helper functions with '_' to distinguish them from
// public exported functions.

function _getApplicationData() {
  // Gets safe copy of OES application data for passing to field configuration
  if ($store.state.application) {
    return UTILS.clone($store.state.application)
  }
  return {}
}

function _getFieldId(section, field, recordIndex) {
  // Gets the unique id for referencing a specific field in a specific section.
  // recordIndex can be provided if referencing a record.
  if (
    field.type === FIELD_TYPE.HEADING ||
    field.type === FIELD_TYPE.LINEBREAK ||
    field.type === FIELD_TYPE.CUSTOM
  ) {
    return ''
  }
  if (typeof recordIndex === 'number') {
    return `${section.id}>${field.apiKey}[${recordIndex}]`
  }
  return `${section.id}>${field.apiKey}`
}

function _getOptions(modelRow, addBlankOption) {
  // Gets options for a droplist field or radio button. If the field definition
  // does not have an "options" property, look up content from "state.referenceData"
  // (which is loaded via api).
  //  addBlankOption - If true, adds a blank item at the beginning of the array (for droplists)
  let optionsOut = addBlankOption ? [{ value: '', text: '' }] : []
  try {
    let optionsIn =
      modelRow.field.options ||
      $store.getters.referenceData(modelRow.field.dataset)
    let divider = UTILS.getListDivider(optionsIn)
    optionsIn.forEach((option) => {
      if (option.divider) {
        optionsOut.push({
          value: '',
          text: divider,
          disabled: true
        })
      } else if (!option.hidden) {
        optionsOut.push({
          value: option.value,
          text: option.text
        })
      }
    })
  } catch (e) {
    UTILS.log(`${modelRow.id} - COULD NOT GET DROPLIST OPTIONS`)
  }
  return optionsOut
}

function _getDroplistItemText(field, value) {
  // Looks up the field value in the options data and returns the user text
  try {
    let options = field.options || $store.getters.referenceData(field.dataset)

    // Normally we would find an exact value match in the options data. However
    // we have an issue where the api sometimes performs unexpected string conversion
    // of doctor record number, meaning that doctor lookups can fail (because we may
    // be comparing a number to a string). To fix this we match on value but not
    // type (==). Non-values (empty/null/false) still need to be matched on value
    // and type (===) because they need to be differentiated.
    return options.find((item) =>
      item.value && value ? item.value == value : item.value === value
    ).text
  } catch (e) {
    if (value || value === false) {
      UTILS.log(`${field.apiKey} - OPTION VALUE NOT FOUND: ${value}`)
    }
    return value
  }
}

function _getAddressText(field, value) {
  try {
    let state = STATES.find((state) => state.value === value.stateCode)
    let country = $store.getters.lookupCode(
      DATASET.COUNTRIES,
      value.countryCode
    )
    let parts = [value.addressLine1, value.addressLine2]
    if (value.countryCode === 'AUS') {
      parts.push(value.suburbName)
      parts.push(state ? state.text : value.stateCode)
      parts.push(value.postCode)
    }
    if (field.showCountry && country) {
      parts.push(country)
    }
    return parts.filter((part) => part).join(', ') // Returns populated parts as comma separated address
  } catch (e) {
    UTILS.log(`${field.apiKey} - INCORRECT ADDRESS CONFIGURATION`)
    return value
  }
}

function _getSchoolName(value) {
  // prevSchoolId identifies the previous AU school. The id is actually
  // compound, e.g. "616|Coogee Public School"
  // This means we can use this id to get the name...
  try {
    return value.split('|')[1] || ''
  } catch (e) {
    return value || ''
  }
}

// PUBLIC HELPER FUNCTIONS *************************************************

export default {
  setStore(store) {
    // The store must always be passed in when initialising the helper
    $store = store
  },

  isFieldRequired(field, oesApplication) {
    try {
      return (
        field.required === true ||
        (typeof field.required === 'function' && field.required(oesApplication)
          ? true
          : false)
      )
    } catch (e) {
      UTILS.log(`${field.apiKey} - INVALID required() FUNCTION`)
    }
  },

  getValidationMessage(field, fieldValue, oesApplication) {
    if (field.type && fieldValue) {
      let typeValidation = VALIDATION_RULES.find((v) => v.type === field.type)
      if (typeValidation && typeValidation.isInvalid(fieldValue, field)) {
        return typeValidation.message
      }
    }
    if (field.type !== FIELD_TYPE.ADDRESS && String(fieldValue).length > 2048) {
      return `Exceeded limit by ${
        String(fieldValue).length - 2048
      } character(s)`
    }
    if (field.validation) {
      try {
        const result = field.validation(fieldValue, oesApplication)
        return typeof result === Boolean ? null : result
      } catch (e) {
        UTILS.log(`${field.apiKey} - FIELD VALIDATION FAILED`)
      }
    }
  },

  isMissing(field, fieldValue, oesApplication) {
    // Returns true if required field is missing
    if (this.isFieldRequired(field, oesApplication)) {
      if (field.type === FIELD_TYPE.ADDRESS) {
        return (
          UTILS.isEmpty(fieldValue) || UTILS.isEmpty(fieldValue.addressLine1)
        )
      }
      return UTILS.isEmpty(fieldValue)
    }
    return false
  },

  isFieldVisible(field, oesApplication) {
    if (field.visible) {
      try {
        return field.visible(oesApplication || _getApplicationData())
      } catch (e) {
        UTILS.log(`${field.apiKey} - COULD NOT GET VISIBILITY`)
      }
    }
    return true
  },

  getFieldId(section, field, recordIndex) {
    // Gets the unique id for referencing a specific field in a specific section.
    // recordIndex can be provided if referencing a record
    return _getFieldId(section, field, recordIndex)
  },

  getFieldLabel(field, addColon, applicationData) {
    // Gets label for specified field (which can be static or dynamic).
    // addColon: true - Terminates label with a colon unless already terminated
    if (!field.label) {
      return ''
    }
    try {
      var label =
        typeof field.label === 'function'
          ? field.label(applicationData || _getApplicationData())
          : field.label
      if (!addColon) {
        return label
      }
      var lastChr = label.substr(label.length - 1)
      var noColonIfEndingIn = ':;?!-'
      return label + (noColonIfEndingIn.indexOf(lastChr) === -1 ? ':' : '')
    } catch (e) {
      UTILS.log(`${field.apiKey} - ERROR GETTING LABEL`)
      return 'LABEL ERROR'
    }
  },

  getValue(apiKey) {
    // Returns a value from the current application for the specified api key, e.g. 'student.familyName'
    try {
      return eval(`$store.state.application.${apiKey}`)
    } catch (e) {
      UTILS.log(`${apiKey} - ERROR GETTING VALUE`)
    }
  },

  getFieldValue(field, isErn, record) {
    // Gets a field value from the current application.
    // isErn - Optional: If true, value is loaded from ERN record (not applicable when "record" parameter is specified)
    // record - Optional: If specified, value is loaded from this record instead of loading from state
    if (!$store && !record) {
      UTILS.log('STORE IS NOT SET IN FIELD HELPER')
    }
    var value
    try {
      // Note: Field apiKey might reference a nested value (e.g. 'student.familyName')
      // so an eval is the most efficient way to resolve the value.
      if (record) {
        value = eval(`record.${field.apiKey}`)
      } else {
        value = eval(
          `${isErn ? '$store.state.ernRecord' : '$store.state.application'}.${
            field.apiKey
          }`
        )
      }
    } catch (e) {
      UTILS.log(`${field.apiKey} - ERROR GETTING FIELD VALUE`)
    }
    if (value === null || value === undefined) {
      // Convert null/undefined into correct empty value for current field datatype. Also
      // ensure we have a field value in the data, otherwise Vue fields won't be reactive.
      value =
        field.type === FIELD_TYPE.COLLECTION
          ? []
          : field.type === FIELD_TYPE.GROUP
          ? {}
          : ''
      if (!isErn && !record) {
        $store.dispatch('setFieldValue', [field, value])
      }
    }
    return value
  },

  getFieldDisplayValue(field, isErn, record) {
    // Gets the field value displayed to the user. This is the same as the field value except
    // for DATE, DROPLIST and ADDRESS fields, where the result has to be formatted or looked up.
    // isErn - Optional: If true, value is loaded from ERN record (not applicable when "record" parameter is specified)
    // record - Optional: If specified, value is loaded from this record instead of loading from state
    var value = this.getFieldValue(field, isErn, record)
    return this.getDisplayValue(field, value)
  },

  getDisplayValue(field, value) {
    // Takes a value and converts it to a display value, based on the type of field. DATE,
    // DROPLIST and ADDRESS fields have a different display value to their stored value,
    // usually requiring special formatting or lookups.
    if (field.type === FIELD_TYPE.DROPLIST || field.type === FIELD_TYPE.RADIO) {
      return _getDroplistItemText(field, value)
    } else if (field.type === FIELD_TYPE.DATE && value) {
      if (field.monthOnly) {
        if (/^(0[1-9]|1[0-2])\/([0-9]{4})$/gm.test(value)) {
          // already formatted as MM/YYYY, so we can display the value without additional formatting
          return value
        } else {
          return moment(value, DATE_FORMAT).format('MM/YYYY')
        }
      }
      return moment(value, DATE_FORMAT).format('DD MMM YYYY') // e.g. "12 Dec 2019"
    } else if (field.type === FIELD_TYPE.ADDRESS && value) {
      return _getAddressText(field, value)
    } else if (field.type === FIELD_TYPE.SCHOOL_LOOKUP) {
      return _getSchoolName(value)
    } else if (field.type === FIELD_TYPE.MM_YYYY) {
      return moment(value, DATE_FORMAT).format('MM-YYYY')
    }
    return value
  },

  getDroplistOptions(modelRow) {
    return _getOptions(modelRow, true)
  },

  getDroplistOptionText(list, option) {
    // Gets text for a specified droplist option or droplist divider
    if (option.divider) {
      return UTILS.getListDivider(list)
    }
    return option.text
  },

  getRadioOptions(modelRow) {
    return _getOptions(modelRow)
  },

  isRecordFilteredOut(field, record) {
    // Returns TRUE if the record should be filtered out according to any filter currently
    // set on the COLLECTION field.
    return field.filter && record[field.filter.apiKey] !== field.filter.value
  },

  getRecordFields(collectionField, record, recordIndex, applicationData) {
    // Gets fields for the specified COLLECTION field, for the specified record.
    // collectionField: <json> required
    // record: <json> required
    // recordIndex: <integer> required if field apiKeys needs full hierarchy (see below)
    // applicationData: <json> optional - Allows application data to be passed in instead of using state.application

    // Field values are resolved using the field's apiKey. When nesting records, each field apiKey
    // will need the full object hierarchy, e.g. "parentCarers[0].contactDetails[0].contactValue"

    // If recordIndex is not provided, no hierarchy prefix will be added to the record field
    // apiKeys. This can be useful when resolving record field values for a specific record.

    let apiKeyPrefix =
      recordIndex === undefined
        ? ''
        : `${collectionField.apiKey}[${recordIndex}].`
    try {
      return collectionField.fields(
        apiKeyPrefix,
        record,
        applicationData || _getApplicationData()
      )
    } catch (e) {
      UTILS.log(
        `${collectionField.apiKey} - ERROR IN COLLECTION FIELD CONFIGURATION`
      )
      return []
    }
  },

  getGroupFields(groupField, noPrefix, applicationData) {
    // Gets fields for the specified GROUP field.
    // groupField <json>: Required - Field object (of type GROUP)
    // noPrefix <boolean>: Optional - If true, field apiKeys will not be prefixed with the group apiKey.
    // applicationData: <json> Optional - Allows application data to be passed in instead of using state.application
    let apiKeyPrefix = noPrefix ? '' : `${groupField.apiKey}.`
    try {
      return groupField.fields(
        apiKeyPrefix,
        this.getFieldValue(groupField, null, applicationData),
        applicationData || _getApplicationData()
      )
    } catch (e) {
      UTILS.log(`${groupField.apiKey} - ERROR IN GROUP FIELD CONFIGURATION`)
      return []
    }
  },

  getRelativeApiKey(apiKey) {
    /*
      Application fields are always defined with full-path api keys, e.g.

      - "parentCarers[1].contactDetails[0].contactValue"

      These map the fields to an application value, and will return this value
      when evaluated. However sometimes we need to get a "relative" api key, e.g.

      - "contactValue"

      Relative api keys are relative to the record they belong to. They are used when
      finding values in auto-linked ERN records. Full-path api keys cannot be used for
      ERN values because they point to specific OES record indexes, and these indexes
      may be completely different (or non existant) in the ERN data.
    */
    let parts = apiKey.split(']')
    if (parts.length === 1) {
      return parts[0] // Return whole key if no record prefix
    }
    return parts.pop().substr(1) // Return key without record prefix
  },

  isDescendantModelRow(ancestor, descendant) {
    return descendant.id.startsWith(ancestor.id)
  }
}
