<!-- 
  Provides the template for the application save/cancel bar
-->
<template>
  <div class="ApplicationSaveCancel">
    <div class="bar">
      <button type="reset">Cancel</button>
      <button class="primary">Update</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApplicationSaveCancel'
}
</script>

<style scoped lang="scss">
.ApplicationSaveCancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: white;
  text-align: right;
  padding: 0 $content-padding;
  box-shadow: 0 0 5px #a9a9a9;

  .bar {
    padding: 0.75rem 0;
  }

  button {
    margin: 0 0 0 0.5em;
    width: 7em;
    border: 0;
  }
}
</style>
