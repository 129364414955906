<template>
  <div>
    <div class="d-flex align-center">
      <v-avatar class="humanIcon" height="46">
        <v-icon>mdi-monitor-dashboard</v-icon>
      </v-avatar>
      <span class="heading-text pl-3">
        School website Religion & Ethics page URL</span
      >
    </div>
    <p class="mt-2 primary--text text-16-24">
      Provide the link to the Religion & Ethics page on the
      {{ schoolName }} website.<br />
      This link will be visible to parents/carers completing an enrolment
      application or SRE/SEE preference update form.
    </p>

    <v-form ref="sreForm" class="mt-8 form-width--808">
      <AdsTextField
        v-model="sreSeeUrl"
        label="School website Religion & Ethics page URL"
        data-testid="sre-see-curriculum-link"
        placeholder="Enter your school website Religion & Ethics page URL"
        @keyup="setSchoolSreSeeUrlLink(sreSeeUrl)"
        :rules="[validators.isValidURL]"
        persistent-placeholder
      />
    </v-form>
  </div>
</template>

<script>
import validators from '@/helpers/validators'
import { AdsTextField } from '@nswdoe/doe-ui-core'

export default {
  name: 'AppSettingsSreSeeUrl',
  components: { AdsTextField },
  props: {
    schoolName: {
      type: String,
      required: true
    },
    savedSreSeeUrl: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      validators: {
        required: validators.required,
        isValidURL: validators.isValidURL,
        duplicateOption: validators.duplicateOption,
        isTitleExists: validators.titleExists
      },
      sreSeeUrl: this.savedSreSeeUrl || null
    }
  },
  mounted() {
    this.$eventHub.$on('validate', this.validate)

    this.$nextTick(function () {
      this.$emit('validateForm')
    })
  },
  methods: {
    validate() {
      if (this.$refs.sreForm) {
        this.$refs.sreForm.validate()
      }
    },
    setSchoolSreSeeUrlLink(url) {
      // IF Staff gave URL start with '*.com' and then replacing with 'https://*.com'
      if (
        url != null &&
        url.trim().length > 0 &&
        url.trim().substr(0, 4) !== 'http'
      ) {
        url = `https://${url}`
      }
      this.$emit('updatedSreSeeSchoolUrl', url)
    }
  }
}
</script>

<style lang="scss" scoped>
.humanIcon {
  background: $ads-light-blue;
  color: $ads-navy;
}

.heading-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

.form-width--808 {
  max-width: 808px;
}

.text-16-24 {
  font-size: 16px;
  line-height: 24px;
}
</style>
