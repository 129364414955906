import API from '@/store/apiUtils'

const y67tDocumentUploadModule = {
  actions: {
    getY67TSignedUrl(
      _,
      {
        schoolCode,
        applicationId,
        file,
        action,
        previewMode,
        stream,
        uploadedBy,
        primarySchoolCode,
        hsTrackingAction,
        dateStamp,
        isBulkOfferAction,
        bulkOfferId
      }
    ) {
      return API.post(
        `${process.env.VUE_APP_API_PI}/v1/application/submitted/${schoolCode}/${
          isBulkOfferAction ? bulkOfferId : applicationId
        }/generateSignedUrl`,
        {
          filename: hsTrackingAction ? dateStamp + '/' + file.name : file.name,
          filetype: file.type,
          action,
          stream,
          staffEmail: uploadedBy,
          previewMode,
          primarySchoolCode,
          hsTrackingAction,
          isBulkOfferAction
        },
        true,
        { schoolcode: schoolCode }
      ).then((resp) => {
        return resp && resp.data && resp.data.body ? resp.data.body.Url : null
      })
    },
    async uploadY67TSupportingDocument(
      { dispatch },
      {
        schoolCode,
        applicationId,
        file,
        uploadedBy,
        progressCallback,
        stream,
        dateStamp,
        hsTrackingAction,
        isBulkOfferAction,
        bulkOfferId
      }
    ) {
      const signedUrl = await dispatch('getY67TSignedUrl', {
        schoolCode,
        applicationId,
        file,
        action: 'UPLOAD',
        stream,
        uploadedBy,
        dateStamp,
        hsTrackingAction,
        isBulkOfferAction,
        bulkOfferId
      })

      const axiosConfig = {
        onUploadProgress: progressCallback
      }
      const headers = {
        noDefaultHeader: true,
        'Content-Type': file.type,
        'x-amz-tagging': `uploadedby=${uploadedBy}`
      }

      await API.put(
        signedUrl,
        file,
        true, // no loader
        headers,
        true, // ignore error handling
        axiosConfig
      )

      // extract out the path "https://domain.com/path/to/the/file.pdf?params=abc" => "path/to/the/file.pdf"
      // can't use new URL().pathname because IE11 is a baby
      const filePath = signedUrl.match(/https:\/\/[a-zA-Z0-9.\-_]+\/(.*)\?/)[1]
      return decodeURIComponent(filePath)
    },
    async deleteY67TSupportingDocument(
      { dispatch },
      {
        schoolCode,
        applicationId,
        file,
        uploadedBy,
        stream,
        hsTrackingAction,
        dateStamp,
        isBulkOfferAction,
        bulkOfferId
      }
    ) {
      const signedUrl = await dispatch('getY67TSignedUrl', {
        schoolCode,
        applicationId,
        file,
        action: 'REMOVE',
        stream,
        uploadedBy,
        dateStamp,
        hsTrackingAction,
        isBulkOfferAction,
        bulkOfferId
      })

      const headers = {
        noDefaultHeader: true
      }

      return API.delete(
        signedUrl,
        null,
        true, // no loader
        headers,
        true // ignore error handling
      )
    },
    async viewY67TSupportingDocument(
      { dispatch },
      {
        schoolCode,
        applicationId,
        file,
        uploadedBy,
        stream,
        primarySchoolCode,
        hsTrackingAction,
        dateStamp,
        isBulkOfferAction,
        bulkOfferId
      }
    ) {
      const signedUrl = await dispatch('getY67TSignedUrl', {
        schoolCode,
        applicationId,
        file,
        action: 'DOWNLOAD',
        previewMode: 'INLINE',
        stream,
        uploadedBy,
        primarySchoolCode,
        dateStamp,
        hsTrackingAction,
        isBulkOfferAction,
        bulkOfferId
      })
      window.open(signedUrl, '_blank')
    }
  }
}

export default y67tDocumentUploadModule
