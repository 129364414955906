/*
  Makes common helper functions and getters available within a template. Note that some
  functions also need to be accessible to other JS code. In these cases we keep the function
  in a helper file (fieldHelper.js) which can be imported anywhere. To make these functions
  accessible from the mixin we provide a wrapper function for each, e.g. getFieldId().
*/

import Helper from '@/helpers/fieldHelper'
import UTILS from '@/store/utils'
import { mapGetters } from 'vuex'
import { FIELD_TYPE } from '@/constants'

export default {
  beforeCreate() {
    Helper.setStore(this.$store)
  },

  computed: {
    ...mapGetters([
      'model',
      'referenceData',
      'application',
      'isEditing',
      'isPrintPreview',
      'isSentToErn',
      'isSpinner',
      'isWithdrawn',
      'isInvalid',
      'isInternalTransfer',
      'isDeclined',
      'isAccepted',
      'focusFieldId',
      'totalAlerts',
      'isCheckForSrn',
      'missingFields',
      'invalidFields',
      'conflictFields',
      'unresolvedMatching'
    ])
  },

  methods: {
    getFirstField(modelRow) {
      // Returns the model row for the first editable field, starting from
      // the specified model row.
      const startIndex = this.model.findIndex((row) => row.id === modelRow.id)
      let index = startIndex
      let exclude = [
        FIELD_TYPE.COLLECTION,
        FIELD_TYPE.RECORD,
        FIELD_TYPE.GROUP,
        FIELD_TYPE.HEADING,
        FIELD_TYPE.LINEBREAK,
        FIELD_TYPE.CUSTOM
      ]

      let isFocusableField = (row) => {
        // Returns true if modelRow is a field that can be focused. A special
        // exception is also made for 'modelRow.isWarning', where the 'Ignore'
        // warning option can be focused.
        let isExcludedField = exclude.find((rowType) => rowType === row.type)
        return (
          row.isWarning ||
          (!isExcludedField && !row.isHidden && !row.isReadOnly)
        )
      }

      while (
        index < this.model.length &&
        !isFocusableField(this.model[index])
      ) {
        if (Helper.isDescendantModelRow(modelRow, this.model[index])) {
          //Still a descendant of our initial field
          index++
        } else {
          //We've moved to a different section without finding a focusable field => default to original field.
          index = startIndex
          break
        }
      }
      return this.model[index]
    },

    getChildren(modelRow) {
      // Gets child model rows for a specified model row (excludes hidden rows)
      return this.model.filter(
        (row) => row.parent && row.parent.id === modelRow.id && !row.isHidden
      )
    },

    getFieldId(section, field, recordIndex) {
      return Helper.getFieldId(section, field, recordIndex)
    },

    getFieldLabel(field, addColon) {
      return Helper.getFieldLabel(field, addColon)
    },

    getValue(apiKey) {
      return Helper.getValue(apiKey)
    },

    getFieldValue(field, isErn, record) {
      return Helper.getFieldValue(field, isErn, record)
    },

    getFieldDisplayValue(field, isErn, record) {
      return Helper.getFieldDisplayValue(field, isErn, record)
    },

    getDisplayValue(field, value) {
      return Helper.getDisplayValue(field, value)
    },

    getDroplistOptions(modelRow) {
      return Helper.getDroplistOptions(modelRow)
    },

    getDroplistOptionText(list, option) {
      return Helper.getDroplistOptionText(list, option)
    },

    getRadioOptions(modelRow) {
      return Helper.getRadioOptions(modelRow)
    },

    getRecordFields(collectionField, record, recordIndex) {
      return Helper.getRecordFields(collectionField, record, recordIndex)
    },

    getGroupFields(groupField, noPrefix) {
      return Helper.getGroupFields(groupField, noPrefix)
    },

    getFieldWidthClass(modelRow) {
      // Returns CSS class for setting field width
      return modelRow.field.colspan === 2
        ? ' width66'
        : modelRow.field.colspan === 3
        ? ' width100'
        : ''
    },

    getFieldRedBarClass(modelRow) {
      // Returns the css class required to show the red bar
      // along the left edge of missing/invalid fields.
      return modelRow.isInvalid || modelRow.isMissing ? ' showRedBar' : ''
    },

    formatValue(field, value) {
      // Formats a field value according to any "format" parameter set on that field.
      // Note that some field types (e.g. MONTH_YEAR) have a default format.

      // Formats are specified using 'X' (for alphanumerics) and interspersed with formatting
      // characters, e.g. "XX/XXXX"

      var fieldFormat = field.format

      if (typeof value === 'string') {
        // Always trim whitespace from beginning and end
        value = value.trim()
      }

      if (!fieldFormat && field.type === FIELD_TYPE.MONTH_YEAR) {
        fieldFormat = 'XX/XXXX'
      }

      if (fieldFormat) {
        var formatted = []
        var formatChr
        var index = 0

        // Strip whitespace and symbols
        value = value.replace(/[^a-zA-Z0-9]/g, '')

        // Step through each field value character and insert formatting where necessary
        value.split('').forEach((chr) => {
          do {
            // Insert formatting character(s)...
            formatChr = fieldFormat.substr(index, 1)
            if (formatChr && formatChr !== 'X') {
              formatted.push(formatChr)
              index++
            }
          } while (formatChr && formatChr !== 'X')

          // Include field value character
          formatted.push(chr)
          index++
        })
        return formatted.join('')
      }
      return value
    },

    removeNonAsciiCharacters(val) {
      // eslint-disable-next-line no-control-regex
      return val ? val.replace(/[^\x00-\x7F]/g, '') : val
    },

    onFieldChange(field, value) {
      if (typeof field.onChange === 'function') {
        try {
          field.onChange(value, this.$store)
        } catch (e) {
          UTILS.log(`${field.apiKey} - ERROR IN onChange() CONFIGURATION`)
        }
      }
    },

    formatPhoneNumber(val) {
      if (val) {
        // alternative Australia-wide landline number, eg: 13 00 00
        if (val.replace(/\s/g, '').length === 6) {
          val = val.replace(/\W/gi, '').replace(/(.{2})(.{2})/g, '$1 $2 ')
        }

        // landline number, eg: 1234 4321
        if (val.replace(/\s/g, '').length === 8) {
          val = val
            .replace(/\W/gi, '')
            .replace(/(.{4})/g, '$1 ')
            .trim()
        }

        if (val.replace(/\s/g, '').length === 10) {
          // for mobile and Australia-wide landline number. eg: 0400 000 000 || 1300 975 707
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '04' ||
            val.replace(/\W/gi, '').substring(0, 2) === '05' ||
            val.replace(/\W/gi, '').substring(0, 2) === '13' ||
            val.replace(/\W/gi, '').substring(0, 2) === '18'
          ) {
            val = val.replace(/\W/gi, '').replace(/(.{4})(.{3})/g, '$1 $2 ')
          }
          // for landline number including area code, eg: 02 1234 4321
          if (
            val.replace(/\W/gi, '').substring(0, 2) === '02' ||
            val.replace(/\W/gi, '').substring(0, 2) === '03' ||
            val.replace(/\W/gi, '').substring(0, 2) === '07' ||
            val.replace(/\W/gi, '').substring(0, 2) === '08'
          ) {
            val = val.replace(/\W/gi, '').replace(/(.{2})(.{4})/g, '$1 $2 ')
          }
        }
      }
      return val
    }
  }
}
