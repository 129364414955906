var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'Field ' + _vm.getFieldRedBarClass(_vm.modelRow) + _vm.getFieldWidthClass(_vm.modelRow),attrs:{"data-id":_vm.modelRow.id}},[(_vm.isFieldLabel)?_c('label',[_c('span',{staticClass:"fieldLabel"},[_vm._v(_vm._s(_vm.modelRow.label))]),(_vm.isTextInput)?_c('div',{staticClass:"fieldContent"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFieldValue),expression:"currentFieldValue"}],ref:"defaultInput",attrs:{"type":"text","aria-describedby":_vm.modelRow.field && _vm.modelRow.field.apiKey
            ? _vm.modelRow.field.apiKey + '-error'
            : '',"maxlength":_vm.getMaxLength,"placeholder":_vm.getPlaceholder},domProps:{"value":(_vm.currentFieldValue)},on:{"keyup":function($event){return _vm.onKeyup()},"change":function($event){return _vm.onChange(_vm.currentFieldValue)},"focus":function($event){return _vm.onFocus()},"input":function($event){if($event.target.composing)return;_vm.currentFieldValue=$event.target.value}}})]):_vm._e(),(_vm.modelRow.type === 'ADDRESS')?_c('div',{staticClass:"fieldContent"},[_c('button',{ref:"defaultInput",staticClass:"address unstyled",attrs:{"type":"button","title":"Click to modify address","aria-describedby":_vm.modelRow.field && _vm.modelRow.field.apiKey
            ? _vm.modelRow.field.apiKey + '-error'
            : ''},on:{"click":function($event){return _vm.editAddress()},"focus":function($event){return _vm.onFocus()}}},[_c('DisplayAddress',{staticClass:"addressText",attrs:{"model-row":_vm.modelRow}}),_c('div',{staticClass:"openAddressIcon"},[_c('font-awesome-icon',{staticClass:"editAddress",attrs:{"focusable":"false","icon":"ellipsis-h"}})],1)],1)]):_vm._e(),(_vm.modelRow.type === 'TEXTAREA')?_c('div',{staticClass:"fieldContent"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFieldValue),expression:"currentFieldValue"}],ref:"defaultInput",attrs:{"maxlength":_vm.getMaxLength,"aria-describedby":_vm.modelRow.field && _vm.modelRow.field.apiKey
            ? _vm.modelRow.field.apiKey + '-error'
            : ''},domProps:{"value":(_vm.currentFieldValue)},on:{"keyup":function($event){return _vm.onKeyup()},"change":function($event){return _vm.onChange(_vm.currentFieldValue)},"focus":function($event){return _vm.onFocus()},"input":function($event){if($event.target.composing)return;_vm.currentFieldValue=$event.target.value}}})]):_vm._e(),(_vm.modelRow.type === 'DROPLIST')?_c('div',{staticClass:"fieldContent"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentFieldValue),expression:"currentFieldValue"}],ref:"defaultInput",attrs:{"aria-describedby":_vm.modelRow.field && _vm.modelRow.field.apiKey
            ? _vm.modelRow.field.apiKey + '-error'
            : ''},on:{"keyup":function($event){return _vm.onKeyup()},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.currentFieldValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.onChange(_vm.currentFieldValue)}],"focus":function($event){return _vm.onFocus()}}},_vm._l((_vm.getDroplistOptions(_vm.modelRow)),function(option,index){return _c('option',{key:_vm.modelRow.id + index,attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0)]):_vm._e(),(_vm.modelRow.type === 'SCHOOL_LOOKUP')?_c('div',{staticClass:"fieldContent"},[_c('SchoolLookup',{ref:"defaultInput",attrs:{"name":"Au School lookup","selected-school-id":_vm.modelRow.oesValue},on:{"schoolCodeChange":_vm.onChange}})],1):_vm._e()]):_vm._e(),(_vm.modelRow.type === 'RADIO')?_c('fieldset',{staticClass:"fieldContent"},[_c('legend',{staticClass:"fieldLabel"},[_vm._v(_vm._s(_vm.modelRow.label))]),_vm._l((_vm.getRadioOptions(_vm.modelRow)),function(option,index){return _c('AppCheckbox',{key:_vm.modelRow.id + index,ref:"defaultInput",refInFor:true,attrs:{"data-id":_vm.modelRow.id + ':' + option.value,"label":option.text,"checked":_vm.modelRow.oesValue === option.value,"aria-described-by":_vm.modelRow.field && _vm.modelRow.field.apiKey
          ? _vm.modelRow.field.apiKey + '-error'
          : ''},on:{"change":function($event){return _vm.setRadioButton(option.value, ...arguments)}}})})],2):_vm._e(),(_vm.modelRow.type === 'DATE')?_c('fieldset',[_c('legend',{staticClass:"fieldLabel"},[_vm._v(_vm._s(_vm.modelRow.label))]),_c('AppDatePicker',{ref:"defaultInput",attrs:{"label":_vm.modelRow.label,"value":_vm.modelRow.oesValue,"future-years":_vm.getFutureYears(_vm.modelRow),"past-years":_vm.getPastYears(_vm.modelRow),"special-years":_vm.getSpecialYears(_vm.modelRow),"month-only":_vm.modelRow.field.monthOnly,"aria-described-by":_vm.modelRow.field && _vm.modelRow.field.apiKey
          ? _vm.modelRow.field.apiKey + '-error'
          : ''},on:{"change":_vm.onChange,"focus":function($event){return _vm.onFocus()}}})],1):_vm._e(),(_vm.exceededLimitBy > 0)?_c('div',{staticClass:"FieldAlert"},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"focusable":"false","icon":"exclamation-triangle"}}),_c('span',{staticClass:"alertMessage"},[_vm._v("Exceeded limit by "+_vm._s(_vm.exceededLimitBy)+" character(s)")])],1):_c('FieldAlert',{attrs:{"model-row":_vm.modelRow}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }