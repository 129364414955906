<!--
  Provides the template for a single selectable option in the conflict resolution dialog.
  For A-B conflicts, an option is simply a field value (either OES or ERN).
  For A-B group conflicts, an option shows all field values for that OES or ERN group
  (e.g. an address). Conflicting values within the group are bolded.
  For LIST-type conflicts, an option represents a single OES or ERN record (e.g. preschool).
  All record field values are displayed within the option and any conflicting values are bolded.
-->
<template>
  <button
    :class="getCssClass"
    type="button"
    :disabled="disabled"
    @click="onClick"
  >
    <div :class="'tick' + (isListType ? ' checkbox' : '')">
      <font-awesome-icon
        focusable="false"
        :icon="disabled ? 'exclamation-triangle' : 'check'"
      />
    </div>

    <div v-if="fields" class="optionContent">
      <div v-if="isAllFieldsEmpty">-Empty-</div>
      <!--
      FUS-1813: Changes to eslint rules now disallow the use of
      v-if with v-for on the same element. The error for the next
      block has been disabled as the application and components
      are currently working.
      -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <div
        v-for="(optionField, index) in fields"
        :key="index"
        v-if="hasFieldValue(optionField)"
        :class="
          'fields' + (isConflict(optionField) ? ' conflict' : ' no_conflict')
        "
      >
        <!-- eslint-enable vue/no-use-v-if-with-v-for -->
        <span class="fieldValue">{{
          isErn ? optionField.ernValue : optionField.oesValue
        }}</span>
        <span class="fieldLabel">({{ optionField.label }})</span>
      </div>
    </div>
    <div v-else class="optionContent">{{ valueText || '-Empty-' }}</div>

    <div v-if="isErn !== undefined" class="source">
      <span>{{ isErn ? 'ERN' : 'New' }}</span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ApplicationResolveConflictOption',
  props: {
    isErn: {
      type: Boolean
    },
    value: {
      type: [String, Boolean, Object]
    },
    valueText: {
      type: String
    },
    isSelected: {
      type: Boolean
    },
    fields: {
      type: Array
    },
    isListType: {
      type: Boolean
    },
    isTextArea: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },
  computed: {
    getCssClass() {
      return (
        'option unstyled' +
        (this.isSelected ? ' isTicked' : '') +
        (this.isTextArea ? ' isTextArea' : '') +
        (this.disabled ? ' disabled' : '')
      )
    },
    isAllFieldsEmpty() {
      return this.fields.filter((f) => this.hasFieldValue(f)).length === 0
    }
  },
  methods: {
    hasFieldValue(field) {
      return (this.isErn && field.ernValue) || (!this.isErn && field.oesValue)
    },
    isConflict(field) {
      // Note: Skip for LIST-type resolution options as they will never have OES
      // and ERN values to compare (they will only have one or the other)
      return (
        !this.isListType &&
        field.ernValue !== undefined &&
        field.oesValue.toString().toLowerCase().replace(/ /g, '') !==
          field.ernValue.toString().toLowerCase().replace(/ /g, '')
      )
    },
    onClick() {
      if (!this.disabled) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.option {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em !important;
  display: flex;
  align-items: center;
  border-radius: 0.15em;
  margin: 0.5em 0 !important;
  background-color: $color-student-record-bg !important;

  &.isTicked {
    background-color: $color-selected-item !important;
  }

  .tick {
    background-color: white;
    margin-right: 0.5em;
    padding: 0.25em;
    line-height: 1;
    color: $color-secondary;
    text-align: center;
    border-radius: 1em;
    * {
      visibility: hidden;
    }
  }
  .tick.checkbox {
    border-radius: 0.2em;
  }
  &.isTicked .tick {
    color: $color-primary;
    * {
      visibility: visible;
    }
  }
  .optionContent {
    flex: 1;
  }

  &.isTextArea .optionContent {
    white-space: pre-wrap; // Ensures carriage-returns are not removed from textareas
  }

  .source span {
    font-size: 0.75rem;
    color: $color-primary;
    font-weight: bold;
    background-color: white;
    border-radius: 1em;
    padding: 0.25em 0.75em;
    margin-left: 1em;
  }
  .conflict .fieldValue {
    font-weight: bold;
  }
  &.disabled {
    .fieldValue {
      color: $grey-1;
    }
    .fieldLabel {
      color: $grey-1;
    }
  }
  .fieldLabel {
    margin-left: 0.5em;
    font-size: 0.85em;
    color: $color-text-body;
  }

  .fields {
    display: inline-block;
    &:after {
      content: ',';
      margin-right: 0.5em;
    }
    &:last-of-type:after {
      content: '';
      margin-right: 0;
    }
  }

  &.disabled {
    background-color: white;
    opacity: 1;
    color: #999999;
    border: 1px solid #eeeeee;
    .tick {
      * {
        visibility: hidden;
      }
    }
  }
}
</style>
