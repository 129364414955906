// Contains validation rules for all of our field types (e.g. date, phone, postcode etc)

import { FIELD_TYPE, DATE_FORMAT } from '@/constants'
import UTILS from '@/store/utils'
import moment from 'moment'

export default [
  {
    type: FIELD_TYPE.POSTCODE,
    isInvalid(val) {
      return val && !/^[0-9][0-9][0-9][0-9]+$/.test(val)
    },
    message: 'Invalid postcode'
  },
  {
    type: FIELD_TYPE.DATE,
    isInvalid(val, field) {
      try {
        if (field.monthOnly && val) {
          // handle MM/YYYY or YYYY-MM-01
          return (
            !/^(0[1-9]|1[0-2])[/-]([0-9]{4})$/.test(val) &&
            !/^([0-9]{4})-(0[1-9]|1[0-2])-01$/.test(val)
          )
        }
        return val && (val.length < 10 || !moment(val, DATE_FORMAT).isValid())
      } catch (e) {
        return val ? true : false
      }
    },
    message: 'Invalid date'
  },
  {
    type: FIELD_TYPE.MONTH_YEAR,
    isInvalid(val) {
      // YYYY-MM-01 or MM/YYYY accepted
      return (
        val &&
        !/^(0[1-9]|1[0-2])[/-]([0-9]{4})$/gm.test(val) &&
        !/^([0-9]{4})-(0[1-9]|1[0-2])-01$/.test(val)
      )
    },
    message: 'Must be a valid MM/YYYY date'
  },
  {
    type: FIELD_TYPE.EMAIL,
    isInvalid(val) {
      return (
        val &&
        !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          val
        )
      )
    },
    message: 'Invalid email'
  },
  {
    type: FIELD_TYPE.NAME,
    isInvalid(val) {
      return val && !/^[a-zA-Z '()/-]+$/.test(val)
    },
    message: `Can only contain letters, spaces, and the following ' ( ) / -`
  },
  {
    type: FIELD_TYPE.PHONE,
    isInvalid(val) {
      return val && !UTILS.isValidPhoneNumber(val)
    },
    message: `Can only contain 0-9, spaces and - ( ) +`
  },
  {
    type: FIELD_TYPE.BIRTHDATE,
    isInvalid(val) {
      if (val && moment(val, DATE_FORMAT) > moment()) {
        return 'Cannot be a future date'
      }
      if (val && moment() < moment(val, DATE_FORMAT).add(7, 'd')) {
        return 'Student must be at least 1 week old'
      }
    }
  }
]
