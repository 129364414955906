/*
  Contains helper functions for mapping api reference data to a format
  usable by our droplists. Also performs various data translations
  which were supposedly not possible on the api.
*/

import { upperFirst } from 'lodash'
import { DATASET } from '@/constants'
import UTILS from '@/store/utils'

export default {
  mapScholasticYears(unmappedYears) {
    // Maps api reference data to a format usable by our form droplists.
    // This means renaming nested schoolYearCode and schoolYearName keys to
    // "value" and "text".

    // Loop through each available calendar year...
    return unmappedYears.map((data) => {
      // Map scholastic year array field names to "value" and "text"...
      let scholasticYears = data.scholasticYears.map((scholasticYear) => {
        return {
          value: scholasticYear.schoolYearCode,
          text: scholasticYear.schoolYearName
        }
      })
      return {
        calenderYear: data.calenderYear,
        scholasticYears: scholasticYears
      }
    })
  },

  mapReferenceData(unmappedData) {
    // Map key names from "code/value/active" to "value/text/hidden"
    let mappedData = {}
    unmappedData.forEach((dataset) => {
      mappedData[dataset.name] = dataset.data.map((data) => {
        return {
          value: data.code,
          text: data.value,
          hidden: data.active !== 'A'
        }
      })
    })

    // CountriesPopular & Countries are combined into single dataset so that they can both appear in the same droplist
    mappedData[DATASET.COUNTRIES] = mappedData.CountriesPopular.concat(
      { divider: true },
      mappedData[DATASET.COUNTRIES]
    )
    delete mappedData.CountriesPopular

    // LanguagesPopular & Languages are combined into single dataset so that they can both appear in the same droplist
    mappedData[DATASET.LANGUAGES] = mappedData.LanguagesPopular.concat(
      { divider: true },
      mappedData[DATASET.LANGUAGES]
    )
    delete mappedData.LanguagesPopular

    // Remove "Allergy - " from allergy name and capitalise 1st letter
    mappedData[DATASET.ALLERGY].forEach((allergy) => {
      allergy.text = upperFirst(allergy.text.replace('Allergy- ', ''))
    })

    // Add divider after 1st country (Australia) and before "Other" (code YYY)
    mappedData[DATASET.COUNTRIES].splice(1, 0, { divider: true })
    let otherIndex = mappedData[DATASET.COUNTRIES].findIndex(
      (c) => c.value === 'YYY'
    )
    mappedData[DATASET.COUNTRIES].splice(otherIndex, 0, { divider: true })

    // Add divider before "Other" (code YYY) in languages
    otherIndex = mappedData[DATASET.LANGUAGES].findIndex(
      (c) => c.value === 'YYY'
    )
    mappedData[DATASET.LANGUAGES].splice(otherIndex, 0, { divider: true })

    // Add non-english languages dataset by duplicating languages and hiding "English".
    // "English" is hidden and not removed so that the value can still be used in
    // conflict resolution (for displaying the ERN value).
    let otherLanguages = UTILS.clone(mappedData[DATASET.LANGUAGES])
    otherLanguages.map((lang) => {
      if (lang.value === 'ENG') lang.hidden = true
    })
    mappedData[DATASET.NON_ENGLISH_LANGUAGES] = otherLanguages

    // For VisaSubClass, TempVisaClass & TempVisaSubClass, prefix description with code
    mappedData[DATASET.VISA_SUBCLASS].forEach((item) => {
      item.text = `${item.value} - ${item.text}`
    })
    mappedData[DATASET.TEMP_VISA_CLASS].forEach((item) => {
      item.text = `${item.value} - ${item.text}`
    })

    mappedData[DATASET.MEDICAL_CONDITION].sort((a, b) => {
      const aText = a.text.toLowerCase()
      const bText = b.text.toLowerCase()
      return aText < bText ? -1 : aText > bText ? 1 : 0
    })

    return mappedData
  }
}
