<template>
  <!-- eslint-disable max-len -->

  <div class="pt-0" ref="studentEoIDetails">
    <p class="pb-3 page-title">
      Let’s start with some basic details of the student. This information is
      used by your nominated high school(s) to identify the student when
      processing the expression of interest.
    </p>
    <v-form
      ref="studentDetailsForm"
      class="v-form"
      data-testid="student-details-form"
      lazy-validation
    >
      <!-- Application origin -->
      <v-card class="pa-6">
        <h2 class="section-heading mb-4">Application origin</h2>
        <RadioGroup
          v-model="originValue"
          :items="applicationOrigin"
          label="How was this application delivered by the parent/carer?"
          data-testid="application-originCode"
          :rules="[validators.required(originValue, 'Application origin')]"
          required
          :error-messages="blurError"
          @radioblur="handleBlur(originValue)"
          @input="blurError = []"
        />
        <div
          v-if="originValue === 'verbal'"
          data-testid="eoi-origin"
          class="warning-message"
        >
          <v-icon class="warning-icon">mdi-alert-outline</v-icon>
          <strong> Note: </strong>{{ verbalAlertText }}
        </div>
        <div aria-live="polite" class="sr-only">
          <p>{{ verbalAlertText }}</p>
        </div>
      </v-card>
      <!-- Student details -->
      <v-card class="pa-6 my-8">
        <h2 class="section-heading mb-8">Student details</h2>
        <AdsTextField
          v-model.trim="student.firstName"
          class="mb-6"
          :label="studentsFirstNameLabel"
          placeholder="Enter first name"
          hint="As it is shown on the birth certificate"
          persistent-hint
          :rules="[
            validators.required(student.firstName, studentsFirstNameLabel),
            validators.name(student.firstName)
          ]"
          maxlength="100"
          data-testid="student-firstName"
          required
          persistent-placeholder
        />

        <AdsTextField
          v-model.trim="student.middleName"
          class="mb-6"
          label="Student's middle name"
          placeholder="Enter middle name"
          hint="(optional)"
          persistent-hint
          maxlength="200"
          :rules="[
            validators.name(student.middleName, 'Student\'s middle name')
          ]"
          data-testid="student-middleName"
          persistent-placeholder
        />

        <AdsTextField
          v-model.trim="student.familyName"
          class="mb-6"
          :label="studentsFamilyNameLabel"
          hint="As it is shown on the birth certificate"
          persistent-hint
          placeholder="Enter family name"
          :rules="[
            validators.required(student.familyName, studentsFamilyNameLabel),
            validators.name(student.familyName)
          ]"
          maxlength="100"
          required
          data-testid="student-familyName"
          persistent-placeholder
        />

        <AdsTextField
          v-model.trim="student.preferredName"
          class="mb-6"
          label="Student's preferred name"
          hint="(Optional) This is the name usually used to address the child. It can be different from their birth certificate."
          persistent-hint
          placeholder="Enter preferred name"
          maxlength="100"
          :rules="[validators.name(student.preferredName)]"
          data-testid="student-preferredName"
          persistent-placeholder
        />

        <DatePicker
          v-model="student.dateOfBirth"
          :label="studentsDateOfBirthLabel"
          class="mb-2 date-picker"
          :date-picker-attrs="{
            min: minBirthDate,
            max: nineYearsAgo
          }"
          year-picker
          date-format="DD-MM-YYYY"
          output-date-format="DD-MM-YYYY"
          required
          :rules="[
            validators.required(student.dateOfBirth, studentsDateOfBirthLabel),
            validators.noFutureDate(
              student.dateOfBirth,
              studentsDateOfBirthLabel
            ),
            this.validateMinimumBirthDate(student.dateOfBirth),
            this.validateMaximumBirthDate(student.dateOfBirth)
          ]"
          data-testid="student-dateOfBirth"
          persistent-placeholder
        />

        <RadioGroup
          class="mt-0"
          v-model="student.genderCode"
          :items="genderOptions"
          :label="studentsGenderLabel"
          data-testid="student-genderCode"
          :rules="[
            validators.required(student.genderCode, studentsGenderLabel)
          ]"
          required
        />
      </v-card>
    </v-form>
  </div>
</template>

<script>
import RadioGroup from '@/components/application/y67t/paperEoI/Form/RadioGroup'
import { DatePicker, AdsTextField } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import { GENDER_OPTIONS, APPLICATION_ORIGIN } from '@/constants'
import validators from '@/helpers/validators'
import moment from 'moment'

export default {
  name: 'StudentEOIDetails',
  components: {
    DatePicker,
    RadioGroup,
    AdsTextField
  },
  data() {
    const dateNineYearsAgo = new Date()
    dateNineYearsAgo.setFullYear(dateNineYearsAgo.getFullYear() - 9)
    const nineYearsAgo = dateNineYearsAgo.toISOString().slice(0, 10)
    const dateSeventeenYearsAgo = new Date()
    dateSeventeenYearsAgo.setFullYear(dateSeventeenYearsAgo.getFullYear() - 17)
    const seventeenYearsAgo = dateSeventeenYearsAgo.toISOString().slice(0, 10)

    const { required, noPastDate, noFutureDate, name } = validators

    return {
      applicationOrigin: APPLICATION_ORIGIN,
      genderOptions: GENDER_OPTIONS,
      minBirthDate: seventeenYearsAgo,
      nineYearsAgo,
      originValue: null,
      validators: {
        required,
        noPastDate,
        noFutureDate,
        name
      },
      isProceedButtonDisabled: false,
      blurError: []
    }
  },
  computed: {
    ...mapGetters(['eoiApplication']),
    student() {
      return this.eoiApplication.student
    },
    studentsFirstNameLabel() {
      return "Student's first name"
    },
    studentsFamilyNameLabel() {
      return "Student's family name"
    },
    studentsDateOfBirthLabel() {
      return `${this.pluralisedFirstName} date of birth`
    },
    studentsGenderLabel() {
      return `${this.pluralisedFirstName} gender`
    },
    pluralisedFirstName() {
      const name = this.student.preferredName || this.student.firstName
      if (name) {
        return name.trim().slice(-1) === 's' ? `${name}'` : `${name}'s`
      }
      return "Student's"
    },
    verbalAlertText() {
      return 'Out-of-area school choices cannot be submitted verbally, \
          the family must submit a paper or digital EOI if they wish to apply to an \
          out-of-area school.'
    }
  },
  watch: {
    originValue(newValue) {
      if (newValue === 'paper') {
        this.eoiApplication.isPaperEOI = true
        this.eoiApplication.isVerbalAdvice = false
      } else {
        this.eoiApplication.isPaperEOI = false
        this.eoiApplication.isVerbalAdvice = true
      }
    }
  },
  methods: {
    validate() {
      this.$store.dispatch(
        'setValidStudent',
        this.$refs.studentDetailsForm.validate()
      )
      this.eoiApplication.isPaperEOI = this.originValue === 'paper'
      this.eoiApplication.isVerbalAdvice = this.originValue === 'verbal'
      this.$store.dispatch('setEoIApplication', this.eoiApplication)
    },
    handleBlur(input) {
      this.blurError = input ? [] : 'Application origin is required'
    },
    dateStringToDateObject(date) {
      return moment(this.formatDateString(date), 'YYYY-MM-DD')
    },
    formatDateString(date) {
      return moment(date, ['DD-MM-YYYY', 'YYYY-MM-DD']).format('YYYY-MM-DD')
    },
    validateMinimumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const minBirthDate = moment(this.minBirthDate)
      return (
        dateObject.diff(minBirthDate) >= 0 ||
        'Children enrolling in high school must be no younger than 9 and no older than 17 years of age. Please choose a date of birth within this range.'
      )
    },
    validateMaximumBirthDate(date) {
      const dateObject = this.dateStringToDateObject(date)
      const nineYearsAgo = moment(this.nineYearsAgo)
      return (
        dateObject.diff(nineYearsAgo) <= 0 ||
        'Children enrolling in high school must be at least 9 years of age.'
      )
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-form {
  font-family: $digitize-eoi-font-family;
  border-radius: 4px;
  .v-text-field.v-input {
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .date-picker {
    max-width: 500px !important;
  }
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
.section-heading {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
  font-style: normal;
  color: $ads-navy;
}
.warning-icon {
  color: $color-warning-dark !important;
}
.warning-message {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $ads-navy;
}
</style>
