<!--
  This template displays a heading for a record (from a COLLECTION field) plus:
  * Shows a "cross" for removing the record if COLLECTION has a "newRecordTemplate" parameter
  * Shows record auto-matching status & options if COLLECTION has "ernMatch" parameter and is conflictAlerting AB or AB_GROUP
-->
<template>
  <div :class="cssClasses">
    <h3>
      <span v-html="heading"></span>

      <!-- Record matching status & resolve/change options -->
      <button
        v-if="isRecordMatchingOptionsVisible"
        class="linking unstyled"
        type="button"
        @click="openResolveLinking()"
        ref="resolveLinking"
      >
        <div v-if="modelRow.isMatchedRecord" class="tickIcon">
          <font-awesome-icon focusable="false" icon="check" />
        </div>
        <span v-if="modelRow.isMatchedRecord" class="linkingText">
          Matched in ERN:
          <span>Change</span>
        </span>

        <div
          v-if="modelRow.isUnresolvedMatching && !isWithdrawn"
          class="alertIcon"
        >
          <font-awesome-icon focusable="false" icon="exclamation-triangle" />
        </div>
        <span
          v-if="modelRow.isUnresolvedMatching && !isWithdrawn"
          class="linkingText error--text"
        >
          Not found in ERN:
          <span>Resolve</span>
        </span>

        <div v-if="modelRow.isNewRecord" class="infoIcon">
          <font-awesome-icon focusable="false" icon="info" />
        </div>
        <span v-if="modelRow.isNewRecord" class="linkingText">
          New record:
          <span>Change</span>
        </span>
      </button>

      <!-- Standard unmatched ERN record message -->
      <div
        v-if="
          modelRow.isUnmatchedErnRecord &&
          !modelRow.field.showGotoErnWhenUnmatched &&
          !isArchived
        "
        class="unmatchedErnRecord"
      >
        <div class="infoIcon">
          <font-awesome-icon focusable="false" icon="info" />
        </div>
        <span>Unmatched ERN record</span>
      </div>

      <!-- Remove record button -->
      <button
        v-if="isRecordRemoveable"
        type="button"
        class="remove unstyled"
        :title="'Remove ' + heading"
        @click="$emit('remove')"
      >
        <font-awesome-icon focusable="false" icon="times" />
      </button>
    </h3>

    <!-- Unmatched ERN record message with family tree link -->
    <v-alert
      v-if="
        modelRow.isUnmatchedErnRecord &&
        modelRow.field.showGotoErnWhenUnmatched &&
        !isWithdrawn &&
        !isArchived
      "
      outlined
      class="goToErnMessage"
      icon="error"
    >
      <div class="messageContent">
        <div>
          Unmatched ERN record.<br />To remove this parent/carer from family
          tree please update in ERN
        </div>
        <div>
          <v-btn
            depressed
            @click="
              $store.dispatch('openErnWithQueryString', {
                functionSelected: 'familyManagerPopup'
              })
            "
            >Go to ERN</v-btn
          >
        </div>
      </div>
    </v-alert>
  </div>
</template>

<script>
import { STREAM, CONFLICT_ALERTING } from '@/constants'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'FieldCollectionHeading',
  props: {
    modelRow: {
      type: Object,
      required: false
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    ...mapGetters(['currentStream']),
    cssClasses() {
      var classes = ['FieldCollectionHeading']
      if (this.modelRow.field.newRecordTemplate && !this.isPrintPreview) {
        classes.push('canAddRemove')
      }
      return classes.join(' ')
    },
    heading() {
      return this.modelRow.label
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    },
    isRecordMatchingOptionsVisible() {
      return (
        !this.isEditing &&
        !this.isPrintPreview &&
        !this.isSentToErn &&
        !this.modelRow.isUnmatchedErnRecord &&
        this.modelRow.field.ernMatch &&
        this.modelRow.field.conflictAlerting !== CONFLICT_ALERTING.LIST &&
        !this.isWithdrawn &&
        !this.isArchived &&
        !this.isInvalid &&
        !this.isInternalTransfer
      )
    },
    isRecordRemoveable() {
      return (
        this.modelRow.field.newRecordTemplate &&
        this.isEditing &&
        !this.modelRow.isReadOnly &&
        !this.modelRow.isResolved &&
        !this.isWithdrawn &&
        !this.isInvalid &&
        !this.isArchived &&
        !this.isInternalTransfer
      )
    }
  },
  methods: {
    openResolveLinking() {
      if (!this.modelRow.isUnmatchedErnRecord) {
        // Note: A clone of modelRow is necessary to prevent it
        // becoming reactive (the model does not live in state
        // and does not need to be tracked by Vue). Deep cloning
        // is required because modelRow may contain functions.
        let modelRow = _.cloneDeep(this.modelRow)
        this.$store.dispatch('set', ['resolveLinking', modelRow])
      }
    }
  },
  watch: {
    focusFieldId: function () {
      if (this.focusFieldId === this.modelRow.id) {
        this.$store.dispatch('set', ['focusFieldId', null])
        this.$refs.resolveLinking.focus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.goToErnMessage {
  // Override Vuetify Alert styles as Rick's design uses an unsupported
  // combination of text, icon and border colours.
  margin-top: 1rem;
  color: black !important;
  background-color: #fffaed !important;
  border-color: #ffd479 !important;
  ::v-deep .v-icon {
    color: $color-primary !important;
  }
  .messageContent {
    display: flex;
    div:first-child {
      flex: 1; // Allows message to fill available width
      padding-right: 1rem;
    }

    .v-btn {
      height: 35px;
    }
  }
}

.FieldCollectionHeading {
  padding: $content-padding;

  h3 {
    font-size: 1rem;
    margin: 0;
    display: flex;
    & > span {
      flex: 1;
    }
    .linking {
      font-size: 0.9rem;
      color: black;
      border-radius: 1em;
      vertical-align: middle;
      margin-left: 0.5em;
      font-weight: normal;
    }
    .linkingText {
      display: inline-block;
      vertical-align: middle;

      span {
        text-decoration: underline;
        color: $color-secondary;
      }
    }
    .remove {
      color: #8a8a8a;
      font-size: 1rem;
      margin-left: 1rem;
      width: 1.1em;
      text-align: center;
      &:hover {
        color: white;
        background-color: $color-red;
        border-radius: 3px;
      }
    }
  }
  &.canAddRemove h3 {
    padding: 0 0 0.15em;
  }

  .tickIcon,
  .infoIcon {
    display: inline-block;
    color: white;
    background-color: green;
    font-size: 0.7rem;
    margin-right: 0.25rem;
    width: 1.5em;
    line-height: 0;
    text-align: center;
    border-radius: 10em;
    padding: 0.25em;
    vertical-align: middle;
  }
  .tickIcon {
    background-color: green;
  }
  .alertIcon {
    color: $color-red;
    display: inline-block;
    margin-right: 0.25rem;
    font-size: 0.9rem;
  }
  .infoIcon {
    background-color: $color-secondary;
  }
  .unmatchedErnRecord {
    display: inline-block;
    font-size: 0.9rem;
    margin-left: 1em;
    font-weight: normal;
    vertical-align: middle;
    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.collectionItemFields.ooa {
  .FieldCollectionHeading {
    h3 {
      font-weight: normal;
    }
  }
}
</style>
