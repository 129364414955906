var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'FieldCollection' +
    (_vm.modelRow.isConflict && !_vm.isPrintPreview ? ' isCollectionConflict' : ''),attrs:{"data-id":_vm.modelRow.id}},[(_vm.isPlaceholderVisible)?_c('div',{staticClass:"placeholderTitle"},[_vm._v(" "+_vm._s(_vm.modelRow.field.placeholderTitle)+" ")]):_vm._e(),(_vm.getRecords.length > 0)?_c('div',_vm._l((_vm.getRecords),function(recordModelRow){return _c('div',{key:recordModelRow.id + _vm.recordRevisionCount,class:(_vm.withWhiteBox(recordModelRow)
          ? `collectionItemFields ${recordModelRow.section.id}`
          : 'ma-4') + _vm.getBackgroundColorClass(recordModelRow),attrs:{"data-id":recordModelRow.id}},[(recordModelRow.field.heading)?_c('RecordHeading',{attrs:{"model-row":recordModelRow},on:{"remove":function($event){return _vm.remove(recordModelRow)}}}):_vm._e(),_vm._l((_vm.getChildren(recordModelRow)),function(recordFieldModelRow){return _c('div',{key:recordFieldModelRow.id,class:(_vm.isFullWidthRow(recordFieldModelRow) ? '' : 'columnLayout') +
          _vm.getFieldWidthClass(recordFieldModelRow)},[(recordFieldModelRow.type == 'LINEBREAK')?_c('br'):(recordFieldModelRow.type == 'COLLECTION')?_c('FieldCollection',{attrs:{"model-row":recordFieldModelRow}}):(recordFieldModelRow.type == 'GROUP')?_c('FieldGroup',{attrs:{"model-row":recordFieldModelRow}}):(recordFieldModelRow.type == 'HEADING')?_c('FieldHeading',{attrs:{"model-row":recordFieldModelRow}}):(recordFieldModelRow.type == 'CUSTOM')?_c('FieldCustom',{attrs:{"model-row":recordFieldModelRow}}):(recordFieldModelRow.type === 'FILE_EDITOR')?_c('AppFileEditor',{attrs:{"label":recordFieldModelRow.label,"value":recordFieldModelRow.oesValue,"handle-view":recordFieldModelRow.field.handleView,"can-view":recordFieldModelRow.field.canView,"status":recordFieldModelRow.field.status}}):(
            _vm.isEditing &&
            !recordFieldModelRow.isHidden &&
            !recordFieldModelRow.isReadOnly &&
            !recordFieldModelRow.isResolved
          )?_c('Field',{attrs:{"model-row":recordFieldModelRow}}):_c('FieldReadOnly',{attrs:{"model-row":recordFieldModelRow}})],1)}),_c('FieldAlert',{staticClass:"alert",attrs:{"model-row":recordModelRow}})],2)}),0):(_vm.showNone)?_c('div',{staticClass:"collectionItemFields pa-3"},[_vm._v("(None)")]):_vm._e(),(
      !_vm.modelRow.isReadOnly &&
      !_vm.isPrintPreview &&
      !_vm.isSentToErn &&
      (_vm.modelRow.isConflict ||
        _vm.modelRow.warningMessage ||
        _vm.modelRow.field.newRecordTemplate) &&
      (_vm.modelRow.field.maxItems
        ? _vm.getRecords.length < _vm.modelRow.field.maxItems
        : true)
    )?_c('div',{class:'collectionFooter'},[_c('FieldAlert',{attrs:{"model-row":_vm.modelRow}}),(_vm.modelRow.field.newRecordTemplate && !_vm.modelRow.isResolved)?_c('button',{ref:"addRecordButton",staticClass:"add unstyled",attrs:{"type":"button","data-title":_vm.modelRow.addLabel},on:{"click":function($event){return _vm.add()}}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"focusable":"false","icon":"plus"}}),_c('span',[_vm._v(_vm._s(_vm.modelRow.addLabel))])],1):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }