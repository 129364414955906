<template>
  <div>
    <div>
      <v-icon class="humanIcon">mdi-human-male-board</v-icon>
      <span class="AmaText">Alternative Meaningful Activities (AMA)</span>
    </div>
    <div class="mt-10">
      <v-row>
        <v-col cols="9">
          <AdsTextField
            v-model="amaOptions.name"
            label="Title"
            disabled
            data-testid="app-settings-ama-title"
            persistent-placeholder
          />
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <AdsTextField
            :value="subtitle"
            label="Subtitle"
            disabled
            data-testid="app-settings-ama-subtitle"
            persistent-placeholder
          />
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <AdsTextArea
            label="Description (optional)"
            placeholder=" "
            v-model="amaOptions.description"
            :outlined="true"
            :rows="3"
            :counter="300"
            maxlength="300"
            :disabled="disabled"
            @keyup="setDescription($event.target.value)"
            data-testid="app-settings-ama-description"
            persistent-placeholder
          />
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { AdsTextField, AdsTextArea } from '@nswdoe/doe-ui-core'

export default {
  name: 'AppSettingsAmaOption',
  components: { AdsTextField, AdsTextArea },
  data() {
    return {
      amaOptions: {},
      subtitle: ''
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    defaultAmaOptions: {
      type: Array,
      default: () => []
    },
    savedAmaOptions: {
      type: Array,
      default: () => []
    }
  },
  computed: {},
  methods: {
    setDescription(description) {
      this.amaOptions.description = description
      this.$emit('updatedAmaOptions', this.amaOptions)
    }
  },
  mounted() {
    if (this.savedAmaOptions?.length > 0) {
      this.amaOptions = {
        id: this.savedAmaOptions[0].id,
        guid: this.savedAmaOptions[0].guid,
        name: this.savedAmaOptions[0].name,
        providers: this.savedAmaOptions[0].providers,
        description: this.savedAmaOptions[0].description
      }
    } else {
      this.amaOptions = {
        id: this.defaultAmaOptions[0].id,
        guid: this.defaultAmaOptions[0].guid,
        name: this.defaultAmaOptions[0].name,
        providers: this.defaultAmaOptions[0].providers,
        description: this.defaultAmaOptions[0].defaultDescription
      }
    }

    this.subtitle = this.defaultAmaOptions[0].providers[0].name
  }
}
</script>

<style lang="scss" scoped>
.humanIcon {
  border-radius: 50%;
  background: $ads-light-blue;
  color: $ads-navy;
  min-width: 46px;
  height: 46px;
}

.AmaText {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

::v-deep .v-text-field.v-textarea {
  margin-left: 0;
}

::v-deep .col-9 {
  padding: 0 12px;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
