import moment from 'moment'

const converToISODate = (date) =>
  moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')

const convertToYYYYMMDDHHMM = (date = new Date()) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
    '0' + date.getDate()
  ).slice(-2)} ${('0' + date.getHours()).slice(-2)}:${(
    '0' + date.getMinutes()
  ).slice(-2)}:${('0' + date.getSeconds()).slice(-2)}`
}

const convertDateToDDMMMMYYYYatHHMM = (date = new Date()) => {
  return `${date.getDate()} ${date.toLocaleString('default', {
    month: 'long'
  })} ${date.getFullYear()} at ${date
    .toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    })
    .toLowerCase()}`
}

export { converToISODate, convertToYYYYMMDDHHMM, convertDateToDDMMMMYYYYatHHMM }
