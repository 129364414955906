<!--
  Displays address text for an address field, along with an icon indicating validation status
-->
<template>
  <div class="addressText">
    <span>{{ modelRow.oesDisplayValue }}</span>
    <font-awesome-icon
      v-if="validationFlag && address.countryCode === 'AUS'"
      :title="getTooltip"
      :icon="getIcon"
      :class="getClass"
      focusable="false"
    />
  </div>
</template>

<script>
import { ADDRESS_VALIDATION } from '@/constants'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
export default {
  name: 'DisplayAddress',
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    address() {
      return this.modelRow.oesValue
    },
    validationFlag() {
      return this.address.validationFlag
    },
    getClass() {
      var prefix = 'circleIcon '
      if (this.validationFlag === ADDRESS_VALIDATION.VALIDATED) {
        return prefix + 'validated'
      } else if (this.validationFlag === ADDRESS_VALIDATION.ACCEPTED) {
        return prefix + 'accepted'
      }
      return prefix + 'unknown'
    },
    getTooltip() {
      if (this.validationFlag === ADDRESS_VALIDATION.VALIDATED) {
        return 'Address validated'
      } else if (this.validationFlag === ADDRESS_VALIDATION.ACCEPTED) {
        return 'Address accepted'
      }
      return 'Address unknown'
    },
    getIcon() {
      // NOTE: Currently ERN returns a differernt code for unknown addresses ('N' instead
      // of 'X') which is why we don't check for ADDRESS_VALIDATION.UNKNOWN below.
      return this.validationFlag === ADDRESS_VALIDATION.VALIDATED ||
        this.validationFlag === ADDRESS_VALIDATION.ACCEPTED
        ? 'check'
        : 'exclamation'
    }
  }
}
</script>

<style scoped lang="scss">
.circleIcon {
  font-size: 0.85rem;
  vertical-align: -0.1em;
  margin-left: 0.5em;

  &.validated {
    background-color: green;
  }
  &.accepted {
    background-color: $color-orange;
  }
  &.unknown {
    background-color: $color-red;
  }
}
</style>
