export default {
  // COMMON

  referenceData: null, // Contains reference data (for drop-lists) loaded from the API. Each category of reference data must be nested under the field name it relates to. So reference data for the scholasticYear field would live in referenceData.scholasticYear.
  oesProperties: {}, // Contains static info for oes applications such as help link
  showSpinner: false, // If true, shows a spinner covering the UI
  messageBox: null, // If a message box is to be displayed, its configuration must be inserted into this object
  snackbar: null, // If a snackbar is to be displayed, its configuration must be inserted into this object
  userName: '', // User name supplied from auth tokens
  userGroup: null, // User group - ERNADMIN or OESSupport
  userID: null, // got the info from the id token and used to save previously selected school in the local storage
  selectedSchool: null, // the school that an user has selected to view the applications for
  schools: [], //the schools that are accessible to logged in users.
  auSchools: null, //all the Australian schools.
  inTrainingMode: false, //Whether or not we are in training mode
  showTimeoutBanner: false, //Whether we show the timeout banner due to a user being inactive

  // APPLICATION VIEW

  filteredApplicationIds: [], // Array of filtered application IDs in visual order as taken from the data table
  filteredTrackingSheetIds: [], // Array of filtered application IDs in visual order as taken from the data table for tracking sheet in Primary school view as we have multiple tables
  renderModel: 0, // Increment to re-render the UI after the model has been recalculated. This is because it would not be efficient to make the model reactive
  isEditing: false, // True if currently editing an application
  isPrintPreview: false, // True if currently about to print an application
  isSendingToErn: false, // If true, shows "Sending to ERN" progress dialog
  application: null, // Complete dataset for the currently displayed application
  cleanApplication: null, // When editing, an unedited copy of the application is kept for reverting to if editing is cancelled
  ernRecord: null, // Complete ERN dataset for current student, if linked.
  sectionExpandedId: null, // ID of section currently expanded
  showSrnSearch: false, // If true, shows SRN-search dialog for selecting a matching ERN record (if one exists)
  showSrnMatchSearch: false, // If true, shows the SRN match search dialog used to search for a student in ERN for y67t
  focusFieldId: null, // If present, the field with this id will receive focus when mounted
  resolveConflict: null, // To display the conflict resolution dialog, set resolveConflict to a DEEP CLONE of the record model row (protects model row from becoming reactive).
  resolveLinking: null, // To display the record linking dialog, set resolveLinking to a DEEP CLONE of the record model row (protects model row from becoming reactive).
  editAddress: null, // When editing an address, set editAddress to a DEEP CLONE of the record model row (protects model row from becoming reactive). This will display the address edit dialog for that field.
  postForm: null, // If a json configuration is provided, posts a payload and displays response in new tab (see AppPostForm.vue for configuration options).
  openWithdrawModal: false, // To display the application withdraw dialog
  openInternalTransfer: false, // To display the mark as internal transfer dialog
  actionFromStream: null, // To identify the stream based on the action details page, Need to set this before calling getter
  openUnlinkSrnModal: false, // To display the Unlink SRN dialog
  applicationWithdrawn: null, // When set to 'pending', show the application withdrawn warning message; If set to 'withdrawn', show the application withdrawn confirmaton message;

  /**
   * Enrolment type vs Application type disambiguation
   * Application Type represents different types of application data ( Core (historically Local Area), OOA offer, Y67T, SHS offer)
   * Application type determines which summary to display and which API to call
   *
   * Conversely enrolmentType is the origination point of the application (i.e. CORE_SHS) and only exists on Core applications.
   * i.e. a core application that came from an SHS offer is applicationType: CORE, enrolmentType: CORE_SHS
   * and a core application that came from OOA is applicationType: CORE, enrolmentType: CORE_OOA
   */
  applicationType: null,

  currentStream: null, //To display component based on the selected stream for central schools
  redirectFromY67TToLocalPending: false, //To select deafult filters when redirect happen from Y67T to Local
  redirectFromY67TToOoaPending: false, //To select deafult filters when redirect happen from Y67T to Ooa
  applicationCounts: null,
  updateCount: null,
  countApiError: false,
  showSettings: false,
  settingsTab: 0
}
