var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-8 pt-4 pb-2",class:{ isEditing: _vm.isEditing }},[_c('v-card',{staticClass:"mb-6 px-6"},[(_vm.isEditing && !_vm.parentCarer.parentCarerCompletingApp)?_c('delete-button',{attrs:{"aria-label":`delete ${_vm.parentCarerName(_vm.parentCarer)}`},on:{"click":function($event){return _vm.$emit('delete', _vm.parentCarer)}}}):_vm._e(),_c('v-card-title',{staticClass:"px-0"},[_c('div',[_vm._v("Parent/carer: "+_vm._s(_vm.parentCarerName(_vm.parentCarer)))])]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('text-field',{attrs:{"label":"Parent/carer given name","rules":[_vm.validators.required, _vm.validators.name],"maxlength":"100","readonly":_vm.readonly},model:{value:(_vm.parentCarer.parentCarerGivenName),callback:function ($$v) {_vm.$set(_vm.parentCarer, "parentCarerGivenName", $$v)},expression:"parentCarer.parentCarerGivenName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('text-field',{attrs:{"label":"Parent/carer family name","rules":[_vm.validators.required, _vm.validators.name],"maxlength":"100","readonly":_vm.readonly},model:{value:(_vm.parentCarer.parentCarerFamilyName),callback:function ($$v) {_vm.$set(_vm.parentCarer, "parentCarerFamilyName", $$v)},expression:"parentCarer.parentCarerFamilyName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('text-field',{attrs:{"label":"Parent/carer email","rules":[
            _vm.parentCarer.parentCarerCompletingApp ? _vm.validators.required : true,
            _vm.validators.email
          ],"readonly":_vm.readonly ||
            !!(
              _vm.parentCarer.parentCarerCompletingApp &&
              _vm.parentCarer.parentCarerEmail
            ),"maxlength":"132","data-testid":"parentCarerEmail"},model:{value:(_vm.parentEmail),callback:function ($$v) {_vm.parentEmail=$$v},expression:"parentEmail"}})],1)],1),_vm._l((_vm.contactsWithKey),function(contactWithKey,cIndex){return _c('v-row',{key:`${cIndex}`},[_c('v-col',{staticClass:"py-0"},[_c('Contact',{ref:`contact-${cIndex}`,refInFor:true,attrs:{"contact":contactWithKey.contact,"rules":[_vm.validateDuplicateContact(_vm.parentCarer)],"readonly":_vm.readonly,"data-testid":`parentCarerContact${cIndex}`},on:{"delete":(c) => _vm.removeContact(_vm.parentCarer, c),"update:contact":(contact) => _vm.validateOtherContacts(contact, _vm.parentCarer)}})],1)],1)}),(_vm.isEditing && _vm.parentCarer.contactDetails.length < 3)?_c('v-row',[_c('AdsButton',{staticClass:"mb-3",attrs:{"icon":"mdi-plus-circle-outline","tertiary":"","button-text":"Add another contact number"},on:{"click":function($event){return _vm.addContact(_vm.parentCarer)}}})],1):_vm._e(),(_vm.errorMessage)?_c('v-row',[_c('v-col',{staticClass:"ml-2"},[_c('alert-message',{attrs:{"data-testid":"parentCarerError"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }