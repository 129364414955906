// See README.md for more info about field configuration

import moment from 'moment'
import UTILS from '@/store/utils'
import {
  FIELD_TYPE,
  CONFLICT_ALERTING,
  DATASET,
  DATE_FORMAT,
  YES_NO
} from '@/constants'
import store from '@/store/store'
import validators from './validators'

const isNotEmptyOrUnknownPrevSchool = (application) =>
  application.previousSchool.prevSchoolType !== '' &&
  application.previousSchool.prevSchoolType !== 'X'

const isAustralianSchool = (application) =>
  application.previousSchool.prevSchoolType === 'A'

const isNotAustralianSchool = (application) => {
  let type = application.previousSchool.prevSchoolType
  return type !== 'A' && type !== '' && type !== 'X'
}

function residencyStatus(application) {
  return application?.studentResidency?.residencyStatus
}

function isKintergartenApplication(application) {
  return application.scholasticYear === 'K'
}

function atecodeValidation(ateCode) {
  if (!ateCode) {
    return true
  }
  return (
    ateCode.length !== 8 ||
    ateCode.charAt(0) !== 'T' ||
    isNaN(ateCode.split('T')[1])
  )
}

function ateStartDateValidation(ateStartDate) {
  if (!ateStartDate) {
    return true
  }
  return !moment(ateStartDate).isValid()
}

function ateEndDateValidation(ateStartDate, ateEndDate) {
  if (!ateEndDate) {
    return true
  }
  return (
    moment(ateEndDate, DATE_FORMAT) < moment().startOf('day') ||
    moment(ateEndDate, DATE_FORMAT) < moment(ateStartDate, DATE_FORMAT)
  )
}

function ateAlertDisplay(application) {
  const { residencyStatus, ateCode, ateStartDate, ateEndDate } =
    application.studentResidency
  return (
    residencyStatus === 'TMP' &&
    (atecodeValidation(ateCode) ||
      ateStartDateValidation(ateStartDate) ||
      ateEndDateValidation(ateStartDate, ateEndDate))
  )
}

function getTempVisaSubClasses(
  application,
  referenceData = {
    tempVisaSubclass: store.getters.referenceData(DATASET.TEMP_VISA_SUBCLASS),
    tempVisaClassAndSubclassMap: store.getters.referenceData(
      DATASET.TEMP_VISA_CLASS_AND_SUBCLASS_MAP
    ),
    visaSubClass: store.getters.referenceData(DATASET.VISA_SUBCLASS)
  }
) {
  if (residencyStatus(application) === 'NZC') {
    return referenceData.tempVisaSubclass
  }
  if (residencyStatus(application) === 'TMP') {
    const tempVisaSubClassMap =
      referenceData.tempVisaClassAndSubclassMap.filter(
        (vsc) => vsc.value === application.studentResidency.visaClass
      )
    const allTempVisaSubClasses = referenceData.tempVisaSubclass
    const tempVisaSubClassMapSort = tempVisaSubClassMap.sort(function (a, b) {
      return a.text - b.text
    })
    return tempVisaSubClassMapSort.map((mapItem) => ({
      value: mapItem.text,
      text: allTempVisaSubClasses
        .filter((tvsc) => tvsc.value === mapItem.text)
        .map((tvsc) => tvsc.text)[0],
      hidden: mapItem.hidden
    }))
  }
  return referenceData.visaSubClass
}

export default [
  {
    label: 'Details',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'student.familyName',
    label: 'Family name',
    required: true,
    type: FIELD_TYPE.NAME,
    maxlength: 100
  },
  {
    apiKey: 'student.firstName',
    label: 'First given name',
    required: true,
    type: FIELD_TYPE.NAME,
    maxlength: 100
  },
  {
    apiKey: 'student.otherName',
    label: 'Second given name',
    required: false,
    type: FIELD_TYPE.NAME,
    maxlength: 100
  },
  {
    apiKey: 'student.prefFirstName',
    label: 'Preferred first name',
    required: false,
    type: FIELD_TYPE.NAME,
    maxlength: 100,
    displayfieldAlerting: true,
    noConflictAlerting: true // for details see FUS-416, we don't want to resolve conflict for preferred first name
  },
  {
    apiKey: 'student.genderCode',
    label: 'Gender',
    required: true,
    type: FIELD_TYPE.RADIO,
    options: [
      { text: 'Male', value: 'M' },
      { text: 'Female', value: 'F' }
    ]
  },
  {
    apiKey: 'student.dateOfBirth',
    label: 'Date of birth',
    required: true,
    type: FIELD_TYPE.DATE,
    validation: validators.birthdate,
    futureYears: 0,
    pastYears: 20
  },
  {
    apiKey: 'intendedStartDate',
    label: 'Intended start date',
    required: true,
    type: FIELD_TYPE.DATE,
    futureYears: 4,
    pastYears: 0
  },
  {
    apiKey: 'scholasticYear',
    label: 'School year',
    required: true,
    type: FIELD_TYPE.DROPLIST,
    dataset: 'scholasticYear'
  },
  {
    apiKey: 'firstEnrolmentDate',
    label: 'First Australian enrolment date',
    required: false,
    type: FIELD_TYPE.DATE,
    conflictSettings: {
      allowErnCoalesce: true
    },
    validation(value) {
      if (value && moment(value, DATE_FORMAT) > moment()) {
        return 'Cannot be a future date'
      }
    }
  },
  {
    apiKey: 'hasAnySiblings',
    label: 'Any siblings enrolled in a NSW government school?',
    readOnly: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    visible() {
      return true
    }
  },

  // SIBLINGS -----------------------------------------------------
  {
    apiKey: 'siblings',
    type: FIELD_TYPE.COLLECTION,
    readOnly: true,
    oesRecordNumberField: 'siblingRecordNo',
    heading(sibling) {
      return `Sibling: ${sibling.siblingsFirstName} ${sibling.siblingsFamilyName}`
    },
    fields(apiKeyPrefix) {
      return [
        {
          apiKey: apiKeyPrefix + 'siblingsSchoolName',
          label: 'Name of most recent school'
        },
        {
          apiKey: apiKeyPrefix + 'siblingsGenderCode',
          label: 'Gender',
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Male', value: 'M' },
            { text: 'Female', value: 'F' }
          ]
        },
        {
          apiKey: apiKeyPrefix + 'siblingsDOB',
          label: 'Date of birth',
          type: FIELD_TYPE.DATE
        },
        {
          apiKey: apiKeyPrefix + 'siblingsFamilyName',
          label: 'Family name',
          type: FIELD_TYPE.NAME
        },
        {
          apiKey: apiKeyPrefix + 'siblingsFirstName',
          label: 'Given name',
          type: FIELD_TYPE.NAME
        },
        {
          apiKey: apiKeyPrefix + 'siblingsOtherName',
          label: 'Second given name',
          type: FIELD_TYPE.NAME
        }
      ]
    },
    visible(application) {
      return application.siblings.length > 0
    }
  },
  {
    label: 'Aboriginality',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'student.isAboriTorStraitIslander',
    label: 'Is the student of Aboriginal or Torres Strait Islander origin?',
    required: true,
    type: FIELD_TYPE.DROPLIST,
    dataset: DATASET.ABORIGINALITY
  },

  {
    label: 'Languages other than English spoken at home',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'student.homeLangOtherThanEnglish',
    label: 'Does the student speak a non-English language at home?',
    required: false,
    type: FIELD_TYPE.RADIO,
    options: [
      { text: 'No, English only', value: false },
      { text: 'Yes', value: true }
    ]
  },
  {
    apiKey: 'student.mainLanguageOtherThanEnglish',
    label: 'Main language other than English',
    default: '',
    required: true,
    visible(application) {
      return application.student.homeLangOtherThanEnglish
    },
    type: FIELD_TYPE.DROPLIST,
    dataset: DATASET.NON_ENGLISH_LANGUAGES
  },
  {
    apiKey: 'student.otherLanguage',
    label: 'Other language spoken at home',
    default: 'ENG',
    required: true,
    visible(application) {
      return application.student.homeLangOtherThanEnglish
    },
    type: FIELD_TYPE.DROPLIST,
    dataset: DATASET.LANGUAGES
  },

  {
    label: "Student's mobile phone",
    type: FIELD_TYPE.HEADING,
    visible(application) {
      // Only show mobile phone field for years 7-12
      return Number.parseInt(application.scholasticYear) > 6
    }
  },
  {
    apiKey: 'student.mobileNumber',
    label: 'Student mobile number',
    required: false,
    type: FIELD_TYPE.PHONE,
    visible(application) {
      // Only show mobile phone field for years 7-12
      return Number.parseInt(application.scholasticYear) > 6
    },
    alert(application) {
      return !application.student.mobileNumber
        ? 'Mobile number should be present'
        : ''
    }
  },

  {
    label: 'Country of birth',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'student.countryBorn',
    label: "Student's country of birth",
    required: true,
    type: FIELD_TYPE.DROPLIST,
    dataset: DATASET.COUNTRIES
  },

  {
    apiKey: 'studentResidency',
    type: FIELD_TYPE.GROUP,
    heading() {
      return `Student's residency status`
    },
    fields(apiKeyPrefix) {
      return [
        {
          apiKey: apiKeyPrefix + 'residencyStatus',
          label: 'Status',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.RESIDENCY_STATUS,
          onChange(value, store) {
            if (value === 'NZC') {
              store.dispatch('set', [
                `application.studentResidency.countryCodeTravelDocumentIssued`,
                'NZL'
              ])
              store.dispatch('set', [
                `application.studentResidency.visaSubClass`,
                '444'
              ])
            }
          }
        },

        {
          apiKey: apiKeyPrefix + 'dateArriveAustralia',
          label: 'Arrival in Australia (if born overseas)',
          required(application) {
            return (
              residencyStatus(application) === 'AUCC1' ||
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'TMP'
            )
          },
          type: FIELD_TYPE.DATE,
          validation(value) {
            if (value && moment(value, DATE_FORMAT) > moment()) {
              return 'Cannot be a future date'
            }
          },
          visible(application) {
            return (
              residencyStatus(application) === 'AUCC1' ||
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'PER' ||
              residencyStatus(application) === 'TMP'
            )
          },
          futureYears: 0,
          pastYears: 20
        },

        {
          apiKey: apiKeyPrefix + 'dateReturnAustralia',
          label: 'Returned to Australia (if living overseas)',
          required(application) {
            return residencyStatus(application) === 'AUCC2'
          },
          type: FIELD_TYPE.DATE,
          validation(value) {
            if (value && moment(value, DATE_FORMAT) > moment()) {
              return 'Cannot be a future date'
            }
          },
          visible(application) {
            return residencyStatus(application) === 'AUCC2'
          },
          futureYears: 0,
          pastYears: 20
        },

        {
          apiKey: apiKeyPrefix + 'travelDocumentNo',
          label: 'Passport or travel documentation no.',
          required(application) {
            return residencyStatus(application) === 'TMP'
          },
          visible(application) {
            return (
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'PER' ||
              residencyStatus(application) === 'TMP'
            )
          }
        },

        {
          apiKey: apiKeyPrefix + 'countryCodeTravelDocumentIssued',
          label: 'Country of issue of passport',
          readOnly(application) {
            return residencyStatus(application) === 'NZC'
          },
          required(application) {
            return residencyStatus(application) === 'TMP'
          },
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.COUNTRIES,
          visible(application) {
            return (
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'PER' ||
              residencyStatus(application) === 'TMP'
            )
          }
        },

        {
          apiKey: apiKeyPrefix + 'visaClass',
          label: 'Current visa class',
          required(application) {
            return residencyStatus(application) === 'TMP'
          },
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.TEMP_VISA_CLASS,
          visible(application) {
            return residencyStatus(application) === 'TMP'
          }
        },

        {
          apiKey: apiKeyPrefix + 'visaSubClass',
          label: 'Current visa subclass',
          readOnly(application) {
            return residencyStatus(application) === 'NZC'
          },
          required(application) {
            return (
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'PER' ||
              residencyStatus(application) === 'TMP'
            )
          },
          type: FIELD_TYPE.DROPLIST,
          options: getTempVisaSubClasses(store.state.application),
          visible(application) {
            return (
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'PER' ||
              residencyStatus(application) === 'TMP'
            )
          }
        },

        {
          apiKey: apiKeyPrefix + 'visaExpiryDate',
          label: 'Visa expiry date',
          required(application) {
            return residencyStatus(application) === 'TMP'
          },
          type: FIELD_TYPE.DATE,
          validation(value, application) {
            if (value && moment(value, DATE_FORMAT) < moment().startOf('day')) {
              return 'Cannot be a past date'
            } else if (
              value &&
              residencyStatus(application) === 'TMP' &&
              moment(value, DATE_FORMAT) <
                moment(application.studentResidency?.ateStartDate, DATE_FORMAT)
            ) {
              return 'Cannot be before the ATE start date'
            }
          },
          visible(application) {
            return (
              residencyStatus(application) === 'NZC' ||
              residencyStatus(application) === 'TMP'
            )
          }
        }
      ]
    }
  },

  {
    label: 'Authority to Enrol',
    type: FIELD_TYPE.HEADING,
    visible(application) {
      return residencyStatus(application) === 'TMP'
    }
  },

  {
    message: {
      type: 'error',
      body: 'Authority to Enrol (ATE) details missing'
    },
    type: FIELD_TYPE.MESSAGE,
    visible(application) {
      return ateAlertDisplay(application)
    }
  },

  {
    apiKey: 'studentResidency.ateCode',
    label: 'Authority to Enrol (ATE) Code',
    required(application) {
      return residencyStatus(application) === 'TMP'
    },
    visible(application) {
      return residencyStatus(application) === 'TMP'
    },
    noConflictAlerting: true,
    maxlength: 8,
    validation(value) {
      if (
        value &&
        (value.length !== 8 ||
          value.charAt(0) !== 'T' ||
          isNaN(value.split('T')[1]))
      ) {
        return `Invalid input. Code must be eight characters, starting with 'T' followed by seven digits.`
      }
    }
  },

  {
    apiKey: 'studentResidency.ateStartDate',
    label: 'ATE start date',
    required(application) {
      return residencyStatus(application) === 'TMP'
    },
    type: FIELD_TYPE.DATE,
    noConflictAlerting: true,
    visible(application) {
      return residencyStatus(application) === 'TMP'
    }
  },

  {
    apiKey: 'studentResidency.ateEndDate',
    label: 'ATE expiry date',
    required(application) {
      return residencyStatus(application) === 'TMP'
    },
    type: FIELD_TYPE.DATE,
    noConflictAlerting: true,
    validation(value, application) {
      if (value && moment(value, DATE_FORMAT) < moment().startOf('day')) {
        return 'Cannot be a past date'
      } else if (
        value &&
        moment(value, DATE_FORMAT) <
          moment(application.studentResidency?.ateStartDate, DATE_FORMAT)
      ) {
        return 'Cannot be before the ATE start date'
      }
    },
    visible(application) {
      return residencyStatus(application) === 'TMP'
    }
  },

  {
    apiKey: 'previousSchool',
    type: FIELD_TYPE.GROUP,
    heading() {
      return `Previous school`
    },
    fields(apiKeyPrefix) {
      return [
        {
          apiKey: `${apiKeyPrefix}prevSchoolType`,
          label: 'Type of school',
          required: false,
          type: FIELD_TYPE.DROPLIST,
          options: [
            { text: 'Australian school', value: 'A' },
            { text: 'Overseas school', value: 'O' },
            { text: 'Other', value: 'Y' },
            { text: 'Unknown/Not Provided', value: 'X' }
          ]
        },
        {
          apiKey: `${apiKeyPrefix}prevSchoolName`,
          label: 'Name of school last attended',
          required: false,
          visible: isNotAustralianSchool,
          maxlength: 128
        },

        {
          apiKey: `${apiKeyPrefix}prevSchoolId`,
          label: 'Name of school last attended',
          required: false,
          visible: isAustralianSchool,
          type: FIELD_TYPE.SCHOOL_LOOKUP,
          colspan: 2
        },
        {
          apiKey: `${apiKeyPrefix}otherSchoolAttendedLocation`,
          label: 'Location of school last attended',
          required: false,
          visible: isNotEmptyOrUnknownPrevSchool,
          maxlength: 128
        },
        {
          apiKey: `${apiKeyPrefix}prevSchoolStartDate`,
          label: 'Attended from',
          required: false,
          visible: isNotEmptyOrUnknownPrevSchool,
          type: FIELD_TYPE.DATE,
          pastYears: 16,
          futureYears: 0,
          monthOnly: true
        },
        {
          apiKey: `${apiKeyPrefix}prevSchoolLastDate`,
          label: 'Attended until',
          required: false,
          visible: isNotEmptyOrUnknownPrevSchool,
          type: FIELD_TYPE.DATE,
          pastYears: 16,
          futureYears: 0,
          validation: validators.prevSchoolLastDate,
          monthOnly: true
        }
      ]
    }
  },

  {
    label: 'Kindergarten students',
    type: FIELD_TYPE.HEADING,
    visible(application) {
      return isKintergartenApplication(application)
    }
  },
  {
    apiKey: 'hasAttendedPreSchool',
    label:
      'Has the student been in early childhood education and care in the year before school?',
    required: false,
    validation(value, application) {
      var preschoolCount = application.preschools.length

      if (value && !preschoolCount) {
        return 'Please add preschool information'
      } else if (!value && preschoolCount) {
        return `This value should be 'Yes' when preschool information is present`
      }
    },
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    visible(application) {
      return isKintergartenApplication(application)
    }
  },

  // PRESCHOOLS ----------------------------------------------------------
  {
    apiKey: 'preschools',
    type: FIELD_TYPE.COLLECTION,
    heading(preschool) {
      var type = store.getters.lookupCode(
        DATASET.PRESCHOOL_TYPE,
        preschool.preschoolType
      )
      if (type) {
        return `Early childhood education and care: ${UTILS.trimFrom(
          type,
          '('
        )}`
      }
      return `Early childhood education and care`
    },
    placeholderTitle: 'Early childhood education and care',
    addLabel: 'Add early childhood care',
    newRecordTemplate() {
      return {
        preschoolType: '',
        preschoolHoursType: '',
        postcode: ''
      }
    },
    oesRecordNumberField: 'preschoolRecordNo',
    conflictAlerting: CONFLICT_ALERTING.LIST,
    fields(apiKeyPrefix, preschool) {
      var isDisplayNameAndPostcode = function () {
        return 'PRS,LDP,LDW'.indexOf(preschool.preschoolType) >= 0
      }
      return [
        {
          apiKey: apiKeyPrefix + 'preschoolType',
          label: 'Type',
          type: FIELD_TYPE.DROPLIST,
          required: true,
          dataset: DATASET.PRESCHOOL_TYPE
        },
        {
          apiKey: apiKeyPrefix + 'preschoolHoursType',
          label: 'Hours',
          required() {
            return preschool.postcode && isDisplayNameAndPostcode()
          },
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Part time', value: 'PTM' },
            { text: 'Full time', value: 'FTM' }
          ]
        },
        {
          apiKey: apiKeyPrefix + 'postcode',
          label: 'Postcode',
          required() {
            // Only required for type 'Preschool' when hours are specified
            return (
              preschool.preschoolType === 'PRS' && preschool.preschoolHoursType
            )
          },
          type: FIELD_TYPE.POSTCODE,
          visible: isDisplayNameAndPostcode
        },
        {
          apiKey: apiKeyPrefix + 'schoolName',
          label: 'Name of preschool/day care service',
          required: false,
          visible: isDisplayNameAndPostcode
        }
      ]
    },
    visible(application) {
      return isKintergartenApplication(application)
    }
  }
]
