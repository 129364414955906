<!--
  This template displays a heading for a "group" field
-->
<template>
  <div class="FieldGroupHeading">
    <h3 :class="isPrintPreview ? '' : 'showDottedLine'">
      <span>{{ modelRow.label }}</span>
    </h3>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
export default {
  name: 'FieldGroupHeading',
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin]
}
</script>

<style scoped lang="scss">
.FieldGroupHeading {
  padding: $content-padding;

  h3 {
    font-size: 1rem;
    margin: 0;
    display: flex;

    &.showDottedLine {
      padding: 0 0 0.15em;
    }
    span {
      flex: 1;
    }
  }
}
</style>
