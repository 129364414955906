<template>
  <div class="py-6 grey lighten-6">
    <SetTargetDialog
      v-if="isModalVisible"
      @close="closeModal"
      @setTarget="setTarget"
      data-testid="dialogShow"
    />
    <v-row class="mx-10">
      <v-col cols="8">
        <span class="pl-3">
          <h1 class="heading">Summary Report {{ this.currentYear }}</h1>
        </span>
      </v-col>
      <v-col cols="4" class="text-right">
        <AdsButton
          @click="showModal"
          @setTarget="setTarget"
          icon="location_searching"
          button-text="Set/Edit enrolment forecast"
          data-testid="editForecastButton"
        />
      </v-col>
    </v-row>
    <v-row class="mx-10">
      <v-col cols="12">
        <NumbersTracker
          @open="showModal"
          @setTarget="setTarget"
          data-testid="y67tNumbersTracker"
        />
      </v-col>
    </v-row>
    <v-row class="mx-10">
      <v-col cols="12">
        <ComparisonPerRound data-testid="y67tComparisonPerRound" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import ComparisonPerRound from '../summary/ComparisonPerRound.vue'
import NumbersTracker from '../summary/NumbersTracker.vue'
import SetTargetDialog from '../summary/SetTargetDialog.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Y67TSecondarySummaryReport',
  components: {
    AdsButton,
    ComparisonPerRound,
    NumbersTracker,
    SetTargetDialog
  },
  data() {
    return {
      isModalVisible: false,
      targetText: 0
    }
  },
  methods: {
    showModal() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    setTarget(value) {
      this.isModalVisible = false
      this.$store.dispatch('setIsLoadingNumberTracker', true)
      let number = Number.parseInt(value)
      this.$store.dispatch('saveSummaryTarget', number)
    }
  },
  computed: {
    ...mapGetters(['numbersTarget', 'selectedSchool']),
    currentYear() {
      return new Date().getFullYear().toString()
    }
  }
}
</script>

<style scoped lang="scss">
body h1 {
  font-size: 1.65rem;
}

::v-deep div.v-card__title h3 {
  font-size: 1.5rem;
}
</style>
