<template>
  <nav
    v-if="currentNum != 0 && totalNum != 0"
    aria-label="pagination"
    class="recordPagination"
    data-testid="application-view-pagination"
  >
    <div class="ApplicationRecordPagination">
      <v-btn
        class="mx-0"
        icon
        text
        color="primary"
        :disabled="currentNum === 1 || isEditing"
        @click="prev"
        aria-label="Previous application"
        data-testid="application-view-previous"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        class="mx-0"
        icon
        text
        color="primary"
        :disabled="currentNum === totalNum || isEditing"
        @click="next"
        aria-label="Next application"
        data-testid="application-view-next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <div class="count">{{ currentNum }} of {{ totalNum }}</div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import { STREAM } from '@/constants'

export default {
  name: 'ApplicationRecordPagination',
  computed: {
    ...mapGetters([
      'filteredApplicationIds',
      'application',
      'isEditing',
      'currentStream'
    ]),
    currentNum() {
      return (
        this.filteredApplicationIds.indexOf(
          this.application[this.currentIdKey]
        ) + 1
      )
    },
    currentIdKey() {
      if (this.currentStream === STREAM.SELECTIVE_OFFERS) {
        return 'shsOfferId'
      }
      return 'applicationID'
    },
    totalNum() {
      return this.filteredApplicationIds.length
    },
    isShsOffer() {
      return this.currentStream === STREAM.SELECTIVE_OFFERS
    },
    streamRoute() {
      return this.isShsOffer ? 'shs-offer' : 'application'
    }
  },
  methods: {
    prev() {
      const schoolCode = this.application.schoolCode
      const prevIdx = this.currentNum - 2
      const prevApplicationId = this.filteredApplicationIds[prevIdx]

      this.$router.push(
        `/${this.streamRoute}/${schoolCode}/${prevApplicationId}`
      )
    },
    next() {
      const schoolCode = this.application.schoolCode
      const nextIdx = this.currentNum
      const nextApplicationId = this.filteredApplicationIds[nextIdx]

      this.$router.push(
        `/${this.streamRoute}/${schoolCode}/${nextApplicationId}`
      )
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationRecordPagination {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-end;

  .count {
    min-width: 60px;
    font-size: 1em;
  }

  .v-btn {
    border-color: transparent;
    color: white !important;
    &:hover {
      text-decoration: none;
    }

    &:focus {
      color: white !important;
      border: 3px solid white;
    }
    &:focus::before {
      opacity: 0;
    }
  }
  .v-btn--icon.v-size--default {
    height: 32px;
    width: 32px;
  }
}
</style>
