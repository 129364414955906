<template>
  <div ref="selectField">
    <div v-if="!isEditing">
      <read-only-field
        :label="$attrs.label"
        :value="$attrs.value"
        v-bind="$attrs"
        :formatter="formatter || defaultFormatter"
        @click="setEditMode($refs.selectField)"
        data-testid="selectFieldReadOnly"
      />
    </div>
    <div v-else>
      <AdsSelect
        v-bind="$attrs"
        v-on="$listeners"
        outlined
        data-testid="selectField"
        persistent-placeholder
      />
    </div>
  </div>
</template>

<script>
import { AdsSelect } from '@nswdoe/doe-ui-core'
import toggleFieldMixin from './toggleFieldMixin'

export default {
  name: 'SelectField',
  mixins: [toggleFieldMixin],
  components: {
    AdsSelect
  },
  props: {
    formatter: {
      type: Function,
      default: null
    }
  },
  methods: {
    defaultFormatter(val) {
      const items = this.$attrs.items
      if (items) {
        const item = items.find((o) => o.value === val)
        return item ? item.text : null
      }
      return null
    }
  }
}
</script>
