<template>
  <label :class="'AppCheckbox' + (disabled ? ' disabled' : '')">
    <input
      type="checkbox"
      :id="id"
      :checked="checked"
      :name="name"
      :disabled="disabled"
      @change="$emit('change', $event.target.checked)"
      @focus="onFocus()"
      ref="defaultInput"
      style="vertical-align: middle"
      :aria-describedby="ariaDescribedBy"
    />
    <span style="vertical-align: middle">{{ label }}</span>
  </label>
</template>

<script>
export default {
  name: 'AppCheckbox',
  props: {
    id: String,
    label: String,
    name: String,
    checked: Boolean,
    hidden: Boolean,
    disabled: Boolean,
    ariaDescribedBy: { type: String, default: '' }
  },
  methods: {
    onFocus() {
      this.$emit('focus', this)
    }
  }
}
</script>

<style scoped lang="scss">
$box-size: 1.5em;

.AppCheckbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 0.15em 0 0 0;
  margin: 0.25em 1em 0.25em 0;
  min-height: $box-size;
  user-select: none;

  &.disabled {
    cursor: default;
    opacity: 0.75;
  }

  span {
    padding-left: 2em;
    margin: 0;
    display: inline-block;

    &:before {
      // Box image
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23333333' d='M0,0h32v32H0V0z'/%3E%3Cpath fill='%23ffffff' d='M2,2h28v28H2V2z'/%3E%3C/svg%3E");
    }

    &:before,
    &:after {
      width: $box-size;
      height: $box-size;
      content: ' ';
      display: block;
      position: absolute;
      background-repeat: no-repeat;
      left: 0px;
      top: 0px;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    height: $box-size;
    width: $box-size;
    border: 0;
    padding: 0;
  }

  input:checked + span:after {
    // Tick image
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath fill='%23313131' d='M25.6,11.4c0.2-0.2,0.2-0.5,0-0.7l-2.3-2.3c-0.2-0.2-0.5-0.2-0.7,0L14,17l-3.6-3.6c-0.2-0.2-0.5-0.2-0.7,0l-2.3,2.3 c-0.2,0.2-0.2,0.5,0,0.7l6.3,6.3c0.2,0.2,0.5,0.2,0.7,0L25.6,11.4L25.6,11.4z'/%3E%3C/svg%3E");
  }
}
</style>
