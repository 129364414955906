var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"ApplicationNavBar"},[_c('div',{staticClass:"back float-left"},[(!_vm.isEditing)?_c('router-link',{staticClass:"unstyled",attrs:{"to":_vm.backRoute,"aria-label":"Back"}},[_c('v-icon',[_vm._v("arrow_back")])],1):_c('span',[_c('v-icon',{attrs:{"disabled":""}},[_vm._v("arrow_back")])],1)],1),_c('div',{staticClass:"ml-3 pt-1 float-left"},[(
        _vm.isOutOfAreaApplication &&
        (!_vm.isLastUpdatedByParentEmail(_vm.application.updatedBy) ||
          _vm.isParentDeclinedOffer ||
          _vm.isParentAcceptedOffer)
      )?_c('ModifiedByInfo',{attrs:{"last-modified-by":_vm.application.updatedBy,"last-modified":_vm.application.lastUpdatedDateTime,"parent-declined-offer":_vm.isParentDeclinedOffer,"parent-accepted-offer":_vm.isParentAcceptedOffer,"parent-notified-by-email":_vm.isParentNotifiedByEmail}}):_vm._e(),(
        _vm.isY67TApplication &&
        ((_vm.application.isPrimary &&
          !_vm.isLastUpdatedByParentEmail(_vm.application.updatedBy)) ||
          _vm.checkStatus(_vm.applicationStatus) ||
          _vm.checkNewStatus(_vm.applicationStatus) ||
          _vm.isActiveLocalPaper)
      )?_c('ModifiedByInfo',{attrs:{"last-modified-by":_vm.application.updatedBy,"last-modified":_vm.application.lastUpdatedDateTime,"email-sent":_vm.application.isEmailSentForHSAction ||
        _vm.application.emailSentCoreAppSubmittedForLHS,"new-status-with-parent-update":_vm.checkNewStatus(_vm.application.applicationStatus) &&
        _vm.isLastUpdatedByParentEmail(_vm.application.updatedBy)}}):_vm._e(),(
        _vm.isLocalAreaApplication &&
        !_vm.isLastUpdatedByParentEmail(_vm.application.lastModifiedUser)
      )?_c('ModifiedByInfo',{attrs:{"last-modified-by":_vm.application.lastModifiedUser,"last-modified":_vm.application.lastModifiedDatetime}}):_vm._e()],1),(
      _vm.isDownloadAvailable &&
      !_vm.isOesSupport &&
      _vm.application &&
      _vm.application.applicationID &&
      _vm.isERNAdmin
    )?_c('div',{staticClass:"download-btn float-right"},[_c('DownloadDocument',{attrs:{"school-code":_vm.application.schoolCode,"application-type":_vm.state.applicationType}})],1):_vm._e(),(_vm.isLocalAreaApplication)?_c('div',{staticClass:"print float-right"},[_c('router-link',{staticClass:"unstyled",attrs:{"to":'/print/' + _vm.application.applicationID,"disabled":_vm.isEditing}},[_c('font-awesome-icon',{staticClass:"icon",attrs:{"focusable":"false","icon":"print"}}),_c('span',[_vm._v("Print")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }