<!--
  Displays bar with session timeout countdown and login button  
-->

<template>
  <div>
    <div
      v-if="hasTimedOut"
      ref="disableUi"
      class="disableUi"
      tabindex="0"
    ></div>
    <div v-if="showBanner" class="AppSessionCountdownBar">
      <div class="message">
        <div class="icon iconCircleBackground">
          <font-awesome-icon focusable="false" icon="exclamation" />
        </div>
        <span class="timer-message" v-if="!hasTimedOut"
          >Your session will expire in
          <strong>{{ countdownText }}</strong></span
        >
        <span v-if="hasTimedOut"
          ><strong
            >Your session has expired. Please log in again to continue.</strong
          ></span
        >
      </div>
      <button type="button" class="primary" @click="onClick">
        {{ hasTimedOut ? 'LOGIN TO CONTINUE' : 'CONTINUE SESSION' }}
      </button>
    </div>
  </div>
</template>

<script>
import API from '@/store/apiUtils'

export default {
  name: 'AppSessionCountdownBar',
  props: { minutesWarning: { type: Number, default: 5 } },
  data() {
    return {
      countdownTimer: null,
      timeout: {
        mins: this.minutesWarning,
        secs: 0
      }
    }
  },
  watch: {
    showBanner: {
      handler(newValue) {
        //If we have just started displaying the banner, then start the countdown and event listeners
        if (newValue) {
          this.addListeners()
          this.beginCountdown()
        }
      },
      immediate: true
    }
  },
  computed: {
    hasTimedOut() {
      return this.timeout.mins === 0 && this.timeout.secs === 0
    },
    showBanner() {
      return this.$store.state.showTimeoutBanner
    },
    countdownText() {
      return `${this.timeout.mins} minute${
        this.timeout.mins !== 1 ? 's' : ''
      }, ${this.timeout.secs} second${this.timeout.secs !== 1 ? 's' : ''}`
    }
  },
  methods: {
    beginCountdown() {
      this.timeout.mins = this.minutesWarning
      this.timeout.secs = 0
      this.countdownTimer = setInterval(this.countDownOneSecond, 1000)
    },
    addListeners() {
      window.addEventListener('keydown', this.continueSession)
      window.addEventListener('mousedown', this.continueSession)
    },
    removeListeners() {
      window.removeEventListener('keydown', this.continueSession)
      window.removeEventListener('mousedown', this.continueSession)
    },
    countDownOneSecond() {
      let mins = this.timeout.mins
      let secs = this.timeout.secs
      if (mins === 0 && secs === 0) {
        clearInterval(this.countdownTimer)
        this.removeListeners()
        this.removeTokens()
      } else if (secs === 0) {
        this.timeout.mins = mins - 1
        this.timeout.secs = 59
      } else {
        this.timeout.secs = secs - 1
      }
    },
    removeTokens() {
      API.clearTokens()
    },
    continueSession() {
      clearInterval(this.countdownTimer)
      this.removeListeners()
      this.$store.dispatch('set', ['showTimeoutBanner', false])
      this.$activityMonitor.activate()
    },
    onClick() {
      this.hasTimedOut ? this.reauthenticate() : this.continueSession()
    },
    reauthenticate() {
      this.$auth.DET.startAuth()
    }
  },
  beforeDestroy() {
    this.removeListeners()
    clearInterval(this.countdownTimer)
  }
}
</script>

<style scoped lang="scss">
.AppSessionCountdownBar {
  position: fixed;
  width: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  background-color: #ffe7a4;
  color: black;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.5);

  .message {
    display: flex;
    align-items: center;
    .icon {
      margin-right: 0.5rem;
    }
  }

  > p,
  > button {
    margin: 0;
  }
}
.disableUi {
  z-index: 99999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(#000, 0.5);
}
</style>
