<!--
  Snackbar component

  options: 

  display     - true to flash the snackbar
  html        - html to display (any user content should be sanitised first)
  icon        - Optional icon name & icon color to determine the default icon 
  attrs       - Optional v-snackbar exposed attributes
-->

<template>
  <div class="AppSnackbar">
    <div class="sr-only" aria-live="polite">
      <span
        v-if="snackbar.html"
        v-html="snackbar.html"
        data-testid="snackbar-accessibility-div"
      ></span>
    </div>
    <v-snackbar v-model="display" v-bind="attrs">
      <div class="pt-4 pb-4">
        <v-icon dark right :color="snackbar.icon.color">{{
          snackbar.icon.name
        }}</v-icon>
        <span
          class="ml-3"
          v-if="snackbar.html"
          v-html="snackbar.html"
          data-testid="snackbar-content"
        ></span>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'AppSnackbar',
  computed: {
    snackbar() {
      return this.$store.state.snackbar || { icon: {} }
    },
    display: {
      get() {
        return this.snackbar ? this.snackbar.display : false
      },
      set() {
        this.$store.dispatch('hideSnackbar')
      }
    },
    attrs() {
      const defaults = {
        light: true,
        bottom: true,
        color: 'white',
        left: true,
        'multi-line': false,
        right: false,
        timeout: 5000,
        top: false,
        vertical: false
      }

      return { ...defaults, ...this.snackbar.attrs }
    }
  }
}
</script>

<style scoped lang="scss">
.AppSnackbar .v-snack {
  font-size: 16px;
  color: #121212;
}
::v-deep .v-snack__content {
  padding: 16px 16px;
}

::v-deep .v-snack__wrapper {
  max-width: 900px !important;
}
</style>
