<template>
  <v-card outlined class="record-bg-color pa-3">
    <v-row>
      <v-col>
        Student:<br /><b>{{ student.surname }}, {{ student.firstName }}</b>
      </v-col>
      <v-col>
        Gender:<br /><b>{{ student.gender }}</b>
      </v-col>
      <v-col>
        Parents/carers:<br />
        <div
          v-for="parent in student.parents"
          :key="parent"
          :class="isParentCarerMatch ? '' : 'conflict'"
        >
          <b>{{ parent }}</b>
        </div>
      </v-col>
      <v-col cols="4">
        Current school:<br /><b>{{ student.schoolNYear }}</b>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        Date of birth:<br /><b>{{ getDateOfBirthFormat }}</b>
      </v-col>
      <v-col>
        Postcode:<br /><b>{{ student.postcode }}</b>
      </v-col>
      <v-col />
      <v-col cols="4">
        Full residential address:<br />
        <span :class="isAddressMatch ? '' : 'conflict'">
          <b>{{ getAddressFormat }}</b>
        </span>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SECTIONS_LA from '@/applicationDefinition/applicationSections'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'SrnSearchSiblingRecord',
  props: {
    student: {
      type: Object
    },
    isParentCarerMatch: {
      type: Boolean
    },
    isAddressMatch: {
      type: Boolean
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    getDateOfBirthFormat() {
      return this.$moment(this.student.dateOfBirth).format('DD/MM/YYYY')
    },
    getAddressFormat() {
      const familySections = SECTIONS_LA.find(
        (section) => section.id === 'family'
      )
      const familyFields = familySections.fields.find(
        (field) => field.apiKey === 'residentialAddress'
      )
      return this.getDisplayValue(familyFields, this.student.residentialAddress)
    }
  }
}
</script>

<style lang="scss" scoped>
.conflict {
  color: $color-error-dark;
}
.record-bg-color {
  border: none;
  background-color: $color-student-record-bg;
}
</style>
