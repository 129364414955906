var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"highSchoolEoIDetails",staticClass:"pt-0"},[(!_vm.isMultipleSchools)?_c('p',{staticClass:"pb-3 page-title"},[_vm._v(" This child is entitled to a place at their local high school."),_c('br'),_vm._v("This child’s local high school for "),_c('strong',[_vm._v(_vm._s(this.residentialAddress))]),_vm._v(" is: ")]):_vm._e(),_c('v-form',{ref:"highSchoolDetailsForm",attrs:{"data-testid":"high-school-form","lazy-validation":""}},[_c('v-card',{staticClass:"pa-6 mb-8"},[(_vm.isMultipleSchools)?_c('h2',{staticClass:"heading mb-4",attrs:{"data-testid":"select-preferred-local-schools"}},[_vm._v(" Select preferred local school ")]):_vm._e(),_c(_vm.isMultipleSchools ? 'RadioGroup' : 'div',{tag:"component",class:_vm.isMultipleSchools ? 'multipleSchools' : '',attrs:{"rules":[
          _vm.validators.customError('Please select preferred local school')
        ],"required":"","error-messages":_vm.blurLocalSchoolError,"label":"Please select preferred local school","data-testid":"preferred-local-schools"},on:{"radioblur":function($event){return _vm.handleBlur(_vm.selectedLocalHighSchoolCode, 'school')},"input":function($event){_vm.blurLocalSchoolError = []}},model:{value:(_vm.selectedLocalHighSchoolCode),callback:function ($$v) {_vm.selectedLocalHighSchoolCode=$$v},expression:"selectedLocalHighSchoolCode"}},_vm._l((_vm.localSecondarySchoolsForDisplay),function(school,index){return _c('SchoolCard',{key:index,attrs:{"image-src":require('@/assets/icon-school.svg'),"radio-attrs":{ value: _vm.localHighSchools[index].school_code },"school-name":school.schoolName,"address-text":school.addressText,"address-url":school.addressUrl,"phone-number":school.phoneNumber,"website-url":school.websiteUrl,"website-text":school.websiteText,"selectable":_vm.isMultipleSchools,"data-testid":"preferred-local-school-card"}})}),1)],1),_c('div',{staticClass:"form-card pa-6 mb-8"},[_c('h2',{staticClass:"heading"},[_vm._v("Other school options")]),_c('RadioGroup',{staticClass:"form-card__question form-card__question--selective",attrs:{"label":"Has the parent submitted an application for placement in an academically selective school?","items":_vm.YES_NO_OPTIONS,"rules":[
          _vm.validators.customError(
            'Please choose if the parent has submitted an application for placement in an academically selective school'
          )
        ],"required":"","error-messages":_vm.blurSelectiveError,"data-testid":"selective-school"},on:{"radioblur":function($event){return _vm.handleBlur(_vm.consideringSelectiveSchool, 'selective')},"input":function($event){_vm.blurSelectiveError = []}},model:{value:(_vm.consideringSelectiveSchool),callback:function ($$v) {_vm.consideringSelectiveSchool=$$v},expression:"consideringSelectiveSchool"}}),(_vm.showOOAQuestion)?_c('v-divider'):_vm._e(),(_vm.showOOAQuestion)?_c('RadioGroup',{staticClass:"form-card__question form-card__question-ooa",attrs:{"label":"Will the parent be considering placement(s) at any other government schools besides their local high school?","items":_vm.YES_NO_OPTIONS,"rules":[
          _vm.validators.customError(
            'Please choose if the parent will be considering placement(s) at any other government schools besides their local high school'
          )
        ],"error-messages":_vm.blurOoaError,"required":"","data-testid":"ooa-schools"},on:{"change":_vm.changeOoaSchoolOptions,"radioblur":function($event){return _vm.handleBlur(_vm.consideringOoAGovtSchools, 'ooa')},"input":function($event){_vm.blurOoaError = []}},model:{value:(_vm.consideringOoAGovtSchools),callback:function ($$v) {_vm.consideringOoAGovtSchools=$$v},expression:"consideringOoAGovtSchools"}}):_vm._e(),(_vm.consideringOoAGovtSchools)?_c('v-divider'):_vm._e(),_c('TopSlideTransition',{staticClass:"school-finder__transition",attrs:{"element-max-height":"40rem"}},[(_vm.consideringOoAGovtSchools || _vm.nominatedSchools.length > 0)?_c('div',{staticClass:"school-finder__wrapper"},[_c('AdditionalSchoolFinder',{attrs:{"card":false,"title":"Nominate out-of-area schools","nominated-schools":_vm.nominatedSchools,"local-schools":_vm.localHighSchools,"data-testid":"ooa-additional-school-finder"},on:{"update:nominatedSchools":_vm.setSelectedSchools}})],1):_vm._e()])],1),(_vm.consideringOoAGovtSchools && _vm.nominatedSchools.length > 0)?_c('div',{staticClass:"form-card"},[_c('h2',{staticClass:"heading mb-6"},[_vm._v("Considerations document")]),_c('p',{staticClass:"description"},[_vm._v(" Upload a scanned document of any details supplied by parent/carer to support this student’s application ")]),_c('ConsiderationsDocumentUpload',{ref:"considerationsDocumentUpload",staticClass:"mt-8",attrs:{"is-busy":_vm.isFileOperationInProgress,"label":"Uploaded file","data-testid":"ooa-considerations-upload"},on:{"update:isBusy":function($event){_vm.isFileOperationInProgress=$event},"update:is-busy":function($event){_vm.isFileOperationInProgress=$event},"notification":_vm.passNotification},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }