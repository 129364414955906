<template>
  <TabNavigation
    v-model="activeTab"
    :items="items"
    :key="key"
    @tabChange="handleTabChange"
  >
    <template slot="content1">
      <div class="mx-12">
        <LocalAreaDataTable mode="pending" :default-page-length="defaultPage" />
      </div>
    </template>
    <template slot="content2">
      <div class="mx-12">
        <LocalAreaDataTable
          mode="processed"
          :default-page-length="defaultPage"
        />
      </div>
    </template>
  </TabNavigation>
</template>

<script>
import { TabNavigation } from '@nswdoe/doe-ui-core'
import LocalAreaDataTable from './LocalAreaDataTable.vue'
import { mapGetters } from 'vuex'

const TAB_NAMES = {
  PENDING: 'Pending',
  PROCESSED: 'Processed'
}

export default {
  name: 'StudentDetailUpdates',
  components: {
    TabNavigation,
    LocalAreaDataTable
  },
  data() {
    return {
      activeTab: 0,
      items: [
        { tab: `${TAB_NAMES.PENDING}` },
        { tab: `${TAB_NAMES.PROCESSED}` }
      ],
      key: 0,
      TAB_NAMES
    }
  },
  watch: {
    schoolCode() {
      this.$store.dispatch('getApplications')
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode'
    }),
    defaultPage() {
      return this.$store.state.laApplications.defaultPage
    }
  },
  created() {
    // load the list on first load
    this.$store.dispatch('getApplications')
  },
  activated() {
    // Refreshing the list in case the status changed in the details view
    this.$store.dispatch('getApplications')
  },
  methods: {
    handleTabChange(index) {
      this.key = Math.random()
      this.activeTab = index
    }
  },
  mounted() {
    this.$eventHub.$on('handleTabChange', this.handleTabChange)
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .ApplicationList {
    min-width: 768px;
    padding-top: 20px;
    .v-card__actions {
      padding: 1rem 3rem;
      background: $grey-6;
    }
  }

  .v-input.searchTxtField {
    background-color: transparent;
    input {
      border: 0;
      outline: none;
      background-color: transparent;
    }
    input::placeholder {
      color: $color-placeholder;
    }
    input:-ms-input-placeholder,
    input::-ms-input-placeholder {
      color: $color-placeholder;
    }
    .v-input__control > .v-input__slot:before {
      border-style: none;
      border-bottom: 1px solid $ads-light-20;
    }

    .v-input__slot {
      padding: 14px 0 18px 12px;

      .v-input__prepend-inner,
      .v-text-field__slot {
        margin-left: 8px;
      }
    }
    //overridding clear button styles after updating to vuetify 3.2.8
    .v-input__icon--clear button {
      margin-right: 28px;
      border: none;

      &:focus {
        border: 1px solid $color-primary-lighten-1;
      }
    }
    .v-input__icon--clear .theme--light.v-icon:before {
      color: white;
      background-color: $color-text-light;
      border-radius: 50%;
      font-size: 16px;
      font-weight: bold;
      padding: 2px;
    }
    .v-input__icon.v-input__icon--append .theme--light.v-icon {
      color: $color-secondary;
    }
  }
  // data table
  .v-data-table {
    padding: 0;
    border: 0;
    table {
      table-layout: fixed;
      .v-data-table__empty-wrapper {
        background-color: white !important; // Prevents hover highlight on "No data available" row
        td {
          padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
          color: $ads-dark-60;
        }
      }
    }
    thead.v-data-table-header {
      th[scope='col'] {
        color: $color-primary;
        font-size: 16px;
        font-weight: normal;
        padding: 20px 8px;
        vertical-align: top;
        .v-data-table-header__icon {
          color: $color-primary-lighten-1;
          margin-left: 3px;
        }
        &.active {
          font-weight: bold;
          color: $color-primary;
          .v-data-table-header__icon {
            color: $color-primary;
          }
        }

        //processed applications
        &.last-modified {
          padding-left: 20px;
        }
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        border-bottom: 1px solid white;
        padding: 25px 8px;
      }
      tr:last-child td {
        border-bottom: 1px solid white;
        padding: 10px;
      }
      td {
        font-size: 14px;
        // overriding vuetify's data table styling
        border-bottom: 0 !important;
      }
    }

    div.v-data-footer {
      font-size: 14px;
    }
  }

  @include desktop {
    .ApplicationList {
      padding: 1.5rem 3rem;
      min-height: 100%;
    }

    .v-data-table {
      td {
        cursor: default;
      }

      &.pending thead.v-data-table-header th.sortable.date-received {
        padding-left: 20px;
      }

      thead.v-data-table-header th.sortable {
        padding: 20px 10px;

        //pending applications
        &.date-received {
          width: 110px;
        }

        &.name {
          overflow-wrap: normal;
          word-wrap: normal;
          width: 90px;
        }

        &.dob {
          width: 100px;
        }
        &.intake-area {
          width: 125px;
        }
        &.scholastic-year {
          width: 100px;
        }

        &.start-date {
          width: 95px;
        }

        &.application-type {
          width: 80px;
        }
        &.srnLink {
          width: 100px;
        }
        &.status {
          width: 150px;
        }
        &.action {
          width: 82px;
          padding-right: 20px;
        }

        //processed applications
        &.last-modified {
          padding-left: 20px;
          width: 170px;
        }

        //ooa applications
        &.select-all {
          width: 60px;
        }
        &.siblings {
          width: 110px;
        }
        &.status-ooa {
          width: 190px;
        }
      }

      div.v-data-table__wrapper table tbody {
        tr:not(:last-child) td {
          padding: 20px 10px;
        }
        tr:last-child td {
          padding: 20px 10px;
        }
        tr td:first-child {
          padding-left: 20px;
        }
        tr td.status-processed {
          padding-right: 20px;
        }
      }
    }
  }
  #app.v-application
    button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
    opacity: 0;
  }
  .search-results-number {
    float: right;
    white-space: nowrap;
  }
  .application-status {
    width: 154px;
    .v-chip__content span,
    .v-chip__content {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
  }

  .application-srn {
    width: 54px;
    .v-chip__content span,
    .v-chip__content {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
  }
  .data-table-header {
    align: right;
  }

  .row-line {
    border-bottom: 1px solid #e0e0e0;
    margin: 0px 5px 10px 0px;
    padding: 0px;
    width: 100%;
  }

  .results-text {
    margin: 25px 20px 0 0 !important;
  }
}
</style>
