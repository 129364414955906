// See README.md for more info about field configuration

import { FIELD_TYPE, YES_NO } from '@/constants'

export default [
  {
    label: `Permissions`,
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'permissionToPublish',
    label: 'Permission to publish?',
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO
  },
  {
    apiKey: 'permissionToOnlineServices',
    label: 'Online services?',
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    readOnly: true,
    visible(application) {
      return (
        new Date(application.dateReceived) <
        new Date(process.env.VUE_APP_ONLINE_SERVICES_RELEASE_DATE)
      )
    },
    noConflictAlerting: true
  },
  {
    label: `Declaration`,
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'parentCarerName_1',
    label: 'Declared by',
    readOnly: true
  },
  {
    apiKey: 'hasStudentDeclaration',
    label: 'Signature of parent/carer?',
    required: true,
    type: FIELD_TYPE.RADIO,
    options: YES_NO,
    readOnly: true
  },
  {
    apiKey: 'studentDeclarationDate',
    label: 'Declaration date',
    required: true,
    type: FIELD_TYPE.DATE,
    readOnly: true
  }
]
