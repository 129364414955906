import { render, staticRenderFns } from "./SaveCancel.vue?vue&type=template&id=6265f552&scoped=true&"
import script from "./SaveCancel.vue?vue&type=script&lang=js&"
export * from "./SaveCancel.vue?vue&type=script&lang=js&"
import style0 from "./SaveCancel.vue?vue&type=style&index=0&id=6265f552&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6265f552",
  null
  
)

export default component.exports