<!--
  Displays content for the Actions panel (left of the application)
-->
<template>
  <div class="ApplicationAlerts">
    <h2>Actions</h2>
    <div class="alerts">&nbsp;</div>
  </div>
</template>

<script>
export default {
  name: 'EmptyAlerts'
}
</script>

<style scoped lang="scss">
.ApplicationAlerts {
  h2 {
    padding: 0.75rem 0;
    margin: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>
