<template>
  <div class="px-8 pt-4 pb-2">
    <v-row>
      <v-col>
        <read-only-field
          label="Australia/Overseas"
          v-model="schoolType"
          readonly
        />
      </v-col>
      <v-col>
        <read-only-field
          label="Student’s intended high school"
          v-model="intendedHighSchool.schoolName"
          readonly
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import ReadOnlyField from '@/components/form/ReadOnlyField'
import { DATASET } from '@/constants'

export default {
  name: 'LocationDetails',
  components: {
    ReadOnlyField
  },
  data() {
    return {
      validators: {
        required: validators.required
      }
    }
  },
  computed: {
    ...mapGetters(['application', 'selectedSchool', 'referenceData']),
    intendedHighSchool() {
      return this.application.intendedHighSchool
    },
    states() {
      return this.referenceData(DATASET.STATES)
    },
    schoolType() {
      if (this.intendedHighSchool.schoolType && this.states.length > 0) {
        const stateCode = this.intendedHighSchool.schoolType
        const customisedStates = {
          NSW: 'NSW non-government school',
          OVS: 'Overseas school',
          YYY: 'Other'
        }
        if (Object.prototype.hasOwnProperty.call(customisedStates, stateCode)) {
          return customisedStates[stateCode]
        } else {
          const displayText = this.states.find(
            (state) => state.value === stateCode
          )
          return displayText.text
        }
      }
      return ''
    }
  }
}
</script>
