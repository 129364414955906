<!--
  Fields will use this component to display as read-only for the following situations:
      * When viewing but not editing an application
      * During print preview
      * After application has been sent to ERN
      * When a conflict has been resolved
      * When a field evaluates as "read-only" based on field definition logic
      * When a parent field is read-only
 -->

<template>
  <div
    :data-id="modelRow.id"
    :class="
      'FieldReadOnly unstyled' + alertCssClasses + getFieldWidthClass(modelRow)
    "
    :disabled="modelRow.isReadOnly || isPrintPreview"
    ref="field"
    @click="editField()"
    :aria-label="modelRow.isReadOnly ? 'read only' : 'edit'"
  >
    <span class="fieldLabel">{{ modelRow.label }}</span>
    <div class="fieldContent">
      <!-- DISABLE LINTING TO PREVENT LINEBREAKS BEING ADDED AROUND VALUE -->
      <!-- eslint-disable -->
      <div v-if="modelRow.type === 'ADDRESS'">
        <DisplayAddress class="addressText" :modelRow="modelRow" />
      </div>
      <div v-else :class="modelRow.type === 'TEXTAREA' ? 'textarea' : ''">
        {{ fieldDisplayValue }}
      </div>
      <!-- eslint-enable -->
      <font-awesome-icon
        v-if="
          modelRow.isResolved || (modelRow.parent && modelRow.parent.isResolved)
        "
        focusable="false"
        icon="lock"
        class="iconLock"
      />
      <font-awesome-icon v-else focusable="false" icon="pen" class="iconPen" />
    </div>
    <!-- If field has an alert... -->
    <div v-if="!isWithdrawn">
      <FieldAlert :model-row="modelRow" />
    </div>
  </div>
</template>

<script>
import FieldAlert from '@/components/form/FieldAlert.vue'
import { RESOLUTION_TYPE } from '@/constants'
import UTILS from '@/store/utils'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import DisplayAddress from '@/components/application/DisplayAddress.vue'

export default {
  name: 'FieldReadOnly',
  props: {
    modelRow: {
      type: Object
    }
  },
  components: {
    FieldAlert,
    DisplayAddress
  },
  mixins: [fieldHelperMixin],
  computed: {
    alertCssClasses() {
      return this.modelRow.isReadOnly || this.isPrintPreview
        ? this.getFieldRedBarClass(this.modelRow)
        : ''
    },
    fieldDisplayValue() {
      const { oesDisplayValue } = this.modelRow
      return oesDisplayValue
    }
  },
  methods: {
    editField() {
      if (this.modelRow.isReadOnly || this.isPrintPreview) {
        return
      }

      if (this.modelRow.isResolved) {
        // If clicking on a resolved field, or a field within a resolved parent,
        // show details of the resolution...
        let resolvedModelRow = this.modelRow
        while (!resolvedModelRow.resolution) {
          // Traverse parents to find model row with resolution
          resolvedModelRow = resolvedModelRow.parent
        }
        let me = this
        this.$store.dispatch('showMessageBox', {
          icon: 'mdi-lock-outline',
          textConfirm: 'Close',
          html: this.getMessageBoxHtml(resolvedModelRow),
          onReady() {
            document.getElementById('undoResolution').onclick = function (e) {
              e.preventDefault()
              me.undoResolution(resolvedModelRow)
            }
          }
        })
      } else {
        window.OES.initialFieldOffset = this.$el.getBoundingClientRect().top // Necessary for keeping the current field in the same position while switching over to edit mode
        this.$store.dispatch('editApplication', this.modelRow)
      }
    },
    getMessageBoxHtml({ label, resolution }) {
      // We disable linting to prevent reindentation, which makes this html difficult to read
      /* eslint-disable */
      return `
        <h2>${UTILS.sanitize(label)} resolved</h2>
        <p>This information has been resolved and locked:</p>
        <p>
          <li>By <strong>${UTILS.sanitize(resolution.staffName)}</strong></li>
          <li>On ${this.$moment(resolution.date).format('LLLL')}</li>
          ${
            resolution.type === RESOLUTION_TYPE.AB_ERN ||
            resolution.type === RESOLUTION_TYPE.AB_OES
              ? `
          <li>Resolved using <strong>${
            resolution.type === RESOLUTION_TYPE.AB_ERN ? 'ERN' : 'new'
          }</strong> information</li>
          `
              : ''
          }
          ${
            resolution.type === RESOLUTION_TYPE.MERGE
              ? `
          <li>Resolved by <strong>manually merging</strong> information</li>
          `
              : ''
          }
        </p>
        <p>You may <a href='#' id="undoResolution">Undo</a> this resolution to unlock ${
          resolution.type === RESOLUTION_TYPE.AB_OES
            ? 'this'
            : 'and restore the original'
        } information.</p>
      `
      /* eslint-enable */
    },
    undoResolution(modelRow) {
      if (this.isEditing) {
        this.$store.dispatch('showMessageBox', {
          message: 'Please finish editing before undoing a conflict resolution.'
        })
      } else {
        this.$store.dispatch('hideMessageBox')
        this.$store.dispatch('updateAlertResolution', [modelRow, null])
      }
    },
    setFocus() {
      this.$refs.field.focus()
    }
  },
  watch: {
    focusFieldId: function () {
      if (this.modelRow.id === this.focusFieldId) {
        this.$store.dispatch('set', ['focusFieldId', null])
        this.setFocus()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.FieldReadOnly {
  padding: 0 1rem !important;
  margin: 0 0 1rem 0 !important;
  vertical-align: top;
  width: 33.33%;
  display: inline-block;
  &.width66 {
    width: 66%;
  }
  &.width100 {
    width: 100%;
  }

  &.showRedBar {
    border-left: 0.3em solid $color-red;
    padding-left: 0.7em;
    margin-bottom: 1em;
    padding-bottom: 0;
  }

  // The [disabled] attribute indicates a readOnly field here
  &[disabled] {
    cursor: not-allowed;
    opacity: 1;
  }

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    cursor: pointer;
    text-decoration: inherit;
    .iconPen,
    .iconLock {
      display: inline-block;
    }
  }

  &:not([disabled]):hover {
    background-color: $color-field-hover;
  }

  .fieldLabel {
    color: $color-text-light;
    font-size: $small-text;
    // wrap long names
    display: inline-block;
    word-break: break-word; // webkit
    word-wrap: break-word; // ie11
    max-width: 100%; // ie11
  }
  .fieldContent {
    padding: 0.5em 0 0.25em;
    color: black;
    min-height: 2em; // Ensures empty values still have height
    position: relative; // Necessary for absolutely placing icon at right hand end

    // Split long lines of text over multiple lines
    overflow-wrap: break-word;
    word-wrap: break-word;

    .textarea {
      white-space: pre-wrap; // Ensures carriage-returns are not removed from textareas
    }
  }
  .iconPen,
  .iconLock {
    display: none;
    position: absolute;
    color: $color-secondary;
    right: -0.5em;
    font-size: 0.9em;
    bottom: 0.5em;
  }
  .iconLock {
    color: black;
  }
}
</style>
