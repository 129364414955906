var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"parentCarerEOIDetails",staticClass:"pt-0"},[_c('p',{staticClass:"pb-3 page-title"},[_vm._v(" Enter the details of the parent/carer completing this expression of interest on behalf of the student. We use this information to contact you through the process. ")]),_c('v-card',{staticClass:"mb-6"},[_c('v-form',{ref:"parentCarerEoIForm",staticClass:"v-form",attrs:{"data-testid":"parent-carer-form","lazy-validation":""}},[_c('div',{staticClass:"pa-6"},[_c('h2',{staticClass:"section-heading mb-8",attrs:{"data-testid":"parent-carer-name"}},[_vm._v(" Parent/carer details ")]),_c('AdsSelect',{staticClass:"mb-6",attrs:{"items":_vm.relationshipTypes,"label":"Relationship","placeholder":"Select...","hint":"e.g. mother, father","persistent-placeholder":"","persistent-hint":"","aria-label":_vm.ariaLabel(_vm.parentCarers.parentCarerRelation, 'relationship'),"rules":[
            _vm.validators.required(
              _vm.parentCarers.parentCarerRelation,
              'Relationship'
            )
          ],"required":"","data-testid":"parent-carer-relation"},model:{value:(_vm.parentCarers.parentCarerRelation),callback:function ($$v) {_vm.$set(_vm.parentCarers, "parentCarerRelation", $$v)},expression:"parentCarers.parentCarerRelation"}}),_c('AdsSelect',{staticClass:"mb-6",attrs:{"items":_vm.titles,"label":"Title","placeholder":"Select...","aria-label":_vm.ariaLabel(_vm.parentCarers.parentCarerTitle, 'title'),"rules":[
            _vm.validators.required(_vm.parentCarers.parentCarerTitle, 'Title')
          ],"required":"","data-testid":"parent-carer-title","persistent-placeholder":""},model:{value:(_vm.parentCarers.parentCarerTitle),callback:function ($$v) {_vm.$set(_vm.parentCarers, "parentCarerTitle", $$v)},expression:"parentCarers.parentCarerTitle"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":"Given name","placeholder":"Enter given name","rules":[
            _vm.validators.required(
              _vm.parentCarers.parentCarerGivenName,
              'Given name'
            ),
            _vm.validators.name(_vm.parentCarers.parentCarerGivenName)
          ],"required":"","maxlength":"200","data-testid":"parent-carer-given-name","persistent-placeholder":""},model:{value:(_vm.parentCarers.parentCarerGivenName),callback:function ($$v) {_vm.$set(_vm.parentCarers, "parentCarerGivenName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"parentCarers.parentCarerGivenName"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"label":"Family name","placeholder":"Enter family name","required":"","rules":[
            _vm.validators.required(
              _vm.parentCarers.parentCarerFamilyName,
              'Family name'
            ),
            _vm.validators.name(_vm.parentCarers.parentCarerFamilyName)
          ],"maxlength":"200","data-testid":"parent-carer-family-name","persistent-placeholder":""},model:{value:(_vm.parentCarers.parentCarerFamilyName),callback:function ($$v) {_vm.$set(_vm.parentCarers, "parentCarerFamilyName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"parentCarers.parentCarerFamilyName"}}),_c('AdsTextField',{staticClass:"mb-6",attrs:{"validate-on-blur":"","label":"Contact email","placeholder":"Enter email","rules":[
            _vm.validators.required(
              _vm.parentCarers.parentCarerEmail,
              'Contact email'
            ),
            _vm.validators.email(_vm.parentCarers.parentCarerEmail, 'email address')
          ],"required":"","maxlength":"200","data-testid":"parent-carer-email","persistent-placeholder":""},model:{value:(_vm.parentCarers.parentCarerEmail),callback:function ($$v) {_vm.$set(_vm.parentCarers, "parentCarerEmail", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"parentCarers.parentCarerEmail"}}),_vm._l((_vm.parentCarers.contactDetails),function(contact,idx){return _c('div',{key:idx,staticClass:"mx-n6 contact-border"},[_c('div',{staticClass:"ma-6"},[_c('v-row',{staticClass:"d-flex align-start justify-space-between my-6"},[_c('v-col',{attrs:{"cols":"10","md":"11"}},[_c('h2',{staticClass:"section-heading mb-1",attrs:{"data-testid":"contact-details"}},[_c('span',{staticClass:"de-emphasise"},[(_vm.parentCarers && _vm.parentCarers.parentCarerGivenName)?_c('span',[_vm._v(_vm._s(_vm.pluralisedFirstName))]):_vm._e(),_vm._v(" contact number "+_vm._s(idx + 1)+": ")]),_vm._v(" "+_vm._s(_vm.contactTypeName(contact.contactType))+" ")]),(idx === 0)?_c('span',{staticClass:"hint"},[_vm._v("Add at least one contact number "),(_vm.parentCarers && _vm.parentCarers.parentCarerGivenName)?_c('span',[_vm._v("for "+_vm._s(_vm.parentCarers.parentCarerGivenName)+" ")]):_vm._e()]):_vm._e()]),_c('v-col',{attrs:{"cols":"2","md":"1"}},[(idx !== 0)?_c('v-btn',{staticClass:"remove-btn no-outline blue-icon mt-n4",attrs:{"text":"","icon":"","aria-label":"remove parent / carer","data-testid":"remove-contact-btn"},on:{"click":function($event){return _vm.removeContact(idx)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e()],1)],1),_c('AdsSelect',{staticClass:"mb-6",attrs:{"items":_vm.contactTypes,"label":"Phone number type","placeholder":"Enter type","aria-label":_vm.ariaLabel(contact.contactType, 'contacttype'),"rules":[
                _vm.validators.required(contact.contactType, 'Phone number type')
              ],"required":"","data-testid":"contact-contact-type","persistent-placeholder":""},model:{value:(contact.contactType),callback:function ($$v) {_vm.$set(contact, "contactType", $$v)},expression:"contact.contactType"}}),_c('AdsFormatContactField',{staticClass:"mb-6",attrs:{"validate-on-blur":"","label":"Enter number","placeholder":"Enter number","required":"","rules":[
                _vm.validators.required(contact.contactValue, 'Phone number'),
                _vm.validators.phone(contact.contactValue),
                _vm.validateSamePhoneNumber(
                  contact.contactType,
                  contact.contactValue,
                  idx
                )
              ],"maxlength":"132","data-testid":"contact-contact-value","persistent-placeholder":""},model:{value:(contact.contactValue),callback:function ($$v) {_vm.$set(contact, "contactValue", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact.contactValue"}}),_c('AdsTextArea',{staticClass:"comment-field",attrs:{"label":"Comments (optional)","placeholder":"e.g. best time to call","data-testid":"contact-comments","counter":false,"persistent-placeholder":""},model:{value:(contact.comments),callback:function ($$v) {_vm.$set(contact, "comments", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contact.comments"}})],1)])}),(_vm.showAddContactButton)?_c('AdsButton',{staticClass:"no-outline addcontact-btn mt-n4",attrs:{"button-text":_vm.addContactLabel,"icon":"add_circle_outline","x-large":"","tertiary":"","data-testid":"add-another-contact-btn"},on:{"click":_vm.addContact}}):_vm._e()],2)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }