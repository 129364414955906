<template>
  <AppModal
    v-if="showDigitiseEntryPointModal"
    v-model="showDigitiseEntryPointModal"
    ref="AppModal"
    data-testid="digitise-entry-point-modal"
    max-width="604px"
    class="digitise-entry-point"
    role-type="none"
  >
    <div slot="header" class="fullWidth">
      <div class="icon iconCircleBackground d-inline-block mr-2">
        <v-icon class="mt-n1" color="primary">mdi-pencil-outline</v-icon>
      </div>
      <div class="d-inline-block">
        <span class="headerText"
          ><strong>{{ studentName }}</strong></span
        >
      </div>
      <v-btn
        text
        icon
        small
        class="modal__close-button float-right"
        color="primary"
        aria-label="close"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div slot="body">
      <transition name="fade">
        <div>
          <div class="pt-1">
            <span class="helpText">
              Choose the action you would like to take on behalf of this
              student:
            </span>
          </div>
          <div>
            <template>
              <v-radio-group
                v-model="applicationType"
                class="digitise-options unstyled"
                aria-labelledby="Student Name"
              >
                <div
                  v-for="(record, index) in records"
                  class="selectedRecordPanel"
                  :class="isSelected(record.value)"
                  :key="index"
                  @click="setSelectedApplicationType(record.value)"
                >
                  <v-row>
                    <v-col cols="1">
                      <v-radio
                        :aria-label="'select' + record.text"
                        class="pt-4 ml-3"
                        :value="record.value"
                        :data-testid="'selected-' + record.value"
                      />
                    </v-col>
                    <v-col cols="1">
                      <div class="pt-3">
                        <v-icon large>{{ record.icon }}</v-icon>
                      </div>
                    </v-col>
                    <v-col cols="10">
                      <div class="pt-4">
                        <span
                          >{{ record.text }}
                          <strong>({{ record.value }})</strong></span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-radio-group>
            </template>
          </div>
        </div>
      </transition>
    </div>
    <div slot="footer">
      <AdsButton
        class="button"
        :disabled="isOkButtonDisabled"
        button-text="Proceed"
        @click="proceedApplication"
      />
    </div>
  </AppModal>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import AppModal from '@/components/app/AppModal.vue'

export default {
  name: 'Y67TDigitiseEntryPointModal',
  components: {
    AppModal,
    AdsButton
  },
  props: {
    selectedItem: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      showDigitiseEntryPointModal: true,
      applicationType: null,
      records: [
        {
          text: 'New expression of interest',
          value: 'EOI',
          icon: 'file_copy'
        },
        {
          text: 'Advisory of placement not required',
          value: 'PNR',
          icon: 'do_not_disturb'
        }
      ]
    }
  },
  computed: {
    isOkButtonDisabled() {
      return !this.applicationType
    },
    studentName() {
      return this.selectedItem.name
    }
  },

  methods: {
    setSelectedApplicationType(type) {
      this.applicationType = type
    },
    isSelected(record) {
      if (record === this.applicationType) {
        return 'isTicked'
      }
      return ''
    },
    proceedApplication() {
      const selectedItem = this.selectedItem
      const applicationType = this.applicationType
      //Remove local storage for localHighSchoolDraft
      if (localStorage.getItem('localHighSchoolDraft')) {
        localStorage.removeItem('localHighSchoolDraft')
      }
      this.$emit('proceed', { selectedItem, applicationType })
      this.$emit('close')
      this.hideMainScrollbar()
    },
    hideMainScrollbar() {
      const styleForScrollbar = document.createElement('style')
      styleForScrollbar.id = 'style-for-digitise-scrollbar'
      styleForScrollbar.innerHTML =
        'html::-webkit-scrollbar { display: none; } html { scrollbar-width: none; }'
      document.head.appendChild(styleForScrollbar)
    }
  }
}
</script>

<style scoped lang="scss">
.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}
.fullWidth {
  width: 100%;
}
.header {
  max-width: none;
}
.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}
// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: none;
}
.fade-enter {
  opacity: 0;
}
.fade-leave {
  display: none;
}
.digitise-options {
  width: 100%;
  border-radius: 0.25em;
  position: relative;

  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }
  .selectedRecordPanel {
    background-color: white;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $grey-darken1;
    box-sizing: border-box;
    height: 80px;
    font-size: 18px;
    color: $color-primary;
  }
  .isTicked {
    background-color: $color-selected-item;
    color: black;
    border: 2px solid $color-primary;
  }
  .helpText {
    font-size: 0.875rem;
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
</style>
