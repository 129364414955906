<!--
  Fields can be defined as type "HEADING". If so, the field is displayed as a subheading.
  Note that field COLLECTION or GROUP headings are more important, and have greater visual
  impact.
-->
<template>
  <div class="FieldHeading">
    <h3>{{ modelRow.label }}</h3>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'

export default {
  name: 'FieldHeading',
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin]
}
</script>

<style scoped lang="scss">
.FieldHeading {
  padding: $content-padding;

  h3 {
    padding: 0;
    margin: 0;
    font-size: 1rem;
  }
}
</style>
