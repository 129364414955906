<template>
  <div class="dashboard-wrapper">
    <CountApiError v-if="countApiError" />
    <div v-else>
      <AppNotifications />
      <div class="dashboard-header">My action items</div>
      <div v-if="noActionApp" class="noActionWrapper">
        <img
          class="tickIcon"
          src="@/assets/blue_tick.svg"
          alt="NSW Government"
        />
        <div class="noActiontittle">You’re all up to date</div>
        <div class="noActionSubtitle">
          You currently have no applications to assess
        </div>
      </div>
      <div v-else class="appCountCard">
        <AppCountCard
          v-for="(appCountConfig, index) in appCountConfigs"
          :key="index"
          :app-count-config="appCountConfig"
        />
      </div>
    </div>
    <div class="dashboard-links-title">
      <v-icon size="24">mdi-link</v-icon>
      <span class="dashboard-links">Quick links</span>
    </div>
    <div class="dashboard-links-margin">
      <v-icon size="18">mdi-logout-variant</v-icon>
      <a
        class="dashboard-link"
        href="https://education.nsw.gov.au/platoapps/online-enrolment-system"
        target="_blank"
        >Help & FAQs
      </a>
    </div>
    <div class="dashboard-links-margin">
      <v-icon size="18">mdi-logout-variant</v-icon>
      <a
        class="dashboard-link"
        href="https://nswdoeesmp1.service-now.com/services_gateway?sys_id=d2e6f2a297d4f110dfae7846f053afc4&si=Online%20Enrolment%20System&id=sg_item"
        target="_blank"
        >Report an issue
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AppNotifications from '@/components/applicationList/AppNotifications'
import AppCountCard from '@/components/app/AppCountCard'
import CountApiError from '@/components/app/CountApiError'
import { STREAM } from '@/constants'

export default {
  name: 'SIDashboard',
  components: { AppCountCard, CountApiError, AppNotifications },
  computed: {
    ...mapGetters(['selectedSchool', 'applicationCounts', 'countApiError']),
    noActionApp() {
      if (!this.applicationCounts) {
        return true
      }

      const actionApp = Object.values(this.applicationCounts).filter(
        (app) =>
          app?.count > 0 ||
          app?.incomingCount > 0 ||
          app?.outgoingCount > 0 ||
          app?.inProgressCount > 0 ||
          app?.newCount > 0
      )
      return actionApp.length === 0
    },
    enrolmentAppCount() {
      return this.applicationCounts?.core?.count || 0
    },
    ooaAppCount() {
      return this.applicationCounts?.ooa?.count || 0
    },
    shsAppCount() {
      return this.applicationCounts?.shs?.count || 0
    },
    year67tAppIncomingCount() {
      return this.applicationCounts?.y67t.incomingCount || 0
    },
    year67tAppOutgoingCount() {
      return this.applicationCounts?.y67t.outgoingCount || 0
    },
    studentDetailUpdatesCount() {
      return (
        this.applicationCounts?.sreSeeUpdates.inProgressCount +
          this.applicationCounts?.sreSeeUpdates.newCount || 0
      )
    },
    appCountConfigs() {
      return {
        enrolmentAppConfig: {
          countTitle: 'Enrolment applications',
          countSubtitle: 'New & In progress',
          appCount: this.enrolmentAppCount,
          icon: 'mdi-note-text-outline',
          value: STREAM.LOCAL_AREA
        },
        ooaAppConfig: {
          countTitle: 'Out-of-area applications',
          countSubtitle: 'New & In progress',
          appCount: this.ooaAppCount,
          icon: 'mdi-bus',
          value: STREAM.OUT_OF_AREA
        },
        shsAppConfig: {
          countTitle: 'Selective high school offers',
          countSubtitle: 'Requires review',
          appCount: this.shsAppCount,
          icon: 'mdi-star-outline',
          value: STREAM.SELECTIVE_OFFERS
        },
        year67tAppOutgoingConfig: {
          countTitle: 'Year 6 outgoing',
          countSubtitle: 'New & In progress',
          appCount: this.year67tAppOutgoingCount,
          icon: 'mdi-school-outline',
          value: STREAM.Y67T_PRIMARY
        },
        year67tAppIncomingConfig: {
          countTitle: 'Year 7 incoming',
          countSubtitle: 'Active',
          appCount: this.year67tAppIncomingCount,
          icon: 'mdi-account-multiple-plus-outline',
          value: STREAM.Y67T_SECONDARY
        },
        studentDetailUpdates: {
          countTitle: 'Student detail updates',
          countSubtitle: 'New & In progress',
          appCount: this.studentDetailUpdatesCount,
          icon: 'mdi-account-edit-outline',
          value: STREAM.STUDENT_DETAIL_UPDATES
        }
      }
    }
  },
  watch: {
    selectedSchool() {
      this.$store.dispatch('getApplicationCounts')
    },
    applicationCounts(newVal) {
      this.$store.commit('set', ['applicationCounts', newVal])
    }
  },
  created() {
    this.$store.dispatch('getApplicationCounts')
  }
}
</script>

<style scoped lang="scss">
.dashboard-wrapper {
  margin: 5rem auto;
  max-width: calc(1200px + 6rem);
  padding: 0 3rem;

  @media (max-width: 576px) {
    margin: 3rem 1rem;
    padding: 0;
  }
}

.noActionWrapper {
  height: 320px;
  max-width: 1200px;
  margin: 2rem auto 3rem;
  border-radius: 4px;
  background: $ads-white;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  margin-right: 3rem;
  text-align: center;
  color: $ads-primary-blue;
}

.tickIcon {
  margin: 4rem auto 2rem;
}

.noActiontittle {
  font-weight: bold;
  font-size: 1.5rem;

  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

.noActionSubtitle {
  font-size: 1rem;
  margin-top: 10px;

  @media (max-width: 576px) {
    font-size: 0.8rem;
  }
}

.dashboard-header {
  font-size: 1.8rem;
  color: $ads-primary-blue;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.dashboard-links {
  font-size: 1.3rem;
  font-weight: bold;
  color: $ads-primary-blue;
}
.dashboard-links-title {
  ::v-deep .v-icon {
    margin-right: 10px;
  }
}

.dashboard-link {
  font-size: 1rem;
  color: $ads-primary-blue;
}

.dashboard-links-margin {
  margin: 1.3rem 0;
  ::v-deep .v-icon {
    margin-right: 18px;
  }
}

.appCountCard {
  margin-bottom: 5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));

  @media screen and (min-width: 577px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
</style>
