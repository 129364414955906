<template>
  <div v-if="otherEois.length > 0" class="duplicate-eois">
    <div class="duplicate-eois__heading">
      <p>Multiple EOIs for this SRN. Please contact parent/carer to confirm</p>
      <v-btn
        class="duplicate-eois__toggle-btn"
        data-testid="toggle-button"
        :aria-expanded="`${showOtherEois}`"
        text
        :ripple="false"
        @click="toggleShowOtherEois"
      >
        <span class="button-text">{{ showOtherEois ? 'Hide' : 'Show' }}</span>
        <v-icon>{{
          showOtherEois ? 'mdi-chevron-up' : 'mdi-chevron-down'
        }}</v-icon>
      </v-btn>
    </div>
    <v-expand-transition>
      <!-- This div is required for expand transition to behave properly (doesn't like tables) -->
      <div v-if="showOtherEois">
        <table data-testid="duplicate-eois-table" class="duplicate-eois__table">
          <thead>
            <tr>
              <th>Date received</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(eoi, i) in sortedOtherEois"
              :key="`sortedothereois${i}`"
            >
              <td data-testid="date">
                {{ $moment(eoi.dateReceived).format('DD MMM YYYY') }}
              </td>
              <td data-testid="status">{{ duplicateEoiEvent(eoi) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import { Y67T_STATUS } from '@/constants'

export default {
  name: 'DuplicateEoiList',
  props: {
    otherEois: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showOtherEois: false
    }
  },
  computed: {
    sortedOtherEois() {
      return [...this.otherEois].sort((a, b) =>
        this.$moment(a.dateReceived).isBefore(b.dateReceived)
          ? -1
          : this.$moment(a.dateReceived).isAfter(b.dateReceived)
          ? 1
          : 0
      )
    }
  },
  methods: {
    duplicateEoiEvent(eoi) {
      return `An EOI ${
        eoi.applicationStatus === Y67T_STATUS.SENT
          ? `was sent to HS tracking on ${this.$moment(
              eoi.dateSentToERN
            ).format('DD MMM YYYY')}.`
          : `is "${eoi.applicationStatus.toLowerCase()}".`
      }`
    },
    toggleShowOtherEois() {
      this.showOtherEois = !this.showOtherEois
    }
  }
}
</script>

<style lang="scss" scoped>
.duplicate-eois {
  color: black;

  .duplicate-eois__warning-icon {
    color: $color-warning-dark !important;
  }

  &__heading {
    display: flex;
    align-items: baseline;
  }

  &__toggle-btn {
    border: none;
    color: currentColor;
    padding: 0 0.5rem;

    .button-text {
      text-decoration: underline;
      font-weight: bold;
    }
    &:hover::before {
      background-color: transparent;
    }

    &:focus {
      outline: 2px solid currentColor;
    }
  }

  &__warning-icon {
    margin-right: 0.5rem;
    margin-top: -0.25rem;
    align-self: center;
    color: currentColor;
  }

  &__table {
    color: $grey-1;

    td,
    th {
      min-width: 10rem;
      padding: 0.5rem 0;
    }
  }
}
</style>
