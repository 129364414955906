// Defines all of the sections used to group fields.
// See README.md for more info

import studentFields from './studentFields'
import parentFields from './parentFields'
import familyFields from './familyFields'
import otherFamilyFields from './otherFamilyFields'
import emergencyFields from './emergencyFields'
import specialFields from './specialFields'
import medicalFields from './medicalFields'
import riskFields from './riskFields'
import supportingDocumentsFields from './supportingDocumentsFields'
import declarationFields from './declarationFields'
import sreSeeAmaFields from './sreSeeAmaFields'

export default [
  {
    id: 'student',
    label: 'Student details',
    fields: studentFields
  },
  {
    id: 'parents',
    label: 'Family details - Parent/carer (enrolment owners)',
    fields: parentFields
  },
  {
    id: 'family',
    label: 'Family details',
    fields: familyFields
  },
  {
    id: 'other',
    label: 'Other family details',
    fields: otherFamilyFields
  },
  {
    id: 'emergency',
    label: 'Additional emergency contacts',
    fields: emergencyFields
  },
  {
    id: 'special',
    label: 'Special circumstances - additional information',
    fields: specialFields
  },
  {
    id: 'medical',
    label: 'Student medical details and health conditions',
    fields: medicalFields
  },
  {
    id: 'risk',
    label: "Student's history relevant to risk assessment",
    fields: riskFields
  },
  {
    id: 'supporting-documents',
    label: 'Supporting documents',
    fields: supportingDocumentsFields
  },
  {
    id: 'sre_see_ama',
    label: 'Supplementary details',
    fields: sreSeeAmaFields
  },
  {
    id: 'declaration',
    label: 'Permissions and declaration of accuracy',
    fields: declarationFields
  }
]
