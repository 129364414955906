<template>
  <v-dialog max-width="630" v-bind="$attrs" v-on="$listeners">
    <v-card class="modal">
      <v-btn class="close-button" icon aria-label="close" @click="closeModal">
        <v-icon> close </v-icon>
      </v-btn>
      <div class="content">
        <h1 class="heading mr-6">
          Change the status of this application for {{ studentName }}?
        </h1>
        <div class="mt-6">
          <AdsSelect
            v-model="status"
            :items="items"
            class="mb-0"
            outlined
            :rules="[(val) => val != null || 'Required.']"
            label="Change status to"
            placeholder="Please select..."
            @change="resetDefaultSendEmailFlag"
            persistent-placeholder
          />
        </div>
        <v-scroll-y-transition>
          <div
            v-if="status"
            class="email-checkbox-container px-6 pb-6 pt-3 mb-6"
          >
            <div
              v-if="isNotAccepted"
              class="my-4"
              v-html="emailLabel"
              data-testid="notAcceptedLabel"
            />
            <OesCheckbox
              v-model="sendEmail"
              v-if="!isNotAccepted"
              data-testid="sendEmailCheckbox"
            >
              <template #label>
                <div class="email-checkbox-label" v-html="emailLabel" />
              </template>
            </OesCheckbox>
            <div :class="{ 'ml-8': !isNotAccepted }">
              <a :href="emailTemplateUri" target="_blank">
                <strong>View the email template</strong>
              </a>
            </div>
          </div>
        </v-scroll-y-transition>
        <div>
          <AdsTextArea
            ref="comment"
            v-model="comment"
            label="Comments (school use only - optional)"
            placeholder=" "
            rows="5"
            :counter="maxCommentLength"
            :maxlength="maxCommentLength"
            persistent-placeholder
          />
        </div>
        <SupportingDocumentUpload
          v-model="files"
          ref="supportingDocumentUpload"
          :is-busy.sync="isFileOperationInProgress"
        />
      </div>
      <div class="d-flex justify-end pt-3">
        <v-btn
          class="px-6 py-4 confirmButton"
          @click="confirmChange"
          :color="confirmButtonColour"
          min-width="50%"
          :disabled="!isValid || isFileOperationInProgress"
          data-testid="confirmButton"
        >
          Confirm status change
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsSelect, AdsTextArea } from '@nswdoe/doe-ui-core'
import { OOA_STATUS, OOA_OFFERED_STATUSES } from '@/constants'
import OesCheckbox from '@/components/app/OesCheckbox'
import SupportingDocumentUpload from '@/components/app/SupportingDocumentUpload'

const optionsByCurrentStatus = {
  ...OOA_OFFERED_STATUSES.reduce((acc, s) => {
    acc[s] = {
      buttonColor: 'red',
      items: [
        OOA_STATUS.NOT_ACCEPTED,
        OOA_STATUS.OFFER_EXPIRED,
        OOA_STATUS.DECLINED
      ]
    }
    return acc
  }, {}),
  [OOA_STATUS.DECLINED]: {
    buttonColor: 'green darken-1',
    items: [
      OOA_STATUS.OFFER_POST_APPEAL,
      OOA_STATUS.OFFER_OTHER,
      OOA_STATUS.DECLINED_POST_APPEAL
    ]
  },
  [OOA_STATUS.DECLINED_POST_APPEAL]: {
    buttonColor: 'green darken-1',
    items: [
      OOA_STATUS.OFFER_POST_APPEAL,
      OOA_STATUS.OFFER_OTHER,
      OOA_STATUS.DECLINED_POST_APPEAL
    ]
  }
}

export default {
  name: 'ChangeStatusModal',
  components: {
    AdsSelect,
    AdsTextArea,
    OesCheckbox,
    SupportingDocumentUpload
  },
  props: {
    studentName: {
      type: String
    },
    currentStatus: {
      type: String,
      required: true
    },
    parentCarerName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      comment: '',
      status: null,
      sendEmail: this.getDefaultSendEmailFlag(),
      maxCommentLength: 200,
      files: [],
      isFileOperationInProgress: false
    }
  },
  methods: {
    confirmChange() {
      this.$emit('confirm', {
        status: this.status,
        comment: this.comment.trim(),
        sendEmail: this.status === OOA_STATUS.NOT_ACCEPTED || this.sendEmail,
        changeOutcome: true,
        documents: this.files
      })
      this.$emit('input', false)
      this.$eventHub.$emit('handleTabChange', 0)
    },
    getDefaultSendEmailFlag() {
      return (
        this.currentStatus === OOA_STATUS.DECLINED &&
        this.status !== OOA_STATUS.DECLINED_POST_APPEAL
      )
    },
    resetDefaultSendEmailFlag() {
      this.sendEmail = this.getDefaultSendEmailFlag()
    },
    closeModal() {
      if (this.files) {
        this.$refs.supportingDocumentUpload.deleteAll()
      }
      this.$emit('input', false)
    }
  },
  computed: {
    isValid() {
      return this.status && !this.$refs.comment.hasError
    },
    options() {
      return optionsByCurrentStatus[this.currentStatus]
    },
    items() {
      return this.options ? this.options.items : []
    },
    isNotAccepted() {
      return this.status === OOA_STATUS.NOT_ACCEPTED
    },
    emailLabel() {
      const offerLabel = `Email parent/carer <strong>${this.parentCarerName}</strong> instructions for accepting the offer and proceeding with a full enrolment application`
      const labelByStatus = {
        [OOA_STATUS.OFFER_POST_APPEAL]: offerLabel,
        [OOA_STATUS.OFFER_OTHER]: offerLabel,
        [OOA_STATUS.OFFER_EXPIRED]: `Email parent/carer <strong>${this.parentCarerName}</strong> to confirm their offer has expired`,
        [OOA_STATUS.DECLINED]: `Email parent/carer <strong>${this.parentCarerName}</strong> to confirm their offer of enrolment has now been declined by the school`,
        [OOA_STATUS.NOT_ACCEPTED]: `Parent/carer <strong>${this.parentCarerName}</strong> will be emailed with a confirmation that their application has been withdrawn.`,
        [OOA_STATUS.DECLINED_POST_APPEAL]: `Email parent/carer <strong>${this.parentCarerName}</strong> to confirm their appeal has been unsuccessful`
      }

      return labelByStatus[this.status]
    },
    confirmButtonColour() {
      if (this.status === OOA_STATUS.DECLINED_POST_APPEAL) {
        return 'red'
      }
      return this.options ? this.options.buttonColor : ''
    },
    emailTemplateUri() {
      return this.$store.getters.emailTemplateLink
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  padding: 2rem;
  .v-btn {
    &.red:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
    &.green:focus {
      box-shadow: 0 0 0 4px #ccefce;
    }
    &.confirmButton {
      color: #ffffff;
    }
  }
  .v-text-field.v-textarea {
    margin-left: 0;
  }
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
}

.body {
  margin: 3.5rem 0;
}

.close-button {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: none;
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
}

::v-deep .email-checkbox-container {
  background-color: $ads-light-10;
  border-radius: 4px;
  .theme--light.v-label {
    color: $ads-dark;
  }
}
</style>
