<!--
    Adds a hidden form for the purpose of posting to 3rd party apps and rendering the 
    response in a new tab. Currently used for opening ERN family tree in a new tab.

    Params:
      url: URL of 3rd party app
      payload: Optional json payload to be posted
-->

<template>
  <form
    id="hiddenForm"
    ref="hiddenForm"
    method="post"
    :action="params.url"
    target="_blank"
    style="display: none"
  >
    <input
      v-for="(item, index) in getPayloadItems"
      :key="index"
      type="hidden"
      :name="item.name"
      :value="item.value"
    />
  </form>
</template>

<script>
export default {
  name: 'AppPostForm',
  computed: {
    params() {
      return this.$store.state.postForm
    },
    getPayloadItems() {
      var payload = this.params.payload
      var items = []
      Object.keys(payload).forEach((key) => {
        items.push({ name: key, value: payload[key] })
      })
      return items
    }
  },
  mounted() {
    this.$refs.hiddenForm.submit()
    this.$store.dispatch('set', ['postForm', null])
  }
}
</script>

<style lang="scss"></style>
