<template>
  <v-btn
    icon
    class="deleteButton"
    v-bind="$attrs"
    v-on="$listeners"
    :aria-label="ariaLabel"
  >
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    ariaLabel: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.deleteButton {
  position: absolute;
  top: 10px;
  right: 12px;
  border: none;
  color: $grey-darken1 !important;
}
.deleteButton.v-btn--icon.v-size--default {
  width: 32px;
  height: 32px;
}
.deleteButton.theme--light.v-btn.v-btn--flat.v-btn--icon.v-btn--round:focus {
  color: $color-primary !important;
  border: 3px solid $color-primary;
}
</style>
