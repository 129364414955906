<template>
  <div class="ApplicationFilter" v-if="items && value">
    <v-avatar :size="selectedFilter.width">
      <v-icon :size="selectedFilter.width" color="white">
        {{ selectedFilter.icon }}
      </v-icon>
    </v-avatar>
    <v-select
      v-model="selectedFilter"
      @change="filterChanged"
      :items="items"
      return-object
      :menu-props="{
        maxWidth: 300,
        bottom: true,
        offsetY: true,
        maxHeight: 500
      }"
      class="custom"
      persistent-placeholder
    >
      <template #selection="{ item }">
        <div>
          <div class="text-selection-title primary--text">
            <h1>{{ item.text }}</h1>
          </div>
          <div class="text-selection-subtitle pt-3">
            <span>{{ item.label }}</span>
          </div>
        </div>
      </template>

      <template #item="{ item }">
        <div class="application-status">
          <v-icon size="22" left>{{ item.icon }}</v-icon>
          {{ item.text }}
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'ApplicationFilter',
  props: {
    value: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedFilter: this.items[0]
    }
  },
  watch: {
    value() {
      this.selectedFilter =
        this.items.find((i) => i.value === this.value) || this.items[0] || {}
      document.title = this.selectedFilter.text + ' | Online Enrolment System'
    }
  },
  methods: {
    filterChanged(newValue) {
      this.$emit('input', newValue.value)
      this.$gtm?.trackView(
        this.selectedFilter.text + ' | Online Enrolment System',
        '/'
      )
    }
  },
  created() {
    document.title = this.selectedFilter.text + ' | Online Enrolment System'
  },
  mounted() {
    this.$eventHub.$on('changeSelectedStream', this.filterChanged)
  }
}
</script>

<style scoped lang="scss">
.ApplicationFilter {
  display: flex;

  .v-avatar {
    overflow: hidden;
  }
}

::v-deep .custom {
  &.v-text-field {
    margin-left: 1rem;
    padding-top: 0;
  }

  &.v-text-field > .v-input__control > .v-input__slot {
    &::before,
    &::after {
      border-style: none;
    }
  }

  &.v-input--is-focused {
    &.v-text-field > .v-input__control > .v-input__slot {
      i.v-icon {
        border-radius: 50%;
        background-color: $grey-4;
      }
    }
  }

  .v-select__selections .text-selection-title h1 {
    font-size: 2rem;
    line-height: 1;
    color: white;
  }

  .v-select__selections .text-selection-subtitle {
    color: white !important;
  }

  .v-input__append-inner .v-icon {
    color: white;
  }
}
</style>
