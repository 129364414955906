<template>
  <div class="d-flex align-center">
    <div
      class="criteria__number-icon d-flex align-center justify-center mr-3"
      v-if="number != null"
    >
      {{ number }}
    </div>
    <div class="criteria__label">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppSettingsCriteriaLabel',
  props: {
    number: {
      type: Number,
      default: null
    },
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.criteria__number-icon {
  border-radius: 50%;
  background: $ads-blue-1;
  color: #ffffff;
  min-width: 28px;
  height: 28px;
  font-weight: 700;
}
.criteria__label {
  font-size: 1rem;
  color: $ads-dark;
}
</style>
