<template>
  <div class="pt-0" ref="reviewEoIDetails">
    <p class="pb-3 page-title">
      Please review your application details below. If you need to make any
      changes, simply click on “<strong>Edit</strong>” next to the details you
      need to change.
    </p>
    <v-expansion-panels v-model="panel" multiple>
      <ReviewExpansionPanel
        data-testid="student-panel"
        title="Student details"
        :is-valid="validStudent !== false"
        @edit="$emit('edit', 1)"
      >
        <StudentView />
      </ReviewExpansionPanel>
      <ReviewExpansionPanel
        data-testid="parent-panel"
        title="Parent/Carer details"
        :is-valid="validParentCarers !== false"
        @edit="$emit('edit', 2)"
      >
        <ParentCarerView />
      </ReviewExpansionPanel>
      <ReviewExpansionPanel
        data-testid="address-panel"
        title="Student’s Residential Address"
        :include-edit-button="false"
      >
        <AddressView />
      </ReviewExpansionPanel>
      <ReviewExpansionPanel
        data-testid="local-school-panel"
        title="High school options"
        :is-valid="validHighSchool !== false && validConsiderations !== false"
        @edit="$emit('edit', 3)"
      >
        <SchoolView />
        <div v-if="includeOOAHighSchoolsPanel">
          <hr class="ml-n7 mr-n3" />
          <OutOfAreaSchoolsView />
          <hr class="ml-n7 mr-n3" />
          <ConsiderationsView />
        </div>
      </ReviewExpansionPanel>
    </v-expansion-panels>
  </div>
</template>

<script>
import ReviewExpansionPanel from '@/components/application/y67t/paperEoI/eoi/Review/ReviewExpansionPanel'
import StudentView from '@/components/application/y67t/paperEoI/eoi/Review/StudentView'
import ParentCarerView from '@/components/application/y67t/paperEoI/eoi/Review/ParentCarerView'
import OutOfAreaSchoolsView from '@/components/application/y67t/paperEoI/eoi/Review/OutOfAreaSchoolsView'
import ConsiderationsView from '@/components/application/y67t/paperEoI/eoi/Review/ConsiderationsView'
import AddressView from '@/components/application/y67t/paperEoI/eoi/Review/AddressView'
import SchoolView from '@/components/application/y67t/paperEoI/eoi/Review/SchoolView'
import { mapGetters } from 'vuex'
import { SCHOOL_CAPS } from '@/constants'

export default {
  name: 'ReviewEOI',
  components: {
    ReviewExpansionPanel,
    AddressView,
    SchoolView,
    StudentView,
    ParentCarerView,
    OutOfAreaSchoolsView,
    ConsiderationsView
  },
  data() {
    return {
      loading: false,
      panel: [],
      displayBtn: true,
      parentCarerCompleting: {}
    }
  },
  computed: {
    ...mapGetters([
      'ooaHighSchools',
      'validStudent',
      'validParentCarers',
      'validHighSchool',
      'validConsiderations',
      'validForm'
    ]),
    includeOOAHighSchoolsPanel() {
      return this.ooaHighSchools && this.ooaHighSchools.length > 0
    },
    ooaHighSchoolsToDisplay() {
      return (
        this.ooaHighSchools &&
        this.ooaHighSchools.reduce(
          (accumulator, school, originalIndex) =>
            this.hasActiveCriteriaData(school) ||
            (school && school.capacity === SCHOOL_CAPS.OVER) ||
            !school.y67t
              ? [...accumulator, { ...school, originalIndex }]
              : accumulator,
          []
        )
      )
    }
  },
  created() {
    const panelCount = 4
    this.panel = Array.from({ length: panelCount }, (_, index) => index)
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0;
  margin: 0;
  height: auto;
}
.page-title {
  font-family: $digitize-eoi-font-family;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
::v-deep .theme--light.v-icon,
::v-deep .theme--light.v-btn.textBtn .v-btn__content,
::v-deep
  .theme--light.v-expansion-panels
  .v-expansion-panel-header
  .v-expansion-panel-header__icon
  .v-icon,
h2,
.navy {
  color: $ads-navy;
}
::v-deep .theme--light.v-btn.textBtn {
  padding: 0;
}
.v-expansion-panel-header {
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
}
.v-expansion-panel-header.v-expansion-panel-header--active {
  background-color: $ads-light-10;
}
::v-deep .v-expansion-panel-content__wrap {
  padding: 24px;
}
.editBtn {
  font-size: 16px;
  display: none;
  font-weight: bold;
}
::v-deep .editBtn .v-btn__content {
  font-size: 16px;
  color: $ads-navy;
}
::v-deep .v-expansion-panel.v-expansion-panel--active.v-item--active .editBtn {
  display: inline-block;
}
.selectMenu {
  width: 60%;
}
.sectionError,
::v-deep .theme--light.v-icon.sectionError {
  color: $ads-error-red;
  font-weight: bold;
}
::v-deep .v-expansion-panel--active + .v-expansion-panel,
::v-deep .v-expansion-panel--active:not(:first-child) {
  margin-top: 0;
}

// Override Vuetify styling that removes borders and corners between panels
.ReviewExpansionPanel.v-expansion-panel {
  &:not(:last-child) {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:not(:first-child) {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  &::after {
    opacity: 1 !important;
  }
}
hr,
::v-deep hr {
  border-top: thin solid $ads-light-20;
}
</style>
