<template>
  <div>
    <v-card class="rounded-0">
      <div id="archive-filters">
        <section>
          <v-form
            ref="formSection1"
            :disabled="searchStatus === SEARCH_STATUS.WORKING"
          >
            <v-select
              class="select-application-type"
              data-testid="select-application-type"
              item-text="label"
              item-value="type"
              :items="archiveTypes"
              outlined
              placeholder="Select application type"
              label="Application type *"
              persistent-placeholder
              v-model="archiveType"
              :rules="[validators.required]"
            />
          </v-form>
        </section>
        <section>
          <v-form
            ref="formSection2"
            :data-show-select-one-validation="showSelectOneValidation"
            :disabled="!archiveType || searchStatus === SEARCH_STATUS.WORKING"
          >
            <div>
              <DateRangeInput
                ref="receivedDate"
                :disabled="
                  !archiveType || searchStatus === SEARCH_STATUS.WORKING
                "
                class="select-received-date"
                data-testid="select-received-date"
                v-model="dateReceived"
                label="Received date"
                placeholder="Select received date"
                @menuClosed="checkSelectOneValidation"
              />
            </div>
            <div>
              <v-select
                class="select-scholastic-years v-text-field--single-line"
                data-testid="select-scholastic-years"
                placeholder="Select scholastic year(s)"
                label="Scholastic year"
                persistent-placeholder
                item-text="label"
                item-value="value"
                :items="availableScholasticYears"
                v-model="scholasticYear"
                multiple
                deletable-chips
                chips
                outlined
                @blur="checkSelectOneValidation"
                @input="checkSelectOneValidation"
              >
                <template #selection="{ item, index }">
                  <v-chip
                    v-if="index === 0"
                    color="primary"
                    :disabled="
                      !archiveType || searchStatus === SEARCH_STATUS.WORKING
                    "
                    :key="item.value"
                    :text="item.label"
                    close
                    outlined
                    @click:close="scholasticYear.splice(index, 1)"
                  >
                    <span>{{ item.label }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="v-select-others">
                    (+{{ scholasticYear.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
            <div>
              <DateRangeInput
                ref="startDate"
                :disabled="
                  !archiveType || searchStatus === SEARCH_STATUS.WORKING
                "
                class="select-start-date"
                data-testid="select-start-date"
                v-model="intendedStartDate"
                placeholder="Select start date"
                label="Start date"
                @menuClosed="checkSelectOneValidation"
              />
            </div>
            <div>
              <AdsTextField
                v-model="firstName"
                class="will-error"
                data-testid="first-name"
                placeholder="Search student's first name"
                label="Student's first name"
                persistent-placeholder
                prepend-inner-icon="search"
                :validate-on-blur="true"
                :rules="[validators.name, validators.minLength(firstName, 2)]"
                @blur="checkSelectOneValidation"
                @input="checkSelectOneValidation"
              />
            </div>
            <div>
              <AdsTextField
                v-model="familyName"
                class="will-error"
                data-testid="last-name"
                placeholder="Search student's last name"
                label="Student's last name"
                persistent-placeholder
                prepend-inner-icon="search"
                :validate-on-blur="true"
                :rules="[validators.name, validators.minLength(familyName, 2)]"
                @blur="checkSelectOneValidation"
                @input="checkSelectOneValidation"
              />
            </div>
            <div>
              <OesDatePicker
                v-model="dateOfBirth"
                class="date-of-birth will-error"
                data-testid="date-of-birth"
                placeholder="Select date of birth"
                label="Date of birth"
                persistent-placeholder
                prepend-inner-icon="date_range"
                :allow-future="false"
                @blur="checkSelectOneValidation"
                @input="checkSelectOneValidation"
              />
            </div>
            <div id="archive-filters__footer">
              <div class="validation-one-selection">
                <span class="validation-one-selection__bar"></span>
                <v-icon>mdi-alert-circle</v-icon> At least one selection is
                required
              </div>
              <div class="archive-filters__actions">
                <AdsButton
                  class="archive-filters__clear"
                  data-testid="archive-filters__clear"
                  v-if="anyFiltersSet"
                  :tertiary="true"
                  button-text="Clear"
                  icon="clear"
                  @click="handleReset"
                  :disabled="searchStatus === SEARCH_STATUS.WORKING"
                />
                <AdsButton
                  class="archive-filters__search"
                  data-testid="archive-filters__search"
                  button-text="Search"
                  icon="search"
                  :disabled="isSearchButtonDisabled()"
                  @click="handleSearch"
                />
              </div>
            </div>
          </v-form>
        </section>
      </div>
    </v-card>
    <div
      class="total-results-found"
      :data-show="searchStatus === SEARCH_STATUS.OK"
    >
      {{ searchResults.length }} result{{
        searchResults.length === 1 ? '' : 's'
      }}
      found
    </div>
    <AdsDataTable
      :headers="tableHeaders"
      :items="searchResults"
      :options="{
        itemsPerPage: 50
      }"
      :footer-props="{
        'items-per-page-options': [10, 15, 50]
      }"
      sort-by="fullName"
      must-sort
      @current-items="currentFilteredItems = $event"
    >
      <!-- NOTE: This empty template is required to remove the search bar -->
      <template #top></template>
      <template #no-data>
        <div
          class="no-data-message no-data-message--start"
          v-if="searchStatus === SEARCH_STATUS.READY"
        >
          <v-icon>search</v-icon>
          <p>Specify your search criteria to see results</p>
        </div>
        <div
          class="no-data-message no-data-message--error"
          v-if="searchStatus === SEARCH_STATUS.ERROR"
        >
          <v-icon>mdi-exclamation-thick</v-icon>
          <p>Unable to retrieve archived applications. Please try again.</p>
          <div>
            <AdsButton
              class="archive-filters__search"
              data-testid="archive-filters__search"
              button-text="Refresh"
              icon="refresh"
              @click="handleSearch"
            />
          </div>
        </div>
        <div
          class="no-data-message no-data-message--loading"
          v-if="searchStatus === SEARCH_STATUS.WORKING"
        >
          <font-awesome-icon
            focusable="false"
            class="icon"
            icon="circle-notch"
          />
          <p>Loading. This may take up to 30 seconds.</p>
        </div>
        <div
          class="no-data-message no-data-message--noresults"
          v-if="searchStatus === SEARCH_STATUS.OK"
        >
          <p>
            No results found. Try removing some filters or clearing the keyword
            search.
          </p>
        </div>
      </template>
      <template slot="item" slot-scope="{ item }">
        <tr class="grey--text text--lighten-1" @click="openApplication(item)">
          <td class="text-xs-right">
            {{ formattedDate(item[API_RESPONSE.DATE_RECEIVED]) }}
          </td>
          <td class="text-xs-right">
            <router-link
              :to="{
                path: getRouterLinkPath(
                  selectedSchool.schoolCode,
                  item[API_RESPONSE.ID],
                  item[API_RESPONSE.TYPE],
                  item[API_RESPONSE.IS_PRIMARY]
                )
              }"
              :aria-label="`${
                item[API_RESPONSE.FULL_NAME]
              }. Open application details.`"
            >
              {{ item[API_RESPONSE.FULL_NAME] }}
            </router-link>
          </td>
          <td class="text-xs-right">
            {{ formattedDate(item[API_RESPONSE.DATE_OF_BIRTH]) }}
          </td>
          <td class="text-xs-right">
            {{ getArchiveTypeLabel(item[API_RESPONSE.TYPE]) }}
          </td>
          <td class="text-sm-center text-md-left">
            <ScholasticYearCell :year="item[API_RESPONSE.SCHOLASTIC_YEAR]" />
          </td>
          <td class="text-xs-right">
            {{ formattedDate(item[API_RESPONSE.DATE_START]) }}
          </td>
          <td class="text-xs-right">
            <Chip
              class="text-center font-weight-bold"
              :aria-label="
                (item[API_RESPONSE.UPDATE_STATUS] || '').toLowerCase()
              "
              v-bind="
                getStatusChip(
                  item[API_RESPONSE.TYPE],
                  item[API_RESPONSE.UPDATE_STATUS]
                )
              "
            />
          </td>
        </tr>
      </template>
    </AdsDataTable>
  </div>
</template>

<script>
import {
  AdsButton,
  AdsTextField,
  AdsDataTable,
  Chip
} from '@nswdoe/doe-ui-core'
import DateRangeInput from '@/components/app/DateRangeInput.vue'
import OesDatePicker from '@/components/app/OesDatePicker.vue'
import ScholasticYearCell from '@/components/app/ScholasticYear.vue'
import { ARCHIVE_TYPES } from '@/constants'
import validators from '@/helpers/validators'
import settingsutils from '@/helpers/settingsUtils'
import { mapGetters } from 'vuex'
import { API_RESPONSE, SEARCH_STATUS } from '../../store/modules/archiving'
import moment from 'moment'

const tableHeaders = [
  {
    text: 'Received',
    value: API_RESPONSE.DATE_RECEIVED,
    align: 'start',
    class: ''
  },
  {
    text: 'Student name',
    value: API_RESPONSE.FULL_NAME,
    align: 'start'
  },
  {
    text: 'Date of birth',
    value: API_RESPONSE.DATE_OF_BIRTH,
    align: 'left'
  },
  {
    text: 'Application type',
    value: API_RESPONSE.TYPE,
    align: 'start'
  },
  {
    text: 'Scholastic year',
    value: API_RESPONSE.SCHOLASTIC_YEAR,
    align: '',
    sort: (a, b) =>
      (a.toUpperCase() === 'K' ? 0 : Number.parseInt(a)) -
      (b.toUpperCase() === 'K' ? 0 : Number.parseInt(b))
  },
  {
    text: 'Start date',
    value: API_RESPONSE.DATE_START,
    align: ''
  },
  {
    text: 'Status',
    value: API_RESPONSE.UPDATE_STATUS,
    class: '',
    align: 'start',
    width: '175'
  }
]

export function isFilterTruthy(obj, key) {
  const value = obj[key]

  if (!value) {
    return false
  }

  if (Array.isArray(value) && value.length === 0) {
    return false
  }

  if (Array.isArray(value) && value.length > 0) {
    for (let i = 0; i < value.length; ++i) {
      if (value[i]) {
        return true
      }
    }
    return false
  }

  return true
}

export function getAppliedFilters(propsToCheck, obj) {
  return propsToCheck.reduce((newObj, key) => {
    if (obj[key] !== undefined && isFilterTruthy(obj, key)) {
      newObj[key] = obj[key]
    }
    return newObj
  }, {})
}

export default {
  name: 'ArchiveView',
  components: {
    AdsButton,
    AdsTextField,
    OesDatePicker,
    AdsDataTable,
    Chip,
    DateRangeInput,
    ScholasticYearCell
  },
  data() {
    return {
      // Begin form field models
      // NOTE: Properties bound to form fields (that relate directly to the API
      // request/response schema) must exactly match an expected field. See the
      // API_REPSONSE object in store/archiving.js for the full list.
      dateReceived: [null, null],
      intendedStartDate: [null, null],
      dateOfBirth: null,
      firstName: null,
      familyName: null,
      scholasticYear: [],
      // End form field models
      currentFilteredItems: [],
      showSelectOneValidation: false,
      validators,
      API_RESPONSE,
      SEARCH_STATUS,
      tableHeaders
    }
  },
  computed: {
    ...mapGetters(['selectedSchool']),
    ...mapGetters({
      searchStatus: 'archiving/searchStatus',
      searchResults: 'archiving/searchResults'
    }),
    availableScholasticYears() {
      const catchmentLevel = this.$store.state.selectedSchool.catchmentLevel
      return settingsutils.getScholasticYears(catchmentLevel)
    },
    filters() {
      return getAppliedFilters(Object.values(API_RESPONSE), this)
    },
    anyFiltersSet() {
      return Object.keys(this.filters).length > 0
    },
    archiveType: {
      get() {
        return this.$store.state.archiving.selectedType
      },
      set(value) {
        this.$store.commit('archiving/setType', value)
      }
    },
    searchFormIsValid() {
      return this.anyFiltersSet && this.isFormValid()
    },
    archiveTypes() {
      const { selectedSchool } = this.$store.getters
      return Object.values(ARCHIVE_TYPES).filter((t) =>
        t.catchmentLevels.includes(selectedSchool.catchmentLevel)
      )
    }
  },
  methods: {
    openApplication(item) {
      this.resetFilteredList()
      const { selectedSchool } = this.$store.getters
      const path = this.getRouterLinkPath(
        selectedSchool.schoolCode,
        item[API_RESPONSE.ID],
        item[API_RESPONSE.TYPE],
        item[API_RESPONSE.IS_PRIMARY]
      )
      this.$router.push({ path }).catch(() => {})
    },
    resetFilteredList() {
      const { selectedSchool } = this.$store.getters
      const currentFilteredItems = this.currentFilteredItems.map((item) =>
        this.getRouterLinkPath(
          selectedSchool.schoolCode,
          item[API_RESPONSE.ID],
          item[API_RESPONSE.TYPE],
          item[API_RESPONSE.IS_PRIMARY]
        )
      )
      this.$store.commit(
        'archiving/setFilteredArchiveRecords',
        currentFilteredItems
      )
    },
    getRouterLinkPath(schoolCode, itemId, itemType, isPrimary) {
      return `/archive/${schoolCode}/${itemId}/${itemType}${
        isPrimary === 'true' ? '/isPrimary' : ''
      }`
    },
    getStatusChip(archiveType, status) {
      if (!ARCHIVE_TYPES[archiveType].statusChips[status]) {
        return {
          text: status
        }
      }

      return ARCHIVE_TYPES[archiveType].statusChips[status]
    },
    getArchiveTypeLabel(archiveType) {
      if (!Object.keys(ARCHIVE_TYPES).includes(archiveType)) {
        // archive type not found
        return '-'
      }
      return ARCHIVE_TYPES[archiveType].columnLabel
    },
    isSearchButtonDisabled() {
      return (
        !this.archiveType ||
        !this.searchFormIsValid ||
        this.searchStatus === SEARCH_STATUS.WORKING
      )
    },
    isFormValid() {
      if (!this.$refs.formSection2 || !this.$refs.formSection2.errorBag) {
        return true
      }
      return !Object.values(this.$refs.formSection2.errorBag).includes(true)
    },
    resetSelectOneValidation() {
      this.showSelectOneValidation = false
    },
    checkSelectOneValidation() {
      this.showSelectOneValidation = !this.anyFiltersSet
    },
    async handleReset() {
      this.$refs.formSection2.reset()
      this.$refs.receivedDate.reset()
      this.$refs.startDate.reset()
      await this.$store.dispatch('archiving/resetSearch')
      this.$refs.formSection1.resetValidation()
      this.resetSelectOneValidation()
    },
    async handleSearch() {
      await this.$store.dispatch('archiving/search', this.filters)
    },
    formattedDate(dateValue) {
      const momentDate = moment(dateValue, 'YYYY-MM-DD')
      if (momentDate.isValid()) {
        return momentDate.format('DD MMM YYYY')
      }
      return '-'
    }
  },
  mounted() {
    this.resetSelectOneValidation()
  },
  watch: {
    selectedSchool() {
      this.handleReset()
    },
    scholasticYear() {
      this.checkSelectOneValidation()
    }
  }
}
</script>

<style lang="scss" scoped>
#archive-filters {
  $section-padding: 2.5rem;

  padding: 0 0.5rem;
  display: grid;
  grid-template-columns:
    calc(300px + #{$section-padding * 2})
    auto;
  grid-template-rows: 1fr;
  max-width: 1440px;

  & > section {
    padding: 2.25rem 2.5rem 1.875rem;
  }

  & > section:last-of-type {
    border-left: 1px solid $ads-grey-03;

    & form {
      position: relative;
      display: grid;
      grid-template-rows: repeat(3, max-content);
      grid-template-columns: repeat(3, 1fr);
      grid-template-areas:
        '. . .'
        '. . .'
        'footer footer footer';
      column-gap: 1rem;

      & #archive-filters__footer {
        grid-area: footer;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .validation-one-selection {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    color: $color-error-dark;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    height: max-content;

    ::v-deep .v-icon {
      color: $color-error-dark;
    }

    &__bar {
      position: absolute;
      top: -4px;
      left: -22px;
      bottom: 30px;
      background: $color-error-dark;
      width: 5px;
    }
  }

  form[data-show-select-one-validation] .validation-one-selection {
    opacity: 1;
  }

  & .archive-filters__actions {
    display: flex;
    gap: 1rem;

    & .archive-filters__clear,
    & .archive-filters__search {
      margin: 0 !important;
      border-width: 2px !important;
      padding: 8px 16px !important;
    }
  }

  & ::v-deep .theme--light.v-messages {
    font-size: 1rem !important;
  }

  & .will-error ::v-deep .theme--light.v-messages {
    min-height: 2.25rem;
  }
}
.v-data-table {
  margin: 1rem 3rem 3rem;
}

.total-results-found {
  visibility: hidden;
  margin: 1.5rem 3rem 1rem;
  font-weight: 700;

  &[data-show] {
    visibility: visible;
  }
}

::v-deep {
  .v-select:not(.ads-select) {
    & .v-input__control input[readonly] {
      position: unset !important;
      top: unset !important;
    }
    & .v-select-others {
      color: #8c9399;
    }
  }
  .v-chip.v-chip--outlined.v-chip.v-chip {
    background-color: $ads-primary-teal !important;
    color: $ads-primary-blue !important;
    border: 1px solid $ads-primary-blue;
  }
}
.v-list.v-select-list ::v-deep .v-list-item.v-list-item--active {
  background-color: #cbedfd !important;

  & .v-list-item__content {
    color: #002664 !important;
  }
}

::v-deep tr.v-data-table__empty-wrapper > td {
  height: 24rem !important;
}

.no-data-message {
  font-size: 1rem;
  color: $ads-grey-01;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  & p {
    margin: 0;
  }

  &--start {
    & .v-icon {
      color: $ads-primary-blue;
      background-color: $ads-primary-teal;
      border-radius: 100%;
      font-size: 37px;
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  &--error {
    .mdi-exclamation-thick {
      background-color: $ads-secondary-red;
      border-radius: 100%;
      padding: 1rem;

      &::before {
        background: $ads-primary-red;
        color: $ads-secondary-red;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 37px;
      }
    }
  }

  &--loading {
    & svg {
      color: $ads-secondary-blue;
      width: 3.5rem;
      height: 3.5rem;
      margin-top: 1rem;
      animation: spin 1.25s infinite linear;
      @keyframes spin {
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}

::v-deep .v-data-table.v-data-table {
  & thead th {
    padding-top: 20px;
    padding-bottom: 20px;
    vertical-align: top;

    & button {
      height: initial !important;
    }
  }
  & tbody tr td {
    font-size: 0.875rem;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

::v-deep .v-chip {
  display: block;
  height: 23px;

  & .v-chip__content {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
  }
}

::v-deep .theme--light.v-input--is-disabled input::placeholder,
::v-deep .theme--light.v-input--is-disabled textarea::placeholder {
  color: #8c9399 !important;
}

::v-deep .v-data-table > .v-data-table__wrapper > table {
  & > thead,
  & > tbody,
  & > tfoot {
    & > tr {
      & > td,
      & > th {
        transition: none;
      }
    }
  }
}
</style>
