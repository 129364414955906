<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <hr class="ml-n7 mr-n3" />
    <p class="section-title mt-4">Application origin</p>
    <v-row>
      <ReviewField
        sm="10"
        label="How was this application delivered by the parent/carer?"
        :value="paperOrVerbal"
      />
    </v-row>
    <hr class="ml-n7 mr-n3" />
    <v-row>
      <ReviewField
        data-testid="firstName"
        label="First name"
        :value="student.firstName"
      />
      <ReviewField
        data-testid="middleName"
        label="Middle name"
        :value="student.middleName"
      />
    </v-row>
    <v-row>
      <ReviewField
        data-testid="familyName"
        label="Family name"
        :value="student.familyName"
      />
      <ReviewField
        data-testid="preferredName"
        label="Preferred name"
        :value="student.preferredName"
      />
    </v-row>
    <v-row>
      <ReviewField
        data-testid="dateOfBirth"
        label="Date of birth"
        :value="student.dateOfBirth"
      />
      <ReviewField data-testid="gender" label="Gender" :value="gender" />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { GENDER_OPTIONS } from '@/constants'
import ReviewField from '@/components/application/y67t/paperEoI/eoi/Review/ReviewField'

export default {
  name: 'StudentView',
  components: {
    ReviewField
  },
  computed: {
    ...mapGetters(['eoiApplication']),
    student() {
      return this.eoiApplication.student
    },
    gender() {
      const gender = GENDER_OPTIONS.find(
        ({ value }) => value === this.student.genderCode
      )
      return gender ? gender.label : ''
    },
    paperOrVerbal() {
      return this.eoiApplication.isVerbalAdvice === true
        ? 'Verbal advice'
        : this.eoiApplication.isPaperEOI === true
        ? 'Paper form'
        : '(empty)'
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: $digitize-eoi-font-family;
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
  line-height: 26px;
}
</style>
