<template>
  <div class="error-box">
    <div v-if="icon" class="error-box__icon-wrapper">
      <slot name="icon">
        <v-icon class="error-box__icon" v-bind="iconAttrs">
          {{ icon }}
        </v-icon>
      </slot>
    </div>
    <div class="error-box__content">
      <div class="error-box__header">
        <slot name="heading">
          <h3 v-if="heading" class="error-box__title">
            {{ heading }}
          </h3>
        </slot>
        <slot name="action-button">
          <v-btn
            v-if="includeActionButton"
            class="btn-link error-box__action-button"
            text
            @click="$emit('click')"
          >
            <v-icon class="action-button__icon">
              {{ actionButtonIcon }}
            </v-icon>
            {{ actionButtonText }}
          </v-btn>
        </slot>
      </div>
      <p class="error-box__description">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorBox',
  props: {
    icon: {
      type: String,
      default: 'mdi-alert-circle-outline'
    },
    iconAttrs: {
      type: Object,
      default: () => {}
    },
    heading: {
      type: String
    },
    includeActionButton: {
      type: Boolean,
      default: false
    },
    actionButtonIcon: {
      type: String,
      default: 'mdi-help-circle-outline'
    },
    actionButtonText: {
      type: String,
      default: 'more info'
    }
  }
}
</script>

<style lang="scss" scoped>
.error-box {
  display: flex;
  align-items: center;
  background-color: $grey-7;
  padding: 1.25rem 2rem;

  p:last-child {
    margin-bottom: 0;
  }

  &__icon {
    font-size: 3.5rem;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
    background-color: $ads-light-blue;
    border-radius: 50%;
    justify-content: center;
    height: 5rem;
    width: 5rem;
  }

  &__content {
    flex: 1;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1rem;
    color: $ads-navy;
    font-weight: bold;
  }

  &__action-button {
    font-weight: 500 !important;
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}

.action-button__icon {
  display: inline;
  font-size: 1.5rem;
  text-decoration: none;
  margin-right: 0.5rem;
}
</style>
