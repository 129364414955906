var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.application)?_c('div',{staticClass:"ApplicationAlerts"},[_c('h2',[_vm._v(" Actions "+_vm._s(!_vm.isWithdrawn && !_vm.application.ooa && !_vm.isInvalid && !_vm.isInternalTransfer && _vm.totalAlerts ? `(${_vm.totalAlerts})` : '')+" ")]),_c('div',{staticClass:"alerts"},[(
        !_vm.isWithdrawn && !_vm.isInvalid && !_vm.isInternalTransfer && !_vm.application.ooa
      )?[((_vm.totalAlerts === 0 || _vm.isSentToErn) && !_vm.hideSendToErn)?_c('div',{staticClass:"successAlert"},[_c('font-awesome-icon',{staticClass:"tickCircle",attrs:{"focusable":"false","icon":"check"}}),(_vm.isSentToErn)?_c('span',[_vm._v("Sent to ERN")]):_c('span',[_vm._v(" Ready to send to ERN ")])],1):_vm._e(),(_vm.isCheckForSrn)?_c('div',[_c('h3',[_vm._v("SRN Check")]),_c('Alert',{attrs:{"is-check-for-srn-alert":true}})],1):_vm._e(),(
          _vm.missingFields.length ||
          _vm.conflictFields.length ||
          _vm.unresolvedMatching.length ||
          _vm.invalidFields.length
        )?_c('div',[_c('h3'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-icon',{staticClass:"error--text",attrs:{"data-testid":"resolve-issue-icon"}},[_vm._v(" error ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"10","data-testid":"resolve-issue-error"}},[_vm._v(" Resolve remaining issues before sending to ERN ")])],1)],1):_vm._e()]:(
        !(
          _vm.isWithdrawn ||
          _vm.isInvalid ||
          _vm.isInternalTransfer ||
          _vm.isDeclined ||
          _vm.isAccepted
        )
      )?[_c('div',{staticClass:"infoAlert"},[_c('font-awesome-icon',{staticClass:"exclamationCircle",attrs:{"focusable":"false","icon":"exclamation-circle"}}),_c('span',[_vm._v("Assess criteria and assign appropriate status")])],1)]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }