<template>
  <div class="px-8 pt-4 pb-2">
    <no-details
      v-if="
        (!considerations || !considerations.length) &&
        !application.otherConsiderations
      "
      detail-name="Consideration"
    />
    <div class="downloadBtn">
      <AdsButton
        v-if="isDisplayDownloadAll() && isOesAdmin"
        secondary
        button-text="Download all"
        icon="mdi-folder-multiple-outline"
        @click="showDownloadModal = true"
      />
      <DownloadDocumentsModal
        v-if="showDownloadModal"
        :applications="applicationInfo"
        :school-code="application.schoolCode"
        :skip-download-app="true"
        :application-type="APPLICATION_TYPE.OUT_OF_AREA"
        @close="showDownloadModal = false"
      />
    </div>
    <v-card
      class="mb-6 px-4 py-3"
      v-for="(consideration, i) in considerations"
      :key="i"
    >
      <div class="consideration-heading px-0 py-2">
        {{ schoolCriteria(consideration.code) }}
      </div>
      <read-only-field
        v-if="consideration.description"
        label="Details"
        :value="consideration.description"
        readonly
      />

      <div
        v-if="
          supportingDocuments != null &&
          supportingDocuments[consideration.code] != null
        "
        class="px-0 mb-4"
      >
        <label class="supporting-docs-label">Supporting documents</label>
        <AppFileEditor
          v-for="(doc, j) in supportingDocuments[consideration.code]"
          :key="j"
          :label="doc.objectKey"
          :value="doc.objectKey"
          :handle-view="handleView"
          :can-view="isOesAdmin"
          :status="doc.status"
        />
      </div>
    </v-card>
    <v-card class="mb-3 px-4" v-if="application.otherConsiderations">
      <div class="consideration-heading px-0 py-2">Other considerations</div>
      <read-only-field
        v-if="application.otherConsiderations"
        label="Details"
        :value="application.otherConsiderations"
        readonly
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'
import { USER_GROUP, APPLICATION_TYPE, FILE_STATUSES } from '@/constants'

import { AdsButton } from '@nswdoe/doe-ui-core'
import NoDetails from '@/components/application/NoDetails'
import ReadOnlyField from '@/components/form/ReadOnlyField'
import AppFileEditor from '@/components/app/AppFileEditor.vue'
import DownloadDocumentsModal from '@/components/applicationList/DownloadDocumentsModal.vue'

export default {
  name: 'OoaFormConsiderations',
  components: {
    ReadOnlyField,
    NoDetails,
    AppFileEditor,
    AdsButton,
    DownloadDocumentsModal
  },
  data() {
    return {
      validators: {
        required: validators.required
      },
      genderFormatter: formatters.gender,
      dateFormatter: formatters.date,
      displayDate: formatters.displayDate,
      showDownloadModal: false,
      APPLICATION_TYPE
    }
  },
  computed: {
    ...mapGetters(['application']),
    applicationInfo() {
      const app = this.application
      return [
        {
          applicationID: app.applicationID,
          studentFirstName: app.student.firstName,
          studentFamilyName: app.student.familyName,
          supportingDocuments: app.supportingDocuments
        }
      ]
    },
    considerations() {
      return this.application.criteria
    },
    isOesAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    supportingDocuments() {
      let supportingDocuments = this.application.supportingDocuments
      if (supportingDocuments != null) {
        for (const [criteriaKey, docs] of Object.entries(supportingDocuments)) {
          if (docs && docs.length > 0) {
            let safeDocs = []
            let unsafeDocs = []
            for (let i = 0; i < docs.length; i++) {
              const doc = docs[i]
              if (doc.status === 'SAFE') {
                safeDocs.push(doc)
              } else {
                unsafeDocs.push(doc)
              }
            }
            supportingDocuments[criteriaKey] = [...safeDocs, ...unsafeDocs]
          }
        }
        return supportingDocuments
      }
      return {}
    }
  },
  methods: {
    schoolCriteria(code) {
      const schoolSettings = this.$store.getters.defaultSelectionCriteria
      const criteria = schoolSettings.find((item) => item.code === code)
      return criteria.value
    },
    name(sibling) {
      return `${sibling.siblingsFirstName} ${sibling.siblingsOtherName} ${sibling.siblingsFamilyName}`
    },
    handleView(value) {
      this.$store.dispatch('showOrDownloadDocument', {
        filePath: this.application.applicationID + value.filePath,
        previewMode: value.previewMode
      })

      this.$gtm.trackEvent({
        category: 'document-view',
        action: 'Considerations',
        label: 'Medical Reasons'
      })
    },
    isDisplayDownloadAll() {
      const documents = this.supportingDocuments
      if (!documents) {
        return false
      }
      if (Array.isArray(documents)) {
        return documents.length > 1
      }

      return (
        documents?.MED?.filter((doc) => doc.status === FILE_STATUSES.SAFE)
          .length > 1
      )
    }
  }
}
</script>
<style scoped lang="scss">
.consideration-heading {
  font-weight: bold;
}
.supporting-docs-label {
  cursor: not-allowed;
  color: $color-text-light;
  font-size: 0.9em;
}
.safe {
  background: $grey-light-10;
  border-radius: 4px;
  padding: 15px 0;
}
.downloadBtn {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 12px;
}
</style>
