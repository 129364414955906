var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-6 grey lighten-6"},[_c('v-row',{staticClass:"pb-3"},[_c('v-col',{staticClass:"pt-4"},[_c('span',{staticClass:"filterLabel"},[_vm._v("Filter by:")]),_c('span',{staticClass:"pl-3"},[_c('DateRangeFilter',{ref:"processedDateFilter",attrs:{"label":"Processed date","heading":"Show requests","archive-type":_vm.archiveType,"filter-options":_vm.dateRangeFilter},on:{"updatefilter":_vm.updateProcessedDateFilter,"menuOpened":_vm.sendFilterAnalytics}})],1),_c('span',{staticClass:"pl-3"},[_c('DateRangeFilter',{ref:"receivedDateFilter",attrs:{"label":"Received date","heading":"Show requests","archive-type":_vm.archiveType,"filter-options":_vm.dateRangeFilter},on:{"updatefilter":_vm.updateReceivedDateFilter,"menuOpened":_vm.sendFilterAnalytics}})],1),_c('span',{staticClass:"pl-3"},[_c('ListViewFilter',{ref:"scholasticYearFilter",attrs:{"label":"Scholastic year","type":"checkbox","filter-options":_vm.scholasticYearFilters},on:{"updatefilter":_vm.updateScholasticYearFilter,"menuOpened":_vm.sendFilterAnalytics}})],1),(_vm.isFiltered)?_c('ClearFilters',{on:{"click":function($event){return _vm.clearAllFilters()}}}):_vm._e()],1)],1),_c('v-card',[_c('AdsDataTable',{attrs:{"headers":_vm.headers,"items":_vm.cUpdates,"search-label":"Find student","search":_vm.internalSearch,"sort-by":"dateProcessed","sort-desc":"","must-sort":"","items-per-page":_vm.pagination.itemsPerPage,"footer-props":{
        'items-per-page-options': [10, 15, 50, -1]
      },"custom-filter":_vm.filterUpdates,"page":_vm.currentPage,"data-testid":"updatesProcessedList"},on:{"pagination":_vm.totalResults,"current-items":function($event){_vm.currentFilteredItems = $event},"update:page":function($event){_vm.currentPage=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('NoResultsFound',{attrs:{"archive-type":_vm.archiveType,"filtered":_vm.isFiltered || Boolean(_vm.internalSearch)}})]},proxy:true},{key:"top",fn:function(){return [_c('v-row',{staticClass:"row-line"},[_c('v-col',[_c('v-text-field',{staticClass:"searchInput",attrs:{"prepend-inner-icon":"mdi-magnify","label":"Find student","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.internalSearch),callback:function ($$v) {_vm.internalSearch=$$v},expression:"internalSearch"}})],1),_c('v-col',{staticClass:"results-text d-flex align-end pr-5",attrs:{"cols":"auto"}},[_c('span',[_vm._v(" "+_vm._s(_vm.resultsText(_vm.pagination.itemsLength))+" ")])])],1)]},proxy:true},{key:"item",fn:function(props){return [_c('tr',{staticClass:"grey--text text--lighten-1",on:{"click":function($event){return _vm.openUpdate(props.item.id)}}},[_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.item[_vm.DT_PROPERTIES.DATE_PROCESSED],'DD MMM YYYY'))+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.TYPE])+" ")]),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(_vm._f("moment")(props.item[_vm.DT_PROPERTIES.DATE_RECEIVED],'DD MMM YYYY'))+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('router-link',{attrs:{"to":{
                path: _vm.getItemPath(_vm.schoolCode, props.item.id)
              },"aria-label":`${props.item.name}. Open application details.`}},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.NAME])+" ")])],1),_c('td',{staticClass:"text-sm-center text-md-left"},[_c('ScholasticYearCell',{attrs:{"year":props.item[_vm.DT_PROPERTIES.SCHOLASTIC_YEAR]}})],1),_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(props.item[_vm.DT_PROPERTIES.REQUESTED_BY])+" ")]),_c('td',{staticClass:"text-xs-right"},[_c('Chip',_vm._b({staticClass:"text-center font-weight-bold",attrs:{"small":"","aria-label":(props.item[_vm.DT_PROPERTIES.UPDATE_STATUS] || '').toLowerCase()}},'Chip',
                _vm.getStatusChipDetails(props.item[_vm.DT_PROPERTIES.UPDATE_STATUS])
              ,false))],1)])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }