var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'FieldGroup' +
    (_vm.modelRow.isReadOnly ? ' isReadOnly' : '') +
    (_vm.modelRow.isConflict && !_vm.isPrintPreview ? ' isConflict' : ''),attrs:{"data-id":_vm.modelRow.id}},[_c('FieldGroupHeading',{attrs:{"model-row":_vm.modelRow}}),_vm._l((_vm.getChildren(_vm.modelRow)),function(fieldModelRow){return _c('div',{key:fieldModelRow.id,class:'columnLayout' + _vm.getFieldWidthClass(fieldModelRow)},[(fieldModelRow.type == 'LINEBREAK')?_c('br'):(
        _vm.isEditing &&
        !fieldModelRow.isHidden &&
        !fieldModelRow.isReadOnly &&
        !fieldModelRow.isResolved
      )?_c('Field',{attrs:{"model-row":fieldModelRow}}):_c('FieldReadOnly',{attrs:{"model-row":fieldModelRow}})],1)}),(!_vm.isWithdrawn)?_c('FieldAlert',{staticClass:"FieldAlert",attrs:{"model-row":_vm.modelRow}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }