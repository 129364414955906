<template>
  <div>
    <ComparisonSkeleton
      v-if="isLoadingChart"
      title="Comparison per round"
      data-testid="compSkeleton"
    />
    <AdsChart
      v-if="!empty && !isLoadingChart"
      title="Comparison per round"
      type="bar"
      height="500"
      :inverse-order="false"
      :series="columnData"
      :horizontal-value="false"
      :horizontal-bar="false"
      :seg-colours="columnColours"
      :reversed-y="false"
      :x-label-cat="categories"
      :xlabel-show="true"
      data-testid="appChart"
      :key="componentKey"
    />
    <NoEOI
      v-if="!isLoadingChart && empty"
      title="Comparison per round"
      subtitle="No EOIs have been submitted yet for this year."
      msg="Once you start receiving EOIs we will display how many Out-of-area
              and Local applications you have for each deliberation round to
              help you track possible student numbers."
      icon="mdi-chart-line-variant"
      data-testid="noEOIs"
    />
  </div>
</template>
<script>
import { AdsChart } from '@nswdoe/doe-ui-core'
import NoEOI from './NoEOI.vue'
import ComparisonSkeleton from './ComparisonSkeleton.vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  name: 'ComparisonPerRound',
  components: { AdsChart, NoEOI, ComparisonSkeleton },
  data() {
    return {
      columnColours: ['#041E42', '#407EC9'],
      componentKey: 0
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'comparisonsPerRound',
      'isLoadingChart',
      'y67tRoundDates'
    ]),
    categories() {
      return [
        [
          'ROUND 1',
          `${moment(this.y67tRoundDates[0].startDate).format(
            'DD MMMM'
          )} - ${moment(this.y67tRoundDates[0].endDate).format('DD MMMM')}`
        ],
        [
          'ROUND 2',
          `${moment(this.y67tRoundDates[1].startDate).format(
            'DD MMMM'
          )} - ${moment(this.y67tRoundDates[1].endDate).format('DD MMMM')}`
        ],
        [
          'ROUND 3',
          `${moment(this.y67tRoundDates[2].startDate).format(
            'DD MMMM'
          )} - ${moment(this.y67tRoundDates[2].endDate).format('DD MMMM')}`
        ],
        ['ROUND 4', '-']
      ]
    },
    columnData() {
      return [
        {
          name: 'Local',
          data: [
            this.comparisonsPerRound.round1local,
            this.comparisonsPerRound.round2local,
            this.comparisonsPerRound.round3local,
            this.comparisonsPerRound.round4local
          ]
        },
        {
          name: 'Out-of-area',
          data: [
            this.comparisonsPerRound.round1ooa,
            this.comparisonsPerRound.round2ooa,
            this.comparisonsPerRound.round3ooa,
            0
          ]
        }
      ]
    },
    empty() {
      let arr = [...this.columnData[0].data, ...this.columnData[1].data]
      return arr.every((item) => item === 0)
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1
    }
  },
  created() {
    this.$store.dispatch('getComparisonPerRounds')
  },
  watch: {
    comparisonsPerRound() {
      this.forceRerender()
    },
    selectedSchool() {
      this.$store.dispatch('setIsLoadingComparison', true)
      this.$store.dispatch('getComparisonPerRounds')
    }
  }
}
</script>
