<template>
  <div class="ApplicationListView">
    <div v-if="selectedSchool">
      <ApplicationList />
    </div>

    <v-card
      height="100%"
      class="d-flex flex-column align-center justify-center"
      v-else-if="!selectedSchool && isSchoolSupport"
    >
      <v-icon size="300" class="grey--text text--lighten-4">mdi-magnify</v-icon>
      <h2 class="font-weight-bold primary--text heading">
        Welcome to the Online Enrolment System
      </h2>
      <p class="text">
        To begin, please choose the "<span
          class="font-weight-bold primary--text"
          >Search Your Schools</span
        >" menu above
      </p>
      <p class="text">to view enrolments for that school.</p>
    </v-card>
  </div>
</template>

<script>
import ApplicationList from '@/components/applicationList/List.vue'
import { USER_GROUP } from '@/constants'

export default {
  name: 'ApplicationListView',
  components: {
    ApplicationList
  },
  computed: {
    selectedSchool() {
      return this.$store.getters.selectedSchool
    },
    isSchoolSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    }
  }
}
</script>

<style scoped lang="scss">
// Desktop layout shows filters as a left sidebar with
// Search and Application List filling space on the right.
$sideBarWidth: 5rem;

.ApplicationListView {
  height: 100%;
  width: 100%;
}

.v-icon {
  position: absolute;
  font-size: 260px !important;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.heading,
.text {
  z-index: 2;
}

.text {
  margin: 0;
}

.heading {
  margin-bottom: 1rem;
}

@include desktop {
  .ApplicationListView,
  .ApplicationList {
    width: 100%;
    position: absolute;
  }
}
</style>
