<template>
  <div ref="dateField">
    <div v-if="!isEditing">
      <read-only-field
        :label="$attrs.label"
        :value="$attrs.value"
        v-bind="$attrs"
        :formatter="dateFormatter"
        @click="setEditMode($refs.dateField)"
        data-testid="dateFieldReadOnly"
      />
    </div>
    <div v-else>
      <DatePicker
        :date-format="dateFormat"
        :output-date-format="outputDateFormat"
        v-bind="$attrs"
        v-on="$listeners"
        data-testid="dateField"
        persistent-placeholder
      />
    </div>
  </div>
</template>

<script>
import { DatePicker } from '@nswdoe/doe-ui-core'
import toggleFieldMixin from './toggleFieldMixin'
import moment from 'moment'
import { DATE_FORMAT, DATE_DISPLAY_FORMAT } from '@/constants'

export default {
  name: 'DateField',
  mixins: [toggleFieldMixin],
  components: {
    DatePicker
  },
  props: {
    dateFormat: {
      type: String,
      default: DATE_DISPLAY_FORMAT
    },
    outputDateFormat: {
      type: String,
      default: DATE_FORMAT
    }
  },
  methods: {
    dateFormatter(val) {
      const mDate = moment(val, ['MM-YYYY', 'YYYY-MM-DD', 'DD-MM-YYYY'])
      return mDate.format(this.dateFormat)
    }
  }
}
</script>
