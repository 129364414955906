<template>
  <div>
    <div
      class="mt-6 pl-3"
      v-if="application.applicationOwnerHasMultipleApplications"
    >
      <message-with-action
        :message="multipleApplicationsMessageProps"
        data-testid="multipleApplicationsMessage"
      />
    </div>
    <parent-carer
      v-for="(parent, pIndex) in parentCarers"
      :key="`${pIndex}`"
      :parent-carer="parent"
      :rules="[validators.contactRequired]"
      @delete="deleteParentCarer"
      :readonly="readonly"
      :data-testid="`parentCarer${pIndex}`"
    />
    <v-row v-if="isEditing && parentCarers.length === 1">
      <v-col class="pl-8 pt-0">
        <AdsButton
          class="ml-3 mb-3"
          icon="mdi-plus-circle-outline"
          secondary
          button-text="Add another parent/carer"
          @click="addParentCarer()"
        />
      </v-col>
    </v-row>
    <MultipleApplicationsModal
      v-model="showMultiApplicationsModal"
      @close="showMultiApplicationsModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ParentCarer from './ParentCarer.vue'
import MessageWithAction from '@/components/app/MessageWithAction'
import MultipleApplicationsModal from '@/components/application/ooa/MultipleApplicationsModal'
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'ParentCarers',
  components: {
    ParentCarer,
    MessageWithAction,
    MultipleApplicationsModal,
    AdsButton
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showMultiApplicationsModal: false,
      validators: {
        contactRequired: (parentCarer) => {
          return (
            !!(
              parentCarer.contactDetails && parentCarer.contactDetails.length
            ) || 'Contact details required'
          )
        }
      }
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing', 'ooaApplicationOwner']),
    parentCarers() {
      return this.application.parentCarers
    },
    multipleApplicationsMessageProps() {
      return {
        icon: 'mdi-account-multiple-outline',
        iconColor: 'orange darken-1',
        body: () =>
          `Parent/carer ${this.ooaApplicationOwner.parentCarerGivenName} ${this.ooaApplicationOwner.parentCarerFamilyName} has made multiple out-of-area applications.`,
        action: {
          text: () =>
            `View applications by ${this.ooaApplicationOwner.parentCarerGivenName} ${this.ooaApplicationOwner.parentCarerFamilyName}`,
          clickHandler: this.openMultiApplicationsModal
        }
      }
    }
  },
  methods: {
    addParentCarer() {
      if (this.application.parentCarers == null) {
        this.application.parentCarers = []
      }
      this.application.parentCarers.push({
        parentCarerTitle: '',
        parentCarerFamilyName: '',
        parentCarerGivenName: '',
        parentCarerRelation: '',
        parentCarerCompletingApp: false,
        parentCarerEmail: '',
        contactDetails: [],
        addedInSi: true,
        residentialAddress: {},
        liveOnResidentialAddress: null
      })
    },
    deleteParentCarer(parent) {
      const index = this.parentCarers.indexOf(parent)
      this.parentCarers.splice(index, 1)
    },
    openMultiApplicationsModal() {
      this.showMultiApplicationsModal = true

      const email = this.ooaApplicationOwner.parentCarerEmail
      const schoolCode = this.$store.getters.application.schoolCode
      this.$store.dispatch('getApplicationOwnerApplications', {
        email,
        schoolCode
      })
    }
  }
}
</script>
