import { FIELD_TYPE, YES_NO } from '@/constants'
import { isSupportForLearningRequired } from './helpers'

export const LEARNING_ADJUSTMENT_OPTIONS = [
  {
    apiKey: 'learningAdjustments.changeToLearningPrograms',
    label: 'Changes to learning programs and/or teaching strategies?'
  },
  {
    apiKey: 'learningAdjustments.communication',
    label: 'Communication, e.g. speaking and/or listening?'
  },
  {
    apiKey: 'learningAdjustments.modificationToEquipment',
    label:
      'Modification to equipment, furniture, learning spaces and/or learning materials?'
  },
  {
    apiKey: 'learningAdjustments.supportForPersonalNeeds',
    label:
      'Support for personal care needs, e.g. hygiene, mealtimes and/or health care needs?'
  },
  {
    apiKey: 'learningAdjustments.socialSupport',
    label: 'Social support to engage safely with other children and teachers?'
  },
  {
    type: FIELD_TYPE.LINEBREAK
  },
  {
    apiKey: 'learningAdjustments.otherAdjustment',
    label: 'Other adjustments?'
  }
]

export const DISABILITY_OPTIONS = [
  {
    apiKey: 'learningSupportNeeds.autism',
    label: 'Autism?'
  },
  {
    apiKey: 'learningSupportNeeds.hearingImpairment',
    label: 'A hearing impairment?'
  },
  {
    apiKey: 'learningSupportNeeds.languageDisorder',
    label: 'A language disorder?'
  },
  {
    apiKey: 'learningSupportNeeds.physicalDisability',
    label: 'A physical disability?'
  },
  {
    apiKey: 'learningSupportNeeds.difficultiesInLearning',
    label: 'Difficulties in learning?'
  },
  {
    apiKey: 'learningSupportNeeds.brainInjury',
    label: 'Acquired brain injury?'
  },
  {
    apiKey: 'learningSupportNeeds.behaviourDisorder',
    label: 'Behaviour disorder?'
  },
  {
    apiKey: 'learningSupportNeeds.intellectualDisability',
    label: 'Intellectual disability?'
  },
  {
    apiKey: 'learningSupportNeeds.mentalHealthDisorder',
    label: 'Mental health disorder?'
  },
  {
    apiKey: 'learningSupportNeeds.visionImpairment',
    label: 'A vision impairment?'
  },
  {
    apiKey: 'learningSupportNeeds.otherDisability',
    label: 'Other disability?'
  }
]

const OPTIONAL_YES_NO_RADIO_CONFIG = {
  required: false,
  type: FIELD_TYPE.RADIO,
  options: YES_NO
}

const setSupportRequired = (store) => {
  const supportRequired = isSupportForLearningRequired(
    store.getters.application
  )
  store.dispatch('set', [
    'application.isSupportForLearningRequired',
    supportRequired
  ])
}

export default [
  {
    label: 'Special circumstances',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'anyAdditionalInforPriorEnrol',
    label:
      'Any special circumstances about the student that the school should know prior to enrolment?',
    ...OPTIONAL_YES_NO_RADIO_CONFIG
  },
  {
    apiKey: 'anyAdditionalInforDetails',
    label: 'Special circumstances',
    type: FIELD_TYPE.TEXTAREA,
    required: true,
    colspan: 2,
    visible(application) {
      return application.anyAdditionalInforPriorEnrol
    }
  },
  {
    label:
      'Students with additional learning and support needs, including disability',
    type: FIELD_TYPE.HEADING
  },

  // todo: Should we make this readonly?
  {
    apiKey: 'isSupportForLearningRequired',
    label:
      'Does the student require support for learning because of disability?',
    ...OPTIONAL_YES_NO_RADIO_CONFIG
  },
  {
    apiKey: 'areThereModifiStratUsedHome',
    label: `Are there modifications at home that may help us at school to meet the student's educational needs?`,
    ...OPTIONAL_YES_NO_RADIO_CONFIG,
    onChange: (_, store) => setSupportRequired(store)
  },
  {
    apiKey: 'modifiStratUsedHome',
    label: 'Modification details',
    type: FIELD_TYPE.TEXTAREA,
    required: true,
    visible(application) {
      return application.areThereModifiStratUsedHome
    }
  },
  {
    label: 'Learning adjustments',
    type: FIELD_TYPE.HEADING
  },
  ...LEARNING_ADJUSTMENT_OPTIONS.map((adjustment) => {
    return adjustment.apiKey
      ? {
          ...adjustment,
          ...OPTIONAL_YES_NO_RADIO_CONFIG,
          onChange: (_, store) => setSupportRequired(store)
        }
      : adjustment
  }),
  {
    apiKey: 'learningAdjustments.otherAdjustmentDetails',
    label: 'Other adjustment details',
    type: FIELD_TYPE.TEXTAREA,
    maxlength: 128,
    colspan: 2,
    required: true,
    visible(application) {
      return application.learningAdjustments.otherAdjustment
    }
  },
  {
    label: 'Disabilities',
    type: FIELD_TYPE.HEADING
  },
  ...DISABILITY_OPTIONS.map((disability) => {
    return {
      ...disability,
      ...OPTIONAL_YES_NO_RADIO_CONFIG,
      onChange: (_, store) => setSupportRequired(store)
    }
  }),
  {
    apiKey: 'learningSupportNeeds.otherDisabilityDetails',
    label: 'Other disability details',
    type: FIELD_TYPE.TEXTAREA,
    maxlength: 128,
    required: true,
    visible(application) {
      return application.learningSupportNeeds.otherDisability
    }
  },
  {
    label: 'Support plan',
    type: FIELD_TYPE.HEADING
  },
  {
    apiKey: 'hasPrevLearningSupportPlan',
    label: 'Has previous learning support plan?',
    ...OPTIONAL_YES_NO_RADIO_CONFIG,
    onChange: (_, store) => setSupportRequired(store)
  },
  {
    apiKey: 'prevLearningSupportPlanDetails',
    label: 'Support plan details',
    type: FIELD_TYPE.TEXTAREA,
    colspan: 2,
    required: true,
    visible(application) {
      return application.hasPrevLearningSupportPlan
    }
  }
]
