<template>
  <div class="px-8 pt-4 pb-2" :class="{ isEditing }">
    <v-card class="mb-6 px-6">
      <delete-button
        v-if="isEditing && !parentCarer.parentCarerCompletingApp"
        @click="$emit('delete', parentCarer)"
        :aria-label="`delete ${parentCarerName(parentCarer)}`"
      />
      <v-card-title class="px-0">
        <div>Parent/carer: {{ parentCarerName(parentCarer) }}</div>
      </v-card-title>
      <v-row>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <select-field
            label="Title"
            v-model="parentCarer.parentCarerTitle"
            :items="titles"
            :rules="[validators.required]"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <text-field
            label="Parent/carer given name"
            v-model="parentCarer.parentCarerGivenName"
            :rules="[validators.required, validators.name]"
            maxlength="100"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <text-field
            label="Parent/carer family name"
            v-model="parentCarer.parentCarerFamilyName"
            :rules="[validators.required, validators.name]"
            maxlength="100"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <select-field
            label="Relationship to student"
            v-model="parentCarer.parentCarerRelation"
            :items="relationships"
            :rules="[validators.required]"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
        <v-col cols="12" md="4">
          <text-field
            label="Parent/carer email"
            v-model="parentEmail"
            :rules="[
              parentCarer.parentCarerCompletingApp ? validators.required : true,
              validators.email
            ]"
            :readonly="
              readonly ||
              !!(
                parentCarer.parentCarerCompletingApp &&
                parentCarer.parentCarerEmail
              )
            "
            maxlength="132"
            data-testid="parentCarerEmail"
          />
        </v-col>
        <v-col />
      </v-row>
      <!--
        We must use a unique key here for reactivity
        We can't use index because Vue gets confused when you delete "1" from [0, 1, 2]
        and then 2's key changes to "1".
      -->
      <v-row
        v-for="(contactWithKey, cIndex) in contactsWithKey"
        :key="`${cIndex}`"
      >
        <v-col class="py-0">
          <Contact
            :ref="`contact-${cIndex}`"
            :contact="contactWithKey.contact"
            @delete="(c) => removeContact(parentCarer, c)"
            :rules="[validateDuplicateContact(parentCarer)]"
            @update:contact="
              (contact) => validateOtherContacts(contact, parentCarer)
            "
            :readonly="readonly"
            :data-testid="`parentCarerContact${cIndex}`"
          />
        </v-col>
      </v-row>
      <v-row v-if="isEditing && parentCarer.contactDetails.length < 3">
        <AdsButton
          class="mb-3"
          icon="mdi-plus-circle-outline"
          tertiary
          button-text="Add another contact number"
          @click="addContact(parentCarer)"
        ></AdsButton>
      </v-row>
      <v-row v-if="errorMessage">
        <v-col class="ml-2">
          <alert-message data-testid="parentCarerError">
            {{ errorMessage }}
          </alert-message>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'

import { AdsButton } from '@nswdoe/doe-ui-core'
import DeleteButton from '@/components/form/DeleteButton'
import TextField from '@/components/form/TextField'
import SelectField from '@/components/form/SelectField'
import AlertMessage from '@/components/form/AlertMessage'
import { DATASET } from '@/constants'
import Contact from './Contact.vue'
import validatableMixin from '@/components/form/validatableMixin'
import { generateGuid } from '@/helpers/generalUtils'

export default {
  name: 'ParentCarer',
  mixins: [validatableMixin('parentCarer', 'Parent/Carer')],
  components: {
    AdsButton,
    DeleteButton,
    TextField,
    SelectField,
    AlertMessage,
    Contact
  },
  props: {
    parentCarer: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      boolYesNo: formatters.boolYesNo,
      addressFormatter: formatters.getAddressFormatter(
        this.$store.getters.referenceData(DATASET.COUNTRIES),
        this.$store.getters.referenceData(DATASET.STATES)
      ),
      validators: {
        name: validators.name,
        required: validators.required,
        email: validators.email
      },
      contactsWithKey: []
    }
  },
  watch: {
    ['parentCarer.contactDetails']: {
      immediate: true,
      handler(pc) {
        this.contactsWithKey = this.generateContactWithKeys(pc)
      }
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing', 'referenceData']),
    titles() {
      return this.referenceData(DATASET.TITLE)
    },
    relationships() {
      return this.referenceData(DATASET.RELATIONSHIP)
    },
    applicationAddress() {
      return this.addressFormatter(this.application.residentialAddress)
    },
    parentEmail() {
      if (this.parentCarer.parentCarerEmail == 'null') {
        return ''
      }
      return this.parentCarer.parentCarerEmail
    }
  },
  methods: {
    generateContactWithKeys(contactDetails) {
      if (!contactDetails) {
        return []
      }
      return contactDetails.map((contact) => {
        return {
          key: generateGuid(),
          contact
        }
      })
    },
    parentCarerName(parent) {
      return parent
        ? `${parent.parentCarerTitle} ${parent.parentCarerGivenName} ${parent.parentCarerFamilyName}`
        : 'Parent/carer'
    },
    removeContact(parent, contact) {
      const index = parent.contactDetails.indexOf(contact)
      parent.contactDetails.splice(index, 1)
      this.validateOtherContacts(null, parent)
    },
    addContact(parent) {
      parent.contactDetails.push({
        contactType: '',
        contactValue: '',
        comments: ''
      })
    },
    validateDuplicateContact(parent) {
      return (contact) => {
        const hasDuplicate = parent.contactDetails.some((pc) => {
          return (
            pc !== contact &&
            pc.contactType === contact.contactType &&
            pc.contactValue === contact.contactValue
          )
        })
        return !hasDuplicate || 'This contact must not be used twice'
      }
    },
    validateOtherContacts(contact, parent) {
      // validate the other instances as well
      if (parent.contactDetails) {
        for (let i = 0; i < parent.contactDetails.length; i++) {
          // skip the triggering one
          if (parent.contactDetails[i] !== contact) {
            const ref = `contact-${i}`
            this.$refs[ref][0].validate(parent.contactDetails[i])
          }
        }
      }
    }
  }
}
</script>
