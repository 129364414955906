<template>
  <div class="ma-3 mb-5">
    <template name="default"
      ><span class="content"
        >No {{ detailName }} details available</span
      ></template
    >
  </div>
</template>

<script>
export default {
  name: 'NoDetails',
  props: {
    detailName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
span.content {
  font-style: italic;
  color: $ads-dark-60;
}
</style>
