var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-radio-group',{staticClass:"option unstyled",attrs:{"aria-labelledby":"Match this student"},model:{value:(_vm.selectedSrn),callback:function ($$v) {_vm.selectedSrn=$$v},expression:"selectedSrn"}},_vm._l((_vm.records),function(record,index){return _c('div',{key:index,staticClass:"studentMatchPanel",class:_vm.isSelected(record),on:{"click":function($event){return _vm.setSelectedRecord(record)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}},[_c('v-radio',{staticClass:"ml-3",attrs:{"aria-label":'select' + record.surname + ',' + record.firstName,"value":record.srn,"data-testid":"selected-srn"}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('div',{staticClass:"ml-n7"},[(_vm.isLinked(record))?_c('div',{staticClass:"label isLinked"},[_c('font-awesome-icon',{attrs:{"focusable":"false","icon":"link"}}),_c('span',[_vm._v(" Currently linked")])],1):_vm._e(),_c('div',{staticClass:"studentName",attrs:{"data-testid":"student-name"}},[_c('strong',[_vm._v(_vm._s(record.surname)+", "+_vm._s(record.firstName))])])])]),_c('v-col',{attrs:{"cols":"2"}},[(_vm.compare)?_c('div',{staticClass:"stars pt-2 mr-2"},[_c('span',{staticClass:"ml-n7 helpText"},[_vm._v("Relevance: ")]),_c('font-awesome-icon',{class:_vm.getStarCssClass(record, 1),attrs:{"focusable":"false","icon":"star","data-testid":"star-1"}}),_c('font-awesome-icon',{class:_vm.getStarCssClass(record, 2),attrs:{"focusable":"false","icon":"star","data-testid":"star-2"}}),_c('font-awesome-icon',{class:_vm.getStarCssClass(record, 3),attrs:{"focusable":"false","icon":"star","data-testid":"star-3"}})],1):_vm._e()])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{staticClass:"ml-n7"},[_c('div',{staticClass:"label"},[_vm._v("Gender")]),_c('div',{class:[
            record.gender === _vm.compare.gender ? 'matched' : '',
            'value'
          ]},[_vm._v(" "+_vm._s(record.gender)+" ")])]),_c('v-col',[_c('div',{staticClass:"label"},[_vm._v("Date of Birth")]),_c('div',{class:_vm.getDobClass(record),attrs:{"data-testid":"date-of-birth"}},[_vm._v(" "+_vm._s(_vm.getDob(record))+" ")])]),_c('v-col',[_c('div',{staticClass:"label"},[_vm._v("Scholastic Year")]),_c('div',{class:_vm.getSyClass(record)},[_vm._v(_vm._s(_vm.getScholasticYear(record)))])]),_c('v-col',[_c('div',{staticClass:"label"},[_vm._v("Parents/Carer")]),_vm._l((record.parents),function(parent,pcindex){return _c('div',{key:pcindex,class:_vm.getPcClass(parent, pcindex),attrs:{"data-testid":"parent-names"}},[_vm._v(" "+_vm._s(parent)+" ")])})],2),_c('v-col',[_c('div',{staticClass:"label"},[_vm._v("Postcode")]),_c('div',{class:[
            record.postcode === _vm.compare.postcode ? 'matched' : '',
            'value'
          ]},[_vm._v(" "+_vm._s(record.postcode)+" ")])])],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }