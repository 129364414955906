<template>
  <div class="px-8 pt-4 pb-2">
    <no-details
      v-if="!hasAnySiblings && !criterias && !otherConsiderations"
      detail-name="Consideration"
    />
    <template v-if="hasAnySiblings">
      <v-card class="mb-6 px-4 pb-6">
        <v-card-title class="px-0 pb-4">
          <div>Sibling(s) currently attending the school</div>
        </v-card-title>
        <template v-for="(sibling, i) in ooaHighSchool.siblings">
          <div :key="i" class="siblings">
            <v-card class="mb-6 px-4 pb-6">
              <v-card-title class="px-0 pb-0">
                <div>Sibling: {{ name(sibling) }}</div>
              </v-card-title>
              <v-row>
                <v-col>
                  <read-only-field
                    label="Given name"
                    v-model="sibling.siblingsFirstName"
                    readonly
                  />
                </v-col>
                <v-col>
                  <read-only-field
                    label="Middle name"
                    v-model="sibling.siblingsMiddleName"
                    readonly
                  />
                </v-col>
                <v-col>
                  <read-only-field
                    label="Family name"
                    v-model="sibling.siblingsFamilyName"
                    readonly
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <read-only-field
                    label="Gender"
                    v-model="sibling.siblingsGenderCode"
                    :formatter="genderFormatter"
                    readonly
                  />
                </v-col>
                <v-col cols="4">
                  <read-only-field
                    label="Date of birth"
                    v-model="sibling.siblingsDOB"
                    :formatter="displayDate"
                    readonly
                  />
                </v-col>
              </v-row>
            </v-card>
          </div>
        </template>
        <v-card v-if="hasFurtherDetails" class="mb-6 px-4 pb-6">
          <v-card-title class="px-0 pb-0">
            <div>Further details</div>
          </v-card-title>
          <v-row>
            <v-col>
              <read-only-field
                label="Details"
                v-model="hasFurtherDetails"
                readonly
              />
            </v-col>
          </v-row>
        </v-card>
      </v-card>
    </template>
    <template v-if="criterias">
      <v-card
        class="mb-6 px-4 pb-6"
        v-for="(criteria, i) in criterias"
        :key="i"
      >
        <v-card-title class="px-0 pb-0">
          <div>{{ schoolCriteria(criteria.code) }}</div>
        </v-card-title>
        <v-row>
          <v-col>
            <read-only-field
              label="Details"
              v-model="criteria.description"
              readonly
            />
          </v-col>
        </v-row>
      </v-card>
    </template>
    <template v-if="otherConsiderations">
      <v-card class="mb-6 px-4 pb-6">
        <v-card-title class="px-0 pb-0">
          <div v-if="isOverCap">Exceptional circumstances</div>
          <div v-else>Reasons for seeking placement at this school</div>
        </v-card-title>
        <v-row>
          <v-col>
            <read-only-field
              label="Details"
              v-model="otherConsiderations"
              readonly
            />
          </v-col>
        </v-row>
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'
import NoDetails from '@/components/application/NoDetails'
import ReadOnlyField from '@/components/form/ReadOnlyField'
import settingsUtils from '@/helpers/settingsUtils'

const valueOrDefault = (val, defaultVal = '') => {
  return val ? val : defaultVal
}

export default {
  name: 'Y67tFormSiblings',
  components: {
    ReadOnlyField,
    NoDetails
  },
  props: {
    schoolCode: {
      type: String
    }
  },
  data() {
    return {
      validators: {
        required: validators.required
      },
      genderFormatter: formatters.gender,
      dateFormatter: formatters.date,
      displayDate: formatters.displayDate
    }
  },
  computed: {
    ...mapGetters(['application', 'defaultSelectionCriteria']),
    ooaHighSchool() {
      return this.application.ooaHighSchools?.find(
        (school) => school.schoolCode === this.schoolCode
      )
    },
    hasAnySiblings() {
      return this.ooaHighSchool.hasAnySiblings && this.ooaHighSchool.siblings
    },
    hasFurtherDetails() {
      if (
        this.hasAnySiblings &&
        this.ooaHighSchool.criteria &&
        this.ooaHighSchool.criteria.length > 0
      ) {
        const hasSib = this.ooaHighSchool.criteria?.find(
          (criteria) => criteria.code === 'SIB'
        )
        return hasSib.description
      }
      return false
    },
    otherConsiderations() {
      return this.ooaHighSchool.otherConsiderations
    },
    criterias() {
      if (
        this.ooaHighSchool.criteria &&
        this.ooaHighSchool.criteria.length > 0
      ) {
        const criterias = this.ooaHighSchool.criteria.filter(
          (criteria) => criteria.code !== 'SIB'
        )
        return criterias
      }
      return false
    },
    isOverCap() {
      return settingsUtils.isOverCap(this.ooaHighSchool.capacity)
    }
  },
  methods: {
    schoolCriteria(code) {
      const criteria = this.defaultSelectionCriteria?.find(
        (item) => item.code === code
      )
      return criteria ? criteria.value : null
    },
    name(sibling) {
      return `${sibling.siblingsFirstName} ${valueOrDefault(
        sibling.siblingsMiddleName
      )} ${sibling.siblingsFamilyName}`
    }
  }
}
</script>
<style scoped>
::v-deep .v-card__title {
  font-size: 1rem !important;
}
.siblings {
  border-bottom: 1px solid #e4e4e6;
}
</style>
