// See README.md for more info about field configuration

import { FIELD_TYPE, SUPPORTING_DOCS, USER_GROUP } from '@/constants'
import store from '@/store/store'

export const generateDocsFields = (items) => {
  return items.reduce((cur, i) => {
    cur.push(
      {
        label: i.label,
        type: FIELD_TYPE.HEADING
      },
      {
        apiKey: 'supportingDocuments.' + i.category,
        type: FIELD_TYPE.COLLECTION,
        showNone: true,
        fields(apiPrefix, fileItem) {
          return [
            {
              apiKey: apiPrefix + 'objectKey',
              colspan: 3,
              type: FIELD_TYPE.FILE_EDITOR,
              handleView(value) {
                store.dispatch('showOrDownloadDocument', value)
              },
              canView: store.state.userGroup === USER_GROUP.ERN_ADMIN,
              status: fileItem.status
            }
          ]
        }
      }
    )
    return cur
  }, [])
}

export default [
  {
    message: {
      type: 'info',
      body: `Please ensure these document(s) are always kept in a secure location within school premises.`
    },
    type: FIELD_TYPE.MESSAGE
  },
  {
    type: FIELD_TYPE.CUSTOM,
    component: 'AdsButton',
    componentProps: {
      secondary: true,
      icon: 'mdi-folder-multiple-outline',
      buttonText: 'Download all'
    },
    canView: USER_GROUP.ERN_ADMIN, // I think for CUSTOM field type I can't use "store.state.userGroup === USER_GROUP.ERN_ADMIN"
    cssStyle: 'display: flex; justify-content: flex-end;'
  },
  ...generateDocsFields(SUPPORTING_DOCS)
]
