<!-- 
  Provides the template for the application save/cancel bar
-->
<template>
  <div class="saveCancel pr-6 py-3">
    <ads-button
      type="reset"
      button-text="Cancel"
      class="mr-3"
      tertiary
      @click="$emit('cancel')"
    />
    <ads-button type="submit" button-text="Update" @click="$emit('submit')" />
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
export default {
  name: 'SaveCancel',
  components: {
    AdsButton
  }
}
</script>

<style scoped lang="scss">
.saveCancel {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  background-color: white;
  text-align: right;
  box-shadow: 0 0 5px #a9a9a9;
}
</style>
