// See README.md for more info about field configuration

import { FIELD_TYPE, CONFLICT_ALERTING, MATCH, DATASET } from '@/constants'
import { getContactText, getContactNumberFields } from './helpers'
import UTILS from '@/store/utils'
import store from '@/store/store'

export default [
  // EMERGENCY CONTACTS -------------------------------------------
  {
    apiKey: 'emergencyContact',
    type: FIELD_TYPE.COLLECTION,
    heading(contact) {
      if (contact) {
        const fullName = UTILS.toFullNameString(
          contact.contactFirstName,
          contact.contactFamilyName
        )
        return `Emergency contact: ${fullName}`
      }
      return `Emergency contact`
    },
    summaryText(contact) {
      var relationship = store.getters.lookupCode(
        DATASET.RELATIONSHIP,
        contact.contactRelationship
      )
      const fullName = UTILS.toFullNameString(
        contact.contactFirstName,
        contact.contactFamilyName
      )
      return `${fullName}${relationship ? `, ${relationship}` : ''}`
    },
    orderBy: 'priority',
    addLabel: 'Add emergency contact',
    newRecordTemplate() {
      return {
        contactFamilyName: '',
        contactFirstName: '',
        contactRelationship: '',
        contactDetails: [
          {
            contactType: '',
            contactValue: '',
            comments: ''
          }
        ]
      }
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      return UTILS.isMatch(oes, ern, {
        contactFirstName: MATCH.FUZZY,
        contactFamilyName: MATCH.FUZZY
      })
    },
    ernRecordNumberField: 'ernEmergencyContactRecordNo',
    oesRecordNumberField: 'contactRecordNo',
    fields(apiKeyPrefix) {
      return [
        {
          apiKey: apiKeyPrefix + 'contactFamilyName',
          label: 'Family name',
          required: true,
          type: FIELD_TYPE.NAME,
          maxlength: 100
        },
        {
          apiKey: apiKeyPrefix + 'contactFirstName',
          label: 'Given name',
          required: false,
          type: FIELD_TYPE.NAME,
          maxlength: 100
        },
        {
          apiKey: apiKeyPrefix + 'contactRelationship',
          label: 'Relationship to student',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.RELATIONSHIP
        },

        // EMERGENCY CONTACT NUMBERS -------------------------------------------
        {
          apiKey: apiKeyPrefix + 'contactDetails',
          type: FIELD_TYPE.COLLECTION,
          conflictAlerting: CONFLICT_ALERTING.LIST,
          ernRecordNumberField: 'ernContactDetailRecordNo',
          oesRecordNumberField: 'contactDetailRecordNo',
          heading(contactNumber) {
            return getContactText(contactNumber)
          },
          placeholderTitle: 'Contact details',
          orderBy: 'priority',
          addLabel: 'Add contact number',
          newRecordTemplate() {
            return {
              contactType: '',
              contactValue: '',
              comments: ''
            }
          },
          fields(apiKeyPrefix, contactNumber) {
            return getContactNumberFields(
              this.apiKey,
              contactNumber,
              apiKeyPrefix,
              true
            )
          }
        }
      ]
    }
  },
  {
    type: FIELD_TYPE.CUSTOM,
    component: 'InfoPanel',
    componentProps: {
      content: `<span style="line-height: 24px"><b>Note: </b>Parent/Carer has not provided two emergency contacts.<span>`,
      isAlertMessage: true
    },
    visible(application) {
      return application.emergencyContact.length < 2
    },
    readOnly: true
  }
]
