<template>
  <Chip
    v-if="applicationStatus !== null"
    v-bind="chipProps"
    class="ooa-status-chip"
    pill
    small
  />
</template>

<script>
import { OOA_STATUS_CHIPS } from '@/constants'
import { Chip } from '@nswdoe/doe-ui-core'

export default {
  name: 'OoaStatusChip',
  props: {
    applicationStatus: {
      type: String
    }
  },
  components: {
    Chip
  },
  computed: {
    chipProps() {
      return (
        OOA_STATUS_CHIPS[this.applicationStatus] || {
          ...OOA_STATUS_CHIPS.default,
          text: this.applicationStatus
        }
      )
    }
  }
}
</script>
<style scoped lang="scss">
.ooa-status-chip::v-deep {
  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
}
</style>
