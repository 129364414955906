<!--
  Provides a dialog for showing address match conflict confirmation.
  Confirming address will update the address in ERN after the EOI is sent to HS.
-->
<template>
  <v-dialog
    v-model="showConfirmAddress"
    max-width="950"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card class="confirmModal">
      <div class="fullWidth pa-6">
        <div class="icon iconCircleBackground d-inline-block mr-2">
          <v-icon class="material-icons-outlined" color="primary">place</v-icon>
        </div>
        <div class="d-inline-block">
          <span class="header" role="alert"
            ><strong>Confirm address change</strong></span
          >
        </div>
        <v-btn
          icon
          class="modal__close-button float-right"
          aria-label="close"
          @click="cancel"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <div class="content pl-6 pr-6">
        <p>
          <strong
            >The residential address entered on this EOI does not match the
            student record in ERN.</strong
          >
        </p>
        <p>
          To confirm the correct address please contact the parent/carer
          <strong data-testid="parentName">{{ parentName }}</strong> on
          <strong data-testid="parentContact">{{ parentContact }}</strong>
        </p>
        <p>
          By changing the residential address, other students in this family
          will also have their residential address updated in ERN.
        </p>
        <p>
          If this student has a complex family tree structure we recommend
          updating their address directly in ERN.
        </p>
        <p>
          <i
            >If you believe the address provided is incorrect, you can withdraw
            the EOI from the details screen and ask the parent/carer to submit a
            new EOI.</i
          >
        </p>
        <p><strong>This address:</strong></p>
        <div class="addressDetails pa-6">
          <span class="pr-2">
            <Chip v-bind="ernChip" class="chip" small />
          </span>
          <span data-testid="ernAddress">{{ ernAddress }}</span>
        </div>
        <p class="mt-4"><strong>will be updated to this address:</strong></p>
        <div class="addressDetails pa-6">
          <span class="pr-2">
            <Chip v-bind="newChip" class="chip" small />
          </span>
          <span data-testid="eoiAddress">{{ eoiAddress }}</span>
        </div>
      </div>
      <div class="footer pa-6">
        <AdsButton
          @click="confirmAddChange"
          data-testid="confirm-address-change"
          class="button"
          button-text="Confirm address change"
          icon="mdi-check"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, Chip } from '@nswdoe/doe-ui-core'

export default {
  name: 'ConfirmAddress',
  components: {
    AdsButton,
    Chip
  },
  data() {
    return {
      newChip: {
        text: 'NEW',
        color: '#407EC9',
        outlined: false,
        textColor: '#FFFFFF'
      },
      ernChip: {
        text: 'ERN',
        color: '#4C4F55',
        outlined: true,
        textColor: '#4C4F55'
      }
    }
  },
  computed: {
    application() {
      return this.$store.state.application
    },
    ernAddress() {
      if (this.$store.getters.ernAddress) {
        const address = this.$store.getters.ernAddress
        return [
          address.addressLine1 ? address.addressLine1.trim() : '',
          address.addressLine2 ? address.addressLine2.trim() : '',
          address.suburbName ? address.suburbName.trim() : '',
          address.stateCode ? address.stateCode.trim() : '',
          address.postCode ? address.postCode.trim() : '',
          address.countryCode ? address.countryCode.trim() : ''
        ]
          .join(' ')
          .toUpperCase()
      }
      return ''
    },
    parentName() {
      if (this.application) {
        return (
          this.application.parentCarers[0].parentCarerGivenName +
          ' ' +
          this.application.parentCarers[0].parentCarerFamilyName
        )
      }
      return ''
    },
    parentContact() {
      if (this.application) {
        const contacts = this.application.parentCarers[0].contactDetails
        return contacts[0].contactValue
      }
      return ''
    },
    eoiAddress() {
      if (this.application) {
        const address = this.application.residentialAddress
        return [
          address.addressLine1.trim(),
          address.suburbName.trim(),
          address.stateCode.trim(),
          address.postCode.trim(),
          address.countryCode.trim()
        ]
          .join(' ')
          .toUpperCase()
      }
      return ''
    },
    isAddressConflict() {
      return this.$store.state.isAddressConflict
    },
    showConfirmAddress() {
      return this.$store.getters.showConfirmAddress
    }
  },
  methods: {
    cancel() {
      this.$store.commit('setShowConfirmAddress', false)
      this.$emit('closeModal', true)
    },
    confirmAddChange() {
      this.$store
        .dispatch('updateErnAddress', this.application)
        .then(() => {
          this.$store.commit('setIsAddressConflict', false)
          this.$store.commit('setReadyToSendErn', true)
          this.$store.commit('setShowConfirmAddress', false)
          this.$store.commit('setFocusToSendErnBtn', true)
          this.$gtm.trackEvent({
            category: 'Details view',
            action: 'Confirming address change',
            label: 'Address conflict resolution',
            value: 'y67t',
            stream: 'Y67T'
          })
        })
        .catch(() => {
          this.$store.dispatch('showMessageBox', {
            icon: 'mdi-exclamation-thick',
            html: '<h2>Address cannot be updated. Please try again later.</h2>'
          })
        })
    }
  }
}
</script>

<style scoped lang="scss">
.fullWidth {
  width: 100%;
}
.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}
.addressDetails {
  background-color: $grey-light-10;
}
.chip {
  font-weight: bold;
}
.cancelButton {
  border: none;
}
.confirmModal {
  padding: 1em;
  .header {
    font-size: 1.25rem;
  }
  .footer {
    text-align: right;
  }
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}
.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}
</style>
