<template>
  <div class="ApplicationList grey lighten-6">
    <!-- OOA Filters -->
    <v-row class="pb-3">
      <v-col class="pt-5">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3"
          ><ListViewFilter
            label="Status"
            type="checkbox"
            :filter-options="statusFilters"
            @updatefilter="updateStatusFilter"
            ref="statusFilter"
            @menuOpened="sendFilterAnalytics"
        /></span>
        <span class="pl-3"
          ><ListViewFilter
            ref="scholasticYrFilter"
            label="Scholastic year"
            type="checkbox"
            :filter-options="scholasticYearFilters"
            @updatefilter="updateScholasticYearFilter"
            @menuOpened="sendFilterAnalytics"
        /></span>
        <span class="pl-3"
          ><ListViewFilter
            ref="siblingsFilter"
            label="Siblings"
            type="radio"
            :filter-options="siblingsFilters"
            @updatefilter="updateSiblingsFilter"
            @menuOpened="sendFilterAnalytics"
        /></span>
        <span class="pl-3">
          <YearDateRangeFilter
            ref="startDateFilter"
            label="Start date"
            :archive-type="archiveType"
            :filter-options="startDateFilter"
            @updatefilter="updateStartDateFilter"
            @updateYearFilter="updateYearFilter"
            @menuOpened="sendFilterAnalytics"
            :year-options="yearOptions"
            selected-application="Out-of-area"
        /></span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
      <v-col v-if="!isOesSupport" cols="auto">
        <v-btn
          class="mt-0 mr-0 ml-3"
          x-large
          :disabled="
            !Object.values(selectedIds).filter((v) => v === true).length
          "
          :aria-label="downloadBtnAriaLabel"
          @click="openDownloadModal"
          data-testid="ooaDownloadButton"
        >
          <v-icon class="mr-2">mdi-download-outline</v-icon>
          Download application(s)
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <AdsDataTable
        class="OOA"
        :loading="loading"
        :items="applications"
        :headers="headers"
        search-label="Find student"
        :search="searchValue"
        sort-by="applicationStatus"
        must-sort
        sort-desc
        :items-per-page="pageLength"
        :footer-props="{
          'items-per-page-options': [10, 15, 50]
        }"
        :custom-filter="filterApplications"
        :custom-sort="customStatusSort"
        @current-items="setFilteredIds"
        @input="performSearchDebounced"
        @blur="trackSearch"
        @update:items-per-page="getItemPerPage"
        @update:search="updateSearchValue"
        @pagination="totalResults"
      >
        <template #no-data>
          <NoResultsFound
            :archive-type="archiveType"
            :filtered="isFiltered || Boolean(searchApplicationsFilter)"
          />
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                prepend-inner-icon="mdi-magnify"
                v-model="searchValue"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>
                {{ resultsText(itemsLength) }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template
          v-for="headerData in headers"
          :slot="`header.${headerData.value}`"
        >
          <button
            v-if="headerData.value !== 'selectAll'"
            class="no-styling"
            :key="headerData.value"
          >
            {{ headerData.text }}
          </button>
        </template>

        <!-- Template for select all functionality -->
        <template slot="header.selectAll">
          <v-checkbox
            :value="allSelected"
            hide-details
            aria-label="Add all applications to PDF download."
            class="my-0 mx-3 pa-0"
            @click.prevent="toggleSelectAll"
            data-testid="ooaToggleSelectAll"
          />
        </template>

        <template slot="item" slot-scope="props">
          <!-- Table template for "out of area applications" -->
          <tr
            @click="openApplication(props.item)"
            class="grey--text text--lighten-1"
          >
            <td class="text-xs-left" @click.stop>
              <OesCheckbox
                v-model="selectedIds[props.item.applicationID]"
                hide-details
                :aria-label="`Add application for ${props.item.name} to PDF download.`"
                class="ma-0 pa-0"
                data-testid="ooaSelectApplicationCheckbox"
              />
            </td>

            <td v-if="!isPendingTab" class="text-xs-right">
              {{ props.item.lastUpdatedDateTime | moment('DD MMM YYYY') }}
            </td>

            <td class="text-xs-right">
              {{ props.item.dateReceived | moment('DD MMM YYYY') }}
            </td>

            <td class="text-xs-right">
              <a
                :href="`/#/${getOpenApplicationHref(props.item)}`"
                :aria-label="`${props.item.name}. Open application details.`"
                :ref="`itemfocusRef${props.index}`"
                >{{ props.item.name }}</a
              >
              <AttributeBadge
                v-if="(props.item.application.residencyStatus || '') === 'TMP'"
                abbr="TR"
                title="Temporary resident"
                class="ml-1"
              />
            </td>
            <td class="text-xs-right">{{ props.item.noOfSiblings }}</td>
            <td
              class="text-xs-right"
              v-html="getConsiderationHtml(props.item.criteria)"
            ></td>

            <td class="text-sm-center text-md-left">
              <v-avatar
                class="primary lighten-2 white--text headline mr-2 font-weight-bold"
                size="29"
                >{{
                  convertScholasticYear(props.item.scholasticYear)
                }}</v-avatar
              >
              <span class="d-none d-lg-inline">
                {{ props.item.scholasticYearFormatted }}
              </span>
            </td>

            <td class="text-xs-right">
              {{ props.item.intendedStartDate | moment('DD MMM YYYY') }}
            </td>

            <td class="text-xs-right">
              <OoaStatusChip
                :application-status="props.item.applicationStatus"
              />
            </td>
          </tr>
        </template>
      </AdsDataTable>
    </v-card>

    <DownloadDocumentsModal
      v-if="showDownloadModal"
      :applications="selectedIdsForDownload"
      :school-code="schoolCode"
      :application-type="APPLICATION_TYPE.OUT_OF_AREA"
      @close="showDownloadModal = false"
    />
  </div>
</template>

<script>
import DownloadDocumentsModal from '@/components/applicationList/DownloadDocumentsModal.vue'
import OoaStatusChip from '@/components/application/ooa/OoaStatusChip.vue'
import ListViewFilter from '@/components/app/ListViewFilter.vue'
import YearDateRangeFilter from '@/components/app/YearDateRangeFilter.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import ClearFilters from './ClearFilters.vue'
import AttributeBadge from '@/components/application/AttributeBadge.vue'

import { mapGetters } from 'vuex'
import {
  searchFilter,
  searchFilterForStringOnly,
  sortScholasticYearCodes,
  setScholasticYear,
  sortByScholasticYear
} from '@/helpers/searchHelper'
import { debounce } from 'lodash'
import OesCheckbox from '@/components/app/OesCheckbox'
import { AdsDataTable } from '@nswdoe/doe-ui-core'

import {
  APPLICATION_TYPE,
  SCHOOL_CAPS,
  OOA_STATUS,
  OOA_OFFERED_STATUSES,
  STREAM,
  OOA_STATUS_ABBREV,
  USER_GROUP,
  ARCHIVE_TYPES
} from '@/constants'
import listCommon from '@/mixins/listCommon'

const pendingHeaders = [
  {
    value: 'selectAll',
    align: 'center',
    class: 'select-all',
    sortable: false
  },
  {
    text: 'Received',
    value: 'dateReceived',
    class: 'date-received',
    align: 'start'
  },
  {
    text: 'Student name',
    value: 'name',
    class: 'name',
    align: 'start'
  },
  {
    text: 'Siblings',
    value: 'noOfSiblings',
    class: 'siblings',
    align: 'start'
  },
  {
    text: 'Considerations',
    value: 'criteria',
    class: 'considerations',
    align: 'start'
  },
  {
    text: 'Scholastic year',
    value: 'scholasticYear',
    class: 'scholastic-year',
    align: 'start'
  },
  {
    text: 'Start date',
    value: 'intendedStartDate',
    class: 'start-date',
    align: 'start'
  },
  {
    text: 'Status',
    value: 'applicationStatus',
    class: 'status-ooa',
    align: 'start'
  }
]

const processedHeaders = [
  {
    value: 'selectAll',
    align: 'center',
    class: 'select-all',
    sortable: false
  },
  {
    text: 'Actioned',
    value: 'lastUpdatedDateTime',
    class: 'actioned',
    align: 'start'
  },
  {
    text: 'Received',
    value: 'dateReceived',
    class: 'date-received',
    align: 'start'
  },
  {
    text: 'Student name',
    value: 'name',
    class: 'name',
    align: 'start'
  },
  {
    text: 'Siblings',
    value: 'noOfSiblings',
    class: 'siblings',
    align: 'start'
  },
  {
    text: 'Considerations',
    value: 'criteria',
    class: 'considerations',
    align: 'start'
  },
  {
    text: 'Scholastic year',
    value: 'scholasticYear',
    class: 'scholastic-year',
    align: 'start'
  },
  {
    text: 'Start date',
    value: 'intendedStartDate',
    class: 'start-date',
    align: 'start'
  },
  {
    text: 'Status',
    value: 'applicationStatus',
    class: 'status-ooa',
    align: 'start'
  }
]

export default {
  name: 'OoaDataTable',
  components: {
    DownloadDocumentsModal,
    OoaStatusChip,
    ListViewFilter,
    OesCheckbox,
    ClearFilters,
    AdsDataTable,
    YearDateRangeFilter,
    NoResultsFound,
    AttributeBadge
  },
  data() {
    return {
      pendingHeaders,
      processedHeaders,
      selectedIds: {},
      filtersCleared: false,
      searchValue: '',
      pageLength: this.defaultPageLength,
      showDownloadModal: false,
      selectedStatus: [],
      selectedScholasticYear: [],
      selectedSiblings: null,
      selectedStartDateRange: [],
      selectedStartYear: [],
      APPLICATION_TYPE,
      itemsLength: 0,
      pendingDefaultStatusOrder: [
        { status: 'New', index: 1 },
        { status: 'Viewed', index: 2 },
        { status: 'In Review', index: 3 },
        { status: 'Shortlisted', index: 4 },
        { status: 'Interview Required', index: 5 },
        { status: 'Waitlisted', index: 6 }
      ],
      processedDefaultStatusOrder: [
        { status: 'Offered', index: 1 },
        { status: 'Offer Post Appeal', index: 1 },
        { status: 'Offer (other)', index: 1 },
        { status: 'Accepted', index: 2 },
        { status: 'Declined', index: 3 },
        { status: 'Declined Post Appeal', index: 3 },
        { status: 'Withdrawn', index: 4 },
        { status: 'Not Accepted', index: 5 },
        { status: 'Offer Expired', index: 6 }
      ],
      archiveType: ARCHIVE_TYPES.OOA.type
    }
  },
  props: {
    defaultPageLength: {
      type: Number,
      default: 50
    },
    ooaApplications: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    isPendingTab: {
      type: Boolean,
      default: false
    }
  },
  mixins: [listCommon],
  activated() {
    this.resetPageLength()
    this.searchValue = this.searchApplicationsFilter
  },
  created() {
    this.searchValue = this.searchApplicationsFilter
  },
  watch: {
    schoolCode() {
      // clear selected applications for download pdf on change of school switcher
      this.selectedIds = {}
      // clear selected filters on change of school switcher
      this.selectedStatus = []
      this.selectedScholasticYear = []
      this.selectedSiblings = null
      this.selectedStartDateRange = []
      this.selectedStartYear = []
    },
    searchValue(newVal) {
      this.searchValue = newVal
      this.updateSearchValue(newVal)
    }
  },
  methods: {
    convertScholasticYear(year) {
      return year === 'Kindergarten' ? 'K' : year?.split(' ')[1]
    },
    updateSearchValue(searchValue) {
      this.$store.commit('setApplicationsFilter', searchValue)
    },
    getItemPerPage(itemPerPage) {
      this.$store.commit('setDefaultPage', itemPerPage)
    },
    resetPageLength() {
      this.pageLength = this.defaultPageLength
    },
    getOpenApplicationHref(item) {
      return `application/${item.schoolCode}/${item.applicationID}`
    },
    openApplication(item) {
      this.$router.push(`/${this.getOpenApplicationHref(item)}`)
    },
    toggleSelectAll() {
      if (!this.allSelected) {
        this.selectedIds = this.filteredApplicationIds.reduce(
          (selected, appId) => {
            selected[appId] = true
            return selected
          },
          {}
        )
      } else {
        this.selectedIds = {}
      }
    },
    openDownloadModal() {
      this.showDownloadModal = true
      this.$gtm.trackEvent({
        category: 'pdfApplication',
        action: 'download',
        label: 'ooaListView',
        value: Object.keys(this.selectedIds).length
      })
    },
    closeDownloadModal() {
      this.showDownloadModal = false
    },
    getConsiderationHtml(criteria) {
      const schoolSettings = this.schoolSettings
      const maxCriteria = 1

      // Over capacity schools don't use data from `considerationData.criteria`
      if (schoolSettings.capacity === SCHOOL_CAPS.OVER) {
        return ''
      }

      if (criteria) {
        // application criteria may not match if school settings have changed
        criteria = criteria
          .map((item) => {
            const match = schoolSettings.criteria.find(
              (consideration) => consideration.code === item.code
            )
            return match ? match.shortName : null
          })
          .filter((c) => c !== null)

        let criteriaNum = criteria.length

        if (criteriaNum > maxCriteria) {
          criteria.splice(maxCriteria)
          criteria.push(
            ` <strong class="text-no-wrap">+ ${
              criteriaNum - maxCriteria
            } other${criteriaNum - maxCriteria > 1 ? 's' : ''}</strong>`
          )
        }

        return criteria.join(', ')
      }

      return ''
    },
    performSearch() {
      this.updateSearchValue(this.searchValue)
      this.resetPageLength()
      // clear selected applications for download pdf on change of keyword filter
      this.selectedIds = {}
    },
    performSearchDebounced: debounce(
      function (value) {
        // IE11 clears the checkboxes even when nothing changes so check if something changed
        if (value !== this.searchApplicationsFilter) {
          this.performSearch()
        }
      },
      300,
      { maxWait: 1000 }
    ),
    updateStatusFilter(selectedOptions) {
      this.selectedStatus = selectedOptions
      // clear selected applications for download pdf on change of status filter
      this.selectedIds = {}
    },
    updateScholasticYearFilter(selectedOptions) {
      if (selectedOptions && selectedOptions.length) {
        this.selectedScholasticYear = selectedOptions
      } else {
        this.selectedScholasticYear = []
      }
      // clear selected applications for download pdf on change of scholastic year filter
      this.selectedIds = {}
    },
    updateSiblingsFilter(selectedOption) {
      if (selectedOption) {
        // returning 0 and 1 to identify No siblings and 1 or more siblings
        this.selectedSiblings = selectedOption === 'No siblings' ? 0 : 1
      } else {
        this.selectedSiblings = null
      }
      // clear selected applications for download pdf on change of Siblings filter
      this.selectedIds = {}
    },
    updateStartDateFilter(dateRange) {
      if (dateRange !== null && dateRange.length > 0) {
        this.selectedStartDateRange = dateRange
      } else {
        this.selectedStartDateRange = []
      }
      // clear selected applications for download pdf on change of StartDate filter
      this.selectedIds = {}
    },
    updateYearFilter(selectedYear) {
      if (selectedYear !== null && selectedYear.length > 0) {
        this.selectedStartYear = selectedYear
      } else {
        this.selectedStartYear = []
      }
      this.selectedIds = {}
    },
    clearAllFilters() {
      // clear all filtered values
      this.performSearch()
      this.$refs.statusFilter.clearFilter()
      this.$refs.scholasticYrFilter.clearFilter()
      this.$refs.siblingsFilter.clearFilter()
      this.$refs.startDateFilter.clearFilter()
    },
    checkStartDateInsideRange(intendedStartDate) {
      const start = this.$moment(this.selectedStartDateRange[0])
      const end = this.$moment(this.selectedStartDateRange[1])
      const startDate = this.$moment(intendedStartDate)

      return start <= startDate && startDate <= end
    },
    filterStatus(applicationStatus) {
      // true if matched
      // OR if 'Offered' selected and application status is one of the alternate offered statuses
      return (
        this.selectedStatus.includes(applicationStatus) ||
        (this.selectedStatus.includes(OOA_STATUS.OFFERED) &&
          OOA_OFFERED_STATUSES.includes(applicationStatus))
      )
    },
    filterStartYear(startYear) {
      return this.selectedStartYear.includes(startYear)
    },
    filterApplications(value, search, item) {
      return searchFilterForStringOnly(value, search, item)
    },
    customStatusSort(applications, sortBy, isDesc) {
      // Check if the applications array is empty or null
      if (!applications || applications.length === 0) {
        return applications
      }
      // Get the field to sort by and the sort direction
      const sortByField = Array.isArray(sortBy) ? sortBy[0] : ''
      const isDescFlag = Array.isArray(isDesc) ? isDesc[0] : false
      const defaultStatusOrder = this.isPendingTab
        ? this.pendingDefaultStatusOrder
        : this.processedDefaultStatusOrder
      // Map each application to include its status index
      const indexedApplications = applications.map((application) => {
        // Find the default status order that matches the application status
        const indexedStatus = defaultStatusOrder.find(
          (element) => element.status === application.applicationStatus
        )
        // Add the status index to the application object
        return { ...application, index: indexedStatus?.index }
      })

      // Sort the indexed applications array
      indexedApplications.sort((a, b) => {
        // Check if we need to apply the custom sort order for application status
        if (sortByField === 'applicationStatus') {
          // Sort by status index in ascending or descending order
          if (isDescFlag) {
            if (a.index == b.index) {
              return this.isPendingTab
                ? new Date(b.dateReceived) - new Date(a.dateReceived)
                : new Date(b.lastUpdatedDateTime) -
                    new Date(a.lastUpdatedDateTime)
            }
            return a.index - b.index
          } else {
            return b.index - a.index
          }
        } else {
          // Get the values of the fields to sort by
          let bValue = b[sortByField]
          let aValue = a[sortByField]
          // Convert to lowercase if sorting by name
          if (sortByField === 'name') {
            bValue = String(bValue).toLowerCase()
            aValue = String(aValue).toLowerCase()
          }
          // Sort in ascending or descending order
          if (isDescFlag) {
            return bValue < aValue ? -1 : 1
          } else {
            return aValue < bValue ? -1 : 1
          }
        }
      })
      // Return the sorted indexed applications array
      return indexedApplications
    },
    trackSearch() {
      if (this.searchValue) {
        this.$gtm.trackEvent({
          category: 'search',
          action: 'searchBoxFiltered',
          label: STREAM.OUT_OF_AREA
        })
      }
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const statusData = this.selectedStatus.length
          ? JSON.stringify(
              this.sortStatuses(this.selectedStatus).map(
                (s) => OOA_STATUS_ABBREV[s] || s
              )
            )
          : null

        const yearData = this.selectedScholasticYear.length
          ? JSON.stringify(sortScholasticYearCodes(this.selectedScholasticYear))
          : null

        const siblingsData = JSON.stringify(this.selectedSiblings)

        // translate "[2020-01-01,2020-12-12]" to "20200101-20201212"
        const dateRangeData = this.selectedStartDateRange.length
          ? JSON.stringify(
              this.selectedStartDateRange
                .map((d) => d.replaceAll('-', ''))
                .join('-')
            )
          : null

        const consolidatedData =
          `${statusData},${yearData},${siblingsData},${dateRangeData}`.replaceAll(
            '"',
            ''
          )

        this.$gtm.trackEvent({
          category: 'applicationFilter',
          action: STREAM.OUT_OF_AREA,
          label: consolidatedData
        })
      }
    },
    sortStatuses(statuses) {
      const sortByStatus = [
        OOA_STATUS.NEW,
        OOA_STATUS.VIEWED,
        OOA_STATUS.IN_REVIEW,
        OOA_STATUS.SHORTLISTED,
        OOA_STATUS.INTERVIEW_REQUIRED,
        OOA_STATUS.WAITLISTED,
        OOA_STATUS.OFFERED,
        OOA_STATUS.ACCEPTED,
        OOA_STATUS.DECLINED,
        OOA_STATUS.WITHDRAWN,
        OOA_STATUS.NOT_ACCEPTED
      ]
      return statuses.sort(function (a, b) {
        return sortByStatus.indexOf(a) - sortByStatus.indexOf(b)
      })
    },
    totalResults(pagination) {
      this.itemsLength = pagination.itemsLength
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'schoolOoaSettings',
      'selectedSchoolCode',
      'searchApplicationsFilter',
      'filteredApplicationIds'
    ]),
    headers() {
      return this.isPendingTab ? this.pendingHeaders : this.processedHeaders
    },
    schoolCode() {
      return this.selectedSchoolCode
    },
    isOesSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isFiltered() {
      return (
        Boolean(this.selectedStatus.length) ||
        Boolean(this.selectedScholasticYear.length) ||
        this.selectedSiblings !== null ||
        Boolean(this.selectedStartDateRange.length) ||
        Boolean(this.selectedStartYear.length)
      )
    },
    schoolSettings() {
      return this.schoolOoaSettings(this.schoolCode)
    },
    visibleApplicationIds() {
      return this.applications
        .slice(0, this.pageLength)
        .map((a) => a.applicationID)
    },
    applicationIds() {
      return this.applications.map((a) => a.applicationID)
    },
    allSelected: {
      get() {
        const allIds = [...this.filteredApplicationIds]

        return (
          Object.keys(this.selectedIds).length &&
          allIds.every((id) => this.selectedIds[id] === true)
        )
      },
      set() {}
    },
    selectedIdsForDownload() {
      const keys = Object.keys(this.selectedIds)
      let selectedIds = []
      for (let k of keys) {
        if (this.selectedIds[k] === true) {
          const app = this.applications.filter((a) => a.applicationID === k)
          if (app.length > 0) {
            selectedIds.push({
              applicationID: app[0].applicationID,
              studentFirstName: app[0].student.firstName,
              studentFamilyName: app[0].student.familyName,
              supportingDocuments: app[0].supportingDocuments
            })
          }
        }
      }
      return selectedIds
    },
    applications() {
      //Convert [K, 1, 2] to [Kindergarten, Year 1, Year 2] for fixing the scholastic year sorting issue
      this.ooaApplications.map((item) => setScholasticYear(item))

      return (
        this.ooaApplications &&
        this.ooaApplications.filter((a) => {
          let Name =
              (this.searchApplicationsFilter || '') !== ''
                ? searchFilter(
                    [
                      a.application.student.familyName,
                      a.application.student.firstName
                    ],
                    this.searchApplicationsFilter
                  )
                : true,
            Status = this.selectedStatus.length
              ? this.filterStatus(a.applicationStatus)
              : true,
            ScholasticYear = this.selectedScholasticYear.length
              ? this.selectedScholasticYear.includes(a.scholasticYear)
              : true,
            StartDateRange = this.selectedStartDateRange.length
              ? this.checkStartDateInsideRange(a.intendedStartDate)
              : true,
            StartYear = this.selectedStartYear.length
              ? this.filterStartYear(
                  new Date(a.intendedStartDate).getFullYear().toString()
                )
              : true,
            NoSiblings =
              this.selectedSiblings !== null && this.selectedSiblings === 0
                ? a.noOfSiblings === 0
                : true,
            MoreSiblings =
              this.selectedSiblings !== null && this.selectedSiblings === 1
                ? a.noOfSiblings > 0
                : true
          return (
            Name &&
            Status &&
            ScholasticYear &&
            NoSiblings &&
            MoreSiblings &&
            StartDateRange &&
            StartYear
          )
        })
      )
    },
    statusFilters() {
      const applicationStatuses = [
        ...new Set(
          this.ooaApplications.map((app) => {
            // offered statuses all appear as "Offered"
            return OOA_OFFERED_STATUSES.includes(app.applicationStatus)
              ? OOA_STATUS.OFFERED
              : app.applicationStatus
          })
        )
      ]
      return this.sortStatuses(applicationStatuses)
    },
    scholasticYearFilters() {
      const applicationScholasticYear = [
        ...new Set(this.ooaApplications.map((app) => app.scholasticYear))
      ]
      return applicationScholasticYear.sort(function (a, b) {
        return sortByScholasticYear.indexOf(a) - sortByScholasticYear.indexOf(b)
      })
    },
    siblingsFilters() {
      return [
        ...new Set(
          this.ooaApplications.map((app) =>
            app.noOfSiblings === 0 ? 'No siblings' : '1 or more siblings'
          )
        )
      ].sort()
    },
    startDateFilter() {
      return { disabled: this.ooaApplications.length ? false : true }
    },
    downloadBtnAriaLabel() {
      return Object.keys(this.selectedIds).length > 1
        ? 'Download applications'
        : 'Download application'
    },
    yearOptions() {
      return [
        ...new Set(
          this.ooaApplications.map((app) =>
            new Date(app.intendedStartDate).getFullYear().toString()
          )
        )
      ].sort()
    }
  }
}
</script>

<style scoped lang="scss">
/**
 * TODO: Attention VUE 3 developers
 * Delete v-input--selection-controls class styles as part of Vue 3 / ADS 3 upgrade.
 */
::v-deep .v-input--selection-controls {
  margin-top: 0 !important;
  padding-top: 0 !important;
}
.ApplicationList {
  overflow: auto;
  min-width: 768px;
  padding-top: 20px;
  ::v-deep .ApplicationStatusChips {
    .v-chip {
      width: 90%;
    }
    .v-chip.text-center {
      justify-content: center;
    }
  }
  .v-card__actions {
    padding: 1rem 3rem;
    background: $grey-6;
  }
}

::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  .v-input__control > .v-input__slot:before {
    border-style: none;
    border-bottom: 1px solid $ads-light-20;
  }
  .v-input__slot {
    padding: 14px 0 18px 12px;

    .v-input__prepend-inner {
      margin-left: 10px;
    }

    .v-text-field__slot {
      margin-left: 16px;
    }
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    margin-right: 28px;
    border: none;

    &:focus {
      border: 1px solid $color-primary-lighten-1;
    }
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }

  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 25px 8px;
      vertical-align: top;

      &.considerations button {
        word-break: break-all;
        hyphens: auto;
      }
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }

      //pending applications
      &.actioned {
        width: 120px;
      }
      &.date-received {
        width: 110px;
      }

      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: 90px;
      }

      &.dob {
        width: 100px;
      }
      &.scholastic-year {
        width: 80px;
      }

      &.start-date {
        width: 95px;
      }

      &.status {
        width: 150px;
      }
      &.action {
        width: 82px;
        padding-right: 20px;
      }

      //processed applications
      &.last-modified {
        padding-left: 20px;
      }

      //ooa applications
      &.select-all {
        width: 60px;
      }
      &.siblings {
        width: 90px;
      }
      &.status-ooa {
        width: 150px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }

  div.v-data-footer {
    font-size: 14px;
  }
}

@include desktop {
  .ApplicationList {
    padding: 0rem 3rem 1.5rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }

    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }

    thead.v-data-table-header th.sortable {
      padding: 25px 10px 5px;

      //pending applications
      &.actioned {
        width: 120px;
      }
      &.date-received {
        width: 122px;
      }

      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 120px;
      }

      &.dob {
        width: 136px;
      }
      &.scholastic-year {
        width: 190px;
      }

      &.start-date {
        width: 150px;
      }

      &.status {
        width: 163px;
      }
      &.action {
        padding-right: 20px;
        width: 102px;
      }
      //processed applications
      &.last-modified {
        padding-left: 20px;
        width: 170px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
    }
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;
}
#app.v-application
  button.v-btn:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ):focus {
  background-color: $color-primary-lighten-1;
}
#app.v-application
  button.v-btn:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ):focus:before {
  opacity: 0;
}
#app.v-application
  button.theme--light.v-btn.v-btn:hover:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $color-primary-lighten-1;
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}
.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled .v-btn__loading {
  color: white !important;
}

.filterLabel {
  color: $color-placeholder;
}

.search-results-number {
  float: right;
  white-space: nowrap;
}

.data-table-header {
  align: right;
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}

.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}

.results-text {
  margin: 25px 20px 0 0 !important;
}
</style>
