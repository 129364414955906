<!--
  Provides the template for the application title and summary details at the top of
  the application.
-->
<template>
  <div class="Y67TSummary mr-8 float-right">
    <div v-if="showActions">
      <div aria-live="polite" class="sr-only">
        <p v-if="focusToSendErnBtn">Ready to send to HS. Send to HS button.</p>
      </div>
      <AdsButton
        class="mr-2 withdraw-btn"
        ref="pSwithdraw"
        x-large
        secondary
        :disabled="isEditing"
        @click="$emit('withdraw')"
        button-text="Withdraw"
        icon="mdi-delete-outline"
      />
      <AdsButton
        class="sendToErn-btn"
        ref="sendToErn"
        x-large
        aria-label="Send to HS"
        :disabled="!readyToSendERN"
        @click="$emit('sendToTracking')"
        button-text="Send to HS"
        icon="mdi-send-outline"
      />
    </div>
    <div v-if="isSecondarySchool">
      <AdsButton
        v-if="isActiveStatus"
        ref="Withdrawn"
        class="button"
        x-large
        secondary
        aria-label="Withdraw"
        button-text="Withdraw"
        icon="mdi-delete-outline"
        @click="$emit('setStatus', Y67T_STATUS.WITHDRAWN)"
      />
      <AdsButton
        v-if="isActiveStatus && !isDesignatedHighSchool"
        class="button red darken-1"
        ref="Declined"
        x-large
        aria-label="Decline"
        button-text="Decline"
        icon="mdi-close-circle-outline"
        @click="$emit('setStatus', Y67T_STATUS.DECLINED)"
      />
      <AdsButton
        v-if="isActiveStatus"
        class="button"
        ref="Offered"
        x-large
        aria-label="Offer"
        button-text="Offer"
        icon="mdi-school-outline"
        @click="$emit('setStatus', Y67T_STATUS.OFFERED)"
      />
    </div>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { STREAM, Y67T_STATUS_CHIPS, Y67T_STATUS } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'Y67TSummary',
  mixins: [fieldHelperMixin],
  components: {
    AdsButton
  },
  props: {
    focusButton: {
      type: String
    }
  },
  data() {
    return {
      Y67T_STATUS
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchool',
      'application',
      'isEditing',
      'focusToSendErnBtn',
      'currentStream'
    ]),
    state() {
      return this.$store.state
    },
    isActiveStatus() {
      return this.applicationStatus === 'Active'
    },
    contact() {
      return this.getValue('parentCarers').find(
        (parent) => parent.parentCarerCompletingApp
      )
    },
    contactName() {
      const contact = this.contact
      if (contact) {
        return `${contact.parentCarerGivenName} ${contact.parentCarerFamilyName}`
      }
      return ''
    },
    applicationStatus() {
      return this.$store.state.application.applicationStatus
    },
    contactType() {
      const contact = this.contact
      if (contact && contact.contactDetails.length > 0) {
        return `${contact.contactDetails[0].contactType}`
      }
      return ''
    },
    showActions() {
      return (
        !this.isSecondarySchool &&
        [Y67T_STATUS.NEW, Y67T_STATUS.IN_PROGRESS].includes(
          this.applicationStatus
        )
      )
    },
    readyToSendERN() {
      return (
        this.$store.getters.readyToSendErn &&
        this.$store.getters.isAddressConflict === false &&
        this.$store.state.application?.student?.SRN
      )
    },
    isSecondarySchool() {
      return this.currentStream === STREAM.Y67T_SECONDARY
    },
    hasResidentialAddress() {
      return this.application.residentialAddress
    },
    isDesignatedHighSchool() {
      return (
        this.selectedSchool.schoolName ===
        this.$store.state.application?.localHighSchool?.schoolName
      )
    }
  },
  methods: {
    contactDetails(type) {
      var contactDetails = (this.contact && this.contact.contactDetails) || []
      var phone = contactDetails.find((item) => item.contactType === type) || {}
      return phone.contactValue
    },
    getStatusChipDetails(status) {
      return Y67T_STATUS_CHIPS[status]
    }
  },
  watch: {
    focusToSendErnBtn: {
      immediate: true,
      handler(val) {
        if (val) {
          const sendToErnBtn = this.$refs.sendToErn
            ? this.$refs.sendToErn.$el
            : null
          if (sendToErnBtn) {
            setTimeout(() => sendToErnBtn.focus(), 0)
          }
        }
      }
    },
    focusButton() {
      requestAnimationFrame(() => {
        this.$refs[this.focusButton]?.$el.focus()
        this.$emit('focusReset')
      })
    }
  }
}
</script>

<style scoped lang="scss">
#app.v-application button.v-btn:not(.v-btn--round).review-btn {
  color: black;
}
::v-deep .ApplicationStatusChips {
  width: 9.0625rem;
  font-weight: 700;
  .v-chip__content span,
  .v-chip__content {
    width: 100%;
    text-align: center;
  }
}
.Y67TSummary {
  float: right;
  .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
      .v-btn--outlined
    ) {
    background-color: $grey-darken1 !important;
    color: white !important;
    .v-icon {
      color: white !important;
    }
  }
}
.addressDetails {
  padding: 1rem 1rem 1rem 2rem;
  .label {
    color: $color-dark70;
    white-space: nowrap;
    padding-right: 0.5rem;
    font-size: 14px;
    margin-left: 0;
  }
  .value {
    color: $color-primary;
    padding-right: 3rem;
  }
}
.details {
  display: flex;
}
.detail {
  flex: 1;
  margin-right: 12px;
  &__label {
    color: #666666;
    white-space: nowrap;
    margin-bottom: 8px;
  }
}
::v-deep .sendToErn-btn.theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

.theme--light.v-btn.v-btn--outlined:focus {
  box-shadow: 0 0 0 2px $ads-navy !important;
}
</style>
