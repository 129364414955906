<template>
  <v-dialog max-width="630" persistent v-bind="$attrs" v-on="$listeners">
    <FocusTrap>
      <v-card class="modal withdrawModal">
        <v-btn
          class="modal__close-button float-right"
          icon
          aria-label="close"
          @click="$emit('input', false)"
        >
          <v-icon> close </v-icon>
        </v-btn>
        <div class="content">
          <h1 class="heading pr-3" data-testid="heading">
            <span class="highlight">Withdraw</span>
            <span v-if="isPnrAdvice"> placement not required</span>
            <span v-else> expression of interest </span>
            for {{ studentName }}?
          </h1>
          <p class="body">
            Are you sure you want to withdraw this
            <span v-if="isPnrAdvice">submission of placement not required</span>
            <span v-else>expression of interest</span>?
          </p>
          <AdsButton
            @click="withdraw"
            id="withdraw-btn"
            class="button red darken-1"
            data-testid="withdraw-btn"
            button-text="Withdraw"
          />
        </div>
      </v-card>
    </FocusTrap>
  </v-dialog>
</template>

<script>
import { FocusTrap, AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'WithdrawModal',
  components: {
    FocusTrap,
    AdsButton
  },
  props: {
    studentName: {
      type: String
    },
    isPnrAdvice: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    withdraw() {
      this.$emit('input', false)
      this.$store.dispatch('withdrawY67TApplication').then(() => {
        this.$router.push({ path: '/' })
        const message = `<strong>${this.studentName}'s</strong> application is now set to <strong>"Withdrawn"</strong>. An email has been sent.`
        this.$store.dispatch('showSnackbar', {
          display: true,
          html: message,
          icon: {
            name: 'mdi-delete-outline',
            color: 'dark70'
          }
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  padding: 2rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
}

.body {
  margin: 3.5rem 0;
}
.withdrawModal {
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 0.75rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .theme--light.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .theme--light.v-btn.primary[data-v-040d6c82]:not(.v-btn--text):not(
      .v-btn--outlined
    ):focus {
    border: none !important;
  }
}

.highlight {
  color: $color-training;
}

#app #withdraw-btn {
  height: 50px;
  padding: 0 23px;
  width: 230px;
  margin: 0 -1rem -1rem 0;
  align-self: flex-end;
}
</style>
