<template>
  <div>
    <DialogModal
      v-if="showDocStatusModal"
      data-testid="documents-status-modal"
      class="downloading-modal"
      :is-persistent="true"
      @close="$emit('close')"
    >
      <template slot="header">
        <div v-if="applicationPDFUnavailable !== 'ALL'" class="heading">
          <span class="mr-2">
            <v-icon color="primary">mdi-lock-outline</v-icon>
          </span>
          <span class="font-weight-bold" role="alert"
            >Please keep these applications secure</span
          >
        </div>
        <div v-if="applicationPDFUnavailable === 'ALL'" class="heading">
          <span class="mr-2">
            <v-icon color="primary">mdi-alert-outline</v-icon>
          </span>
          <span class="font-weight-bold" role="alert"
            >This application is unavailable for download</span
          >
        </div>
      </template>

      <div slot="body">
        <div class="content">
          <p v-if="applicationPDFUnavailable !== 'ALL'">
            Please ensure once you download this document that it is kept in a
            secure location within the school premises.
          </p>
          <p v-if="applicationPDFUnavailable === 'ALL'">
            Sorry, the PDF of this application cannot be downloaded as it is
            currently missing.
          </p>
          <div
            v-if="applicationPDFUnavailable === 'FEW'"
            data-testid="docs-unavailable-msg"
          >
            <div class="sub-header mb-4">
              <span class="mr-2">
                <v-icon color="primary">mdi-alert-outline</v-icon>
              </span>
              <span
                v-if="applicationPDFUnavailableCount > 1"
                class="font-weight-bold"
                role="alert"
                >{{ applicationPDFUnavailableCount }} documents are unavailable
                for download</span
              >
              <span
                v-if="applicationPDFUnavailableCount === 1"
                class="font-weight-bold"
                role="alert"
                >{{ applicationPDFUnavailableCount }} document is unavailable
                for download</span
              >
            </div>
            <p v-if="applicationPDFUnavailableCount > 1">
              The following documents are unavailable because they are currently
              missing:
            </p>
            <p v-if="applicationPDFUnavailableCount === 1">
              The following document is unavailable because it is currently
              missing:
            </p>
          </div>
          <!--
          FUS-1813: Changes to eslint rules now disallow the use of
          v-if with v-for on the same element. The error for the next
          block has been disabled as the application and components
          are currently working.
          -->
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <div
            v-for="(supportingDocument, i) in supportingDocuments"
            v-if="
              supportingDocument.status === 'MISSING' &&
              applicationPDFUnavailable === 'FEW'
            "
            :key="i"
            class="body-1 my-0 py-0 mb-5 docs-item vc"
          >
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
            <div class="font-weight-bold mb-4 docs-item-title">
              {{ supportingDocument.name }}
            </div>
            <div class="mb-4 docs-item-content">
              <div v-if="supportingDocument.status === 'MISSING'">
                <div class="mb-1">
                  <span class="mr-2">
                    <v-icon color="primary">mdi-file-outline</v-icon>
                  </span>
                  <span class="font-weight-bold">
                    {{ `${supportingDocument.applicationID}.pdf` }}
                  </span>
                </div>
                <div>
                  <span class="mr-2">
                    <v-icon color="red darken-1">mdi-alert-circle</v-icon>
                  </span>
                  <span>{{ docMessageByStatus('MISSING') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="fileUnavailable && applicationPDFUnavailable !== 'ALL'"
            data-testid="docs-unavailable-msg"
          >
            <div class="sub-header mb-4">
              <span class="mr-2">
                <v-icon color="primary">mdi-alert-outline</v-icon>
              </span>
              <span class="font-weight-bold" role="alert"
                >Some supporting document(s) are unavailable</span
              >
            </div>
            <p>
              Some of the supporting document(s) from the selected
              application(s) cannot be downloaded as they may be infected, or
              are currently missing. The document(s) are listed below:
            </p>
          </div>
          <!--
          FUS-1813: Changes to eslint rules now disallow the use of
          v-if with v-for on the same element. The error for the next
          block has been disabled as the application and components
          are currently working.
          -->
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <div
            v-for="(supportingDocument, i) in supportingDocuments"
            v-if="
              supportingDocument.fileUnavailable &&
              applicationPDFUnavailable !== 'ALL'
            "
            :key="i"
            class="body-1 my-0 py-0 mb-2 docs-item vc"
          >
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
            <div class="font-weight-bold mb-4 docs-item-title">
              {{ supportingDocument.name }}
            </div>
            <div
              v-for="(doc, j) in supportingDocument.docs"
              :key="j"
              class="mb-4 docs-item-content"
            >
              <div v-if="doc.status !== 'SAFE'">
                <div class="mb-1">
                  <span class="mr-2">
                    <v-icon color="primary">mdi-file-outline</v-icon>
                  </span>
                  <span class="font-weight-bold">{{
                    fileName(doc.objectKey)
                  }}</span>
                </div>
                <div>
                  <span class="mr-2">
                    <v-icon color="red darken-1">mdi-alert-circle</v-icon>
                  </span>
                  <span>{{ docMessageByStatus(doc.status) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="applicationPDFUnavailable !== 'ALL'" slot="footer">
        <AdsButton
          @click="startDownload"
          class="ma-2"
          primary
          large
          height="50"
          data-testid="start-download"
          aria-label="Download"
          icon="mdi-download-outline"
          button-text="Download"
        />
      </div>
    </DialogModal>
    <AppModal
      v-if="showDownloadProgressModal"
      ref="AppModal"
      data-testid="download-documents-modal"
      class="downloading-modal"
      role-type="none"
    >
      <span slot="header">
        <v-avatar size="40" color="primary lighten-4" class="mr-4">
          <v-icon color="primary">mdi-download-outline</v-icon>
        </v-avatar>
        <span class="font-weight-bold" role="alert"
          >Preparing {{ count }} applications for download</span
        >
      </span>

      <div slot="body">
        <div class="content">
          <v-progress-linear
            indeterminate
            color="primary lighten-1"
            class="mt-2 mb-6"
          ></v-progress-linear>

          <p>
            We are preparing a zip archive of the applications you have
            selected. This window will
            <strong>close automatically</strong> once the download commences.
          </p>
          <p>
            <strong>Note:</strong> for a large number of applications, this may
            take several minutes.
          </p>
        </div>
      </div>

      <div slot="footer">
        <AdsButton
          icon="mdi-delete-outline"
          @click="cancelDownload"
          button-text="Cancel download"
          aria-label="Cancel download"
        />
      </div>
    </AppModal>
    <AppModal
      v-if="downloadError || count > 100"
      ref="AppModal"
      data-testid="download-error-modal"
      role-type="none"
    >
      <template slot="header">
        <v-icon @click="$emit('close')" class="text-right closeBtn">
          mdi-close
        </v-icon>
        <div class="heading px-3">
          <v-avatar size="40" color="primary lighten-4" class="mr-4">
            <v-icon color="primary">mdi-download-outline</v-icon>
          </v-avatar>
          <span class="font-weight-bold" role="alert">
            Error downloading application(s)
          </span>
        </div>
      </template>
      <template slot="body">
        <div class="content mt-3 mx-3">
          <p>
            Sorry, the application(s) you selected cannot be downloaded at this
            time.
            {{
              count > 100
                ? 'If the number of applications selected exceeded the maximum limit of 100, please download in batches of up to 100 applications.'
                : 'Please try again later.'
            }}
          </p>
          <p>
            If this problem persists, contact support on
            <strong>1300 32 32 32</strong>.
          </p>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import DialogModal from '@/components/app/DialogModal.vue'
import { APPLICATION_TYPE, FILE_STATUSES } from '@/constants'
import { AdsButton } from '@nswdoe/doe-ui-core'

function getTransformedDocs(supportingDocuments) {
  let docs = []

  if (
    supportingDocuments &&
    supportingDocuments['digitizeEoiConsiderations'] &&
    supportingDocuments['digitizeEoiConsiderations'].length > 0
  ) {
    docs = supportingDocuments['digitizeEoiConsiderations'].map((doc) => ({
      objectKey: doc.objectKey
    }))
  }

  return docs
}

export default {
  name: 'Y67TDownloadDocuments',
  components: {
    AppModal,
    DialogModal,
    AdsButton
  },
  props: {
    applications: {
      type: Array,
      default: () => []
    },
    schoolCode: {
      type: String,
      required: true
    },
    applicationType: {
      type: String
    }
  },
  data() {
    return {
      showDocStatusModal: true,
      showDownloadProgressModal: false,
      hasCancelled: false,
      downloadError: false,
      supportingDocuments: [],
      downloadApplications: []
    }
  },
  computed: {
    count() {
      return this.applications?.length
    },
    applicationPDFUnavailableCount() {
      return this.supportingDocuments.filter((d) => d.status === 'MISSING')
        .length
    },
    applicationPDFUnavailable() {
      switch (this.applicationPDFUnavailableCount) {
        case 0:
          return 'NONE'
        case this.supportingDocuments.length:
          return 'ALL'
        default:
          return 'FEW'
      }
    },
    fileUnavailable() {
      return this.supportingDocuments?.some((doc) => doc.fileUnavailable)
    }
  },
  methods: {
    // init separated from mounted so it can be mocked in tests
    init() {
      if (
        (this.applications?.length === 1 &&
          this.applications[0]?.isDigitizeEOIConsiderationDocUploaded) ||
        this.applications?.length > 1
      ) {
        this.initDocStatus()
      }
    },
    initDocStatus() {
      this.$store.dispatch('showSpinner')

      const applicationsInfo = this.applications?.map((app) => ({
        id: app.applicationID,
        docs: getTransformedDocs(app.supportingDocuments)
      }))
      this.$store
        .dispatch('getAppsDocStatus', {
          applicationsInfo,
          schoolCode: this.schoolCode,
          applicationType: this.applicationType
        })
        .then((data) => {
          this.supportingDocuments = data.supportingDocuments.map((app) => {
            const selApp = this.applications?.filter(
              (a) => a.applicationID === app.applicationID
            )
            if (selApp.length > 0) {
              app.name =
                selApp[0].studentFamilyName + ',' + selApp[0].studentFirstName
              app.studentFirstName = selApp[0].studentFirstName
              app.studentFamilyName = selApp[0].studentFamilyName
            }
            app.fileUnavailable = false
            for (let i = 0; i < app.docs.length; i++) {
              const doc = app.docs[i]
              if (doc.status !== FILE_STATUSES.SAFE) {
                app.fileUnavailable = true
              }
            }
            return app
          })
          this.$store.dispatch('hideSpinner')
        })
        .catch((error) => {
          if (error) {
            this.downloadError = true
            this.$store.dispatch('hideSpinner')
          }
        })
    },
    docMessageByStatus(status) {
      let msg = 'This file cannot be downloaded.'

      if (status === FILE_STATUSES.INFECTED) {
        msg = 'This file is infected and cannot be downloaded.'
      } else if (status === FILE_STATUSES.TO_BE_SCANNED) {
        msg = 'This file has not been virus scanned and cannot be downloaded.'
      } else if (status === FILE_STATUSES.MISSING) {
        msg = 'This file is missing and cannot be downloaded.'
      }
      return msg
    },
    fileName(objectKey) {
      return objectKey.split('/').slice(-1)[0]
    },
    startDownload() {
      this.showDocStatusModal = false
      if (
        (this.applications?.length === 1 &&
          this.applications[0]?.isDigitizeEOIConsiderationDocUploaded) ||
        this.applications?.length > 1
      ) {
        this.startZipDownload()
      } else {
        this.startPDFDownload(
          this.applications[0]?.applicationID,
          this.schoolCode,
          this.applicationType
        )
      }
    },
    startZipDownload() {
      this.downloadApplications = this.supportingDocuments.map((app) => {
        let supportingDocuments = app.docs
          .filter((doc) => doc.status === FILE_STATUSES.SAFE)
          .map((doc) => doc.objectKey)
        supportingDocuments =
          supportingDocuments && supportingDocuments.length > 0
            ? supportingDocuments
            : undefined
        return {
          id: app.applicationID,
          status: app.status,
          studentFirstName: app.studentFirstName,
          studentFamilyName: app.studentFamilyName,
          supportingDocuments
        }
      })

      this.downloadApplications = this.downloadApplications?.filter(
        (downApp) => downApp.status !== 'MISSING'
      )
      if (this.downloadApplications.length >= 20) {
        this.showDownloadProgressModal = true
      }
      if (!this.showDownloadProgressModal) {
        this.$store.dispatch('showSpinner')
      }
      if (
        !this.downloadApplications ||
        this.downloadApplications.length === 0
      ) {
        this.$store.dispatch('hideSpinner')
        return
      }
      this.$store
        .dispatch('getSupportingDocumentsPDFs', {
          downloadApplications: this.downloadApplications,
          schoolCode: this.schoolCode,
          applicationType: APPLICATION_TYPE.Y67T
        })
        .then((data) => {
          if (!this.hasCancelled) {
            if (data.body) {
              // Set an artificial delay to avoid AWS access denied error FUS-332
              // This is hopefully a temporary work-around until we can find and fix the underlying cause
              // where the zip is not actually available in S3 when the signed url is returned.
              const dynamicTimeout =
                Math.floor(this.downloadApplications.length / 10) * 100 || 500
              setTimeout(() => {
                this.doDownload(
                  data.body.Location,
                  'Year 7 Expression of Interest (multiple)'
                )
              }, dynamicTimeout)
            } else {
              const pdfData = `data:application/pdf;base64,${data}`
              const fileName = `${this.downloadApplications[0]['id']}`
              // Workaround as IE prevents blob URIs due to security restrictions
              // stackoverflow.com/a/45732897
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                const pdfDataBlob = this.pdfBlobConversion(pdfData)
                window.navigator.msSaveOrOpenBlob(
                  pdfDataBlob,
                  `${fileName}.pdf`
                )
                this.$emit('close')
                this.$store.dispatch('hideSpinner')
                return
              }
              this.doDownload(pdfData, `${fileName}.pdf`)
            }
          }
        })
        .catch((error) => {
          if (error) {
            this.downloadError = true
          }
        })
        .finally(() => {
          this.$store.dispatch('hideSpinner')
        })
    },
    doDownload(href, fileName) {
      if (this.hasCancelled) {
        return
      }
      const link = document.createElement('a')

      link.href = href
      link.download = fileName
      link.click()

      this.$emit('close')
    },
    async startPDFDownload(applicationId, schoolCode, applicationType) {
      const fileName = this.getFileName(applicationId)
      const pdfData = await this.$store
        .dispatch('getApplicationPDF', {
          applicationId,
          schoolCode,
          applicationType
        })
        .then((data) => `data:application/pdf;base64,${data}`)
        .catch((error) => {
          if (error) {
            this.downloadError = true
          }
        })

      if (pdfData) {
        // Workaround as IE prevents blob URIs due to security restrictions
        // stackoverflow.com/a/45732897
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const pdfDataBlob = this.pdfBlobConversion(pdfData)
          window.navigator.msSaveOrOpenBlob(pdfDataBlob, `${fileName}.pdf`)
          this.$emit('close')
          return
        }
        this.doDownload(pdfData, fileName)
      }
    },
    cancelDownload() {
      this.$emit('close')
      this.hasCancelled = true
    },
    // base64-blob library not working
    // From stackoverflow.com/a/48909758
    pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || ''
      var sliceSize = 512
      b64Data = b64Data.replace(/^[^,]+,/, '')
      b64Data = b64Data.replace(/\s/g, '')
      var byteCharacters = window.atob(b64Data)
      var byteArrays = []

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset = offset + sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize)

        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      var blob = new Blob(byteArrays, { type: contentType })
      return blob
    },
    getFileName(applicationId) {
      return `Year 7 Expression of Interest - ${applicationId}`
    }
  },
  mounted() {
    this.init()
  },
  destroyed() {
    this.hasCancelled = true
  }
}
</script>

<style lang="scss" scoped>
::v-deep .AppModal {
  text-align: left;
  white-space: normal;

  .box {
    padding: 1rem;
    max-width: 722px;
  }

  .heading {
    font-size: 1.3333rem;
  }

  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
  }

  .docs-item {
    background: $grey-light-10;
    border-radius: 4px;
    padding: 10px 20px !important;

    .docs-item-title {
      font-size: 1.125rem;
    }

    .docs-item-content {
      font-size: 0.875rem;
    }
  }
}

::v-deep .header {
  flex-direction: column;
}

.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.3333rem;
}

.closeBtn {
  margin: -0.25rem -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}

.downloading-modal ::v-deep .header {
  display: flex;
  align-items: flex-start;
}
.download-error-modal {
  .header {
    font-size: 20px;
    font-weight: 700;
  }
}

.modal {
  padding: 2rem;
}
.footer {
  text-align: right;
}

.modal {
  padding: 2rem;
}
</style>
