<template>
  <div>
    <AppModal
      v-if="showExportConfirmModal"
      ref="AppModal"
      data-testid="export-confirm-modal"
      class="export-modal"
    >
      <template slot="header">
        <v-icon
          ref="closeButton"
          @click="$emit('close')"
          class="text-right closeBtn"
          aria-label="Close"
          data-testid="export-close-modal"
        >
          mdi-close
        </v-icon>
        <div class="heading">
          <span class="font-weight-bold">Please keep this secure</span>
        </div>
      </template>
      <div slot="body">
        <div class="content">
          <p>
            Please ensure once you export this document that it is kept in a
            secure location within the school premises.
          </p>
        </div>
      </div>
      <div slot="footer">
        <JsonCsv :name="`${fileName}`" :data="applications" header="true">
          <AdsButton
            button-text="Continue"
            aria-label="Continue"
            data-testid="export-continue-btn"
            @click="$emit('close')"
          />
        </JsonCsv>
      </div>
    </AppModal>
  </div>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import { AdsButton } from '@nswdoe/doe-ui-core'
import JsonCsv from 'vue-json-csv'

export default {
  name: 'ExportListAsCsv',
  components: {
    AppModal,
    AdsButton,
    JsonCsv
  },
  props: {
    applications: {
      type: Array,
      default: () => []
    },
    fileName: {
      type: String,
      default: 'data.csv'
    }
  },
  data() {
    return {
      showExportConfirmModal: true
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .AppModal {
  text-align: left;
  white-space: normal;
  .box {
    padding: 1rem;
    max-width: 550px;
  }
  .heading {
    font-size: 1.3333rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
  }
}
::v-deep .header {
  flex-direction: column;
}
.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.closeBtn {
  margin: 0 -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}
.footer {
  text-align: right;
}
.theme--light.v-icon.v-icon--link {
  color: $color-dark70;
  &:focus {
    color: $color-primary;
    outline: 3px solid $color-primary;
    border: none;
  }
}
</style>
