<template>
  <v-dialog value="true" persistent width="630">
    <v-card class="WithdrawApplication pa-4">
      <v-btn
        @click="close"
        class="ma-0 primary--text withdraw-close-btn"
        right
        absolute
        fab
        small
        color="white"
        depressed
        aria-label="Close withdraw application dialog"
        id="btn-close-withdraw-application"
      >
        <v-icon>close</v-icon>
      </v-btn>

      <v-container class="px-8 my-5" v-if="applicationWithdrawn === 'pending'">
        <v-row justify="start" align="center">
          <h1>
            <span class="withdraw-title-highlight">Withdraw</span> enrolment
            application for {{ studentName }}?
          </h1>
        </v-row>
        <v-row justify="end" class="mt-5">
          <p>
            This application will be permanently removed from the pending
            applications view and sent to
            <strong>"Processed Applications"</strong>
          </p>
        </v-row>
        <v-row justify="end">
          <v-card>
            <OesCheckbox
              v-model="sendWithdrawEmail"
              class="mr-1"
              data-testid="send-withdraw-email-checkbox"
            >
              <template #label>
                <p>
                  Email parent/carer <strong>{{ parentName }}</strong> to advise
                  the application has been withdrawn.
                  <strong
                    ><a
                      class="d-block mt-5"
                      @click.stop
                      target="_blank"
                      href="https://education.nsw.gov.au/platoapps/online-enrolment-system/online-enrolment-guides"
                      >View the email template</a
                    ></strong
                  >
                </p>
              </template>
            </OesCheckbox>
          </v-card>
        </v-row>
        <v-row justify="end" class="mt-5">
          <v-btn color="red withdraw-btn" @click="withdrawApplication">
            Confirm withdrawal
          </v-btn>
        </v-row>
      </v-container>

      <v-container
        class="pl-5 pr-5 pb-0 pt-0"
        v-if="applicationWithdrawn === 'withdrawn'"
      >
        <v-row justify="start" align="center">
          <v-col cols="1" class="align-center justify-center d-flex pa-0">
            <v-avatar color="grey darken-1" size="40">
              <v-icon color="white" size="34" class="rotated90"
                >mdi-power-cycle</v-icon
              >
            </v-avatar>
          </v-col>
          <v-col cols="11">
            <h1>Application withdrawn</h1>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="11">
            <p>This application has been successfully withdrawn.</p>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-btn color="primary" @click="backToApplications"
            >Back to applications</v-btn
          >
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import OesCheckbox from '@/components/app/OesCheckbox'
import { mapGetters } from 'vuex'
import UTILS from '@/store/utils'

export default {
  name: 'WithdrawApplication',
  components: {
    OesCheckbox
  },
  data() {
    return {
      sendWithdrawEmail: false
    }
  },
  computed: {
    ...mapGetters(['oesProperties']),
    application() {
      return this.$store.state.application
    },
    applicationWithdrawn() {
      return this.$store.state.applicationWithdrawn
    },
    studentName() {
      let firstName
      let familyName
      if (this.application) {
        firstName = this.application.student.firstName || ''
        familyName = this.application.student.familyName || ''
      }
      if (firstName || familyName) {
        return (firstName + ' ' + familyName).trim()
      }
      return 'this student'
    },
    parentName() {
      let name = ''

      const parent = this.application.parentCarers.find(
        (pc) => pc.isEnrolmentOwner
      )

      if (parent) {
        name = `${parent.parentCarerGivenName} ${parent.parentCarerFamilyName}`
      }

      return name
    }
  },
  methods: {
    close() {
      this.$store.dispatch('set', ['openWithdrawModal', false])
    },
    backToApplications() {
      this.close()
      this.$router.push('/')
    },
    withdrawApplication() {
      const params = {
        applicationStatus: 'Withdrawn',
        sendWithdrawEmail: this.sendWithdrawEmail
      }

      if (this.application.student.srn) {
        params.SRN = this.application.student.srn
      }
      if (
        UTILS.isNextCalendarYear7LocalApp(this.application, this.oesProperties)
      ) {
        // Call unstampedLocalApplication API before withdraw application...
        this.$store.dispatch('y67tCoreLinkUnlinkSrn', params)
      } else if (
        UTILS.isNextCalendarYear7OoaApp(this.application, this.oesProperties)
      ) {
        this.$store.dispatch('y67tOoaLinkUnlinkSrn', params)
      } else {
        this.$store.dispatch('withdrawApplication', this.sendWithdrawEmail)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.WithdrawApplication {
  h1 {
    color: $color-primary;
    width: 90%;

    .withdraw-title-highlight {
      color: $color-red;
    }
  }
  .v-card {
    background: $grey-light-10;
    box-shadow: none;
    padding-left: 20px;

    ::v-deep .v-input__slot {
      align-items: initial;
    }

    ::v-deep .v-input--selection-controls__input .v-icon {
      color: $color-btn-disabled-text-default;
    }

    ::v-deep .v-input--selection-controls .v-input__slot > .v-label {
      color: $grey-dark;
    }
  }

  button.v-btn.withdraw-close-btn {
    border: none;
    &:focus {
      box-shadow: 0 0 0 2px $color-focus !important;
    }
  }
  button.v-btn.withdraw-btn {
    border: none;
    &:focus {
      box-shadow: 0 0 0 4px $color-red-border;
    }
  }
}
</style>
