import { render, staticRenderFns } from "./ClearFilters.vue?vue&type=template&id=20172a47&scoped=true&"
import script from "./ClearFilters.vue?vue&type=script&lang=js&"
export * from "./ClearFilters.vue?vue&type=script&lang=js&"
import style0 from "./ClearFilters.vue?vue&type=style&index=0&id=20172a47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20172a47",
  null
  
)

export default component.exports