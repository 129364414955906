<template>
  <v-dialog max-width="520" v-bind="$attrs" v-on="$listeners">
    <v-card class="modal sendToErnModal">
      <v-btn
        class="modal__close-button float-right"
        icon
        aria-label="close"
        @click="close"
      >
        <v-icon> close </v-icon>
      </v-btn>
      <div class="content">
        <div class="header d-flex align-center">
          <v-avatar color="primary lighten-4" size="40">
            <v-icon class="ml-1" color="primary"> mdi-send-outline </v-icon>
          </v-avatar>
          <h1 class="heading ml-4" data-testid="heading">Send to HS</h1>
        </div>
        <p class="body">
          <strong>Please note:</strong> <br />
          By sending this EOI to the high school you will WITHDRAW all other
          EOIs that are linked to this SRN
        </p>
        <div class="actions d-flex justify-end align-center">
          <AdsButton
            tertiary
            :ripple="false"
            class="cancel-btn primary--text"
            data-testid="cancel-btn"
            button-text="Cancel"
            @click="close"
          />
          <AdsButton
            @click="sendToTracking"
            class="button"
            data-testid="send-btn"
            button-text="Send to HS"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'SendToERNConfirmationModal',
  components: { AdsButton },
  props: {
    studentName: {
      type: String
    }
  },
  computed: {
    application() {
      return this.$store.getters.application || {}
    }
  },
  methods: {
    sendToTracking() {
      this.$emit('input', false)
      this.$store
        .dispatch('sendToTracking', this.$store.state.application.applicationID)
        .then(() => {
          this.$router.push({ path: '/' })
          const studentName = `${this.application.student.firstName} ${this.application.student.familyName}`
          const message = `<strong>${studentName}'s</strong> application has been sent to HS.`
          this.$store.dispatch('showSnackbar', {
            display: true,
            html: message,
            icon: {
              name: 'mdi-send',
              color: 'success'
            }
          })
        })
    },
    close() {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped lang="scss">
.modal {
  padding: 1.6rem;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
}

.body {
  margin: 1.5rem 0;
  line-height: 1.5;
}
.sendToErnModal {
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 0.75rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
}

.highlight {
  color: $color-training;
}

.actions {
  button.v-btn {
    padding: 0.9rem;
  }
}

.cancel-btn {
  border: none;
  font-weight: bold;

  &:hover::before {
    background-color: transparent;
  }

  &:focus {
    outline: 1px solid currentColor;
  }
}
</style>
