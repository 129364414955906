<template>
  <div class="py-6 grey lighten-6">
    <v-row class="pb-3" justify="end" v-if="isERNAdmin">
      <v-col cols="auto">
        <AdsButton
          class="mr-0"
          @click="openExportModal"
          :disabled="isDisableExportCsv"
          button-text="Export list as CSV"
          icon="list_alt"
          secondary
        />
      </v-col>
    </v-row>
    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="applications"
        search-label="Find student"
        :search="internalSearch"
        :no-results-found="'No results found. Try removing some filters or clearing the keyword search.'"
        :no-data-text="'No results found. Try removing some filters or clearing the keyword search.'"
        sort-by="name"
        sort-asc
        must-sort
        :items-per-page="pageLength"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        :custom-filter="filterApplications"
        @update:page="updatePagination"
        @pagination="totalResults"
        :page.sync="currentPage"
        data-testid="y67tUnlinkedStudentsList"
      >
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>{{ resultsText(itemsLength) }}</span>
            </v-col>
          </v-row>
        </template>
        <template #[`item.parent`]="{ item }">
          <div
            v-if="
              item.familyContact.contactDetails &&
              item.familyContact.contactDetails.length > 0
            "
          >
            <div class="py-1">Family</div>
          </div>
          <div
            v-for="parentCarer in item.parentCarers"
            :key="parentCarer.parentCarerGivenName"
            class="py-1"
          >
            {{ parentCarer.parentCarerFamilyName }},
            {{ parentCarer.parentCarerGivenName }}
          </div>
        </template>
        <template #[`item.email`]="{ item }">
          <div
            v-if="
              item.familyContact.contactDetails &&
              item.familyContact.contactDetails.length > 0
            "
            class="py-1"
          >
            <span v-if="getFamilyEmail(item.familyContact.contactDetails)">{{
              getFamilyEmail(item.familyContact.contactDetails)
            }}</span>
            <span v-else>&nbsp;</span>
          </div>
          <div
            v-for="parentCarer in item.parentCarers"
            :key="parentCarer.parentCarerGivenName"
            class="py-1"
          >
            <div v-if="parentCarer.checkERN" class="checkERN">
              <v-icon>mdi-alert-outline</v-icon>
              <span class="srn-text">Check ERN to get contact details</span>
            </div>
            <div v-else>
              {{
                parentCarer.parentCarerEmail !== 'null'
                  ? parentCarer.parentCarerEmail
                  : '&nbsp;'
              }}
            </div>
          </div>
        </template>
        <template #[`item.phone`]="{ item }">
          <div
            v-if="
              item.familyContact.contactDetails &&
              item.familyContact.contactDetails.length > 0
            "
            class="py-1"
          >
            <span v-if="getFamilyPhone(item.familyContact.contactDetails)">{{
              getFamilyPhone(item.familyContact.contactDetails)
            }}</span>
            <span v-else>&nbsp;</span>
          </div>
          <div
            v-for="parentCarer in item.parentCarers"
            :key="parentCarer.parentCarerGivenName"
            class="py-1"
          >
            <div v-if="!parentCarer.checkERN">
              <div
                v-for="contact in parentCarer.contactDetails"
                :key="contact.contactValue"
              >
                {{ contact.contactValue }}
              </div>
            </div>
            <div v-else></div>
          </div>
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn
            text
            icon
            small
            class="digitise-entrypoint-button"
            @click="openDigitiseEntryPointModal(item)"
            :aria-label="`Add new EOI or PNR for ${item.name}`"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
      </AdsDataTable>
      <Y67TDigitiseEntryPointModal
        v-if="showDigitiseEntryPointModal"
        :selected-item="selectedItem"
        @close="closeDigitiseEntryPointModal"
        @proceed="proceedApplication"
      />
      <ApplicationModal
        v-if="displayApplicationModal"
        :type="applicationType"
        :item="selectedItem"
        @close="closeApplicationModal"
      />
      <ExportListAsCsv
        v-show="showExportModal"
        :applications="applicationsExportCsv"
        :file-name="csvFileName"
        @close="closeExportModal"
      />
    </v-card>
  </div>
</template>

<script>
import Y67TDigitiseEntryPointModal from '@/components/applicationList/Y67TDigitiseEntryPointModal.vue'
import ApplicationModal from '@/components/application/y67t/paperEoI/ApplicationModal.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { searchFilter, searchFilterForStringOnly } from '@/helpers/searchHelper'
import { AdsDataTable, AdsButton } from '@nswdoe/doe-ui-core'
import { APPLICATION_TYPE, STREAM, USER_GROUP } from '@/constants'
import ExportListAsCsv from '@/components/applicationList/ExportListAsCsv.vue'

const headers = [
  {
    text: 'Student name',
    value: 'name',
    class: 'name',
    align: 'start'
  },
  {
    text: 'SRN',
    value: 'student.SRN',
    class: 'srn',
    align: 'start'
  },
  {
    text: 'Family/Parent/Carer',
    value: 'parent',
    class: 'familyname',
    sortable: false
  },
  {
    text: 'Contact email',
    value: 'email',
    class: 'email',
    sortable: false
  },
  {
    text: 'Contact phone',
    value: 'phone',
    class: 'phone',
    sortable: false
  },
  {
    text: 'Actions',
    value: 'action',
    class: 'action',
    sortable: false
  }
]
const currentDate = moment().format('DD_MMM_YYYY')

export default {
  name: 'Y67TPrimaryStudentList',
  components: {
    AdsDataTable,
    Y67TDigitiseEntryPointModal,
    ApplicationModal,
    AdsButton,
    ExportListAsCsv
  },
  data() {
    return {
      headers,
      showDownloadModal: false,
      showExportModal: false,
      selectedItem: '',
      showDigitiseEntryPointModal: false,
      APPLICATION_TYPE,
      displayApplicationModal: false,
      applicationType: '',
      pageLength: 50,
      itemsLength: 0,
      currentPage: 1,
      tabName: 'STUDENT'
    }
  },

  methods: {
    closeExportModal() {
      this.showExportModal = false
    },
    openExportModal() {
      this.showExportModal = true
    },
    totalResults(pagination) {
      this.itemsLength = pagination.itemsLength
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    updatePagination() {
      document.querySelector('td .v-btn').focus()
    },
    filterApplications(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    trackSearch() {
      if (this.searchValue) {
        this.$gtm.trackEvent({
          category: 'search',
          action: 'searchBoxFiltered',
          label: STREAM.Y67T_PRIMARY
        })
      }
    },
    getFamilyEmail(contact) {
      if (contact) {
        const emailContact = contact.find(
          (item) => item.contactType === 'EMAIL'
        )
        return emailContact ? emailContact.contactValue : false
      }
      return false
    },
    getFamilyPhone(contact) {
      if (contact) {
        const phoneContact = contact.find(
          (item) => item.contactType !== 'EMAIL'
        )
        return phoneContact ? phoneContact.contactValue : false
      }
      return false
    },
    openDigitiseEntryPointModal(item) {
      this.selectedItem = item
      this.showDigitiseEntryPointModal = true
    },
    closeDigitiseEntryPointModal() {
      this.showDigitiseEntryPointModal = false
    },
    proceedApplication(item) {
      this.showDigitiseEntryPointModal = false
      this.selectedItem = item
      this.applicationType = item.applicationType
      this.displayApplicationModal = true
    },
    closeApplicationModal() {
      this.displayApplicationModal = false
      this.$store.dispatch('getY67TApplications')
      if (this.applicationSubmitted) {
        this.$emit('refresh', true)
      }
      this.showMainScrollbar()
    },
    showMainScrollbar() {
      const styleForScrollbar = document.querySelector(
        '#style-for-digitise-scrollbar'
      )
      if (styleForScrollbar) {
        document.head.removeChild(styleForScrollbar)
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      y67tApplications: 'y67tApplications',
      applicationSubmitted: 'applicationSubmitted',
      y67tSearchFilter: 'y67tSearchFilter'
    }),
    isDisableExportCsv() {
      const autoDraftApplications = this.y67tApplications(
        this.schoolCode
      )?.filter((app) => app.applicationStatus === 'AutoDraft')

      return !autoDraftApplications || autoDraftApplications.length === 0
    },
    applicationsExportCsv() {
      const autoDraftApplications = this.y67tApplications(
        this.schoolCode
      ).filter((app) => app.applicationStatus === 'AutoDraft')
      const selectedEOIs = [
        ['Sensitive - personal and health information'],
        [''],
        [
          'SRN',
          'Student Name',
          'Family/Parent/Carer',
          'Contact email',
          'Contact phone'
        ]
      ]

      for (const application of autoDraftApplications) {
        const parentListNames = []
        const parentListEmails = []
        const parentListNumbers = []

        if (
          application.familyContact &&
          application.familyContact.contactDetails.length > 0
        ) {
          const { contactDetails } = application.familyContact

          const name = 'Family'
          let email = ''
          let number = ''

          for (const contact of contactDetails) {
            if (contact.contactType === 'EMAIL') {
              email = contact.contactValue
            } else if (
              ['PHDAY', 'PHMOB', 'PHTXT', 'PHHOM', 'PHWRK', 'PHSIL'].includes(
                contact.contactType
              )
            ) {
              number = contact.contactValue
            }
          }
          parentListNames.push(name)
          parentListEmails.push(email)
          parentListNumbers.push(number)
        }

        for (const parentCarer of application.parentCarers) {
          const {
            parentCarerGivenName,
            parentCarerFamilyName,
            parentCarerEmail,
            contactDetails,
            checkERN
          } = parentCarer

          const name = `${parentCarerGivenName} ${parentCarerFamilyName}`
          const email = checkERN
            ? 'Check ERN to get details'
            : parentCarerEmail !== 'null'
            ? parentCarerEmail
            : ''
          const number = contactDetails[0]?.contactValue || ''

          parentListNames.push(name)
          parentListEmails.push(email)
          parentListNumbers.push(number)
        }
        const row = [
          application.student.SRN,
          application.name,
          parentListNames.join('\n'),
          parentListEmails.join('\n'),
          parentListNumbers.join('\n')
        ]
        selectedEOIs.push(row)
      }
      return selectedEOIs
    },
    csvFileName() {
      return `Year 6-7 unlinked student list_${currentDate}.csv`
    },
    totalApplicationsDisplayed() {
      return this.applications.length
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    },
    applications() {
      const applications = this.y67tApplications(this.schoolCode)
      let studentList = applications.filter(
        (app) => app.applicationStatus === 'AutoDraft'
      )
      return studentList.filter((a) => {
        let Name =
          (this.y67tSearchFilter(this.tabName) || '') !== ''
            ? searchFilter(
                [
                  a.application.student.familyName,
                  a.application.student.firstName
                ],
                this.y67tSearchFilter(this.tabName)
              )
            : true
        return Name
      })
    },
    internalSearch: {
      get() {
        return this.y67tSearchFilter(this.tabName) || ''
      },
      set(value) {
        this.idPerPage = []
        this.$store.commit('setY67tSearchFilter', {
          tabName: this.tabName,
          value: value
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-ripple__container {
  width: 0 !important;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.results-text {
  margin: 25px 30px 0 0 !important;
}

::v-deep .mdi-magnify {
  margin-top: 3px !important;
}
.digitise-entrypoint-button {
  border: none;
  &:focus {
    border: 3px solid $color-primary !important;
    color: $color-primary !important;
  }
}
.digitise-entrypoint-button.v-btn--icon.v-size--small {
  height: 32px !important;
  width: 32px !important;
}
.ApplicationList {
  overflow: auto;
  min-width: 768px;
  padding-top: 20px;

  ::v-deep .ApplicationStatusChips {
    width: 9.0625rem;
    font-weight: 700;
    .v-chip__content span,
    .v-chip__content {
      width: 100%;
      text-align: center;
    }
  }
  .v-card__actions {
    padding: 1rem 3rem;
    background: $grey-6;
  }
}
::v-deep .data-table-footer {
  text-align: right;
  .value {
    color: $color-primary;
    font-weight: bold;
  }
  .link {
    outline: none;
    text-decoration: underline;
  }
  .mdi-send-outline:before {
    transform: rotate(-45deg);
  }
}
::v-deep .v-data-table.dataTable .searchInput {
  padding: 16px 20px !important;
}
::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
      &.active {
        font-weight: bold;
        color: $color-primary;
        .v-data-table-header__icon {
          color: $color-primary;
        }
      }
      &.date-received {
        width: 110px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: 90px;
      }
      &.srn {
        width: 110px;
      }
      &.action {
        width: 150px;
        padding-right: 20px;
        text-align: center !important;
      }
      &.select-all {
        width: 60px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}

@include desktop {
  .ApplicationList {
    padding: 1.5rem 3rem;
    min-height: 100%;
  }

  ::v-deep .v-data-table {
    td {
      cursor: default;
    }

    &.pending thead.v-data-table-header th.sortable.date-received {
      padding-left: 20px;
    }
    thead.v-data-table-header th {
      &.familyname {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 120px;
      }
    }
    thead.v-data-table-header th.sortable {
      padding: 20px 10px;
      &.date-received {
        width: 122px;
      }
      &.name {
        overflow-wrap: normal;
        word-wrap: normal;
        width: auto;
        min-width: 100px;
      }
      &.action {
        padding-right: 20px;
        width: 150px;
      }
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 30px;
      }
      tr td.status-processed {
        padding-right: 20px;
      }
      tr {
        vertical-align: initial;
      }
      td:last-child {
        text-align: center !important;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
#app.v-application
  button.v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
  opacity: 0;
}

.loadMoreBar {
  text-align: center;
  background-color: $grey-6;
  padding: 1rem;
}
.loadMoreCount {
  position: absolute;
  right: 1rem;
  line-height: 45px;
}
.filterLabel {
  color: $color-placeholder;
}

.chip-label {
  min-width: 90px;
  font-weight: bold;
}

.search-results-number {
  float: right;
  white-space: nowrap;
}

.data-table-header {
  align: right;
}
.checkERN {
  font-style: italic;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: $ads-error-red;
  width: 85%;
  border-bottom: 2px solid;
  .v-icon {
    color: $ads-error-red;
  }
  span {
    display: inline-block;
    vertical-align: middle;
  }
}
</style>
