<template>
  <div class="ehub-prefill-notification">
    <v-icon size="24"> mdi-clipboard-text-multiple-outline </v-icon>
    <p class="mb-0 ml-1">
      This enrolment application has been partially
      <strong>pre-filled</strong> with existing ERN data
    </p>
  </div>
</template>

<script>
export default {
  name: 'EhubPrefillNotification'
}
</script>

<style scoped lang="scss">
.ehub-prefill-notification {
  display: flex;
  margin: $content-padding 3rem;
  padding: $content-padding;
  border: 1px solid $color-primary-lighten-1;
  border-radius: $field-border-radius;
  background-color: rgba(200, 220, 240, 0.4);
}
</style>
