<template>
  <div>
    <div class="mx-6 mx-md-12 pt-4">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30" class="material-icons-outlined">home</v-icon>
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">
            Parent communications - local enrolments
          </h3>
        </div>
      </div>
      <div
        class="d-flex align-start"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <v-radio-group
          label="Choose how to manage communication for local enrolment applications:"
          :value="whoWillContact"
          @change="$emit('updatedWhoWillContact', $event)"
          :readonly="isSuperAdmin"
        >
          <v-radio
            v-for="item in whoWillContactOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            class="py-1"
          />
        </v-radio-group>
      </div>
      <p class="app-settings__subheading pt-4 pb-2">
        Enrolments for this year will be responded to:
      </p>
      <div
        class="d-flex align-start mb-2"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <AdsSelect
          @change="$emit('updatedCurrentYearTimeFrameLocal', $event)"
          :value="currentYearTimeFrame ? currentYearTimeFrame.value : ''"
          :items="currentYearTimeFrames"
          item-text="value"
          return-object
          label="Current time frame"
          :outlined="true"
          :readonly="isSuperAdmin"
          placeholder="Current time frame"
          class="app-settings__time-frames-input mr-12"
          aria-labelledby="currentYearTimeFramePreview"
          persistent-placeholder
        />
        <div v-if="currentYearTimeFrame" class="app-settings__preview">
          <p><strong>Preview:</strong></p>
          <p
            class="app-settings__preview-text app-settings__paragraph-text"
            id="currentYearTimeFramePreview"
          >
            {{ this.getDescription(currentYearTimeFrame.description) }}
          </p>
        </div>
      </div>
    </div>
    <div class="mx-6 mx-md-12">
      <p class="app-settings__subheading pb-2">
        Enrolments for next year will be responded to:
      </p>
      <div
        class="d-flex align-start pb-8"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
      >
        <AdsSelect
          @change="$emit('updatedNextYearTimeFrameLocal', $event)"
          :value="nextYearTimeFrame ? nextYearTimeFrame.value : ''"
          :items="nextYearTimeFrames"
          item-text="value"
          return-object
          label="Current time frame"
          :outlined="true"
          :readonly="isSuperAdmin"
          class="app-settings__time-frames-input mr-12"
          placeholder="Current time frame"
          aria-labelledby="nextYearTimeFramePreview"
          persistent-placeholder
        />
        <div v-if="nextYearTimeFrame" class="app-settings__preview">
          <p><strong>Preview:</strong></p>
          <p
            class="app-settings__preview-text app-settings__paragraph-text"
            id="nextYearTimeFramePreview"
          >
            {{ this.getDescription(nextYearTimeFrame.description) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WHO_WILL_CONTACT_OPTIONS } from '@/constants'
import { AdsSelect } from '@nswdoe/doe-ui-core'
import settingsUtils from '@/helpers/settingsUtils'

export default {
  name: 'AppSettingsParentCommsLocal',
  components: { AdsSelect },
  props: {
    school: {
      type: Object,
      required: true
    },
    whoWillContact: {
      type: String,
      default: null
    },
    currentYearTimeFrame: {
      type: Object,
      default: null
    },
    currentYearTimeFrames: {
      type: Array,
      default: () => []
    },
    nextYearTimeFrame: {
      type: Object,
      default: null
    },
    nextYearTimeFrames: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      whoWillContactOptions: WHO_WILL_CONTACT_OPTIONS
    }
  },
  computed: {
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    }
  },
  methods: {
    getDescription(timeframe) {
      return WHO_WILL_CONTACT_OPTIONS[1].value === this.whoWillContact
        ? timeframe.parentToSchool.replace('<School>', this.school.schoolName)
        : timeframe.schoolToParent.replace('<School>', this.school.schoolName)
    }
  }
}
</script>

<style lang="scss" scope>
.v-radio > label {
  color: $ads-navy !important;
}
</style>
