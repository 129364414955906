<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <p class="section-title mt-4">Nominated out-of-area schools</p>
    <v-row v-for="(school, index) in ooaHighSchools" :key="index">
      <ReviewField
        sm="10"
        :label="`Nominated school ${index + 1}`"
        :value="school.schoolName"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewField from '@/components/application/y67t/paperEoI/eoi/Review/ReviewField'

export default {
  name: 'OutOfAreaSchoolsView',
  components: {
    ReviewField
  },
  computed: {
    ...mapGetters(['ooaHighSchools'])
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: $digitize-eoi-font-family;
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
  line-height: 26px;
}
</style>
