<template>
  <div>
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.11779 0C1.84791 0 0 1.72263 0 4.08331C0 4.62563 0.15247 5.50177 0.330162 5.97914L4.10869 13.9999L7.88722 5.97914C8.06376 5.5029 8.21738 4.62563 8.21738 4.08331C8.21738 1.72263 6.38772 0 4.11779 0ZM4.10862 2.33332C5.08076 2.33332 5.86948 3.11717 5.86948 4.08331C5.86948 5.04945 5.08076 5.8333 4.10862 5.8333C3.13647 5.8333 2.34775 5.04945 2.34775 4.08331C2.34775 3.11717 3.13647 2.33332 4.10862 2.33332ZM22.2951 12.2499C19.702 12.2499 17.6086 14.2608 17.6086 16.9166C17.6086 17.5261 17.7828 18.4399 17.9846 18.9765L22.3043 28L26.624 18.9765C26.8269 18.4399 27 17.5272 27 16.9166C27 14.2608 24.8882 12.2499 22.2951 12.2499ZM5.31011 12.8333L4.75984 13.9999H11.739C13.2121 13.9999 14.3803 15.1609 14.3803 16.6249C14.3803 18.0889 13.2121 19.2499 11.739 19.2499H7.92383C5.49462 19.2499 3.52166 21.2107 3.52166 23.6249C3.52166 26.0391 5.49462 27.9999 7.92383 27.9999H21.653L21.0936 26.8332H7.92377C6.12507 26.8332 4.69551 25.4125 4.69551 23.6249C4.69551 21.8373 6.12507 20.4166 7.92377 20.4166H11.739C13.8426 20.4166 15.5542 18.7156 15.5542 16.6249C15.5542 14.5343 13.8426 12.8333 11.739 12.8333H5.31011ZM22.3042 14.8749C23.4392 14.8749 24.3586 15.7886 24.3586 16.9166C24.3586 18.0445 23.4392 18.9582 22.3042 18.9582C21.1693 18.9582 20.2499 18.0445 20.2499 16.9166C20.2499 15.7886 21.1693 14.8749 22.3042 14.8749Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'RouteIcon'
}
</script>

<style scoped lang="scss">
.v-icon.v-icon svg {
  border-radius: 0;
  fill: currentColor;
}

.v-avatar .v-icon svg {
  margin-left: -50%;
  width: 45px;
  height: 46px;
  transform: translate(50%, 0px);
}
</style>
