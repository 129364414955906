<template>
  <div class="px-8 pt-4 pb-2">
    <no-details v-if="!siblings || !siblings.length" detail-name="Sibling" />
    <v-card class="mb-6 px-4 pb-6" v-for="(sibling, i) in siblings" :key="i">
      <v-card-title class="px-0 pb-0">
        <div>Sibling: {{ name(sibling) }}</div>
      </v-card-title>
      <v-row>
        <v-col>
          <read-only-field
            label="Given name"
            v-model="sibling.siblingsFirstName"
            readonly
          />
        </v-col>
        <v-col>
          <read-only-field
            label="Middle name"
            v-model="sibling.siblingsOtherName"
            readonly
          />
        </v-col>
        <v-col>
          <read-only-field
            label="Family name"
            v-model="sibling.siblingsFamilyName"
            readonly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <read-only-field
            label="Gender"
            v-model="sibling.siblingsGenderCode"
            :formatter="genderFormatter"
            readonly
          />
        </v-col>
        <v-col cols="4">
          <read-only-field
            label="Date of birth"
            v-model="sibling.siblingsDOB"
            :formatter="displayDate"
            readonly
          />
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'

import NoDetails from '@/components/application/NoDetails'
import ReadOnlyField from '@/components/form/ReadOnlyField'

const valueOrDefault = (val, defaultVal = '') => {
  return val ? val : defaultVal
}

export default {
  name: 'OoaFormSiblings',
  components: {
    ReadOnlyField,
    NoDetails
  },
  data() {
    return {
      validators: {
        required: validators.required
      },
      genderFormatter: formatters.gender,
      dateFormatter: formatters.date,
      displayDate: formatters.displayDate
    }
  },
  computed: {
    ...mapGetters(['application']),
    siblings() {
      return this.application.siblings
    }
  },
  methods: {
    name(sibling) {
      return `${sibling.siblingsFirstName} ${valueOrDefault(
        sibling.siblingsOtherName
      )} ${sibling.siblingsFamilyName}`
    }
  }
}
</script>
