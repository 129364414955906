<!--
  Displays a single ERN record as a selectable option within the manual-linking dialog
-->
<template>
  <button
    :class="'option unstyled ' + (isSelected ? 'isTicked' : '')"
    type="button"
    role="checkbox"
    :aria-checked="isSelected"
    @click="$emit('click')"
  >
    <div class="tick">
      <font-awesome-icon focusable="false" icon="check" />
    </div>

    <div class="optionContent">
      {{ text }}<strong v-if="linkedOesId"> (already matched)</strong>
    </div>
  </button>
</template>

<script>
export default {
  name: 'ApplicationResolveLinkingOption',
  props: {
    isSelected: {
      type: Boolean
    },
    text: {
      type: String
    },
    linkedOesId: {
      type: String
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
.option {
  display: inline-block;
  width: 100%;
  padding: 0.5em 1em !important;
  display: flex;
  align-items: center;
  border-radius: 0.15em;
  margin: 0.5em 0 !important;
  background-color: $color-student-record-bg !important;

  &.isTicked {
    background-color: $color-selected-item !important;
  }

  .tick {
    background-color: white;
    margin-right: 0.5em;
    padding: 0.25em;
    line-height: 1;
    text-align: center;
    border-radius: 1em;
    * {
      visibility: hidden;
    }
  }
  &.isTicked .tick {
    color: $color-primary;
    background-color: white;
    * {
      visibility: visible;
    }
  }
  .optionContent {
    flex: 1;
  }
}
</style>
