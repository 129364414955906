import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faSearch,
  faExclamation,
  faExclamationTriangle,
  faExclamationCircle,
  faEllipsisH,
  faSync,
  faCircleNotch,
  faTimes,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faPlus,
  faMinus,
  faPen,
  faInfo,
  faInfoCircle,
  faQuestion,
  faQuestionCircle,
  faCircle,
  faLink,
  faCheck,
  faLock,
  faStar,
  faPrint,
  faUser,
  faHeadset
} from '@fortawesome/free-solid-svg-icons'

export default function addGlyphs() {
  library.add(
    faSearch,
    faExclamation,
    faExclamationTriangle,
    faExclamationCircle,
    faEllipsisH,
    faSync,
    faCircleNotch,
    faTimes,
    faAngleLeft,
    faAngleRight,
    faAngleUp,
    faAngleDown,
    faPlus,
    faMinus,
    faPen,
    faInfo,
    faInfoCircle,
    faQuestion,
    faQuestionCircle,
    faCircle,
    faLink,
    faCheck,
    faLock,
    faStar,
    faPrint,
    faUser,
    faHeadset
  )

  Vue.component('FontAwesomeIcon', FontAwesomeIcon)
}
