<template>
  <div class="ads-style" v-show="this.initialised">
    <!-- v-on:submit.prevent handles submit on pressing the Enter key -->
    <v-form ref="y67tForm" @submit.prevent="save()">
      <ads-expansion-panel :items="panels" multiple v-model="openPanels">
        <template slot="contentStudentDetails">
          <v-form
            ref="studentDetailsForm"
            v-model="studentValid"
            @submit.prevent="save()"
            validation-section-name="Student details"
            data-testid="studentSection"
          >
            <student-details :readonly="isReadOnly" />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="submit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentParentCarers">
          <v-form
            ref="parentCarerForm"
            v-model="parentCarerValid"
            @submit.prevent="save()"
            validation-section-name="Parent/carer details"
            data-testid="parentSection"
          >
            <parent-carers :readonly="isReadOnly" />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="sumbit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentLocationDetails">
          <location-details data-testid="locationSection" />
        </template>
        <template slot="contentOoaSchoolChoices">
          <ooa-school-choices data-testid="ooaChoicesSection" />
        </template>
        <template v-for="(school, index) in ooaSchoolSlots">
          <template :slot="`content${school.id}`">
            <Considerations
              :school-code="school.schoolCode"
              :key="index"
              :data-testid="`ooaSection-${school.id}`"
            />
          </template>
        </template>
        <template slot="contentConsiderationsDocuments">
          <considerations-documents
            data-testid="considerationsDocumentsSection"
          />
        </template>
        <template slot="contentFlags">
          <flags />
        </template>
        <template slot="contentWalkups">
          <walkups />
        </template>
      </ads-expansion-panel>
      <!-- v-on:reset.prevent in the v-form wasn't working, so I have to handle it here -->
      <save-cancel v-if="isEditing" @cancel="cancel" />
    </v-form>
    <errors-dialog
      v-model="showErrors"
      :errors="errors"
      @close="closeErrorsDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'
import { getFormErrors, focusOnFirstError } from '@/helpers/form'

import StudentDetails from './StudentDetails.vue'
import ParentCarers from './ParentCarers.vue'
import LocationDetails from './LocationDetails.vue'
import OoaSchoolChoices from './OoaSchoolChoices.vue'
import Considerations from './Considerations.vue'
import ConsiderationsDocuments from './ConsiderationsDocuments.vue'
import Flags from './Flags.vue'
import Walkups from './Walkups.vue'
import ErrorsDialog from './ErrorsDialog.vue'
import SaveCancel from '@/components/form/SaveCancel'
import { STREAM } from '@/constants'

export default {
  name: 'Y67TForm',
  components: {
    AdsExpansionPanel,
    SaveCancel,
    StudentDetails,
    ParentCarers,
    LocationDetails,
    OoaSchoolChoices,
    Considerations,
    ConsiderationsDocuments,
    ErrorsDialog,
    Flags,
    Walkups
  },
  data() {
    return {
      studentValid: true,
      parentCarerValid: true,
      openPanels: [],
      initialised: false,
      errors: [],
      showErrors: false,
      residentialAddress: null
    }
  },
  computed: {
    ...mapGetters([
      'application',
      'isEditing',
      'referenceData',
      'isY67TApplicationReadOnly',
      'selectedSchool',
      'isAddressConflict',
      'currentStream'
    ]),
    hasOoaChoices() {
      if (
        this.application.ooaHighSchools &&
        this.application.ooaHighSchools.length > 0
      ) {
        return true
      }
      return false
    },
    hasConsiderationsDocuments() {
      return (
        this.application.supportingDocuments?.digitizeEoiConsiderations
          ?.length > 0
      )
    },
    panels() {
      const errorProps = { headerClass: 'sectionError', icon: 'error_outline' }
      const sections = [
        {
          id: 'StudentDetails',
          title: 'Student details',
          ...(this.studentValid ? {} : errorProps)
        }
      ]

      if (this.isSecondarySchool) {
        sections.push(
          {
            id: 'Flags',
            title: 'Flags & tags'
          },
          {
            id: 'Walkups',
            title: 'Walkups'
          }
        )
      }

      sections.push(
        {
          id: 'ParentCarers',
          title: 'Parent/carer details',
          ...(this.parentCarerValid ? {} : errorProps)
        },
        {
          id: 'LocationDetails',
          title: 'Location details',
          ...(this.isAddressConflict && !this.isSecondarySchool
            ? { icon: 'error' }
            : '')
        }
      )

      if (this.hasOoaChoices) {
        if (!this.isSecondarySchool) {
          sections.push({
            id: 'OoaSchoolChoices',
            title: 'Out of area school choices'
          })
        }
        if (this.isSecondarySchool) {
          const schoolToDisplay = this.application.ooaHighSchools.find(
            (school) => school.schoolCode === this.selectedSchool.schoolCode
          )
          if (schoolToDisplay) {
            sections.push({
              id: 'Considerations1',
              title: `${schoolToDisplay.schoolName} considerations`
            })
          }
        } else {
          this.application.ooaHighSchools.forEach((school, index) => {
            sections.push({
              id: `Considerations${index + 1}`,
              title: `${school.schoolName} considerations`
            })
          })
        }
        if (this.hasConsiderationsDocuments) {
          sections.push({
            id: 'ConsiderationsDocuments',
            title: 'Considerations document(s)'
          })
        }
      }
      return sections
    },
    ooaSchoolSlots() {
      const slots = []
      if (this.hasOoaChoices) {
        if (this.isSecondarySchool) {
          const schoolToDisplay = this.application.ooaHighSchools.find(
            (school) => school.schoolCode === this.selectedSchool.schoolCode
          )
          if (schoolToDisplay) {
            slots.push({
              id: 'Considerations1',
              schoolCode: schoolToDisplay.schoolCode
            })
          }
        } else {
          this.application.ooaHighSchools.forEach((school, index) => {
            slots.push({
              id: `Considerations${index + 1}`,
              schoolCode: school.schoolCode
            })
          })
        }
        return slots
      }
      return slots
    },
    isSecondarySchool() {
      if (this.currentStream === STREAM.Y67T_SECONDARY) {
        return true
      } else if (
        this.currentStream === STREAM.ARCHIVE &&
        !this.$route.params.primary
      ) {
        return true
      }
      return false
    },
    isReadOnly() {
      return (
        this.isY67TApplicationReadOnly ||
        this.isSecondarySchool ||
        this.currentStream === STREAM.ARCHIVE
      )
    }
  },
  mounted() {
    // open and close all the panels to ensure they are rendered so we can run pre-validation
    this.openPanels = [...Array(this.panels.length).keys()]
    this.$nextTick(() => {
      this.openPanels = []

      // give the animation a chance to complete
      setTimeout(() => {
        this.initialised = true
      }, 100)
    })
  },
  methods: {
    save() {
      if (this.isY67TApplicationReadOnly) {
        return
      }
      this.validate()
      if (this.errors.length) {
        this.showErrors = true
      } else {
        this.$store.dispatch('saveY67TApplication')
      }
    },
    cancel() {
      this.$store.dispatch('cancelApplication')
    },
    stopEdit() {
      this.$store.commit('set', ['isEditing', false])
    },
    validate() {
      this.$refs.y67tForm.validate()
      this.$refs.studentDetailsForm.validate()
      this.$refs.parentCarerForm.validate()
      this.errors = getFormErrors(this.$refs.y67tForm)
    },
    closeErrorsDialog() {
      this.showErrors = false
      focusOnFirstError(this.$refs.y67tForm)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header.sectionError {
  span i {
    color: $ads-error-red;
  }
  span {
    color: $ads-error-red;
    font-weight: bold;
  }
}
::v-deep .col {
  padding: 6px 12px;
}
::v-deep .theme--light {
  .v-expansion-panels .v-expansion-panel .subtitle-1,
  .panel--icon .v-icon {
    color: $ads-error-red !important;
  }
}
::v-deep .displayField {
  label {
    font-weight: bold;
    font-size: 14px !important;
    color: $ads-primary-blue !important;
  }
  p {
    color: $ads-grey-01;
    line-height: 24px;
  }
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-card__title {
  font-size: 16px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-expansion-panel-content__wrap {
  color: $ads-grey-01;
}
</style>
