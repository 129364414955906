<template>
  <div>
    <AppModal
      v-if="showDocStatusModal"
      ref="AppModal"
      data-testid="documents-status-modal"
      class="downloading-modal"
    >
      <template slot="header">
        <v-icon
          ref="closeButton"
          @click="$emit('close')"
          class="text-right closeBtn"
          aria-label="Close"
        >
          mdi-close
        </v-icon>
        <div v-if="applicationPDFUnavailable !== 'ALL'" class="heading">
          <span class="mr-2">
            <v-icon color="primary">mdi-lock-outline</v-icon>
          </span>
          <span class="font-weight-bold" v-if="!skipDownloadApp">
            Please keep the application(s) secure
          </span>
          <span class="font-weight-bold" v-if="skipDownloadApp">
            Please keep the document(s) secure
          </span>
        </div>
        <div v-if="applicationPDFUnavailable === 'ALL'" class="heading">
          <span class="mr-2">
            <v-icon color="primary">mdi-alert-outline</v-icon>
          </span>
          <span class="font-weight-bold"
            >This application is unavailable for download</span
          >
        </div>
      </template>

      <div slot="body">
        <div class="content">
          <p v-if="applicationPDFUnavailable !== 'ALL'">
            Please ensure once you download the document(s) that they are kept
            in a secure location within the school premises.
          </p>
          <p v-if="applicationPDFUnavailable === 'ALL'">
            Sorry, the PDF of this application cannot be downloaded as it is
            currently missing.
          </p>
          <div
            v-if="applicationPDFUnavailable === 'FEW'"
            data-testid="docs-unavailable-msg"
          >
            <div class="sub-header mb-4">
              <span class="mr-2">
                <v-icon color="primary">mdi-alert-outline</v-icon>
              </span>
              <span class="font-weight-bold"
                >{{ applicationPDFUnavailableCount }} documents are unavailable
                for download</span
              >
            </div>
            <p>
              The following documents are unavailable because they are currently
              missing:
            </p>
          </div>
          <!--
          FUS-1813: Changes to eslint rules now disallow the use of
          v-if with v-for on the same element. The error for the next
          block has been disabled as the application and components
          are currently working.
          -->
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <div
            v-for="(supportingDocument, i) in supportingDocuments"
            v-if="
              supportingDocument.status === 'MISSING' &&
              applicationPDFUnavailable === 'FEW'
            "
            :key="i"
            class="body-1 my-0 py-0 mb-5 docs-item vc"
          >
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
            <div class="font-weight-bold mb-4 docs-item-title">
              {{ supportingDocument.name }}
            </div>
            <div class="mb-4 docs-item-content">
              <div v-if="supportingDocument.status === 'MISSING'">
                <div class="mb-1">
                  <span class="mr-2">
                    <v-icon color="primary">mdi-file-outline</v-icon>
                  </span>
                  <span class="font-weight-bold">
                    {{ `${supportingDocument.applicationID}.pdf` }}
                  </span>
                </div>
                <div>
                  <span class="mr-2">
                    <v-icon color="red darken-1">mdi-alert-circle</v-icon>
                  </span>
                  <span>{{ docMessageByStatus('MISSING') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="fileUnavailable && applicationPDFUnavailable !== 'ALL'"
            data-testid="docs-unavailable-msg"
          >
            <div class="sub-header mb-4">
              <span class="mr-2">
                <v-icon color="primary">mdi-alert-outline</v-icon>
              </span>
              <span class="font-weight-bold"
                >Some supporting documents are unavailable</span
              >
            </div>
            <p>
              Some of the supporting documents from the selected applications
              cannot be downloaded as they may be infected, or are currently
              missing. The documents are listed below:
            </p>
          </div>
          <!--
          FUS-1813: Changes to eslint rules now disallow the use of
          v-if with v-for on the same element. The error for the next
          block has been disabled as the application and components
          are currently working.
          -->
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <div
            v-for="(supportingDocument, i) in supportingDocuments"
            v-if="
              supportingDocument.fileUnavailable &&
              applicationPDFUnavailable !== 'ALL'
            "
            :key="i"
            class="body-1 my-0 py-0 mb-2 docs-item vc"
          >
            <!-- eslint-enable vue/no-use-v-if-with-v-for -->
            <div class="font-weight-bold mb-4 docs-item-title">
              {{ supportingDocument.name }}
            </div>
            <div
              v-for="(doc, j) in supportingDocument.docs"
              :key="j"
              class="mb-4 docs-item-content"
            >
              <div v-if="doc.status !== 'SAFE'">
                <div class="mb-1">
                  <span class="mr-2">
                    <v-icon color="primary">mdi-file-outline</v-icon>
                  </span>
                  <span class="font-weight-bold">{{
                    fileName(doc.objectKey)
                  }}</span>
                </div>
                <div>
                  <span class="mr-2">
                    <v-icon color="red darken-1">mdi-alert-circle</v-icon>
                  </span>
                  <span>{{ docMessageByStatus(doc.status) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="applicationPDFUnavailable !== 'ALL'" slot="footer">
        <AdsButton
          icon="mdi-download-outline"
          @click="startDownload"
          button-text="Download"
          aria-label="Download"
        />
      </div>
    </AppModal>
    <AppModal
      v-if="showDownloadProgressModal"
      ref="AppModal"
      data-testid="download-documents-modal"
      class="downloading-modal"
    >
      <span slot="header">
        <v-avatar size="40" color="primary lighten-4" class="mr-4">
          <v-icon color="primary">mdi-download-outline</v-icon>
        </v-avatar>
        <span class="font-weight-bold"
          >Preparing {{ count }} application(s) for download</span
        >
      </span>

      <div slot="body">
        <div class="content">
          <v-progress-linear
            indeterminate
            color="primary lighten-1"
            class="mt-2 mb-6"
          ></v-progress-linear>

          <p>
            We are preparing a zip archive of the application(s) you have
            selected. This window will <strong>close automatically</strong> once
            the download commences.
          </p>
          <p>
            <strong>Note:</strong> for a large number of applications, this may
            take several minutes.
          </p>
        </div>
      </div>

      <div slot="footer">
        <AdsButton
          icon="mdi-delete-outline"
          @click="cancelDownload"
          button-text="Cancel download"
          aria-label="Cancel download"
        />
      </div>
    </AppModal>
    <AppModal
      v-if="downloadError"
      ref="AppModal"
      data-testid="download-error-modal"
    >
      <template slot="header">
        <v-icon @click="$emit('close')" class="text-right closeBtn"
          >mdi-close</v-icon
        >
        <div class="heading px-3">
          <v-avatar size="40" color="primary lighten-4" class="mr-4">
            <v-icon color="primary">
              {{
                skipDownloadApp ? 'mdi-alert-outline' : 'mdi-download-outline'
              }}</v-icon
            >
          </v-avatar>
          <span class="font-weight-bold">
            {{ errorMessage }}
          </span>
        </div>
      </template>
      <template slot="body">
        <div class="content mt-3 mx-3" v-if="skipDownloadApp">
          <p>We encountered an error downloading your selected file(s).</p>
          <p>Please try again.</p>
        </div>
        <div class="content mt-3 mx-3" v-if="!skipDownloadApp">
          <p>
            Sorry, the application(s) you selected cannot be downloaded at this
            time. Please try again later.
          </p>
          <p>
            If this problem persists, contact support on
            <strong>1300 32 32 32</strong>.
          </p>
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import { FILE_STATUSES, APPLICATION_TYPE } from '@/constants'
import AppModal from '@/components/app/AppModal.vue'
import { AdsButton } from '@nswdoe/doe-ui-core'

function getTransformedDocs(
  supportingDocuments,
  applicationID,
  applicationType
) {
  let docs = []

  if (
    applicationType === APPLICATION_TYPE.OUT_OF_AREA &&
    supportingDocuments &&
    supportingDocuments['MED'] &&
    supportingDocuments['MED'].length > 0
  ) {
    docs = supportingDocuments['MED'].map((doc) => ({
      objectKey: applicationID + doc.objectKey
    }))
  }

  if (
    applicationType === APPLICATION_TYPE.Y67T &&
    supportingDocuments &&
    supportingDocuments['digitizeEoiConsiderations'] &&
    supportingDocuments['digitizeEoiConsiderations'].length > 0
  ) {
    docs = supportingDocuments['digitizeEoiConsiderations'].map((doc) => ({
      objectKey: doc.objectKey
    }))
  }

  return docs
}

function createDownloadApplicationObject(app, supportingDocuments) {
  return {
    id: app.applicationID,
    status: app.status,
    studentFirstName: app.studentFirstName,
    studentFamilyName: app.studentFamilyName,
    supportingDocuments:
      supportingDocuments?.length > 0 ? supportingDocuments : undefined
  }
}

export default {
  name: 'DownloadDocumentsModal',
  components: {
    AppModal,
    AdsButton
  },
  props: {
    applications: {
      type: Array,
      default: () => []
    },
    schoolCode: {
      type: String,
      default: ''
    },
    skipDownloadApp: {
      type: Boolean,
      default: false
    },
    applicationType: {
      type: String
    },
    document: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      showDocStatusModal: true,
      showDownloadProgressModal: false,
      hasCancelled: false,
      downloadError: false,
      supportingDocuments: [],
      downloadApplications: []
    }
  },
  computed: {
    count() {
      return this.downloadApplications ? this.downloadApplications.length : 0
    },
    errorMessage() {
      const message = this.skipDownloadApp ? 'file(s)' : 'application(s)'
      return `Error downloading ${message}`
    },
    applicationPDFUnavailableCount() {
      return this.supportingDocuments.filter((d) => d.status === 'MISSING')
        .length
    },
    applicationPDFUnavailable() {
      switch (this.applicationPDFUnavailableCount) {
        case 0:
          return 'NONE'
        case this.supportingDocuments.length:
          return 'ALL'
        default:
          return 'FEW'
      }
    },
    fileUnavailable() {
      let isFileUnavailable = false

      if (this.supportingDocuments.length > 0) {
        for (let i = 0; i < this.supportingDocuments.length; i++) {
          const doc = this.supportingDocuments[i]
          if (doc.fileUnavailable) {
            isFileUnavailable = true
            break
          }
        }
      }

      return isFileUnavailable
    }
  },
  methods: {
    isDigitizeEOIConsiderationDocUploaded() {
      return (
        this.applications.length > 1 ||
        (this.applications.length === 1 &&
          this.applications[0].isDigitizeEOIConsiderationDocUploaded)
      )
    },
    init() {
      if (
        this.applicationType === APPLICATION_TYPE.OUT_OF_AREA ||
        (this.applicationType === APPLICATION_TYPE.Y67T &&
          this.isDigitizeEOIConsiderationDocUploaded())
      ) {
        this.initDocStatus()
      }
      if (this.$refs.closeButton) {
        this.$refs.closeButton.$el.focus()
      }
    },
    initDocStatus() {
      this.$store.dispatch('showSpinner')

      const applicationsInfo = this.applications.map((app) => ({
        applicationID: app.applicationID,
        docs: getTransformedDocs(
          app.supportingDocuments,
          app.applicationID,
          this.applicationType
        )
      }))
      this.$store
        .dispatch('getAppsDocStatus', {
          applicationsInfo,
          schoolCode: this.schoolCode,
          applicationType: this.applicationType
        })
        .then((data) => {
          this.supportingDocuments = data.supportingDocuments.map((app) => {
            const selApp = this.applications.filter(
              (a) => a.applicationID === app.applicationID
            )
            if (selApp.length > 0) {
              app.name =
                selApp[0].studentFamilyName + ',' + selApp[0].studentFirstName
              app.studentFirstName = selApp[0].studentFirstName
              app.studentFamilyName = selApp[0].studentFamilyName
            }
            app.fileUnavailable = false
            for (let i = 0; i < app.docs.length; i++) {
              const doc = app.docs[i]
              if (doc.status !== FILE_STATUSES.SAFE) {
                app.fileUnavailable = true
              }
            }
            return app
          })
          this.$store.dispatch('hideSpinner')
        })
        .catch((error) => {
          if (error) {
            this.downloadError = true
            this.$store.dispatch('hideSpinner')
          }
        })
    },
    docMessageByStatus(status) {
      let msg = 'This file cannot be downloaded.'

      if (status === FILE_STATUSES.INFECTED) {
        msg = 'This file is infected and cannot be downloaded.'
      } else if (status === FILE_STATUSES.TO_BE_SCANNED) {
        msg = 'This file has not been virus scanned and cannot be downloaded.'
      } else if (status === FILE_STATUSES.MISSING) {
        msg = 'This file is missing and cannot be downloaded.'
      }
      return msg
    },
    fileName(objectKey) {
      return objectKey.split('/').slice(-1)[0]
    },
    startDownload() {
      this.showDocStatusModal = false

      // Download a Core or OOA supporting document
      if (this.document) {
        this.downloadApplications = this.$store.dispatch(
          'showOrDownloadDocument',
          {
            filePath:
              this.applicationType === APPLICATION_TYPE.LOCAL_AREA
                ? this.document.filePath
                : this.applications[0].applicationID + this.document.filePath,
            previewMode: this.document.previewMode
          }
        )
        this.$emit('close')
        return
      }

      // Download the Y67T application PDF (without any supporting docs)
      if (
        this.applicationType === APPLICATION_TYPE.Y67T &&
        !this.isDigitizeEOIConsiderationDocUploaded()
      ) {
        this.startPDFDownload(
          this.applications[0].applicationID,
          this.schoolCode,
          this.applicationType
        )
        return
      }

      if (this.applicationType === APPLICATION_TYPE.LOCAL_AREA) {
        // 1. This will only prepare a payload to download all supporting docs for a single application
        // 2. Need to refactor this code if you want to download the application PDF as well (and for multiple applications)
        // In Core, this.supportingDocuments is pre-loaded inside the application before this dialog is opened
        // (by the getLocalAreaSupportingDocs method in laApplications.js)
        this.$store.dispatch('showSpinner')
        const app = this.applications[0]
        const supportingDocuments = Object.values(
          this.$store.getters.application.supportingDocuments
        )
          .flat()
          .filter((doc) => doc.status === FILE_STATUSES.SAFE)
          .map((doc) => doc.objectKey)
        this.downloadApplications = [
          createDownloadApplicationObject(app, supportingDocuments)
        ]
      } else {
        this.downloadApplications = this.supportingDocuments.map((app) => {
          const supportingDocuments = app.docs
            .filter((doc) => doc.status === FILE_STATUSES.SAFE)
            .map((doc) => doc.objectKey)
          return createDownloadApplicationObject(app, supportingDocuments)
        })
      }

      this.downloadApplications = this.downloadApplications.filter(
        (downApp) => downApp.status !== 'MISSING'
      )

      if (
        !this.downloadApplications ||
        this.downloadApplications.length === 0
      ) {
        return
      }

      if (this.downloadApplications.length === 1 && this.skipDownloadApp) {
        this.downloadApplications = this.downloadApplications.map((app) => ({
          ...app,
          status: 'MISSING'
        }))
      }

      if (this.downloadApplications.length >= 10) {
        this.showDownloadProgressModal = true
      }

      // Download the Core or OOA or Y67T application PDF zip file (including any supporting docs)
      this.$store
        .dispatch('getSupportingDocumentsPDFs', {
          downloadApplications: this.downloadApplications,
          schoolCode: this.schoolCode,
          applicationType: this.applicationType
        })
        .then((data) => {
          if (!this.hasCancelled) {
            if (data.body) {
              // Set an artificial delay to avoid AWS access denied error FUS-332
              // This is hopefully a temporary work-around until we can find and fix the underlying cause
              // where the zip is not actually available in S3 when the signed url is returned.
              const dynamicTimeout =
                Math.floor(this.downloadApplications.length / 10) * 100 || 500
              setTimeout(() => {
                this.doDownload(
                  data.body.Location,
                  'Out of Area Enrolments (multiple)'
                )
              }, dynamicTimeout)
            } else {
              const pdfData = `data:application/pdf;base64,${data}`
              const fileName = `${this.downloadApplications[0]['id']}`
              // Workaround as IE prevents blob URIs due to security restrictions
              // stackoverflow.com/a/45732897
              if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                const pdfDataBlob = this.pdfBlobConversion(pdfData)
                window.navigator.msSaveOrOpenBlob(
                  pdfDataBlob,
                  `${fileName}.pdf`
                )
                this.$emit('close')
                return
              }
              this.doDownload(pdfData, `${fileName}.pdf`)
            }
          }
          this.$store.dispatch('hideSpinner')
        })
        .catch((error) => {
          if (error) {
            this.downloadError = true
          }
        })
    },
    doDownload(href, fileName) {
      if (this.hasCancelled) {
        return
      }
      const link = document.createElement('a')

      link.href = href
      link.download = fileName
      link.target = '_blank'
      link.click()
      this.$emit('close')
    },

    // Download the Y67T application PDF (without any supporting docs)
    async startPDFDownload(applicationId, schoolCode, applicationType) {
      const fileName = `Year 7 Expression of Interest - ${applicationId}`
      const pdfData = await this.$store
        .dispatch('getApplicationPDF', {
          applicationId,
          schoolCode,
          applicationType
        })
        .then((data) => `data:application/pdf;base64,${data}`)
        .catch((error) => {
          if (error) {
            this.downloadError = true
          }
        })

      if (pdfData) {
        // Workaround as IE prevents blob URIs due to security restrictions
        // stackoverflow.com/a/45732897
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const pdfDataBlob = this.pdfBlobConversion(pdfData)
          window.navigator.msSaveOrOpenBlob(pdfDataBlob, `${fileName}.pdf`)
          this.$emit('close')
          return
        }
        this.doDownload(pdfData, fileName)
      }
    },
    cancelDownload() {
      this.$emit('close')
      this.hasCancelled = true
    },
    // base64-blob library not working
    // From stackoverflow.com/a/48909758
    pdfBlobConversion(b64Data, contentType) {
      contentType = contentType || ''
      var sliceSize = 512
      b64Data = b64Data.replace(/^[^,]+,/, '')
      b64Data = b64Data.replace(/\s/g, '')
      var byteCharacters = window.atob(b64Data)
      var byteArrays = []

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset = offset + sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize)

        var byteNumbers = new Array(slice.length)
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        var byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
      }

      var blob = new Blob(byteArrays, { type: contentType })
      return blob
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .AppModal {
  text-align: left;
  white-space: normal;

  .box {
    padding: 1rem;
    max-width: 722px;
  }

  .heading {
    font-size: 1.3333rem;
  }

  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
    .sub-header {
      font-size: 1.25rem;
    }
  }

  .docs-item {
    background: $grey-light-10;
    border-radius: 4px;
    padding: 10px 20px !important;

    .docs-item-title {
      font-size: 1.125rem;
    }

    .docs-item-content {
      font-size: 0.875rem;
    }
  }
}

::v-deep .header {
  flex-direction: column;
}

.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}

.closeBtn {
  margin: -0.25rem -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}

.downloading-modal ::v-deep .header {
  display: flex;
  align-items: flex-start;
}
</style>
