<template>
  <div>
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0)">
        <path
          d="M25.387 0.677975C25.0639 0.354888 24.6531 0.133693 24.2056 0.0417953C23.758 -0.0501026 23.2933 -0.00866456 22.869 0.160975L1.48902 8.60997C1.07472 8.77342 0.715432 9.05128 0.45304 9.41115C0.190648 9.77101 0.0359668 10.1981 0.007037 10.6425C-0.0218928 11.0869 0.07612 11.5304 0.289645 11.9213C0.50317 12.3121 0.823408 12.6342 1.21302 12.85L8.80002 16.988L13.214 24.851C13.4103 25.2189 13.7038 25.5259 14.0624 25.7387C14.421 25.9515 14.831 26.0619 15.248 26.058H15.385C15.8304 26.0323 16.259 25.8792 16.6198 25.6168C16.9806 25.3544 17.2584 24.9938 17.42 24.578L25.903 3.19797C26.0739 2.77366 26.116 2.30844 26.0243 1.86032C25.9325 1.41219 25.7109 1.00099 25.387 0.677975ZM21.42 3.09598L9.38702 15.13L2.32002 10.958C2.18302 10.889 2.22002 10.717 2.35502 10.648L21.42 3.09598ZM15.11 23.718L11.145 16.478L22.973 4.64798L15.42 23.718C15.407 23.7485 15.3854 23.7745 15.3577 23.7928C15.3301 23.811 15.2977 23.8208 15.2645 23.8208C15.2314 23.8208 15.199 23.811 15.1713 23.7928C15.1437 23.7745 15.122 23.7485 15.109 23.718H15.11Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="26.069" height="26.062" />
        </clipPath>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SendIcon'
}
</script>

<style scoped lang="scss">
.v-icon.v-icon svg {
  border-radius: 0;
  transform: translateX(-1px) scale(0.85);
  fill: currentColor;
}
</style>
