export default {
  adviceType: 'PNR',
  consideringSelectiveSchool: null,
  isPaperEOI: null,
  isVerbalAdvice: null,
  currentPrimarySchool: null, // pre-app
  student: {},
  intendedHighSchool: {},
  localHighSchool: null,
  parentCarers: [
    {
      parentCarerCompletingApp: true,
      contactDetails: [{}]
    }
  ],
  residentialAddress: null
}
