<!--
  Provides a dialog for showing possible SRN matches. Choosing one will link the OES
  application to the one in ERN, allowing conflicts to then be highlighted.
-->
<template>
  <AppModal
    v-if="studentMatches"
    @close="cancel()"
    ref="AppModal"
    icon="search"
    max-width="950px"
    role-type="none"
  >
    <div slot="header" class="fullWidth">
      <div class="icon iconCircleBackground d-inline-block mr-2">
        <v-icon class="mt-n1" color="primary">mdi-account-outline</v-icon>
      </div>
      <div class="d-inline-block">
        <span><strong>Match this student</strong></span>
      </div>
      <v-btn
        text
        icon
        small
        class="modal__close-button float-right"
        color="primary"
        aria-label="close"
        @click="cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <div slot="body" class="content">
      <!-- Step 1: Student SRN search -->
      <!--
        Note: Transition fade between steps is necessary to emphasize the change in content.
        Otherwise it can look like nothing has happened because steps 1 & 2 display very similar
        lists.
      -->
      <transition name="fade">
        <div>
          <SrnMatchCurrentStudent :student="getStudentData" />
          <div class="header pt-0 pl-0">
            <v-row class="mt-6">
              <v-col cols="1">
                <div class="icon iconCircleBackground mr-2">
                  <v-icon class="mt-n1" color="primary">mdi-link</v-icon>
                </div>
              </v-col>
              <v-col cols="11">
                <span class="ml-n7 d-block"
                  ><strong
                    >Closest matches at {{ getPrimarySchoolName }}
                  </strong></span
                >
                <span class="ml-n7 helpText"
                  >Select the student record that matches the details above,
                  then select proceed</span
                >
              </v-col>
            </v-row>
          </div>
          <div class="scroll">
            <SrnMatchSearchRecord
              :records="studentMatches"
              :compare="getStudentData"
              :current-srn="currentSrn"
              @click="selectRecord"
              label="Student"
            />
          </div>
        </div>
      </transition>
    </div>
    <div slot="footer">
      <AdsButton
        @click="proceed"
        class="button button--forward proceed-btn"
        :disabled="isOkButtonDisabled"
        button-text="Proceed"
        icon="mdi-arrow-right"
      />
    </div>
  </AppModal>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import AppModal from '@/components/app/AppModal.vue'
import SrnMatchSearchRecord from '@/components/application/y67t/SrnMatchSearchRecord'
import SrnMatchCurrentStudent from '@/components/application/y67t/SrnMatchCurrentStudent'
import { mapGetters } from 'vuex'

export default {
  name: 'SrnMatchSearch',
  components: {
    SrnMatchCurrentStudent,
    SrnMatchSearchRecord,
    AppModal,
    AdsButton
  },
  data() {
    return {
      studentMatches: null,
      selectedRecord: null
    }
  },
  computed: {
    ...mapGetters(['y67tSearchFilter']),
    isOkButtonDisabled() {
      return (
        !this.selectedRecord ||
        (this.currentSrn &&
          this.selectedRecord &&
          this.selectedRecord.srn === this.currentSrn)
      )
    },

    getStudentData() {
      // Gets student data from the current application and maps the field names
      // to those returned from the student search, so the two can be compared.
      let app = this.$store.state.application

      return {
        dateOfBirth: app.student.dateOfBirth,
        postcode: app.residentialAddress ? app.residentialAddress.postCode : '',
        firstName: app.student.firstName,
        gender: app.student.genderCode === 'F' ? 'Female' : 'Male',
        parents: this.currentParents,
        schYear: 'Year 6',
        surname: app.student.familyName
      }
    },

    currentSrn() {
      return this.$store.state.application.student.SRN
    },

    currentParents() {
      let parents = []
      this.$store.state.application.parentCarers.forEach((parent) => {
        parents.push(
          `${parent.parentCarerFamilyName},  ${parent.parentCarerGivenName}`
        )
      })
      return parents
    },

    getPrimarySchoolName() {
      return this.$store.state.application.currentPrimarySchool.schoolName
    },
    application() {
      return this.$store.state.application
    },
    isPnrAdvice() {
      return (
        this.application.adviceType && this.application.adviceType === 'PNR'
      )
    }
  },

  methods: {
    getStudentMatches() {
      let me = this
      this.$store.dispatch('showSpinner')
      this.$store.dispatch('getY67TSrnMatches', {
        onComplete(matches) {
          me.populatePostcodes(matches)
          me.studentMatches = matches
          // Select current linked record, if present
          let currentLinkedRecord = matches.find(
            (record) => record.srn == me.currentSrn
          )
          if (currentLinkedRecord) {
            me.selectedRecord = currentLinkedRecord
          }
          me.$store.dispatch('hideSpinner')
        },
        // TODO: Y67T hardcode for Year 6. This may need to be updated in future.
        schYear: 'Year 6'
      })
    },

    populatePostcodes(matches) {
      // Extracts postcodes from address as API doesn't provide them separately
      matches.forEach((match) => {
        const parts = match.displayAddress.split(' ')
        parts.forEach((part) => {
          if (part.length === 4 && Number(part) >= 2000) {
            match.postcode = part
          }
        })
      })
    },

    selectRecord(record) {
      this.selectedRecord = record
    },

    proceed() {
      localStorage.setItem(
        'y67tSearch',
        this.y67tSearchFilter('PRIMARYEOILIST')
      )
      this.linkSrn(this.selectedRecord.srn)
    },

    linkSrn(srn) {
      if (this.isPnrAdvice) {
        // get ern address, add residentail address for PNR advice
        this.$store.dispatch('getAddressForSRN', srn).then(() => {})
      } else {
        this.$store.dispatch('linkSrnToEoI', srn).then(() => {
          // emit an event to do address lookup or lookup the address and store in vuex here...
          this.$store.dispatch('getAddressForSRN', srn)
        })
      }
      this.$store.commit('setShowSrnMatchSearch', false)
    },

    cancel() {
      this.$store.commit('setShowSrnMatchSearch', false)
    }
  },

  mounted() {
    this.getStudentMatches()
  }
}
</script>

<style scoped lang="scss">
.content {
  border-top: 1px solid #d4d4d4;
}

.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}

.fullWidth {
  width: 100%;
}

.header {
  max-width: none;

  .helpText {
    font-size: 1rem;
    color: $grey-1;
  }
}

.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}

.checkbox {
  font-size: 1.5rem;
  width: 1em;
  height: 1em;
  color: white;
  background-color: white;
  border-radius: 1em;
  padding: 0.2em;
  vertical-align: middle;
  margin-right: 0.5rem;
}

.isSelected .checkbox {
  color: $color-primary;
}

.scroll {
  // If enough screen height, make list of matches scrollable
  @media (min-height: 600px) {
    overflow: auto;
    // Makes the list of matches scrollable (viewport height minus height of dialog
    // header, current student details and footer)
    max-height: calc(100vh - 260px);
    // Padding gives space so that the focus ring is not cut off.
    // Negative margin counteracts the padding so size retains the same.
    padding: 0 1em;
    margin: 0 -1em;
  }
}

// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-active {
  transition: none;
}

.fade-enter {
  opacity: 0;
}

.fade-leave {
  display: none;
}
::v-deep .proceed-btn.theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}
</style>
