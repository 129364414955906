import { VChip } from 'vuetify/lib/components'
import { disableVuetifyClickFn } from '@/helpers/hacks'
import { Chip } from '@nswdoe/doe-ui-core'
import Vue from 'vue'

/** This component hacks the ADS Chip that will always read as 'clickable' by NVDA */

export default Vue.extend(Chip).extend({
  components: {
    VChip: disableVuetifyClickFn(VChip)
  }
})
