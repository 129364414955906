<template>
  <div>
    <v-avatar
      class="primary lighten-2 white--text headline mr-2 font-weight-bold"
      size="29"
    >
      {{ year }}
    </v-avatar>
    <span class="d-none d-lg-inline">
      {{ scholasticYearFormatted }}
    </span>
  </div>
</template>

<script>
import allScholasticYears from '@/store/allScholasticYears'
export default {
  name: 'ScholasticYear',
  props: {
    year: {
      type: String,
      default: ''
    }
  },
  computed: {
    scholasticYearFormatted() {
      return allScholasticYears.reduce(
        (acc, i) => (i.value === this.year ? i.text : acc),
        ''
      )
    }
  }
}
</script>
