<template>
  <div>
    <div class="mx-6 mx-md-12 pt-4">
      <div class="d-flex align-start">
        <div
          class="app-settings__circle-icon d-flex align-center justify-center"
        >
          <v-icon size="30">playlist_add_check</v-icon>
        </div>
        <div class="ml-3 mt-3">
          <h3 class="app-settings-heading">Selection criteria</h3>
        </div>
      </div>
      <h4 class="app-settings__subheading pt-4 pb-4">
        {{ school.schoolName }} selection criteria:
      </h4>
      <div
        v-if="isOverCap"
        class="d-flex align-center pb-6"
        data-testid="app-settings-over-cap-criteria"
      >
        <div
          class="app-settings__circle-icon large d-flex align-center justify-center"
        >
          <v-icon size="90">mdi-alert-circle-outline</v-icon>
        </div>
        <div class="ml-6">
          <p class="app-settings__paragraph-text">
            When schools are <strong>over cap</strong>, selection criteria are
            disabled. Parents are given the opportunity to provide limited
            details and schools assess these applications according to enrolment
            policy.
          </p>
        </div>
      </div>
      <div v-else>
        <v-card v-if="selectionCriteria && selectionCriteria.length">
          <v-list class="py-0" data-testid="app-settings-criteria-list">
            <v-list-item
              v-for="(criteria, i) in selectionCriteria"
              :key="criteria.code"
              class="app-settings__criteria-row mr-0"
              :class="{ unselected: isUnselected(criteria) }"
              two-line
            >
              <v-list-item-content :class="{ locked: !criteria.allowReorder }">
                <v-list-item-title class="d-flex pl-3">
                  <OesCheckbox
                    v-if="criteria.allowReorder"
                    v-model="criteria.selected"
                    class="mr-1 criteria"
                    :aria-label="`${criteria.selected ? i + 1 : ''} -
                    ${criteria.value}`"
                    :id="getCriteriaId(criteria.code)"
                    @change="(val) => selectionChanged(val, i)"
                    :readonly="isSuperAdmin"
                    data-testid="app-settings-criteria-row-checkbox"
                  >
                    <template slot="label">
                      <AppSettingsCriteriaLabel
                        :number="isUnselected(criteria) ? null : i + 1"
                        :text="criteria.value"
                      />
                    </template>
                  </OesCheckbox>
                  <div
                    v-if="!criteria.allowReorder"
                    class="d-flex align-center"
                    tabindex="0"
                  >
                    <v-icon
                      class="mr-2"
                      role="img"
                      aria-label="position locked"
                      aria-hidden="false"
                      >mdi-lock-outline</v-icon
                    >
                    <AppSettingsCriteriaLabel
                      :number="i + 1"
                      :text="criteria.value"
                    />
                  </div>
                  <v-spacer />
                  <v-btn
                    v-if="criteria.selected && criteria.allowReorder"
                    icon
                    class="app-settings__reorder-button"
                    @click="moveUp(i)"
                    :disabled="isFirstMovable(i) || isSuperAdmin"
                    :aria-label="`move ${criteria.value} up`"
                  >
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="app-settings__criteria-description"
                >
                  <AdsTextArea
                    v-if="criteria.selected"
                    v-model="criteria.description"
                    label="Description"
                    placeholder=" "
                    :outlined="true"
                    :rows="3"
                    :counter="250"
                    maxlength="250"
                    data-testId="app-settings-criteria-description"
                    persistent-placeholder
                  />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <div :class="isY67THighSchoolOnly ? 'pt-6' : ''">
          <OesCheckbox
            v-if="!isY67THighSchoolOnly"
            @change="$emit('updatedOtherConsiderations', $event)"
            :input-value="otherConsiderations"
            class="app-settings__other-considerations-checkbox"
            data-testid="app-settings-other-considerations-checkbox"
            @click="trackOtherConsiderationsClick"
            :readonly="isSuperAdmin"
          >
            <template slot="label">
              <!-- eslint-disable -->
              <span
                >Enable &ldquo;<strong>Other considerations</strong>&rdquo;
                field in selections criteria form</span
              >
              <!-- eslint-enable -->
            </template>
          </OesCheckbox>
          <Alert
            v-if="!isY67THighSchoolOnly && isY67TAndOoaSchool"
            class="px-6 pb-6"
            in-page
            type="warning"
            text="NOTE: The other considerations field will not appear for 6-7 transition EOIs."
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AdsTextArea, Alert } from '@nswdoe/doe-ui-core'
import OesCheckbox from '@/components/app/OesCheckbox'
import AppSettingsCriteriaLabel from './AppSettingsCriteriaLabel.vue'
import settingsUtils from '@/helpers/settingsUtils'

export default {
  name: 'AppSettingsSelectionCriteria',
  components: {
    AdsTextArea,
    OesCheckbox,
    AppSettingsCriteriaLabel,
    Alert
  },
  props: {
    school: {
      type: Object,
      required: true
    },
    capacity: {
      type: String,
      default: null
    },
    selectionCriteria: {
      type: Array,
      default: () => []
    },
    otherConsiderations: {
      type: Boolean,
      default: null
    }
  },
  computed: {
    isSuperAdmin() {
      return settingsUtils.isSuperAdmin()
    },
    isOverCap() {
      return settingsUtils.isOverCap(this.capacity)
    },
    isHighSchool() {
      return settingsUtils.isHighSchool(this.school)
    },
    isY67THighSchoolOnly() {
      return settingsUtils.isY67THighSchoolOnly(this.school)
    },
    isY67TAndOoaSchool() {
      return settingsUtils.isY67TAndOoaSchool(this.school)
    }
  },
  methods: {
    getCriteriaId(code) {
      return `criteria-${code}`
    },
    isUnselected(criteria) {
      return !criteria.selected && criteria.allowReorder
    },
    selectionChanged(val, i) {
      const criteria = this.selectionCriteria[i]
      // FUS-1813: Changes to eslint rules now disallow the mutation of
      // component props. The error for the next line has been disabled
      // as the component is currently working.
      // eslint-disable-next-line vue/no-mutating-props
      this.selectionCriteria.splice(i, 1)
      if (val) {
        // move to the bottom of the selected list
        const selectedCount = this.selectionCriteria.filter(
          (c) => c.selected
        ).length // eslint-disable-line
        // FUS-1813: Changes to eslint rules now disallow the mutation of
        // component props. The error for the next line ihas been disabled
        // as the component is currently working.
        // eslint-disable-next-line vue/no-mutating-props
        this.selectionCriteria.splice(selectedCount, 0, criteria)
      } else {
        // move to the top of unselected list
        const unselectedCount = this.selectionCriteria.filter(
          (c) => !c.selected
        ).length // eslint-disable-line
        // FUS-1813: Changes to eslint rules now disallow the mutation of
        // component props. The error for the next line ihas been disabled
        // as the component is currently working.
        // eslint-disable-next-line vue/no-mutating-props
        this.selectionCriteria.splice(
          this.selectionCriteria.length - unselectedCount,
          0,
          criteria
        )
      }
      //setting the focus manually to the inner <input> element inside the Vue component
      setTimeout(() => {
        document
          .querySelector(`input#${this.getCriteriaId(criteria.code)}`)
          .focus()
      }, 100)
    },
    moveUp(i) {
      const criteria = this.selectionCriteria[i]
      // FUS-1813: Changes to eslint rules now disallow the mutation of
      // component props. The error for the next line has been disabled
      // as the component is currently working.
      // eslint-disable-next-line vue/no-mutating-props
      this.selectionCriteria.splice(i, 1)
      // FUS-1813: Changes to eslint rules now disallow the mutation of
      // component props. The error for the next line has been disabled
      // as the component is currently working.
      // eslint-disable-next-line vue/no-mutating-props
      this.selectionCriteria.splice(i - 1, 0, criteria)
      this.ariaAnnouncement = `${criteria.value} moved to position ${i}`
    },
    isFirstMovable(index) {
      return (
        this.selectionCriteria.find((c) => c.allowReorder && c.selected) ===
        this.selectionCriteria[index]
      )
    },
    trackOtherConsiderationsClick() {
      this.$gtm.trackEvent({
        category: 'otherConsiderations',
        action: this.otherConsiderations,
        label: this.school.schoolName
      })
    }
  }
}
</script>

<style scoped lang="scss">
.app-settings__criteria-row {
  min-height: 60px;
  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }
  &.unselected {
    background: $ads-light-10;
    .v-label {
      color: $ads-dark-80;
    }
  }
}

.app-settings__reorder-button {
  border: none !important;
  &.v-btn.v-btn--disabled {
    color: $ads-light-40 !important;
    opacity: 1;
  }
  &.v-btn:focus {
    border: 2px solid $color-primary !important;
  }
}

::v-deep .app-settings__criteria-description .v-text-field.v-textarea {
  max-width: 800px;
  margin-left: 80px;
  margin-top: 5px;
  .locked & {
    margin-top: 10px;
  }
  .v-label {
    font-weight: bold;
    font-size: 1.2rem;
  }
  textarea {
    color: $ads-dark-70;
  }
}

.app-settings__other-considerations-checkbox label span {
  color: $ads-dark;
}
</style>
