<template>
  <AdsSelect
    id="statusSwitcher"
    v-model="applicationStatus"
    :items="statusOptions"
    placeholder="Change status..."
    outlined
    hide-details
    aria-label="Change the application status"
    @change="changeStatus"
    min-width="200px"
    persistent-placeholder
  />
</template>

<script>
import { OOA_EDITABLE_STATUSES } from '@/constants'
import { AdsSelect } from '@nswdoe/doe-ui-core'

export default {
  name: 'ApplicationStatusSwitcher',
  components: {
    AdsSelect
  },
  data() {
    const statusOptions = OOA_EDITABLE_STATUSES.map((s) => ({
      value: s,
      text: s
    }))
    return {
      applicationStatus: null,
      statusOptions
    }
  },
  methods: {
    changeStatus(val) {
      this.$store.dispatch('saveApplicationStatus', {
        applicationStatus: val
      })
    }
  }
}
</script>

<style scoped lang="scss">
#statusSwitcher {
  max-width: 250px;
  min-width: 200px;
}
</style>
