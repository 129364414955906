function getStatusFromValidity(isValid) {
  switch (isValid) {
    case false:
      return 'invalid'
    case true:
      return 'complete'
    default:
      return 'initial'
  }
}

// NOTE: 'currentStep' state + getter is in the module.
// data is held in the meta part of the application object
const stepperModule = {
  getters: {
    steps: (state, getters) => {
      return [
        {
          key: 'student',
          name: 'Student details',
          form: 'studentEoIDetails',
          status: getStatusFromValidity(getters.validStudent)
        },
        {
          key: 'parentcarer',
          name: 'Parent/carer details',
          form: 'parentCarerEOIDetails',
          status: getStatusFromValidity(getters.validParentCarers)
        },
        {
          key: 'highschool',
          name: 'High school options',
          form: 'highSchoolEoIDetails',
          status:
            getters.validConsiderations !== null
              ? getStatusFromValidity(
                  getters.validHighSchool && getters.validConsiderations
                )
              : getStatusFromValidity(getters.validHighSchool)
        },
        {
          key: 'review',
          name: 'Review & submit'
        }
      ].map((stepInfo, index) => ({ ...stepInfo, step: index + 1 }))
    }
  }
}

export default stepperModule
