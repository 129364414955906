<template>
  <div class="pa-8" :class="{ isEditing }">
    <v-row v-if="isPaperEOI">
      <v-col cols="12" md="4">
        <radio-options
          label="Application origin"
          v-model="origin"
          :options="applicationOrigin"
          :rules="[validators.required]"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <text-field
          label="Student's first name"
          v-model.trim="student.firstName"
          :rules="[validators.required, validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <text-field
          label="Student's family name"
          v-model.trim="student.familyName"
          :rules="[validators.required, validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <text-field
          label="Student's preferred name"
          v-model.trim="student.preferredName"
          :rules="[validators.name]"
          maxlength="100"
          :readonly="readonly"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <radio-options
          label="Student's gender"
          v-model="student.genderCode"
          :options="genderOptions"
          :rules="[validators.required]"
          row
          :readonly="readonly"
        />
      </v-col>
      <v-col cols="12" md="4">
        <date-field
          label="Student’s date of birth"
          v-model="student.dateOfBirth"
          :date-picker-attrs="{
            min: seventeenYearsAgo,
            max: nineYearsAgo
          }"
          :date-menu-attrs="{
            'offset-y': true,
            top: true
          }"
          year-picker
          :rules="[
            validators.required,
            validators.noFutureDate,
            ...birthdateValidators
          ]"
          :readonly="readonly"
          data-testid="studentsBirthdate"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatters from '@/helpers/formatters'
import validators from '@/helpers/validators'
import { findValidatableElement } from '@/helpers/form'

import TextField from '@/components/form/TextField'
import DateField from '@/components/form/DateField'
import RadioOptions from '@/components/form/RadioOptions'
import { GENDER_OPTIONS, APPLICATION_ORIGIN } from '@/constants'

export default {
  name: 'StudentDetails',
  components: {
    TextField,
    DateField,
    RadioOptions
  },
  props: {
    readonly: {
      type: Boolean,
      defaiult: false
    }
  },
  data() {
    const dateNineYearsAgo = new Date()
    dateNineYearsAgo.setFullYear(dateNineYearsAgo.getFullYear() - 9)
    const nineYearsAgo = dateNineYearsAgo.toISOString().slice(0, 10)
    const dateSeventeenYearsAgo = new Date()
    dateSeventeenYearsAgo.setFullYear(dateSeventeenYearsAgo.getFullYear() - 17)
    const seventeenYearsAgo = dateSeventeenYearsAgo.toISOString().slice(0, 10)

    const { required, noPastDate, noFutureDate, name } = validators

    return {
      nineYearsAgo,
      seventeenYearsAgo,
      boolYesNo: formatters.boolYesNo,
      validators: {
        required,
        noPastDate,
        noFutureDate,
        name
      },
      genderOptions: GENDER_OPTIONS,
      applicationOrigin: APPLICATION_ORIGIN
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing', 'referenceData']),
    student() {
      return this.application.student
    },
    birthdateValidators() {
      return [
        validators.getY67TStudentMinAgeValidator(this.student.dateOfBirth),
        validators.getY67TStudentMaxAgeValidator(this.student.dateOfBirth)
      ]
    },
    isPaperEOI() {
      return this.application.isPaperEOI || this.application.isVerbalAdvice
    },
    origin() {
      return this.application.isPaperEOI ? 'paper' : 'verbal'
    }
  },
  methods: {
    crossValidate(ref) {
      const refEl = this.$refs[ref]
      // our component doesn't implement vuetify's validatable interface
      // so we have to find the validatable element in its children
      const validatableElement = findValidatableElement(refEl)
      if (validatableElement) {
        validatableElement.validate(true)
      }
    }
  }
}
</script>
