<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <p class="section-title mt-4">Considerations document(s)</p>
    <div class="field-label">Supporting documents</div>
    <div class="field-value" data-testid="considerations-document">
      <v-icon
        v-if="considerationsDocumentName"
        size="30"
        class="field-icon"
        color="primary"
        >mdi-file-outline</v-icon
      >
      {{ considerationsDocumentName || '(empty)' }}
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ConsiderationsView',
  computed: {
    ...mapGetters(['supportingDocuments']),
    considerationsDocumentName() {
      return this.supportingDocuments?.digitizeEoiConsiderations[0]?.name
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  font-family: $digitize-eoi-font-family;
  font-size: 18px;
  color: $ads-navy;
  font-weight: bold;
  line-height: 26px;
}
.field-value {
  font-family: $digitize-eoi-font-family;
  color: $ads-navy;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  overflow-wrap: break-word;
}
.field-label {
  font-family: $digitize-eoi-font-family;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: $ads-dark-70;
  overflow-wrap: break-word;
  margin-bottom: 0.25rem;
}
.field-icon {
  margin-right: 1rem;
}
</style>
