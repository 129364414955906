import { render, staticRenderFns } from "./SrnSearchSiblingRecord.vue?vue&type=template&id=4c982f40&scoped=true&"
import script from "./SrnSearchSiblingRecord.vue?vue&type=script&lang=js&"
export * from "./SrnSearchSiblingRecord.vue?vue&type=script&lang=js&"
import style0 from "./SrnSearchSiblingRecord.vue?vue&type=style&index=0&id=4c982f40&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c982f40",
  null
  
)

export default component.exports