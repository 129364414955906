<template>
  <div>
    <FileUpload
      ref="fileUpload"
      v-model="files"
      label="Supporting documents (school use only - optional)"
      :max-file-count="5"
      :max-file-size-mb="5"
      :mime-types="mimeTypes"
      @validationError="handleUploadValidationError"
      @upload="uploadFile"
      @delete="deleteFile"
    />
    <div
      class="alertsWrapper"
      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
      role="alert"
      aria-live="assertive"
    >
      <Alert
        v-for="(alert, i) in alerts"
        :key="i"
        in-page
        :show-alert="true"
        type="error"
        :icon="alert.icon"
        :text="alert.title"
        absolute
      >
        <template slot="optional">
          <div v-html="alert.html" />
          <v-btn
            icon
            class="snackbarCloseButton"
            @click="closeError(i)"
            aria-label="close"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </Alert>
    </div>
  </div>
</template>

<script>
import FileUpload from './FileUpload.vue'
import { FILE_UPLOAD_ERROR_TYPES } from '@/constants'
import { Alert } from '@nswdoe/doe-ui-core'

export default {
  name: 'SupportingDocumentUpload',
  components: {
    FileUpload,
    Alert
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isBusy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      alerts: [],
      files: this.value,
      documentGroupId: new Date().getTime(),
      operationsInProgress: 0,
      mimeTypes: 'image/jpg,image/jpeg,image/png,application/pdf'
    }
  },
  watch: {
    files(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.files = val
    },
    operationsInProgress(val) {
      if (val) {
        this.$emit('update:isBusy', true)
      } else {
        this.$emit('update:isBusy', false)
      }
    }
  },
  computed: {
    fileTypesListHtml() {
      // convert mime types to file types list for display
      // image/jpg,application/pdf => <strong>jpg</strong>, or <strong>pdf</strong
      const types = this.mimeTypes
        .split(',')
        .map((mime) => `<strong>${mime.split('/')[1]}</strong>`)
      if (types.length > 1) {
        types[types.length - 1] = `or ${types[types.length - 1]}`
      }
      return types.join(', ')
    }
  },
  methods: {
    uploadFile(file, { progress, success, failure }) {
      this.operationsInProgress++
      const { applicationID, schoolCode } = this.$store.getters.application
      const uploadedBy = this.$store.getters.userID

      this.$store
        .dispatch('uploadSupportingDocument', {
          file,
          schoolCode,
          applicationId: applicationID,
          path: this.documentGroupId,
          uploadedBy,
          progressCallback: progress
        })
        .then((uploadedPath) => {
          file.key = uploadedPath
          success(file)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleUploadValidationError({ fileName: file.name })
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress--
        })
    },
    deleteFile(file, { success, failure }) {
      this.operationsInProgress++
      const { applicationID, schoolCode } = this.$store.getters.application

      this.$store
        .dispatch('deleteSupportingDocument', {
          file,
          schoolCode,
          applicationId: applicationID,
          path: this.documentGroupId
        })
        .then(() => {
          success(file)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleDeleteValidationError(file.name)
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress--
        })
    },
    deleteAll(successCallback, failureCallback) {
      const noOp = () => {}
      for (let f of this.files) {
        this.deleteFile(f, {
          success: successCallback || noOp,
          failure: failureCallback || noOp
        })
      }
    },
    handleUploadValidationError({ type, fileName }) {
      const errorReasons = {
        [FILE_UPLOAD_ERROR_TYPES.FILE_SIZE]: {
          reason: 'it exceeds the maximum file size of <strong>5mb</strong>.',
          action: 'Please choose a smaller document and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_TYPE]: {
          reason: 'it is an invalid file type.',
          action: `Please choose a file of type ${this.fileTypesListHtml} and try again.`
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_NAME]: {
          reason: 'this is not a valid file name.',
          action:
            'Please remove symbols and non-alphanumeric characters from the file name and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES]: {
          reason: 'you have exceeded the maximum number of documents.'
        }
      }

      const reason = errorReasons[type]
        ? errorReasons[type].reason
        : 'of a server error.'

      const action = errorReasons[type]
        ? errorReasons[type].action || ''
        : 'Please try uploading the file again.'

      if (reason) {
        this.pushNotification(
          {
            display: true,
            title: 'File did not upload',
            type: 'error',
            html:
              `<p class="mb-2"><strong>${fileName}</strong> could not be uploaded because ${reason}<p>` +
              (action ? `<p>${action}</p>` : ''),
            icon: 'mdi-alert-circle'
          },
          // Notifications added immediately after file explorer closes need a delay in order for some assistive technologies to pick up the notification.
          500
        )
      }
    },
    handleDeleteValidationError(fileName) {
      this.pushNotification({
        display: true,
        title: 'File could not be removed',
        type: 'error',
        html: `<p class="mb-2"><strong>${fileName}</strong> could not be removed because of a server error.</p><p>Please try removing the file again.</p>`,
        icon: 'mdi-alert-circle'
      })
    },
    pushNotification(notification, delay) {
      if (delay) {
        setTimeout(() => {
          this.alerts.push(notification)
        }, delay)
      } else {
        this.alerts.push(notification)
      }
    },
    closeError(i) {
      this.alerts.splice(i, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.alertsWrapper {
  position: fixed;
  left: 20px;
  bottom: 10px;
  max-width: 50vw;
  z-index: 10;
  &.mobile {
    max-width: 100vw;
    margin-right: 20px;
  }
}

::v-deep .v-alert .v-alert__wrapper {
  .snackbar--text {
    margin-bottom: 8px;
    ~ span {
      line-height: 1.3rem;
    }
  }
  .v-alert__content {
    margin-right: 35px;
  }
  .snackbarCloseButton {
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
button.snackbarCloseButton.v-btn.v-btn--flat.v-btn--icon.v-btn--round.theme--light.v-size--default:focus {
  border: 2px solid $color-primary;
}
</style>
