<template>
  <v-dialog
    :value="!$store.state.messageBox"
    v-bind="$attrs"
    v-on="$listeners"
    :max-width="dialogWidth"
    :persistent="isPersistent"
    ref="dialogModal"
  >
    <v-card class="dialogModal">
      <v-icon
        ref="closeButton"
        @click="$emit('close')"
        class="modal__close-button float-right"
        aria-label="close"
      >
        mdi-close
      </v-icon>
      <div class="header heading d-flex align-left">
        <slot role="alert" name="header"></slot>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="d-flex justify-end align-end">
        <v-card-actions>
          <slot name="footer"></slot>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogModal',
  props: {
    isPersistent: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: Number,
      default: 744
    }
  }
}
</script>

<style scoped lang="scss">
.dialogModal {
  padding: 2rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: absolute;
    top: 1.25rem;
    right: 1.2rem;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .heading {
    display: flex;
    font-weight: 700;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.3333rem;
  }

  p {
    line-height: 1.5;
  }

  .body {
    margin: 1.5rem 0;
    line-height: 1.5;
  }
}
</style>
