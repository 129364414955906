<template>
  <div class="message">
    <v-icon size="30" class="message__icon" :color="message.iconColor">{{
      message.icon
    }}</v-icon>

    <div class="message__body">
      <p v-if="message && message.vHtmlBody" v-html="message.vHtmlBody"></p>
      <p
        v-else-if="message && message.body"
        data-testid="messageWithActionBody"
      >
        {{ bodyText }}
      </p>
      <button
        type="button"
        v-if="message && message.action"
        @click="onClick"
        class="no-styling message__action"
      >
        {{ actionText }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageWithAction',
  props: {
    message: {
      type: Object
    }
  },
  methods: {
    onClick() {
      this.message.action.clickHandler(this.$store)
    }
  },
  computed: {
    actionText() {
      return this.message.action.text(this.$store)
    },
    bodyText() {
      return this.message.body(this.$store)
    }
  }
}
</script>

<style lang="scss" scoped>
.message {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 0 0 1.2rem 1.2rem;
  border-radius: 4px;
  border-color: transparent;
  color: $grey-dark;
  font-weight: bold;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__action {
    margin-top: 0.5rem;
    text-decoration: underline;
    font-weight: bold;
  }

  &__body {
    font-size: 1.07rem;
    margin-right: 2rem;

    > p {
      margin: 0;
    }
  }

  &__icon {
    margin-right: 1.2rem;
    align-self: start;
  }
}
</style>
