<template>
  <v-autocomplete
    v-model="selectedSchool"
    :search-input="searchString"
    :items="auSchools"
    append-icon="mdi-chevron-down"
    hide-details
    item-value="orgUnitName"
    placeholder="Search Australian schools"
    solo
    @change="selectSchool"
    return-object
    :menu-props="{
      maxHeight: 300,
      bottom: true,
      offsetY: true,
      class: 'schoolLookupMenu'
    }"
    :filter="nameStartWith"
    filled
    ref="defaultInput"
  >
    <template #item="{ item }">
      <template>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            item.orgUnitName
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          >{{ item.suburb }}, {{ item.stateCode }}</v-list-item-action
        >
      </template>
    </template>

    <template #selection="{ item }">
      <template>{{ item.orgUnitName }}, {{ item.stateCode }}</template>
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  name: 'SchoolLookup',
  props: {
    selectedSchoolId: String
  },
  data() {
    return {
      searchString: null,
      selectedSchool: {}
    }
  },
  computed: {
    auSchools() {
      return this.$store.state.auSchools || []
    }
  },
  mounted() {
    try {
      this.selectedSchool =
        this.auSchools.find((s) => s.uniqueRecord === this.selectedSchoolId) ||
        {}
    } catch (e) {
      this.selectedSchool = {}
    }
  },
  methods: {
    selectSchool(selectedSchool) {
      // Note that selectedSchool could be undefined if user deletes the selected value
      this.selectedSchool = selectedSchool || null
      this.$emit(
        'schoolCodeChange',
        selectedSchool ? selectedSchool.uniqueRecord : null
      )
    },
    nameStartWith(item, queryText) {
      const schoolName = item.orgUnitName.toLowerCase()
      const searchText = queryText.toLowerCase()
      return schoolName.startsWith(searchText)
    }
  }
}
</script>

<style scoped lang="scss">
//css overrides for v-autocomplete which needs to be revisited if vuetify inputs are going to be implemented in the edit page.
::v-deep {
  .v-input__control {
    background: white;
    box-shadow: 0px 0px;
    border: 1px solid $grey-4;
    min-height: 9px;
    background: $grey-7;
    height: 35px;
    min-height: 35px !important;
    font-size: $font-size-root !important;

    .v-input__slot {
      border-radius: 0;
      background: $grey-7 !important;
      box-shadow: none !important;
      min-height: 31px !important;
      height: 31px;
      input {
        border: none;
        background: $grey-7;
        padding: 0;
        outline: none;
      }
    }
  }
  .theme--light.v-icon {
    color: black;
  }
}
</style>
