<template>
  <div>
    <AppModal
      v-if="showSetTargetModal"
      ref="AppModal"
      data-testid="set-target-modal"
      class="set-target-modal"
    >
      <template slot="header">
        <v-icon
          ref="closeButton"
          @click="$emit('close')"
          class="text-right closeBtn"
          aria-label="Close"
          data-testid="set-target-close-modal"
        >
          mdi-close
        </v-icon>
        <div class="heading">
          <span class="font-weight-bold">Enter an enrolment forecast</span>
        </div>
      </template>
      <div slot="body">
        <div class="content">
          <p v-if="targetText">
            Your current forecast is <strong>{{ targetText }}</strong
            >. Enter a forecast for year 7 enrolments below:
          </p>
          <p v-else>Enter a forecast for year 7 enrolments below:</p>
          <p>
            <AdsTextField
              class="forecast-text"
              label="Enrolment forecast"
              placeholder="Enter forecast"
              maxlength="20"
              v-model="target"
              height="70"
              :rules="[validators.nan, validators.max, validators.required]"
              persistent-placeholder
            />
          </p>
        </div>
      </div>
      <div slot="footer">
        <AdsButton
          ref="confirmBtn"
          color="primary"
          @click="emitTarget"
          aria-label="Confirm"
          button-text="Confirm"
          :disabled="isSetTargetButtonDisabled || !target"
        />
      </div>
    </AppModal>
  </div>
</template>

<script>
import AppModal from '@/components/app/AppModal.vue'
import { AdsTextField, AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
export default {
  name: 'SetTargetDialog',
  components: {
    AppModal,
    AdsTextField,
    AdsButton
  },
  computed: {
    ...mapGetters(['selectedSchool', 'numbersTarget', 'targetText']),
    isSetTargetButtonDisabled() {
      if (
        this.validators.max(this.target) ===
          'Forecast number should be in between 1 to 999. Please choose an integer within this range.' ||
        this.validators.nan(this.target) ===
          'Forecast entered is not a valid number. Please use an integer between 1 to 999.'
      ) {
        return true
      }
      return false
    }
  },
  data() {
    return {
      target: null,
      showSetTargetModal: true,
      validators: {
        required: validators.required,
        nan: validators.nan,
        max: validators.max
      }
    }
  },
  methods: {
    emitTarget() {
      this.$emit('setTarget', this.target)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .AppModal {
  text-align: left;
  white-space: normal;
  .box {
    padding: 1rem;
    width: 600px;
  }
  .heading {
    font-size: 1.3333rem;
    font-weight: 700;
    margin-top: -1rem;
  }
  .body {
    font-size: 1.0666rem;
    line-height: 1.6rem;
  }
}
::v-deep .header {
  flex-direction: column;
}
.heading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
}
.closeBtn {
  margin: 0 -0.5rem 0 auto;
  border: none;
  padding: 0;
  &:hover {
    text-decoration: none !important;
  }
}
.footer {
  text-align: right;
}
.forecast-text {
  width: 400px;
}
.theme--light.v-icon.v-icon--link {
  color: $color-dark70;
  &:focus {
    color: $color-primary;
    outline: 3px solid $color-primary;
    border: none;
  }
}
</style>
