import Vue from 'vue'
import API from '@/store/apiUtils'
import router from '@/router'
import { convertToYYYYMMDDHHMM } from '@/helpers/date'
import { GENERIC_SERVER_ERROR, STREAM } from '@/constants'

import searchSrnMockData from '../../../tests/testData/sduSrnSearch.js'

const SRE_SEE_UPDATE_TYPE_TEXT = 'SRE/SEE'

const studentDetailUpdatesModule = {
  state: {
    studentDetailUpdates: [],
    studentDetailUpdate: {},
    filteredStudentDetailUpdates: [],
    sduApiApplicationUrl: '/v1/sreSeePreferenceChange/',
    ernRegistrationUrl: '/sresee/ern/registration/'
  },
  getters: {
    studentDetailUpdates: (state) => (schoolId) => {
      return state.studentDetailUpdates[schoolId] || []
    },

    studentDetailUpdate: (state) => state.studentDetailUpdate,

    getFilteredStudentDetailUpdates: (state) => {
      return state.filteredStudentDetailUpdates
    },

    getSduApplicationURL: (state) => state.sduApiApplicationUrl
  },
  mutations: {
    setStudentDetailUpdates(state, { schoolId, studentDetailUpdates }) {
      if (!state.studentDetailUpdates[schoolId]) {
        // eslint-disable-next-line import/no-named-as-default-member
        Vue.set(state.studentDetailUpdates, schoolId, [])
      }
      const manipulatedResponses = studentDetailUpdates?.map(
        ({ request, ...others }) => {
          return {
            ...others,
            request: {
              ...request,
              requestType: SRE_SEE_UPDATE_TYPE_TEXT
            }
          }
        }
      )

      // eslint-disable-next-line import/no-named-as-default-member
      Vue.set(state.studentDetailUpdates, schoolId, manipulatedResponses)
    },

    setStudentDetailUpdate(state, studentDetailUpdate) {
      state.studentDetailUpdate = studentDetailUpdate
    },

    setFilteredStudentDetailUpdates(state, filteredStudentDetailUpdates) {
      state.filteredStudentDetailUpdates = filteredStudentDetailUpdates
    },
    setStudentDetailUpdateStatus(state, status) {
      state.studentDetailUpdate.request.statusRequest = status
    }
  },
  actions: {
    loadStudentDetailUpdates({ commit, getters, state }, payload = {}) {
      const school = getters.selectedSchool
      const schoolCode = payload.payloadSchoolCode || school?.schoolCode

      if (!schoolCode) {
        return Promise.resolve()
      }

      return API.get(
        `${process.env.VUE_APP_API_PI}${state.sduApiApplicationUrl}${schoolCode}`,
        false,
        { schoolCode }
      )
        .then((res) => {
          return res?.data
        })
        .then((responses) => {
          const studentDetailUpdates = responses?.body
          commit('setStudentDetailUpdates', {
            schoolId: school.schoolCode,
            studentDetailUpdates
          })
        })
    },

    loadStudentDetailUpdate({ commit, state, getters }, { schoolCode, id }) {
      let updateGetUrl = `${process.env.VUE_APP_API_PI}${state.sduApiApplicationUrl}${schoolCode}/${id}`
      const currentStream = getters.currentStream

      if (currentStream === STREAM.ARCHIVE) {
        const applicationType = router.currentRoute.params.type
        updateGetUrl = `${process.env.VUE_APP_API_PI}/v1/archived/application/${schoolCode}/${id}?applicationType=${applicationType}`
      }

      return API.get(updateGetUrl, false, { schoolCode })
        .then((res) => {
          return res?.data
        })
        .then((response) => {
          commit('setStudentDetailUpdate', response?.body)
        })
    },

    loadStudentSearchDetails({ state }, { processType = 0, ...params }) {
      if (processType === 0) {
        return API.post(
          `${process.env.VUE_APP_API_PI}${state.sduApiApplicationUrl}${state.studentDetailUpdate.schoolCode}/studentSearchByRequest`,
          params,
          false,
          { schoolCode: state.studentDetailUpdate.schoolCode }
        ).then((res) => {
          return res?.data
        })
      }
      return Promise.resolve(searchSrnMockData[processType])
    },

    updateStudentDetailUpdateStatusNotState(
      { state },
      { schoolCode, id, status }
    ) {
      return API.patch(
        `${process.env.VUE_APP_API_PI}${state.sduApiApplicationUrl}${schoolCode}/${id}/status`,
        { status },
        false,
        { schoolCode }
      )
        .then((res) => {
          return res?.data
        })
        .then((response) => {
          if (response?.status === 200) {
            return Promise.resolve(response)
          } else {
            return Promise.reject(response)
          }
        })
    },

    updateStudentDetailUpdateStatusAndState({ commit, dispatch }, payload) {
      return dispatch('updateStudentDetailUpdateStatusNotState', payload).then(
        (response) => {
          commit('setStudentDetailUpdate', response?.body)
        }
      )
    },

    updateStudentDetailUpdateSrn(
      { commit, state, dispatch },
      { schoolCode, id, srn }
    ) {
      return API.patch(
        `${process.env.VUE_APP_API_PI}${state.sduApiApplicationUrl}${schoolCode}/${id}/srn`,
        { srn },
        false,
        { schoolCode }
      )
        .then((res) => {
          return res?.data
        })
        .then((response) => {
          commit('setStudentDetailUpdate', response?.body)
        })
        .catch((data) => {
          if (data?.response?.status === 400) {
            dispatch('showMessageBox', {
              icon: 'priority_high',
              html: `<h2>Unable to complete request</h2><p>${GENERIC_SERVER_ERROR}</p>`
            })
            return Promise.reject()
          }
          return Promise.reject(data)
        })
    },

    acceptStudentDetailUpdate({ state, dispatch, getters }, { schoolCode }) {
      const payload = {
        srn: state.studentDetailUpdate.studentDetail.srn,
        scholasticYear: state.studentDetailUpdate.studentDetail.scholasticYear,
        schoolCode: state.studentDetailUpdate.schoolCode,
        sreSeeOptionSetGUID:
          state.studentDetailUpdate.request.sreSeeOptionSetGUID,
        requestMedium: 'EHUB',
        requestedBy: `${state.studentDetailUpdate.parentDetail.parentFirstName} ${state.studentDetailUpdate.parentDetail.parentSurname}`,
        requestId: state.studentDetailUpdate.uuid,
        approvedBy: getters.staffUserEmailId,
        approvedDate: convertToYYYYMMDDHHMM(new Date()),
        requestedDate: convertToYYYYMMDDHHMM(
          new Date(state.studentDetailUpdate.submittedDate)
        ),
        note: ''
      }
      if (process.env.NODE_ENV === 'development') {
        return Promise.resolve(payload)
      }
      return API.post(
        `${state.ernRegistrationUrl}${schoolCode}/sreSeePreferenceChange`,
        payload,
        false,
        null,
        true
      )
        .then((res) => {
          return res?.data
        })
        .catch(() => {
          dispatch('showMessageBox', {
            icon: 'priority_high',
            html: `<h2>Unable to complete request</h2><p>${GENERIC_SERVER_ERROR}</p>`
          })
          return Promise.reject()
        })
    },
    setStudentDetailStatus({ commit }, status) {
      commit('setStudentDetailUpdateStatus', status)
    }
  }
}

export default studentDetailUpdatesModule
