<template>
  <div class="mt-2">
    <!-- Simple alerts -->
    <Alert
      v-for="(alert, i) in alertsToDisplay"
      v-bind="alert"
      in-page
      :key="`headAlert${i}`"
    />

    <!-- Complex alerts -->
    <Alert
      v-if="isSubmittedAndDeclined"
      in-page
      type="error"
      icon="mdi-alert-circle"
      background-colour
      data-testid="submittedAndDeclinedAlert"
      :text="`${getValue(
        'student.firstName'
      )}'s parent/carer has declined your school's offer after submitting an enrolment application`"
    >
      <template #optional>
        <div class="pt-4 alert--textsimple">
          <p>
            Please withdraw {{ getValue('student.firstName') }}'s enrolment
            application
          </p>
          <p class="mb-0" v-if="getValue('applicationID')">
            <strong
              ><router-link :to="{ name: 'ShsOfferApplicationView' }">
                Go to enrolment application
              </router-link></strong
            >
          </p>
        </div>
      </template>
    </Alert>

    <Alert
      v-if="isSubmittedByPaperAndDeclined || isInternalTransferAndDeclined"
      in-page
      type="error"
      icon="mdi-alert-circle"
      background-colour
      :data-testid="`${
        isSubmittedByPaperAndDeclined
          ? 'submittedByPaperAndDeclinedAlert'
          : 'internalTransferAndDeclinedAlert'
      }`"
      text="This enrolment application is invalid and should be withdrawn."
    >
      <template #optional>
        <div class="pt-4 alert--textsimple">
          <p>
            {{ getValue('student.firstName') }}’s parent/carer has declined your
            selective high school offer{{
              isSubmittedByPaperAndDeclined
                ? ' after submitting a paper enrolment application'
                : ''
            }}.
          </p>
          <p>
            <strong>Step 1: </strong>Go to ERN and update the student record
          </p>
          <p class="mb-0">
            <strong
              >Step 2:
              <a href="#" @click.prevent="showWithdrawConfirmModal"
                >Mark enrolment application status as 'withdrawn'</a
              >
            </strong>
          </p>
        </div>
      </template>
    </Alert>

    <Alert
      v-if="isSubmittedAndAccepted"
      in-page
      type="success"
      icon="mdi-check-circle"
      data-testid="submittedAndAcceptedAlert"
      :text="`${getValue('student.firstName')} ${getValue(
        'student.familyName'
      )}'s parent/carer(s) have submitted an enrolment application`"
    >
      <template #optional>
        <div class="pt-4 alert--textsimple">
          <p class="mb-0" v-if="getValue('applicationID')">
            <strong
              ><router-link :to="{ name: 'ShsOfferApplicationView' }">
                Go to enrolment application
              </router-link></strong
            >
          </p>
        </div>
      </template>
    </Alert>

    <Alert
      v-if="isWithdrawnAndDeclined && !isWithdrawnAndDeclinedInternalTransfer"
      in-page
      icon="mdi-lock-outline"
      class="greyBorderAndIcon"
      data-testid="withdrawnAndDeclinedAlert"
      :text="`The parent/carer initially accepted your school's offer and submitted ${
        getValue('applicationID') ? 'an' : 'a paper'
      } enrolment application, but then declined.`"
    >
      <template #optional>
        <div class="alert--textsimple">
          <p v-if="getValue('applicationID')">
            Enrolment application withdrawn.
          </p>
          <p v-else :class="{ 'mb-0': !getValue('applicationID') }">
            Enrolment application recorded as manually withdrawn in ERN.
          </p>
          <p class="mb-0" v-if="getValue('applicationID')">
            <strong
              ><router-link :to="{ name: 'ShsOfferApplicationView' }">
                Go to enrolment application
              </router-link></strong
            >
          </p>
        </div>
      </template>
    </Alert>

    <Alert
      v-if="isInvalidAndDeclined"
      in-page
      icon="mdi-lock-outline"
      class="greyBorderAndIcon"
      data-testid="invalidAndDeclinedAlert"
      :text="`${getValue(
        'student.firstName'
      )}'s parent/carer has declined your school's offer after submitting an enrolment application`"
    >
      <template #optional>
        <div class="alert--textsimple">
          <p>
            {{ getValue('student.firstName') }}'s enrolment application has been
            marked as invalid.
          </p>
          <p class="mb-0" v-if="getValue('applicationID')">
            <strong
              ><router-link :to="{ name: 'ShsOfferApplicationView' }">
                Go to enrolment application
              </router-link></strong
            >
          </p>
        </div>
      </template>
    </Alert>

    <!-- end top alerts -->

    <div class="d-flex justify-end mt-6">
      <v-menu offset-y left>
        <template #activator="{ on, attrs }">
          <AdsButton
            secondary
            v-on="on"
            v-bind="attrs"
            button-text="More actions"
            icon="more_vert"
            :disabled="isMoreActionsButtonDisabled"
            data-testid="moreActionsButton"
          />
        </template>
        <v-list>
          <v-list-item
            v-for="({ click, title, icon, ...btn }, index) in buttonActions"
            :key="index"
            @click="click"
            v-bind="btn"
          >
            <v-list-item-icon>
              <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="pb-6" v-if="renderReady">
      <AdsExpansionPanel
        :items="cExpansionPanelItems"
        v-model="openPanels"
        data-testid="sdu-accordion"
        class="mt-3"
      >
        <template v-for="cps in cPanelsData" #[`content${cps.id}`]>
          <template
            v-for="({ invisibleFieldOnEmpty, ...cpd }, f) in cps.sections"
          >
            <ReadOnlyFieldList
              :key="`cpd${f}`"
              :item-object="cpd.fields"
              :data-testid="`rofl${cpd.id}`"
              :columns="cps.columns"
              :title="cpd.name"
              v-bind="{ invisibleFieldOnEmpty }"
            />
          </template>
        </template>
      </AdsExpansionPanel>
    </div>
    <AppModal
      v-if="isShowConfirmSubmittedByPaperModal"
      ref="confirmSubmittedByPaperModal"
      data-testid="confirm-submitted-by-paper-modal"
    >
      <template slot="header">
        <div class="d-flex justify-space-between pl-3 w-full">
          <span class="font-weight-bold" role="alert"> Confirm update </span>
          <v-icon
            @click="isShowConfirmSubmittedByPaperModal = false"
            class="closeBtn"
          >
            mdi-close
          </v-icon>
        </div>
      </template>
      <template slot="body">
        <div class="content mt-3 mx-3">
          <p>
            <strong>
              {{ getValue('student.firstName') }}
              {{ getValue('student.familyName') }}'s enrolment application
              status will be marked as 'submitted by paper'.</strong
            >
          </p>
          <p>Please ensure the paper forms have been entered in ERN.</p>
          <Alert
            in-page
            icon="mdi-information"
            class="in-page-alert--small"
            alert-class="alert--no-border"
            elevation="0"
            background-colour
            type="info"
          >
            <template #optional>
              <p>This action cannot be undone.</p>
              <p>
                The parent/carer <em>will not</em> be sent an email to inform
                them of this update.
              </p>
            </template>
          </Alert>
        </div>
      </template>
      <template #footer>
        <div class="mb-0 mr-3 pb-2">
          <AdsButton
            icon="mdi-check"
            button-text="Confirm"
            aria-label="Confirm"
            @click="handleSubmittedByPaperConfirm"
          />
          <AdsButton
            tertiary
            button-text="Cancel"
            aria-label="Cancel"
            @click="isShowConfirmSubmittedByPaperModal = false"
          />
        </div>
      </template>
    </AppModal>
    <AppModal
      v-if="isShowConfirmWithdrawModal"
      ref="confirmWithdrawModal"
      data-testid="confirm-withdraw-modal"
    >
      <template slot="header">
        <div class="d-flex justify-space-between pt-3 pl-3 w-full">
          <span class="font-weight-bold">
            Mark enrolment application as 'Withdrawn'
          </span>
          <v-icon
            @click="isShowConfirmWithdrawModal = false"
            class="closeBtn"
            aria-label="close"
          >
            mdi-close
          </v-icon>
        </div>
      </template>
      <template slot="body">
        <div class="content mx-3">
          <p class="mb-3">
            {{ getValue('student.firstName') }}
            {{ getValue('student.familyName') }}'s enrolment application will be
            marked as <strong>'Withdrawn'.</strong>
          </p>
          <Alert
            in-page
            icon="mdi-alert"
            class="pt-3 modal-alerts in-page-alert--small"
            alert-class="alert--no-border"
            elevation="0"
            background-colour
            type="warning"
            role="none"
          >
            <template #optional>
              <p>
                Please ensure that {{ getValue('student.firstName') }}
                {{ getValue('student.familyName') }}'s student record has been
                updated in ERN before proceeding.
              </p>
              <p>This action cannot be undone.</p>
            </template>
          </Alert>
        </div>
      </template>
      <template #footer>
        <div class="mb-0 mr-3 pb-2">
          <AdsButton
            class="button red darken-1"
            button-text="Mark as withdrawn"
            aria-label="Mark as withdrawn"
            @click="handleWithdrawConfirm"
          />
          <AdsButton
            tertiary
            button-text="Cancel"
            aria-label="Cancel"
            @click="isShowConfirmWithdrawModal = false"
          />
        </div>
      </template>
    </AppModal>
  </div>
</template>

<script>
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import ReadOnlyFieldList from '@/components/application/ReadOnlyFieldList.vue'
import {
  STREAM,
  DATASET,
  SHS_STATUS,
  CONTACT_PHONE_TYPE,
  SHS_OFFER_STATUS,
  SCHOOL_SELECTIVENESS
} from '@/constants'
import { AdsExpansionPanel, Alert, AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'
import formatters from '@/helpers/formatters'
import AppModal from '@/components/app/AppModal.vue'

export default {
  name: 'ShsSummary',
  mixins: [fieldHelperMixin],
  components: {
    AdsExpansionPanel,
    ReadOnlyFieldList,
    Alert,
    AdsButton,
    AppModal
  },
  props: {
    schoolCode: {
      type: String
    }
  },
  data() {
    return {
      openPanels: 0,
      showChangeStatus: false,
      addressFormatter: () => {},
      isShowConfirmSubmittedByPaperModal: false,
      isShowConfirmWithdrawModal: false
    }
  },
  watch: {
    referenceData: {
      handler(v) {
        this.setAddressFormatter(v)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['currentStream', 'lookupCode', 'referenceData']),
    state() {
      return this.$store.state
    },
    cPanelsData() {
      const parentCarer = this.parentCarer || {}
      const [contact] = parentCarer?.contactDetails || [{}]

      const baseOfferData = [
        {
          name: 'Student details',
          id: 'StudentDetails',
          columns: 3,
          sections: [
            {
              invisibleFieldOnEmpty: false,
              fields: {
                'First name': this.getValue('student.firstName'),
                'Preferred name': this.getValue('student.prefFirstName'),
                'Family name': this.getValue('student.familyName'),
                Gender: formatters.gender(this.getValue('student.genderCode')),
                'Date of birth': formatters.displayDate(
                  this.getValue('student.dateOfBirth')
                ),
                'Residential address': this.addressFormatter({
                  ...this.getValue('residentialAddress')
                })
              }
            },
            {
              name: 'Residency status',
              invisibleFieldOnEmpty: false,
              fields: {
                'Student residency status':
                  this.application?.student?.residencyStatus,
                'Student visa class': this.application?.student?.visaClass,
                'Student visa sub-class':
                  this.application?.student?.visaSubClass,
                'Aboriginal or Torres Strait Islander origin': this.application
                  ?.student?.isAboriTorStraitIslander
                  ? 'Yes'
                  : 'No'
              }
            }
          ]
        },
        ...(parentCarer.parentCarerCompletingApp === true
          ? [
              {
                name: 'Parent/carer details',
                id: 'ParentCarerDetails',
                columns: 3,
                sections: [
                  {
                    invisibleFieldOnEmpty: false,
                    fields: {
                      Title: parentCarer?.parentCarerTitle,
                      'Given name': parentCarer.parentCarerGivenName,
                      'Family name': parentCarer.parentCarerFamilyName,
                      Relationship: this.lookupCode(
                        DATASET.RELATIONSHIP,
                        parentCarer.parentCarerRelation
                      ),
                      'Residential address': this.addressFormatter(
                        parentCarer.residentialAddress
                      )
                    }
                  },
                  {
                    name: 'Contact details',
                    invisibleFieldOnEmpty: false,
                    fields: {
                      'Contact email': this.parentCarer?.parentCarerEmail,
                      'Phone number': contact.contactValue,
                      'Phone number type':
                        CONTACT_PHONE_TYPE[contact.contactType] || ''
                    }
                  }
                ]
              }
            ]
          : [])
      ]
      return baseOfferData
    },
    isSelectedSchoolPartiallySelective() {
      return (
        this.$store.getters.selectedSchool?.selectiveInd ===
        SCHOOL_SELECTIVENESS.PARTIALLY
      )
    },
    cExpansionPanelItems() {
      return this.cPanelsData.map(({ name, id }) => ({
        id,
        title: name
      }))
    },
    parentCarer() {
      return this.getValue('parentCarers').find(
        (pc) => pc.parentCarerCompletingApp === true
      )
    },
    buttonActions() {
      const internalTransferItem = {
        title: 'Mark as internal transfer',
        icon: 'mdi-cached',
        click: () => {
          this.openInternalTransferModal()
        }
      }
      return [
        {
          title: 'Mark as submitted by paper form',
          icon: 'mdi-check',
          click: () => {
            this.isShowConfirmSubmittedByPaperModal = true
          }
        },
        ...(this.isSelectedSchoolPartiallySelective
          ? [internalTransferItem]
          : [])
      ]
    },
    isMoreActionsButtonDisabled() {
      return !(
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.ACCEPTED.toUpperCase() &&
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.ISSUED?.toUpperCase()
      )
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    },
    applicationHistory() {
      return this.application?.applicationHistory
    },
    isSubmittedAndAccepted() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.SUBMITTED.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.ACCEPTED.toUpperCase()
      )
    },
    isSubmittedAndDeclined() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.SUBMITTED.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    isSubmittedByPaperAndAccepted() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.SUBMITTED_BY_PAPER.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.ACCEPTED.toUpperCase()
      )
    },
    isSubmittedByPaperAndDeclined() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.SUBMITTED_BY_PAPER.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    isInternalTransferAndAccepted() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.INTERNAL_TRANSFER.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.ACCEPTED.toUpperCase()
      )
    },
    isInternalTransferAndDeclined() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.INTERNAL_TRANSFER.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    isWithdrawnAndDeclined() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.WITHDRAWN.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    isWithdrawnAndDeclinedInternalTransfer() {
      const lastUpdatedRecord = this.applicationHistory?.at(-2)
      if (!lastUpdatedRecord) {
        return false
      }
      return (
        lastUpdatedRecord.enrolmentStatus === SHS_STATUS.INTERNAL_TRANSFER &&
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.WITHDRAWN.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    isInvalidAndDeclined() {
      return (
        this.getValue('applicationStatus')?.toUpperCase() ===
          SHS_STATUS.INVALID.toUpperCase() &&
        this.getValue('offerStatus')?.toUpperCase() ===
          SHS_OFFER_STATUS.DECLINED.toUpperCase()
      )
    },
    renderReady() {
      return Boolean(this.addressFormatter)
    },
    alertsToDisplay() {
      return [
        // if application is issued status
        ...(this.getValue('applicationStatus')?.toUpperCase() ===
        SHS_STATUS.ISSUED.toUpperCase()
          ? [
              {
                type: 'info',
                text: 'The parent/carer has been issued instructions to enrol online',
                'data-testid': 'issuedAlert'
              }
            ]
          : []),
        // if application is issued status
        ...(this.getValue('applicationStatus')?.toUpperCase() ===
        SHS_STATUS.AUTO_RETRACTED.toUpperCase()
          ? [
              {
                icon: 'mdi-lock-outline',
                class: 'greyBorderAndIcon',
                text: "The parent/carer initially accepted your school's offer, but then declined.",
                subtext: 'Enrolment application auto retracted.',
                'data-testid': 'autoRetractedAlert'
              }
            ]
          : []),

        // if application is submitted by paper status
        ...(this.isSubmittedByPaperAndAccepted
          ? [
              {
                text: `${this.getValue('student.firstName')} ${this.getValue(
                  'student.familyName'
                )}'s parent/carer(s) have submitted a paper enrolment application`,
                type: 'success',
                'data-testid': 'submittedByPaperAcceptedAlert'
              }
            ]
          : []),
        // if application is Internal transfer status
        ...(this.isInternalTransferAndAccepted
          ? [
              {
                icon: 'mdi-lock-outline',
                class: 'greyBorderAndIcon',
                text: 'Selective enrolment application marked as internal transfer',
                subtext: `${this.getValue('student.firstName')} ${this.getValue(
                  'student.familyName'
                )}'s parent/carer(s) have submitted a separate mainstream enrolment application, which has been internally transferred to a selective high school enrolment in ERN.`,
                'data-testid': 'internalTransferAndAcceptedAlert'
              }
            ]
          : []),
        // if application is Internal transfer status
        ...(this.isWithdrawnAndDeclinedInternalTransfer
          ? [
              {
                icon: 'mdi-lock-outline',
                class: 'greyBorderAndIcon',
                text: `The parent/carer initially accepted your school's offer and submitted an application, but then declined.`,
                subtext: `Enrolment application recorded as manually withdrawn in ERN.`,
                'data-testid': 'withdrawnAndDeclinedAlert'
              }
            ]
          : [])
      ]
    }
  },
  methods: {
    setAddressFormatter(v) {
      this.addressFormatter = formatters.getAddressFormatter(
        v(DATASET.COUNTRIES),
        v(DATASET.STATES)
      )
    },
    handleSubmittedByPaperConfirm() {
      this.$store
        .dispatch('updateShsOfferEnrolmentStatus', {
          shsOffer: this.application,
          payload: {
            status: 'Submitted By Paper'
          }
        })
        .finally(() => {
          this.isShowConfirmSubmittedByPaperModal = false
        })
    },
    showWithdrawConfirmModal() {
      this.isShowConfirmWithdrawModal = true
    },
    handleWithdrawConfirm() {
      if (this.getValue('applicationID')) {
        const applicationStatusId = 4
        this.$store
          .dispatch('saveCoreApplicationStatus', {
            applicationStatusId
          })
          .then(this.updateWithdrawEnrolmentStatus)
          .catch(() => {
            this.isShowConfirmWithdrawModal = false
          })
      } else {
        this.updateWithdrawEnrolmentStatus()
      }
    },
    updateWithdrawEnrolmentStatus() {
      this.$store
        .dispatch('updateShsOfferEnrolmentStatus', {
          shsOffer: this.application,
          payload: {
            status: 'Withdrawn'
          }
        })
        .finally(() => {
          this.isShowConfirmWithdrawModal = false
        })
    },
    async openInternalTransferModal() {
      await this.$store.dispatch('set', [
        'actionFromStream',
        STREAM.SELECTIVE_OFFERS
      ])
      this.$store.dispatch('set', ['openInternalTransfer', true])
    }
  }
}
</script>
<style lang="scss" scoped>
.w-full {
  width: 100%;
}
::v-deep .alert--element:not(.in-page-alert--small) .v-alert__wrapper {
  margin: 5px 18px;
}
::v-deep .alert--element .v-alert.alert--no-border .v-alert__border {
  display: none;
}
::v-deep .alert--element.in-page-alert--small {
  .v-alert {
    margin-bottom: 0;
  }
  .v-alert__icon.v-icon {
    font-size: 22px;
    height: 22px;
    min-width: 22px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
}
.greyBorderAndIcon {
  ::v-deep .v-alert__border {
    background-color: $color-placeholder !important;
    border-color: $color-placeholder !important;
  }
  ::v-deep .v-icon {
    color: $color-placeholder !important;
  }
  ::v-deep .alert--textsimple {
    padding-top: 16px !important;
  }
}
.theme--light.v-icon.v-icon--link:focus {
  border: none !important;
  outline: 3px solid currentColor;
}
::v-deep .modal-alerts {
  .alertElement:focus {
    border: none !important;
    outline: 3px solid currentColor;
  }
}
</style>
