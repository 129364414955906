// Hook into the v-form validator
// https://github.com/vuetifyjs/vuetify/issues/3464

// Pass me the name of the property to validate
// I'll run all the rules you bind to the component
const validatableMixin = (objectName, sectionLabel) => {
  return {
    props: {
      rules: {
        type: Array,
        default: () => []
      }
    },
    watch: {
      [objectName]: {
        deep: true,
        handler(v) {
          this.validate()
          this.$emit(`update:${objectName}`, v)
        }
      }
    },
    data() {
      return {
        errorMessage: null,
        shouldValidate: true,
        errorBucket: [],
        sectionLabel
      }
    },
    computed: {
      hasError() {
        return this.errorMessage !== null
      }
    },
    methods: {
      validate() {
        this.errorMessage = null
        const value = this[objectName]
        for (let rule of this.rules) {
          const error = rule(value)
          if (error !== true) {
            this.errorMessage = error
            this.errorBucket = [error]
            break
          }
        }
      }
    },
    // register component with vuetify form
    inject: {
      form: { default: null }
    },
    created() {
      this.form && this.form.register(this)
    },
    beforeDestroy() {
      this.form && this.form.unregister(this)
    }
  }
}

export default validatableMixin
