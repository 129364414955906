<template>
  <v-dialog max-width="787" v-bind="$attrs" v-on="$listeners" persistent>
    <v-card class="modal">
      <v-btn class="close-button" icon aria-label="close" @click="close">
        <v-icon> close </v-icon>
      </v-btn>
      <div class="content">
        <div class="header d-flex align-center">
          <h1 class="heading ml-4" data-testid="heading">
            Applications by parent/carer {{ applicationOwnerName }}
          </h1>
        </div>
        <p class="body">
          <v-data-table
            class="OOA"
            :items="applicationsByApplicationOwner"
            :headers="headers"
            :loading="!applicationsByApplicationOwner.length"
            hide-default-footer
          >
            <template
              v-for="headerData in headers"
              :slot="`header.${headerData.value}`"
            >
              <button class="no-styling black--text" :key="headerData.value">
                {{ headerData.text }}
              </button>
            </template>

            <template slot="item" slot-scope="props">
              <!-- Table template for "out of area application owner's applications" -->
              <tr
                @click="openApplication(props.item)"
                class="grey--text text--lighten-1"
                data-testid="table-item"
              >
                <td class="text-xs-right">
                  {{ props.item.dateReceived | moment('DD MMM YYYY') }}
                </td>

                <td class="text-xs-right font-weight-bold">
                  <button
                    :aria-label="`Open application details for ${props.item.studentName}`"
                    @click.stop="openApplication(props.item)"
                    class="no-styling font-weight-bold text-decoration-underline"
                    data-testid="btn-student-name"
                  >
                    {{ props.item.studentName }}
                  </button>
                </td>
                <td class="text-sm-center text-md-left">
                  <v-avatar
                    class="primary lighten-2 white--text headline mr-2 font-weight-bold"
                    size="29"
                    >{{ props.item.scholasticYear }}
                  </v-avatar>
                  <span class="d-none d-lg-inline">
                    {{ props.item.scholasticYearText }}
                  </span>
                </td>
                <td class="text-xs-right">
                  <OoaStatusChip :application-status="props.item.status" />
                </td>
              </tr>
            </template>
          </v-data-table>
        </p>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import OoaStatusChip from '@/components/application/ooa/OoaStatusChip.vue'
import allScholasticYears from '@/store/allScholasticYears'

export default {
  name: 'MultipleApplicationsModal',
  components: { OoaStatusChip },
  data() {
    return {
      headers: [
        {
          text: 'Received',
          value: 'dateReceived',
          class: 'date-received',
          align: 'start'
        },
        {
          text: 'Student name',
          value: 'studentName',
          align: 'start'
        },
        {
          text: 'Scholastic year',
          value: 'scholasticYear',
          align: 'start'
        },
        {
          text: 'Status',
          value: 'status',
          align: 'start'
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['ooaApplicationOwner', 'ooaApplicationOwnerApplications']),
    applicationOwnerName() {
      return (
        this.ooaApplicationOwner &&
        `${this.ooaApplicationOwner.parentCarerGivenName} ${this.ooaApplicationOwner.parentCarerFamilyName} `
      )
    },
    applicationsByApplicationOwner() {
      return this.ooaApplicationOwnerApplications.map((a) => {
        return {
          applicationID: a.applicationID,
          schoolCode: a.schoolCode,
          dateReceived: a.dateReceived,
          studentName: `${a.application.student.familyName}, ${a.application.student.firstName}`,
          scholasticYearText: this.scholasticYearText(
            a.application.scholasticYear
          ),
          scholasticYear: a.application.scholasticYear,
          status: a.application.applicationStatus
        }
      })
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    openApplication(item) {
      this.close()
      this.$router.push(`/application/${item.schoolCode}/${item.applicationID}`)
    },
    scholasticYearText(num) {
      return allScholasticYears.find((y) => y.value === num).text
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  padding: 1.6rem 0 1.6rem 0;
}

.content {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 1.333333rem;
  color: $color-primary;
  padding-left: 1rem;
}

.body {
  margin: 1.5rem 0;
  line-height: 1.5;
}

.close-button {
  position: absolute;
  top: 0.15rem;
  right: 0.15rem;
  border: none;
}

.highlight {
  color: $color-training;
}

.actions {
  button.v-btn {
    padding: 0.9rem;
  }
}

.cancel-btn {
  border: none;
  font-weight: bold;

  &:hover::before {
    background-color: transparent;
  }

  &:focus {
    outline: 1px solid currentColor;
  }
}

::v-deep div.v-data-table__wrapper table {
  .v-data-table-header tr th button {
    font-size: 1rem;
    font-weight: normal;
  }
  tbody {
    tr td {
      padding: 25px 8px 25px 1rem;
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
    }
  }
}
</style>
