<!--
  Provides a help menu triggered by the question mark icon
-->
<template>
  <v-menu
    content-class="help-menu"
    offset-y
    left
    nudge-bottom="2"
    nudge-right="4"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        dark
        class="help-icon mr-3 circular-btn"
        aria-label="Help"
      >
        <v-icon medium>help_outline</v-icon>
      </v-btn>
    </template>
    <v-card class="mx-auto" max-width="240" width="205">
      <!-- List items loop -->
      <v-list :nav="true">
        <v-list-item
          v-for="(link, i) in links"
          :key="i"
          :href="link.href"
          :target="link.target"
          class="body-1 my-0 py-0"
        >
          <v-list-item-icon class="mr-1 my-3">
            <!--
            FUS-1813: Changes to eslint rules now disallow the use of
            v-text and v-html on components. The error for the next
            line has been disabled as it conforms to the standards set
            by Vuetify 2.
            @see https://v2.vuetifyjs.com/en/components/lists/#dense
            -->
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <v-icon v-text="link.icon" left></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <!--
            FUS-1813: Changes to eslint rules now disallow the use of
            v-text and v-html on components. The error for the next
            line has been disabled as it conforms to the standards set
            by Vuetify 2.
            @see https://v2.vuetifyjs.com/en/components/lists/#dense
            -->
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <v-list-item-title v-text="link.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AppHelpMenu',
  computed: {
    links() {
      return [
        {
          icon: 'help_outline',
          text: 'Help & FAQs',
          href: this.$store.getters.helpLink,
          target: '_blank'
        },
        {
          icon: 'mdi-bug-outline',
          text: 'Report an issue',
          // Will be saved in the oes-properties rather than hardcoded
          href: 'https://nswdoeesmp1.service-now.com/services_gateway?sys_id=d2e6f2a297d4f110dfae7846f053afc4&si=Online%20Enrolment%20System&id=sg_item',
          target: '_blank'
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.v-btn.help-icon {
  padding: 7.5px;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.help-menu {
  .v-icon,
  .v-list-item__title {
    color: $color-primary;
  }
  .v-list-item__title {
    font-size: 1.1rem;
    letter-spacing: 0;
  }
  .v-list-item {
    &:focus {
      outline: none;
    }
  }
}
</style>
