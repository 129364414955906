<!--
  Provides the template for the yellow/green bar indicating SRN link status. If linked,
  the bar will turn green and the SRN will be displayed. If sent to HS, the bar will
  change to reflect this.
-->
<template>
  <div class="mt-5 ml-8 mr-8">
    <div aria-live="polite" class="sr-only">
      <p v-if="isWithdrawn"></p>
      <p v-else-if="isNew"></p>
      <p v-else-if="isInProgress">Application moved to 'In Progress'</p>
      <p v-else-if="pnrNoLocalHighSchool"></p>
      <p v-else></p>
    </div>
    <div class="SrnMatchStatusBar">
      <Alert
        v-if="isTemporaryResident"
        in-page
        type="warning"
        :text="`${studentName} is listed as a temporary resident in ERN`"
        background-colour
        class="y67t-alerts"
        data-testid="temporary-resident-alert"
        role="none"
      />
      <Alert
        v-if="isWithdrawn"
        type="info"
        text="Application Withdrawn"
        subtext="This application has been withdrawn. No further action can be taken at this time."
        in-page
        icon="mdi-lock-outline"
        class="y67t-alerts withdrawn-alert"
        data-testid="withdrawn-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isNew"
        type="warning"
        text="SRN has not been matched"
        :subtext="`You need to check if ${application.student.firstName} ${application.student.familyName} has an existing SRN record.`"
        in-page
        :action-text="isEditing ? '' : 'CHECK'"
        :action-handler="check"
        class="y67t-alerts new-alert"
        data-testid="new-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isReadyToSendErn && otherEois.length === 0 && !isSubmitted"
        type="warning"
        :text="`Linked SRN: ${application.student.SRN}`"
        subtext="Ready to send to HS."
        in-page
        class="y67t-alerts inprogress-alert"
        data-testid="inprogress-status-bar"
        icon="mdi-send-outline"
        role="none"
      />
      <Alert
        v-else-if="isAddressConflict && otherEois.length === 0 && !isSubmitted"
        type="warning"
        :text="`Linked SRN: ${application.student.SRN}`"
        in-page
        class="y67t-alerts inprogress-alert"
        data-testid="inprogress-status-bar"
        icon="mdi-send-outline"
        role="none"
      />
      <Alert
        v-else-if="otherEois.length > 0 && !isSubmitted && isPrimaryStream"
        type="warning"
        :text="`Linked SRN: ${application.student.SRN}`"
        in-page
        class="y67t-alerts inprogress-alert"
        data-testid="inprogress-status-bar"
        icon="mdi-alert"
        role="none"
      >
        <template slot="optional">
          <DuplicateEoiList :other-eois="otherEois" />
        </template>
      </Alert>
      <Alert
        v-else-if="pnrNoLocalHighSchool"
        type="success"
        :text="`Linked SRN: ${application.student.SRN}`"
        :subtext="`The current address(ERN) has no designated high school and need not be sent to HS.`"
        in-page
        icon="link"
        class="y67t-alerts pnr-alert"
        data-testid="pnr-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isSent"
        type="success"
        text="Sent to HS"
        :subtext="`This EOI was sent to HS tracking sheet by ${
          application.updatedBy
        } on ${$moment(application.lastUpdatedDateTime).format('LLLL')}`"
        in-page
        icon="send"
        class="y67t-alerts senttoern-alert"
        data-testid="senttoern-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isOffered"
        type="info"
        text="This application is currently under offer"
        action-text="CHANGE STATUS"
        :action-handler="changeStatusClick"
        in-page
        icon="mdi-send-outline"
        class="y67t-alerts offered-alert"
        data-testid="offered-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isDeclined"
        type="error"
        text="This application has been declined"
        subtext="No further action can be taken at this time."
        in-page
        icon="mdi-lock-outline"
        class="y67t-alerts declined-alert"
        data-testid="declined-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isNotAccepted"
        type="info"
        text="The parent/carer has chosen not to accept this offer"
        subtext="No further action can be taken at this time."
        in-page
        icon="mdi-lock-outline"
        class="y67t-alerts notaccepted-alert"
        data-testid="notaccepted-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isExpired"
        type="info"
        text="The offer has expired"
        subtext="No further action can be taken at this time."
        in-page
        icon="mdi-lock-outline"
        class="y67t-alerts isexpired-alert"
        data-testid="isExpired-status-bar"
        role="none"
      />
      <Alert
        v-else-if="isSubmitted"
        type="success"
        :text="`This application has been accepted and full application has been submitted`"
        :subtext="``"
        in-page
        icon="send"
        class="y67t-alerts submitted-alert"
        data-testid="submitted-status-bar"
        role="none"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import DuplicateEoiList from './DuplicateEoiList.vue'
import { Y67T_STATUS, STREAM } from '@/constants'
import { Alert } from '@nswdoe/doe-ui-core'

export default {
  name: 'SrnMatchStatusBar',
  mixins: [fieldHelperMixin],
  components: { DuplicateEoiList, Alert },
  data() {
    return {
      showChangeStatus: false
    }
  },
  props: {
    focusButton: {
      type: String
    }
  },
  computed: {
    ...mapGetters([
      'isAddressConflict',
      'selectedSchool',
      'readyToSendErn',
      'isEditing',
      'currentStream'
    ]),
    application() {
      return this.$store.state.application
    },
    isPrimaryStream() {
      return this.currentStream === STREAM.Y67T_PRIMARY
    },
    otherEois() {
      return this.application?.otherEOIs || []
    },
    appStatus() {
      return this.application.applicationStatus
    },
    isNew() {
      return this.appStatus === Y67T_STATUS.NEW
    },
    isInProgress() {
      return this.appStatus === Y67T_STATUS.IN_PROGRESS
    },
    isWithdrawn() {
      return this.appStatus === Y67T_STATUS.WITHDRAWN
    },
    isSent() {
      return this.appStatus === Y67T_STATUS.SENT
    },
    isOffered() {
      return this.appStatus === Y67T_STATUS.OFFERED
    },
    isDeclined() {
      return this.appStatus === Y67T_STATUS.DECLINED
    },
    isNotAccepted() {
      return this.appStatus === Y67T_STATUS.NOT_ACCEPTED
    },
    isExpired() {
      return this.appStatus === Y67T_STATUS.OFFER_EXPIRED
    },
    isSubmitted() {
      return this.appStatus === Y67T_STATUS.SUBMITTED
    },
    pnrNoLocalHighSchool() {
      return (
        this.appStatus === Y67T_STATUS.NOT_REQUIRED &&
        this.application.localHighSchool == null
      )
    },
    isLinked() {
      return this.application.student && this.application.student.SRN
    },
    studentName() {
      const names = [
        this.getValue('student.firstName'),
        this.getValue('student.familyName')
      ]
      return this.formatNames(names)
    },
    isReadyToSendErn() {
      return (
        this.application.student.SRN &&
        this.readyToSendErn &&
        !this.isAddressConflict &&
        !this.pnrNoLocalHighSchool
      )
    },
    isTemporaryResident() {
      const flagTags = this.application?.flags || []
      const tmpflagTag = flagTags.filter((data) => data?.type === 'TMP')
      return tmpflagTag.length > 0
    }
  },
  methods: {
    formatNames(names) {
      return names
        .map((n) => (n ? n.trim() : ''))
        .filter((n) => n)
        .join(' ')
    },
    check() {
      this.$store.commit('setShowSrnMatchSearch', true)
    },
    changeStatusClick() {
      this.$emit('setStatus')
    }
  },
  watch: {
    focusButton() {
      requestAnimationFrame(() => {
        document.getElementsByClassName('alert--clickable-action')[0]?.focus()
        this.$emit('focusReset')
      })
    }
  }
}
</script>

<style scoped lang="scss">
.SrnMatchStatusBar {
  padding: 1rem 1rem 0 1rem;
  &.disabled {
    opacity: 0.5;
  }

  .icon {
    margin-right: 0.6em;
    width: 1.75em;
    height: 1.75em;
  }

  .iconSearch {
    background-color: #dc5800;
  }

  .iconLinked {
    background-color: $color-success;
  }

  .iconSent {
    background-color: $color-success;
  }
  .check {
    margin-left: auto;
  }
  .no-style {
    text-decoration: underline;
    border: none;
    &:before {
      background-color: transparent !important;
    }
  }
  .v-btn {
    font-weight: 700;
  }
  .edit-srn {
    text-decoration: underline;
    border: 0;
  }
  .theme--light.v-btn.v-btn--disabled.edit-srn {
    background-color: white;
    opacity: 1;
  }
}
::v-deep .y67t-alerts {
  .alertElement:focus {
    border: none !important;
    outline: 3px solid currentColor;
  }
}
::v-deep .withdrawn-alert {
  .alertElement {
    .v-alert__icon {
      color: $color-dark70 !important;
    }
    .v-alert__border {
      border-color: $color-dark70 !important;
    }
  }
}
::v-deep .notaccepted-alert {
  .alertElement {
    .v-alert__icon {
      color: $color-dark70 !important;
    }
    .v-alert__border {
      border-color: $color-dark70 !important;
    }
  }
}
::v-deep .alert--clickable-action:focus {
  outline: 2px solid $ads-navy;
  border-radius: 3px;
}
::v-deep .isexpired-alert {
  .alertElement {
    .v-alert__icon {
      color: $color-dark70 !important;
    }
    .v-alert__border {
      border-color: $color-dark70 !important;
    }
  }
}
::v-deep .alert--textsimple {
  margin-top: 5px;
}
</style>
