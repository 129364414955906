<template>
  <v-dialog
    max-width="630"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
    :key="dialogKey"
  >
    <v-card class="setStatusModal">
      <v-btn
        class="modal__close-button float-right"
        :ripple="false"
        icon
        aria-label="close"
        @click="close"
        @keydown.esc="close"
        ref="closeBtn"
      >
        <v-icon> close </v-icon>
      </v-btn>
      <div class="header">
        <h1 role="alert" class="heading" data-testid="heading">
          <span v-if="isOffered"
            >Make an offer of enrolment to {{ this.studentName }}?</span
          >
          <div v-if="isWithdrawn || isDeclined">
            <span class="withdraw-decline-heading">{{
              this.status.slice(0, -1)
            }}</span>
            application for {{ this.studentName }}?
          </div>
          <div v-if="isChange">
            Change the status of this application for {{ this.studentName }}?
          </div>
        </h1>
      </div>
      <AdsSelect
        v-if="isChange"
        data-testid="selectOptions"
        v-model="selected"
        :items="items"
        :menu-props="{ bottom: true, offsetY: true }"
        class="mt-10"
        outlined
        :rules="[(val) => val != null || 'Required.']"
        label="Change status to"
        placeholder="Please select..."
        persistent-placeholder
      />
      <div v-if="notChange || selected !== ''">
        <p class="my-5" v-if="!isChange">
          The status of the application will be set to
          <strong>&#8220;{{ this.status }}&#8221;</strong>.
        </p>
        <Alert
          v-if="overCap && isOffered && outOfAreaSchool"
          data-testid="overCapAlert"
          in-page
          type="warning"
          alert-class="offer-note"
          html-text="<span class='subtext'><b>Note:</b> DEL approval is required before offering a place as your </br> school is currently over cap.</span>"
        />
        <div class="email-template-container" data-testid="emailContainer">
          <p v-if="notChange">
            <span v-if="isWithdrawn"
              >Please ensure you have spoken with the parent/carer(s) before
              withdrawing.</span
            >
            Parent/carer <strong>{{ this.parentName }}</strong> will be emailed
            with<span v-if="isOffered">
              instructions for accepting the offer and proceeding with a full
              enrolment application.</span
            >
            <span v-if="isWithdrawn || isDeclined">
              a confirmation that their application has been
              {{ this.status.toLowerCase() }}.</span
            >
          </p>
          <p v-if="isChange">
            The parent/carer of <strong>{{ this.studentName }}</strong> will be
            emailed with a confirmation that their
            <span v-if="selected.includes('Not Accepted')"
              >application has not been accepted.</span
            >
            <span v-if="selected.includes('Offer Expired')">
              offer has been expired. Changing this offer should only be done
              consistently with your school's processes and with the
              authorisation of the principal.
            </span>
          </p>
          <div>
            <a :href="this.y67tEmailLink" target="_blank"
              >View email template</a
            >
          </div>
        </div>
        <v-divider class="divider" />
        <AdsTextArea
          v-model="staffComment"
          class="comment-field"
          label="Comments (school use only - optional)"
          placeholder="You can write the reason for your decision here. This information will not be shared with the parent/carer."
          rows="5"
          maxlength="200"
          counter="200"
          persistent-placeholder
        />
        <SupportingDocumentUploadY67T
          v-model="files"
          @dateStamp="dateStamp"
          ref="supportingDocumentUploady67t"
          :is-busy.sync="isFileOperationInProgress"
          :hs-tracking-action="true"
        />
      </div>
      <div class="actions">
        <AdsButton
          :class="buttonStyle.color"
          :button-text="buttonStyle.text"
          @click="setAppStatus"
          :disabled="isValid || isFileOperationInProgress"
          data-testid="action-btn"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, AdsTextArea, AdsSelect, Alert } from '@nswdoe/doe-ui-core'
import SupportingDocumentUploadY67T from '@/components/application/y67t/SupportingDocumentUploadY67T'
import { Y67T_STATUS } from '@/constants'
import { mapGetters } from 'vuex'
import UTILS from '@/store/utils'
export default {
  name: 'SetStatusModalY67T',
  components: {
    AdsButton,
    AdsTextArea,
    AdsSelect,
    SupportingDocumentUploadY67T,
    Alert
  },
  props: {
    studentName: {
      type: String
    },
    parentName: {
      type: String
    },
    status: {
      type: String
    },
    isChange: {
      type: Boolean
    }
  },
  data() {
    return {
      staffComment: '',
      selected: '',
      formatChange: '',
      files: [],
      isFileOperationInProgress: false,
      date: '',
      dialogKey: ''
    }
  },
  computed: {
    ...mapGetters([
      'y67tEmailLink',
      'application',
      'selectedSchool',
      'overCap'
    ]),
    isValid() {
      return this.isChange && this.selected === ''
    },
    application() {
      return this.$store.getters.application || {}
    },
    isOffered() {
      return this.status === Y67T_STATUS.OFFERED
    },
    isWithdrawn() {
      return this.status === Y67T_STATUS.WITHDRAWN
    },
    isDeclined() {
      return this.status === Y67T_STATUS.DECLINED
    },
    notChange() {
      return (
        this.status === Y67T_STATUS.DECLINED ||
        this.status === Y67T_STATUS.WITHDRAWN ||
        this.status === Y67T_STATUS.OFFERED
      )
    },
    dynamicStatus() {
      return this.status ? this.status : this.formatChange
    },
    outOfAreaSchool() {
      return (
        this.selectedSchool.schoolName !==
        this.application.localHighSchool.schoolName
      )
    },
    icon() {
      return {
        name: this.isOffered
          ? 'mdi-check-circle'
          : this.isDeclined
          ? 'mdi-cancel'
          : this.isWithdrawn || this.isChange
          ? 'mdi-delete-outline'
          : '',
        color: this.isOffered
          ? 'success'
          : this.isDeclined
          ? 'error'
          : this.isWithdrawn || this.isChange
          ? 'dark70'
          : ''
      }
    },
    buttonStyle() {
      return {
        color: this.isOffered
          ? 'button success'
          : this.isDeclined || this.isWithdrawn
          ? 'button red'
          : this.isChange
          ? 'button'
          : '',
        text: this.isOffered
          ? 'Make offer of enrolment'
          : this.isDeclined
          ? 'Confirm decline of enrolment'
          : this.isWithdrawn
          ? 'Confirm application withdrawal'
          : this.isChange
          ? 'Confirm status change'
          : ''
      }
    },
    items() {
      if (
        this.application.localHighSchool.schoolCode ===
        this.selectedSchool.schoolCode
      ) {
        return [`${Y67T_STATUS.NOT_ACCEPTED} (by parent)`]
      } else {
        return [
          `${Y67T_STATUS.NOT_ACCEPTED} (by parent)`,
          `${Y67T_STATUS.OFFER_EXPIRED} (by school)`
        ]
      }
    }
  },
  methods: {
    dateStamp(value) {
      this.date = value
    },
    setAppStatus() {
      this.$emit('input', false)
      this.$emit('focus', this.status ? this.status : 'changeStatus')
      const supportingDocuments = this.files.map((file) => {
        return this.date + '/' + file.name
      })
      this.$store
        .dispatch('setStatusY67TApplication', {
          status: this.dynamicStatus,
          comment: UTILS.sanitize(this.staffComment),
          supportingDocuments: supportingDocuments
        })
        .then((response) => {
          this.$router.push({ path: '/' })
          const studentName = `${this.application.student.firstName} ${this.application.student.familyName}`
          let message
          if (response.data.body.isEmailSentForHSAction) {
            message = `<strong>${studentName}'s</strong> application is now set to <strong>&#8220;${this.dynamicStatus}&#8221;</strong>. Email sent to parent.`
          } else {
            message = `<strong>${studentName}'s</strong> application is now set to <strong>&#8220;${this.dynamicStatus}&#8221;</strong>.`
          }
          this.$store.dispatch('getNumbersTracker')
          this.$store.dispatch('showSnackbar', {
            display: true,
            html: message,
            icon: this.icon
          })
        })
    },
    close() {
      this.selected = ''
      this.formatChange = ''
      this.staffComment = ''
      if (this.files) {
        this.files = []
        this.$refs.supportingDocumentUploady67t?.deleteAll()
      }
      for (
        let i = this.$refs.supportingDocumentUploady67t?._data.alerts?.length;
        i >= 0;
        i--
      ) {
        this.$refs?.supportingDocumentUploady67t?.closeError(i)
      }
      this.$emit('input', false)
      this.$emit('focus', this.status)
      // force rerender of dialog so comment box is reset
      this.dialogKey = Math.random()
    }
  },
  watch: {
    selected() {
      this.formatChange = this.selected.split(' ').slice(0, -2).join(' ').trim()
    },
    focusButton() {
      requestAnimationFrame(() => {
        this.$refs[this.focusButton]?.$el.focus()
        this.$emit('focusReset')
      })
    }
  },
  mounted() {
    this.$store.dispatch('getOverCap', this.selectedSchool?.schoolCode)
  }
}
</script>

<style scoped lang="scss">
.heading {
  font-size: 20px;
  padding: 10px 35px 0 0;
  color: $ads-navy;
  line-height: 1.4 !important;
}
.email-template-container {
  background-color: $ads-light-10;
  line-height: 1.5;
  padding: 16px;
  border-radius: 4px;
  a {
    text-decoration: underline;
    color: $ads-dark;
    font-weight: bold;
  }
  a:focus {
    outline: $ads-dark 2px solid;
    border-radius: 2px;
  }
}
.divider {
  margin: 28px 0px;
  color: $ads-light-40;
  border-width: 1px;
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .theme--light.v-btn {
    padding: 20px 40px !important;
  }
}
.comment-field.v-text-field.v-textarea {
  margin-left: 0px !important;
}

.setStatusModal {
  padding: 1.7rem;
  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: inherit;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $color-primary !important;
    }
  }
  .withdraw-decline-heading {
    color: $ads-red;
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
    .v-alert__icon.v-icon {
      margin-top: 7px;
      font-size: 30px;
    }
  }
}
</style>
