<!-- this component was copied and modified from Y67T -->
<template>
  <div class="oes-address-finder">
    <div :id="menuAnchorID" class="menu-anchor" />
    <div id="suggestionsText" class="sr-only">
      Suggestions will appear below as you type more than three characters into
      the field.
    </div>
    <!-- Screen reader message to announce no of options available after the address search. -->
    <div class="sr-only" aria-live="assertive" role="alert">
      {{ noOfSuggestionsMsg }}
    </div>
    <v-combobox
      ref="combobox"
      v-model="selectedAddress"
      :items="optionsList"
      :item-text="itemText"
      :search-input.sync="searchInput"
      aria-describedby="suggestionsText"
      return-object
      v-bind="attrs"
      v-on="$listeners"
      @input="handleSuggestionSelection"
      persistent-placeholder
    >
      <!-- Passes all slots/scopedSlots passed in to this component down into v-combobox -->
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
      <template v-for="(_, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </v-combobox>
  </div>
</template>
<script>
import _uniqBy from 'lodash/uniqBy'
import API from '@/store/apiUtils'

const EVENTS = {
  LOADING_SUGGESTIONS: 'loadingSuggestions',
  LOADING_SELECTION: 'loadingSelection',
  UPDATE_ADDRESS: 'update:address',
  UPDATE_OPTIONS: 'update:options'
}
const AUSTRALIAN_STATES = ['NSW', 'VIC', 'QLD', 'SA', 'WA', 'TAS', 'NT', 'ACT']
export default {
  name: 'OesAddressFinder',
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default: null
    },
    preFilledAddress: {
      type: String
    },
    suggestionsApiDebounce: {
      type: Number,
      default: 500
    },
    maxSuggestions: {
      type: Number,
      default: 10
    },
    includePoBoxes: {
      type: Boolean,
      default: false
    },
    minLengthForSearch: {
      type: Number,
      default: 4
    },
    noMatchingText: {
      type: String,
      default: 'No address found'
    },
    itemText: {
      type: String,
      default: 'address'
    }
  },
  data() {
    return {
      selectedAddress: null,
      optionsList: [],
      searchInput: ''
    }
  },
  computed: {
    attrs() {
      const defaultProps = {
        attach: `#${this.menuAnchorID}`,
        'append-icon': null,
        clearable: true,
        'hide-no-data': true,
        outlined: true,
        'return-object': true,
        'prepend-inner-icon': 'search',
        'no-filter': true,
        placeholder: ' '
      }
      return { ...defaultProps, ...this.$attrs }
    },
    menuAnchorID() {
      // eslint-disable-next-line no-underscore-dangle
      return `address-menu-anchor_${this._uid}`
    },
    noOfSuggestionsMsg() {
      const numOptions = (this.optionsList && this.optionsList.length) || null
      return Number.isInteger(numOptions)
        ? `There are ${numOptions} results to choose from`
        : ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (
          value &&
          typeof value === 'object' &&
          value.id !== (this.selectedAddress && this.selectedAddress.id)
        ) {
          const addressParts = [
            value.addressLine1,
            value.suburbName,
            value.stateCode,
            value.postCode
          ].filter((v) => v)

          this.selectedAddress = {
            id: value.addressId,
            [this.itemText]: addressParts.join(', ')
          }
        }
      }
    },
    searchInput(val) {
      if (!val) {
        this.optionsList = []
        return
      }
      const selectAddress = this.selectedAddress
        ? this.selectedAddress.address
        : null
      // Remove multiple instances of whitespace, but preserve spaces in the address sent to the API
      const sanitisedVal = this.sanitise(val)
      if (
        sanitisedVal.trim().length >= this.minLengthForSearch &&
        val !== selectAddress
      ) {
        this.debounce(
          () => this.handleSuggestions(sanitisedVal),
          this.suggestionsApiDebounce
        )
      }
    }
  },
  methods: {
    capitalise(string) {
      return string
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    },
    sanitise(val) {
      return val.replace(/^\s+/, '').replace(/\s+/g, ' ')
    },
    clearInput() {
      this.selectedAddress = null
      this.optionsList = []
      this.searchInput = null
    },
    debounce(callback, duration) {
      clearTimeout(this.debounceTimerID)
      this.debounceTimerID = setTimeout(() => {
        callback()
      }, duration)
    },
    async getSuggestions(address) {
      this.$emit(EVENTS.LOADING_SUGGESTIONS, true)
      try {
        let addressText = this.sanitise(address)
        return await this.getAddressSuggestions(addressText)
      } catch (e) {
        this.$emit(EVENTS.LOADING_SUGGESTIONS, false, true)
        return false
      } finally {
        this.$emit(EVENTS.LOADING_SUGGESTIONS, false)
      }
    },
    populateSuggestionsList(suggestions) {
      const uniqueSuggestions = _uniqBy(
        suggestions,
        (suggestion) => suggestion.address
      )
      if (Array.isArray(uniqueSuggestions) && uniqueSuggestions.length !== 0) {
        this.optionsList = uniqueSuggestions
      } else if (this.noMatchingText) {
        this.optionsList = [
          { [this.itemText]: this.noMatchingText, disabled: true }
        ]
      }
      this.$emit(EVENTS.UPDATE_OPTIONS, this.optionsList)
    },
    async handleSuggestions(searchInput) {
      // eslint-disable-next-line max-len
      const suggestions = await this.getSuggestions(searchInput)
      this.populateSuggestionsList(suggestions)
    },
    async handleSuggestionSelection(suggestion) {
      let addressObject
      if (suggestion && typeof suggestion === 'object') {
        // this.selectedAddress = suggestion
        addressObject = await this.getFullAddressInfo(suggestion.id)
        addressObject.validationFlag = 'V'
      }
      this.$emit(EVENTS.UPDATE_ADDRESS, addressObject)
      this.$emit('input', addressObject)
    },
    getAddressSuggestions(searchText) {
      const params = {
        address: searchText,
        maxNumberOfResults: this.maxSuggestions,
        addressType: this.includePoBoxes ? null : 'physical'
      }
      return API.get(
        `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive1`,
        true,
        {
          'x-preflight': 'force',
          noDefaultHeader: true
        },
        params,
        true
      ).then((response) => {
        return Array.isArray(response.data) ? response.data : []
      })
    },
    async getFullAddressInfo(searchAddressId) {
      this.$emit(EVENTS.LOADING_SELECTION, true)
      try {
        const apiResponse = await API.post(
          `${process.env.VUE_APP_API_PI}/nswpoint/v2/api/predictive2`,
          { id: searchAddressId },
          false,
          { 'x-preflight': 'force', noDefaultHeader: true }
        )

        const { addressDetails, addressId, geo } = apiResponse.data.data

        const isAustralianState = AUSTRALIAN_STATES.includes(
          addressDetails.stateTerritory
        )
        return {
          addressLine1: this.capitalise(
            addressDetails.formattedAddress.split(',')[0]
          ),
          suburbName: this.capitalise(addressDetails.localityName),
          postCode: addressDetails.postcode,
          stateCode: isAustralianState ? addressDetails.stateTerritory : 'XXX',
          countryCode: isAustralianState ? 'AUS' : 'XXX',
          addressId,
          longitude: geo.geometry.coordinates[0],
          latitude: geo.geometry.coordinates[1]
        }
      } finally {
        this.$emit(EVENTS.LOADING_SELECTION, false)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.menu-anchor {
  position: relative;
}
</style>
