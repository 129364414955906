<template>
  <div class="px-8 pt-4 pb-2">
    <v-row v-if="flags.length > 0">
      <!--
      FUS-1813: Changes to eslint rules now disallow the use of
      v-if with v-for on the same element. The error for the next
      block has been disabled as the application and components
      are currently working.
      -->
      <!-- eslint-disable vue/no-use-v-if-with-v-for -->
      <v-col
        v-if="getDescription(flag.type)"
        v-for="(flag, index) in flags"
        :key="index"
        cols="12"
        md="4"
      >
        <!-- eslint-enable vue/no-use-v-if-with-v-for -->
        <v-row class="center">
          <v-img
            :alt="`${flag.description}`"
            class="round"
            :src="getImage(flag.type.toLowerCase())"
          />
          {{ getDescription(flag.type.toUpperCase()) }}
        </v-row>
      </v-col>
    </v-row>
    <no-details v-else detail-name="flags and tags" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Y67T_FLAGS_TAGS } from '@/constants'
import NoDetails from '@/components/application/NoDetails'
export default {
  name: 'Y67tFormFlags',
  components: {
    NoDetails
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['application', 'selectedSchool']),
    siblingsTag() {
      return {
        type: 'SIB',
        description: `Sibling at ${this.abbreviateSchoolName(
          this.selectedSchool.schoolName
        )}`
      }
    },
    flags() {
      const flagsTags = []
      if (this.hasAnySiblings(this.application?.ooaHighSchools)) {
        flagsTags.push(this.siblingsTag)
      }
      if (this.application?.flags) {
        flagsTags.push(...this.application.flags)
      }
      if (
        !!flagsTags.find((ft) => ft.type === 'TMP') &&
        !!flagsTags.find((ft) => ft.type === 'NA')
      ) {
        return flagsTags.filter((ft) => ft.type !== 'NA')
      }
      return flagsTags
    }
  },
  methods: {
    getDescription(type) {
      if (Y67T_FLAGS_TAGS.flags[type]) {
        return Y67T_FLAGS_TAGS.flags[type]
      } else if (Y67T_FLAGS_TAGS.tags[type]) {
        if (type == 'SIB') {
          return this.siblingsTag.description
        }
        return Y67T_FLAGS_TAGS.tags[type]
      } else {
        return false
      }
    },
    getImage(type) {
      return require(`@/assets/flags/${type}.svg`)
    },
    hasAnySiblings(ooaHighSchools) {
      if (!ooaHighSchools) {
        return false
      }
      const selectedOoaHighSchool = ooaHighSchools?.filter(
        (ooaHighSchool) =>
          ooaHighSchool?.schoolCode === this.application.schoolCode &&
          ooaHighSchool?.hasAnySiblings &&
          ooaHighSchool?.siblings?.length > 0
      )
      return selectedOoaHighSchool && selectedOoaHighSchool.length > 0
    },
    abbreviateSchoolName(schoolName) {
      if (schoolName.includes('Secondary College')) {
        return schoolName.replace('Secondary College', 'SC')
      }
      if (schoolName.includes('High School')) {
        return schoolName.replace('High School', 'HS')
      }
      return schoolName || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.round {
  border-radius: 4px;
  margin-right: 5px;
  max-width: 35px;
}
</style>
