<template>
  <span class="no-results-found">
    {{ message }}
    <template v-if="hasArchiveLink">
      You can also
      <button class="no-results-found__cta" @click="handleClick">
        search the archive</button
      >.
    </template>
  </span>
</template>

<script>
import { STREAM, ARCHIVE_TYPES } from '@/constants'

export default {
  props: {
    archiveType: {
      type: String,
      default: ARCHIVE_TYPES.ALL.type
    },
    filtered: {
      type: Boolean,
      default: false
    },
    hasArchiveLink: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    message() {
      if (this.filtered) {
        return 'No results found. Try removing some filters or clearing the keyword search.'
      } else {
        return 'No results found.'
      }
    }
  },
  methods: {
    async handleClick() {
      await this.$store.dispatch('archiving/setType', this.archiveType)
      this.$store.commit('set', ['currentStream', STREAM.ARCHIVE])
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  span.no-results-found {
    color: $ads-grey-01;

    & span.no-results-found__content {
      line-height: 1.5;
    }

    & button.no-results-found__cta {
      color: $ads-primary-blue;
      text-decoration: underline;
      padding: 0;
      margin: 0;
      border: none;

      &:focus-visible {
        outline: 3px solid $ads-primary-blue-dark;
      }
    }
  }
}
</style>
