<template>
  <div
    id="app-notifications"
    v-if="Array.isArray(notifications) && notifications.length > 0"
  >
    <div class="dashboard-header">My notifications</div>
    <Alert
      v-for="n in notifications"
      :key="n.uuid"
      class="mb-2 b-0"
      role="none"
      in-page
      background-colour
      :allow-dismiss="n.dismissable"
      @dismiss="dismissNotification(n)"
      :text="n.heading"
      :subtext="n.subHeading"
      :type="n.severity"
      :action-text="n.callToAction.buttonText"
      :action-handler="handleAction.bind(null, n)"
    />
  </div>
</template>

<script>
import { Alert } from '@nswdoe/doe-ui-core'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Alert
  },
  computed: {
    ...mapGetters(['notifications', 'selectedSchool', 'showSettings'])
  },
  methods: {
    ...mapActions(['dismissNotification']),
    handleAction(notification) {
      switch (notification.systemType) {
        case 'SRE':
          this.$store.commit('set', ['showSettings', true])
          this.$store.commit('set', ['settingsTab', 2])
          return
        case 'CAP':
          this.$store.commit('set', ['showSettings', true])
          this.$store.commit('set', ['settingsTab', 1])
          return
        default:
          window.open(
            notification.callToAction.URL,
            '_blank',
            'noopener,noreferrer'
          )
      }
    }
  },
  watch: {
    selectedSchool() {
      this.$store.dispatch('fetchNotifications')
    },
    showSettings: async function (open) {
      if (!open) {
        await this.$store.dispatch('resetNotifications')
        await this.$store.dispatch('fetchNotifications')
      }
    }
  },
  created() {
    this.$store.dispatch('fetchNotifications')
  }
}
</script>

<style scoped lang="scss">
#app-notifications {
  margin-bottom: 5rem;
}
.dashboard-header {
  font-size: 1.8rem;
  color: $ads-primary-blue;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
::v-deep .v-alert {
  padding: 20px;

  &:focus {
    border: none;
    outline: 3px solid $color-primary;
  }
  .v-btn--icon {
    border: 0;
  }
  .alert--clickable-action {
    margin: 0;
  }
  .v-alert__icon.v-icon {
    font-size: 32px;
    height: 32px;
    min-width: 32px;
    margin: 0 20px 0 8px;
  }
}
</style>
