export default [
  null,
  {
    status: 200,
    message: 'StudentSearchByRequest successfully completed.',
    body: [
      {
        dateOfBirth: '2010-07-27T14:00:00.000+00:00',
        displayAddress: 'CAMPBELLTOWN 2560 Australia',
        familyDisplayName: 'Family : Mr D Vanthiya',
        firstName: 'Haranad',
        gender: 'Male',
        matchPercentage: 97,
        otherName: 'Mani',
        parentsDisplayName:
          'Mother: Kundavei,  Devi Kundavai; Father: Vanthiya,  Devan Kanavu',
        schoolNYear: 'Campbelltown Public School, Year 6',
        srn: 454171250,
        surname: 'Gudivada',
        enrolmentStatus: 'ENRCON',
        parentCarers: [
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              },
              {
                contactType: 'EMAIL',
                contactValue: 'ehub20220725190622@test.com'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mrs',
            firstName: 'Devi',
            familyName: 'Kundavei',
            middleName: 'Kundavai',
            gender: 'F',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'MUM'
          },
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              },
              {
                contactType: 'EMAIL',
                contactValue: 'spbsir61@yopmail.com'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mr',
            firstName: 'Devan',
            familyName: 'Vanthiya',
            middleName: 'Kanavu',
            gender: 'M',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'DAD'
          }
        ]
      },
      {
        dateOfBirth: '2010-07-27T14:00:00.000+00:00',
        displayAddress: 'CAMPBELLTOWN 2560 Australia',
        familyDisplayName: 'Family : Mr D Vanthiya',
        firstName: 'Christopher',
        gender: 'Male',
        matchPercentage: 85,
        otherName: 'Mani',
        parentsDisplayName:
          'Mother: Kundavei,  Devi Kundavai; Father: Vanthiya,  Devan Kanavu',
        schoolNYear: 'Campbelltown Public School, Year 6',
        srn: 454171251,
        surname: 'Horikx',
        enrolmentStatus: 'ENRCON',
        parentCarers: [
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              },
              {
                contactType: 'EMAIL',
                contactValue: 'ehub20220725190622@test.com'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mrs',
            firstName: 'Devi',
            familyName: 'Kundavei',
            middleName: 'Kundavai',
            gender: 'F',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'MUM'
          },
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              },
              {
                contactType: 'EMAIL',
                contactValue: 'spbsir61@yopmail.com'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mr',
            firstName: 'Devan',
            familyName: 'Vanthiya',
            middleName: 'Kanavu',
            gender: 'M',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'DAD'
          }
        ]
      },
      {
        dateOfBirth: '2010-07-27T14:00:00.000+00:00',
        displayAddress: 'CAMPBELLTOWN 2560 Australia',
        familyDisplayName: 'Family : Mr D Vanthiya',
        firstName: 'Srinivas Rao',
        gender: 'Male',
        matchPercentage: 82,
        otherName: 'Mani',
        parentsDisplayName:
          'Mother: Kundavei,  Devi Kundavai; Father: Vanthiya,  Devan Kanavu',
        schoolNYear: 'Campbelltown Public School, Year 6',
        srn: 454171252,
        surname: 'Kandimalla',
        enrolmentStatus: 'ENRCON',
        parentCarers: [
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mrs',
            firstName: 'Devi',
            familyName: 'Kundavei',
            middleName: 'Kundavai',
            gender: 'F',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'MUM'
          },
          {
            contactDetails: [
              {
                contactType: 'PHMOB',
                contactValue: '1234567890'
              },
              {
                contactType: 'EMAIL',
                contactValue: 'spbsir61@yopmail.com'
              }
            ],
            isEnrolmentOwner: true,
            isAboriOrTorres: '1',
            speakLanguageOtherThanEnglish: true,
            mainLangOtherThanEnglish: 'TEL',
            otherLanguage: 'KAN',
            liveOnResidentialAddress: true,
            countryBirth: 'IND',
            title: 'Mr',
            firstName: 'Devan',
            familyName: 'Vanthiya',
            middleName: 'Kanavu',
            gender: 'M',
            occupationGroup: '1',
            occupationName: 'Manager',
            schoolEducationCode: '4',
            educationQualificationCode: '7',
            recordLastModified: '2023-01-30T00:55:14.138+0000',
            relation: 'DAD'
          }
        ]
      }
    ]
  },
  {
    status: 200,
    message: 'StudentSearchByRequest successfully completed.',
    body: []
  }
]
