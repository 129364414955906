<!--
  This template displays a single, editable field, including label and any validation/conflict alerts.
  NOTE: data-id attribute is added so that external automated test suites can navigate our content.
-->

<template>
  <div
    :data-id="modelRow.id"
    :class="
      'Field ' + getFieldRedBarClass(modelRow) + getFieldWidthClass(modelRow)
    "
  >
    <!-- Field label... -->
    <label v-if="isFieldLabel">
      <span class="fieldLabel">{{ modelRow.label }}</span>
      <!-- If simple text input field... -->
      <div v-if="isTextInput" class="fieldContent">
        <input
          type="text"
          v-model="currentFieldValue"
          :aria-describedby="
            modelRow.field && modelRow.field.apiKey
              ? modelRow.field.apiKey + '-error'
              : ''
          "
          :maxlength="getMaxLength"
          ref="defaultInput"
          :placeholder="getPlaceholder"
          @keyup="onKeyup()"
          @change="onChange(currentFieldValue)"
          @focus="onFocus()"
        />
      </div>

      <!-- If ADDRESS field... -->
      <div v-if="modelRow.type === 'ADDRESS'" class="fieldContent">
        <button
          type="button"
          class="address unstyled"
          ref="defaultInput"
          @click="editAddress()"
          @focus="onFocus()"
          title="Click to modify address"
          :aria-describedby="
            modelRow.field && modelRow.field.apiKey
              ? modelRow.field.apiKey + '-error'
              : ''
          "
        >
          <DisplayAddress class="addressText" :model-row="modelRow" />
          <div class="openAddressIcon">
            <font-awesome-icon
              focusable="false"
              icon="ellipsis-h"
              class="editAddress"
            />
          </div>
        </button>
      </div>

      <!-- If TEXTAREA field... -->
      <div v-if="modelRow.type === 'TEXTAREA'" class="fieldContent">
        <textarea
          v-model="currentFieldValue"
          :maxlength="getMaxLength"
          ref="defaultInput"
          @keyup="onKeyup()"
          @change="onChange(currentFieldValue)"
          @focus="onFocus()"
          :aria-describedby="
            modelRow.field && modelRow.field.apiKey
              ? modelRow.field.apiKey + '-error'
              : ''
          "
        ></textarea>
      </div>

      <!-- If DROPLIST field... -->
      <div v-if="modelRow.type === 'DROPLIST'" class="fieldContent">
        <select
          v-model="currentFieldValue"
          ref="defaultInput"
          @keyup="onKeyup()"
          @change="onChange(currentFieldValue)"
          @focus="onFocus()"
          :aria-describedby="
            modelRow.field && modelRow.field.apiKey
              ? modelRow.field.apiKey + '-error'
              : ''
          "
        >
          <option
            v-for="(option, index) in getDroplistOptions(modelRow)"
            :key="modelRow.id + index"
            :value="option.value"
            :disabled="option.disabled"
          >
            {{ option.text }}
          </option>
        </select>
      </div>

      <!-- If SCHOOL_LOOKUP field... -->
      <div v-if="modelRow.type === 'SCHOOL_LOOKUP'" class="fieldContent">
        <SchoolLookup
          name="Au School lookup"
          :selected-school-id="modelRow.oesValue"
          @schoolCodeChange="onChange"
          ref="defaultInput"
        />
      </div>
    </label>

    <!--
      If RADIO BUTTON field...
      NOTE: data-id attribute is added so that external automated test suites can navigate our content
    -->
    <fieldset v-if="modelRow.type === 'RADIO'" class="fieldContent">
      <legend class="fieldLabel">{{ modelRow.label }}</legend>
      <AppCheckbox
        v-for="(option, index) in getRadioOptions(modelRow)"
        :data-id="modelRow.id + ':' + option.value"
        :key="modelRow.id + index"
        :label="option.text"
        :checked="modelRow.oesValue === option.value"
        @change="setRadioButton(option.value, ...arguments)"
        ref="defaultInput"
        :aria-described-by="
          modelRow.field && modelRow.field.apiKey
            ? modelRow.field.apiKey + '-error'
            : ''
        "
      />
    </fieldset>

    <!-- If DATE field, use picker... -->
    <fieldset v-if="modelRow.type === 'DATE'">
      <legend class="fieldLabel">{{ modelRow.label }}</legend>
      <AppDatePicker
        ref="defaultInput"
        :label="modelRow.label"
        :value="modelRow.oesValue"
        :future-years="getFutureYears(modelRow)"
        :past-years="getPastYears(modelRow)"
        :special-years="getSpecialYears(modelRow)"
        @change="onChange"
        @focus="onFocus()"
        :month-only="modelRow.field.monthOnly"
        :aria-described-by="
          modelRow.field && modelRow.field.apiKey
            ? modelRow.field.apiKey + '-error'
            : ''
        "
      />
    </fieldset>

    <!-- Warn as you type if has exceeded length limit... -->
    <div v-if="exceededLimitBy > 0" class="FieldAlert">
      <font-awesome-icon
        class="icon"
        focusable="false"
        icon="exclamation-triangle"
      />
      <span class="alertMessage"
        >Exceeded limit by {{ exceededLimitBy }} character(s)</span
      >
    </div>
    <FieldAlert v-else :model-row="modelRow" />
  </div>
</template>

<script>
import AppCheckbox from '@/components/app/AppCheckbox.vue'
import AppDatePicker from '@/components/app/AppDatePicker.vue'
import SchoolLookup from '@/components/form/SchoolLookup.vue'
import FieldAlert from '@/components/form/FieldAlert.vue'
import DisplayAddress from '@/components/application/DisplayAddress.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { FIELD_TYPE } from '@/constants'
import _ from 'lodash'

export default {
  name: 'FormField',
  components: {
    AppCheckbox,
    AppDatePicker,
    SchoolLookup,
    FieldAlert,
    DisplayAddress
  },
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin],
  data() {
    return {
      isValidationAlertsCleared: false,
      originalFieldValue: '',
      currentFieldValue: this.modelRow.oesValue
    }
  },
  computed: {
    isFieldLabel() {
      // Only fields with a single input should be wrapped in a label tag
      return (
        this.modelRow.type !== 'RADIO' &&
        this.modelRow.type !== FIELD_TYPE.DATE &&
        this.modelRow.type !== FIELD_TYPE.FILE_EDITOR
      )
    },
    isTextInput() {
      return (
        this.modelRow.type !== FIELD_TYPE.ADDRESS &&
        this.modelRow.type !== FIELD_TYPE.TEXTAREA &&
        this.modelRow.type !== FIELD_TYPE.RADIO &&
        this.modelRow.type !== FIELD_TYPE.DATE &&
        this.modelRow.type !== FIELD_TYPE.DROPLIST &&
        this.modelRow.type !== FIELD_TYPE.FILE_EDITOR &&
        this.modelRow.type !== FIELD_TYPE.SCHOOL_LOOKUP
      )
    },
    getMaxLength() {
      if (this.modelRow.field.maxlength) {
        return this.modelRow.field.maxlength
      }
      if (this.modelRow.field.format) {
        return this.modelRow.field.format.length
      }
      if (this.modelRow.type === FIELD_TYPE.POSTCODE) {
        return '4'
      }
      if (this.modelRow.type === FIELD_TYPE.MONTH_YEAR) {
        return '7'
      }
      if (this.modelRow.type === FIELD_TYPE.PHONE) {
        return '132'
      }
      return ''
    },
    exceededLimitBy() {
      return typeof this.currentFieldValue === 'string'
        ? this.currentFieldValue.length - 2048
        : 0
    },
    getPlaceholder() {
      if (this.modelRow.type === FIELD_TYPE.MONTH_YEAR) {
        return 'MM/YYYY'
      }
      return ''
    }
  },
  methods: {
    getFutureYears(modelRow) {
      if (modelRow.field.futureYears >= 0) {
        return modelRow.field.futureYears
      }
    },
    getPastYears(modelRow) {
      if (modelRow.field.pastYears >= 0) {
        return modelRow.field.pastYears
      }
    },
    getSpecialYears(modelRow) {
      if (modelRow.field.specialYears) {
        return modelRow.field.specialYears
      }
    },
    editAddress() {
      this.$store.dispatch('set', ['editAddress', _.cloneDeep(this.modelRow)])
    },
    onChange(val) {
      val = this.formatValue(this.modelRow.field, val)
      if (FIELD_TYPE.PHONE === this.modelRow.field?.type) {
        val = this.removeNonAsciiCharacters(val)
        if (/^[0-9]+$/.test(val)) {
          val = this.formatPhoneNumber(val)
        }
      }
      this.currentFieldValue = val
      this.$store.dispatch('setFieldValue', [this.modelRow.field, val])
      this.onFieldChange(this.modelRow.field, val)
      this.$store.dispatch('refreshModel')
    },
    onKeyup() {
      // When modifying a field with an existing validation alert, clear the
      // alert while typing. It will validate again when leaving the field.
      if (
        !this.isValidationAlertsCleared &&
        this.originalFieldValue !== this.currentFieldValue
      ) {
        // FUS-1813: Changes to eslint rules now disallow the mutation of
        // component props. The error for the next line ihas been disabled
        // as the component is currently working.
        // eslint-disable-next-line vue/no-mutating-props
        this.modelRow.isInvalid = false
        // FUS-1813: Changes to eslint rules now disallow the mutation of
        // component props. The error for the next line ihas been disabled
        // as the component is currently working.
        // eslint-disable-next-line vue/no-mutating-props
        this.modelRow.isMissing = false
        this.isValidationAlertsCleared = true
      }
    },
    setRadioButton(val, checked) {
      this.onChange(checked ? val : '')
    },
    setFocus() {
      // Focuses the current field by finding the "defaultInput" ref...
      try {
        var defaultInput = this.$refs.defaultInput
        if (defaultInput.focus) {
          // A focusable html input was found...
          defaultInput.focus()
        } else if (defaultInput.$refs && defaultInput.$refs.defaultInput) {
          // A component containing a focusable control was found...
          defaultInput.$refs.defaultInput.focus()
        } else if (
          defaultInput[0].$refs &&
          defaultInput[0].$refs.defaultInput
        ) {
          // An array of focusable controls was found, so focus the first...
          defaultInput[0].$refs.defaultInput.focus()
        }
      } catch (e) {
        // No focusable element was found
      }
    },
    onFocus() {
      this.$store.dispatch('set', ['focusFieldId', this.modelRow.id])
      this.originalFieldValue = this.currentFieldValue
      this.isValidationAlertsCleared = false
      this.moveFieldIntoView()
    },
    moveFieldIntoView() {
      // If focused field is concealed below Save/Cancel bar, scroll it into view
      var saveBarHeight = 65
      var distanceFromBottom =
        window.innerHeight - this.$el.getBoundingClientRect().bottom
      if (distanceFromBottom < saveBarHeight) {
        window.scrollTo(
          window.pageXOffset,
          window.pageYOffset + (saveBarHeight - distanceFromBottom)
        )
      }
    }
  },
  updated() {
    // For DROPLIST fields, if field value is not in the droplist, clear the
    // value out of the store. Otherwise we end up with what looks like an
    // empty field on screen, but with an underlying value. This will mess up
    // mandatory field checking.
    if (this.modelRow.type === FIELD_TYPE.DROPLIST) {
      if (
        !this.getDroplistOptions(this.modelRow).find(
          (option) => option.value == this.modelRow.oesValue
        )
      ) {
        this.onChange('')
      }
    }
  },
  watch: {
    focusFieldId() {
      if (this.modelRow.id === this.focusFieldId) {
        this.setFocus()
      }
    }
  },
  mounted() {
    // When clicking on a field to enter edit mode, we want that field to stay
    // in the same position on screen. However because of field heights varying
    // between view and edit mode, the window scroll position for the current field
    // will also be different.

    // So when we leave view mode, we store the height between the current field
    // and the top of the viewport. Then in edit mode, we set window scroll so that
    // the edit field has the same distance to the top of the viewport.
    if (window.OES && window.OES.initialFocusField === this.modelRow.id) {
      window.OES.initialFocusField = null
      if (window.OES.initialFieldOffset) {
        var difference =
          window.OES.initialFieldOffset - this.$el.getBoundingClientRect().top
        window.scrollTo(window.pageXOffset, window.pageYOffset - difference)
        window.OES.initialFieldOffset = null
      }
      // this.setFocus()
    }
  }
}
</script>

<style scoped lang="scss">
.Field {
  padding: $content-padding;
  padding-top: 0;
  display: inline-block;
  vertical-align: top;
  width: 33.33%; // Currently giving a three column layout - will make responsive later

  &.width66 {
    width: 66.66%;
  }
  &.width100 {
    width: 100%;
  }

  input,
  textarea,
  select {
    width: 100%;
  }

  textarea {
    // Allows text areas to be manually resized vertically but not horizontally
    min-width: 100%;
    max-width: 100%;
    min-height: 6em;
    max-height: 20em;
  }

  .fieldContent {
    padding-top: 0.25em;

    .openAddressIcon {
      padding: 0 0.5em;
      color: silver;
    }

    button:hover .openAddressIcon,
    button:focus .openAddressIcon {
      // Highlights the address ellipsis when hovering/focused
      color: black;
    }
  }
  .fieldLabel {
    color: $color-text-light;
    font-size: $small-text;
    font-weight: normal;
    margin: 0;
    // wrap long names
    display: inline-block;
    word-break: break-word; // webkit
    word-wrap: break-word; // ie11
    max-width: 100%; // ie11
  }

  &.showRedBar {
    border-left: 0.3em solid $color-red;
    padding-left: 0.7em;
    margin-bottom: 1em;
    padding-bottom: 0;
  }

  .address {
    display: flex;
    align-items: center;
    padding: 0.5em 0 0.5em 0.75em;
    border: $field-border;
    border-radius: $field-border-radius;
    background-color: $color-field-background;
    color: black;
    width: 100%;

    .addressText {
      flex: 1;
    }
  }
  .FieldAlert {
    color: $color-red;
    display: flex;
    padding-top: 0.3rem;
    font-size: 0.9rem;
    .icon {
      font-size: 1rem;
    }
    .alertMessage {
      display: inline-block;
      padding-left: 0.25rem;
    }
  }
}
</style>
