<template>
  <div :class="{ isEditing }">
    <v-card class="contactCard mb-6 px-4">
      <delete-button
        v-if="isEditing"
        @click="$emit('delete', contact)"
        :aria-label="`delete ${contactLabel}`"
      />
      <v-card-title class="px-0 pb-0">
        <div>{{ contactLabel }}</div>
      </v-card-title>
      <v-row>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <select-field
            label="Type"
            v-model="contact.contactType"
            :items="contactOptions"
            :rules="[validators.required]"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <AdsFormatContactField
            v-if="isEditing"
            :label="contactLabel"
            v-model="contact.contactValue"
            :rules="[validators.required, validators.phone]"
            maxlength="132"
            :readonly="readonly"
            persistent-placeholder
          />
          <!-- eslint-enable vue/no-mutating-props -->
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <text-field
            v-else
            :label="contactLabel"
            v-model="contact.contactValue"
            :rules="[validators.required, validators.phone]"
            maxlength="132"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
        <v-col cols="12" md="4">
          <!--
          FUS-1813: Changes to eslint rules now disallow the mutation of
          component props. The error for the next block has been disabled
          as the component is currently working.
          -->
          <!-- eslint-disable vue/no-mutating-props -->
          <text-field
            label="Comments"
            v-model="contact.comments"
            maxlength="50"
            :readonly="readonly"
          />
          <!-- eslint-enable vue/no-mutating-props -->
        </v-col>
      </v-row>
      <v-row v-if="errorMessage">
        <v-col>
          <alert-message>{{ errorMessage }}</alert-message>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import { getContactText } from '@/applicationDefinition/helpers'

import TextField from '@/components/form/TextField'
import SelectField from '@/components/form/SelectField'
import DeleteButton from '@/components/form/DeleteButton'
import AlertMessage from '@/components/form/AlertMessage'
import validatableMixin from '@/components/form/validatableMixin'
import { AdsFormatContactField } from '@nswdoe/doe-ui-core'

export default {
  name: 'Y67tFormContact',
  mixins: [validatableMixin('contact', 'Contact')],
  components: {
    TextField,
    SelectField,
    DeleteButton,
    AlertMessage,
    AdsFormatContactField
  },
  props: {
    contact: {
      type: Object,
      default: () => ({
        contactType: '',
        contactValue: '',
        comments: ''
      })
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      validators: {
        required: validators.required,
        phone: validators.phone
      }
    }
  },
  computed: {
    ...mapGetters(['application', 'isEditing']),
    contactLabel() {
      return getContactText(this.contact)
    },
    contactOptions() {
      return this.$store.getters.ooaContactOptions
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .contactCard {
  background: #fdfdfd;
  .v-input .v-input__control .v-input__slot {
    background: white;
  }
}
</style>
