<template>
  <div ref="schoolLookup">
    <div v-if="!isEditing">
      <read-only-field
        :label="$attrs.label"
        :value="readOnlySchoolName"
        v-bind="$attrs"
        @click="setEditMode($refs.schoolLookup)"
        data-testid="schoolLookupAdsReadOnly"
      />
    </div>
    <div v-else>
      <v-autocomplete
        v-model="selectedSchool"
        :items="optionsList"
        :search-input.sync="searchInput"
        :rules="rules"
        placeholder="Search Australian schools"
        return-object
        hide-no-data
        clearable
        outlined
        @input="onTypeAheadSelect"
        v-bind="$attrs"
        data-testid="schoolLookupAds"
      />
    </div>
  </div>
</template>

<script>
import toggleFieldMixin from './toggleFieldMixin'

export default {
  name: 'SchoolLookupAds',
  mixins: [toggleFieldMixin],
  props: {
    value: {
      type: String,
      default: null
    },
    stateCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      optionsList: [],
      searchInput: '',
      selectedSchool: null,
      rules: [
        ...(this.$attrs.rules || []),
        () => {
          return this.optionsList.length > 0 || 'Not found.'
        }
      ]
    }
  },
  computed: {
    schoolsList() {
      return this.$store.getters.auSchools || []
    },
    readOnlySchoolName() {
      return this.value.split('|')[1]
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectedSchool = this.mapSchool(
            this.schoolsList.find((s) => s.uniqueRecord === val)
          )
          this.optionsList = [this.selectedSchool]
        } else {
          this.selectedSchool = null
        }
      }
    },
    searchInput(val) {
      if (!val) {
        this.optionsList = []
        this.selectedSchool = null
        return
      }
      if (val.length < 3) {
        this.optionsList = []
      } else {
        // don't search if we've just set in a value
        if (!this.selectedSchool || val !== this.selectedSchool.text) {
          this.search(val)
        }
      }
    }
  },
  async created() {
    if (this.$store.getters.auSchools === null) {
      await this.$store.dispatch('getAllAuSchools')
    }
  },
  methods: {
    onTypeAheadSelect() {
      if (this.schoolsList.length > 0) {
        this.$emit('input', this.selectedSchool.value)
        this.$emit('update:stateCode', this.selectedSchool.stateCode)
      }
    },
    search(val) {
      if (this.schoolsList.length > 0) {
        const matchedSchools = this.schoolsList.filter((item) =>
          item.orgUnitName.toLowerCase().startsWith(val.toLowerCase())
        )
        this.optionsList = matchedSchools.map(this.mapSchool)
      }
    },
    mapSchool(school) {
      return school
        ? {
            ...school,
            text:
              school.orgUnitName +
              (school.suburb === 'null' ? '' : ` (${school.suburb.trim()})`),
            value: school.uniqueRecord
          }
        : {}
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input__icon.v-input__icon--append {
  display: none;
}
</style>
