<template>
  <div class="py-6 grey lighten-6">
    <!-- UPDATE Filters -->
    <v-row class="pb-3">
      <v-col class="pt-4">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3">
          <DateRangeFilter
            ref="processedDateFilter"
            label="Processed date"
            heading="Show requests"
            :archive-type="archiveType"
            :filter-options="dateRangeFilter"
            @updatefilter="updateProcessedDateFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <DateRangeFilter
            ref="receivedDateFilter"
            label="Received date"
            heading="Show requests"
            :archive-type="archiveType"
            :filter-options="dateRangeFilter"
            @updatefilter="updateReceivedDateFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <ListViewFilter
            ref="scholasticYearFilter"
            label="Scholastic year"
            type="checkbox"
            :filter-options="scholasticYearFilters"
            @updatefilter="updateScholasticYearFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
    </v-row>

    <v-card>
      <AdsDataTable
        :headers="headers"
        :items="cUpdates"
        search-label="Find student"
        :search="internalSearch"
        sort-by="dateProcessed"
        sort-desc
        must-sort
        :items-per-page="pagination.itemsPerPage"
        :footer-props="{
          'items-per-page-options': [10, 15, 50, -1]
        }"
        :custom-filter="filterUpdates"
        @pagination="totalResults"
        @current-items="currentFilteredItems = $event"
        :page.sync="currentPage"
        data-testid="updatesProcessedList"
      >
        <template #no-data>
          <NoResultsFound
            :archive-type="archiveType"
            :filtered="isFiltered || Boolean(internalSearch)"
          />
        </template>
        <template #top>
          <v-row class="row-line">
            <v-col>
              <v-text-field
                class="searchInput"
                v-model="internalSearch"
                prepend-inner-icon="mdi-magnify"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text d-flex align-end pr-5">
              <span>
                {{ resultsText(pagination.itemsLength) }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template slot="item" slot-scope="props">
          <!-- Table template for "out of area applications" -->
          <tr
            @click="openUpdate(props.item.id)"
            class="grey--text text--lighten-1"
          >
            <td class="text-xs-right">
              {{
                props.item[DT_PROPERTIES.DATE_PROCESSED] | moment('DD MMM YYYY')
              }}
            </td>
            <td class="text-xs-right">
              {{ props.item[DT_PROPERTIES.TYPE] }}
            </td>
            <td class="text-xs-right">
              {{
                props.item[DT_PROPERTIES.DATE_RECEIVED] | moment('DD MMM YYYY')
              }}
            </td>

            <td class="text-xs-right">
              <router-link
                :to="{
                  path: getItemPath(schoolCode, props.item.id)
                }"
                :aria-label="`${props.item.name}. Open application details.`"
              >
                {{ props.item[DT_PROPERTIES.NAME] }}
              </router-link>
            </td>

            <td class="text-sm-center text-md-left">
              <ScholasticYearCell
                :year="props.item[DT_PROPERTIES.SCHOLASTIC_YEAR]"
              />
            </td>

            <td class="text-xs-right">
              {{ props.item[DT_PROPERTIES.REQUESTED_BY] }}
            </td>

            <td class="text-xs-right">
              <Chip
                small
                class="text-center font-weight-bold"
                :aria-label="
                  (props.item[DT_PROPERTIES.UPDATE_STATUS] || '').toLowerCase()
                "
                v-bind="
                  getStatusChipDetails(props.item[DT_PROPERTIES.UPDATE_STATUS])
                "
              />
            </td>
          </tr>
        </template>
      </AdsDataTable>
    </v-card>
  </div>
</template>

<script>
/**
 * ***Explaining filters***
 *
 * Filter types
 * 1. Tab filtering
 * 2. Search filtering
 * 3. Pill filtering
 *
 * Tab filtering
 * Tab filtering is filtered using the SDU_TAB_FILTERING constant. This is a map of the tab name to the statuses that should be displayed.
 *
 * Search filtering
 * Search filtering is done using the searchFilterForStringOnly function.
 *
 * Pill filtering
 * TODO: Explain pill filtering
 *  */

import ListViewFilter from '@/components/app/ListViewFilter.vue'
import DateRangeFilter from '@/components/app/DateRangeFilter.vue'
import ScholasticYearCell from '@/components/app/ScholasticYear.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import ClearFilters from './ClearFilters.vue'
import { mapGetters } from 'vuex'
import {
  searchFilterForStringOnly,
  sortByScholasticYear
} from '@/helpers/searchHelper'
import { AdsDataTable } from '@nswdoe/doe-ui-core'
import { default as Chip } from '@/components/app/ChipNonClickable'
import allScholasticYears from '@/store/allScholasticYears'
import {
  SDU_STATUS_SORTING_ORDER,
  SDU_TABS,
  SDU_TAB_FILTERING,
  STREAM,
  ARCHIVE_TYPES
} from '@/constants'
import { mixStatuses } from '@/mixins/sduMixins'

const DT_PROPERTIES = {
  ID: 'id',
  DATE_PROCESSED: 'dateProcessed',
  TYPE: 'type',
  DATE_RECEIVED: 'dateReceived',
  NAME: 'name',
  SCHOLASTIC_YEAR: 'scholasticYear',
  REQUESTED_BY: 'requestedBy',
  UPDATE_STATUS: 'updateStatus',
  SCHOLASTIC_YEAR_FORMAT: 'scholasticYearFormatted'
}
const headers = [
  {
    text: 'Processed date',
    value: DT_PROPERTIES.DATE_PROCESSED,
    align: 'start',
    class: ''
  },
  {
    text: 'Type',
    value: DT_PROPERTIES.TYPE,
    align: 'start'
  },
  {
    text: 'Received',
    value: DT_PROPERTIES.DATE_RECEIVED,
    align: 'start',
    class: ''
  },
  {
    text: 'Student name',
    value: DT_PROPERTIES.NAME,
    align: 'start'
  },
  {
    text: 'Scholastic year',
    value: DT_PROPERTIES.SCHOLASTIC_YEAR,
    align: '',
    sort: (a, b) => {
      return (
        (a.toUpperCase() === 'K' ? 0 : a) - (b.toUpperCase() === 'K' ? 0 : b)
      )
    }
  },
  {
    text: 'Requested by',
    value: DT_PROPERTIES.REQUESTED_BY,
    align: ''
  },
  {
    text: 'Status',
    value: DT_PROPERTIES.UPDATE_STATUS,
    class: '',
    align: 'start',
    width: '175',
    sort: (a, b) => {
      return (
        SDU_STATUS_SORTING_ORDER.indexOf(a) -
        SDU_STATUS_SORTING_ORDER.indexOf(b)
      )
    }
  }
]

export default {
  name: 'StudentDetailUpdatesProcessed',
  components: {
    Chip,
    DateRangeFilter,
    ListViewFilter,
    AdsDataTable,
    ClearFilters,
    ScholasticYearCell,
    NoResultsFound
  },
  data() {
    return {
      headers,
      selectedStatus: [],
      internalSearch: '',
      DT_PROPERTIES,
      currentPage: 1,
      filteredIds: 0,
      pagination: { itemsLength: 0, itemsPerPage: 50 },
      tabName: SDU_TABS.PROCESSED,
      selectedDateProcessedRange: [],
      selectedDateReceivedRange: [],
      selectedScholasticYear: [],
      currentFilteredItems: [],
      archiveType: ARCHIVE_TYPES.SRESEE.type
    }
  },
  mixins: [mixStatuses],
  methods: {
    totalResults(pagination) {
      this.pagination = pagination
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    },
    getItemPath(schoolCode, id) {
      return `/student-detail-update/${schoolCode}/${id}`
    },
    openUpdate(id) {
      this.resetFilteredList()
      this.$router
        .push({
          path: this.getItemPath(this.schoolCode, id)
        })
        .catch(() => {})
    },
    resetFilteredList() {
      this.$store.commit(
        'setFilteredStudentDetailUpdates',
        this.currentFilteredItems.map((i) => i.id)
      )
    },
    filterUpdates(value, search, item) {
      this.currentPage = 1
      return searchFilterForStringOnly(value, search, item)
    },
    updateProcessedDateFilter(dateRange) {
      if (dateRange !== null && dateRange.length > 0) {
        this.selectedDateProcessedRange = dateRange
      } else {
        this.selectedDateProcessedRange = []
      }
    },
    updateReceivedDateFilter(dateRange) {
      if (dateRange !== null && dateRange.length > 0) {
        this.selectedDateReceivedRange = dateRange
      } else {
        this.selectedDateReceivedRange = []
      }
    },
    updateScholasticYearFilter(selectedOptions) {
      if (selectedOptions && selectedOptions.length) {
        this.selectedScholasticYear = selectedOptions
      } else {
        this.selectedScholasticYear = []
      }
    },
    checkStartDateInsideRange(dateReceived, selectedDateRange) {
      const start = this.$moment(selectedDateRange[0])
      const end = this.$moment(selectedDateRange[1])
      const receivedDate = this.$moment(dateReceived)
      return start <= receivedDate && receivedDate <= end
    },
    matchesStatusFilter(updateStatus) {
      return SDU_TAB_FILTERING[this.tabName].includes(
        updateStatus.toUpperCase()
      )
    },
    matchesProcessedDateRangeFilter(update) {
      return (
        !this.selectedDateProcessedRange.length ||
        this.checkStartDateInsideRange(
          this.$moment(update[DT_PROPERTIES.DATE_PROCESSED]).format(
            'YYYY-MM-DD'
          ),
          this.selectedDateProcessedRange
        )
      )
    },
    matchesReceivedDateRangeFilter(update) {
      return (
        !this.selectedDateReceivedRange.length ||
        this.checkStartDateInsideRange(
          this.$moment(update[DT_PROPERTIES.DATE_RECEIVED]).format(
            'YYYY-MM-DD'
          ),
          this.selectedDateReceivedRange
        )
      )
    },
    matchesScholasticYearFilter(update) {
      return (
        !this.selectedScholasticYear.length ||
        this.selectedScholasticYear.includes(
          update[DT_PROPERTIES.SCHOLASTIC_YEAR_FORMAT]
        )
      )
    },
    clearAllFilters() {
      this.$refs.processedDateFilter.clearFilter()
      this.$refs.receivedDateFilter.clearFilter()
      this.$refs.scholasticYearFilter.clearFilter()
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const processedDateData = this.selectedDateProcessedRange.length
          ? JSON.stringify(this.selectedDateProcessedRange)
          : null
        const receivedDateData = this.selectedDateReceivedRange.length
          ? JSON.stringify(this.selectedDateReceivedRange)
          : null
        const scholasticYearData = this.selectedScholasticYear.length
          ? JSON.stringify(this.selectedScholasticYear)
          : null
        const consolidatedData =
          `${processedDateData},${receivedDateData},${scholasticYearData}`.replaceAll(
            '"',
            ''
          )
        this.$gtm.trackEvent({
          category: 'updateFilter',
          action: STREAM.STUDENT_DETAIL_UPDATES,
          label: consolidatedData
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      getUpdatesBySchoolCode: 'studentDetailUpdates'
    }),
    mappedUpdates() {
      return (this.getUpdatesBySchoolCode(this.schoolCode) || [])
        .map((i) => ({
          [DT_PROPERTIES.ID]: i.uuid,
          [DT_PROPERTIES.DATE_PROCESSED]: i.request.processedDate,
          [DT_PROPERTIES.TYPE]: i.request.requestType,
          [DT_PROPERTIES.DATE_RECEIVED]: i.submittedDate,
          [DT_PROPERTIES.NAME]: `${i.studentDetail.studentSurname}, ${i.studentDetail.studentFirstName}`,
          [DT_PROPERTIES.SCHOLASTIC_YEAR]: i.studentDetail.scholasticYear,
          [DT_PROPERTIES.REQUESTED_BY]: `${i.parentDetail.parentSurname}, ${i.parentDetail.parentFirstName}`,
          [DT_PROPERTIES.UPDATE_STATUS]: i.request.statusRequest,
          [DT_PROPERTIES.SCHOLASTIC_YEAR_FORMAT]: allScholasticYears.reduce(
            (acc, y) =>
              y.value === i?.studentDetail?.scholasticYear ? y.text : acc,
            ''
          )
        }))
        .filter((update) =>
          this.matchesStatusFilter(update[DT_PROPERTIES.UPDATE_STATUS])
        )
    },
    cUpdates() {
      return this.mappedUpdates.filter(
        (update) =>
          this.matchesProcessedDateRangeFilter(update) &&
          this.matchesReceivedDateRangeFilter(update) &&
          this.matchesScholasticYearFilter(update) &&
          (!this.internalSearch ||
            searchFilterForStringOnly(update.name, this.internalSearch, update))
      )
    },
    dateRangeFilter() {
      return { disabled: this.mappedUpdates?.length ? false : true }
    },
    scholasticYearFilters() {
      const updateScholasticYear = [
        ...new Set(
          this.mappedUpdates.map((u) => u[DT_PROPERTIES.SCHOLASTIC_YEAR_FORMAT])
        )
      ]
      return updateScholasticYear.sort(function (a, b) {
        return sortByScholasticYear.indexOf(a) - sortByScholasticYear.indexOf(b)
      })
    },
    isFiltered() {
      return (
        Boolean(this.selectedDateProcessedRange.length) ||
        Boolean(this.selectedDateReceivedRange.length) ||
        Boolean(this.selectedScholasticYear.length)
      )
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .v-input.searchTxtField {
  background-color: transparent;
  input {
    border: 0;
    outline: none;
    background-color: transparent;
  }
  input::placeholder {
    color: $color-placeholder;
  }
  input:-ms-input-placeholder,
  input::-ms-input-placeholder {
    color: $color-placeholder;
  }
  //overridding clear button styles after updating to vuetify 3.2.8
  .v-input__icon--clear button {
    padding: 0;
    border: none;
  }
  .v-input__icon--clear .theme--light.v-icon:before {
    color: white;
    background-color: $color-text-light;
    border-radius: 50%;
    font-size: 16px;
    font-weight: bold;
    padding: 2px;
  }
  .v-input__icon.v-input__icon--append .theme--light.v-icon {
    color: $color-secondary;
  }
}
.alert {
  color: $color-red;
}
// data table
::v-deep .v-data-table {
  padding: 0;
  border: 0;

  .v-chip__content span {
    display: inline-block;
    text-align: center;
    min-width: 100px;
    font-weight: bold;
  }
  .v-data-table__wrapper {
    overflow-x: hidden !important;
  }
  table {
    table-layout: fixed;
    .v-data-table__empty-wrapper {
      background-color: white !important; // Prevents hover highlight on "No data available" row
      td {
        padding: 62px 0 !important; // Adds extra tall row height to "No data available" row
        color: $ads-dark-60;
      }
    }
  }
  thead.v-data-table-header {
    th[scope='col'] {
      color: $color-primary;
      font-size: 16px;
      font-weight: normal;
      padding: 20px 8px;
      vertical-align: top;
      &:first-child {
        padding-left: 20px !important;
      }
      .v-btn:not(.v-btn--round).v-size--small {
        min-width: 0;
      }
      .v-data-table-header__icon {
        color: $color-primary-lighten-1;
        margin-left: 3px;
      }
    }
  }

  div.v-data-table__wrapper table tbody {
    tr:not(:last-child) td {
      border-bottom: 1px solid white;
      padding: 25px 8px;
    }
    tr:last-child td {
      border-bottom: 1px solid white;
      padding: 10px;
    }
    td {
      font-size: 14px;
      // overriding vuetify's data table styling
      border-bottom: 0 !important;
      &:last-child {
        padding-right: 20px !important;
      }
    }
  }

  div.v-data-footer {
    font-size: 14px;
  }

  @include desktop {
    td {
      cursor: default;
    }

    div.v-data-table__wrapper table tbody {
      tr:not(:last-child) td {
        padding: 20px 10px;
      }
      tr:last-child td {
        padding: 20px 10px;
      }
      tr td:first-child {
        padding-left: 20px;
      }
    }
    .v-btn--icon.v-size--default {
      height: 32px;
      width: 32px;
    }
  }
}
::v-deep
  .dataTable
  table
  thead:not(.v-data-table-header-mobile)
  th:first-child {
  padding-left: 10px !important;
}
::v-deep
  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn--outlined
  ) {
  background-color: $ads-dark-60 !important;
  color: $ads-white !important;
  border: 3px solid transparent;
  .v-icon {
    color: $ads-white !important;
  }
}
.filterLabel {
  color: $color-placeholder;
}
.row-line {
  border-bottom: 1px solid #e0e0e0;
  margin: 0px 5px 10px 0px;
  padding: 0px;
  width: 100%;
}
.chip-label {
  min-width: 90px;
  font-weight: bold;
}
.search-results-number {
  float: right;
  white-space: nowrap;
}
.data-table-header {
  align: right;
}
</style>
