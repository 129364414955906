<!--
 FieldCustom provides flexibilty to pass in any arbitruary component to meet UI requirments)
-->
<template>
  <div
    class="FieldCustom"
    :style="modelRow.field.cssStyle"
    data-testid="custom-field-component"
  >
    <component
      v-if="
        modelRow.field.component === 'AdsButton' &&
        canView &&
        containsSafeSupportingDocuments
      "
      :is="modelRow.field.component"
      :model-row="modelRow"
      v-bind="modelRow.field.componentProps"
      @click="displayDownloadDialog = true"
      data-testid="custom-field-component-AdsButton"
    />
    <component
      v-else-if="modelRow.field.component !== 'AdsButton'"
      :is="modelRow.field.component"
      :model-row="modelRow"
      v-bind="modelRow.field.componentProps"
      data-testid="custom-field-component-non-AdsButton"
    />
    <DownloadDocumentsModal
      v-if="modelRow.field.component === 'AdsButton' && displayDownloadDialog"
      :applications="applicationInfo"
      :school-code="schoolCode"
      :application-type="APPLICATION_TYPE.LOCAL_AREA"
      :skip-download-app="true"
      @close="displayDownloadDialog = false"
    />
  </div>
</template>

<script>
import { APPLICATION_TYPE, FILE_STATUSES } from '@/constants'
import { AdsButton } from '@nswdoe/doe-ui-core'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import InfoPanel from '@/components/application/InfoPanel'
import MessageWithAction from '@/components/app/MessageWithAction.vue'
import DownloadDocumentsModal from '@/components/applicationList/DownloadDocumentsModal.vue'

export default {
  name: 'FieldCustom',
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin],
  components: {
    InfoPanel,
    MessageWithAction,
    AdsButton,
    DownloadDocumentsModal
  },
  data() {
    return {
      displayDownloadDialog: false,
      APPLICATION_TYPE
    }
  },
  computed: {
    containsSafeSupportingDocuments() {
      const supportingDocuments = Object.values(
        this.application.supportingDocuments
      )
        .flat()
        .filter((doc) => doc.status === FILE_STATUSES.SAFE)
        .map((doc) => doc.objectKey)
      return supportingDocuments.length > 1
    },
    canView() {
      return this.$store.state.userGroup === this.modelRow.field.canView
    },
    application() {
      return this.$store.getters.application
    },
    schoolCode() {
      return this.application.schoolCode
    },
    applicationInfo() {
      return [
        {
          applicationID: this.application.applicationID,
          studentFirstName: this.application.student.firstName,
          studentFamilyName: this.application.student.familyName,
          supportingDocuments: this.application.supportingDocuments,
          isDigitizeEOIConsiderationDocUploaded:
            this.application.isDigitizeEOIConsiderationDocUploaded
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.FieldCustom {
  padding: $content-padding;
}
</style>
