import {
  STATUS,
  SDU_STATUS,
  SDU_STATUS_CHIPS,
  STATUS_CHIPS_DARK_BACKGROUND
} from '@/constants'

export const mixStatuses = {
  data() {
    return {
      sduStatusChips: {
        dark: {
          ...SDU_STATUS_CHIPS,
          [SDU_STATUS.IN_PROGRESS]:
            STATUS_CHIPS_DARK_BACKGROUND[STATUS.IN_PROGRESS]
        },
        light: SDU_STATUS_CHIPS
      }
    }
  },
  methods: {
    getStatusChipDetails(status, chipsArray = this.sduStatusChips.light) {
      const STATUSKEY = Object.entries(SDU_STATUS).reduce(
        (acc, [key, value]) => {
          return value === status.toUpperCase() ? key : acc
        },
        null
      )
      return chipsArray[SDU_STATUS[STATUSKEY]]
    }
  }
}
