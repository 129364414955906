<template>
  <div class="ApplicationList grey lighten-6">
    <Banner default-background1 class="oes-banner ml-0 mr-0">
      <template #title>
        <v-row class="application-filter-row" align="center">
          <v-col class="pt-0 text-no-wrap">
            <div class="IE-FlexAuto">
              <ApplicationFilter
                v-model="selectedApplication"
                :items="filters"
                data-testid="application-filter"
                @changeSelectedStream="changeSelectedStream(stream)"
              />
            </div>
          </v-col>
        </v-row>
      </template>
      <template #rightPannel>
        <v-row class="mx-4" align="center">
          <v-col
            class="pt-0"
            v-for="(application, index) in totalApplicationsDisplayed"
            :key="index"
          >
            <v-card
              class="counter-card text-center text-no-wrap"
              :data-testid="application['data-testid']"
            >
              <v-card-title
                class="counter-card-title justify-center white--text pb-2 pt-8"
              >
                {{ new Intl.NumberFormat().format(application.count) }}
              </v-card-title>
              <v-card-text class="counter-card-text px-2 py-0">
                {{ application.label }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </Banner>
    <div v-if="selectedApplication === 'dashboard'">
      <Dashboard
        @switch-filter="
          selectedApplication =
            selectedApplication === 'dashboard' ? 'dashboard' : localArea
        "
      />
    </div>
    <div v-if="selectedApplication === localArea">
      <LocalAreaListTabs />
    </div>
    <div v-if="selectedApplication === outOfAreaCode">
      <OoaTabDataTable :default-page-length="defaultPage" />
    </div>
    <div v-if="selectedApplication === year67Primary">
      <Y67TPrimaryDataTable />
    </div>
    <div v-if="selectedApplication === year67Secondary">
      <Y67TSecondaryDataTable />
    </div>
    <div v-if="selectedApplication === studentDetailUpdates">
      <StudentDetailUpdates />
    </div>
    <div v-if="selectedApplication === selectiveOffers">
      <SelectiveOffersList />
    </div>
    <div v-if="selectedApplication === archive">
      <ArchiveView />
    </div>
  </div>
</template>

<script>
import {
  CATCHMENT_LEVEL,
  STREAM,
  Y67T_STATUS,
  SCHOOL_SELECTIVENESS
} from '@/constants'
import { mapGetters } from 'vuex'

import { Banner } from '@nswdoe/doe-ui-core'

import Y67TPrimaryDataTable from '@/components/applicationList/Y67TPrimaryDataTable.vue'
import Y67TSecondaryDataTable from '@/components/applicationList/Y67TSecondaryDataTable.vue'
import StudentDetailUpdates from '@/components/applicationList/StudentDetailUpdates.vue'
import LocalAreaListTabs from '@/components/applicationList/LocalAreaListTabs.vue'
import Dashboard from '@/components/applicationList/Dashboard.vue'
import OoaTabDataTable from '@/components/applicationList/OoaTabDataTable.vue'
import ApplicationFilter from '@/components/applicationList/Filter.vue'
import ArchiveView from '@/components/applicationList/ArchiveView.vue'
import SelectiveOffersList from '@/components/applicationList/SelectiveOffersList.vue'

const FILTERS = {
  dashboard: {
    text: 'Dashboard',
    label: 'Overview of enrolment applications',
    value: 'dashboard',
    icon: 'mdi-home-outline',
    width: '44'
  },
  localArea: {
    text: 'Enrolment applications',
    label: 'Review pending and processed enrolment applications',
    value: STREAM.LOCAL_AREA,
    icon: 'mdi-note-text-outline',
    width: '44'
  },
  studentDetailUpdates: {
    text: 'Student detail updates',
    label: 'Review requests to update student details',
    value: STREAM.STUDENT_DETAIL_UPDATES,
    icon: 'mdi-account-edit-outline',
    width: '44'
  },
  outOfArea: {
    text: 'Out-of-area applications',
    label: 'Review applications from out-of-area students',
    value: STREAM.OUT_OF_AREA,
    icon: 'mdi-bus',
    width: '44'
  },
  y67tPrimary: {
    text: 'Year 6 outgoing',
    label: 'Pending 6-7 transition applications',
    value: STREAM.Y67T_PRIMARY,
    icon: 'mdi-school-outline',
    width: '44'
  },
  y67tSecondary: {
    text: 'Year 7 incoming',
    label: 'Tracking sheet for Year 7 applications',
    value: STREAM.Y67T_SECONDARY,
    icon: 'mdi-account-multiple-plus-outline',
    width: '44'
  },
  archive: {
    text: 'Archive',
    label: 'Archived applications, EOIs & student detail updates',
    value: STREAM.ARCHIVE,
    icon: 'mdi-folder-multiple-outline',
    width: '44'
  },
  selectiveOffers: {
    text: 'Selective high school offers',
    label:
      'Students who have accepted an offer and been issued an enrolment application',
    value: STREAM.SELECTIVE_OFFERS,
    icon: 'mdi-star-outline',
    width: '44'
  }
}

export default {
  name: 'ApplicationList',
  components: {
    Y67TPrimaryDataTable,
    ApplicationFilter,
    OoaTabDataTable,
    Y67TSecondaryDataTable,
    StudentDetailUpdates,
    Banner,
    ArchiveView,
    Dashboard,
    LocalAreaListTabs,
    SelectiveOffersList
  },
  data() {
    return {
      selectedApplication: 'dashboard',
      localArea: STREAM.LOCAL_AREA,
      outOfAreaCode: STREAM.OUT_OF_AREA,
      year67Primary: STREAM.Y67T_PRIMARY,
      year67Secondary: STREAM.Y67T_SECONDARY,
      studentDetailUpdates: STREAM.STUDENT_DETAIL_UPDATES,
      archive: STREAM.ARCHIVE,
      selectiveOffers: STREAM.SELECTIVE_OFFERS
    }
  },
  watch: {
    // Change application filter on change of school
    // set to 'pending' if the selected filter is not available for the school
    selectedSchool: {
      handler(newValue) {
        if (newValue) {
          const chkFilter = this.filters.filter(
            (item) => item.value === this.selectedApplication
          )
          this.selectedApplication = chkFilter.length
            ? this.selectedApplication
            : 'dashboard'
        }
      },
      immediate: true
    },
    selectedApplication: {
      handler(selectedApplication) {
        this.$store.commit('set', ['currentStream', selectedApplication])
      },
      immediate: true
    },
    currentStream: {
      handler(currentStream) {
        this.selectedApplication = currentStream
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({
      selectedSchool: 'selectedSchool',
      ooaApplications: 'ooaApplications',
      pendingApplications: 'pendingApplications',
      y67tApplications: 'y67tApplications',
      currentStream: 'currentStream'
    }),
    defaultPage() {
      return this.$store.state.laApplications.defaultPage
    },
    filters() {
      const filters = [FILTERS.dashboard, FILTERS.localArea]
      const catchmentLevel = this.$store.getters.selectedSchool.catchmentLevel
      // not all schools have OOA applications
      if (
        this.$store.getters.selectedSchool &&
        this.$store.getters.selectedSchool.ooa
      ) {
        filters.push(FILTERS.outOfArea)
      }
      if (
        this.$store.getters.selectedSchool &&
        this.$store.getters.selectedSchool.y67t &&
        (catchmentLevel === CATCHMENT_LEVEL.PRIMARY ||
          catchmentLevel === CATCHMENT_LEVEL.CENTRAL)
      ) {
        filters.push(FILTERS.y67tPrimary)
      }
      if (
        this.$store.getters.selectedSchool &&
        this.$store.getters.selectedSchool.y67t &&
        (catchmentLevel === CATCHMENT_LEVEL.SECONDARY ||
          catchmentLevel === CATCHMENT_LEVEL.CENTRAL)
      ) {
        filters.push(FILTERS.y67tSecondary)
      }
      if (
        this.$store.getters.selectedSchool &&
        this.$store.getters.selectedSchool.selectiveInd &&
        this.$store.getters.selectedSchool.selectiveInd !==
          SCHOOL_SELECTIVENESS.NOT
      ) {
        filters.push(FILTERS.selectiveOffers)
      }
      filters.push(FILTERS.studentDetailUpdates)
      filters.push(FILTERS.archive)
      return filters
    },
    totalApplicationsDisplayed() {
      if (!this.selectedSchool) {
        return []
      }

      if ([STREAM.Y67T_SECONDARY].includes(this.selectedApplication)) {
        return [
          {
            count: this.numberOfY67TStudentsInRounds.round1,
            label: 'Round 1 OOA total'
          },
          {
            count: this.numberOfY67TStudentsInRounds.round2,
            label: 'Round 2 OOA total'
          },
          {
            count: this.numberOfY67TStudentsInRounds.round3,
            label: 'Round 3 OOA total'
          },
          {
            count: this.numberOfY67TStudentsInRounds.local,
            label: 'Local students'
          }
        ]
      }

      return []
    },
    numberOfY67TStudentsInRounds() {
      const schoolCode = this.selectedSchool.schoolCode
      const currentYear = new Date().getFullYear().toString()
      const eoiApplications = this.y67tApplications(schoolCode).filter(
        (app) =>
          app.applicationStatus !== 'AutoDraft' &&
          currentYear === new Date(app.psDateSentToERN).getFullYear().toString()
      )
      const ooaRound1SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) => o.preferenceNo === 1 && o.schoolCode === schoolCode
        )
      ).length
      const ooaRound2SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) =>
            o.preferenceNo === 2 &&
            o.schoolCode === schoolCode &&
            o.registrationStatus !== Y67T_STATUS.INACTIVE
        )
      ).length
      const ooaRound3SchoolsCount = eoiApplications?.filter((a) =>
        a.ooaHighSchools?.find(
          (o) =>
            o.preferenceNo === 3 &&
            o.schoolCode === schoolCode &&
            o.registrationStatus !== Y67T_STATUS.INACTIVE
        )
      ).length
      const localHighSchoolCount = eoiApplications
        ?.filter(
          (a) =>
            a.localHighSchool?.schoolCode === schoolCode &&
            a.localHighSchool?.registrationStatus !== Y67T_STATUS.INACTIVE &&
            a.applicationStatus !== Y67T_STATUS.NOT_REQUIRED
        )
        .concat(
          eoiApplications?.filter((a) =>
            a.walkups?.find(
              (i) =>
                i.schoolCode === schoolCode &&
                i.preferenceNo === -1 &&
                i.applicationId
            )
          )
        ).length
      return {
        round1: ooaRound1SchoolsCount,
        round2: ooaRound2SchoolsCount,
        round3: ooaRound3SchoolsCount,
        local: localHighSchoolCount
      }
    }
  }
}
</script>

<style scoped lang="scss">
.alert {
  color: $color-red;
}
.oes-banner {
  align-items: center;
}
.application-filter-row {
  height: 128px;
}
.counter-card {
  width: 202px;
  height: 128px;
  background-color: $ads-custom-navy-3;
}
.counter-card-title {
  font-size: 40px !important;
}
.counter-card-text {
  font-size: 14px !important;
  color: $ads-light-blue !important;
}

::v-deep .theme--light.v-tabs-items {
  background-color: #f5f5f5 !important;
}
::v-deep .theme--light.v-tabs .v-tab,
::v-deep .theme--light.v-tabs .v-tab:not(.v-tab--active) {
  color: $ads-dark;
}
::v-deep .theme--light.v-tabs .v-tab.v-tab--active {
  font-weight: bold;
}
::v-deep .theme--light.v-tabs .v-tabs-slider-wrapper {
  height: 4px !important;
  .v-tabs-slider {
    background-color: $ads-red;
  }
}
::v-deep .theme--light.v-tabs .v-tab small {
  font-size: 100%;
}
::v-deep .v-slide-group__content {
  padding-left: 48px;
  padding-right: 48px;
}

/** Generic datatable styles */
::v-deep .v-data-footer {
  text-align: right;
  .v-search-results__row {
    background-color: $grey-6;
    height: 68px;
    padding: 1.5rem;
  }
  .value {
    color: $color-primary;
    font-weight: bold;
  }
  .link {
    outline: none;
    text-decoration: underline;
  }
  .mdi-send-outline:before {
    transform: rotate(-45deg);
  }
}
::v-deep .v-data-table.dataTable.theme--light .searchInput.v-text-field {
  /* TODO revise following important tags */
  .v-label {
    color: $ads-dark-60 !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    background-color: transparent !important;
  }
  .v-input__icon.v-input__icon--clear {
    // Stop the clear icon from being hidden on blur
    opacity: 1;
  }
  &.v-input .v-icon {
    &.v-icon--link:focus {
      border: 3px solid $ads-navy;
      border-radius: 50%;
      color: $ads-navy;
    }
    &:focus::after {
      opacity: 0;
    }
  }
}
::v-deep .ApplicationFilter > .v-avatar {
  border-radius: initial;
}
</style>
