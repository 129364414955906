<template>
  <div class="pa-8">
    <v-row>
      <v-col
        :class="
          (isAddressConflict || hadAddressConflict) &&
          !isSecondarySchool &&
          !isArchived
            ? 'address-conflict-bg'
            : ''
        "
        cols="12"
        md="4"
      >
        <read-only-field
          label="Submitted EOI Address"
          v-model="residentialAddress"
          readonly
        />
        <div
          v-if="isAddressConflict && !isSecondarySchool && !isArchived"
          class="address-conflict"
        >
          <div class="conflict-text float-left">
            <v-icon class="material-icons pr-3">error</v-icon>
            <span>Conflict with ERN</span>
          </div>
          <div class="float-right">
            <AdsButton
              @click="resolveConflict"
              data-testid="resolve-conflict"
              class="button red darken-1"
              button-text="Resolve"
            />
          </div>
        </div>
        <div
          v-if="
            isReadyToSendErn &&
            hadAddressConflict &&
            !isSecondarySchool &&
            !isArchived
          "
          class="ready-to-sent-ern"
        >
          <div class="sent-ern-text float-left">
            <v-icon class="material-icons pr-3">send</v-icon>
            <span>Ready to send to HS</span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div v-if="isSecondarySchool">
          <read-only-field
            label="Current Address (ERN)"
            v-model="ernAddress"
            readonly
          />
        </div>
        <div v-else>
          <read-only-field
            label="Local high school"
            v-model="schoolName"
            readonly
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="isSecondarySchool">
      <v-col cols="12" md="4">
        <read-only-field
          label="Submitted EOI Date"
          v-model="dateReceived"
          :formatter="displayDate"
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <read-only-field
          label="Current address start date (ERN)"
          v-model="startDate"
          :formatter="displayDate"
          readonly
        />
      </v-col>
      <v-col cols="12" md="4">
        <read-only-field
          label="Local high school"
          v-model="schoolName"
          readonly
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import validators from '@/helpers/validators'
import formatters from '@/helpers/formatters'

import ReadOnlyField from '@/components/form/ReadOnlyField'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { DATASET, STREAM } from '@/constants'

export default {
  name: 'LocationDetails',
  components: {
    ReadOnlyField,
    AdsButton
  },
  data() {
    return {
      validators: {
        required: validators.required
      },
      displayDate: formatters.displayDate
    }
  },
  computed: {
    ...mapGetters([
      'application',
      'selectedSchool',
      'isAddressConflict',
      'readyToSendErn',
      'currentStream',
      'referenceData'
    ]),
    addressFormatter() {
      return formatters.getAddressFormatter(
        this.referenceData(DATASET.COUNTRIES),
        this.referenceData(DATASET.STATES)
      )
    },
    applicationComment() {
      return this.application?.comment
    },
    hadAddressConflict() {
      return this.applicationComment?.find(
        (event) => event.eventName === 'AddressConflictResolved'
      )
        ? true
        : false
    },
    residentialAddress() {
      return this.addressFormatter(this.application.residentialAddress)
    },
    ernAddress() {
      if (this.application.ernAddress) {
        return this.addressFormatter(this.application.ernAddress)
      }
      return null
    },
    schoolName() {
      return this.application.localHighSchool?.schoolName
    },
    isSecondarySchool() {
      if (this.currentStream === STREAM.Y67T_SECONDARY) {
        return true
      } else if (
        this.currentStream === STREAM.ARCHIVE &&
        !this.$route.params.primary
      ) {
        return true
      }
      return false
    },
    isArchived() {
      return this.currentStream === STREAM.ARCHIVE
    },
    startDate() {
      if (this.application.ernAddress) {
        return this.application.ernAddress?.startDate
      }
      return null
    },
    dateReceived() {
      if (this.application.dateReceived) {
        return this.application.dateReceived.slice(0, 10)
      }
      return null
    },
    isReadyToSendErn() {
      return (
        this.application.student.SRN &&
        this.readyToSendErn &&
        this.isAddressConflict === false
      )
    }
  },
  methods: {
    resolveConflict() {
      this.$store.commit('setShowConfirmAddress', true)
    }
  }
}
</script>
<style scoped lang="scss">
.address-conflict-bg {
  background-color: $grey-light-10;
  .address-conflict {
    color: $color-error-dark;
    .v-icon {
      color: $color-error-dark;
      margin-top: -3px;
    }
    .conflict-text {
      padding-top: 20px;
    }
  }
  .ready-to-sent-ern {
    color: $color-warning-dark;
    .v-icon {
      color: $color-warning-dark;
      margin-top: -3px;
    }
    .sent-ern-text {
      padding-top: 20px;
    }
  }
}
</style>
