<template>
  <div ref="studentAddress">
    <div class="content">
      <div class="mainContent">
        <div class="card">
          <div class="pt-10">
            <template>
              <v-radio-group
                class="address-options unstyled"
                v-model="selectedRecord"
              >
                <div class="address-options-box pb-5">
                  <v-row>
                    <v-col cols="1">
                      <v-radio
                        :aria-label="'select use existing ERN address for this student'"
                        class="pt-4 ml-9"
                        :value="'existingaddress'"
                        data-testid="selected-existingaddress"
                        @click="setSelectedRecord('existingaddress')"
                      />
                    </v-col>
                    <v-col cols="11">
                      <div
                        class="address-options-label pt-4"
                        @click="setSelectedRecord('existingaddress')"
                      >
                        Use existing ERN address for this student
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">&nbsp;</v-col>
                    <v-col cols="10">
                      <div class="existing-address pt-4 pl-4">
                        <v-row>
                          <v-col cols="1">
                            <div class="ern-icon pt-1 pl-3">ERN</div>
                          </v-col>
                          <v-col cols="11">
                            <div class="ern-address pt-1 pl-2">
                              {{
                                this.convertToUpperCase(
                                  residentialAddress.addressLine1
                                )
                              }}
                              {{
                                this.convertToUpperCase(
                                  residentialAddress.suburbName
                                )
                              }}
                              {{
                                this.convertToUpperCase(
                                  residentialAddress.stateCode
                                )
                              }}
                              {{ residentialAddress.postCode }}
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </v-col>
                    <v-col cols="1">&nbsp;</v-col>
                  </v-row>
                </div>
                <div class="text-or pt-6 pb-6">
                  <v-row>
                    <v-col cols="12">
                      <div>OR</div>
                    </v-col>
                  </v-row>
                </div>
                <div class="address-options-box pb-5">
                  <v-row>
                    <v-col cols="1">
                      <v-radio
                        :aria-label="'select enter a new address for this student'"
                        class="pt-4 ml-9"
                        :value="'newaddress'"
                        data-testid="selected-newaddress"
                        @click="setSelectedRecord('newaddress')"
                      />
                    </v-col>
                    <v-col cols="10">
                      <div
                        class="address-options-label pt-4"
                        @click="setSelectedRecord('newaddress')"
                      >
                        Enter a new address for this student
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="1">&nbsp;</v-col>
                    <v-col cols="10">
                      <div class="pt-4">
                        <AddressLookupComponent
                          ref="addressFinder"
                          v-bind="$attrs"
                          hide-details
                          label="Student's home address"
                          placeholder="Enter student's address"
                          class="address-lookup"
                          :disabled="proceedDisabledStatus"
                          :no-matching-text="''"
                          :error-messages="errors"
                          :min-length-for-search="minLengthForSearch"
                          @update:search-input="handleSearchInput"
                          @update:address="handleAddressUpdated"
                          @update:options="handleOptions"
                        />
                        <div v-if="newAddressWarning" class="pt-5">
                          <div class="float-left">
                            <v-icon class="warning-icon"
                              >mdi-alert-outline</v-icon
                            >
                          </div>
                          <div class="float-left pl-1 note-text">
                            <strong> Note:</strong> Entering a new address will
                            update this student’s address in ERN
                          </div>
                        </div>
                        <div v-if="newAddressError" class="error-message pt-5">
                          <div class="float-left">
                            <font-awesome-icon
                              focusable="false"
                              icon="exclamation-circle"
                              class="exclamationCircle mt-1 fa-lg ml-1 mr-1"
                            />
                          </div>
                          <div
                            class="float-left paddingText"
                            v-if="newAddressError"
                            v-html="newAddressError"
                          ></div>
                        </div>
                        <div aria-live="polite" class="sr-only">
                          <span v-if="newAddressWarning"
                            >Note: Entering a new address will update this
                            student’s address in ERN.</span
                          >
                          <span
                            v-if="newAddressError"
                            v-html="newAddressError"
                          ></span>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="1">&nbsp;</v-col>
                  </v-row>
                </div>
                <div class="pt-6 pb-6 no-cursor">
                  <v-row>
                    <v-col cols="12">
                      <div class="pt-1 float-right">
                        <AdsButton
                          class="button"
                          :disabled="isOkButtonDisabled"
                          @click="proceed"
                          button-text="Proceed"
                          icon="mdi-arrow-right"
                          data-testid="proceed-button"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-radio-group>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddressLookupComponent from '@/components/form/AddressLookupComponent'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { mapGetters } from 'vuex'

const EVENTS = {
  UPDATE_ADDRESS: 'update:address',
  PROCEED: 'proceed'
}

export default {
  name: 'StudentAddressLookup',
  components: {
    AddressLookupComponent,
    AdsButton
  },
  data() {
    return {
      selectedRecord: null,
      selectedOldRecord: null,
      proceedDisabledStatus: true,
      selectedAddress: null,
      addressSearchInput: null,
      addressSearchOptions: null,
      newAddressWarning: false,
      newAddressError: null,
      minLengthForSearch: 4,
      errors: [],
      count: 0
    }
  },
  computed: {
    ...mapGetters([
      'eoiApplication',
      'referenceData',
      'contactTypes',
      'predictiveSchools',
      'school',
      'allStates'
    ]),
    residentialAddress() {
      return this.eoiApplication.residentialAddress
    },
    isOkButtonDisabled() {
      return !(
        this.selectedRecord &&
        this.eoiApplication.localHighSchool &&
        (this.selectedRecord === 'existingaddress' || this.selectedAddress)
      )
    },
    selectedSchool() {
      return this.$store.state.selectedSchool
    },
    apiErrorMessage() {
      return 'Sorry, we can’t retrieve your address at the moment. Please try again later.'
    },
    noAddressMessage() {
      return `Sorry, we can’t retrieve your address at the moment. Please verify the address details and try again later.`
    },
    unableToFindAddresses() {
      return (
        this.addressSearchInput &&
        this.addressSearchInput.length >= this.minLengthForSearch &&
        !(this.addressSearchOptions.length > 0)
      )
    },
    catchmentAreaMessage() {
      return 'Address entered is not in the catchment area of any NSW public high school.<br />Student must be a NSW resident to be assessed online.'
    },
    sameAddressMessage() {
      return `This address is the same as the student's current address in ERN.<br />Please enter a new address or select Use existing ERN address radio option.`
    }
  },
  methods: {
    addError(error) {
      this.errors = [...this.errors, error]
    },
    clearErrors() {
      this.errors = []
    },
    setSelectedRecord(record) {
      this.$emit('click', record)
      this.count++
      if (this.selectedOldRecord === record) {
        return false
      }
      this.selectedOldRecord = this.selectedRecord = record
      this.clearErrors()
      this.$refs.addressFinder.clearInput()
      if (this.selectedRecord === 'newaddress') {
        this.proceedDisabledStatus = false
        this.addressSearchInput = null
        this.selectedAddress = null
      } else if (this.selectedRecord === 'existingaddress' && this.count > 1) {
        this.$store.dispatch('getLocalHighSchoolForAddress')
      } else {
        this.proceedDisabledStatus = true
        this.newAddressWarning = false
        this.newAddressError = null
        this.addressSearchInput = null
      }
    },
    async handleAddressUpdated(addressObject) {
      if (addressObject) {
        await this.$store.dispatch('getLocalHighSchool', [
          addressObject.longitude,
          addressObject.latitude
        ])
      }
      if (this.eoiApplication.localHighSchool && addressObject) {
        if (
          this.eoiApplication.residentialAddress.addressId ===
          addressObject.addressId
        ) {
          this.eoiApplication.localHighSchool = null
          this.addError(this.sameAddressMessage)
          this.newAddressError = this.sameAddressMessage
          this.newAddressWarning = false
        } else {
          this.newAddressError = null
          this.newAddressWarning = true
        }
      } else {
        this.addError(this.catchmentAreaMessage)
        this.newAddressError = this.catchmentAreaMessage
        this.newAddressWarning = false
      }
      this.selectedAddress = addressObject
      this.$emit(EVENTS.UPDATE_ADDRESS, { ...addressObject })
    },
    convertToUpperCase(value) {
      return value ? value.toUpperCase() : value
    },
    handleSearchInput(input) {
      if (this.addressSearchInput !== input || input == null) {
        this.clearErrors()
        this.addressSearchInput = input
      }
      this.selectedAddress = null
      this.newAddressError = null
      this.newAddressWarning = false
    },
    handleOptions(options) {
      this.addressSearchOptions = options
      if (this.unableToFindAddresses) {
        this.showUnableToFindAddressError(this.noAddressMessage)
      }
    },
    handleLoadingSuggestions(isBadRequest) {
      if (isBadRequest) {
        this.addError(this.apiErrorMessage)
        this.newAddressError = this.apiErrorMessage
      }
    },
    showUnableToFindAddressError(errorMessage) {
      this.addError(errorMessage)
      this.newAddressError = errorMessage
    },
    proceed() {
      if (this.selectedRecord === 'newaddress') {
        this.eoiApplication.residentialAddress = this.selectedAddress
        this.eoiApplication.residentialAddressUpdated = true
        this.$store.dispatch('setEoIApplication', this.eoiApplication)
      }
      this.$emit(EVENTS.PROCEED)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-input label.v-label {
  color: $ads-navy;
}
::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
  border: 2px solid $ads-navy;
  background: none !important;
}
::v-deep .v-input--selection-controls__input {
  .v-icon {
    color: $grey-darken1;
  }
  .v-input--selection-controls__ripple:before {
    opacity: 0;
  }
}
::v-deep .v-autocomplete .v-icon.v-icon--link:focus {
  border: 3px solid $color-primary;
  border-radius: 50%;
}
::v-deep .error-message-bold {
  color: $color-error-dark;
  font-weight: bold;
}
::v-deep .theme--light.v-icon:focus::after {
  opacity: 0;
}
::v-deep .v-autocomplete .v-icon.v-icon--link.error--text:focus {
  border: 3px solid $color-error-dark;
  border-radius: 50%;
}
.address-options {
  margin: auto;
  width: 70%;
  color: $ads-navy;

  .address-options-box {
    font-family: $digitize-eoi-font-family;
    background-color: white;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    cursor: default;
    .address-options-label {
      font-size: 22px;
      font-weight: bold !important;
      line-height: 26px;
      cursor: pointer;
    }
  }
  .existing-address {
    background-color: $grey-light-10;
    height: 79px;
    border-radius: 4px;

    .ern-icon {
      width: 55px;
      height: 23px;
      background: white;
      border: 1px solid $color-dark70;
      box-sizing: border-box;
      border-radius: 20px;
      font-size: 13px;
      color: $color-dark70;
      font-weight: 700;
      font-family: Arial;
    }
    .ern-address {
      font-family: Arial;
    }
  }
  .text-or {
    text-align: center !important;
    font-weight: 700;
    line-height: 26px;
    font-size: 20px;
    cursor: default;
  }
  .warning-icon {
    color: $color-warning-dark !important;
  }
  .v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .v-btn:not(.v-btn--round):not(.v-btn--outlined):not(.v-btn--text) {
    font-family: $digitize-eoi-font-family;
  }
  .error-message {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $color-error-dark;
  }
  .note-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .paddingText {
    padding-top: 1px;
    padding-left: 3px;
    width: 95%;
  }
  .no-cursor {
    cursor: default;
  }
}
</style>
