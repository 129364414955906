<template>
  <!--
  FUS-1813: Changes to eslint rules now disallow the mutation of
  component props. The error for the next block has been disabled
  as the component is currently working.
  -->
  <!-- eslint-disable vue/no-mutating-props -->
  <v-dialog
    v-if="showBulkOfferModal"
    v-model="showBulkOfferModal"
    max-width="670"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <!-- eslint-enable vue/no-mutating-props -->
    <v-card class="bulk-offer-modal">
      <v-btn
        class="modal__close-button float-right"
        :ripple="false"
        icon
        aria-label="close"
        @click="close"
        @keydown.esc="close"
        ref="closeBtn"
      >
        <v-icon> close </v-icon>
      </v-btn>

      <div class="header d-flex mb-3">
        <div class="icon iconCircleBackground mr-3">
          <v-icon color="primary">mdi-school-outline</v-icon>
        </div>
        <div class="align-self-center">
          <h1
            role="alert"
            class="heading align-self-center"
            data-testid="heading"
          >
            <span v-if="step === 1"> Bulk offers </span>
            <span v-if="step === 2">
              <span v-if="singleApp"
                >Send enrolment offer to {{ studentName }}?</span
              >
              <span v-else
                >Send enrolment offers to
                {{
                  localAppsCount > 0 && applicationType === 'local'
                    ? `${localAppsCount} Local`
                    : `${ooaAppsCount} Out-of-area`
                }}
                students?</span
              >
            </span>
            <span v-if="step === 3">
              Enrolment {{ singleApp ? 'offer' : 'offers' }} sent
              <span class="sr-only" v-if="counts.success > 0">
                {{ counts.success }}/{{ counts.total }}
                {{ applicationType === 'local' ? 'Local' : 'Out-of-area' }}
                students have been offered a place and emails sent to parents.
                {{
                  applicationType === 'local' || singleApp
                    ? ''
                    : ' If there are paper EOIs please make sure to send them appropriate communication.'
                }}
              </span>
              <span class="sr-only">
                {{
                  counts.failure > 0
                    ? `${counts.failure} student offers failed to process. Please try again.`
                    : ''
                }}
              </span>
            </span>
          </h1>
        </div>
      </div>
      <div v-if="step === 1">
        <div>
          <div class="pt-1">
            <span class="subheading">
              Select which students you would like to send enrolment offers to:
            </span>
          </div>
          <div>
            <template>
              <v-radio-group
                v-model="applicationType"
                class="digitise-options unstyled"
              >
                <div
                  v-for="(record, index) in records"
                  class="selectedRecordPanel"
                  :class="isSelected(record.value)"
                  :key="index"
                  @click="setSelectedApplicationType(record.value)"
                >
                  <v-row>
                    <v-col cols="1">
                      <v-radio
                        aria-live="polite"
                        :aria-label="ariaLabel(record)"
                        class="pt-8 ml-3"
                        :value="record.value"
                      />
                    </v-col>
                    <v-col cols="10" id="radio-point">
                      <div>
                        <h3 class="mb-1 mt-1 heading">{{ record.text }}</h3>
                      </div>
                      <div>
                        <p class="subheading">
                          <strong>
                            {{
                              record.text === 'Local offer'
                                ? localAppsCount
                                : ooaAppsCount
                            }}
                          </strong>
                          {{ record.subtitle }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-radio-group>
            </template>
          </div>
        </div>
      </div>
      <div v-if="step === 2">
        <Alert
          v-if="overCap && this.applicationType === 'outOfArea'"
          data-testid="overCapAlert"
          in-page
          type="warning"
          alert-class="offer-note"
          html-text="<span class='subtext'><b>Note:</b> DEL approval is required before offering a place as your </br> school is currently over cap.</span>"
        />
        <div
          class="email-template-container"
          :class="!overCap ? 'mt-5' : 'mt-0'"
          data-testid="emailContainer"
        >
          <p class="mb-5">
            The status of
            {{ singleApp ? 'this application' : 'these applications' }} will be
            set to &#8220;Offered&#8221;.
          </p>
          <p class="mb-0">
            The {{ singleApp ? 'parent/carer(s)' : 'parents/carers' }} will be
            emailed instructions for accepting the offer and proceeding with a
            full enrolment application.
            <a :href="this.y67tEmailLink" target="_blank"
              >View email template(s)</a
            >
          </p>
        </div>
        <AdsTextArea
          class="mt-10"
          v-model="staffComment"
          label="Comments (school use only - optional)"
          :placeholder="
            singleApp
              ? 'You can write the reason for your decision here. This information will not be shared with the parent/carer(s).'
              : note
          "
          rows="5"
          maxlength="200"
          counter="200"
          persistent-placeholder
        />
        <SupportingDocumentUploadY67T
          class="mb-10"
          v-model="files"
          ref="supportingDocumentUploady67t"
          :is-bulk-offer-action="true"
          :bulk-offer-id="bulkOfferId"
          :is-busy.sync="isFileOperationInProgress"
        />
      </div>
      <div v-if="step === 3" class="text-center">
        <div
          class="results-template-container"
          data-testid="resultsContainer"
          v-if="counts.success > 0"
        >
          <span class="success-numbers">
            <v-icon class="success-numbers mb-2 mr-1"
              >mdi-check-circle-outline</v-icon
            >
            <span class="mx-0">{{ counts.success }}/{{ counts.total }}</span>
          </span>
          <p v-if="singleApp">
            {{ studentName }} has been offered a place and email sent to
            parent(s).
          </p>
          <p v-else>
            {{ applicationType === 'local' ? 'Local' : 'Out-of-area' }} students
            have been offered a place <br />
            and emails sent to parents.
            {{
              applicationType === 'local'
                ? ''
                : ' If there are paper EOIs please make sure to send them appropriate communication.'
            }}
          </p>
        </div>
        <div
          class="mt-6"
          v-if="counts.failure > 0"
          :class="{ 'failure-message': counts.success === 0 }"
        >
          <span class="error-numbers">
            <v-icon class="error-icon mb-1 mr-1">error_outline</v-icon>
            {{ counts.failure }}
            student offer(s) failed to process. Please try again.
          </span>
        </div>
      </div>
      <div class="actions">
        <div v-if="step === 1">
          <AdsButton
            tertiary
            class="button"
            button-text="Cancel"
            @click="close"
            data-testid="cancel-btn"
          />
          <AdsButton
            data-testid="continue-btn"
            :ripple="false"
            icon="mdi-arrow-right"
            :disabled="!this.applicationType"
            button-text="Continue"
            @click="nextStep"
          />
        </div>
        <div v-if="step === 2">
          <AdsButton
            class="button"
            tertiary
            button-text="Cancel"
            @click="close"
            data-testid="cancel-btn"
          />
          <AdsButton
            icon="school"
            :ripple="false"
            class="button success"
            :button-text="
              singleApp ? 'Confirm and send offer' : 'Confirm and send offers'
            "
            data-testid="confirm-btn"
            @click="bulkOfferConfirm"
            :disabled="isFileOperationInProgress"
          />
        </div>
      </div>
      <div class="final-action">
        <div
          v-if="step === 3"
          :class="{ 'only-failure-button': counts.success === 0 }"
        >
          <AdsButton
            class="button"
            tertiary
            button-text="Close window"
            @click="close"
            data-testid="close-btn"
          />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton, AdsTextArea, Alert } from '@nswdoe/doe-ui-core'
import UTILS from '@/store/utils'
import { mapGetters } from 'vuex'
import SupportingDocumentUploadY67T from '@/components/application/y67t/SupportingDocumentUploadY67T'
import moment from 'moment'
export default {
  name: 'Y67TBulkOfferModal',
  components: { AdsButton, SupportingDocumentUploadY67T, AdsTextArea, Alert },
  props: {
    applications: {
      type: Array
    },
    showBulkOfferModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      step: 1,
      applicationType: null,
      attachments: [],
      records: [
        {
          text: 'Local offer',
          subtitle: `Local student(s) will be offered a place`,
          value: 'local'
        },
        {
          text: 'Out-of-area offer',
          subtitle: `Out-of-area student(s) will be offered a place`,
          value: 'outOfArea'
        }
      ],
      counts: {
        success: 0,
        failure: 0,
        total: 0
      },
      files: [],
      staffComment: '',
      isFileOperationInProgress: false,
      bulkOfferId: null
    }
  },
  computed: {
    ...mapGetters(['y67tEmailLink', 'selectedSchool', 'overCap']),
    localAppsCount() {
      return this.applications.filter((a) => a.isLocal).length
    },
    ooaAppsCount() {
      return this.applications.filter((a) => !a.isLocal).length
    },
    singleApp() {
      return this.localAppsCount === 1 && this.applicationType === 'local'
        ? this.applications.filter((a) => a.isLocal)
        : this.ooaAppsCount === 1 && this.applicationType === 'outOfArea'
        ? this.applications.filter((a) => !a.isLocal)
        : false
    },
    studentName() {
      return `${this.singleApp[0]?.studentName}`
    },
    note() {
      return `You can write the reason for your decision here. This information will not be shared with the ${
        this.singleApp ? 'parent/carer(s)' : 'parents/carers'
      }.
Note: This information will appear on all ${
        this.applicationType === 'local'
          ? this.localAppsCount
          : this.ooaAppsCount
      } student records in OES.`
    }
  },
  methods: {
    bulkOfferConfirm() {
      const applications =
        this.applicationType === 'local'
          ? this.applications.filter((a) => a.isLocal === true)
          : this.applications.filter((a) => a.isLocal === false)
      const eoiIds = applications.map((a) => a.applicationID)
      this.$store.dispatch('showSpinner')
      this.$store
        .dispatch('bulkOfferY67T', {
          comment: UTILS.sanitize(this.staffComment),
          attachments: this.files,
          bulkOfferId: this.bulkOfferId,
          eoiIds: eoiIds
        })
        .then((response) => {
          const responses = response.data.body
          this.counts.total = eoiIds?.length
          this.counts.success = responses.filter(
            (r) => r.status === 200
          )?.length
          this.counts.failure = responses.filter(
            (r) => r.status !== 200
          )?.length
          this.$store.dispatch('hideSpinner')
          return (this.step = this.step + 1)
        })
    },
    close() {
      this.applicationType = null
      this.staffComment = ''
      this.$emit('close')
      if (this.counts.total > 0) {
        this.$emit('refresh')
      }
      if (this.files) {
        this.files = []
        this.$refs.supportingDocumentUploady67t?.deleteAll()
      }
      for (
        let i = this.$refs.supportingDocumentUploady67t?._data.alerts?.length;
        i >= 0;
        i--
      ) {
        this.$refs?.supportingDocumentUploady67t?.closeError(i)
      }
    },
    nextStep() {
      return (this.step = this.step + 1)
    },
    setSelectedApplicationType(type) {
      this.applicationType = type
    },
    isSelected(record) {
      if (record === this.applicationType) {
        return 'isTicked'
      }
      return ''
    },
    ariaLabel(record) {
      return record.value === 'local'
        ? 'select' + record.text + this.localAppsCount + record.subtitle
        : 'select' + record.text + this.ooaAppsCount + record.subtitle
    }
  },
  created() {
    this.localAppsCount === 0 || this.ooaAppsCount === 0
      ? (this.step = 2)
      : (this.step = 1)
    if (this.step === 2) {
      this.localAppsCount === 0
        ? (this.applicationType = 'outOfArea')
        : (this.applicationType = 'local')
    }
    this.bulkOfferId =
      this.selectedSchool?.schoolCode +
      '-' +
      moment().format('YYYYMMDDHHmmssSSS')
  },
  mounted() {
    this.$store.dispatch('getOverCap', this.selectedSchool?.schoolCode)
  }
}
</script>

<style scoped lang="scss">
.heading {
  font-size: 20px;
  padding: 10px 20px 0 0;
  color: $ads-primary-blue;
  line-height: 1.4 !important;
  display: inline;
}
.subheading {
  color: $ads-grey-01;
  font-size: 16px;
  line-height: 24px;
}
.results-template-container {
  background-color: rgba(168, 237, 179, 0.6);
  line-height: 1.5;
  padding: 26px;
  border-radius: 4px;
  margin: 40px 30px 0 30px;
  .success-numbers {
    font-size: 56px;
    font-weight: 700;
    color: $ads-success-green;
  }
}
#radio-point {
  margin-top: 16px;
}
.error-numbers {
  color: $ads-error-red;
  font-size: 16px;
  font-weight: 700;
  .error-icon {
    font-size: 27px;
    color: $ads-error-red;
  }
}
.email-template-container {
  background-color: rgba(203, 237, 253, 0.5);
  line-height: 1.5;
  padding: 20px;
  border-radius: 4px;
  a {
    text-decoration: underline;
    color: $ads-primary-blue;
    font-weight: 400;
  }
  a:focus {
    outline: $ads-primary-blue 2px solid;
    border-radius: 2px;
  }
}

.divider {
  margin: 28px 0px;
  color: $ads-light-40;
  border-width: 1px;
}
.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  .theme--light.v-btn {
    padding: 20px 20px !important;
  }
}
.final-action {
  display: flex;
  justify-content: center;
}
.v-text-field.v-textarea {
  margin-left: 0px;
}
.icon {
  width: 45px;
  height: 45px;
  border-radius: 25px;
  background-color: $ads-primary-teal !important;
}
.bulk-offer-modal {
  padding: 1.7rem;
  min-height: 485px;
  position: relative;

  .theme--light.button.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
      .v-btn--text
    ).primary:not(.app-settings__save-button) {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .modal__close-button {
    position: inherit;
    border: none;
  }
  .modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
    color: $grey-darken1 !important;
    &:focus {
      color: $ads-primary-blue !important;
      border: 3px solid $ads-primary-blue !important;
    }
  }
  ::v-deep {
    .theme--light.v-btn.v-btn--disabled:not(.v-btn--text):not(
        .v-btn--outlined
      ) {
      background-color: $ads-dark-60 !important;
      color: $ads-white !important;
    }
    textarea {
      padding: 10px 3px;
    }
    .subtext {
      font-weight: 500 !important;
    }
    .v-alert__icon.v-icon {
      margin-top: 7px;
      font-size: 30px;
    }
    .offer-note {
      margin: 20px 0px;
      line-height: 1.5;
      background-color: rgba(255, 206, 153, 0.4) !important;
      box-shadow: none !important;
      border: 2px solid #fff;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
    .offer-note:focus {
      border: 2px solid $ads-primary-blue;
      border-radius: 4px;
    }
  }
  .theme--light.v-btn.v-btn--text:focus {
    border: 2px solid $ads-primary-blue !important;
  }
}

.only-failure-button {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.failure-message {
  position: absolute;
  top: 40%;
  left: 18%;
}

::v-deep .theme--light.v-btn.v-btn--disabled .v-icon {
  color: #fff !important;
}

.v-application .primary--text {
  color: $ads-primary-blue !important;
}
.v-application .success {
  background-color: $ads-success-green !important;
}
.digitise-options {
  width: 100%;
  border-radius: 0.25em;
  position: relative;
  &[disabled] {
    opacity: 1;
    background: transparent;
    cursor: default;
  }
  .selectedRecordPanel {
    background-color: white;
    height: 118px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid $ads-grey-03;
    box-sizing: border-box;
    font-size: 18px;
  }
  .isTicked {
    background-color: $ads-primary-teal !important;
    color: $ads-primary-blue;
    border: 2px solid $color-primary;
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
    background: none;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      color: $grey-darken1;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
}
</style>
