import UTILS from '@/store/utils'

export default {
  set(state, [key, value]) {
    // Sets a value in state. "key" must specify the full object hierarchy, e.g.
    // "application.parentCarers[1].contactDetails[2].contactValue"
    if (state) {
      try {
        eval(`state.${key} = value`)
      } catch (e) {
        UTILS.log(`FAILED TO SET STATE: ${key} = "${value}"`)
      }
    }
  }
}
