<template>
  <v-radio-group
    ref="inputRef"
    class="radio-group"
    v-bind="$attrs"
    :label="label"
    v-on="$listeners"
    @change="$emit('input', $event)"
  >
    <v-radio
      v-for="item in items"
      :key="item.value"
      class="radio-group__button"
      v-bind="item"
      @blur="$emit('radioblur', $event)"
    />
    <slot />
  </v-radio-group>
</template>

<script>
export default {
  name: 'RadioGroup',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    label: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group {
  ::v-deep label.v-label {
    font-weight: normal;
    color: $ads-navy;
  }
  ::v-deep .v-input--selection-controls__input {
    .v-icon {
      //   color: $ads-selection-control-gray;
    }
    .v-input--selection-controls__ripple:before {
      opacity: 0;
    }
  }
  ::v-deep .v-radio.v-radio--is-focused .v-input--selection-controls__ripple {
    border: 2px solid $ads-navy;
  }
}
</style>
