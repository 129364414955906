<template>
  <div class="px-15 pt-7 pb-9">
    <h3 class="mb-6" v-if="title">{{ title }}</h3>
    <div class="d-flex flex-wrap">
      <template v-for="([key, value], i) in Object.entries(itemObject)">
        <div
          v-if="showField(value)"
          :class="[flexBasisClass, `rofl--margin-${columns}`]"
          :key="i"
        >
          <ReadOnlyField
            :label="key"
            :value="value"
            :formatter="formatter"
            :readonly="true"
          /></div
      ></template>
    </div>
  </div>
</template>

<script>
import ReadOnlyField from '@/components/form/ReadOnlyField.vue'

export default {
  name: 'ReadOnlyFieldList',
  components: { ReadOnlyField },
  props: {
    itemObject: {
      type: Object,
      required: true
    },
    invisibleFieldOnEmpty: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Number,
      default: 2
    }
  },
  computed: {
    flexBasisClass() {
      switch (this.columns) {
        case 1:
          return 'flex-basis-100'
        case 3:
          return 'flex-basis-1-3'
        default:
          return 'flex-basis-50'
      }
    }
  },
  methods: {
    showField(value) {
      return this.invisibleFieldOnEmpty ? Boolean(value) : true
    },
    formatter(value) {
      return value || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.flex-basis-50 {
  flex-basis: 50%;
}
.flex-basis-1-3 {
  flex-basis: 33.3333%;
}
.flex-basis-100 {
  flex-basis: 100%;
}
.rofl--margin {
  &-2 {
    margin-top: 28px;
    &:nth-of-type(-n + 2) {
      margin-top: 0;
    }
  }
  &-3 {
    margin-top: 28px;
    &:nth-of-type(-n + 3) {
      margin-top: 0;
    }
  }
}
::v-deep {
  .displayField.readonly {
    &,
    & label {
      cursor: unset;
    }
  }
}
</style>
