// ensure babel-polyfill is first import so we dont get failures in ie11 when vue + vuetify loads. See APO-939.
import 'core-js/stable'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import addGlyphs from './fontAwesome'
import cssVars from 'css-vars-ponyfill'
import store from './store/store'
import vuetify from './plugins/vuetify'
import VueGtm from '@gtm-support/vue2-gtm'
import OAuth from './plugins/OAuth/OAuth'
import ActivityMonitor from './plugins/ActivityMonitor'
import { NODE_ENVIRONMENTS } from './constants'
import AppSettingsModal from '@/components/app/AppSettingsModal'
import vuemoment from 'vue-moment'
import buffer from 'buffer'

addGlyphs()

//calling cssVars ponyfill to provide client-side support for CSS custom properties (aka "CSS variables") in legacy and modern browsers.
cssVars({
  //more options can be added here, more details is at https://jhildenbiddle.github.io/css-vars-ponyfill/#/
})

window.Buffer = window.buffer || buffer.Buffer

const gtmQueryParams =
  process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.PROD
    ? {}
    : {
        gtm_auth: 'gHJuiIy-8FVxEUUgxo944A',
        gtm_preview: 'env-3',
        gtm_cookies_win: 'x'
      }

// Adds Google Tag Manager tracking
Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  debug:
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.LOCAL ||
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.DEV ||
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.TEST ||
    process.env.VUE_APP_ENV_NAME === NODE_ENVIRONMENTS.PREPROD,
  vueRouter: router,
  queryParams: gtmQueryParams
})

Vue.use(vuemoment) // Adds MomentJS date handling utilities
Vue.config.productionTip = false

Vue.use(ActivityMonitor, {
  events: ['keydown', 'mousedown'],
  inactivityTimeout:
    (process.env.VUE_APP_ACTIVITY_TIMEOUT_MINUTES -
      process.env.VUE_APP_ACTIVITY_TIMEOUT_WARNING_MINUTES) *
    60000,
  throttlerTimeout: 1000,
  onInactiveUser: () => {
    store.dispatch('beginFinalInactivityTimer')
  }
})

Vue.use(OAuth, {
  router,
  authProviderConfig: {
    DET: {
      urls: {
        authorise: process.env.VUE_APP_DET_AUTH_URI,
        token: process.env.VUE_APP_DET_TOKEN_URI
      },
      params: {
        client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
        response_type: 'code',
        scope: 'openid'
      }
    }
  },
  goToRouteOnRedirect: true,
  appStateToPersist: () => ({
    selectedSchool: store.state.selectedSchool
  }),
  storagePrefix: 'si.auth',
  onAuthorisedRedirect: (tokens, persistedState) => {
    sessionStorage.setItem('accessToken', tokens.access)
    sessionStorage.setItem('idToken', tokens.id)
    sessionStorage.setItem('refreshToken', tokens.refresh)
    store.commit('set', ['selectedSchool', persistedState.selectedSchool])
    store.dispatch('setCurrentUser')
  },
  onRedirectError: () => {}
})

Vue.component('AppSettingsModal', AppSettingsModal)

Vue.prototype.$eventHub = new Vue()

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
