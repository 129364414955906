<template>
  <v-btn text small class="clear-filters pl-2 ml-3" v-on="$listeners">
    <v-icon small class="mr-1">close</v-icon>
    Clear filters
  </v-btn>
</template>

<script>
export default {
  name: 'ClearFilters'
}
</script>
<style lang="scss" scoped>
.clear-filters {
  &.v-btn {
    font-size: 1rem;
    color: $ads-navy;
    border: 1px solid transparent;
    &:active,
    &:focus {
      border: 2px solid $color-primary;
    }
  }
}
</style>
