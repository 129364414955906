import API from '@/store/apiUtils'
import UTIL from '@/store/utils'

export function generateIdentifier(schoolCode, notification) {
  const { uuid, id } = notification
  return `${schoolCode}:${uuid}:${id}`
}

export function filterActiveNotifications(
  notifications,
  dismissedData,
  schoolCode
) {
  return notifications.filter((n) => {
    const id = generateIdentifier(schoolCode, n)
    return !dismissedData.includes(id)
  })
}

export function getDismissedData(localStorageKey) {
  return (
    JSON.parse(localStorage.getItem(localStorageKey)) ?? {
      version: '1',
      release: '8.1.0',
      items: []
    }
  )
}

export function setDismissedData(localStorageKey, newValue) {
  localStorage.setItem(localStorageKey, JSON.stringify(newValue))
}

export function purgeExpiredNotifications(
  fetchedItems,
  dismissedData,
  schoolCode
) {
  let purgeItems = false
  dismissedData.items
    .filter((dI) => dI.startsWith(schoolCode))
    .forEach((dI) => {
      let foundInApiResponse = false
      fetchedItems.forEach((fI) => {
        if (generateIdentifier(schoolCode, fI) === dI) {
          foundInApiResponse = true
        }
      })
      if (!foundInApiResponse) {
        purgeItems = true
        const idx = dismissedData.items.indexOf(dI)
        if (idx !== -1) {
          dismissedData.items.splice(idx, 1)
        }
      }
    })
  return [purgeItems, dismissedData]
}

const notifications = {
  state: () => ({
    notifications: [],
    notificationsLoading: false
  }),
  getters: {
    notifications: (state) => state.notifications,
    notificationsLoading: (state) => state.notificationsLoading,
    localStorageKey: (_state, _getters, rootState) =>
      `dismissedNotifications:${rootState.userID}`
  },
  mutations: {
    setNotifications(state, notifications) {
      state.notifications = notifications
    },
    setNotificationsLoading(state, loading) {
      state.notificationsLoading = loading
    }
  },
  actions: {
    resetNotifications({ commit }) {
      commit('setNotifications', [])
    },
    dismissNotification({ commit, getters, rootGetters }, notification) {
      const dismissedData = getDismissedData(getters.localStorageKey)
      const { schoolCode } = rootGetters.selectedSchool
      dismissedData.items.push(generateIdentifier(schoolCode, notification))
      setDismissedData(getters.localStorageKey, dismissedData)
      const notifications = filterActiveNotifications(
        getters.notifications,
        dismissedData.items,
        schoolCode
      )
      commit('setNotifications', notifications)
    },
    async fetchNotifications({ commit, getters, rootGetters }) {
      commit('setNotificationsLoading', true)

      const notifications = []

      const { schoolCode } = rootGetters.selectedSchool

      if (!schoolCode || !schoolCode.match(/[0-9]{4}/)) {
        UTIL.log('Aborting fetchNotifications(), invalid school code')
        commit('setNotifications', notifications)
        return
      }

      let dismissedData = getDismissedData(getters.localStorageKey)

      const apiUrl = `${process.env.VUE_APP_SMI_API}/v1/admin/smi/alerts/${schoolCode}/customNotifications`
      const headers = {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        'x-id-token': sessionStorage.getItem('idToken'),
        schoolCode
      }

      return API.get(apiUrl, true, headers, null, true)
        .then((response) => {
          const { data, status } = response
          const { body: fetchedItems } = data

          if (status !== 200 || data.status !== 200) {
            UTIL.log('Unable to fetch notifications, response status !== 200')
            return
          }

          const [itemsWerePurged, sanitisedDismissedData] =
            purgeExpiredNotifications(fetchedItems, dismissedData, schoolCode)
          if (itemsWerePurged) {
            setDismissedData(getters.localStorageKey, sanitisedDismissedData)
          }

          notifications.push(
            ...filterActiveNotifications(
              fetchedItems,
              sanitisedDismissedData.items,
              schoolCode
            )
          )
        })
        .catch(() => {
          UTIL.log('Unable to fetch notifications, API.get() was rejected.')
        })
        .finally(() => {
          commit('setNotifications', notifications)
          commit('setNotificationsLoading', false)
        })
    }
  }
}

export default notifications
