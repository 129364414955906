<template>
  <div class="ads-style" v-show="this.initialised">
    <!-- v-on:submit.prevent handles submit on pressing the Enter key -->
    <v-form ref="y67tPnrForm" @submit.prevent="save()">
      <ads-expansion-panel :items="panels" multiple v-model="openPanels">
        <template slot="contentStudentDetails">
          <v-form
            ref="studentDetailsForm"
            v-model="studentValid"
            @submit.prevent="save()"
            validation-section-name="Student details"
          >
            <student-details :readonly="isReadOnly" />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="sumbit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentFlags">
          <flags />
        </template>
        <template slot="contentWalkups">
          <walkups />
        </template>
        <template slot="contentParentCarers">
          <v-form
            ref="parentCarerForm"
            v-model="parentCarerValid"
            @submit.prevent="save()"
            validation-section-name="Parent/carer details"
          >
            <parent-carers :readonly="isReadOnly" />
            <!-- Form needs a submit button for Enter to submit functionality -->
            <button type="sumbit" style="display: none" />
          </v-form>
        </template>
        <template slot="contentLocationDetails">
          <pnr-location-details v-if="hasLocationDetails" />
        </template>
        <template slot="contentPlacementDetails">
          <placement-details />
        </template>
      </ads-expansion-panel>
      <!-- v-on:reset.prevent in the v-form wasn't working, so I have to handle it here -->
      <save-cancel v-if="isEditing" @cancel="cancel" />
    </v-form>
    <errors-dialog
      v-model="showErrors"
      :errors="errors"
      @close="closeErrorsDialog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core'
import { getFormErrors, focusOnFirstError } from '@/helpers/form'

import StudentDetails from './PnrStudentDetails.vue'
import ParentCarers from './ParentCarers.vue'
import PnrLocationDetails from './PnrLocationDetails.vue'
import PlacementDetails from './PlacementDetails.vue'
import Flags from './Flags.vue'
import Walkups from './Walkups.vue'

import ErrorsDialog from './ErrorsDialog.vue'
import SaveCancel from '@/components/form/SaveCancel'
import { STREAM, Y67T_EDITABLE_STATUSES } from '@/constants'

export default {
  name: 'PnrForm',
  components: {
    AdsExpansionPanel,
    SaveCancel,
    StudentDetails,
    ParentCarers,
    PnrLocationDetails,
    PlacementDetails,
    ErrorsDialog,
    Flags,
    Walkups
  },
  data() {
    return {
      studentValid: true,
      parentCarerValid: true,
      openPanels: [],
      initialised: false,
      errors: [],
      showErrors: false,
      residentialAddress: null
    }
  },
  computed: {
    ...mapGetters([
      'application',
      'isEditing',
      'referenceData',
      'isY67TApplicationReadOnly',
      'selectedSchool',
      'currentStream'
    ]),
    panels() {
      const errorProps = { headerClass: 'sectionError', icon: 'error_outline' }
      const sections = [
        {
          id: 'StudentDetails',
          title: 'Student details',
          ...(this.studentValid ? {} : errorProps)
        }
      ]
      if (this.isSecondarySchool) {
        sections.push(
          {
            id: 'Flags',
            title: 'Flags & tags'
          },
          {
            id: 'Walkups',
            title: 'Walkups'
          }
        )
      }
      sections.push({
        id: 'ParentCarers',
        title: 'Parent/carer details',
        ...(this.parentCarerValid ? {} : errorProps)
      })
      if (this.hasLocationDetails) {
        sections.push({
          id: 'LocationDetails',
          title: 'Location details'
        })
      }
      sections.push({
        id: 'PlacementDetails',
        title: 'Placement not required'
      })
      return sections
    },
    isSecondarySchool() {
      if (this.currentStream === STREAM.Y67T_SECONDARY) {
        return true
      } else if (
        this.currentStream === STREAM.ARCHIVE &&
        !this.$route.params.primary
      ) {
        return true
      }
      return false
    },
    isReadOnly() {
      return (
        Y67T_EDITABLE_STATUSES.indexOf(this.application.applicationStatus) ===
          -1 ||
        this.isSecondarySchool ||
        this.currentStream === STREAM.ARCHIVE
      )
    },
    hasLocationDetails() {
      return this.application.residentialAddress
    }
  },
  mounted() {
    // open and close all the panels to ensure they are rendered so we can run pre-validation
    this.openPanels = [...Array(this.panels.length).keys()]
    this.$nextTick(() => {
      this.openPanels = []

      // give the animation a chance to complete
      setTimeout(() => {
        this.initialised = true
      }, 100)
    })
  },
  methods: {
    save() {
      if (this.isY67TApplicationReadOnly) {
        return
      }
      this.validate()
      if (this.errors.length) {
        this.showErrors = true
      } else {
        this.$store.dispatch('saveY67TApplication')
      }
    },
    cancel() {
      this.$store.dispatch('cancelApplication')
    },
    stopEdit() {
      this.$store.commit('set', ['isEditing', false])
    },
    validate() {
      this.$refs.y67tPnrForm.validate()
      this.$refs.studentDetailsForm.validate()
      this.$refs.parentCarerForm.validate()
      this.errors = getFormErrors(this.$refs.y67tPnrForm)
    },
    closeErrorsDialog() {
      this.showErrors = false
      focusOnFirstError(this.$refs.y67tPnrForm)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-expansion-panel-header.sectionError {
  span i {
    color: $ads-error-red;
  }
  span {
    color: $ads-error-red;
    font-weight: bold;
  }
}
::v-deep .col {
  padding: 6px 12px;
}
::v-deep .displayField {
  label {
    font-weight: bold;
    font-size: 14px !important;
    color: $ads-primary-blue !important;
  }
  p {
    color: $ads-grey-01;
    line-height: 24px;
  }
}

::v-deep .subtitle-1 {
  font-size: 20px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-card__title {
  font-size: 16px !important;
  color: $ads-primary-blue !important;
}

::v-deep .v-expansion-panel-content__wrap {
  color: $ads-grey-01;
}
</style>
