// See README.md for more info about field configuration

import { FIELD_TYPE, CONFLICT_ALERTING, DATASET } from '@/constants'
import {
  isParentCarerMatch,
  getParentCarerSummaryText,
  statementOfAccountChangeHandler
} from './helpers'
import validators from './validators'

export default [
  // PARENT CARERS ---------------------------------------------------
  {
    apiKey: 'parentCarers',
    type: FIELD_TYPE.COLLECTION,
    filter: {
      apiKey: 'isEnrolmentOwner',
      value: true
    },
    heading(parent) {
      return `Parent/carer: ${parent.parentCarerTitle} ${parent.parentCarerGivenName} ${parent.parentCarerFamilyName} `
    },
    conflictAlerting: CONFLICT_ALERTING.AB,
    ernMatch(oes, ern) {
      return isParentCarerMatch(oes, ern)
    },
    ernRecordNumberField: 'ernParentRecordNo',
    oesRecordNumberField: 'parentCarerRecordNo',
    showGotoErnWhenUnmatched: true,
    summaryText(record) {
      return getParentCarerSummaryText(record)
    },
    fields(apiKeyPrefix, parent) {
      return [
        {
          apiKey: apiKeyPrefix + 'parentCarerTitle',
          label: 'Title',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.TITLE
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerGenderCode',
          label: 'Gender',
          required: false,
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Male', value: 'M' },
            { text: 'Female', value: 'F' }
          ]
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerRelation',
          label: 'Relationship to student',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.RELATIONSHIP
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerFamilyName',
          label: 'Family name',
          required: true,
          type: FIELD_TYPE.NAME,
          maxlength: 100,
          readOnly() {
            // if paernt has a parentId, they are linked in ERN, therefore we don't allow their names to be changed
            return !!parent.parentId
          }
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerGivenName',
          label: 'Given name',
          required: true,
          type: FIELD_TYPE.NAME,
          maxlength: 100,
          readOnly() {
            // if paernt has a parentId, they are linked in ERN, therefore we don't allow their names to be changed
            return !!parent.parentId
          }
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerCountryBirth',
          label: 'Country of birth',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.COUNTRIES
        },
        {
          apiKey: apiKeyPrefix + 'isParentCarerAboriOrTorres',
          label: 'Aboriginality',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.ABORIGINALITY
        },
        {
          label: 'Statement of account',
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'statementOfAccount',
          label: 'Statement of account',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.STATEMENT_OF_ACCOUNT,
          validation: validators.statementAccountValidator,
          onChange(val, store) {
            statementOfAccountChangeHandler(val, store, parent)
          }
        },
        {
          label: 'Occupation',
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerOccupationName',
          label: 'Occupation',
          validation: validators.occupationNameValidator,
          maxlength: 132,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for occupation
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerOccupationGroup',
          label: 'Occupation group',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.OCCUPATION_GROUP,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for occupation
        },

        {
          label: 'Education',
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerSchoolEducationCode',
          label: 'Highest level of schooling completed',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.SCHOOL_EDUCATION,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for education
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerEducationQualificationCode',
          label: 'Highest qualification completed',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.TERTIARY_EDUCATION,
          displayfieldAlerting: true,
          noConflictAlerting: true // for details see FUS-683, we don't want to resolve conflict for education
        },
        {
          label: 'Languages other than English spoken at home',
          type: FIELD_TYPE.HEADING
        },
        {
          apiKey: apiKeyPrefix + 'isParentCarerSpeakLangOtherEnglish',
          label:
            'Does this parent/carer speak a language other than English at home?',
          required: false,
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'No, English only', value: false },
            { text: 'Yes', value: true }
          ]
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerRequireInterpreter',
          label: 'Would an interpreter be required?',
          required: false,
          type: FIELD_TYPE.RADIO,
          options: [
            { text: 'Yes', value: true },
            { text: 'No', value: false }
          ],
          visible() {
            return parent.isParentCarerSpeakLangOtherEnglish
          }
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerMainLanguage',
          label: 'Main language other than English',
          default: 'XXX',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.NON_ENGLISH_LANGUAGES,
          visible() {
            return parent.isParentCarerSpeakLangOtherEnglish
          }
        },
        {
          apiKey: apiKeyPrefix + 'parentCarerSpeakLang',
          label: 'Other language spoken at home',
          default: 'ENG',
          required: true,
          type: FIELD_TYPE.DROPLIST,
          dataset: DATASET.LANGUAGES,
          visible() {
            return parent.isParentCarerSpeakLangOtherEnglish
          }
        }
      ]
    }
  }
]
