<template>
  <div>
    <FileUpload
      ref="fileUpload"
      v-model="files"
      :label="label"
      :max-file-count="maxFileCount"
      :max-file-size-mb="maxFileSizeMb"
      :mime-types="mimeTypes"
      placeholder="Upload a file"
      @validationError="handleUploadValidationError"
      @upload="uploadFile"
      @delete="deleteFile"
      @blur="handleBlur"
      :error-messages="fileErrorMessages"
    />
  </div>
</template>

<script>
import FileUpload from '@/components/app/FileUpload'
import { FILE_UPLOAD_ERROR_TYPES } from '@/constants'
import validators from '@/helpers/validators'
import { mapGetters } from 'vuex'

const FILE_UPLOAD_MESSAGE = 'Please upload considerations file'

export default {
  name: 'ConsiderationsDocumentUpload',
  components: {
    FileUpload
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    isBusy: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Uploaded file'
    },
    maxFileCount: {
      type: Number,
      default: 1
    },
    maxFileSizeMb: {
      type: Number,
      default: 10
    },
    mimeTypes: {
      type: String,
      default: 'application/pdf'
    }
  },
  data() {
    return {
      alerts: [],
      files: this.value,
      operationsInProgress: 0,
      validators: {
        emptyArray: validators.emptyArray
      },
      fileErrorMessages: []
    }
  },
  watch: {
    files(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.files = val
    },
    operationsInProgress(val) {
      if (val) {
        this.$emit('update:isBusy', true)
      } else {
        this.$emit('update:isBusy', false)
      }
    },
    validConsiderations(val) {
      if (!val) {
        this.addErrorMessage(FILE_UPLOAD_MESSAGE)
      }
    }
  },
  computed: {
    ...mapGetters(['eoiApplication', 'validConsiderations']),
    staffUserEmailId() {
      return `${this.$store.state.userID}@det.nsw.edu.au`
    },
    fileTypesListHtml() {
      // convert mime types to file types list for display
      // image/jpg,application/pdf => <strong>pdf</strong
      const types = this.mimeTypes
        .split(',')
        .map((mime) => `<strong>${mime.split('/')[1]}</strong>`)
      if (types.length > 1) {
        types[types.length - 1] = `or ${types[types.length - 1]}`
      }
      return types.join(', ')
    }
  },
  methods: {
    uploadFile(file, { progress, success, failure }) {
      this.operationsInProgress++
      this.$store.dispatch('setIsFileUploadInProgress', { inProgress: true })
      this.fileErrorMessages = []
      this.$store
        .dispatch('uploadY67TSupportingDocument', {
          file,
          schoolCode: this.eoiApplication.currentPrimarySchool?.schoolCode,
          applicationId: this.eoiApplication.student?.SRN,
          uploadedBy: this.staffUserEmailId,
          progressCallback: progress,
          stream: this.eoiApplication.meta?.stream
        })
        .then((uploadedPath) => {
          file.key = uploadedPath
          success(file)
          this.$store.dispatch('setSupportingDocuments', {
            digitizeEoiConsiderations: [
              {
                name: file.name,
                size: file.size,
                type: file.type
              }
            ]
          })
          this.fileErrorMessages = []
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleUploadValidationError({ fileName: file.name })
          this.addErrorMessage(FILE_UPLOAD_MESSAGE)
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress--
          this.$store.dispatch('setIsFileUploadInProgress', {
            inProgress: false
          })
        })
    },
    deleteFile(file, { success, failure }) {
      this.operationsInProgress++
      this.$store.dispatch('setIsFileDeleteInProgress', { inProgress: true })
      this.$store
        .dispatch('deleteY67TSupportingDocument', {
          file,
          schoolCode: this.eoiApplication.currentPrimarySchool?.schoolCode,
          applicationId: this.eoiApplication.student?.SRN,
          uploadedBy: this.staffUserEmailId,
          stream: this.eoiApplication.meta?.stream
        })
        .then(() => {
          success(file)
          this.$store.dispatch('setSupportingDocuments', {
            digitizeEoiConsiderations: []
          })
          this.addErrorMessage(FILE_UPLOAD_MESSAGE)
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err)
          this.handleDeleteValidationError(file.name)
          failure(file)
        })
        .finally(() => {
          this.operationsInProgress--
          this.$store.dispatch('setIsFileDeleteInProgress', {
            inProgress: false
          })
        })
    },
    deleteAll(successCallback, failureCallback) {
      const noOp = () => {}
      for (let f of this.files) {
        this.deleteFile(f, {
          success: successCallback || noOp,
          failure: failureCallback || noOp
        })
      }
    },
    handleUploadValidationError({ type, fileName }) {
      const errorReasons = {
        [FILE_UPLOAD_ERROR_TYPES.FILE_SIZE]: {
          reason: 'it exceeds the maximum file size of <strong>10mb</strong>.',
          action: 'Please choose a smaller document and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_TYPE]: {
          reason: 'it is an invalid file type.',
          action: `Please choose a file of type ${this.fileTypesListHtml} and try again.`
        },
        [FILE_UPLOAD_ERROR_TYPES.FILE_NAME]: {
          reason: 'this is not a valid file name.',
          action:
            'Please remove symbols and non-alphanumeric characters from the file name and try again.'
        },
        [FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES]: {
          reason: `it exceeds the maximum number of ${this.maxFileCount} document per EOI.`
        }
      }

      const reason = errorReasons[type]
        ? errorReasons[type].reason
        : 'of a server error.'

      const action = errorReasons[type]
        ? errorReasons[type].action || ''
        : 'Please try uploading the file again.'

      if (reason) {
        let notification = {
          display: true,
          type: 'error',
          html:
            `<p class="mb-2"><strong>File did not upload</strong></p><p class="mb-2 font-weight-normal"><strong>${fileName}</strong> did not upload because ${reason}` +
            (action ? ` ${action}</p>` : '</p>')
        }
        this.$emit('notification', notification)
      }
    },
    handleDeleteValidationError(fileName) {
      let notification = {
        display: true,
        type: 'error',
        html: `<p class="mb-2"><strong>File could not be removed</strong><p><p class="mb-2 font-weight-normal"><strong>${fileName}</strong> could not be removed because of a server error. Please try removing the file again.</p>`
      }
      this.$emit('notification', notification)
    },
    handleBlur() {
      this.addErrorMessage(FILE_UPLOAD_MESSAGE)
    },
    addErrorMessage(message) {
      if (
        this.files.length === 0 &&
        !this.fileErrorMessages.includes(message)
      ) {
        this.fileErrorMessages.push(message)
      }
    }
  }
}
</script>
