<template>
  <v-dialog v-bind="$attrs" v-on="$listeners" max-width="650">
    <v-card>
      <!-- Wrap announcable content in an alert -->
      <!-- only include if $attrs.value, otherwise it's getting announced on dismiss -->
      <div v-if="$attrs.value" role="alert">
        <v-card-title>
          <v-icon size="44">mdi-alert</v-icon>
          <div id="errorsDialogTitle" class="ml-3">
            Please fix the following errors before saving:
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <div v-for="e in errors" :key="e.section">
            <v-row>
              <v-col cols="7">
                {{ sectionName(e.sections) }} >
                <strong>{{ e.label }}</strong>
              </v-col>
              <v-col cols="5">
                {{ e.error }}
              </v-col>
            </v-row>
            <v-divider />
          </div>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer />
        <AdsButton
          ref="okButton"
          class="mr-6 mb-3 errorsDialogOkButton"
          button-text="Ok"
          @click="$emit('close')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'ErrorsDialog',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  components: {
    AdsButton
  },
  watch: {
    '$attrs.value': function (val) {
      this.handleAutoFocus(val)
    }
  },
  methods: {
    sectionName(sectionArray) {
      return sectionArray.join(' > ')
    },
    handleAutoFocus(val) {
      if (val) {
        setTimeout(() => {
          const okButton = this.$refs.okButton
          if (okButton) {
            okButton.$el.focus()
          }
        }, 200)
      }
    }
  }
}
</script>

<style scoped>
.errorsDialogOkButton:focus {
  box-shadow: none !important;
}
</style>
