<template>
  <div class="file-upload" :class="{ ie11: isIe }">
    <v-file-input
      :accept="acceptedFileTypes"
      class="file-input"
      ref="fileInput"
      :value="files"
      :multiple="maxFileCount > 0"
      :clearable="false"
      :placeholder="placeholderText"
      :messages="uploadingMessage"
      hide-details="auto"
      @change="onChange"
      v-bind="attrs"
      v-on="$listeners"
      aria-describedby="fileUploadRestrictions"
      persistent-placeholder
    >
      <template #prepend-inner>
        <div class="v-input__icon v-input__icon--prepend-inner">
          <v-icon
            @click="$refs.fileInput.$refs.input.click()"
            class="file-input__upload-icon"
            aria-label="upload file"
            icon
          >
            mdi-paperclip
          </v-icon>
        </div>
      </template>
      <template #selection="{ text, file }">
        <v-chip
          class="file-chip"
          :class="{ 'file-chip--removable': !isFileProcessing(file) }"
          small
          :color="isFileUploading(file) ? 'transparent' : 'grey lighten-5'"
        >
          {{ shortenChipText(text) }}
          <v-icon
            v-if="!isFileProcessing(file)"
            @click.stop="onDelete(file)"
            class="file-chip__close-btn"
            :aria-label="`Remove file ${file.name}`"
            small
          >
            mdi-close
          </v-icon>
        </v-chip>
      </template>
      <template #message="{ message }">
        <div>
          <div class="progress-wrapper">
            <v-progress-linear
              v-if="isUploading || isRemoving || isInvalid"
              class="mb-3"
              color="primary lighten-1"
              background-color="primary lighten-4"
              :indeterminate="(isInvalid || isRemoving) && !isUploading"
              :value="progress"
            />
          </div>
          <div>{{ message }}</div>
        </div>
      </template>
    </v-file-input>
    <div id="fileUploadRestrictions">
      <span v-if="this.mimeTypes">
        {{
          maxFileCount === 1
            ? `${supportedFileTypes} files supported only.`
            : `Supported file types: ${supportedFileTypes}.`
        }}
      </span>
      <span v-if="maxFileSizeMb">
        {{ maxFileSizeMb ? `${maxFileSizeMb}mb limit per file.` : '' }}
      </span>
      <span v-if="maxFileCount">
        {{
          maxFileCount === 1
            ? ` ${maxFileCount} file maximum.`
            : ` ${maxFileCount} files maximum.`
        }}
      </span>
    </div>

    <v-dialog
      v-model="showOverwriteConfirmation"
      max-width="500px"
      @keydown.esc="handleOverwriteConfirmation('cancel')"
      persistent
    >
      <v-card role="alert" class="px-3 pb-3">
        <div class="d-flex align-center ml-3 mb-6">
          <FeatureIcon
            class="mt-10"
            size="40px"
            icon="mdi-file-replace-outline"
          />
          <h3 class="overwriteModalTitle mt-10 ml-3">
            Overwrite existing file?
          </h3>
          <v-spacer />
          <v-btn
            class="modal__close-button"
            icon
            ref="closeBtn"
            @click="handleOverwriteConfirmation('cancel')"
            aria-label="close"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <div class="my-3 mx-4">
          <p v-html="overwriteMessage" />
        </div>
        <v-card-actions>
          <v-spacer />
          <div class="d-flex justify-end">
            <AdsButton
              @click="handleOverwriteConfirmation('cancel')"
              tertiary
              button-text="Cancel"
            />
            <AdsButton
              @click="handleOverwriteConfirmation('overwrite')"
              :button-text="overwriteButtonText"
              data-testid="overwriteConfirmationButton"
            />
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { FILE_UPLOAD_ERROR_TYPES } from '@/constants'
import { isIe } from '@/helpers/generalUtils'
import { FeatureIcon, AdsButton } from '@nswdoe/doe-ui-core'

const UPLOAD_STATUSES = {
  UPLOADING: 'UPLOADING',
  REMOVING: 'REMOVING',
  COMPLETE: 'COMPLETE',
  AWAITING_GROUP: 'AWAITING_GROUP'
}

const ONE_MB_IN_BYTES = 1048576

export default {
  name: 'FileUpload',
  inheritAttrs: false,
  components: {
    FeatureIcon,
    AdsButton
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: null
    },
    fileNameRegEx: {
      type: RegExp,
      default: () => /^[0-9a-zA-Z!\-_*.'() .]+$/
    },
    maxFileCount: {
      type: Number,
      default: null
    },
    maxFileSizeMb: {
      type: Number,
      default: null
    },
    mimeTypes: {
      type: String,
      default: null
    }
  },
  data() {
    const files = this.value ? [...this.value] : []
    return {
      isIe: isIe(),
      files, // array of selected files' metadata. Don't push actual file objects into this object otherwise onChange doesn't trigger consistently
      invalidCount: 0,
      maxFileSize: this.maxFileSizeMb * ONE_MB_IN_BYTES,
      showOverwriteConfirmation: null,
      overwriteMessage: '',
      handleOverwriteConfirmation: null,
      overwriteButtonText: '',
      progress: 0
    }
  },
  computed: {
    attrs() {
      const defaults = {
        chips: true,
        outlined: true,
        prependIcon: ''
      }
      return { ...defaults, ...this.$attrs }
    },
    placeholderText() {
      return this.placeholder === null
        ? this.maxFileCount === 1
          ? 'Select your file'
          : 'Select your file(s)'
        : this.placeholder
    },
    fileExtensions() {
      return this.mimeTypes
        ? this.mimeTypes
            .split(',')
            .map((mt) => mt.split('/')[1].toLowerCase().trim())
        : []
    },
    supportedFileTypes() {
      return this.fileExtensions.map((ext) => ext.toUpperCase()).join(', ')
    },
    acceptedFileTypes() {
      return this.fileExtensions.map((ext) => `.${ext}`).join(',')
    },
    isInvalid() {
      return this.invalidCount > 0
    },
    isUploading() {
      return this.filesUploading.length > 0
    },
    isRemoving() {
      return this.filesRemoving.length > 0
    },
    filesInUploadGroup() {
      return this.files.filter((file) => this.isFileInUploadGroup(file)) || []
    },
    filesUploading() {
      return this.filesInUploadGroup.filter(this.isFileUploading) || []
    },
    filesRemoving() {
      return this.files.filter(
        (file) => file.uploadStatus === UPLOAD_STATUSES.REMOVING
      )
    },
    totalUploaded() {
      return this.filesInUploadGroup.reduce(
        (total, file) =>
          total +
          (file.uploadStatus === UPLOAD_STATUSES.AWAITING_GROUP
            ? file.size
            : 0),
        0
      )
    },
    totalFileSize() {
      return this.filesInUploadGroup.reduce(
        (total, file) => total + (file.size || 0),
        0
      )
    },
    uploadingMessage() {
      const numFilesInGroup = this.filesInUploadGroup.length
      const numFilesUploading = this.filesUploading.length
      const numUploadedFiles = this.files.length
      const numFile = numFilesInGroup - numFilesUploading + 1
      if (this.isUploading) {
        return this.maxFileCount === 1
          ? `File uploading...`
          : `File ${numFile} of ${numFilesInGroup} uploading...`
      } else if (this.isRemoving) {
        return `Removing ${this.filesRemoving.length} ${
          this.filesRemoving.length > 1 ? 'files' : 'file'
        }...`
      } else if (this.$refs.fileInput && this.$refs.fileInput.hasError) {
        return this.$refs.fileInput.errorBucket[0]
      } else if (numUploadedFiles) {
        return `${numUploadedFiles} file${
          numUploadedFiles > 1 ? 's' : ''
        } uploaded`
      } else {
        return ''
      }
    }
  },
  watch: {
    isUploading(newValue) {
      // If we're done uploading, set the status of all uploading files to 'complete'
      if (!newValue) {
        this.files.forEach((file) => {
          if (this.isFileInUploadGroup(file)) {
            file.uploadStatus = UPLOAD_STATUSES.COMPLETE
          }
        })
        // Re-sync for IE11
        this.changeNoOp()
      }
    },
    value(newValue) {
      this.files = [...newValue]
    }
  },
  methods: {
    focusCloseBtn() {
      requestAnimationFrame(() => {
        this.$refs.closeBtn.$el.focus()
      })
    },
    findExistingFile(fileName) {
      return this.files.find((f) => f.name === fileName)
    },
    shortenChipText(text) {
      return this.$vuetify.breakpoint.xsOnly && text.length > 10
        ? `${text.substring(0, 2)}...${text.substring(text.length - 5)}`
        : text
    },
    isFileInUploadGroup(file) {
      return (
        file.uploadStatus === UPLOAD_STATUSES.AWAITING_GROUP ||
        file.uploadStatus === UPLOAD_STATUSES.UPLOADING
      )
    },
    isFileUploading(file) {
      return file.uploadStatus === UPLOAD_STATUSES.UPLOADING
    },
    isFileProcessing(file) {
      const processingStatuses = [
        UPLOAD_STATUSES.UPLOADING,
        UPLOAD_STATUSES.REMOVING
      ]
      return processingStatuses.includes(file.uploadStatus)
    },
    async onChange(files) {
      if (files === this.files) {
        // onChange is being triggered when opening the file select window. Bail out if nothing changed.
        return
      }
      if (!files.length) {
        // prevent the vuetify component from clearing the field if no files selected
        this.changeNoOp()
      }

      const newFiles = []
      const existingFiles = []
      for (const file of files) {
        if (this.findExistingFile(file.name)) {
          existingFiles.push(file)
        } else {
          newFiles.push(file)
        }
      }

      if (this.maxFileCount) {
        // add new files to old files to get total count
        if (this.files.length + newFiles.length > this.maxFileCount) {
          this.changeNoOp()
          this.$emit('validationError', {
            type: FILE_UPLOAD_ERROR_TYPES.EXCEED_MAX_FILES,
            fileName: files.map((f) => f.name).join(', ')
          })
          return
        }
      }

      if (
        !existingFiles.length ||
        (await this.confirmFileOverride(existingFiles)) === 'overwrite'
      ) {
        this.uploadFiles(files)
      } else {
        // cancel - do nothing
        this.changeNoOp()
      }
    },
    confirmFileOverride(existingFiles) {
      this.showOverwriteConfirmation = true
      this.focusCloseBtn()
      return new Promise(
        function (resolve) {
          this.handleOverwriteConfirmation = resolve

          const fileNames = existingFiles.map(
            (f) => `<strong>${f.name}</strong>`
          )

          if (existingFiles.length > 1) {
            fileNames[fileNames.length - 1] =
              'and ' + fileNames[fileNames.length - 1]
            this.overwriteButtonText = 'Overwrite files'

            this.overwriteMessage = `Files ${fileNames.join(
              ', '
            )} have already been uploaded. Would you like to overwrite the existing files with the ones you have just selected?`
          } else {
            this.overwriteButtonText = 'Overwrite file'
            this.overwriteMessage = `A file named ${fileNames[0]} has already been uploaded. Would you like to overwrite the existing file with the one you have just selected?`
          }
        }.bind(this)
      ).then((resolution) => {
        this.showOverwriteConfirmation = false
        return resolution
      })
    },
    uploadFiles(files) {
      for (let file of files) {
        if (this.validateFile(file)) {
          this.startUpload(file)
        } else {
          this.changeNoOp()
        }
      }
    },
    async startUpload(file) {
      const existingFile = this.findExistingFile(file.name)
      if (existingFile) {
        // remove the existing file first
        this.files.splice(
          this.files.findIndex((f) => f.name === existingFile.name),
          1
        )
      }

      // push a json copy of the file, not the file itself
      this.files.push({
        name: file.name,
        size: file.size,
        type: file.type,
        uploadStatus: UPLOAD_STATUSES.UPLOADING
      })

      this.emitUploadEvent(file)
    },
    emitUploadEvent(file) {
      // trigger the event to start the upload
      this.$emit('upload', file, {
        progress: this.progressCallback,
        success: this.finishUpload, // on success
        failure: this.removeFile // on failure
      })
    },
    progressCallback(e) {
      this.progress = Math.ceil((e.loaded * 100) / e.total)
    },
    finishUpload(uploadedFile) {
      this.progress = 0
      // 'uploadedFile' is the actual file object we passed out, not what we track internally
      // so we need to find and update our internal object
      const file = this.findExistingFile(uploadedFile.name)
      if (file) {
        // s3 path key should be returned in uploaded file
        file.key = uploadedFile.key
        file.uploadStatus = UPLOAD_STATUSES.AWAITING_GROUP
        this.$emit('input', this.files)
        // Re-sync for IE11
        this.changeNoOp()
      }
    },
    changeNoOp() {
      // We must cause a change in the value or else the v-file-input component will change its internal value to something other than our supplied value
      this.files = [...this.files]
    },
    isValidFileSize(file) {
      return !(this.maxFileSize && file.size > this.maxFileSize)
    },
    isValidFileName(file) {
      return this.fileNameRegEx.test(file.name)
    },
    isValidFileType(file) {
      const fileParts = file.name.split('.')
      const ext =
        fileParts.length > 1
          ? fileParts[fileParts.length - 1].toLowerCase()
          : ''
      return (
        !this.mimeTypes ||
        (!!(file.type && this.mimeTypes.includes(file.type)) &&
          !!(ext && this.fileExtensions.includes(ext)))
      )
    },
    validateFile(file) {
      if (!this.isValidFileSize(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_SIZE,
          fileName: file.name
        })
        return false
      } else if (!this.isValidFileType(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_TYPE,
          fileName: file.name
        })
        return false
      } else if (!this.isValidFileName(file)) {
        this.$emit('validationError', {
          type: FILE_UPLOAD_ERROR_TYPES.FILE_NAME,
          fileName: file.name
        })
        return false
      }

      return true
    },
    onDelete(file) {
      const failureCallback = (file) => {
        file.uploadStatus = UPLOAD_STATUSES.COMPLETE
        this.$emit('input', this.files)
      }
      file.uploadStatus = UPLOAD_STATUSES.REMOVING
      // emit delete with success and failure callbacks
      this.$emit('delete', file, {
        success: this.removeFile,
        failure: failureCallback
      })
    },
    removeFile(deletedFile) {
      // remove file from list once removal is completed by parent
      this.files.splice(
        this.files.findIndex((f) => f.name === deletedFile.name),
        1
      )
      this.$emit('input', this.files)
      // Re-sync for IE11
      this.changeNoOp()
    }
  }
}
</script>

<style lang="scss" scoped>
.file-upload {
  margin-top: 1rem;
  background-color: #ffffff;
  color: $color-text-body;

  ::v-deep button {
    border: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.v-dialog p {
  line-height: 1.5;
}
.file-chip {
  padding-right: 31px;

  &--removable {
    padding-right: 0;
  }
}

#fileUploadRestrictions {
  color: $ads-dark-60;
  font-size: 0.933rem;
  margin-left: 2px;
  margin-top: 10px;
}

.progress-wrapper {
  background-color: $ads-light-blue;
}

.modal__close-button.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  border: none;
  &:focus {
    color: $color-primary !important;
    border: 2px solid $color-primary !important;
  }
}

//Vuetify components
::v-deep {
  .v-text-field .v-text-field__details {
    padding: 0px 2px;
  }
  &:not(.error--text).v-messages__wrapper .v-messages__message {
    color: $ads-dark-60;
    font-size: 0.933rem;
    line-height: 1rem;
  }

  // This CSS is to resolve the bug with the upload component in safari:
  // https://github.com/vuetifyjs/vuetify/issues/10832
  // This bug was resolved in this fix:
  // https://github.com/vuetifyjs/vuetify/commit/d5800aad7dc9e62e7d398c890b7af6580e6060ce
  // as part of v2.3.11. However, due to circumstances at the time the bug was found
  // we were unable to do a vuetify update and so are implementing the fix ourselves.
  // This should be removed once vuetify is updated to this version or higher.

  .v-file-input input[type='file'] {
    pointer-events: none;
  }

  .v-file-input .v-input__icon button {
    width: 48px;
    &:hover {
      text-decoration: none !important;
    }
  }
}

// IE11 fixes
.ie11 {
  ::v-deep .v-file-input {
    .v-input__icon button {
      // fix paper clip icon positioning
      padding: 0px;
      margin-left: -3px;
    }
    .v-text-field__slot .v-label {
      // fix label positioning
      left: -28px !important;
    }
  }
}

.overwriteModalTitle {
  font-size: 1.25rem;
}
</style>
