<!--
  This template is used for displaying an alert message below a field, group or record.
  The message will inform about either a:
    - Missing field
    - Invalid field
    - Field warning with an IGNORE option (e.g. "Mobile number required")
    - Field/record/collection ERN conflict message and RESOLVE/UNDO option
-->

<template>
  <div v-if="!isPrintPreview">
    <div v-if="modelRow.isInvalid" aria-live="polite" class="FieldAlert">
      <font-awesome-icon
        class="icon"
        focusable="false"
        icon="exclamation-triangle"
      />
      <span class="alertMessage" :id="modelRow.field.apiKey + '-error'">{{
        modelRow.validationMessage
      }}</span>
    </div>

    <div v-if="modelRow.resolution" aria-live="polite" class="FieldAlert">
      <div class="tickCircle">
        <div>
          <font-awesome-icon focusable="false" icon="check" />
        </div>
      </div>
      <span class="resolutionMessage"
        >Resolved by {{ modelRow.resolution.staffName }}</span
      >
      <button
        v-if="!isEditing"
        type="button"
        class="resolve unstyled"
        @click.stop="resolveConflict()"
        title="Revert to the unresolved value and unlock"
        ref="resolveButton"
      >
        Undo
      </button>
    </div>

    <div v-else-if="modelRow.isMissing" aria-live="polite" class="FieldAlert">
      <font-awesome-icon
        class="icon"
        focusable="false"
        icon="exclamation-triangle"
      />
      <span class="alertMessage" :id="modelRow.field.apiKey + '-error'"
        >Field is required</span
      >
    </div>

    <div
      v-else-if="modelRow.isConflict && !isWithdrawn"
      aria-live="polite"
      class="FieldAlert"
    >
      <font-awesome-icon
        class="icon"
        focusable="false"
        icon="exclamation-triangle"
      />
      <span class="alertMessage" :id="modelRow.field.apiKey + '-error'"
        >Different to record in ERN</span
      >
      <button
        v-if="!isEditing"
        type="button"
        class="ignore unstyled"
        @click.stop="resolveConflict()"
        title="Resolve by choosing a value to keep"
        ref="resolveButton"
      >
        Resolve
      </button>
    </div>
    <div
      v-else-if="
        modelRow.fieldConflictAlerting &&
        modelRow.displayfieldAlerting &&
        !isWithdrawn
      "
      aria-live="polite"
      class="conflictAlert"
    >
      <div>
        <v-icon>mdi-alert-circle-outline</v-icon>
        <span class="pl-3">
          <b>This value is different in ERN.</b><br />
          <span class="pl-9">The existing ERN record will be updated.</span>
        </span>
      </div>
      <AdsButton
        type="button"
        tertiary
        @click.stop="displayConflict()"
        button-text="View ERN record"
      />
      <DialogGeneric
        title="ERN values"
        max-width="636px"
        icon="swap_calls"
        display-close-btn
        :message="message"
        v-model="displayMoreInfoDialog"
      />
    </div>

    <div
      v-else-if="modelRow.warningMessage"
      aria-live="polite"
      class="FieldAlert"
    >
      <font-awesome-icon
        :class="'icon' + (modelRow.isWarningIgnored ? ' greyed' : '')"
        focusable="false"
        icon="exclamation-triangle"
      />
      <span
        :class="'alertMessage' + (modelRow.isWarningIgnored ? ' greyed' : '')"
        :id="modelRow.field.apiKey + '-error'"
        >{{ modelRow.warningMessage }}</span
      >
      <button
        v-if="!isEditing"
        type="button"
        class="ignore unstyled"
        @click.stop="toggleIgnoreAlert()"
        :title="
          modelRow.isWarningIgnored ? 'Restore warning' : 'Ignore warning'
        "
        ref="resolveButton"
      >
        {{ modelRow.isWarningIgnored ? 'Undo' : 'Ignore' }}
      </button>
    </div>
  </div>
</template>

<script>
import { AdsButton, DialogGeneric } from '@nswdoe/doe-ui-core'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import _ from 'lodash'

export default {
  name: 'FieldAlert',
  components: {
    AdsButton,
    DialogGeneric
  },
  data() {
    return {
      displayMoreInfoDialog: false
    }
  },
  props: {
    modelRow: {
      type: Object
    }
  },
  mixins: [fieldHelperMixin],
  computed: {
    message() {
      return `<div style="box-shadow: 0px 2px 3px 2px #00000024; padding: 24px 35px;">
               <p style="font-size: 20px; color: #121212; font-weight: bold">${
                 this.modelRow.section.label
               }</p>
               <div style="color: #2E2F30">${this.modelRow.label}</div>
               <p style="color: #121212; font-weight: bold">${
                 this.modelRow.ernDisplayValue || '-Empty-'
               }</p>
              </div>`
    }
  },
  methods: {
    toggleIgnoreAlert() {
      var resolution = {
        id: this.modelRow.id,
        type: 'IGNORE',
        staffName: this.$store.state.userName,
        date: this.$moment().format('YYYY-MM-DD HH:mm')
      }
      this.$store.dispatch('updateAlertResolution', [
        this.modelRow,
        this.modelRow.isWarningIgnored ? null : resolution
      ])
    },
    resolveConflict() {
      if (this.modelRow.isResolved) {
        // If already resolved, undo resolution...
        // Revert OES value...
        this.$store.dispatch('updateAlertResolution', [this.modelRow, null])
      } else {
        // Otherwise resolve...
        this.$store.dispatch('set', [
          'resolveConflict',
          _.cloneDeep(this.modelRow)
        ])
      }
    },
    displayConflict() {
      this.displayMoreInfoDialog = true
    }
  },
  watch: {
    focusFieldId: function () {
      if (
        !this.isEditing &&
        this.focusFieldId === this.modelRow.id &&
        (this.modelRow.isConflict || this.modelRow.warningMessage)
      ) {
        this.$store.dispatch('set', ['focusFieldId', null])
        this.$refs.resolveButton.focus()
      }
    }
  }
}
</script>

<style lang="scss">
#adsDialogComponent > div.v-card__title.d-flex.justify-space-between {
  margin-bottom: 0;
  color: $grey-dark !important;
}

#adsDialogComponent > div.v-card__title.d-flex.justify-space-between > div {
  font-weight: bold;
  font-size: 20px !important;
}

#adsDialogComponent > div.v-card__text.content {
  padding: 0 24px;
  margin-bottom: 0;
}

#adsDialogComponent .close-btn__wrapper .v-btn {
  border: none;

  &:focus {
    border: 2px solid $color-primary;
  }
}
</style>

<style scoped lang="scss">
.FieldAlert {
  color: $color-red;
  display: flex;
  padding-top: 0.3rem;
  font-size: 0.9rem;

  .icon {
    font-size: 1rem;
    &.greyed {
      color: silver;
    }
  }
  .alertMessage {
    display: inline-block;
    padding-left: 0.25rem;
    &.greyed {
      color: $color-dark70;
    }
  }
  .resolutionMessage {
    display: inline-block;
    color: $color-text-body;
    font-style: italic;
    padding-left: 0.25em;
  }
  .ignore,
  .resolve {
    text-decoration: underline;
    color: $color-secondary;
    margin-left: 1rem;
  }
  .tickCircle div {
    color: white;
    background-color: green;
    border-radius: 1em;
    line-height: 1;
    font-size: 0.7em;
    padding: 0.3em;
    svg {
      vertical-align: -0.2em;
    }
  }
}

.conflictAlert {
  padding-top: 8px;
  background-color: $grey-light-10;
}

::v-deep .conflictAlert .v-btn {
  margin-left: 12px;
  text-decoration: underline;
  height: 30px;
}

::v-deep .conflictAlert .buttonText {
  color: $color-primary-lighten-1;
}
</style>
