<template>
  <!-- eslint-disable max-len -->
  <v-container class="pt-0">
    <v-row>
      <ReviewField
        data-testid="residentialAddress"
        sm="8"
        label="Student’s Residential Address"
        :value="residentialAddress"
      />
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ReviewField from '@/components/application/y67t/paperEoI/eoi/Review/ReviewField'
import utils from '@/mixins/utils'

export default {
  name: 'AddressView',
  components: {
    ReviewField
  },
  mixins: [utils],
  computed: {
    ...mapGetters(['eoiApplication']),
    residentialAddress() {
      return this.eoiApplication.residentialAddress
        ? this.addressObjectToString(this.eoiApplication.residentialAddress)
        : null
    }
  }
}
</script>

<style lang="scss" scoped></style>
