<template>
  <v-dialog :persistent="true" value="true" max-width="950px">
    <v-card v-if="isParentCarerMatch && isAddressMatch" class="pa-3">
      <v-card-title>Confirm sibling match</v-card-title>
      <v-card-text class="pt-0">
        <v-row>
          <v-col>
            Please confirm you want to create a new SRN for this <b>student</b>:
          </v-col>
        </v-row>
        <v-row>
          <v-col tabindex="0">
            <SrnSearchSiblingRecord
              :student="studentRecord"
              :is-parent-carer-match="true"
              :is-address-match="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>Linked to this <b>sibling</b>:</v-col>
        </v-row>
        <v-row>
          <v-col tabindex="0">
            <SrnSearchSiblingRecord
              :student="siblingRecord"
              :is-parent-carer-match="isParentCarerMatch"
              :is-address-match="isAddressMatch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-icon color="orange darken-1">mdi-alert</v-icon>
            &nbsp;<b>Note:</b> Once you select confirm, this student will be
            linked to the sibling's family tree in ERN. For more information,
            check the
            <a
              href="https://education.nsw.gov.au/platoapps/online-enrolment-system/online-enrolment-guides/check-and-link-an-srn"
              target="_blank"
            >
              Family Tree Quick Reference Guide.
            </a>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <AdsButton
          :large="true"
          :tertiary="true"
          button-text="< Back"
          @click="$emit('cancelLinkSrnForThisSibling')"
        />
        <AdsButton
          icon="check"
          :large="true"
          button-text="Confirm"
          @click="$emit('linkSrnForThisSibling', siblingRecord)"
        />
      </v-card-actions>
    </v-card>
    <v-card v-else class="pa-3">
      <v-card-title>Sibling details are not an exact match</v-card-title>
      <v-card-text class="pt-0">
        <v-row>
          <v-col>
            <v-icon color="orange darken-1">mdi-alert</v-icon>
            &nbsp;A <b>new student SRN</b> must be created for this student.
          </v-col>
        </v-row>
        <v-row>
          <v-col><b>Student details:</b></v-col>
        </v-row>
        <v-row>
          <v-col tabindex="0">
            <SrnSearchSiblingRecord
              :student="studentRecord"
              :is-parent-carer-match="true"
              :is-address-match="true"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col><b>Chosen sibling details:</b></v-col>
        </v-row>
        <v-row>
          <v-col tabindex="0">
            <SrnSearchSiblingRecord
              :student="siblingRecord"
              :is-parent-carer-match="isParentCarerMatch"
              :is-address-match="isAddressMatch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <b>Note:</b> Once the new student SRN is created, it can be matched
            to an existing family tree in ERN. For more information, check the
            <a
              href="https://education.nsw.gov.au/platoapps/online-enrolment-system/online-enrolment-guides/check-and-link-an-srn"
              target="_blank"
            >
              Family Tree Quick Reference Guide.
            </a>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <AdsButton
          :large="true"
          :tertiary="true"
          button-text="< Back"
          @click="$emit('cancelLinkSrnForThisSibling')"
        />
        <AdsButton
          icon="check"
          :large="true"
          button-text="Create new student SRN"
          @click="$emit('createSrnForThisStudent')"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import UTILS from '@/store/utils'
import CONFLICT from '@/helpers/conflictHelper'
import SrnSearchSiblingRecord from '@/components/application/SrnSearchSiblingRecord'

export default {
  name: 'SrnSearchSiblingDialog',
  components: { AdsButton, SrnSearchSiblingRecord },
  props: {
    studentRecord: {
      type: Object,
      default: null
    },
    siblingRecord: {
      type: Object,
      default: null
    }
  },
  computed: {
    isParentCarerMatch() {
      const studentParents = this.studentRecord.parents
      const siblingParents = this.siblingRecord.parents

      if (studentParents.length !== siblingParents.length) {
        return false
      }

      // Would be nice to just use the JS 'includes' function but
      // I want to use 'isFuzzyMatch' function from conflict resolution instead
      const containsParent = (parentsList, parent) => {
        for (let i = 0; i < parentsList.length; i++) {
          if (UTILS.isFuzzyMatch(parentsList[i], parent)) {
            return true
          }
        }
        return false
      }

      for (let i = 0; i < studentParents.length; i++) {
        if (!containsParent(siblingParents, studentParents[i])) {
          return false
        }
      }
      return true
    },
    isAddressMatch() {
      return !CONFLICT.isAddressConflict({
        oesValue: this.studentRecord.residentialAddress,
        ernValue: this.siblingRecord.residentialAddress
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  color: black !important;
}
</style>
