var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.model.length &&
    !_vm.state.isEditing &&
    !_vm.isSentToErn &&
    !_vm.isWithdrawn &&
    !_vm.isInvalid &&
    !_vm.isInternalTransfer
  )?_c('div',{staticClass:"ApplicationSummary"},[(!_vm.hideWithdraw)?_c('AdsButton',{staticClass:"secondaryBtn",class:{ 'mr-2': !_vm.hideSendToErn },attrs:{"x-large":"","secondary":"","button-text":"Withdraw","icon":"remove_circle_outline","aria-label":"Withdraw","data-testid":"withdraw-btn"},on:{"click":_vm.openWithdrawDialog}}):_vm._e(),(_vm.showInternalTransferBtn && !_vm.isSRNLinked)?_c('AdsButton',{staticClass:"secondaryBtn",class:{ 'mr-2': !_vm.hideSendToErn },attrs:{"x-large":"","secondary":"","button-text":"Mark as internal transfer","icon":"mdi-cached","aria-label":"Mark as internal transfer","data-testid":"mark-as-internal-transfer-btn"},on:{"click":_vm.openInternalTransferModal}}):_vm._e(),(!_vm.hideSendToErn)?_c('AdsButton',{staticClass:"sendToErnButton",attrs:{"x-large":"","aria-label":"Send to ERN","disabled":_vm.isSendToErnDisabled,"button-text":"Send to ERN","icon":"mdi-send-outline","loading":_vm.state.isSendingToErn && !_vm.isSentToErn},on:{"click":_vm.sendToERN}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }