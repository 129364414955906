<template>
  <div class="ApplicationList px-0 grey lighten-6">
    <!-- List view filters ( pending and processed applications) -->
    <v-row class="pb-3">
      <v-col class="pt-0">
        <span class="filterLabel">Filter by:</span>
        <span class="pl-3">
          <ListViewFilter
            ref="statusFilter"
            label="Status"
            type="checkbox"
            :filter-options="statusFilters"
            @updatefilter="updateStatusFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <ListViewFilter
            ref="scholasticYrFilter"
            label="Scholastic year"
            type="checkbox"
            :filter-options="scholasticYearFilters"
            :show-default-filter="defaultScholasticYrFilter"
            @updatefilter="updateScholasticYearFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3">
          <YearDateRangeFilter
            ref="startDateFilter"
            label="Start date"
            :archive-type="archiveType"
            :year-options="yearOptions"
            :filter-options="startDateFilters"
            :show-default-filter="selectedReceivedYear"
            @updateYearFilter="updateYearFilter"
            @updatefilter="updateStartDateFilter"
            @menuOpened="sendFilterAnalytics"
            :selected-application="mode"
          />
        </span>
        <span
          class="pl-3"
          v-if="this.mode === 'processed' || this.mode === 'pending'"
        >
          <ListViewFilter
            ref="applicationTypeFilter"
            label="Application Type"
            type="checkbox"
            :filter-options="applicationTypeFilters"
            :show-default-filter="defaultApplicationTypeFilter"
            @updatefilter="updateApplicationTypeFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span
          class="pl-3"
          v-if="this.mode === 'processed' || this.mode === 'pending'"
        >
          <ListViewFilter
            ref="intakeFilter"
            label="Intake Area"
            type="checkbox"
            :filter-options="intakeFilters"
            :show-default-filter="defaultIntakeFilter"
            @updatefilter="updateIntakeFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <span class="pl-3" v-if="this.mode === 'pending'">
          <ListViewFilter
            ref="srnLinkedFilter"
            label="SRN link"
            type="checkbox"
            :filter-options="srnLinkFilters"
            :show-default-filter="defaultSrnLinkedFilter"
            @updatefilter="updateSrnLinkFilter"
            @menuOpened="sendFilterAnalytics"
          />
        </span>
        <ClearFilters @click="clearAllFilters()" v-if="isFiltered" />
      </v-col>
    </v-row>
    <v-card elevation="2" class="mb-20">
      <AdsDataTable
        :class="mode"
        :items="applications"
        :headers="headers"
        search-label="Find student"
        :search="searchValue"
        :sort-by="mode === 'pending' ? 'applicationStatus' : 'lastModifiedDate'"
        must-sort
        sort-desc
        :items-per-page="pageLength"
        :footer-props="{
          'items-per-page-options': [10, 15, 50]
        }"
        :custom-filter="filterApplications"
        @current-items="setFilteredIds"
        @input="performSearchDebounced"
        @blur="trackSearch"
        @update:items-per-page="getItemPerPage"
        @update:search="updateSearchValue"
        @pagination="totalResults"
      >
        <template #no-data>
          <NoResultsFound
            :archive-type="archiveType"
            :filtered="isFiltered || Boolean(searchApplicationsFilter)"
          />
        </template>
        <template #top>
          <v-row class="row-line mb-0">
            <v-col>
              <v-text-field
                class="searchInput"
                prepend-inner-icon="mdi-magnify"
                v-model="searchValue"
                label="Find student"
                single-line
                hide-details
                clearable
              />
            </v-col>
            <v-col cols="auto" class="results-text">
              <span>
                {{ resultsText(itemsLength) }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template
          v-for="headerData in headers"
          :slot="`header.${headerData.value}`"
        >
          <button class="no-styling" :key="headerData.value">
            {{ headerData.text }}
          </button>
        </template>

        <template slot="item" slot-scope="props">
          <!-- Table template for "pending applications" -->
          <tr
            @click="openApplication(props.item)"
            class="grey--text text--lighten-1"
          >
            <td class="text-xs-right" v-if="mode === 'processed'">
              {{
                props.item.lastModifiedDate === null
                  ? ''
                  : $moment(props.item.lastModifiedDate).format('DD MMM YYYY')
              }}
            </td>

            <td class="text-xs-right">
              {{ props.item.dateReceived | moment('DD MMM YYYY') }}
            </td>

            <td class="text-xs-right">
              <a
                :href="`/#/application/${schoolCode}/${props.item.applicationID}`"
                :aria-label="`${props.item.name}. Open application details.`"
                :ref="`itemfocusRef${props.index}`"
                >{{ props.item.name }}</a
              >
              <AttributeBadge
                v-if="props.item.residencyStatus === 'TMP'"
                abbr="TR"
                title="Temporary resident"
                class="ml-1"
              />
            </td>

            <td v-if="mode === 'pending'">
              {{ props.item.studentDOB | moment('DD MMM YYYY') }}
            </td>

            <td class="text-sm-center text-md-left">
              <v-avatar
                class="primary lighten-2 white--text headline mr-2 font-weight-bold"
                size="29"
                >{{ props.item.scholasticYearCode }}</v-avatar
              >
              <span class="d-none d-lg-inline">
                {{ props.item.scholasticYear }}
              </span>
            </td>

            <td class="text-xs-right">
              {{ props.item.intendedStartDate | moment('DD MMM YYYY') }}
            </td>

            <td class="text-xs-right">
              {{ props.item.applicationType }}
            </td>

            <td class="text-sm-center text-md-left">
              {{ calculateIntakeArea(props.item) }}
            </td>

            <td class="text-xs-right" v-if="mode === 'pending'">
              <span v-if="isSrnLinked(props.item.srn)">
                <v-icon color="#002664" size="30"> mdi-link </v-icon>
              </span>
              <span v-else>
                <v-icon color="#002664" size="30"> mdi-link-off </v-icon>
              </span>
            </td>

            <td class="text-xs-right">
              <Chip
                v-bind="getStatusChipDetails(props.item.applicationStatus)"
                class="application-status"
                small
              />
            </td>

            <td class="text-center alert" v-if="mode === 'pending'">
              <span v-if="props.item.alertsFound">
                <v-icon color="red" size="30" title="Action required">
                  mdi-alert-circle
                </v-icon>
                <span class="sr-only">Action required</span>
              </span>
            </td>
          </tr>
        </template>
      </AdsDataTable>
    </v-card>
  </div>
</template>

<script>
import ListViewFilter from '@/components/app/ListViewFilter.vue'
import YearDateRangeFilter from '@/components/app/YearDateRangeFilter.vue'
import NoResultsFound from '@/components/app/NoResultsFound.vue'
import AttributeBadge from '@/components/application/AttributeBadge.vue'
import ClearFilters from './ClearFilters.vue'
import {
  sortScholasticYearCodes,
  sortByScholasticYear,
  searchFilterForStringOnly
} from '@/helpers/searchHelper'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { Chip, AdsDataTable } from '@nswdoe/doe-ui-core'
import {
  STATUS_CHIPS,
  ARCHIVE_TYPES,
  APPLICATION_OFFER_TYPE_NAME
} from '@/constants'

const headerDefinitions = {
  lastModifiedDate: {
    text: 'Processed date',
    value: 'lastModifiedDate',
    class: 'last-modified',
    align: 'start'
  },
  dateReceived: {
    text: 'Received',
    value: 'dateReceived',
    class: 'date-received',
    align: 'start',
    width: '100px'
  },
  name: {
    text: 'Student name',
    value: 'name',
    class: 'name',
    align: 'start',
    width: '190px'
  },
  studentDOB: {
    text: 'Date of birth',
    value: 'studentDOB',
    class: 'dob',
    align: 'start',
    width: '100px'
  },
  inArea: {
    text: 'Intake area',
    value: 'inArea',
    class: 'intake-area',
    align: 'start',
    width: '100px'
  },
  scholasticYear: {
    text: 'Scholastic year',
    value: 'scholasticYear',
    align: 'start',
    class: 'scholastic-year',
    width: '120px'
  },
  applicationType: {
    text: 'Application type',
    value: 'applicationType',
    class: 'application-type',
    align: 'start',
    width: '110px'
  },
  intendedStartDate: {
    text: 'Start date',
    value: 'intendedStartDate',
    class: 'start-date',
    align: 'start',
    width: '110px'
  },
  srnLinked: {
    text: 'SRN link',
    value: 'srn',
    class: 'srnLink',
    align: 'start',
    width: '80px'
  },
  applicationStatus: {
    text: 'Status',
    value: 'applicationStatus',
    class: 'status',
    align: 'start',
    width: '154px'
  },
  alertsFound: {
    text: 'Action',
    value: 'alertsFound',
    class: 'action',
    align: 'start',
    width: '60px'
  }
}

export default {
  name: 'LocalAreaDataTable',
  components: {
    ListViewFilter,
    YearDateRangeFilter,
    ClearFilters,
    Chip,
    AdsDataTable,
    NoResultsFound,
    AttributeBadge
  },
  data() {
    return {
      selectAll: false,
      selectedIds: [],
      searchValue: '',
      pageLength: this.defaultPageLength,
      itemsLength: 0,
      selectedReceivedYear: [],
      defaultScholasticYrFilter: [],
      defaultApplicationTypeFilter: [],
      defaultIntakeFilter: [],
      defaultSrnLinkedFilter: [],
      archiveType: ARCHIVE_TYPES.ALL.type
    }
  },
  props: {
    mode: {
      type: String,
      default: 'pending'
    },
    defaultPageLength: {
      type: Number,
      default: 50
    }
  },
  methods: {
    getStatusChipDetails(status) {
      return STATUS_CHIPS[status]
    },
    isSrnLinked(srn) {
      return !!srn
    },
    updateSearchValue(searchValue) {
      this.$store.commit('setApplicationsFilter', searchValue)
    },
    getItemPerPage(itemPerPage) {
      this.$store.commit('setDefaultPage', itemPerPage)
    },
    setFilteredIds(items) {
      const ids = items.map((item) => item.applicationID)
      this.$store.dispatch('set', ['filteredApplicationIds', ids])
    },
    resetPageLength() {
      this.pageLength = this.defaultPageLength
    },
    performSearch() {
      this.updateSearchValue(this.searchValue)
      this.resetPageLength()
    },
    performSearchDebounced: debounce(
      function () {
        this.performSearch()
      },
      300,
      { maxWait: 1000 }
    ),
    calculateIntakeArea({ inArea, applicationType }) {
      if (applicationType === APPLICATION_OFFER_TYPE_NAME.SHS_OFFER) {
        return '-'
      }
      return inArea ? 'In area' : 'Out-of-area'
    },
    openApplication(item) {
      this.$router.push(`/application/${this.schoolCode}/${item.applicationID}`)
    },
    updateStatusFilter(selectedOptions) {
      this.$store.commit('setStatusFilter', selectedOptions)
    },
    updateApplicationTypeFilter(selectedOptions) {
      this.$store.commit('setApplicationTypeFilter', selectedOptions)
    },
    updateIntakeFilter(selectedOptions) {
      this.$store.commit('setIntakeFilter', selectedOptions)
    },
    updateSrnLinkFilter(selectedOptions) {
      this.$store.commit('setSrnLinkFilter', selectedOptions)
    },
    updateScholasticYearFilter(selectedOptions) {
      this.$store.commit('setScholasticYearFilter', selectedOptions)
    },
    updateYearFilter(selectedOptions) {
      this.$store.commit('setStartYearFilter', selectedOptions)
    },
    updateStartDateFilter(dateRange) {
      this.$store.commit('setStartDateFilter', dateRange)
    },
    clearFilters() {
      this.$store.commit('setStatusFilter', [])
      this.$store.commit('setScholasticYearFilter', [])
      this.$store.commit('setStartYearFilter', [])
      this.$store.commit('setStartDateFilter', [])
      this.$store.commit('setIntakeFilter', [])
      this.$store.commit('setSrnLinkFilter', [])
      this.$store.commit('setApplicationTypeFilter', [])
    },
    clearAllFilters() {
      this.$refs.statusFilter.clearFilter()
      this.$refs.scholasticYrFilter.clearFilter()
      this.$refs.startDateFilter && this.$refs.startDateFilter.clearFilter()
      this.$refs.intakeFilter?.clearFilter()
      this.$refs.srnLinkedFilter?.clearFilter()
      this.$refs.applicationTypeFilter?.clearFilter()
      this.performSearch()
    },
    filterApplications(value, search, item) {
      return searchFilterForStringOnly(value, search, item)
    },
    trackSearch() {
      if (this.searchValue) {
        this.$gtm.trackEvent({
          category: 'search',
          action: 'searchBoxFiltered',
          label: this.mode
        })
      }
    },
    sendFilterAnalytics(opened) {
      // send analytics data when each filter is closed
      if (!opened) {
        const statusData = this.statusFilter.length
          ? JSON.stringify(this.statusFilter.sort())
          : null

        const yearData = this.scholasticYearFilter.length
          ? JSON.stringify(sortScholasticYearCodes(this.scholasticYearFilter))
          : null

        // translate "[2020-01-01,2020-12-12]" to "20200101-20201212"
        const dateRangeData = this.startDateFilter.length
          ? JSON.stringify(
              this.startDateFilter.map((d) => d.replaceAll('-', '')).join('-')
            )
          : null

        const consolidatedData = (
          this.mode === 'pending'
            ? `${statusData},${yearData},${dateRangeData}`
            : `${statusData},${yearData}`
        ).replaceAll('"', '')

        this.$gtm.trackEvent({
          category: 'applicationFilter',
          action: this.mode,
          label: consolidatedData
        })
      }
    },
    totalResults(pagination) {
      this.itemsLength = pagination ? pagination.itemsLength : 0
    },
    resultsText(itemsLength) {
      return itemsLength === 1
        ? itemsLength + ' result'
        : itemsLength + ' results'
    }
  },
  activated() {
    this.resetPageLength()
    this.searchValue = this.searchApplicationsFilter
  },
  created() {
    this.searchValue = this.searchApplicationsFilter
    if (
      this.redirectFromY67TToLocalPending ||
      this.redirectFromY67TToOoaPending
    ) {
      // Shared default filters
      const currentYear = new Date().getFullYear()
      const nextYear = (currentYear + 1).toString()
      this.selectedReceivedYear = [nextYear]
      this.updateYearFilter(nextYear)
      this.defaultScholasticYrFilter = ['Year 7']
      this.defaultSrnLinkedFilter = ['Unlinked']

      if (this.redirectFromY67TToLocalPending) {
        this.defaultApplicationTypeFilter = ['Local']
        this.defaultIntakeFilter = ['In area']
        this.$store.commit('set', ['redirectFromY67TToLocalPending', false])
      }

      if (this.redirectFromY67TToOoaPending) {
        this.defaultApplicationTypeFilter = ['Out-of-area offer']
        this.defaultIntakeFilter = ['Out-of-area']
        this.$store.commit('set', ['redirectFromY67TToOoaPending', false])
      }
    } else {
      this.clearFilters()
    }
  },
  watch: {
    mode() {
      // clear filters on change of mode
      this.clearFilters()
      this.resetPageLength()
      this.selectedReceivedYear = []
      this.defaultScholasticYrFilter = []
      this.defaultApplicationTypeFilter = []
      this.defaultIntakeFilter = []
      this.defaultSrnLinkedFilter = []
    },
    schoolCode() {
      this.updateSearchValue(this.searchValue)
      // clear filters on change of school switcher
      this.clearFilters()
      this.selectedReceivedYear = []
      this.defaultScholasticYrFilter = []
      this.defaultApplicationTypeFilter = []
      this.defaultIntakeFilter = []
      this.defaultSrnLinkedFilter = []
    },
    searchValue() {
      this.updateSearchValue(this.searchValue)
    }
  },
  computed: {
    ...mapGetters({
      schoolCode: 'selectedSchoolCode',
      pendingApplications: 'pendingApplications',
      pendingApplicationsFiltered: 'pendingApplicationsFiltered',
      processedApplications: 'processedApplications',
      processedApplicationsFiltered: 'processedApplicationsFiltered',
      searchApplicationsFilter: 'searchApplicationsFilter',
      statusFilter: 'statusFilter',
      scholasticYearFilter: 'scholasticYearFilter',
      startYearFilter: 'startYearFilter',
      startDateFilter: 'startDateFilter',
      intakeFilter: 'intakeFilter',
      srnLinkFilter: 'srnLinkFilter',
      applicationTypeFilter: 'applicationTypeFilter',
      redirectFromY67TToLocalPending: 'redirectFromY67TToLocalPending',
      redirectFromY67TToOoaPending: 'redirectFromY67TToOoaPending'
    }),
    headers() {
      const headers = []
      if (this.mode === 'processed') {
        headers.push(headerDefinitions.lastModifiedDate)
      }
      headers.push(headerDefinitions.dateReceived)
      headers.push(headerDefinitions.name)
      if (this.mode === 'pending') {
        headers.push(headerDefinitions.studentDOB)
      }
      headers.push(headerDefinitions.scholasticYear)
      headers.push(headerDefinitions.intendedStartDate)
      headers.push(headerDefinitions.applicationType)
      headers.push(headerDefinitions.inArea)
      if (this.mode === 'pending') {
        headers.push(headerDefinitions.srnLinked)
      }
      headers.push(headerDefinitions.applicationStatus)

      if (this.mode === 'pending') {
        headers.push(headerDefinitions.alertsFound)
      }

      return headers
    },
    applications() {
      if (this.mode === 'pending') {
        return this.pendingApplicationsFiltered(this.schoolCode)
      }

      return this.processedApplicationsFiltered(this.schoolCode)
    },
    statusFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)
      return [
        ...new Set(applications.map((app) => app.applicationStatus))
      ].sort()
    },
    applicationTypeFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)
      return [...new Set(applications.map((app) => app.applicationType))].sort()
    },
    intakeFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)
      return [
        ...new Set(
          applications.map((app) => {
            if (app.inArea) {
              return 'In area'
            }
            return 'Out-of-area'
          })
        )
      ].sort()
    },
    srnLinkFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)
      return [
        ...new Set(
          applications.map((app) => {
            if (app.srn) {
              return 'Linked'
            }
            return 'Unlinked'
          })
        )
      ].sort()
    },
    isFiltered() {
      return (
        Boolean(this.statusFilter.length) ||
        Boolean(this.scholasticYearFilter.length) ||
        Boolean(this.startYearFilter.length) ||
        Boolean(this.startDateFilter.length) ||
        Boolean(this.intakeFilter.length) ||
        Boolean(this.srnLinkFilter.length) ||
        Boolean(this.applicationTypeFilter.length)
      )
    },
    scholasticYearFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)

      const applicationScholasticYear = [
        ...new Set(applications.map((app) => app.scholasticYear))
      ]
      return applicationScholasticYear.sort(function (a, b) {
        return sortByScholasticYear.indexOf(a) - sortByScholasticYear.indexOf(b)
      })
    },
    startDateFilters() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)
      return { disabled: applications.length ? false : true }
    },
    yearOptions() {
      const applications =
        this.mode === 'pending'
          ? this.pendingApplications(this.schoolCode)
          : this.processedApplications(this.schoolCode)

      if (this.mode === 'pending') {
        return [
          ...new Set(
            applications.map((app) =>
              new Date(app.intendedStartDate).getFullYear().toString()
            )
          )
        ].sort()
      } else {
        const currentYear = new Date().getFullYear()
        const twoYearsAgo = currentYear - 2
        const nextYear = currentYear + 1

        let processedYearOptions = []
        const tempYears = [twoYearsAgo, currentYear, nextYear].map(String)
        tempYears.map((year) => {
          if (
            applications.find(
              (app) => app.intendedStartDate.substring(0, 4) === year
            )
          ) {
            processedYearOptions.push(year)
          }
        })
        return processedYearOptions
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
