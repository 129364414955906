<template>
  <div class="px-8 pt-4 pb-2">
    <v-row>
      <v-col>
        <span class="considerations-docs-label">Supporting documents</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="considerations-docs">
        <div
          class="considerations-docs"
          v-for="(record, index) in digitizeEoiConsiderations"
          :key="index"
        >
          <div class="considerations-docs-file float-left">
            <v-icon>attach_file</v-icon> {{ record.name }}
          </div>
          <div
            v-if="!isOesSupport && isERNAdmin"
            class="considerations-docs-view float-right"
          >
            <AdsButton
              data-testid="viewEyeButton"
              class="mr-2 considerations-view-btn"
              x-large
              secondary
              button-text="View"
              icon="mdi-eye-outline"
              @click="viewSupportingDocument(record.type, record.objectKey)"
              :loading="loading"
            />
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AdsButton } from '@nswdoe/doe-ui-core'
import { USER_GROUP } from '@/constants'

export default {
  name: 'ConsiderationsDocuments',
  components: {
    AdsButton
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['application', 'selectedSchool']),
    digitizeEoiConsiderations() {
      return this.application.supportingDocuments.digitizeEoiConsiderations
    },
    staffUserEmailId() {
      return `${this.$store.state.userID}@det.nsw.edu.au`
    },
    isOesSupport() {
      return this.$store.state.userGroup === USER_GROUP.OES_SUPPORT
    },
    isERNAdmin() {
      return this.$store.state.userGroup === USER_GROUP.ERN_ADMIN
    }
  },
  methods: {
    viewSupportingDocument(type, objectKey) {
      this.$store.dispatch('showSpinner')
      const objectKeyArray = objectKey.split('/')
      const eoiID = objectKeyArray[1]
      const file = {}
      file.name = `${objectKeyArray[3]}/${objectKeyArray[4]}`
      file.type = type
      this.$store
        .dispatch('viewY67TSupportingDocument', {
          file,
          schoolCode: this.application.schoolCode,
          primarySchoolCode: this.application.currentPrimarySchool.schoolCode,
          applicationId: eoiID,
          uploadedBy: this.staffUserEmailId,
          stream: 'y67t'
        })
        .then(() => {
          this.$store.dispatch('hideSpinner')
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.considerations-docs-label {
  font-size: 14px;
  color: $grey-2;
}
.considerations-docs {
  background: $grey-light-10 !important;
  border-radius: 4px !important;
  min-height: 60px;
}
.considerations-docs-file {
  padding-top: 18px;
}
</style>
