import { render, staticRenderFns } from "./SrnSearchSiblingDialog.vue?vue&type=template&id=7ffa874c&scoped=true&"
import script from "./SrnSearchSiblingDialog.vue?vue&type=script&lang=js&"
export * from "./SrnSearchSiblingDialog.vue?vue&type=script&lang=js&"
import style0 from "./SrnSearchSiblingDialog.vue?vue&type=style&index=0&id=7ffa874c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ffa874c",
  null
  
)

export default component.exports