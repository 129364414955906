<template>
  <div>
    <svg
      width="27"
      height="23"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.95 23V20.2941H17.55V17.5882H22.95V14.8824L27 18.9412L22.95 23ZM14.85 18.9412C14.85 19.9018 15.0525 20.8218 15.417 21.6471H0V17.5882C0 14.5982 4.833 12.1765 10.8 12.1765C12.15 12.1765 13.446 12.2982 14.6475 12.5282C15.768 12.7447 16.794 13.0424 17.6985 13.4212C15.9705 14.6524 14.85 16.6682 14.85 18.9412ZM2.7 17.5882V18.9412H12.15C12.15 17.5341 12.4605 16.1947 13.014 14.9906L10.8 14.8824C6.3315 14.8824 2.7 16.1 2.7 17.5882ZM10.8 0C12.2322 0 13.6057 0.570166 14.6184 1.58507C15.6311 2.59997 16.2 3.97648 16.2 5.41176C16.2 6.84705 15.6311 8.22356 14.6184 9.23846C13.6057 10.2534 12.2322 10.8235 10.8 10.8235C9.36783 10.8235 7.99432 10.2534 6.98162 9.23846C5.96893 8.22356 5.4 6.84705 5.4 5.41176C5.4 3.97648 5.96893 2.59997 6.98162 1.58507C7.99432 0.570166 9.36783 0 10.8 0ZM10.8 2.70588C10.0839 2.70588 9.39716 2.99097 8.89081 3.49842C8.38446 4.00587 8.1 4.69412 8.1 5.41176C8.1 6.12941 8.38446 6.81766 8.89081 7.32511C9.39716 7.83256 10.0839 8.11765 10.8 8.11765C11.5161 8.11765 12.2028 7.83256 12.7092 7.32511C13.2155 6.81766 13.5 6.12941 13.5 5.41176C13.5 4.69412 13.2155 4.00587 12.7092 3.49842C12.2028 2.99097 11.5161 2.70588 10.8 2.70588Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'PersonArrow'
}
</script>

<style scoped lang="scss">
.v-icon.v-icon svg {
  border-radius: 0;
  fill: currentColor;
}

.v-avatar .v-icon svg {
  fill: currentColor;
  transform: translateX(5%);
}
</style>
