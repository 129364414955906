<template>
  <transition-group class="preference-list" name="list" tag="ol">
    <li
      v-for="(item, index) in internalItems"
      :key="getItemKey(item)"
      class="list-item list-complete-item"
    >
      <slot name="item" :item="item" :index="index">
        <slot name="itemText" :item="item" :index="index">
          <span class="list-item__text">
            {{ getItemText(item) }}
          </span>
        </slot>
        <slot name="actions" :item="item" index="index">
          <span class="list-item__actions">
            <v-btn
              class="action action--move-up no-outline"
              :disabled="index === 0"
              icon
              :aria-label="moveUpLabel(item)"
              @click="moveUpHandler(index)"
            >
              <v-icon class="action__icon action__icon--up">
                mdi-arrow-up
              </v-icon>
            </v-btn>
            <v-btn
              class="action action--remove no-outline"
              icon
              :aria-label="removeLabel(item)"
              @click="removeHandler(index)"
            >
              <v-icon class="action__icon action__icon--remove">
                mdi-close
              </v-icon>
            </v-btn>
          </span>
        </slot>
      </slot>
    </li>
  </transition-group>
</template>

<script>
import _get from 'lodash/get'
import { numToOrdinal } from '@/helpers/generalUtils'

const EVENTS = {
  UPDATE_ITEMS: 'update:items'
}

export default {
  name: 'PreferenceList',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemText: {
      type: String
    },
    itemKey: {
      type: String
    },
    deferRemove: {
      type: Boolean,
      default: false
    },
    deferMoveUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dataItems: [...this.items]
    }
  },
  computed: {
    internalItems: {
      get() {
        return this.dataItems
      },
      set(value) {
        this.dataItems = value
        this.$emit(EVENTS.UPDATE_ITEMS, value)
      }
    }
  },
  watch: {
    items(newValue) {
      this.dataItems = newValue
    }
  },
  methods: {
    getItemText(item) {
      if (typeof item === 'string') {
        return item
      }
      return _get(item, this.itemText)
    },
    getItemKey(item) {
      if (typeof item === 'string') {
        return item
      }
      if (this.itemKey) {
        return _get(item, this.itemKey)
      }
      return _get(item, this.itemText)
    },
    moveUpCallback(index) {
      return () => {
        if (index > 0) {
          // Required this roundabout way of setting `internalItems`
          // due to how Vue's reactivity works
          const temp = this.internalItems[index]
          const newItemOrder = this.internalItems
          newItemOrder[index] = this.internalItems[index - 1]
          newItemOrder[index - 1] = temp
          this.internalItems = [...newItemOrder]
        }
      }
    },
    moveUpHandler(index) {
      if (index > 0) {
        document.activeElement.blur() // If element remains focused the animations behave strangely
        const callback = this.moveUpCallback(index)
        const eventObject = {
          lowerItem: this.internalItems[index],
          upperItem: this.internalItems[index - 1],
          lowerIndex: index,
          upperIndex: index - 1
        }
        if (this.deferMoveUp) {
          this.$emit('moveUp', { ...eventObject, callback })
        } else {
          callback()
          this.$emit('moveUp', eventObject)
        }
      }
    },
    removeCallback(index) {
      return () => {
        // Required this roundabout way of setting `internalItems` due to how Vue's reactivity works
        const newItems = this.internalItems
        newItems.splice(index, 1)
        this.internalItems = [...newItems]
      }
    },
    removeHandler(index) {
      document.activeElement.blur() // If element remains focused the animations behave strangely
      const callback = this.removeCallback(index)
      const eventObject = { item: this.internalItems[index], index }
      if (this.deferRemove) {
        this.$emit('remove', { ...eventObject, callback })
      } else {
        callback()
        this.$emit('remove', eventObject)
      }
    },
    moveUpLabel(item, index) {
      return `Move up ${this.getItemText(item)}. Currently ${numToOrdinal(
        index + 1
      )} of ${this.items.length}`
    },
    removeLabel(item) {
      return `Remove ${this.getItemText(item)}`
    }
  }
}
</script>

<style lang="scss" scoped>
.list-item {
  display: flex;
  align-items: center;

  &__actions {
    display: flex;
    margin-left: auto;
  }
}

ol {
  padding-left: 0;
  list-style: none;
  counter-reset: pref-counter;
}

li {
  counter-increment: pref-counter;
  padding: 1rem 0;
  font-weight: 500;
  overflow: hidden;

  &:not(:last-child) {
    border-bottom: 1px solid $ads-light-20;
  }

  &::before {
    content: counter(pref-counter);
    background: $ads-blue-1;
    width: 2.25rem;
    min-width: 2.25rem;
    height: 2.25rem;
    font-weight: bold;
    border-radius: 50%;
    display: inline-block;
    line-height: 2.25rem;
    color: white;
    text-align: center;
    margin-right: 1rem;
  }
}

.v-icon.action__icon {
  color: $ads-navy;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 300ms;
  transition-property: all;
}

.list-enter {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}

.list-leave-active,
.list-enter-to {
  max-height: 10rem; // larger than it should ever need to be
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.list-leave-to {
  padding-top: 0;
  padding-bottom: 0;
  max-height: 0;
}
.no-outline {
  border: none !important;
}
.v-btn {
  &:focus {
    border: 2px solid $ads-navy !important;
  }
}
</style>
