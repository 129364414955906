<template>
  <nav
    v-if="currentNum != 0 && totalNum != 0"
    aria-label="pagination"
    class="recordPagination"
    data-testid="archive-view-pagination"
  >
    <div class="ArchiveRecordPagination">
      <v-btn
        class="mx-0"
        icon
        text
        color="primary"
        :disabled="currentNum === 1"
        @click="prev"
        aria-label="Previous record"
        data-testid="archive-view-previous"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn
        class="mx-0"
        icon
        text
        color="primary"
        :disabled="currentNum === totalNum"
        @click="next"
        aria-label="Next record"
        data-testid="archive-view-next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <div class="count">{{ currentNum }} of {{ totalNum }}</div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ArchiveRecordPagination',
  computed: {
    ...mapGetters({
      filteredArchiveRecords: 'archiving/filteredArchiveRecords'
    }),
    currentNum() {
      return this.filteredArchiveRecords.indexOf(this.currentIdKey) + 1
    },
    currentIdKey() {
      return this.$router?.currentRoute?.path
    },
    totalNum() {
      return this.filteredArchiveRecords.length
    }
  },
  methods: {
    prev() {
      const prevIdx = this.currentNum - 2
      const prevRecord = this.filteredArchiveRecords[prevIdx]
      this.$router.push(prevRecord)
    },
    next() {
      const nextIdx = this.currentNum
      const nextRecord = this.filteredArchiveRecords[nextIdx]
      this.$router.push(nextRecord)
    }
  }
}
</script>

<style scoped lang="scss">
.ArchiveRecordPagination {
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: flex-end;

  .count {
    min-width: 60px;
    font-size: 1em;
  }

  .v-btn {
    border-color: transparent;
    color: white !important;
    &:hover {
      text-decoration: none;
    }

    &:focus {
      color: white !important;
      border: 3px solid white;
    }
    &:focus::before {
      opacity: 0;
    }
  }
  .v-btn--icon.v-size--default {
    height: 32px;
    width: 32px;
  }
}
</style>
