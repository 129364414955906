<template>
  <div>
    <v-checkbox
      v-bind="$attrs"
      v-on="$listeners"
      ref="oesCheckbox"
      :ripple="false"
      :aria-describedby="`checkbox-${_uid}`"
      @change="handleChange"
    >
      <!-- pass through slots -->
      <template v-for="(_, slotName) of $slots" #[slotName]>
        <slot :name="slotName" />
      </template>
    </v-checkbox>
    <span class="sr-only" :id="`checkbox-${_uid}`">
      {{ isSelected ? 'checked' : 'not checked' }}</span
    >
  </div>
</template>

<script>
/**
 * TODO: Attention VUE 3 developers
 * This component has been updated temporarily for accessibility bug (FUS-2642)
 * Delete the aria-describedby and handleChange related stuff as part of Vue 3 / ADS 3 upgrade.
 * Please refer the FUS-2642 bug for pull request
 */
export default {
  name: 'OesCheckbox',
  // override the default value/input for v-model binding
  // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
  model: {
    prop: 'inputValue',
    event: 'change'
  },
  data() {
    return {
      isSelected: false
    }
  },
  mounted() {
    this.handleChange(this.$attrs['input-value'])
  },
  methods: {
    handleChange($event) {
      if (Array.isArray($event)) {
        this.isSelected = $event.includes(this.$attrs.value)
      } else {
        this.isSelected = $event
      }
    }
  }
}
</script>
