<template>
  <div
    v-if="appCountConfig.appCount != 0"
    class="appCountWrapper"
    tabindex="0"
    @keyup.enter="changeStream(appCountConfig)"
    @click="changeStream(appCountConfig)"
  >
    <div class="titleWrapper">
      <v-icon size="24" class="iconName">{{ appCountConfig.icon }}</v-icon>
      <div class="countTitle">{{ appCountConfig.countTitle }}</div>
    </div>
    <div class="appCount">{{ appCountConfig.appCount }}</div>
    <div class="countSubtitle">{{ appCountConfig.countSubtitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'AppCountCard',
  props: {
    appCountConfig: {
      type: Object
    }
  },
  methods: {
    changeStream(stream) {
      this.$eventHub.$emit('changeSelectedStream', stream)
    }
  }
}
</script>

<style lang="scss" scoped>
$app-count-padding-x: 24px;

.appCountWrapper {
  cursor: pointer;
  position: relative;

  height: 11.25rem;
  background: $ads-white;
  border-radius: 4px;
  border: 1px solid #cdd3d6;
  border-spacing: 2px;
  padding: 0 $app-count-padding-x;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background: #f5fcff;
    border: 1px solid $ads-primary-blue;
  }

  &:focus,
  &:active {
    background: #f5fcff;
    outline: 3px solid $ads-blue-2;
    border-spacing: 2px;
  }

  &:active {
    .countTitle,
    .countSubtitle {
      text-decoration: underline $ads-primary-blue;
    }
  }
}

.titleWrapper {
  top: 1.25rem;
  height: 1.5rem;
  display: flex;
  position: absolute;
  width: calc(100% - #{$app-count-padding-x} - #{$app-count-padding-x});

  .v-icon {
    margin-top: -1px;
    -webkit-box-align: inherit;
    align-items: unset;
  }
}

.countTitle {
  position: relative;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  color: $ads-primary-blue;
  padding-left: 0.5rem;

  @media (max-width: 576px) {
    font-size: 1.2rem;
  }
}

.appCount {
  color: $ads-primary-blue;
  text-align: center;
  font-weight: bold;
  font-size: 50px;
  margin: auto;
}

.countSubtitle {
  top: 7.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - #{$app-count-padding-x} - #{$app-count-padding-x});
  position: absolute;
  color: $ads-primary-blue;
  text-align: center;

  @media (max-width: 576px) {
    font-size: 0.9rem;
  }
}
</style>
