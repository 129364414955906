<template>
  <div>
    <div>
      <v-icon class="humanIcon">mdi-human-male-board</v-icon>
      <span class="SreText"> Special Religious Education (SRE) options</span>
    </div>
    <div v-if="sreOptions.length === 0" class="noSreOptionWrapper">
      <div class="noSreOption">
        No SRE options are currently set up. Click Add New to add a new SRE
        option.
      </div>
    </div>
    <v-form ref="sreForm" v-else class="sre-option-wrapper">
      <AppSettingsSreSeeOptions
        :disabled="disabled"
        :default-sre-see-options="defaultSreOptions"
        :options="sreOptions"
        @updatedSreSeeOptions="setSreOptions"
        ref="sreOptions"
        :is-duplicate-option="isDuplicateOption"
      />
    </v-form>
    <div class="addNewBtn">
      <AdsButton
        v-if="showAddNewButton"
        secondary
        :disabled="disabled"
        button-text="Add new"
        aria-label="Add new"
        icon="mdi-plus-circle-outline"
        @click="addNewSreOption"
      />
    </div>
  </div>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import AppSettingsSreSeeOptions from './AppSettingsSreSeeOptions.vue'
import settingsUtils from '@/helpers/settingsUtils'
import API from '@/store/apiUtils'

export default {
  name: 'AppSettingsSreOptions',
  components: { AdsButton, AppSettingsSreSeeOptions },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    defaultSreOptions: {
      type: Array,
      default: () => []
    },
    savedSreOptions: {
      type: Array,
      default: () => []
    },
    availableScholasticYears: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sreOptions: [],
      isDuplicateOption: false
    }
  },
  mounted() {
    this.$eventHub.$on('validate', this.validate)
    if (this.savedSreOptions?.length > 0) {
      // FUS-1813: Changes to eslint rules now disallow the mutation of
      // component props. The error for the next line has been disabled
      // as the component is currently working.
      // eslint-disable-next-line vue/no-mutating-props
      this.savedSreOptions
        .sort((o1, o2) => (o1.name < o2.name ? -1 : 1))
        .map((saved) => {
          const option = {
            id: saved.id,
            guid: saved.guid,
            religion: saved.name,
            name: saved.name,
            religions: this.defaultSreOptions.map(({ name }) => name),
            providers: saved.providers,
            availableProviders: this.getProviders(saved.name),
            scholasticYears: saved.scholasticYears,
            availableScholasticYears: this.availableScholasticYears,
            description: saved.description,
            curriculumLink: saved.curriculumLink
          }
          this.sreOptions.push(option)
        })
    }
    this.$nextTick(function () {
      this.$emit('validateForm')
    })
  },
  computed: {
    showAddNewButton() {
      return this.sreOptions.length < 15
    }
  },
  methods: {
    validate() {
      if (this.$refs.sreForm) {
        this.isDuplicateOption = settingsUtils.checkDuplicateOption(
          this.sreOptions
        )
        this.$emit('duplicateSreOption', this.isDuplicateOption)
        this.$refs.sreForm.validate()
      }
    },
    getProviders(religion) {
      const options = this.defaultSreOptions.find(
        (option) => option.name === religion
      )
      return options?.providers || []
    },
    addNewSreOption() {
      const option = {
        religion: '',
        guid: API.getGuid(),
        religions: this.defaultSreOptions.map(({ name }) => name),
        providers: [],
        availableProviders: [],
        scholasticYears: [],
        availableScholasticYears: this.availableScholasticYears,
        description: '',
        curriculumLink: '',
        availableReligions: this.defaultSreOptions
      }

      this.isDuplicateOption = settingsUtils.checkDuplicateOption(
        this.sreOptions
      )

      this.$emit('duplicateSreOption', this.isDuplicateOption)
      this.$nextTick(() => {
        this.sreOptions.length === 0 ||
        (this.sreOptions.length > 0 &&
          this.$refs.sreForm.validate() &&
          this.sreOptions.every(
            (option) => option.scholasticYears.length > 0
          ) &&
          !this.isDuplicateOption)
          ? this.sreOptions.push(option)
          : this.sreOptions
        if (this.sreOptions.length > 0) {
          const lastIndex = this.sreOptions.length - 1
          this.focusOnSreOptionsChanged(lastIndex)
        }
      })
    },
    setSreOptions(options) {
      this.isDuplicateOption = settingsUtils.checkDuplicateOption(
        this.seeOptions
      )
      let opt
      for (opt in options) {
        options[opt].availableReligions = this.defaultSreOptions
      }

      this.$emit('duplicateSeeOption', this.isDuplicateOption)
      this.$emit('updatedSreOptions', options, false)
    },
    focusOnSreOptionsChanged(lastIndex) {
      this.$nextTick(() => {
        const sreOptionsRefs = this.$refs.sreOptions.$refs
        if (sreOptionsRefs) {
          const sreSeeOptionRemoveBtnRefName =
            'sreSeeOptionRemoveBtn-' + lastIndex
          const nestedElementRefs = sreOptionsRefs[sreSeeOptionRemoveBtnRefName]
          if (nestedElementRefs && nestedElementRefs.length > 0) {
            const elementRef = nestedElementRefs[0]
            if (elementRef) {
              elementRef.$el.focus()
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.humanIcon {
  border-radius: 50%;
  background: $ads-light-blue;
  color: $ads-navy;
  min-width: 46px;
  height: 46px;
}

.SreText {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: $color-primary;
  padding-left: 11px;
}

.addNewBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.noSreOption {
  box-shadow: 0px 1px 4px $ads-light-40;
  border-radius: 4px;
  padding: 32px 0 38px 54px;
  margin-top: 2.5rem;
}

.noSreOptionWrapper,
.sre-option-wrapper,
.addNewBtn {
  max-width: 808px;
}

.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
