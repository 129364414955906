import UTILS from '@/store/utils'
import ApiUtils from '@/store/apiUtils'

const monthToIso = (str) => {
  if (/^[0|1][0-9]\/[0-9]{4}$/.test(str)) {
    const [month, year] = str.split('/')
    return `${year}-${month}-01`
  }
  return str
}

const preparePutData = (data, cleanData) => {
  const app = UTILS.clone(data)

  const prevSchool = app.previousSchool
  if (prevSchool) {
    prevSchool.prevSchoolStartDate = monthToIso(prevSchool.prevSchoolStartDate)
    prevSchool.prevSchoolLastDate = monthToIso(prevSchool.prevSchoolLastDate)
    if (prevSchool.prevSchoolType === 'O') {
      prevSchool.prevSchoolTypeValue = 'OVS'
    }
  }

  // if the start date changed, toggle the enterStartDateSelected flag
  if (app.intendedStartDate !== cleanData.intendedStartDate) {
    app.enterStartDateSelected = true
  }

  ApiUtils.removeEmptyKeys(app, false)

  // this flag is only used to toggle the multiple applications notification (Form builder needs it to render). API doesn't need it, therefore delete on save.
  delete app.applicationOwnerHasMultipleApplications

  return app
}

export default { preparePutData }
