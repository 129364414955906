<template>
  <div>
    <svg
      width="28"
      height="31"
      viewBox="0 0 28 31"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.523099 10.611L13.1071 18.62C13.2914 18.7385 13.506 18.8015 13.7251 18.8015C13.9442 18.8015 14.1588 18.7385 14.3431 18.62L26.9271 10.612C27.0877 10.5083 27.2197 10.366 27.3111 10.1981C27.4025 10.0302 27.4504 9.84214 27.4504 9.65099C27.4504 9.45984 27.4025 9.27173 27.3111 9.10386C27.2197 8.93599 27.0877 8.7937 26.9271 8.68999L14.3421 0.680989C14.1578 0.562506 13.9432 0.499512 13.7241 0.499512C13.505 0.499512 13.2904 0.562506 13.1061 0.680989L0.523099 8.68899C0.362524 8.7927 0.230496 8.93498 0.139075 9.10286C0.0476545 9.27073 -0.000244141 9.45884 -0.000244141 9.64999C-0.000244141 9.84114 0.0476545 10.0292 0.139075 10.1971C0.230496 10.365 0.362524 10.5073 0.523099 10.611ZM13.7231 3.00399L24.1801 9.64999L13.7241 16.297L3.2681 9.64999L13.7231 3.00399Z"
      />
      <path
        d="M25.6912 14.409L13.7242 22.016L1.75819 14.409C1.5037 14.2628 1.20277 14.2201 0.917633 14.2895C0.632497 14.359 0.384966 14.5354 0.226227 14.7822C0.0674888 15.0291 0.0096849 15.3275 0.0647702 15.6158C0.119855 15.904 0.283617 16.1601 0.522187 16.331L13.1082 24.339C13.2925 24.4575 13.507 24.5205 13.7262 24.5205C13.9453 24.5205 14.1598 24.4575 14.3442 24.339L26.9282 16.331C27.1668 16.1601 27.3305 15.904 27.3856 15.6158C27.4407 15.3275 27.3829 15.0291 27.2241 14.7822C27.0654 14.5354 26.8179 14.359 26.5327 14.2895C26.2476 14.2201 25.9467 14.2628 25.6922 14.409H25.6912Z"
      />
      <path
        d="M25.6912 20.129L13.7242 27.736L1.75819 20.129C1.5037 19.9828 1.20277 19.9401 0.917633 20.0095C0.632497 20.079 0.384966 20.2554 0.226227 20.5022C0.0674888 20.7491 0.0096849 21.0475 0.0647702 21.3358C0.119855 21.624 0.283617 21.8801 0.522187 22.051L13.1082 30.059C13.2925 30.1775 13.507 30.2405 13.7262 30.2405C13.9453 30.2405 14.1598 30.1775 14.3442 30.059L26.9282 22.051C27.1668 21.8801 27.3305 21.624 27.3856 21.3358C27.4407 21.0475 27.3829 20.7491 27.2241 20.5022C27.0654 20.2554 26.8179 20.079 26.5327 20.0095C26.2476 19.9401 25.9467 19.9828 25.6922 20.129H25.6912Z"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'LayersIcon'
}
</script>

<style scoped lang="scss">
.v-icon.v-icon svg {
  border-radius: 0;
  fill: currentColor;
}
</style>
