<template>
  <TabNavigation
    v-model="ooaTab"
    :items="ooaTabItems"
    text-transform="none"
    :key="key"
    @tabChange="handleTabChange"
  >
    <template slot="content1">
      <OoaDataTable
        :ooa-applications="ooaPendingApplications"
        :loading="loading"
        :is-pending-tab="true"
      />
    </template>
    <template slot="content2">
      <OoaDataTable
        :ooa-applications="ooaProcessedApplications"
        :loading="loading"
      />
    </template>
  </TabNavigation>
</template>

<script>
import { TabNavigation } from '@nswdoe/doe-ui-core'
import OoaDataTable from './OoaDataTable.vue'
import { mapGetters } from 'vuex'
import { OOA_STATUS } from '@/constants'

export default {
  name: 'OoaTabDataTable',
  components: { TabNavigation, OoaDataTable },
  data() {
    return {
      loading: false,
      ooaTab: 0,
      ooaTabItems: [{ tab: 'PENDING' }, { tab: 'PROCESSED' }],
      key: 0
    }
  },
  props: {},
  methods: {
    // This is to keep the search field consistent for pending and processed applications data table
    handleTabChange(index) {
      this.key = Math.random()
      this.ooaTab = index
    }
  },
  computed: {
    ...mapGetters([
      'selectedSchoolCode',
      'ooaApplications',
      'schoolOoaSettings',
      'searchApplicationsFilter',
      'filteredApplicationIds'
    ]),
    allOoaApplications() {
      return this.ooaApplications(this.selectedSchoolCode)
    },
    ooaPendingApplications() {
      const ooaPendingStatus = [
        OOA_STATUS.NEW,
        OOA_STATUS.VIEWED,
        OOA_STATUS.IN_REVIEW,
        OOA_STATUS.INTERVIEW_REQUIRED,
        OOA_STATUS.SHORTLISTED,
        OOA_STATUS.WAITLISTED
      ]
      return this.allOoaApplications.filter((application) =>
        ooaPendingStatus.includes(application.applicationStatus)
      )
    },
    ooaProcessedApplications() {
      const ooaProcessedStatus = [
        OOA_STATUS.OFFERED,
        OOA_STATUS.DECLINED,
        OOA_STATUS.WITHDRAWN,
        OOA_STATUS.ACCEPTED,
        OOA_STATUS.NOT_ACCEPTED,
        OOA_STATUS.OFFER_EXPIRED,
        OOA_STATUS.OFFER_POST_APPEAL,
        OOA_STATUS.OFFER_OTHER,
        OOA_STATUS.DECLINED_POST_APPEAL
      ]
      return this.allOoaApplications.filter((application) =>
        ooaProcessedStatus.includes(application.applicationStatus)
      )
    }
  },
  activated() {
    // refresh the list in case the status changed in the details window
    this.loading = true
    this.$store.dispatch('getOoaApplications').finally(() => {
      this.loading = false
    })
  },
  created() {
    // load the list on first load
    this.loading = true
    this.$store.dispatch('getOoaApplications').finally(() => {
      this.loading = false
    })
  },
  watch: {
    selectedSchoolCode() {
      this.$store.dispatch('getOoaApplications')
    }
  },
  mounted() {
    this.$eventHub.$on('handleTabChange', this.handleTabChange)
  }
}
</script>
