<template>
  <div>
    <v-card flat data-testid="numbersskeleton">
      <v-card-title>
        <h1>{{ title }}</h1>
      </v-card-title>
      <hr />
      <v-card-text>
        <v-row>
          <v-col cols="3" md="3">
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-col>
          <v-col class="mr-15">
            <v-skeleton-loader type="image"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ComparisonSkeleton',
  props: {
    title: {
      type: String,
      default: 'Skeleton'
    }
  }
}
</script>
