<!--
  Provides the template for displaying all the application sections
-->
<template>
  <form
    :class="'ApplicationSections ' + applicationType"
    @submit.prevent="submit()"
    @reset.prevent="cancel()"
  >
    <section
      v-for="(section, index) in sections"
      :data-id="section.id"
      :key="section.id"
      class="section"
    >
      <SectionBar :section="section" :section-index="index + 1" />
      <div
        v-if="isSectionExpanded(section)"
        :class="isPrintPreview ? 'sectionFields' : 'sectionFields pl-16'"
        :id="section.id + '-fields'"
      >
        <FieldRow
          v-for="modelRow in getModelRowsForSection(section)"
          :key="modelRow.id"
          :model-row="modelRow"
        />
      </div>
    </section>
    <SaveCancel v-if="isEditing" />
  </form>
</template>

<script>
import SECTIONS_LA from '@/applicationDefinition/applicationSections'
import SectionBar from '@/components/application/SectionBar.vue'
import FieldRow from '@/components/form/FieldRow.vue'
import SaveCancel from '@/components/application/SaveCancel.vue'
import fieldHelperMixin from '@/helpers/fieldHelperMixin'
import { FIELD_TYPE } from '@/constants'
import { mapGetters } from 'vuex'

export default {
  name: 'ApplicationSections',
  components: {
    SectionBar,
    FieldRow,
    SaveCancel
  },
  mixins: [fieldHelperMixin],
  computed: {
    sections() {
      const self = this
      const updatedLASections = SECTIONS_LA.map((section) => {
        if (section.id === 'supporting-documents') {
          //new object needs to generated to preserve the reactivity of sections, therefore spread operator is used here. Otherwise the total doc number won't be get updated initially.
          return {
            ...section,
            label: `Supporting documents (${self.totalSupportingDocs})`
          }
        }
        return section
      })
      // If there is no sre/see configured, we won't display the sre/see section
      const hidingSreseeSection = !this.application?.sreSeeAmaSelection
      const filteredApplicationSections = updatedLASections.filter(
        (section) => section.id !== 'sre_see_ama'
      )
      const applicationSections = {
        LA: hidingSreseeSection
          ? filteredApplicationSections
          : updatedLASections
      }
      return applicationSections[this.applicationType]
    },
    applicationType() {
      return this.$store.state.applicationType
    },
    ...mapGetters(['totalSupportingDocs'])
  },
  methods: {
    getModelRowsForSection(section) {
      // Although model is a flat list, it represents a hierarchical application
      // field & record structure. Here we need to get just the root level fields
      // (depth 0) for the current section. We also have to exclude any records
      // as these will instead be recursively handled within the FieldRow component.
      return this.model.filter(
        (row) =>
          row.section.id === section.id &&
          row.depth === 0 &&
          row.type !== FIELD_TYPE.RECORD
      )
    },
    isSectionExpanded(section) {
      return (
        this.isPrintPreview ||
        this.$store.state.sectionExpandedId === section.id
      )
    },
    submit() {
      if (this.applicationType === 'OOA') {
        this.$store.dispatch('saveOoaApplication')
      } else {
        this.$store.dispatch('saveApplication')
      }
    },
    cancel() {
      this.$store.dispatch('cancelApplication')
    }
  }
}
</script>

<style scoped lang="scss">
.ApplicationSections .sectionFields {
  padding: 1rem;
}

.Y67T {
  padding: 0;
}
</style>
