<!-- 
  Provides a profile menu triggered by an user avatar
-->

<template>
  <v-menu content-class="profilemenu" offset-y left class="profile-menu">
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        v-on="on"
        v-bind="attrs"
        aria-label="Profile menu"
        :disabled="isEditing"
        class="profile-menu-btn circular-btn"
      >
        <v-avatar
          size="40"
          color="primary lighten-3"
          class="font-weight-bold primary--text subtitle-2 user-avatar"
          >{{ getInitials() }}</v-avatar
        >
      </v-btn>
    </template>
    <v-card class="mx-auto" max-width="400" width="266" tile>
      <v-list :nav="true">
        <v-list-item tabindex="0">
          <v-list-item-icon>
            <v-btn icon class="profileBtn" aria-label="Settings menu">
              <v-avatar color="primary lighten-3" size="48">
                <span class="font-weight-bold primary--text headline">{{
                  getInitials()
                }}</span>
              </v-avatar>
            </v-btn>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="font-weight-medium primary--text">{{
              this.userName
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- to be released later -->
        <!-- <v-divider></v-divider>
        <v-list-item>
          <v-list-item-icon>
            <v-icon class="font-weight-bold primary--text text--lighten-2">home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              <a
                class="font-weight-medium primary--text subtitle text-normal"
                small
                //this url needs to match the environment the user is logged in.
                href="https://liferaydxp.test.det.nsw.edu.au/group/staff-portal/update-my-profile"
              >Update Profile</a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>-->

        <v-divider></v-divider>
        <router-link class="font-weight-medium primary--text" to="/logout">
          <v-list-item tabindex="0">
            <v-list-item-icon>
              <v-avatar color="white" size="48">
                <!-- <span class="font-weight-bold primary--text title">{{ getInitials() }}</span> -->
                <v-icon class="rotate180 font-weight-bold primary--text"
                  >logout</v-icon
                >
              </v-avatar>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'AppProfileMenu',
  computed: {
    userName() {
      return this.$store.state.userName
    },
    isEditing() {
      return this.$store.getters.isEditing
    }
  },
  methods: {
    getInitials() {
      if (this.userName) {
        return this.userName
          .split(' ')
          .map((el) => el.charAt(0))
          .join('')
      }
      return ''
    }
  }
}
</script>

<style scoped lang="scss">
.rotate180 {
  transform: rotate(180deg);
}
.profilemenu.profilemenu {
  a {
    text-decoration: none;
  }

  .v-list-item__icon {
    margin-right: 16px;
  }
  .v-list-item__title {
    font-size: 16px;
    white-space: normal;
    text-overflow: initial;
  }
}

.user-avatar {
  cursor: pointer;
}
.profileBtn {
  border: 0;
}
.profile-menu-btn {
  border: 0;
}
.profile-menu-btn.theme--light.v-btn--disabled {
  .v-avatar.primary.lighten-3 {
    background-color: $color-btn-disabled-text-default !important;
    border-color: $color-btn-disabled-text-default !important;
  }
}
</style>
