<template>
  <AppModal
    v-if="showDigitiseFlowCancelConfirmModal"
    v-model="showDigitiseFlowCancelConfirmModal"
    ref="AppModal"
    data-testid="digitise-entry-point-modal"
    max-width="525px"
    class="digitise-cancel-modal"
    role-type="none"
  >
    <div slot="header" class="fullWidth">
      <div class="icon iconCircleBackground d-inline-block mr-2">
        <v-icon class="mt-n1" color="primary">mdi-close-circle-outline</v-icon>
      </div>
      <div class="d-inline-block">
        <span class="header-text"
          ><strong>{{ headingText }}</strong></span
        >
      </div>
      <v-btn
        text
        icon
        small
        class="modal__close-button float-right"
        color="primary"
        aria-label="close"
        @click="$emit('close')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div slot="body">
      <transition name="fade">
        <div>
          <div class="pt-1">
            <span class="subheader-text">{{ subHeadingText }}</span>
            <span class="sr-only" role="alert"
              >{{ headingText }} {{ subHeadingText }}</span
            >
          </div>
        </div>
      </transition>
    </div>
    <div slot="footer">
      <AdsButton
        class="confirm-btn"
        button-text="Confirm"
        @click="cancelApplication"
        data-testid="cancel-application"
      />
    </div>
  </AppModal>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'
import AppModal from '@/components/app/AppModal.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DigitiseFlowCancelConfirmModal',
  components: {
    AppModal,
    AdsButton
  },
  props: {
    type: {
      type: String
    },
    studentName: {
      type: String
    }
  },
  data() {
    return {
      showDigitiseFlowCancelConfirmModal: true
    }
  },
  computed: {
    ...mapGetters(['eoiApplication']),
    headingText() {
      return this.type === 'EOI'
        ? 'Cancel this expression of interest?'
        : 'Cancel this placement not required?'
    },
    subHeadingText() {
      return `Are you sure you want to cancel this ${this.type} for ${this.studentName}?`
    }
  },
  methods: {
    cancelApplication() {
      if (
        this.type === 'EOI' &&
        this.eoiApplication.supportingDocuments.digitizeEoiConsiderations
          ?.length > 0
      ) {
        const fileName =
          this.eoiApplication.supportingDocuments.digitizeEoiConsiderations[0]
            ?.name
        const fileType =
          this.eoiApplication.supportingDocuments.digitizeEoiConsiderations[0]
            ?.type
        this.$store
          .dispatch('deleteY67TSupportingDocument', {
            file: { name: fileName, type: fileType },
            schoolCode: this.eoiApplication.currentPrimarySchool?.schoolCode,
            applicationId: this.eoiApplication.student?.SRN,
            uploadedBy: `${this.$store.state.userID}@det.nsw.edu.au`,
            stream: this.eoiApplication.meta?.stream
          })
          .then(() => {
            this.$store.dispatch('setSupportingDocuments', {
              digitizeEoiConsiderations: []
            })
          })
          .catch(() => {
            this.$store.dispatch('showMessageBox', {
              icon: 'mdi-exclamation-thick',
              html: '<h2>Considerations document uploaded could not be removed.</h2>'
            })
          })
      }
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped lang="scss">
.modal__close-button.float-right.v-btn.v-btn--flat.v-btn--icon.v-btn--round {
  color: $grey-darken1 !important;
  &:focus {
    color: $color-primary !important;
  }
}
.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
    .v-btn-outlined
  ) {
  background-color: $grey-darken1 !important;
  color: white !important;

  .v-icon {
    color: white !important;
  }
}
.fullWidth {
  width: 100%;
}
.header {
  max-width: none;
}
.icon {
  background-color: $color-selected-item;
  width: 1.5em;
  line-height: 1.5em;
}
// Classes for Vue "fade" transition. Transition will fade in main dialog
// content when swapping between UI steps 1 & 2. This is necessary to show
// the content has changed, otherwise it is sometimes hard to see what has happened.
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: none;
}
.fade-enter {
  opacity: 0;
}
.fade-leave {
  display: none;
}
.v-btn:focus:not(.v-btn--round):not(.v-btn--outlined):not(
    .v-btn--text
  ).primary:not(.app-settings__save-button) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
