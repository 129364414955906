<template>
  <v-expansion-panel class="ReviewExpansionPanel">
    <v-expansion-panel-header
      class="ReviewExpansionPanel__header"
      :class="{ sectionError: !isValid }"
      :aria-expanded="isExpanded ? 'true' : 'false'"
      @click="isExpanded = !isExpanded"
    >
      <span class="ReviewExpansionPanel__title">
        <v-icon
          v-if="!isValid"
          class="ReviewExpansionPanel__icon sectionError pr-3"
        >
          error_outline
        </v-icon>
        {{ title }}
      </span>
      <span class="ReviewExpansionPanel__actions">
        <AdsButton
          v-if="includeEditButton"
          class="ReviewExpansionPanel__action ReviewExpansionPanel__action--edit"
          text
          tertiary
          icon="edit"
          button-text="Edit"
          @click.stop="$emit('edit')"
        >
        </AdsButton>
      </span>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="ReviewExpansionPanel__content">
      <slot />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { AdsButton } from '@nswdoe/doe-ui-core'

export default {
  name: 'ReviewExpansionPanel',
  components: {
    AdsButton
  },
  props: {
    isValid: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    includeEditButton: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isExpanded: true
    }
  }
}
</script>

<style lang="scss" scoped>
.ReviewExpansionPanel {
  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0 0.75rem 1rem 1.75rem;
  }
  &__header {
    font-family: $digitize-eoi-font-family;
    font-size: 18px;
    color: $ads-navy;
    font-weight: bold;
    line-height: 26px;

    &.sectionError {
      color: $ads-error-red;
    }
  }

  &__title {
    font-family: $digitize-eoi-font-family;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    font-size: 18px;
    color: $ads-navy;
    font-weight: bold;
    line-height: 26px;
  }

  &__actions {
    flex: 0 1 auto;
    height: 36px;
    min-width: 64px;
  }

  &__action {
    font-weight: bold;
    font-size: 16px;
    color: $ads-navy;
    line-height: 24px;
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
}
</style>
